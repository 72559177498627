import styles from 'src/denali-components/ImageGallery/image-gallery.module.scss'
import { TranslateComponent } from 'src/common/translations'

export const DetailImageDescription = (image) => {
  return (
    <>
      <div className={styles.imageGalleryBlur}></div>
      <div className="image-gallery-description">
        <div className={styles.imageGalleryDescriptionWrapper}>
          <div className={styles.imageGalleryDescriptionContent}>
            <span className={styles.imageGalleryDescriptionTitle}>
              <TranslateComponent>{image.title}</TranslateComponent>
            </span>
            <span>
              <TranslateComponent>{image.caption}</TranslateComponent>
            </span>
          </div>
          <div></div>
        </div>
      </div>
    </>
  )
}
