import { Button, ButtonGroup } from '@aws-amplify/ui-react'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TranslateComponent } from 'src/common/translations'
import Table from 'src/components/Table/clientSideTable'
import { DenaliTableStyle } from 'src/denali-components/Table/table.d'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { AccessControl } from 'src/components/accessControl'
import { DataError } from 'src/denali-components/Table/DataError'

/**
 * UtilityBillDataTotal
 * Utility bill data - Total table
 */
export const UtilityBillDataTotal = ({
  totalsHeader,
  totalEntries,
  filtersList,
  checkboxClick,
  onUpdateControlsFunc,
  columnOrderDown,
  listDataIsLoading,
  deleteHandler,
  downloadBillData,
  toggleUploadBillShowModal,
  actionButtonDisable,
  errors
}) => {
  if (errors.totalError) return <DataError tableName="total" />

  return (
    <>
      <Table
        testName="utilityBillDataPage"
        header={totalsHeader}
        rows={totalEntries}
        rowCheckbox
        search={true}
        filtersList={filtersList}
        checkboxClick={checkboxClick}
        isShowPagination={false}
        searchFields={['year']}
        onUpdateControlsFunc={onUpdateControlsFunc}
        columnOrderDown={columnOrderDown}
        tableRowOverflowHidden={false}
        isDenaliTable={true}
        denaliTableStyle={DenaliTableStyle.classic}
        listDataIsLoading={listDataIsLoading}
        denaliBulkActions={
          <ButtonGroup>
            <AccessControl id="tc.pages.utility-bill-data.delete">
              <Tooltip>
                <TooltipTrigger testName="deleteUtilityBillDataTrigger">
                  <Button
                    onClick={deleteHandler}
                    className="button-red button-icon"
                    disabled={actionButtonDisable}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <TooltipContent>
                    <TranslateComponent>
                      {actionButtonDisable
                        ? 'Please select one or more items to delete.'
                        : 'Delete'}
                    </TranslateComponent>
                  </TooltipContent>
                </TooltipTrigger>
              </Tooltip>
            </AccessControl>
            <Tooltip>
              <TooltipTrigger testName="downloadUtilityBillDataTrigger">
                <Button
                  onClick={downloadBillData}
                  className="button-gray button-icon"
                  disabled={actionButtonDisable}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <TooltipContent>
                  <TranslateComponent>
                    {actionButtonDisable
                      ? 'Please select one or more items to download.'
                      : 'Download'}
                  </TranslateComponent>
                </TooltipContent>
              </TooltipTrigger>
            </Tooltip>
            <AccessControl id="tc.pages.utility-bill-data.create">
              <Button
                onClick={toggleUploadBillShowModal}
                data-testid="utilityBillDataUploadButton"
              >
                <TranslateComponent>Upload Data</TranslateComponent>
              </Button>
            </AccessControl>
          </ButtonGroup>
        }
      />
    </>
  )
}
