import Container from 'src/components/Container'
import { Block } from 'src/components/layouts'
import {EditActiveStyledContainer,
  FormSubHeader,StyledAddOfferings,StyledOfferingsLabel,
  StyledButtonsContent, MinInputField,
StyledInput,
InputField
} from './styles'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {validate} from './formValidator.js'
import { useQuery } from 'src/hooks/APIHooks'
import { useLocation, useNavigate,useParams } from "react-router-dom"
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import AttachFiles from '../add/AttachFiles'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import Label from "src/components/legacy/components/label/label"
import moment from 'moment'
import { UPDATE_CONTRACT
  ,CREATE_OFFERING,UPDATE_OFFERING, 
  GET_OFFERINGS_BY_BUILDING_func,
  GET_CONTRACT_BY_ID } from './queries'
import {LIST_OFFERINGS_BY_CONTRACT,GET_BUILDINGDETAILS_BY_ID_func} from "../queries"
import { AddBuildingModal } from './AddBuildingModal'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import Modal from 'src/components/legacy/components/modal/modal'
import {
  filterOfferings,
  getDefautContractPeriod,
  getExpirationDateByContractPeriod,
  isOfferingDisabled,
  getOfferingDetailsBySubscriptionName,
  STATUS_TO_SAVE,
  ACCEPTED_FILE_TYPES
} from "src/pages/contracts-and-offerings/common/contracts-and-offerings"
import {BuildingsList} from "./BuildingsList"
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import Tooltip from 'src/components/legacy/components/tooltip/tooltip'
import {
  DATE_FORMAT_DASH1,
  isValid,
  utcTimeEndOfTheDay,
  utcTimeStartOfTheDay,
  utcTimeEndOfTheDayEng,
  utcTimeStartOfTheDayEng
} from 'src/components/legacy/common/time-helpers'
import { CREATE_ATTACHMENT,CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT,UPDATE_ATTACHMENT } from '../queries'
  import { getUniqueItems } from 'src/common/helperFunctions'
  import{useConfig} from "src/pages/contracts-and-offerings/common/config"
  import {
    hasCMSA
  } from "src/pages/contracts-and-offerings/common/contracts-and-offerings"
  import {valueWithinMinMax} from "./helpers";
import { AddOfferingsModal } from './AddOfferingsModal'
  import { TranslateComponent } from 'src/common/translations'
  import { useSelector } from 'react-redux'
  import { useAppDispatch } from 'src/redux/store'
  import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
  

export const EditActiveContract = ({initialValues = null}) => {
  const { id: contractId } = useParams()
  const navigate = useNavigate()
  const [mode, setMode] = useState(ACTIONS.EDIT)
  const [doneSaving, setDoneSaving] = useState(false)
  // const [type, setType] = useState(paramType)
  const [offeringDDLData, setOfferingDDLData] = useState([])
  const [showAddOfferingModal, setShowAddOfferingModal] = useState(false)
    const [showAddBuildingModal, setShowAddBuildingModal] = useState(false)
    const [showAddOfferingSuccess, setShowAddOfferingSuccess] = useState(false)
    const [showAddingSuccess, setShowAddingSuccess] = useState(false)
  const [contractOfferings,setContractOfferings] = useState(null)
  const [confirm, setConfirm] = useState(null)
  const [dialogTypes,setDialogTypes] = useState(null)
  const [selectedBuildings, setSelectedBuildings] = useState([])
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false)
  const {offerings:offeringsConfig,contracts:contractsConfig} = useConfig()

  // Translation
  const appDispatch = useAppDispatch();

  const textsToTranslate = {
    cancelConfirmation: "Cancellation Confirmation",
    cancelConfirmTitle: "Confirm Cancellation of Contract",
    cancelConfirm: "Are you sure you want to cancel offerings for contract",
    cancelConfirmDesc: "Cancelling the contract will disable all offerings for all buildings on this contract.",
    cancelContract: "Cancel Contract",
    cancel: "Cancel",
    addOfferings: "Add Offerings",
    offerings: "Offering(s)",
    selectOfferings: "Select Offerings",
    contractStartDate: "Contract Start Date",
    serviceContractNumber: "Service Contract Number",
    serviceContractDesc: "Please enter the appropriate Service Contract Number or Project ID (CAP Pro only). The form will not be approved until validated. For Independent offices, Purchase Order document is required below.",
    purchaseOrderNumber: "Purchase Order Number",
    mechServiceAgreeNumber: "Mechanical Service Agreement Number",
    pactNumber: "PACT Contract Number",
    offeringDetails: "Offering Details",
    totalBLRCount: "Total Baseline Report Count",
    coachingServicesValue: "Coaching Service Value",
    supportingNotes: "Supporting Notes",
    maxChar: "characters max",
    selectionMsg: "Selected seasons must add up to one complete year, or, \"No Seasonal Variation\" must be selected.",
    primaryBuilding: "Primary Building For Contract",
    accManager: "Account Manager",
    salesOfficeCode: "Billing Sales Office Code",
    salesOfficeCodeDesc: "If the contract sales office differs from the requester's default office, enter an Alternate Sales Office Code.",
    additionalRecipients: "Additional Notification Recipients",
    additionalRecipientDesc: "Any recipients that you would like to receive notifications about this contract request. Emails must be from the Trane internal team.",
    findBuildingPlaceholder: "Find a building",
    selectOnePlaceholder: "Select One",
    editConfirmTitle: "Confirm Offering Edits",
    editConfirmDesc: "Are you sure you want to save the following changes?",
    deleteConfirm: "Are you sure you want to delete",
    deleteFile: "Delete File",
    yes: "Yes",
    no: "No",
    save: "Save",
    close: "Close",
    offeringsAdded: "Offerings Added",
    offeringSuccessMsg: "The Selected Offerings have been added to the contract and applied to all specified buildings",
    buildingsAdded: "Buildings Added",
    buildingsAddedMsg: "Building(s) have been added to your contract. Selected offerings will be applied to the newly added buildings.",
    duplicateOfferings: "Duplicate active offering found for the following locations",
    successTitle: "Success!",
    editSuccessMsg: "Your edits have been saved and the contract request has been updated!",
    editContracttitle: "Edit Contract Request: Contract",
    deviceCount: "Device Count",
    businessStream: "Business Stream",
    expirationDate: "Expiration Date",
    buildingInvoiceCount: "Building Invoice Count",
    buildingCountDesc: "This is the number of buildings that the customer will be invoiced for. Building count will default to the number of buildings selected above.",
    removeOffering: "Remove Offering",
    removeQuestion: "Are you sure you want to remove",
    removeDesc: "Removing this offering will apply to all",
    removeDescCont: "buildings in this contract",
    offeringRemoved: "Offering Removed",
    offeringRemovedDesc: "Changes will be applied when the updated contract request is saved",
    cancelDesc: "has been cancelled!",
    accManagerDesc: "Email address of the person who sold the contract",
    emailDesc: "Email addresses should be seperated by a comma"
  }

  const [optionsTranslated, setOptionsTranslated] = useState(textsToTranslate)
  const { translatedJSON } = useSelector((state: any) => state?.translationCombinedSlice)

  useEffect(() => {
    appDispatch<any>(translateJSON({ json: textsToTranslate }));
  }, [])

  useEffect(() => {
    if (translatedJSON) {
      setOptionsTranslated(translatedJSON)
    }
  }, [translatedJSON, textsToTranslate])

  const {
    refetch: refetchContractById,
    loading:loadingContractById
  } = useQuery({
    query:GET_CONTRACT_BY_ID,
    disableInitialLoad:true,
    dataPath:"data.listContracts.items"
  })

  const {
    refetch: refetchOfferingsByContract
  } = useQuery({
    query:LIST_OFFERINGS_BY_CONTRACT,
    disableInitialLoad:true,
    dataPath:"data.listOfferingsByContract.items"
  })

  useEffect(() => {
    if (doneSaving) {
      navigate(pages["Contracts & Offerings"].href)
    }
  }, [doneSaving])

  useEffect(() => {
    if(contractId){
      (async () => {
       const contractAndOfferings = await Promise.all([
        refetchContractById({id:contractId}),
        refetchOfferingsByContract({id:contractId})
       ])
       setContractOfferings({contract : contractAndOfferings[0][0],offerings :contractAndOfferings[1]})
      })()
      
    }
  }, [contractId])


  
  const {refetch:refetchBuildingsByIds} = useQuery({
    query:null
  })

  const {refetch:refetchOfferingsByBuilding} = useQuery({
    query:null
  })




  const { onSubmit: createOffering, loading: isLoadingCreateOffering} = useMutation({
    query: CREATE_OFFERING,
    dataPath: 'data.createOffering'
  })

  const { onSubmit: updateContract, loading: isLoadingUpdateContract } = useMutation({
    query: UPDATE_CONTRACT,
    dataPath: 'data.updateContract'
  })

  const { onSubmit: updateOffering, loading: isLoadingUpdateOffering } = useMutation({
    query: UPDATE_OFFERING,
    dataPath: 'data.updateOffering'
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const combinedLoading = 
        isLoadingUpdateContract || isLoadingUpdateOffering || isLoadingCreateOffering

  const onDeleteAttachment = (fileId, fileName) => {
    formik.setFieldValue(
      'attachments',
      values.attachments.filter(
        (attachment) => !(attachment.fileName === fileName)
      )
    )
  }


const showConfirm = (fileId, fileName) => {
  setConfirm({
    title: optionsTranslated.deleteFile,
    text: `${optionsTranslated.deleteConfirm} ${fileName} ?`,
    className: 'confirm-delete-dialog',
    confirmText: optionsTranslated.yes,
    cancelText: optionsTranslated.no,
    handleConfirm: () => {
      setConfirm(null)
      onDeleteAttachment(fileId, fileName)
    }
  })
}



  useEffect(() => {
    if(mode === ACTIONS.EDIT && initialValues){
      refetchBuildingsByIds(null,null, GET_BUILDINGDETAILS_BY_ID_func(values.buildingIds)).then(res => {
        if(res?.data && !res.data.errors){
        const buildings = Object.values(res.data)
        

        setOfferingDDLData(state  => 
          [...state,
        ...buildings.reduce((acc:[any],b:any):[any] => {
          const data =  {
            key:b.id,
            label:b.name,
            crmCustId:b.crmCustId,
            address:b.address
          }
          if(!(state.some(building => building.key === b.id))){
          acc.push(data)
          }
          return acc;
        }
       ,[] ) as any[]
      ]
        )


    }
        })
      }
    },[mode,initialValues]
    
    )



  const handleAddOffering = e => {
        e.preventDefault()
        setShowAddOfferingModal(true)
    }
    const handleAddBuilding = e => {
        e.preventDefault()
        setShowAddBuildingModal(true)
    }

    // Handle modal close option
    const handleCloseAddOfferingModal = () => {
        setShowAddOfferingModal(false)
    }
    const handleCloseAddBuildingModal = () => {
        setShowAddBuildingModal(false)
    }
  const onCloseDialog = field => {
    setDialogTypes({
      [field] : null
    })
    if (isUpdateSuccess){
      setDoneSaving(true)
    }
    }

  const showAddOfferingSuccessModal = () => (
        <Modal
            gray={true}
            className="add-offering-success-modal"
            heading={optionsTranslated.offeringsAdded}
            handleClose={() => {
        setShowAddOfferingSuccess(false)
        handleCloseAddOfferingModal()
      }}
            buttons={[{
                text: optionsTranslated.close,
                handleClick: () => {
          setShowAddOfferingSuccess(false)
          handleCloseAddOfferingModal()
        }
            }]}
        >
            <fieldset>
                <p className="">{optionsTranslated.offeringSuccessMsg}</p>
            </fieldset>
        </Modal>
    )



    const showAddBuildingSuccessModel = () => (<Modal
        gray={false}
        className="add-offering-success-modal"
        heading={optionsTranslated.buildingsAdded}
        handleClose={() => {
      setShowAddingSuccess(false)
      handleCloseAddBuildingModal()
    }}
        buttons={[{
            text: optionsTranslated.close,
            handleClick: () => {
        setShowAddingSuccess(false)
      handleCloseAddBuildingModal()
      }
        }]}
    >
        <fieldset>
            <p className=""> {selectedBuildings?.length} {optionsTranslated.buildingsAddedMsg} </p>
        </fieldset>
    </Modal>)



  const formik = useFormik({
    initialValues : {
      ...initialValues,
      "newOfferingsToBuildinsMap":initialValues.offeringsToBuildinsMap.map(off => ({...off,checkboxCheckedEnabled:true}))
    },
    enableReinitialize: true,
    validateOnMount:true,
    validateOnChange: true,
    validate,
    onSubmit: async (values: any) => {
      let isOnlyDIOffering = false,
          isCapMV =  false
      const updatedBuildings = values.newOfferingsToBuildinsMap.filter(_new  =>  _new.checkboxCheckedEnabled)
      if(updatedBuildings?.length > 0){
        const existingUpdatedBuildings = values.newOfferingsToBuildinsMap.filter(_new  =>  _new.checkboxCheckedEnabled && (values.offeringsToBuildinsMap.some(existing => _new.buildingId === existing.buildingId))
        )
        const offeringsinCurrentContract = values.offeringsToBuildinsMap.map(o => o.name)
      const {data : existingOfferingsOfSelectedBuildingsData} = 
        await refetchOfferingsByBuilding(null,null, GET_OFFERINGS_BY_BUILDING_func(getUniqueItems(updatedBuildings.map(n => n.buildingId))))        

        const existingOfferingsOfSelectedBuildings:any = Object.values(existingOfferingsOfSelectedBuildingsData).reduce((acc:any,off:any) => 
        {
          const data = off.items.map((_off:any) =>  ({name : _off.name,
            buildingId: _off.buildingId,
            code: _off.code,
            contractId : _off.contractId
          })
          )
          acc = acc.concat(data)
         return acc
        }
          ,[]) 

          const duplicateOfferingsforBuildings = updatedBuildings.reduce((acc, value) => {
            const currentBuildingOfferings = existingOfferingsOfSelectedBuildings.filter((off: any) => off.buildingId === value.buildingId)
            const currentBuildingOfferingsFromOtherContracts = currentBuildingOfferings.filter((off:any) => off.contractId !== contractId )
            const isExistingUpdatedBuilding = existingUpdatedBuildings.some(e => e.buildingId === value.buildingId)
            const duplicates = currentBuildingOfferings.reduce((dupAcc,off) => {
              if (!['CS', 'BR'].includes(off?.name)){
             if( (!isExistingUpdatedBuilding) )
              {
                if(( off.name === value.name || 
                  isOfferingDisabled(currentBuildingOfferings.map(off => off.name), value.name))) {
             !(dupAcc.some(a => a.name === value.name && a.buildingId === value.buildingId)) && dupAcc.push(value)
              return dupAcc
                }
             }else {
              if(( currentBuildingOfferingsFromOtherContracts.some(contract => value.name === contract.name) || 
                isOfferingDisabled(currentBuildingOfferingsFromOtherContracts.map(off => off.name), value.name,true))) {
           !(dupAcc.some(a => a.name === value.name && a.buildingId === value.buildingId)) && dupAcc.push(value)
            return dupAcc
              }else {
                if(((!offeringsinCurrentContract.some(contract => value.name === contract)) && !(isOfferingDisabled(offeringsinCurrentContract,value.name))
                  && ( off.name === value.name || 
                  isOfferingDisabled(currentBuildingOfferings.map(off => off.name), value.name)))){
                    !(dupAcc.some(a => a.name === value.name && a.buildingId === value.buildingId)) && dupAcc.push(value)  
                  }
              }
             }

            }
            return dupAcc
              
            },[])
              acc = duplicates?.length > 0 ? acc.concat(
                duplicates
              ) : acc;
            return acc
          }, [])
        if (duplicateOfferingsforBuildings?.length > 0) {
          const errorText = <>
            {optionsTranslated.duplicateOfferings}:
            <ul >
              {duplicateOfferingsforBuildings?.map(
                duplicateOfferingsforBuilding =>
                (<li key={duplicateOfferingsforBuilding.buildingId}>{`${offeringDDLData.find(off => off.key === duplicateOfferingsforBuilding.buildingId)?.label} - ${duplicateOfferingsforBuilding.name}`}</li>)
                )
              }
                
            </ul>
          </>
          setDialogTypes({
            error: {
              text: errorText,
              translateText: false
            },
            onCloseDialog: () => () => setDialogTypes(null)
          })
          return;
        }
      }

        if(values.offerings?.length === 1 && hasCMSA(values.offerings)){
          isOnlyDIOffering = true
        }
        if(values.offerings?.includes("CAPxM&V")){
          isCapMV = true
        }

      const inputData = {
        id:values.contractId,
        accountId:values.accountId,
        deviceCount: parseInt(values.numberOfDevices),
        buildingCount: parseInt(values.buildingCount),
        accountMgr: values.accountManager,
        billingSalesOfficeCode: values.billingSalesOfficeCode,
        receipients: values.additionalRecipients === "" ? undefined : values.additionalRecipients?.split(","),
        stream: values.businessStream,
        serviceNumber: !isOnlyDIOffering ? values.serviceContractNumber : null,
        status: STATUS_TO_SAVE.APPROVED,
          endDate: utcTimeEndOfTheDayEng(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
            contractPeriod : values.length,
            expDate:values.expirationDate
          })),
        coachingServicesValue: values.coachingServicesValue,
        csTermsAccepted: values.csTermsAccepted,
        primaryBuilding: values.primaryBuilding,
        totalBaselineReportCount: values.totalBaselineReportCount,
        additionalContractNumbers:JSON.stringify({
          mechanicalServiceAgreementNumber : isOnlyDIOffering ? values.mechanicalServiceAgreementNumber : null,
          pactContractNumber: isCapMV ? values.pactContractNumber : null
        })

        // primaryBuilding: values.primaryBuilding

      }
      await updateContract({input: inputData}).then(async res => {
        if(!res.errors){
          setIsUpdateSuccess(true)
          setDialogTypes({
            notification: {
              title: optionsTranslated.successTitle,
              text: optionsTranslated.editSuccessMsg,
            }
          })
          const saveAttachements  = () => values.attachments
          .filter((a) => !a.id)
          .map((a) =>
            createAttachmentMutation({
              input: {
                attachedAt: new Date(a.timestamp).toISOString(),
                buildingId: values.accountId,
                contractId:res.id,
                description: a.description,
                name: a.fileName,
                title: a.title,
                sizeInBytes: a.fileSize,
                userId: values.currentUserId,
                type: a.type
              }
            })
          )

          const updateAttachements  = () => values.attachments
          .filter((a) => a.id)
          .reduce((acc,value) => {
            const existingAttachmnent  = values.originalAttachments.find(a => a.id === value.id)
            if(existingAttachmnent?.description !== value.description)
           acc.push( updateAttachmentMutation({
              input: {
                description: value.description,
                id:value.id
              }
            })
           )
           return acc;
          },[]
          )

          const deleteAttachements  = () => values.originalAttachments
          .filter((oa) => !(values.attachments.find(a => a.id === oa.id)))
          .map(attachment  => deleteAttachmentMutation({
            input: {
              id:attachment.id
            }
          }))
        const {newOfferingsToBuildinsMap,offeringsToBuildinsMap} = values

          const offeringsToDelete = offeringsToBuildinsMap.filter(mapping => 
            !(newOfferingsToBuildinsMap.some(newMapping => newMapping.buildingId === mapping.buildingId &&
              newMapping.name === mapping.name && newMapping.checkboxCheckedEnabled)
              )
            );
          const offeringsToUpdate = offeringsToBuildinsMap.filter(mapping => 
            (newOfferingsToBuildinsMap.some(newMapping => newMapping.buildingId === mapping.buildingId &&
              newMapping.name === mapping.name &&  newMapping.checkboxCheckedEnabled)
              )
            )?.map(offering => ({
              id:offering.id,
              status: STATUS_TO_SAVE.APPROVED,
              isActive: 1,
              startDate: utcTimeStartOfTheDayEng(values.startDate),
              endDate: utcTimeEndOfTheDayEng(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
                contractPeriod : values.length,
                expDate:values.expirationDate
              }))
            }))
          const offeringsToCreate = newOfferingsToBuildinsMap.filter(newMapping => 
            !(offeringsToBuildinsMap.some(mapping => newMapping.buildingId === mapping.buildingId &&
              newMapping.name === mapping.name && newMapping.checkboxCheckedEnabled) 
              ) && newMapping.checkboxCheckedEnabled
            )?.map(offering => {
              const offeringDetails = getOfferingDetailsBySubscriptionName(contractsConfig,offeringsConfig,offering.name)
              return {buildingId: offering.buildingId,
              accountId: values.accountId,
              contractId: res.id,
              name:  offeringDetails?.Name || offering.name,
                code: offeringDetails?.Code || offering.name,
                subscriptionCode:offering.name,
              description: offeringDetails?.Description || "",
              analyticsStartDate: utcTimeStartOfTheDayEng(values.analyticsStartDate || values.startDate),
              startDate: utcTimeStartOfTheDayEng(values.startDate),
              endDate: utcTimeEndOfTheDayEng(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
                contractPeriod : values.length,
                expDate:values.expirationDate
              })),
              gracePeriod: offeringDetails?.GracePeriod || 30, //Unsure on this
              status: STATUS_TO_SAVE.APPROVED,
              isActive: 1,
              type: "Building",
              typeId: "NA",
            }
            })
          Promise.all([ 
            ...(offeringsToCreate.map(async e => await createOffering({input: e}))),
            ...(offeringsToDelete.map(offeringDelete => updateOffering({input: {id: offeringDelete.id,status:STATUS_TO_SAVE.DELETED,isActive:0}}))),
            ...(offeringsToUpdate.map(offeringInput => updateOffering({input:offeringInput})))
          ]
          ).then(pres => {
            Promise.all([...saveAttachements(),
              ...updateAttachements(),
              ...deleteAttachements()
            ]).then((response) =>
            Promise.all(
              response.map((r) => {
                return r?.data?.createAttachment?.id
                  ? createEntryWithAttachmentMutation({
                      input: JSON.stringify({
                        modelType: 'Contract',
                        attachments: {
                          create: [
                            {
                              buildingId:
                                r.data.createAttachment.buildingId,
                                contractId:
                                r.data.createAttachment.contractId,
                              name: r.data.createAttachment.name
                            }
                          ]
                        }
                      })
                    })
                  : ''
              })
            ).then(() => setDoneSaving(true))
            )
          })
        }
        } )
      }
  })

  const { values, errors } = formik


  const handleOfferingCancel = async () => {
   return  await Promise.all(await values?.offeringDetails?.map(async e => await updateOffering({
      input: {
        id: e?.id,
        status: STATUS_TO_SAVE.CANCELLED,
        isActive:0
      }
    })))

  }
  const handleContractCancel = async () => {
    return await updateContract({
      input: {
        id: values?.contractId,
        status: STATUS_TO_SAVE.CANCELLED
      }
    })
  }

  const handleRemoveOffering = useCallback(
    (newOfferingNames, removedOffering) => {
      setConfirm({
        title: optionsTranslated.removeOffering,

        text: `${optionsTranslated.removeQuestion}: ${removedOffering} ?`,

        description: `${optionsTranslated.removeDesc} ${offeringDDLData?.length} ${optionsTranslated.removeDescCont}`,

        confirmText: optionsTranslated.removeOffering,

        cancelText: optionsTranslated.cancel,

        handleConfirm: () => {
          setConfirm(null)

          updateOfferings(newOfferingNames)
        },

        handleCancel: () => setConfirm(null),
      })
    },
    [setConfirm, offeringDDLData, optionsTranslated]
  )

  const updateOfferings = useCallback(
    (newOfferingNames) => {
      formik.setFieldValue('offerings', newOfferingNames)
      for (const offering of newOfferingNames) {
        const defaultContractPeriodKey = getDefautContractPeriod(
          contractsConfig,
          offering.key
        )
        defaultContractPeriodKey
          ? formik.setValues({
              ...values,
              offerings: newOfferingNames,
              length: defaultContractPeriodKey
            })
          : formik.setFieldValue('offerings', newOfferingNames)
      }
      const modifiedExistingOfferingsToBuildinsMap =
        values?.newOfferingsToBuildinsMap?.filter((offering) =>
          newOfferingNames?.includes(offering.name)
        )
      formik.setFieldValue(
        'newOfferingsToBuildinsMap',
        modifiedExistingOfferingsToBuildinsMap
      )
      setConfirm({
        title: optionsTranslated.offeringRemoved,
        text: optionsTranslated.offeringRemovedDesc,
        isHideConfirmButton: true,
        cancelText: optionsTranslated.close,
        handleCancel: () => {
          setConfirm(null)
        }
      })
    },
    [formik, contractsConfig, values]
  )

  const handleOfferingChange = useCallback(
    (selectedOfferings) => {
      const newOfferingNames = selectedOfferings?.map((item) => item.key)
      if (newOfferingNames?.length < values?.offerings?.length) {
        const removedOffering = values?.offerings?.find(
          (item) => !newOfferingNames?.includes(item)
        )

        handleRemoveOffering(newOfferingNames, removedOffering)
      } else {
        updateOfferings(newOfferingNames)
      }
    },
    [values.offerings, handleRemoveOffering, updateOfferings]
  )


  return (
    <EditActiveStyledContainer>
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={`${optionsTranslated.editContracttitle} ${values.serviceContractNumber}`}
          titleTools={
            <>
              <button className="cancel-contract-btn" onClick={e => {
                 setConfirm({
                  title: optionsTranslated.cancelConfirmTitle,
                  text: `${optionsTranslated.cancelConfirm} ${values?.serviceContractNumber ? `: ${values?.serviceContractNumber}` : ''} ?`,
                  description: optionsTranslated.cancelConfirmDesc,
                  confirmText: optionsTranslated.cancelContract,
                  cancelText: optionsTranslated.cancel,
                  handleConfirm: async () => {
                   setConfirm(null)
                   const res:any = await Promise.all([handleContractCancel(),handleOfferingCancel()])
                   const errors = res.flat()?.find(_data =>  !_data);
                   if(!errors){
                     setConfirm({
                       title: optionsTranslated.cancelConfirmation,
                       text: `${values?.serviceContractNumber ? values?.serviceContractNumber : ''}: ${optionsTranslated.cancelConfirmDesc}`,
                       isHideConfirmButton: true,
                       cancelText: optionsTranslated.close,
                       handleCancel: () => {
                         setConfirm(null)
                         navigate(pages["Contracts & Offerings"].href)
                       }
                     })
                   }
                  },
                  handleCancel: () => {
                    setConfirm(null)
                  }
                })
              }}>
                <span className="close-button icon icon-close" />{optionsTranslated.cancelContract}
              </button>
            </>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
<h3>
  <TranslateComponent>
  Buildings and Offerings
  </TranslateComponent>
</h3>
        </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><StyledOfferingsLabel text={
              <Block display={"flex"} justify={"space-between"}>
              <span className={errors.offerings && "error"}>
                {optionsTranslated.offerings}*
              </span>
              <StyledAddOfferings onClick={e => handleAddOffering(e)}>
                + {optionsTranslated.addOfferings}
              </StyledAddOfferings>
              </Block>
            }/>
              <div>
                <SelectrixWrapper
                  className={
                    'editActive location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select'
                  }
                  placeHolderInside={true}
                  defaultValue={values.offerings}
                  multiple={true}
                  onChange={handleOfferingChange}
                  options={filterOfferings(contractsConfig, values.offerings)}
                  searchable={false}
                  searchBoxInside={false}
                  searchIndex={false}
                  stayOpen={true}
                />
                {/* {<p className="error"><>{errors.utilityId && "Utility Company is required."}</></p> } */}
                {errors.offerings && (
                  <p className="error">
                    <>{errors.offerings}</>
                  </p>
                )}
              </div>

          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
<h4>
  <TranslateComponent>
 Select Offerings
  </TranslateComponent>
</h4>
<span>
<TranslateComponent>
Use checkboxes to enable/disable offerings for buildings in this contract.
  </TranslateComponent>
</span>
        </Content>
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><StyledOfferingsLabel text={
              <Block display={"flex"} justify={"space-between"}>
              <span>
              </span>
              <StyledAddOfferings onClick={e => handleAddBuilding(e)}>
                <TranslateComponent>+ Add Buildings</TranslateComponent>
              </StyledAddOfferings>
              </Block>
            }/>
        <div>
        <BuildingsList
        formik ={formik}
        offeringsToBuildinsMap = {values.offeringsToBuildinsMap}
        newOfferingsToBuildinsMap ={values.newOfferingsToBuildinsMap}
    buildings ={offeringDDLData}
    config = {contractsConfig}
    />
    {errors.buildingIds && <p className="error"><>{errors.buildingIds}</></p>}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <h3>
            <TranslateComponent>
              Contract Information
            </TranslateComponent>
          </h3>
        </Content>
        
        <Content
          xlColumn={6}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <>
            <span>
              {optionsTranslated.buildingInvoiceCount}
            </span>
            <Tooltip
              horizontalPosition="left"
              content={optionsTranslated.buildingCountDesc}
              SpanOrDiv="span"
              width="200px"
              size="12px"
            >
            <span className="icon icon-infocircle" />
            </Tooltip>
            </>
          } />
            <div>
              <StyledInput type="number" value={values.buildingCount} onChange={e => {
                const value = valueWithinMinMax(e.target.value)
                formik.setFieldValue("buildingCount", value)
              }} />
            </div>
          </>
        </Content>
        <Content
          xlColumn={6}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <span>
             {optionsTranslated.deviceCount}
            </span>
          } />
            <div>
              <StyledInput type="number" value={values.numberOfDevices} onChange={e => {
                const value = valueWithinMinMax(e.target.value)
                formik.setFieldValue("numberOfDevices", value)
              }} />
            </div>
          </>
        </Content>
        
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                {optionsTranslated.businessStream}
              </span>
            }/>
        <div>
        {values.businessStream}
          {/* {<p className="error"><>{errors.utilityId && "Utility Company is required."}</></p> } */}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>


        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                {optionsTranslated.contractStartDate}*
              </span>
            }/>
        <div>
        {values.startDate}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <><Label text={
              <span>
                {optionsTranslated.expirationDate}*
              </span>
            }/>
        <div>
          <ModalDatePickerInput 
            date={values.expirationDate}
            onChange={e => {
              formik.setValues(
                {
                  ...values,
                  "expirationDate": moment(e).format("MM/DD/YYYY"),
                  "length" : "custom"
                }
              )
            }}
            // isRequired={false}
            // labelText={`${translate("Creation Date")}*`}
            hasError={Boolean(errors.expirationDate)}
            errorMessage={errors.expirationDate}
          />
          </div>
          </>
        </Content>
        {/independent/i.test(values.businessStream) ? <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
              <span className={errors.serviceContractNumber && "error"}>
                {optionsTranslated.purchaseOrderNumber}*
              </span>
            }/>
            <div>
              <input value={values.serviceContractNumber || ""} maxLength={50}  onChange={e => {
                formik.setFieldValue("serviceContractNumber", e.target.value)
              }} />
            </div>
            </>
            {errors.serviceContractNumber && <p className="error"><>{errors.serviceContractNumber}</></p>}
      </Content>:
      !(values.offerings?.length === 1 && hasCMSA(values.offerings)) ? 
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <><Label text={
        <span className={errors.serviceContractNumber && "error"}>
          {optionsTranslated.serviceContractNumber}*
        </span>
      }/>
      <div>
        <input maxLength={50}  value={values.serviceContractNumber || ""} onChange={e => {
          formik.setFieldValue("serviceContractNumber", e.target.value)
        }} />
      </div>
      {errors.serviceContractNumber && <p className="error"><>{errors.serviceContractNumber}</></p>}
      </></Content>
      : null}
      {(/independent/i.test(values.businessStream) || !(values.offerings?.length === 1 && hasCMSA(values.offerings))) &&
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
       {optionsTranslated.serviceContractDesc} 
      </Content>}
      {values.offerings.includes("CAPxM&V") &&
      <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
    >
      <><Label text={
        <span className={errors.pactContractNumber && "error"}>
          {optionsTranslated.pactNumber}*
        </span>
      }/>
      <div>
        <input value={values.pactContractNumber || ""} onChange={e => {
          formik.setFieldValue("pactContractNumber", e.target.value)
        }} />
      </div>
      {errors.pactContractNumber && <p className="error"><>{errors.pactContractNumber}</></p>}
      </></Content> }
       {(values.offerings?.length === 1 && hasCMSA(values.offerings)) &&
       <Content
       xlColumn={12}
       lColumn={12}
       mColumn={12}
       sColumn={12}
       border="none"
     >
       <><Label text={
        <span className={errors.mechanicalServiceAgreementNumber && "error"}>
          {optionsTranslated.mechServiceAgreeNumber}*
         </span>
       }/>
       <MinInputField>
         <input value={values.mechanicalServiceAgreementNumber || ""}
         className="input-field"
         onChange={e => {
           formik.setFieldValue("mechanicalServiceAgreementNumber", e.target.value)
         }} />
       </MinInputField>
       {errors.mechanicalServiceAgreementNumber && <p className="error"><>{errors.mechanicalServiceAgreementNumber}</></p>}
       </> </Content>}
         {
          (values.offerings.includes("BR") || values.offerings.includes("CS")) && 
          <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <FormSubHeader>{optionsTranslated.offeringDetails}</FormSubHeader>
      </Content>
         }

{values.offerings.includes("BR") &&
      <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
    >
      <><Label text={
        <span className={errors.totalBaselineReportCount && "error"}>
          {optionsTranslated.totalBLRCount}
        </span>
      }/>
      <div>
        <input value={values.totalBaselineReportCount || ""} onChange={e => {
          const value = valueWithinMinMax(e.target.value)
          formik.setFieldValue("totalBaselineReportCount", value)
        }} />
      </div>
      {errors.totalBaselineReportCount && <p className="error"><>{errors.totalBaselineReportCount}</></p>}
      </></Content> }


      {values.offerings.includes("CS") &&
      <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
    >
      <><Label text={
        <span className={errors.coachingServicesValue && "error"}>
          {optionsTranslated.coachingServicesValue}
        </span>
      }/>
      <div>
        <input value={values.coachingServicesValue || ""} maxLength={10} onChange={e => {
          formik.setFieldValue("coachingServicesValue", e.target.value)
        }} />
      </div>
      {errors.coachingServicesValue && <p className="error"><>{errors.coachingServicesValue}</></p>}
      </></Content> }

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <AttachFiles 
          setFieldValue ={formik.setFieldValue}
          values={formik.values}
          organizationId={values.accountId}
          onDeleteAttachment={(id, name) => showConfirm(id, name)}
          proposalFileTypes={ACCEPTED_FILE_TYPES}
          />
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
              <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {
          [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><TextAreaInput
          maxLength={2000} 
            labelText={optionsTranslated.supportingNotes}
            defaultValue={values?.supportingNotes}
            name="Supporting Notes"
            onChange={({
              target: { value }
            }) => {
              formik.setFieldValue("supportingNotes", value)
            }}
            isRequired={false}
          />2000 {optionsTranslated.maxChar}</> : values?.supportingNotes
        }
      </Content>
      
        {<p className="error"><>{errors.seasons && optionsTranslated.selectionMsg}</></p> }
        <br />
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
              <span className={errors.accountManager && "error"}>
                {optionsTranslated.accManager}
              </span>
            }/>
            <div>
              <InputField>
                <input
                  value={values.accountManager || ''}
                  placeholder="Email ID"
                  onChange={(e) => {
                    formik.setFieldValue('accountManager', e.target.value)
                  }}
                  className="input-field"
                />
              </InputField>
              <div>{optionsTranslated.accManagerDesc}</div>
              {errors.accountManager && (
                <p className="error">
                  <>{errors.accountManager}</>
                </p>
              )}
            </div>
            </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
          <>
              <span>
                {optionsTranslated.salesOfficeCode}
              </span>
              <Tooltip
                      horizontalPosition="left"
                      content={optionsTranslated.salesOfficeCodeDesc}
                      SpanOrDiv="span"
                      width="200px"
                      size="12px"
                    >
                      <span className="icon icon-infocircle" />
                    </Tooltip>
              </>
            }/>
            <div>
                 <input
                   maxLength={10}
                   value={values.billingSalesOfficeCode || ''}
                   onChange={(e) => {
                     formik.setFieldValue(
                       'billingSalesOfficeCode',
                       e.target.value
                     )
                   }}
                 />
            </div>
            </>
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
          <>
              <span>
                {optionsTranslated.additionalRecipients}
              </span>
              <Tooltip
              horizontalPosition="left"
              content={optionsTranslated.additionalRecipientDesc}
              SpanOrDiv="span"
              width="200px"
              size="12px"
              >
              <span className="icon icon-infocircle" />
              </Tooltip>
              </>
            }/>
            <div>
              <InputField>
                <input
                  value={values.additionalRecipients || ''}
                  placeholder="Email ID"
                  onChange={(e) => {
                    formik.setFieldValue('additionalRecipients', e.target.value)
                  }}
                  className="input-field"
                />
              </InputField>
              <div>{optionsTranslated.emailDesc}</div>
              {errors.additionalRecipients && (
                <p className="error">
                  <>{errors.additionalRecipients}</>
                </p>
              )}
            </div>
            </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                {optionsTranslated.primaryBuilding}
              </span>
            }/>
        <div>
          <SelectrixWrapper
                        // appendTagPrompt="Create Utility Company “{searchtext}”"
                        className={`location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select`}
                        placeholder={`-${optionsTranslated.selectOnePlaceholder}-`}
                        placeHolderInside={true}
            defaultValue={values.primaryBuilding}
                        inputPlaceholder={`${optionsTranslated.findBuildingPlaceholder}...`}
                        multiple={false}
                        onChange={e => {
              formik.setFieldValue("primaryBuilding", e.key)
            }
            }
						options={offeringDDLData.filter(({ key }) => values.buildingIds.includes(key))}
						searchable={true}
						searchBoxInside={true}
						searchIndex={false}
						stayOpen={false}
						// tags={true}
						// validationWithRegisterCase={true}
					/>
         {errors.primaryBuilding && <p className="error"><>{errors.primaryBuilding}</></p>}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
      </Content>
      <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} border={"none"}>
          <StyledButtonsContent>
            <Button type="secondary" onClick={() => {
              navigate(pages["Contracts & Offerings"].href)
            }}>
              {optionsTranslated.cancel}
            </Button>
            <Button  type="primary" onClick={ async () => {
              // formik.handleSubmit()
              const _errors = await formik.validateForm({...values})
      if (Object.keys(_errors)?.length > 0) {
        return
      }
              setDialogTypes({
                confirm: {
                  title: optionsTranslated.editConfirmTitle,
                  description: optionsTranslated.editConfirmDesc,
                  handleConfirm: () => formik.handleSubmit(),
                  handleCancel: () => setDialogTypes(null)
                }
              })
            }
          }
            >
              {optionsTranslated.save}
            </Button>
          </StyledButtonsContent>
        </Content>
    </Container>
    {showAddOfferingModal && <AddOfferingsModal values = {values} 
    onConfirm = {() => setShowAddOfferingSuccess(true)}
    contractsConfig={contractsConfig}
    offeringDDLData ={offeringDDLData}
    formik ={formik}
    handleCancel = {handleCloseAddOfferingModal}
    />}
      {showAddBuildingModal && <AddBuildingModal values = {values} 
      setOfferingDDLData = {setOfferingDDLData}
       onConfirm = {() => setShowAddingSuccess(true)}
    offeringDDLData ={offeringDDLData}
    formik ={formik}
    handleCancel = {handleCloseAddBuildingModal}
    selectedBuildings={selectedBuildings}
    setSelectedBuildings={setSelectedBuildings}
    config = {contractsConfig}
    />}
    {showAddOfferingSuccess && showAddOfferingSuccessModal()}
    {showAddingSuccess && showAddBuildingSuccessModel()}
    <StandardDialogs error={dialogTypes?.error} onCloseDialog = {onCloseDialog} confirm={dialogTypes?.confirm || confirm} notification={dialogTypes?.notification} isLoading={combinedLoading} />
    </EditActiveStyledContainer>
  )
}
