import { TranslateComponent } from 'src/common/translations'
import { Flex } from '@aws-amplify/ui-react'
import {
  faBuilding,
  faCalendarLines,
  faTachographDigital
} from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { Chip } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import {
  ChipStatus,
  ChipStatuses
} from 'src/denali-components/Table/ChipStatus'
import {
  ChipPriority,
  ChipPriorities
} from 'src/denali-components/Table/ChipPriority'
import {
  ChipNextStep,
  ChipNextSteps
} from 'src/denali-components/Table/ChipNextStep'
import { Visibility } from 'src/denali-components/Table/Visibility'
import styles from 'src/denali-components/Page/detailPage.module.scss'

interface DetailSubHeaderProps {
  title: string
  priority?: ChipPriorities
  isPublic?: boolean
  buildingName?: string
  tisObjects?: Array<any>
  dateCreated?: string
  status?: ChipStatuses
  nextStep?: ChipNextSteps
}

export const DetailSubHeader = ({
  title,
  priority,
  isPublic,
  buildingName,
  tisObjects,
  dateCreated,
  status,
  nextStep
}: DetailSubHeaderProps) => {
  return (
    <>
      <Flex className={styles.detailSubHeader}>
        <h2 data-testid={'manageOpportunity-title'}>
          <TranslateComponent>{title}</TranslateComponent>
        </h2>
        <Tooltip key="priority">
          <TooltipTrigger>
            <ChipPriority priorityText={priority} />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Priority</TranslateComponent>
          </TooltipContent>
        </Tooltip>
        <Tooltip key="visibility">
          <TooltipTrigger>
            <Visibility value={isPublic ? 'ON' : 'OFF'} />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>
              {isPublic ? 'Visible' : 'Not Visible'}
            </TranslateComponent>
          </TooltipContent>
        </Tooltip>
      </Flex>
      <Flex alignItems="center" gap="12px">
        <Chips className={styles.detailMeta}>
          {buildingName && (
            <Tooltip key="building">
              <TooltipTrigger>
                <Chip size="large" text={buildingName} icon={faBuilding} />
              </TooltipTrigger>
              <TooltipContent>
                <TranslateComponent>Building</TranslateComponent>
              </TooltipContent>
            </Tooltip>
          )}
          {tisObjects && tisObjects.length > 0 && (
            <Tooltip key="equipment">
              <TooltipTrigger>
                <Chips>
                  {tisObjects.map((equipment, index) => (
                    <Chip
                      key={index}
                      size="large"
                      text={equipment.tisObjectName}
                      icon={faTachographDigital}
                    />
                  ))}
                </Chips>
              </TooltipTrigger>
              <TooltipContent>
                <TranslateComponent>Equipment</TranslateComponent>
              </TooltipContent>
            </Tooltip>
          )}
          {dateCreated && (
            <Chip
              size="large"
              text={`Created on ${formatDate(dateCreated)}`}
              icon={faCalendarLines}
            />
          )}
          {status && (
            <Tooltip key="status">
              <TooltipTrigger>
                <ChipStatus size="large" statusText={status} />
              </TooltipTrigger>
              <TooltipContent>
                <TranslateComponent>Status</TranslateComponent>
              </TooltipContent>
            </Tooltip>
          )}
          {nextStep && (
            <Tooltip key="nextStep">
              <TooltipTrigger>
                <ChipNextStep
                  size="large"
                  nextStepText={convertNextStep(nextStep)}
                />
              </TooltipTrigger>
              <TooltipContent>
                <TranslateComponent>Next Step</TranslateComponent>
              </TooltipContent>
            </Tooltip>
          )}
        </Chips>
      </Flex>
    </>
  )
}

const formatDate = (date: string) => {
  return new Date(date)
    .toLocaleDateString('en-UK', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    })
    .replace(/(\d{1,2}\s\w+)\s(\d{4})/, '$1, $2')
}

const convertNextStep = (nextStep: string): ChipNextSteps => {
  switch (nextStep) {
    case 'buildingPersonnelReview':
      return 'Building Personnel Review'
    case 'traneOfficeReview':
      return 'Trane Office Review'
    case 'mechTechOnSite':
      return 'Mech Tech On-Site'
    case 'BASTechOnSite':
      return 'BAS Tech On-Site'
    case 'removeService':
      return 'Remove Service'
    case 'complete':
      return 'Complete'
    case 'archive':
      return 'Archive'
  }
  return 'Archive'
}

export default DetailSubHeader
