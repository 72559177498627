import { Dialog } from 'src/denali-components/Dialog/Dialog'
import { Tabs, TabItem } from '@aws-amplify/ui-react'
import tabStyles from 'src/denali-components/Tabs/tabs.module.scss'
import styles from 'src/denali-components/Dialog/dialog.module.scss'
import { TranslateComponent } from 'src/common/translations'

interface DialogTabbedProps {
  isOpen?: boolean
  onClose?: () => void
  title: string
  actions?: any
  headerActions?: any
  showHeader?: boolean
  className?: string
  width?: string
  dataTestId?: string
  children?: any
  contents: any
}

export const DialogTabbed = ({
  isOpen = true,
  onClose = () => {},
  title = '',
  actions = null,
  headerActions = null,
  showHeader = true,
  className = '',
  width = null,
  dataTestId,
  children,
  contents,
  ...props
}: DialogTabbedProps) => {
  const tabs = getTabs(contents)

  return (
    <Dialog
      title={title}
      onClose={onClose}
      width={width}
      className={styles.dialog}
      data-testid={dataTestId}
    >
      <Tabs
        defaultIndex={0}
        testId="dialogTabs"
        className={tabStyles.denaliDialogTabs}
      >
        {tabs.map((tab, index) => (
          <TabItem
            title={tab.title}
            key={index}
            className={tabStyles.denaliTabItem}
          >
            {tab.content}
          </TabItem>
        ))}
      </Tabs>
    </Dialog>
  )
}

function getTabs(contents) {
  return contents.map((content) => ({
    title: content.header,
    content: content.content.props.children
  }))
}
