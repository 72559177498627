import { SwitchField, SwitchFieldProps } from '@aws-amplify/ui-react'
import styles from './switch.module.scss'

export const Switch = (props: SwitchFieldProps) => {
  return (
    <div className={styles.switchComponent}>
      <SwitchField {...props} />
    </div>
  )
}
