// Default form for new Denali pages/components. Use RHF FormProvider to wrap the form.
import { FormProvider, FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { DenaliFormProps } from './types'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch } from 'src/redux/store'
import styles from './form.module.scss'

/**
 * A reusable form component that handles form validation and managing the state of the data.
 *
 * defaultValues is the initial values of the form.
 * schema is the yup schema that validates the form data.
 * onSubmit is the function that is called when the form is submitted.
 * children is the form fields.
 *
 * If we want to update a redux store, we can use the useAppDispatch hook to dispatch an action.
 *
 * The onSubmit handler is passed the current values from the form and a reset function.
 */
export const DenaliForm = <TFieldValues extends FieldValues>({
  className,
  id,
  schema,
  onSubmit,
  defaultValues,
  updateReduxStore,
  updateReduxStoreAction,
  children,
  values,
  disabled = false,
  resetOptions = { keepDefaultValues: false, keepValues: false }
}: DenaliFormProps<TFieldValues>) => {
  const methods = useForm<TFieldValues>({
    values,
    defaultValues,
    disabled,
    resolver: yupResolver(schema),
    resetOptions
  })

  const dispatch = useAppDispatch()

  const reset = useCallback(() => {
    methods.reset()
  }, [])

  // If updateReduxStore is true, we want to dispatch the updateReduxStoreAction whenever the form values change.
  useEffect(() => {
    if (updateReduxStore) {
      const { unsubscribe } = methods.watch((vals) => {
        dispatch(updateReduxStoreAction(vals as TFieldValues))
      })
      return () => unsubscribe()
    }
  }, [methods.watch])

  const randomId = useMemo(() => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    )
  }, [])

  return (
    <FormProvider {...methods}>
      <form
        id={id ?? `denali-form-${randomId}`}
        className={`${className} ${styles.denaliForm} denali-form`}
        onSubmit={methods.handleSubmit((values) => onSubmit(values, reset))}
      >
        {children}
      </form>
    </FormProvider>
  )
}
