import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { Button as DenaliButton } from '@trane/trane-components/dist/simple/button/index.js'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { useEffect, useState, useMemo } from 'react'
import { useFormik } from 'formik'
import validationSchema from './formValidator'
import SelectBuildings from "./select-buildings"
import { useQuery } from 'src/hooks/APIHooks'
import { ADD_ACCOUNT, GET_BUILDINGS, UPDATE_ACCOUNT, UPDATE_BUILDING, GET_UTILITY_ACCOUNTS_BY_ORG_ID, GET_UTILITY_METERS_BY_ACCOUNT } from "./queries/queries"
import { LIST_OFFERINGS_BY_ACCOUNT } from 'src/pages/contracts-and-offerings/queries'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Table from "src/components/Table/clientSideTable"
import styled from 'styled-components'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from "src/components/Icon"
import { v4 as uuid4 } from 'uuid'
import translate, { TranslateComponent } from 'src/common/translations'
import { isUBMEnabled } from 'src/common/featureFlags'
import { UtilityMessageContainer, UtilityMessageHeader, UtilityMessageTextContainer, UtilityMessage } from './styles'
import { accessControlFunc } from 'src/components/accessControl'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { isOfferingActive } from "../helpers"

const ButtonWrapper = styled.span`
  padding-top: 24px;
`
export const OrgManagementAdd = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : id ? ACTIONS.VIEW : ACTIONS.ADD)
  const [createdId, setCreatedId] = useState(null)
  const isUBMUser = accessControlFunc({ id: "tc.pages.organization-management.utility-bill-management" })

  const orgInput = {
    orgName: "",
    selectedBuildings: []
  }

  useEffect(() => {
    if (createdId) {
      navigate(pages["Organization Management/:id"].href.replace(":id", createdId))
    }
  }, [createdId])

  const [buildings, setBuildings] = useState([])

  const { data: buildingData = [], refetch } = useQuery({
    query: GET_BUILDINGS,
    variables: { filter: { accountId: { eq: id } }, limit: 1000 },
    errorPolicy: 'global',
    dataPath: 'data.searchBuildings.items'
  })

  const { data: offeringData = [], refetch: refetchOfferings } = useQuery({
    query: LIST_OFFERINGS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listOfferingsByAccount.items'
  })

  const { data: utilityAccounts = [], refetch: refetchUtilityAccounts } = useQuery({
    query: GET_UTILITY_ACCOUNTS_BY_ORG_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listUtilityAccountByAccount.items'
  })

  const { data: utilityMeters, refetch: fetchUtilityMeters } = useQuery({
    query: GET_UTILITY_METERS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listUtilityMeterByAccount.items'
  })

  const { onSubmit: createAccount } = useMutation({
    query: ADD_ACCOUNT
  })

  const { onSubmit: updateAccount } = useMutation({
    query: UPDATE_ACCOUNT
  })

  const { onSubmit: updateBuilding } = useMutation({
    query: UPDATE_BUILDING
  })

  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])

  useEffect(() => {
    if (isUBMEnabled && isUBMUser && id) {
      refetchOfferings({ id, limit: 1000 })
    }
  }, [id])

  const showUtilityData = useMemo(() => isUBMUser && offeringData?.filter(offering => offering.code === "UBM" && isOfferingActive(offering)).length > 0, [isUBMUser, offeringData])

  useEffect(() => {
    if (isUBMEnabled && showUtilityData && id) {
      refetchUtilityAccounts({ orgId: id, limit: 1000 })
    }
  }, [id, showUtilityData])

  useEffect(() => {
    if (isUBMEnabled && showUtilityData && id) {
      fetchUtilityMeters({ orgId: id, limit: 1000 })
    }
  }, [id, showUtilityData])

  useEffect(() => {
    if (buildingData && buildingData.length) {
      const buildingsForSetting = buildingData.filter(b => Boolean(b)).map(b => ({ ...b, key: b.id, label: b.name }))
      setBuildings(buildingsForSetting)
      if (mode !== ACTIONS.ADD) {
        formik.setFieldValue("selectedBuildings", buildingsForSetting)
        formik.setFieldValue("orgName", buildingData[0].accountName)
      }
    }
  }, [buildingData])

  const formik = useFormik({
    initialValues: orgInput,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      if (id) {
        if (values?.orgName !== buildingData[0]?.accountName) {
          updateAccount({
            input: {
              name: values.orgName,
              id: id
            }
          })
        }
        const existingBuildings = buildingData?.map(i => i.id) || []
        const newBuildings = values?.selectedBuildings?.map(b => b.id) || []
        const newBuildingsToAdd = buildingData[0].accountName === values.orgName ? newBuildings.filter(nb => !existingBuildings.includes(nb)) : newBuildings
        await Promise.all(await newBuildingsToAdd.map(async b => await updateBuilding({ input: { id: b, accountId: id, accountName: values.orgName } }))).then(() => {
          setTimeout(() => {
            refetch({ filter: { accountId: { eq: id } }, limit: 1000 })
            setMode(ACTIONS.VIEW)
          }, 1000);
        })
      } else {
        const uuid = uuid4();
        await createAccount({ input: { name: values.orgName, id: uuid, accountId: uuid } }).then(async response => {
          await Promise.all(await values.selectedBuildings.map(async b => await updateBuilding({ input: { id: b.id, accountId: response?.data?.createAccount?.id, accountName: values.orgName } }))).then(() => {
            setTimeout(() => {
              setMode(ACTIONS.VIEW)
              setCreatedId(response?.data?.createAccount?.id)
            }, 1000);
          })
        })
      }
    }
  })

  const nameHeadings = [
    {
      name: 'name',
      title: <TranslateComponent>Building Name</TranslateComponent>,
      key: 'name',
      maxWidth: '120px',
      onDataClick: (rowData) => {
        navigate(
          pages['Building Setup/:id'].href.replace(':id', rowData.id)
        )
      },
    },
    {
      name: 'address',
      title: <TranslateComponent>Address</TranslateComponent>,
      key: 'address',
      maxWidth: '120px'
    },
    {
      name: 'city',
      title: <TranslateComponent>City</TranslateComponent>,
      key: 'city',
      maxWidth: '120px'
    },
    {
      name: 'state',
      title: <TranslateComponent>State/Region</TranslateComponent>,
      key: 'state',
      maxWidth: '120px'
    },
    {
      name: 'country',
      title: <TranslateComponent>Country</TranslateComponent>,
      key: 'country',
      maxWidth: '120px'
    },
    {
      name: "actions",
      title: "",
      key: "actions",
      sortField: false,
      disabled: true
    }
  ]

  const { values, errors } = formik

  // Intentionally clearing building id and building name because it doesn't apply to these events
  const amplitudeProperties = { "building name": "", "building id": "", "organization name": values?.orgName, "organization id": id }
  const utilityHeadings = [
    {
      name: 'account',
      title: 'Account',
      key: 'accountName',
      maxWidth: '120px',
      onDataClick: (rowData) => {
        trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ACCOUNT_LINK, amplitudeProperties)
        navigate(pages["Edit Account Details/:id"].href.replace(":orgId", id).replace(":id", rowData.id))
      },
    },
    {
      name: 'provider',
      title: 'Provider Name',
      key: 'providerName',
      maxWidth: '120px'
    },
    // Buildings column will sort by the buildingsCount value, but display custom component
    // of either button or string with number of buildings associated
    {
      name: 'buildingsCount',
      title: 'Buildings',
      key: 'buildingsCount',
      maxWidth: '120px',
      // Use parseInt for buildingsCount because we needed it as a string for sorting
      customComponent: ({ buildingsCount, id }) => getBuildingsValue(parseInt(buildingsCount), id)
    },
  ]

  const getBuildingsValue = (buildingCount = 0, accountId) => !buildingCount || buildingCount === 0
    ? <DenaliButton onClick={() => {
      trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ASSIGN_BUILDINGS, amplitudeProperties)
      navigate(pages["Edit Account Details/:id"].href.replace(":orgId", id).replace(":id", accountId))
    }}
      size="small">Assign Buildings</DenaliButton>
    : `${buildingCount} ${buildingCount !== 1 ? "buildings" : "building"}`

  const utilityRowData = useMemo(() =>
    utilityAccounts?.map(acct =>
    ({
      ...acct,
      accountName: acct.accountName ? acct.accountName : acct.accountNumber,
      // Sorting in table component is done using localeCompare so passing buildingsCount as string
      // Declaring the column as a number type is an option, but then sort is done using month and year, which is confusing/misleading
      buildingsCount: utilityMeters?.filter(meter => meter.buildingId && meter.utilityAccountId === acct.id)?.length?.toString()
    })) ?? []
    , [utilityAccounts, utilityMeters])

  const buildingsTranslate = translate("Buildings")
  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <>
            <TextInput
              style={{ width: "260px" }}
              labelText="Organization Name"
              defaultValue={values?.orgName}
              name="name"
              onChange={(e => formik.setFieldValue("orgName", e.target.value))}
              hasError={errors?.['orgName']}
              errorMessage={"The name is required."}
            />
          </> : values?.orgName
          }
          titleTools={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ?
            <ButtonWrapper>
              <Button type="primary" onClick={() => {
                formik.handleSubmit()
                //Navigate to the details page here
              }
              }>
                <TranslateComponent>Save</TranslateComponent>
              </Button>
              <Button type="secondary" onClick={() => mode === ACTIONS.EDIT ? setMode(ACTIONS.VIEW) : navigate(pages["Organization Management"].href)}>
                <TranslateComponent>Cancel</TranslateComponent>
              </Button>
            </ButtonWrapper>
            : !isUBMEnabled && <IconSvg
              name={"edit"}
              margin="0px 0px 0px 10px"
              color="#666"
              hover="#666"
              width="15px"
              onClick={() => {
                // formik.handleSubmit()
                //Navigate to the details page here
                setMode(ACTIONS.EDIT)
              }}
            />
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        display={![ACTIONS.ADD, ACTIONS.EDIT].includes(mode) && "flex"}
      >
        {
          [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <div>
            <SelectBuildings
              initialValues={values?.selectedBuildings}
              onButtonClick={(e) => {
                const newSelectedBuildings = [...e]
                formik.setFieldValue("selectedBuildings", newSelectedBuildings)
                setBuildings(buildings.filter(b => !newSelectedBuildings.map(e => e.id).includes(b.id)))
              }}
              title={"Add Building to Organization"}
              description={"You must have permissions for both organizations to move buildings between organizations, adding a building to this organization will remove it from other organizations."}
              sideText={<><TranslateComponent>Create new buildings in</TranslateComponent> <Link to="/building-setup/add"><TranslateComponent>Building Setup</TranslateComponent></Link></>}
              buttonTitle={"Add"}
            />
          </div>
            : ""
        }

        {
          isUBMEnabled && ![ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ?
            <Table
              key="locationDevicesTable"
              rows={values.selectedBuildings}
              header={nameHeadings}
              disableTableScroll={false}
              search={false}
              containerWidth={isUBMEnabled && showUtilityData ? "50%" : "100%"}
              containerPadding="0 10px 0 0"
              fixedLayout={false}
              title={buildingsTranslate}
              action={mode === ACTIONS.EDIT || !isUBMEnabled ? null : () => {
                // formik.handleSubmit()
                //Navigate to the details page here
                setMode(ACTIONS.EDIT)
              }}
              actionText={<TranslateComponent>Edit Buildings</TranslateComponent>}
              actionButtonType="ghost"
              actionButtonSize="small"
              iconType="edit"
            />
            : <Table
              key="locationDevicesTable"
              rows={values.selectedBuildings}
              header={nameHeadings}
              search={false}
            />
        }
        {
          isUBMEnabled &&
          (showUtilityData && ![ACTIONS.ADD, ACTIONS.EDIT].includes(mode) &&
            (utilityRowData.length ?
              <Table
                key="utilityAccountsTable"
                rows={utilityRowData}
                header={utilityHeadings}
                disableTableScroll={false}
                search={false}
                containerWidth="50%"
                containerPadding="0 0 0 10px"
                fixedLayout={false}
                title="Utility Accounts"
                action={() => {
                  trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_EDIT_ACCOUNTS, amplitudeProperties)
                  navigate(pages["Associate Utility Accounts/:id"].href.replace(":id", id))
                }}
                actionText="Edit Accounts"
                actionButtonType="ghost"
                actionButtonSize="small"
                iconType="edit"
              />
              :
              <UtilityMessageContainer>
                <UtilityMessageHeader>Utility Accounts</UtilityMessageHeader>
                <UtilityMessageTextContainer>
                  <UtilityMessage>No utility accounts have been associated with this organization.</UtilityMessage>
                  <DenaliButton onClick={() => {
                    trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ASSOCIATE_UTILITY_ACCOUNTS, amplitudeProperties)
                    navigate(pages["Associate Utility Accounts/:id"].href.replace(":id", id))
                  }}>Associate Utility Accounts</DenaliButton>
                </UtilityMessageTextContainer>
              </UtilityMessageContainer>
            )
          )
        }
      </Content>
    </Container>
  )
}
