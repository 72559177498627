import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

// Legacy imports
import InlineHelp from 'src/components/legacy/components/inline-help/inline-help'

// Project imports
import translate, { TranslateComponent } from 'src/common/translations'
import { AccessControl } from 'src/components/accessControl'
import { PdfDownloadDetailViewPages } from 'src/components/print-components/helpers'
import PdfDownloadDetailView from 'src/components/print-components/pdf-download-detail-view/pdf-download-detail-view'
import { ACTIONS, MODAL_TYPE } from 'src/constants'

// Denali imports
import {
  faBullseyeArrow,
  faEllipsisVertical,
  faNote
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { CreateOpportunityButton } from 'src/denali-pages/Opportunities/CreateOpportunityButton'
import { getRowActionsSetIcon } from 'src/denali-components/Table/RowActions'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'
import { CardDetails } from 'src/denali-components/Cards/CardDetails/CardDetails'
import { FileBadge } from 'src/denali-components/File/FileBadge'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'

// Local imports
import { DetailCostSavings } from './OpportunityDetail/DetailCostSavings'
import { DetailFindings } from './OpportunityDetail/DetailFindings'
import { DetailImpact } from './OpportunityDetail/DetailImpact'
import { DetailSubHeader } from './OpportunityDetail/DetailSubHeader'
import styles from 'src/denali-components/Page/detailPage.module.scss'

export const OpportunityDetailContainer = ({
  opportunity,
  loading,
  inlineHelpText,
  handleCreateOpportunityClicked,
  uiMode,
  costOfWaiting,
  pdfOpportunity,
  deleteOpportunitySetup,
  deleteOpportunityHandler,
  modalType,
  hideModalDialog
}) => {
  const navigate = useNavigate()
  const controls = getControls(navigate, deleteOpportunitySetup)
  const costSavingsPath = opportunity?.settings?.costSavings

  return (
    <Page
      title={translate('Opportunities')}
      titleTools={
        <InlineHelp inlineHelpText={inlineHelpText} uiMode={uiMode} />
      }
      icon={<FontAwesomeIcon icon={faBullseyeArrow} />}
      iconColor={IconColor.orange}
      actions={getActions(
        pdfOpportunity,
        opportunity,
        handleCreateOpportunityClicked,
        controls,
        uiMode
      )}
    >
      <DetailSubHeader
        title={opportunity?.name}
        priority={opportunity?.settings?.priority}
        isPublic={opportunity?.isPublic}
        buildingName={opportunity?.location?.locationName}
        tisObjects={opportunity?.tisObjects}
        dateCreated={opportunity?.settings?.proposalDate}
        status={opportunity?.status}
        nextStep={opportunity?.settings?.nextStep}
      />
      <DetailCostSavings
        costSavings={costSavingsPath?.savings}
        costToImplement={costSavingsPath?.costToImplement}
        simpleROI={costSavingsPath?.roiCalculation}
        simplePayback={costSavingsPath?.paybackCalculation}
        // showPotentialSavings={costSavingsPath?.costOfWaiting?.value}
        potentialSavings={costOfWaiting}
      />
      {opportunity.settings.kpi.filter((kpi) => kpi.value).length > 0 && (
        <DetailImpact opportunity={opportunity} />
      )}
      <div className={styles.detailRow}>
        {(opportunity?.findings?.length > 0 ||
          opportunity?.settings?.images?.length > 0) && (
          <DetailFindings
            images={
              opportunity.settings.images ? opportunity.settings.images : []
            }
            findings={opportunity.findings}
          />
        )}

        {opportunity.settings?.description?.blocks.length > 0 && (
          <CardDetails
            style="notes"
            title="Description"
            icon={<FontAwesomeIcon icon={faNote} />}
            iconColor="gray"
            description={opportunity.settings?.description?.blocks.map(
              (block) => block.text
            )}
          />
        )}
      </div>
      {opportunity.settings.file && opportunity.settings.file.id && (
        <div className={styles.detailColumn}>
          <span className={styles.detailHeading}>
            <TranslateComponent>Attachments</TranslateComponent>
          </span>
          <div className={styles.detailRow}>
            <FileBadge
              fileId={opportunity.settings.file && opportunity.settings.file.id}
              fileFullName={opportunity.settings.file.displayName}
              name={opportunity.settings.file.name}
              buildingId={opportunity.settings.file.buildingId}
              fileSize={''} // Doesn't exist in the Opportunites data
              description={opportunity.settings.file.description}
              showDate={false}
              showDescription={false}
              showPreviewButton={false}
              downloadButton={'icon'}
              deleteFile={false}
            />
          </div>
        </div>
      )}
      {modalType === MODAL_TYPE.CONFIRMATION && (
        <DialogConfirm
          onAgree={() => setTimeout(deleteOpportunityHandler, 500)}
          title={'Delete Opportunity'}
          onClose={hideModalDialog}
        >
          {renderConfirmationText(modalType)}
        </DialogConfirm>
      )}
    </Page>
  )
}

const renderConfirmationText = (modalType) => {
  switch (modalType) {
    case MODAL_TYPE.CONFIRMATION:
      return 'Are you sure you want to delete this Opportunity?'
    default:
      return ''
  }
}

const getActions = (
  pdfOpportunity,
  opportunity,
  handleCreateOpportunityClicked,
  controls,
  uiMode
) => {
  return [
    <Tooltip key="downloadPDF">
      <TooltipTrigger>
        <PdfDownloadDetailView
          downloadData={pdfOpportunity}
          type={PdfDownloadDetailViewPages.OPPORTUNITY}
          uiMode={uiMode}
        />
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>Download</TranslateComponent>
      </TooltipContent>
    </Tooltip>,
    <CreateOpportunityButton
      key={'create'}
      title={'Create New'}
      amplifyButton
      buildingId={opportunity?.location?.locationId}
      accountId={opportunity?.organization?.organizationId}
      handleCreateOpportunityClicked={handleCreateOpportunityClicked}
    />,
    // Created By
    <UserNameBubble
      key="CreatedBy"
      showFullName={false}
      showTooltip
      tooltipContent={`Created by ${opportunity?.createdBy}`}
      firstName={opportunity?.createdBy}
      lastName={''}
      subLine={''}
      iconOnly
    />,
    // Assigned To
    <UserNameBubble
      key="AssignedTo"
      showFullName={false}
      showTooltip
      tooltipContent={
        opportunity?.assignedTo?.key
          ? `Assigned to ${opportunity.assignedTo.key}`
          : 'Unassigned'
      }
      firstName={
        opportunity?.assignedTo?.key
          ? opportunity?.assignedTo.key
          : 'Unassigned'
      }
      lastName={''}
      subLine={''}
      iconOnly
      assignedTo
    />,
    <Menu
      key="OpportunitiesDetailsActions"
      trigger={
        <MenuButton
          className="button-gray button-icon"
          data-testid="OpportunitiesDetailsActions"
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </MenuButton>
      }
    >
      {controls.map((item) => (
        <AccessControl
          key={item.text}
          id={`tc.pages.opportunities.${
            item.text === 'Copy' ? 'add' : item.text.toLowerCase()
          }`}
        >
          <MenuItem
            key={item.text}
            onClick={() => item.action(opportunity)}
            className={`amplify-button__actions__` + item.text.toLowerCase()}
          >
            <FontAwesomeIcon icon={getRowActionsSetIcon(item.text)} />
            <TranslateComponent>{item.text}</TranslateComponent>
          </MenuItem>
        </AccessControl>
      ))}
    </Menu>
  ]
}

const getControls = (navigate, deleteOpportunitySetup) => {
  return [
    {
      text: 'Edit',
      action: (opportunity) => {
        navigate(`/opportunities/${opportunity?.opportunityId}`, {
          state: { mode: ACTIONS.EDIT, id: opportunity?.opportunityId }
        })
      }
    },
    {
      text: 'Copy',
      action: (opportunity) => {
        navigate(`/opportunities/${opportunity?.opportunityId}`, {
          state: { mode: ACTIONS.COPY, id: opportunity?.opportunityId }
        })
      }
    },
    {
      text: 'Delete',
      action: () => {
        deleteOpportunitySetup()
      }
    }
  ]
}

export default OpportunityDetailContainer
