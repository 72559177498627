import { Button } from 'src/components/inputs/button'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import {
  MapContainer,
  Status,
  StatusText,
  DownloadSuccessNote,
  UpdatedTimeMessage,
  TimestampContainer,
  MessageLabel,
  MessageValueLabel
} from './style'
import {
  startMapDownload,
  mapDownloadStatusFromConfigrationHistory,
  getConfigurationMessageQuery,
  getDeviceQuery,
  listConfigurationSuccessMessageQuery
} from './graphql'
import Icon from 'src/components/Icon'
import { useEffect, useState } from 'react'
import COLORS from 'src/components/legacy/common/colors.json'
import { useMemo, useRef } from 'react'
import moment from 'moment/moment'
import { TEXT_DATE_FORMAT_WITH_MINUTES } from 'src/components/legacy/common/time-helpers'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { formatServerLocalTime } from '../helper'
import { TranslateComponent } from '../../../../common/translations'
import { getDateFormatByLocale } from '../../../../common/chartHelperFunctions'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'

const POLLING_TIME = 60000

const MapDownload = ({
  equipmentDetails,
  loadingEquipmentDetails,
  equipAssocatedDeviceDetails
}) => {
  let intervalId = useRef(null)

  const [mapDownloadState, setMapDownloadState] = useState('success')

  const [isPollingTimedOut, setIsPollingTimedOut] = useState(false)

  const [startPollingTime, setStartPollingTime] = useState(null)

  const [lastestMapdownloadTime, setLatestMapdownloadTime] = useState('--')

  const [
    loadingSuccessHistory,
    refetchSuccessHistory,
    mapdownloadSuccessHistory
  ] = useAPIPagination(
    listConfigurationSuccessMessageQuery,
    'data.listConfigurationMessageByDevice'
  )

  const { refetch: getConfigurationMessageHistory, loading } = useQuery({
    query: mapDownloadStatusFromConfigrationHistory,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listConfigurationMessageHistoryByDevice.items'
  })

  const { refetch: getConfigurationMessage } = useQuery({
    query: getConfigurationMessageQuery,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getConfigurationMessage'
  })

  const {
    data: deviceDetails,
    refetch: refetchDeviceDetails,
    loading: loadingDeviceDetails
  } = useQuery({
    query: getDeviceQuery,
    variables: {
      id: equipAssocatedDeviceDetails?.id
    },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDevice'
  })

  const lastDataCollectionTimestamp = useMemo(() => {
    try {
      if (deviceDetails) {
        // connectionTimeLocal - is not UTC, but stored in UTC format in backend
        // so remove the UTC format and format again
        const lastCollectedTime =
          deviceDetails?.deviceConnection.items?.[0]?.connectionTimeLocal
        const formattedTime = formatServerLocalTime(lastCollectedTime)
        return formattedTime || '--'
      } else {
        return '--'
      }
    } catch (e) {
      return '--'
    }
  }, [deviceDetails])

  // Have to update last updated map download
  const lastMapDownloadUpdateTimestampHandler = (mapDownloadStatusResponse) => {
    try {
      const timezone = deviceDetails?.building?.tz || moment.tz.guess()
      if (mapDownloadStatusResponse && mapDownloadStatusResponse?.length > 0) {
        const downloadStatus = mapDownloadStatusResponse?.find(
          (x) => x?.messageStatus?.toLowerCase() === 'success'
        )
        if (downloadStatus) {
          const lastUpdatedTime =
            downloadStatus?.updatedAt || downloadStatus?.createdAt
          const formattedTime = lastUpdatedTime
            ? moment(lastUpdatedTime)
                .tz(timezone)
                ?.format(
                  getDateFormatByLocale(TEXT_DATE_FORMAT_WITH_MINUTES)
                ) || '--'
            : '--'
          setLatestMapdownloadTime(formattedTime)
        } else {
          setLatestMapdownloadTime('--')
        }
      } else {
        setLatestMapdownloadTime('--')
      }
    } catch (e) {
      setLatestMapdownloadTime('--')
    }
  }

  useEffect(() => {
    // If the latest map download is not success
    // get the last successful mapdownload and show the date
    if (mapdownloadSuccessHistory && mapdownloadSuccessHistory?.length > 0) {
      const sortedItems = mapdownloadSuccessHistory?.sort(
        (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
      )
      lastMapDownloadUpdateTimestampHandler([sortedItems?.[0]])
    }
  }, [mapdownloadSuccessHistory])

  const { onSubmit: startMapDownloadMutation, loading: loadingStartDownload } =
    useMutation({
      query: startMapDownload,
      variables: { equipmentId: equipmentDetails?.id },
      disableInitialLoad: true,
      errorPolicy: 'global',
      dataPath: 'data'
    })

  // Get device details to know last map download status
  useEffect(() => {
    if (equipAssocatedDeviceDetails?.id) {
      refetchDeviceDetails()
    }
    return () => {
      stopPolling()
    }
  }, [equipmentDetails, equipAssocatedDeviceDetails])

  const mapDownloadStatusMessage = useMemo(() => {
    if (mapDownloadState) {
      if (mapDownloadState === 'success') {
        return 'Data Map matches Data Collection'
      } else if (mapDownloadState === 'error') {
        return 'Map download Failed'
      } else if (mapDownloadState === 'retry') {
        return 'Something went wrong, please try again later'
      } else {
        return 'Map download is in progress. 1 of 2 steps have been completed'
      }
    }
  }, [mapDownloadState])

  const getMapdownloadStatusFromConfiguration = async () => {
    try {
      // if the message is success, only then get the configuration history message to get timestamp
      // last map download status is received
      const lastMapDownloadStatus =
        deviceDetails?.recentConfigurationMessageStatus
          ?.split('#')?.[1]
          ?.toLowerCase() || ''

      if (
        lastMapDownloadStatus !== 'success' &&
        lastestMapdownloadTime === '--' &&
        equipmentDetails?.deviceId
      ) {
        refetchSuccessHistory({ deviceId: equipmentDetails?.deviceId })
      }

      if (lastMapDownloadStatus === 'success') {
        setMapDownloadState('success')
        const configurationMessageGroupId =
          deviceDetails?.recentConfigurationMessageStatus?.split('#')?.[0]
        if (configurationMessageGroupId) {
          const mapDownloadStatus = await getConfigurationMessage({
            id: configurationMessageGroupId
          })
          if (mapDownloadStatus?.updatedAt) {
            lastMapDownloadUpdateTimestampHandler([mapDownloadStatus])
          } else {
            const mapDownloadStatusHistory = await getConfigurationMessageHistory({
              deviceId: equipmentDetails?.deviceId,
              configurationMessageGroupId: configurationMessageGroupId
            })
            lastMapDownloadUpdateTimestampHandler(mapDownloadStatusHistory)
          }
        }
        stopPolling()
      } else if (lastMapDownloadStatus === 'created') {
        setMapDownloadState('created')
        if (!startPollingTime) {
          setStartPollingTime(new Date().getTime?.())
          startPolling()
        } else {
          if ((new Date().getTime() - startPollingTime) / 1000 > 300) {
            setIsPollingTimedOut(true)
            stopPolling()
          }
        }
      } else if (lastMapDownloadStatus === 'retry') {
        setMapDownloadState('retry')
        stopPolling()
      } else if (lastMapDownloadStatus === 'failed') {
        setMapDownloadState('error')
        stopPolling()
      }
    } catch (e) {
      setMapDownloadState('error')
    }
  }

  useEffect(() => {
    if (deviceDetails) {
      // gets and sets map download status and latest updated date
      // As on Feb-6, 2024 and DXTC-4127
      // Configuration messages are moved to a new table - Configuration Message
      // So old map download status will be from ConfigurationMessageHistory,
      // After Feb-6, Mapdownload status will be stored in ConfigurationMessage
      // So this change handled in UI, like if we don't find record in ConfigurationMessage
      // fetch from ConfigurationMessageHistory
      getMapdownloadStatusFromConfiguration()
    }
  }, [deviceDetails])

  const startPolling = () => {
    setIsPollingTimedOut(false)
    intervalId.current = setInterval(() => {
      equipAssocatedDeviceDetails?.id && refetchDeviceDetails()
    }, POLLING_TIME)
  }

  const stopPolling = () => {
    try {
      clearInterval(intervalId.current)
      intervalId.current = null
    } catch (error) {}
  }

  const startMapDownloadHandler = async () => {
    try {
      // have to start new time stamp and have to call status for next 2 mins
      setStartPollingTime(new Date().getTime?.())
      await startMapDownloadMutation({
        equipmentId: equipmentDetails?.id
      })
      await refetchDeviceDetails()
      startPolling()
    } catch (error) {
      setMapDownloadState('error')
    }
  }

  return (
    <MapContainer>
      {equipmentDetails?.device?.type === 'LEC' ? (
        <UpdatedTimeMessage>
          <MessageLabel className="message-label">
            <TranslateComponent>Last Data Collection</TranslateComponent>:
          </MessageLabel>
          <MessageValueLabel className="message-value">
            {lastDataCollectionTimestamp !== '--'
              ? moment(lastDataCollectionTimestamp).format(
                  getDateFormatByLocale('MMM D, YYYY h:mm A')
                )
              : '--'}
          </MessageValueLabel>
        </UpdatedTimeMessage>
      ) : (
        <>
          <h2>
            <TranslateComponent>Map Download</TranslateComponent>
          </h2>
          <Status>
            {mapDownloadState !== 'success' && (
              <Icon
                name="close"
                height="100%"
                width="13px"
                color={COLORS.RED}
                hover={COLORS.RED}
              />
            )}
            {mapDownloadState === 'success' && (
              <Icon
                name="check"
                height="100%"
                width="13px"
                color={COLORS.GREEN}
                hover={COLORS.GREEN}
              />
            )}
            <StatusText>
              <TranslateComponent>
                {mapDownloadStatusMessage}
              </TranslateComponent>
            </StatusText>
            {mapDownloadState === 'created' && isPollingTimedOut && (
              <button
                className="icon-loader"
                title="Check map download status"
                style={{
                  marginTop: '6px',
                  marginLeft: '0px',
                  background: 'none',
                  border: 'none'
                }}
                onClick={() => startPolling()} // TO DO
              />
            )}
            <button
              className={`${
                !equipmentDetails?.id || loadingStartDownload ? 'disabled' : ''
              } map-download-button`}
              style={{ marginLeft: 5 }}
              testName={DETAILS_TEXT_NAME_PREFIX('mapDwnld')}
              onClick={startMapDownloadHandler}
            >
              <TranslateComponent>Start Download</TranslateComponent>
            </button>
          </Status>
          {mapDownloadState === 'success' && (
            <DownloadSuccessNote>
              <TranslateComponent>
                {' '}
                All data has been updated with the latest collection results
              </TranslateComponent>
            </DownloadSuccessNote>
          )}

          <TimestampContainer>
            <UpdatedTimeMessage>
              <MessageLabel className="message-label">
                <TranslateComponent>
                  Mapping Status Last Updated
                </TranslateComponent>
                :
              </MessageLabel>
              <MessageValueLabel
                className="message-value"
                style={{ textTransform: 'capitalize' }}
              >
                {lastestMapdownloadTime}
              </MessageValueLabel>
            </UpdatedTimeMessage>
            <UpdatedTimeMessage>
              <MessageLabel className="message-label">
                <TranslateComponent>Last Data Collection</TranslateComponent>:
              </MessageLabel>
              <MessageValueLabel
                className="message-value"
                style={{ textTransform: 'capitalize' }}
              >
                {lastDataCollectionTimestamp}
              </MessageValueLabel>
            </UpdatedTimeMessage>
          </TimestampContainer>
        </>
      )}
    </MapContainer>
  )
}
export default MapDownload
