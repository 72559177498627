import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'

export { isClickOrKeyboardActivate, handleKeyboardActivate, getColor }

function handleKeyboardActivate(event, callback, params = []) {
  if (isClickOrKeyboardActivate(event)) {
    callback(event, ...params)
  }
}

function isClickOrKeyboardActivate(event) {
  return !event?.code || event?.code === 'Enter' || event?.code === 'Space'
}

function getColor(key: string) {
  if (!key || key === '') return 'green'
  const colorMap = {
    a: 'orange',
    b: 'orange',
    c: 'orange',
    d: 'orange',
    e: 'red',
    f: 'red',
    g: 'red',
    h: 'red',
    i: 'green',
    j: 'green',
    k: 'green',
    l: 'green',
    m: 'turquoise',
    n: 'turquoise',
    o: 'turquoise',
    p: 'blue',
    q: 'blue',
    r: 'blue',
    s: 'blue',
    t: 'blue',
    u: 'purple',
    v: 'purple',
    w: 'purple',
    x: 'purple',
    y: 'pink',
    z: 'pink'
  }
  const color = colorMap[key[0]?.toLocaleLowerCase()]
  return color || 'turquoise'
}

export const randomizeString = (string: string) => {
  return string
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('')
}

export const useDebounce = (
  callback: () => void,
  wait = 300,
  debounceOptions = {}
) => {
  const ref = useRef(() => undefined)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, wait, debounceOptions)
  }, [])

  return debouncedCallback
}

export const handleScrollFade = ({
  element,
  setActiveScroll,
  selector,
  offset = 10,
  threshold
}: HandleScrollFadeProps) => {
  // Set the "wrapper" as either element?.current if it's a ref, or the element itself if it's an HTMLElement
  let wrapper = null
  let selectedElement = null
  if ('current' in element) {
    wrapper = element?.current
  } else {
    wrapper = element
  }

  // Now we want to select a different element based on the wrapper element we can either
  // use a function or we grab the first child element.
  if (selector) {
    selectedElement = selector(wrapper)
  }

  // If we selected another element, we want to see if that element is scrolled more than the 'wrapper'
  // element's height.
  let bottomOffset = null
  if (selectedElement) {
    bottomOffset = selectedElement?.clientHeight - wrapper?.scrollTop
    setActiveScroll(bottomOffset - offset >= wrapper?.clientHeight)
  } else {
    setActiveScroll(wrapper?.scrollTop >= threshold)
  }
}

export type HandleScrollFadeProps = {
  // The element to listen for scroll events on.
  element: React.RefObject<HTMLElement> | HTMLElement
  // The function to call when the scroll event is detected.
  setActiveScroll: Dispatch<SetStateAction<boolean>>
  // The offset applied to the scroll position to trigger the fade. Defaults to 10.
  offset?: number
  // The selector to use to find if we have "scrolled" passed the threshold.
  selector?: (
    element: HTMLElement
  ) => React.RefObject<HTMLElement> | HTMLElement
  // The target to use to if we don't have a selector.
  threshold?: number
}
