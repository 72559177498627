import styles from 'src/denali-components/Table/table.module.scss'
import { Button } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp
} from 'src/denali-components/lib/pro-solid-svg-icons'

export const RowExpandButton = ({ subTableOpen, setSubTableState }) => (
  <Button
    className={styles.tableRowExpandArrow}
    name="ExpandSubTable"
    onClick={(e) => {
      e.stopPropagation()
      setSubTableState(!subTableOpen)
    }}
  >
    <FontAwesomeIcon icon={subTableOpen ? faAngleUp : faAngleDown} />
  </Button>
)
