import { Button, Flex } from '@aws-amplify/ui-react'
import { useCallback, useMemo } from 'react'

// Denali imports
import { Dialog } from 'src/denali-components/Dialog/Dialog'

// Project imports
import { TranslateComponent } from 'src/common/translations'
import { MODAL_TYPE } from 'src/pages/issuesFindings/add-opportunities-to-finding'

// Local imports
import styles from '../issues-findings.module.scss'
import { useIssuesAndFindingsAddToOpportunityDialogContext } from '../hooks/use-issues-findings-add-to-opportunity-dialog-context'
import { IssuesAndFindingsAddToOpportunityDialogBody } from './IssuesAndFindingsAddToOpportunityDialogBody'

export const IssuesAndFindingsAddToOpportunityDialog = () => {
  const {
    closeDialog,
    modalType,
    setModalType,
    optionType,
    opportunityName,
    setOpportunityNameError,
    createOpportunity,
    updateOpportunity
  } = useIssuesAndFindingsAddToOpportunityDialogContext()

  const buttonText = useMemo(() => {
    switch (modalType) {
      case MODAL_TYPE.INITIAL:
        return 'Next'
      case MODAL_TYPE.EXISTING:
      case MODAL_TYPE.NEW:
        return 'Save'
    }
  }, [modalType])

  const saveHandler = useCallback(() => {
    switch (modalType) {
      case MODAL_TYPE.INITIAL:
        setModalType(optionType)
        break
      case MODAL_TYPE.NEW:
        if (opportunityName.length < 1) {
          setOpportunityNameError('The name is required')
          break
        }
        createOpportunity(opportunityName)
      case MODAL_TYPE.EXISTING:
        updateOpportunity()
        break
    }
  }, [modalType, optionType, opportunityName])

  return (
    <Dialog
      title="Add to an Opportunity"
      className={styles.addToOpportunityModal}
      onClose={closeDialog}
      width="fit-content"
      height={modalType === MODAL_TYPE.EXISTING ? '75vh' : null}
      actions={
        <Flex direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            className="button-gray button-icon-left"
            onClick={closeDialog}
          >
            <TranslateComponent>Cancel</TranslateComponent>
          </Button>
          <Button onClick={saveHandler}>
            <TranslateComponent>{buttonText}</TranslateComponent>
          </Button>
        </Flex>
      }
    >
      <IssuesAndFindingsAddToOpportunityDialogBody />
    </Dialog>
  )
}
