import styles from 'src/denali-components/ImageGallery/image-gallery.module.scss'
import { Button } from '@aws-amplify/ui-react'
import { TranslateComponent } from 'src/common/translations'

export const DetailFindingDescription = (finding) => {
  const currentFinding = finding.finding

  return (
    <>
      <div className={styles.imageGalleryBlur}></div>
      <div className="image-gallery-description">
        <div className={styles.imageGalleryDescriptionWrapper}>
          {currentFinding?.images?.length !== 0 && (
            <div className={styles.imageGalleryDescriptionContent}>
              <span className={styles.imageGalleryDescriptionTitle}>
                <TranslateComponent>
                  {currentFinding?.images[0].title}
                </TranslateComponent>
              </span>
              <span>
                <TranslateComponent>
                  {currentFinding?.images[0].description}
                </TranslateComponent>
              </span>
            </div>
          )}
          <Button
            onClick={() =>
              (window.location.href = `/findings/${currentFinding.findingId}`)
            }
          >
            <TranslateComponent>View Findings</TranslateComponent>
          </Button>
        </div>
      </div>
    </>
  )
}
