export const GET_UTILITY_ACCOUNT = `
  query getUtilityAccount($utilityAccountId: ID!) {
    getUtilityAccount(id: $utilityAccountId) {
      accountEmail
      accountId
      id
      billingAddress
      accountNumber
      accountName
      providerName
    }
  }
`

export const GET_UTILITY_METERS = `
  query getUtilityMeters($utilityAccountId: ID!, $nextToken: String) {
    listUtilityMeterByUtilityAccount(utilityAccountId: $utilityAccountId, nextToken: $nextToken, limit: 1000) {
      nextToken
      items {
        id
        meterNumber
        lastStatementDate
        buildingId
        serviceAddress
        serviceType
      }
    }
  }
`

export const GET_UTILITY_STATEMENTS = `
  query getUtilityStatements($orgId: ID!, $nextToken: String) {
    listUtilityStatementByAccount(accountId: $orgId, nextToken: $nextToken, limit: 1000) {
      nextToken
      items {
        month
        id
        statementDate
        totalDue
        dueDate
        utilityAccountIds
      }
    }
  }
`

export const GET_BUILDING = `
  query getBuilding($id: ID!) {
    getBuilding(id: $id) {
      id
      isActive
      value: name
    }
  }
`

export const UPDATE_ACCOUNT_DISPLAY_NAME = `
  mutation updateUtilityAccount($utilityAccountId: ID!, $accountName: String) {
    updateUtilityAccount(input: {id: $utilityAccountId, accountName: $accountName}) {
      id
      accountName
    }
  }
`

export const UPDATE_METER_ASSOCIATION = `
  mutation updateUtilityMeter($utilityMeterId: ID!, $buildingId: ID) {
    updateUtilityMeter(input: {id: $utilityMeterId, buildingId: $buildingId}) {
      id
    }
  }
`
