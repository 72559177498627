import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import { useNotifications } from 'src/components/top-menu/components/notification-list/hooks/useNotifications'
import {
  GET_TOTAL_UNREAD_NOTIFICATIONS_COUNT,
  SEARCH_NOTIFICATIONS_ACTIVE
} from 'src/components/top-menu/graphql'
import { usePrevious } from 'src/hooks'
import { useQuery } from 'src/hooks/APIHooks'
import { selectUiMode, selectUserInfo } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { Dialog } from '../Dialog/Dialog'
import NotificationList from 'src/components/top-menu/components/notification-list/index.jsx'
import { useTranslation } from 'react-i18next'
import { TranslateComponent } from 'src/common/translations'
import { Button } from '@aws-amplify/ui-react'
import { useInterval } from 'src/hooks/useInterval'
import { useNavigate } from 'react-router'

export const NotificationsDisplay = ({ isOpen, setIsOpen }) => {
  const intervalListerner = useInterval({ interval: 600000 })
  const uiMode = useSelector(selectUiMode)
  const isDenaliTheme = uiMode === UiMode.denali
  const user = useSelector(selectUserInfo)
  const {
    data: notificationCount,
    refetch: refetchTotalUnreadNotificationsCount
  } = useQuery({
    query: GET_TOTAL_UNREAD_NOTIFICATIONS_COUNT,
    variables: { userId: user.id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchNotificationActives.total'
  })
  useEffect(() => {
    if (!user.id) return
    refetchTotalUnreadNotificationsCount({ userId: user.id })
  }, [user])

  useEffect(() => {
    if (intervalListerner && user.id) {
      refetchTotalUnreadNotificationsCount({ userId: user.id })
    }
  }, [intervalListerner, user])

  return isDenaliTheme ? (
    <NotificationDialog
      intervalListerner={intervalListerner}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      notificationCount={notificationCount}
    />
  ) : (
    <NotificationPanel
      intervalListerner={intervalListerner}
      setIsOpen={setIsOpen}
      notificationCount={notificationCount}
    />
  )
}

function NotificationDialog({
  isOpen,
  setIsOpen,
  notificationCount,
  intervalListerner
}) {
  const [t] = useTranslation()
  const navigate = useNavigate()

  return createPortal(
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      title={t('Notifications')}
      data-testid="notificationDialog"
      headerActions={
        <>
          {notificationCount && (
            <TranslateComponent>{`${notificationCount} total`}</TranslateComponent>
          )}
          <Button
            onClick={() => {
              navigate('/notification-settings')
            }}
          >
            <TranslateComponent>Settings</TranslateComponent>
          </Button>
        </>
      }
    >
      <div className="denali-notifications">
        <NotificationPanel
          intervalListerner={intervalListerner}
          setIsOpen={setIsOpen}
          notificationCount={notificationCount}
        />
      </div>
    </Dialog>,
    document.getElementById('app')
  )
}

function NotificationPanel({
  setIsOpen,
  notificationCount,
  intervalListerner
}) {
  // Migrated from src/components/top-menu/index.jsx
  const userInfo = useSelector(selectUserInfo)
  const { handleNotifications, notificationList } = useNotifications()
  const [nextNotificationsToken, setNextNotificationsToken] = useState('')
  const [selectedNotificationStatus, setSelectedNotificationStatus] =
    useState(null)
  const previousSelectedNotificationStatus = usePrevious(
    selectedNotificationStatus
  )
  const { refetch: refetchNotifications } = useQuery({
    query: SEARCH_NOTIFICATIONS_ACTIVE,
    variables: { userId: userInfo.id },
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  useEffect(() => {
    if (!userInfo.id) return
    processNotifications()
  }, [userInfo])

  useEffect(() => {
    if (intervalListerner && userInfo.id) {
      processNotifications({ reset: true })
    }
  }, [intervalListerner, userInfo])

  const processNotifications = async ({
    nextToken = '',
    reset = null,
    filter = null
  } = {}) => {
    if (nextToken === null) return

    const { data } = await refetchNotifications({
      userId: userInfo.id,
      ...(nextToken ? { nextToken } : {}),
      filter
    })

    if (!data || data?.errors) return

    const notifications = data?.listNotificationActiveByUser?.items || []
    await handleNotifications(notifications, reset)
    setNextNotificationsToken(data?.listNotificationActiveByUser?.nextToken)
  }

  return (
    <NotificationList
      userId={userInfo?.id}
      onCloseDropdown={() => setIsOpen(false)}
      notificationCount={notificationCount}
      onToggle={() => setIsOpen((state) => !state)}
      notifications={notificationList || []}
      nextNotificationsToken={nextNotificationsToken}
      selectedNotificationStatus={selectedNotificationStatus}
      setSelectedNotificationStatus={setSelectedNotificationStatus}
      previousSelectedNotificationStatus={previousSelectedNotificationStatus}
      refetchNextNotifications={processNotifications}
    />
  )
}
