import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import styles from './page-error.module.scss'
import { TranslateComponent } from 'src/common/translations'

export const PageError = ({ buildingId, organizationId, message }) => {
  if (!buildingId && organizationId) {
    return (
      <div className={styles.wrapper}>
        <FontAwesomeIcon icon={faExclamationTriangle} className={styles.icon} />
        <div className={styles.message}>
          <TranslateComponent>
            {message
              ? message
              : 'No data available at the organization level. Please choose a building.'}
          </TranslateComponent>
        </div>
      </div>
    )
  }
}
