import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Select from 'src/components/legacy/components/select/select'
import {
  PRIORITY_COLORS,
  PRIORITY_LIST
} from 'src/components/legacy/common/opportunity.js'
import { useTranslation } from 'react-i18next'

import { PriorityWrapper } from './styles'
import translate, { TranslateComponent } from 'src/common/translations'
import store from 'src/redux/store';

function Priority({ priorities, priority, onChange }) {
  const [t] = useTranslation()
  const priorityStyle = useMemo(
    () => ({
      background:
        PRIORITY_COLORS.get(priority) || PRIORITY_COLORS.values().next().value
    }),
    [priority]
  )
  const priorityValues = useMemo(
    () =>
      priorities.map((value, key) => ({
        key,
        value: t(`components:priority>${value}`)
      })),
    [priorities]
  )
  const editable = useMemo(() => Boolean(onChange), [])
  const translatePriority={
    "Not Prioritized": translate("Not Prioritized"),
    "URGENT!":  translate("URGENT!"),
    "High":  translate("High"),
    "Medium":  translate("Medium"),
    "Low":  translate("Low")
  }
  let selectedLanguage = store?.getState().appData?.selectedLanguage
  selectedLanguage = selectedLanguage ? selectedLanguage : "en"
  return (
    <PriorityWrapper>
      <div className="priority" data-testid="priority" style={{'maxWidth':'100%'}}>
        {editable ? (
          <>
            <label>{<TranslateComponent>Priority Level</TranslateComponent>}*</label>
            <Select
              options={priorityValues}
              onChange={(index) => onChange(priorities[index])}
              selectedItem={t(`components:priority>${priority}`)}
            />
          </>
        ) : (
          <>
            <p><TranslateComponent>Priority</TranslateComponent></p>
            <h3  className={selectedLanguage!="en" ? 'PriorityText' :" "}>{translatePriority[priority]}</h3>
            <div className="color-area" style={priorityStyle} />
          </>
        )}
      </div>
    </PriorityWrapper>
  )
}

Priority.propTypes = {
  priorities: PropTypes.array.isRequired,
  priority: PropTypes.string.isRequired,
  onChange: PropTypes.func // if onChange prop missing, component is considered to be in view mode
}

Priority.defaultProps = {
  priorities: PRIORITY_LIST
}

export default Priority
