import styles from './card-details.module.scss'
import { TranslateComponent } from 'src/common/translations'
import { formatCurrency } from 'src/common/currency'
import { Chip, ChipColor, ChipIcon } from 'src/denali-components/Table/Chip'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface CardDetailsProps {
  style?: 'energy-savings' | 'notes'
  title: string
  icon?: React.ReactNode
  iconColor?: 'blue' | 'green' | 'orange' | 'gray'
  primary?: number | string
  primaryFormat?: CardDetailsFormatPrimary
  chip?: string
  chipIcon?: ChipIcon | IconDefinition
  disclaimer?: string
  description?: Array<string>
  children?: React.ReactNode
}

export const CardDetails = ({
  style,
  title,
  icon,
  iconColor,
  primary,
  primaryFormat,
  chip,
  chipIcon,
  disclaimer,
  description,
  children
}: CardDetailsProps) => {
  return (
    <div className={styles.cardDetails} data-style={style}>
      <div className={styles.icon} data-color={iconColor}>
        {icon}
      </div>
      {title && (
        <span className={styles.title}>
          <TranslateComponent>{title}</TranslateComponent>
        </span>
      )}
      {primary !== null && primary !== undefined && (
        <span className={styles.primary}>
          {primaryFormat ? formatPrimary(primary, primaryFormat) : primary}{' '}
          {chip && (
            <Chip
              text={chip}
              icon={chipIcon}
              color={ChipColor.transparentWhite}
            />
          )}
        </span>
      )}
      {disclaimer && (
        <span className={styles.disclaimer}>
          <TranslateComponent>
            {disclaimer.charAt(0).toUpperCase() + disclaimer.slice(1)}
          </TranslateComponent>
        </span>
      )}
      {description &&
        description.map((paragraph, index) => (
          <div key={index} className={styles.description}>
            <p>
              <TranslateComponent>{paragraph}</TranslateComponent>
            </p>
          </div>
        ))}
      {children}
    </div>
  )
}

export enum CardDetailsFormatPrimary {
  USD = 'USD',
  PERCENT = 'percent',
  YEAR = 'year'
}

export const formatPrimary = (
  primary: number | string,
  primaryFormat: string
) => {
  if (primaryFormat === CardDetailsFormatPrimary.USD) {
    return formatCurrency(Math.round(primary as number), 'USD')
  }
  if (primaryFormat === CardDetailsFormatPrimary.PERCENT) {
    return `${primary.toLocaleString('en-US')}%`
  }
  if (primaryFormat === CardDetailsFormatPrimary.YEAR) {
    return Number(primary) >= 1
      ? `${primary.toLocaleString('en-US')} years`
      : `${((primary as number) * 12).toLocaleString('en-US')} months`
  }
  return primary
}
