import Container from 'src/components/Container'
import Content from 'src/components/Content'
import Icon from 'src/denali-ui/components/Icon/index'
import colors from 'src/components/legacy/common/colors.json'
import { TranslateComponent } from 'src/common/translations'
import PageHeader from 'src/components/pageHeaderNew/header'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { GET_AVAILABLE_UTILITY_ACCOUNTS, UPDATE_UTILITY_ACCOUNT, GET_ACCOUNT_BY_ID, LIST_UTILITY_STATEMENTS } from './queries'
import { DownloadStatementButton } from './components/utilityStatementDownload'
import { Link, useNavigate, useParams } from "react-router-dom"
import Table from "src/components/Table/clientSideTable"
import RouterPrompt from "src/components/RouterPrompt"
import { pages } from 'src/pages/pages.js'
import Checkbox from 'src/denali-ui/components/Checkbox'
import Modal from 'src/denali-ui/components/Modal'
import { NewStyles } from 'src/NewStyles'
import { WarningContainer, BuildingLink } from 'src/common/featureRestrictionHOC'
import { LIST_OFFERINGS_BY_ACCOUNT } from 'src/pages/contracts-and-offerings/queries'
import { isOfferingActive } from "../helpers"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const getAccountNumberString = (number) => {
  return number === 1 ? "1 account. This utility account will be removed from the organization." : `${number} accounts. These utility accounts will be removed from the organization.`
}

const arraysAreSame = (array1, array2) => {
  if (array1.length === array2.length) {
    const sorted1 = array1.sort()
    const sorted2 = array2.sort()
    for (let i = 0; i < sorted1.length; i++) {
      if (sorted1[i] !== sorted2[i]) {
        return false
      }
    }
  } else {
    return false
  }
  return true
}

export const AssociateUtilityAccounts = () => {
  const { id: organizationId } = useParams()
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false)
  const [showSuccessConfirmation, setShowSuccessConfirmation] = useState(false)
  const [associatedAccountIds, setAssociatedAccountIds] = useState([])
  const [selectedAccountIds, setSelectedAccountIds] = useState([])
  const [displayedRows, setDisplayedRows] = useState([])
  const [utilityAccountRowData, setUtilityAccountRowData] = useState([])
  const [statementData, setStatementData] = useState([])
  const [nextLocation, setNextLocation] = useState("")
  const [shouldNavigate, setShouldNavigate] = useState(false)
  const navigate = useNavigate()

  const { data: orgData = [] } = useQuery({
    query: GET_ACCOUNT_BY_ID,
    variables: { id: organizationId },
    errorPolicy: 'global',
    dataPath: 'data.getAccount'
  })

  const { data: organizationOfferingData = [], loading: loadingOfferings, error: offeringsError } = useQuery({
    query: LIST_OFFERINGS_BY_ACCOUNT,
    variables: { id: organizationId },
    errorPolicy: 'global',
    dataPath: 'data.listOfferingsByAccount.items'
  })

  const hasUBMOffering = useMemo(() => organizationOfferingData?.some(offering => offering.code === "UBM" && isOfferingActive(offering)), [organizationOfferingData])

  const { data: utilityAccounts = [], refetch: refetchUtilityAccounts } = useQuery({
    query: GET_AVAILABLE_UTILITY_ACCOUNTS,
    variables: { tisAccountId: organizationId },
    errorPolicy: 'global',
    dataPath: 'data.listUtilityAccounts'
  })

  const { data: utilityStatements = [], refetch: fetchUtilityStatements } = useQuery({
    query: LIST_UTILITY_STATEMENTS,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listUtilityStatements'
  })

  useEffect(() => {
    if (!utilityStatements?.items?.length && utilityAccounts?.items?.length) {
      fetchUtilityStatements()
    }
    if (utilityStatements?.items?.length) {
      setStatementData(prev => ([...prev, ...utilityStatements.items]))
    }
    if (utilityStatements?.nextToken) {
      fetchUtilityStatements({ nextToken: utilityStatements.nextToken })
    }
  }, [utilityAccounts, utilityStatements])

  const { onSubmit: updateAccount } = useMutation({
    query: UPDATE_UTILITY_ACCOUNT
  })

  const getLastStatementId = id => {
    const accountStatements = statementData.filter(statement => statement.utilityAccountIds.includes(id)).sort((a, b) => !a.statementDate ? 1 : !b.statementDate ? -1 : -1 * a.statementDate.localeCompare(b.statementDate))
    return accountStatements?.[0]?.id
  }

  // Intentionally clearing building id and building name because it doesn't apply to these events
  const amplitudeProperties = { "building name": "", "building id": "", "organization id": organizationId, "organization name": orgData?.name }
  const trackStatementDownload = (accountId, accountNumber) => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.DOWNLOAD_STATEMENT, { ...amplitudeProperties, "utility account id": accountId, "utility account number": accountNumber })
  useEffect(() => {
    if (statementData.length && utilityAccounts?.items?.length) {
      setUtilityAccountRowData(prev => [...prev.map(accountRow => ({ ...accountRow, statement: <DownloadStatementButton saveFileName={`${accountRow.accountNumber}_Statement`} utilityStatementId={getLastStatementId(accountRow.id)} usageTrackingCallback={() => trackStatementDownload(accountRow.id, accountRow.accountNumber)} /> }))])
    }
  }, [statementData, utilityAccounts])

  const handleSaveAssociations = () => {
    trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_SAVE_ASSOCIATIONS, amplitudeProperties)
    const requestVariables = []
    let addingAccounts = false
    selectedAccountIds.forEach(id => {
      if (!associatedAccountIds.includes(id)) {
        addingAccounts = true
        requestVariables.push({ utilityAccountId: id, tisAccountId: organizationId })
      }
    })
    associatedAccountIds.forEach(id => {
      if (!selectedAccountIds.includes(id)) {
        requestVariables.push({ utilityAccountId: id, tisAccountId: null })
      }
    })
    setShowRemovalConfirmation(false)
    Promise.all(requestVariables.map(variables => updateAccount(variables)))
      .then(result => {
        // Don't show success message if we get an error
        if (!result?.[0]?.error) {
          // Only show confirmation after saving if we added accounts - not if we only removed accounts
          if (addingAccounts) {
            setShowSuccessConfirmation(true)
            setAssociatedAccountIds(selectedAccountIds)
          } else {
            setAssociatedAccountIds(selectedAccountIds)
            setShouldNavigate(true)
          }
        }
      })
      .catch(error => { })
  }

  // Fetch Utility Accounts with nextToken until we have all of them
  useEffect(() => {
    try {
      if (utilityAccounts && utilityAccounts.items?.length) {
        const rowData = []
        const associatedData = []
        utilityAccounts.items.forEach(account => {
          if (account.accountId === organizationId) {
            associatedData.push(account.id)
          }
          rowData.push(account)
        })
        setAssociatedAccountIds(currentList => [...currentList, ...associatedData])
        setSelectedAccountIds(currentList => [
          ...currentList,
          ...associatedData
        ])
        setUtilityAccountRowData(currentList => ([
          ...currentList,
          ...rowData,
        ]))
      }
      if (utilityAccounts && utilityAccounts.nextToken !== null) {
        refetchUtilityAccounts({ tisAccountId: organizationId, nextToken: utilityAccounts.nextToken })
      }
    } catch (error) { }
  }, [utilityAccounts])

  // Navigate to correct location after saving without adding any accounts (only removing accounts)
  // Doing this in its own effect to avoid triggering unsaved changes prompt
  useEffect(() => {
    if (shouldNavigate) {
      if (nextLocation) {
        navigate(nextLocation)
      } else {
        navigate(pages["Organization Management/:id"].href.replace(":id", organizationId))
      }
    }
  }, [shouldNavigate])

  const selectAllCheckboxChecked = useMemo(() => {
    return Boolean(selectedAccountIds.length && displayedRows.length && displayedRows.every(row => selectedAccountIds.includes(row.id)))
  }, [selectedAccountIds, displayedRows])

  const utilityHeadings = [
    {
      title: "",
      key: '',
      columnType: 'component',
      maxWidth: '20px',
      canSort: false,
      customHeaderComponent: (tableConfig) => {
        return (
          <Checkbox
            checked={selectAllCheckboxChecked}
            onClick={event => {
              if (event.target.checked) {
                setSelectedAccountIds(currentIds => [...new Set([...currentIds, ...displayedRows.map(row => row.id)])])
              } else {
                setSelectedAccountIds(currentIds => {
                  const selectedClone = [...currentIds]
                  displayedRows.forEach(row => {
                    const index = selectedClone.indexOf(row.id)
                    if (index >= 0) {
                      selectedClone.splice(index, 1)
                    }
                  })
                  return selectedClone
                })
              }
            }} />
        )
      },
    },
    {
      name: "accountEmail",
      title: "Email/Username Credential",
      key: "accountEmail",
      maxWidth: '100px'
    },
    {
      name: 'providerName',
      title: 'Provider Name',
      key: 'providerName',
      maxWidth: '100px'
    },
    {
      name: 'accountNumber',
      title: 'Account Number',
      key: 'accountNumber',
      maxWidth: '100px',
      numericSort: false,
    },
    {
      name: 'accountId',
      title: 'Account ID',
      key: 'id',
      maxWidth: '120px'
    },
    {
      name: 'statement',
      title: 'Last Statement PDF',
      key: 'statement',
      canSort: false,
      maxWidth: '60px'
    },
  ]

  // Block navigation without saving if associated accounts != selected accounts - i.e. there are unsaved changes
  const shouldBlock = useCallback(() => {
    return associatedAccountIds.length !== selectedAccountIds.length || associatedAccountIds.some(id => !selectedAccountIds.includes(id))
  }, [associatedAccountIds, selectedAccountIds])

  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <NewStyles>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={`Associate Utility Accounts to ${orgData?.name ? orgData.name : ""}`}
          />
          <p style={{ fontSize: "14px", paddingTop: "10px" }}>Select the accounts that you would like to associate with this organization.</p>
        </Content>
        {showRemovalConfirmation && (
          <Modal
            heading="Confirm Account Removal"
            modalHeight="auto"
            handleClose={() => setShowRemovalConfirmation(false)}
            buttons={[
              {
                variant: 'secondary',
                text: 'Cancel',
                handleClick: () => setShowRemovalConfirmation(false)
              },
              {
                variant: 'primary',
                text: 'Save Changes',
                handleClick: () => {
                  trackEvent(USER_EVENTS.ADMIN_TOOLS.events.REMOVE_ACCOUNTS, amplitudeProperties)
                  handleSaveAssociations()
                }
              }
            ]}
          >
            <p>{`You have unselected ${getAccountNumberString(associatedAccountIds.filter(id => !selectedAccountIds.includes(id)).length)}`}</p>
            <br />
            <p>Are you sure you would like to save these changes?</p>
          </ Modal>
        )}
        {showSuccessConfirmation && (
          <Modal
            heading="Account Associations Made"
            modalHeight="auto"
            handleClose={() => {
              setShowSuccessConfirmation(false)
              if (nextLocation) {
                navigate(nextLocation)
              } else {
                navigate(pages["Organization Management/:id"].href.replace(":id", organizationId))
              }
            }}
            buttons={[
              {
                text: 'Close',
                handleClick: () => {
                  setShowSuccessConfirmation(false)
                  if (nextLocation) {
                    navigate(nextLocation)
                  } else {
                    navigate(pages["Organization Management/:id"].href.replace(":id", organizationId))
                  }
                }
              }
            ]}
          >
            <p>The utility accounts selected have been associated to {orgData?.name}. You will now see these utility accounts within the organization.</p>
          </ Modal>
        )}
        {hasUBMOffering ?
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            display="flex"
          >
            <RouterPrompt shouldBlockNavigation={shouldBlock} proceedOnSave={false} setNextLocation={setNextLocation} handleSave={() => handleSaveAssociations()} />
            <Table
              key="utilityAccountsTable"
              rows={utilityAccountRowData}
              header={utilityHeadings}
              showItemCount={true}
              search={true}
              searchFields={['providerName', 'accountNumber']}
              updateRows={(rows) => {
                // Keep track of what accounts are displayed to user at any point
                // This depends on pagination, search, and filter applied
                if (!arraysAreSame(rows, displayedRows)) {
                  setDisplayedRows(rows)
                }
              }}
              rowCheckbox={true}
              checkboxClick={row => {
                setSelectedAccountIds(curr => {
                  const selectedClone = [...curr]
                  const index = curr.indexOf(row.id)
                  if (index >= 0) {
                    selectedClone.splice(index, 1)
                  } else {
                    selectedClone.push(row.id)
                  }
                  return selectedClone
                })
              }}
              checkboxChecked={row => selectedAccountIds.includes(row.id)}
              fixedLayout={false}
              actionsList={[
                {
                  actionText: "Cancel",
                  action: () => navigate(pages["Organization Management/:id"].href.replace(":id", organizationId)),
                  actionButtonType: "secondary",
                  iconType: "none"
                },
                {
                  actionText: "Save",
                  status: arraysAreSame(associatedAccountIds, selectedAccountIds) ? "disabled" : undefined,
                  action: async () => {
                    if (associatedAccountIds.some(id => !selectedAccountIds.includes(id))) {
                      setShowRemovalConfirmation(true)
                    } else {
                      handleSaveAssociations()
                    }
                  },
                  iconType: "none"
                }
              ]}
              alignWithFilters={true}
              actionText="Save"
              iconType="none"
              headerPadding="0 0 10px 0"
            />
          </Content>
          : (!organizationOfferingData && !offeringsError) || loadingOfferings ?
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <Spinner />
            </Content>
            :
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              placeSelf="center"
            >
              <WarningContainer>
                <Icon
                  name="Warning"
                  width="25px"
                  height="25px"
                  margin="0px 10px"
                  color={colors.RED}
                />
                <p style={{ marginBlockStart: "1em", marginBlockEnd: "1em" }}>
                  <TranslateComponent>
                    {`Utility Bill Management is not currently subscribed to for this organization.
                Enabled offerings can be seen in`}
                  </TranslateComponent>{' '}
                  <BuildingLink>
                    <Link to={`/building-setup?organization=${organizationId}`}><TranslateComponent>Building Setup.</TranslateComponent></Link>
                  </BuildingLink>
                </p>
              </WarningContainer>
            </Content>
        }
      </NewStyles>
    </Container>
  )
}
