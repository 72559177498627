import { Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  useMergeRefs
} from '@floating-ui/react'
import { cloneElement, forwardRef } from 'react'

// Denali components
import { faChevronRight } from 'src/denali-components/lib/pro-solid-svg-icons'

import styles from './flyout-menu.module.scss'
import { FlyoutMenuComponentProps, FlyoutMenuProps } from './types'
import { FlyoutMenuContext } from './FlyoutMenuContext'
import { useFlyoutMenu } from './hooks'

export const FlyoutMenuComponent = forwardRef<
  HTMLButtonElement,
  FlyoutMenuProps &
    React.HTMLProps<HTMLButtonElement> &
    FlyoutMenuComponentProps
>(function MenuComponentFunction(
  { children, label, triggerBuilder, placement, ...props },
  forwardedRef
) {
  const {
    activeIndex,
    context,
    elementsRef,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    hasFocusInside,
    item,
    isNested,
    isOpen,
    labelsRef,
    nodeId,
    parent,
    refs,
    setActiveIndex,
    setHasFocusInside
  } = useFlyoutMenu({ placement })

  const triggerElement = () => {
    if (triggerBuilder === undefined) {
      return (
        <button
          ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
          tabIndex={
            !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1
          }
          role={isNested ? 'menuitem' : undefined}
          data-open={isOpen ? '' : undefined}
          data-nested={isNested ? '' : undefined}
          data-focus-inside={hasFocusInside ? '' : undefined}
          className={isNested ? 'MenuItem' : 'RootMenu'}
          {...getReferenceProps(
            parent.getItemProps({
              ...props,
              onFocus(event: React.FocusEvent<HTMLButtonElement>) {
                props.onFocus?.(event)
                setHasFocusInside(false)
                parent.setHasFocusInside(true)
              }
            })
          )}
        >
          <Flex
            direction="row"
            justifyContent="space-between"
            className={styles.flyoutMenuItem}
          >
            <p className={styles.flyoutMenuLabel}>{label}</p>
            {isNested && (
              <FontAwesomeIcon
                icon={faChevronRight}
                aria-hidden
                className={styles.flyoutMenuArrow}
              />
            )}
          </Flex>
        </button>
      )
    }

    return cloneElement(triggerBuilder(label, parent, item, isNested), {
      ref: useMergeRefs([refs.setReference, item.ref, forwardedRef]),
      ...getReferenceProps(
        parent.getItemProps({
          ...props,
          onFocus(event: React.FocusEvent<HTMLButtonElement>) {
            props.onFocus?.(event)
            setHasFocusInside(false)
            parent.setHasFocusInside(true)
          }
        })
      )
    })
  }

  return (
    <FloatingNode id={nodeId}>
      {triggerElement()}
      <FlyoutMenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          setHasFocusInside,
          isOpen
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen && (
            <FloatingFocusManager
              context={context}
              modal={false}
              initialFocus={isNested ? -1 : 0}
              returnFocus={!isNested}
            >
              <div
                ref={refs.setFloating}
                className={`${label}-menu ${styles.flyoutMenu} ${
                  props.floatingClassName ?? ''
                }`}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                {children}
              </div>
            </FloatingFocusManager>
          )}
        </FloatingList>
      </FlyoutMenuContext.Provider>
    </FloatingNode>
  )
})
