import { TranslateComponent } from 'src/common/translations'
import styles from './building-selector.module.scss'
import { OrganizationIcon } from './OrganizationIcon'

export const SelectedOrganizationDisplay = ({
  organization,
  sidebarIsExpanded
}) => {
  return (
    <div>
      {sidebarIsExpanded && (
        <>
          <div
            className={styles.inputSelectionName}
            data-testid={`org-${organization.id}`}
          >
            {organization?.primary}
          </div>
          <div className={styles.inputSelectionSubTitle}>
            <TranslateComponent>Organization</TranslateComponent>
          </div>
        </>
      )}
      {!sidebarIsExpanded && <OrganizationIcon name={organization?.primary} />}
    </div>
  )
}
