/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useRef } from 'react'
import { Timeline as TimelineChartComp } from '../../../../chart-library/CommonComponents/Timeline'
import { useConfig } from '../useConfig'
import { TimelineStackedChart } from './TimelineStackedChart'
import { format } from 'date-fns'
import { formatDateByMomentInEng } from 'src/chart-library/CommonComponents/Timeline/helpers'
import store from 'src/redux/store';

export default function Timeline({
  hide = false,
  hideChart = false,
  startTime = null,
  endTime = null,
  series = [],
  categories = [],
  yAxisMin,
  yAxisMax,
  getSeriesDataInSelectedRnage = () => [],
  className = ''
}: any) {
  const timelineRef = useRef({ reset: () => null })

  const { canvas, isInitialized, xAxis } = useConfig()

  const stackProps = useMemo(() => {
    // Reset timeline slider when new data comes in
    timelineRef.current.reset()

    return !isInitialized
      ? {}
      : {
          categories: categories,
          series: series,
          yAxisMin: yAxisMin,
          yAxisMax: yAxisMax
        }
  }, [series, yAxisMin, yAxisMax, isInitialized])

  const handleOnRangeChange = useMemo(
    () =>
      filterStackedChartDataBySelectedRange(
        series,
        categories,
        getSeriesDataInSelectedRnage
      ),
    [series, categories]
  )

  if (!isInitialized || hide) {
    return null
  }

  return (
    <TimelineChartComp
      x={xAxis.x}
      y={xAxis.y + 50}
      width={canvas.width}
      trackStartTime={startTime}
      trackEndTime={endTime}
      getBrushPosition={handleOnRangeChange}
      ref={timelineRef}
      className={className}
    >
      {!hideChart && <TimelineStackedChart {...stackProps} key={1} />}
    </TimelineChartComp>
  )
}

function filterStackedChartDataBySelectedRange(
  data,
  categories = [],
  callback
) {
  function filter(start, end, isNoChange) {
    return isNoChange
      ? data
      : data.reduce((av, cv) => {
          av.push({
            ...cv,
            data: (cv.data || []).slice(start, end)
          })

          return av
        }, [])
  }

  function filterCategories(start, end, isNoChange) {
    return isNoChange ? categories : categories.slice(start, end)
  }

  function findRangeIndex(valueAtStart, valueAtEnd) {
    const startIndex = categories.indexOf(valueAtStart)
    const endIndex = categories.indexOf(valueAtEnd)

    return {
      start: startIndex !== -1 ? startIndex : 0,
      end: endIndex !== -1 ? endIndex + 1 : categories.length,
      isNoChange: startIndex === 0 && endIndex === categories.length - 1
    }
  }

  function findRangeValue(startTime = '', endTime = '') {
    let selectedLanguage = store?.getState().appData?.selectedLanguage
    selectedLanguage = selectedLanguage ? selectedLanguage : "en"
    
    const valueAtStart: any = startTime ? format(selectedLanguage === 'en' ? startTime : formatDateByMomentInEng(startTime), 'MMM-yyyy') : ''
    const valueAtEnd: any = endTime ? format(selectedLanguage === 'en' ? endTime : formatDateByMomentInEng(endTime), 'MMM-yyyy') : ''
    return {
      startValue: !categories?.includes(valueAtStart)
        ? '_index-not-found'
        : valueAtStart,
      endValue: !categories?.includes(valueAtEnd)
        ? '_index-not-found'
        : valueAtEnd
    }
  }

  return function onChange(props) {
    const selectedRangeValues = findRangeValue(props.startTime, props.endTime)
    const selectedRangeIndices = findRangeIndex(
      selectedRangeValues.startValue,
      selectedRangeValues.endValue
    )

    const data = filter(
      selectedRangeIndices.start,
      selectedRangeIndices.end,
      selectedRangeIndices.isNoChange
    )

    const categories = filterCategories(
      selectedRangeIndices.start,
      selectedRangeIndices.end,
      selectedRangeIndices.isNoChange
    )

    callback({ data, categories })
  }
}
