import { useEffect, useState } from 'react'
import EngeryHeatMap from '../electricalDemand/EngeryHeatMap'

export const EnergyConsumptionHeatMapChart = ({
  chartData = { data: [] },
  unit,
  tooltipTitle
}) => {
  const [timeStamps, setTimeStamps] = useState([])
  useEffect(() => {
    if (chartData) {
      const timeStamps =
        chartData?.data?.[0]?.values[0]?.map((data) => data?.timeStamp) || []
      setTimeStamps(timeStamps)
    }
  }, [chartData])

  return (
    <EngeryHeatMap
      chartData={{ data: chartData?.data, timeStamps }}
      TOOLTIP_TITLE={tooltipTitle}
      unit={unit}
    />
  )
}
