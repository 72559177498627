import { Link } from 'react-router-dom'
import { TranslateComponent } from 'src/common/translations'
import { Block } from 'src/components/layouts'

export const PageNotFound = () => (
  <Block padding={'0 0 0 12px'}>
    <h3 style={{ lineHeight: 'normal' }}>
     <TranslateComponent>The page you are looking for is not available. The location of the
      item may have changed. If you have used a bookmark or shared hyperlink for
      navigation, select the page from the left sidebar and bookmark the page
      location or </TranslateComponent><Link to="/"><TranslateComponent>click here</TranslateComponent></Link><TranslateComponent> to go to Home page.</TranslateComponent> 
    </h3>
  </Block>
)
