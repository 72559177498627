import cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, } from 'react-router-dom'

// Project imports
import { ACTIONS } from 'src/constants'
import { getSearchParams, getValidFileName } from 'src/common/helperFunctions.js'
import { Content } from 'src/components/layouts'
import translate from 'src/common/translations'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { useAppDispatch, useAppSelector } from 'src/redux/store'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { selectDocumentsAndReportsData, setDocumentsAndReports } from 'src/redux/slicers/formData'
import { UiMode } from 'src/redux/types/AppTypes'

// Legacy components
import {
  DATE_FORMAT,
} from 'src/components/legacy/common/time-helpers'
import { getField } from 'src/components/legacy/common/helpers'
import Label from 'src/components/legacy/components/label/label'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import Select from 'src/components/legacy/components/select/select'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal'

// Components
import Header from 'src/components/pageHeader/header'
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'
import { MODAL_TYPE } from 'src/constants'

// Denali components
import { AddDocumentForm } from 'src/denali-pages/Reports/AddDocumentForm'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'

// Local imports
import { ACCEPTED_FILE_TYPES } from './constants'
import {
  Column,
  HrTag,
  Input,
  ToggleWrapper,
  InformationPanel,
  PanelItem,
  PanelLabel,
  ToolbarWrapper,
  InlineEditForm,
  CustomContainer,
  SelectWrapper,
  DateWrapper,
  ColumnDateContainer,
  CustomHeader
} from './styles'
import {
  documentTypeList,
  documentStatusList,
  getAttachedDetails,
  getDocTestName,
} from './helper'
import { useAddDocumentsState } from './hooks'
import Spinner from 'src/components/Spinner'
import useAttachmentClaims from './attachmentClaimsHook'


const initialValues = {
  documentName: '',
  documentType: '',
  documentStatus: '',
  visibility: false,
  location: {},
  createdBy: '',
  createdDate: '',
  note: '',
  attachments: [],
  description: '',
  startDate: ''
}

const Documents = (props) => {
  const [t] = useTranslation()

  const optionsTranslated = {
    cancel: translate('CANCEL'),
    save: translate('SAVE'),
    deleteFile: translate('Delete File'),
    confirmDelete: translate(`Are you sure you want to delete `),
    yes: translate('Yes'),
    no: translate('No'),
    replaceAttach: translate('Replace Existing Attachments'),
    uploadMultiple: translate(
      'You are attempting to upload one or more files that will replace existing attachments. Do you want to replace the existing attachments?'
    ),
    replace: translate('Replace'),
    replaceExist: translate(
      'You are attempting to upload one or more files that will replace existing attachments.'
    ),
    editDoc: translate('Edit Document'),
    addDoc: translate('Add Document'),
    visibility: translate('Visibility'),
    docType: translate('Document Type'),
    selectOne: translate('Select One'),
    docStatus: translate('Document Status'),
    findBuilding: translate(`Find building...`),
    noResults: translate('No results. Change or clear your text above.'),
    noFiles: translate('No files attached'),
    docName: translate('Document Name'),
    building: translate('Building'),
    docNameError: translate('Please enter a document name'),
    docTypeError: translate('Please select a document type'),
    docStatusError: translate('Please select a document status'),
    createdBy: translate('Created By')
  }

  const [modalType, setModalType] = useState<any>("")
  const navigate = useNavigate()
  const { width } = useBreakPoint()
  const userInfo: any = useAppSelector(selectUserInfo)
  const MAX_LENGTH_OF_PROPOSED = 50
  const {
    values = initialValues,
    downloadFileFunc = () => { },
    mode,
    uiMode
  } = props || {}

  const {
    organizationId,
    buildingId,
  } = getSearchParams()
  const [locationId, setLocationId] = useState(buildingId)
  const [buildings, setBuildings] = useState([])
  const [confirm, setConfirm] = useState(null)
  const dispatch = useAppDispatch()
  const [RefreshAttachmentClaims] = useAttachmentClaims()

  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return ('Are you sure you want to delete this Attachment?')
      default:
        return ('')
    }
  }

  const hideModalDialog = () => {
    setModalType('')
  }

  const onReset = useCallback(() => {
    dispatch(setDocumentsAndReports(initialValues))
  }, [dispatch])

  const goBack = useCallback(async () => {
    const buildingIds = buildingId ? [buildingId] : null
    const organizationIds = organizationId ? [organizationId] : null
    await RefreshAttachmentClaims(buildingIds, organizationIds).then(() => {
      navigate(`/reports?organization=${organizationId}${buildingId ? `&location=${buildingId}` : ''}`)
    })
  }, [organizationId, buildingId])

  const reduxValues = useAppSelector(selectDocumentsAndReportsData);

  // Set BuildingsList and individual building based on query response by org/salesoffice level
  const onChangeBuildingsData = useCallback((arrayList) => {
    const list = arrayList?.map((building) => ({
      key: building?.key,
      value: building?.value,
      searchableValue: building?.value
    }))
    setBuildings(_sortBy(list, 'value'))
    if (locationId) {
      const building = getLocationBuilding(arrayList)
      onBuildingChangeSelect(building || '')
    }
  }, [locationId])

  const {
    formik,
    formSubmitHandler,
    buildingsList,
    getPreSignedUrlMutation,
    deleteAttach,
    setDeleteAttach,
    isSubmitting,
    isDocumentLoading
  } = useAddDocumentsState({
    locationId,
    initialValues,
    values,
    mode,
    goBack,
    onChangeBuildingsData,
    uiMode
  })

  const {
    setFieldValue,
    values: {
      documentName,
      visibility,
      attachments = [],
      createdDate,
      documentType,
      documentStatus,
      note,
      startDate
    } = {}
  } = formik

  // Get the building detail if individual location is selected in building picker
  const getLocationBuilding = (buildingListData) =>
    buildingListData?.find((building) => building?.key === locationId)

  const toolbar = () => (
    <ToolbarWrapper className="toolbar-buttons">
      <button
        className="secondary"
        disabled={isSubmitting}
        type="reset"
        data-testid={getDocTestName('cancel-button')}
        onClick={() => {
          goBack()
        }}
      >
        {optionsTranslated.cancel}
      </button>
      <button
        className={`primary ${isSubmitting ? 'wait' : ''}`}
        type="submit"
        data-testid={getDocTestName('save-button')}
        disabled={isSubmitting}
      >
        {isSubmitting ? <div className="spinner" /> : optionsTranslated.save}
      </button>
    </ToolbarWrapper>
  )

  const onBuildingChangeSelect = ({ key = '', value = '' } = {}) => {
    if (key) {
      setLocationId(key)
      setFieldValue('location', {
        locationName: value,
        locationId: key
      })
    }
  }

  const onDescriptionChange = (e) => {
    setFieldValue(
      'attachments',
      attachments?.map((attachment) =>
        attachment?.title === e?.fileName
          ? { ...attachment, description: e?.value, descriptionChanged: true }
          : attachment
      )
    )
  }
  const handleFormSubmit = (e) => {
    e?.preventDefault()
    formik?.handleSubmit()
  }

  const addFileFunc = async (e) => {
    try {
      const fileName = getValidFileName(e?.name)
      Object.defineProperty(e, 'name', {
        writable: true,
        value: fileName
      })
      const res = await getPreSignedUrlMutation({
        input: `{"action": "UPLOAD", "key": "${e?.name}", "buildingId": "${formik?.values?.location?.locationId}" }`
      })
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: e,
        redirect: 'follow',
        headers: { 'Content-Type': '' }
      }
      const response = await fetch(
        JSON.parse(res?.data?.getPreSignedUrl).body,
        requestOptions
      )
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  const onDeleteAttachment = (fileId?, fileName?) => {
    const updatedAttachments = attachments?.filter(
      (attachment) => attachment?.title !== fileName
    )
    // If denali mode we just need to update the redux store.
    if (uiMode === UiMode.denali) {
      const updatedValues = {
        ...reduxValues,
        attachments: updatedAttachments
      }
      dispatch(setDocumentsAndReports(updatedValues))
      setModalType('')
    }
    setFieldValue('attachments', updatedAttachments)
    if (fileId) {
      const deleteAttachments = [...deleteAttach]
      deleteAttachments.push(fileId)
      setDeleteAttach(deleteAttachments)
    }
  }

  const showConfirm = (fileId, fileName) => {
    setConfirm({
      title: optionsTranslated.deleteFile,
      text: optionsTranslated.confirmDelete + `${fileName}?`,
      className: 'confirm-delete-dialog',
      confirmText: optionsTranslated.yes,
      cancelText: optionsTranslated.no,
      handleConfirm: () => {
        setConfirm(null)
        onDeleteAttachment(fileId, fileName)
      }
    })
    setModalType(MODAL_TYPE.CONFIRMATION)
  }

  const warnBeforeReplace = (callback) => {
    setConfirm({
      title: optionsTranslated.replaceAttach,
      text: optionsTranslated.uploadMultiple,
      confirmText: optionsTranslated.replace,
      cancelText: optionsTranslated.cancel,
      handleConfirm: () => {
        callback()
        setConfirm(null)
      },
      handleCancel: () => {
        callback(optionsTranslated.replaceExist)
        setConfirm(null)
      }
    })
  }

  const onChangeUploadStatus = (newFiles) => {
    const attachmentCopy = cloneDeep(attachments)
    const newFilesCopy = cloneDeep(newFiles)
    const locationIdCopy = cloneDeep(formik?.values?.location?.locationId)

    const attachFiles = getAttachedDetails(
      newFilesCopy,
      attachmentCopy,
      locationIdCopy
    )
    formik?.setFieldValue('attachments', attachFiles)
  }
  const onChangeDate = (path) => (date) => {
    date = date.target ? date.target.value : moment(date).format(DATE_FORMAT)
    setFieldValue(path, date)
    setFieldValue('startDate', date)
    setFieldValue('createdDate', date)
  }
  const { errors, touched } = formik

  if (uiMode === UiMode.denali) {
    return (
      <>
        <AddDocumentForm
          attachments={attachments}
          warnBeforeReplace={warnBeforeReplace}
          errors={errors}
          addFileFunc={addFileFunc}
          downloadFileFunc={downloadFileFunc}
          showConfirm={showConfirm}
          uiMode={uiMode}
          userInfo={userInfo}
          onSubmit={formSubmitHandler}
          isSubmitting={isSubmitting}
          goBack={goBack}
          mode={mode}
        />
        {modalType === MODAL_TYPE.CONFIRMATION && (
          <DialogConfirm
            onAgree={onDeleteAttachment}
            title={'Delete Attachment'}
            onClose={hideModalDialog}
          >
            {renderConfirmationText(modalType)}
          </DialogConfirm>
        )}
      </>
    )
  }

  return (
    <InlineEditForm data-testid="document-new">
      {isDocumentLoading && mode === ACTIONS.EDIT ? <Spinner page={true} /> :
        <form onSubmit={handleFormSubmit} className="inline-edit-form">
          <CustomContainer
            sColumns={12}
            mColumns={12}
            lColumns={12}
            xlColumns={12}
          >
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <CustomHeader data-testid="document-new-header">
                <Header
                  hideToolBar={true}
                  pageTitle={
                    mode === ACTIONS.EDIT
                      ? optionsTranslated.editDoc
                      : optionsTranslated.addDoc
                  }
                />
              </CustomHeader>
            </Content>

            {/* first section */}
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <Column columns="1fr 1fr">
                <div
                  data-testid="document-name"
                  className={`doc-name ${errors?.documentName && touched?.documentName
                    ? 'invalid'
                    : undefined
                    }`}
                >
                  <label>{optionsTranslated.docName}:*</label>
                  <br />
                  <Input
                    id="doc-name"
                    type="text"
                    maxLength={MAX_LENGTH_OF_PROPOSED}
                    value={documentName}
                    onChange={(event) =>
                      setFieldValue('documentName', event?.target?.value)
                    }
                  />
                  {errors?.documentName && touched?.documentName && (
                    <p className="error">{optionsTranslated.docNameError}</p>
                  )}
                </div>
                <ToggleWrapper className="toggle-button">
                  <label>{optionsTranslated.visibility}:</label>
                  <ToggleButtonHorizontal
                    id="doc-visibility"
                    name="visibleToCustomer"
                    checked={visibility}
                    testId="doc-visibility"
                    onChange={(e) =>
                      setFieldValue('visibility', e?.target?.checked)
                    }
                  />
                </ToggleWrapper>
              </Column>
            </Content>

            {/* second section */}
            <Content
              xlColumn={9}
              lColumn={9}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <ColumnDateContainer columns="0fr 0fr 0fr">
                <DateWrapper
                  width={width}
                  className={
                    getField(errors, 'startDate')
                      ? 'proposal-date error'
                      : 'proposal-date'
                  }
                >
                  <ModalDatePickerInput
                    date={
                      startDate ??
                      (moment(createdDate).isValid()
                        ? moment(createdDate).format(DATE_FORMAT)
                        : '')
                    }
                    onChange={onChangeDate('createdDate')}
                    isRequired={false}
                    labelText={`${t('opportunities:CreatedDate')}*`}
                    hasError={Boolean(getField(errors, 'createdDate'))}
                    errorMessage={getField(errors, 'createdDate')}
                  />
                </DateWrapper>

                <SelectWrapper width={width}>
                  <label>{`${optionsTranslated.docType}*`}</label>
                  <Select
                    testName={getDocTestName('documentType')}
                    placeholder={'— Select One —'}
                    selectedItem={documentTypeList(t)[documentType]}
                    onChange={(event) => setFieldValue('documentType', event)}
                    options={documentTypeList(t)}
                    className={`dropdown-select ${errors?.documentType && touched?.documentType
                      ? 'invalid'
                      : undefined
                      }`}
                  />
                  {errors?.documentType && touched?.documentType && (
                    <p className="error">{optionsTranslated.docTypeError}</p>
                  )}
                </SelectWrapper>
                <SelectWrapper width={width}>
                  <label>{`${optionsTranslated.docStatus}*`}</label>
                  <Select
                    testName={getDocTestName('documentStatus')}
                    placeholder={'— Select One —'}
                    selectedItem={documentStatusList(t)[documentStatus]}
                    onChange={(value) => setFieldValue('documentStatus', value)}
                    options={documentStatusList(t)}
                    className={`dropdown-select ${errors?.documentStatus && touched?.documentStatus
                      ? 'invalid'
                      : undefined
                      }`}
                  />
                  {errors?.documentStatus && touched?.documentStatus && (
                    <p className="error">{optionsTranslated.docStatusError}</p>
                  )}
                </SelectWrapper>
              </ColumnDateContainer>
            </Content>

            {/* Third section */}
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <Content xlColumn={9} lColumn={9} border="none" width={width}>
                <Column columns="1fr 1fr">
                  <div className="doc-name">
                    <label>{optionsTranslated.building}:*</label>
                    <SelectrixWrapper
                      testName={getDocTestName('location')}
                      arrow={true}
                      className="location selectrix-wrapper--type-autocomplete"
                      customKeys={{ key: 'key', label: 'value' }}
                      defaultValue={
                        mode && mode === ACTIONS.EDIT
                          ? formik?.values?.location?.locationId
                          : locationId
                      }
                      disableStateVal={true}
                      inputPlaceholder={optionsTranslated.findBuilding}
                      notFoundPrompt={optionsTranslated.noResults}
                      onChange={onBuildingChangeSelect}
                      options={buildings}
                      placeholder={'— ' + optionsTranslated.selectOne + ' —'}
                      searchable={true}
                      searchBoxInside={true}
                      searchIndex={false}
                      value={formik?.values?.location?.locationId}
                    />
                    {touched?.location &&
                      getField(errors, 'location.locationId') && (
                        <p className="error">{t('reports:Locations')}</p>
                      )}
                  </div>
                  <div>
                    <InformationPanel>
                      <PanelItem>
                        <PanelLabel>{optionsTranslated.createdBy}</PanelLabel>
                        <div
                          data-testid={getDocTestName('created-by')}
                        >{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
                      </PanelItem>
                    </InformationPanel>
                  </div>
                </Column>
              </Content>
              <HrTag className="doc-seperator-two" />
            </Content>

            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              width={width}
              border="none"
            >
              <div
                className="attached-files"
                data-testid={getDocTestName('attach-files')}
              >
                <Label
                  text={`${t('notes:AttachedFiles')}* ${formik?.values?.attachments?.length
                    ? `(${formik?.values?.attachments?.length})`
                    : ''
                    }`}
                />
                <AttachedFiles
                  attachments={attachments?.map((item) => ({
                    ...item,
                    isEditable: true,
                    fileName: item?.title,
                    fileId: item?.id,
                    fileSize: item?.sizeInBytes
                  }))}
                  onBeforeReplaceAttachment={(e) => warnBeforeReplace(e)}
                  onDeleteAttachment={(id, name) => showConfirm(id, name)}
                  onDescriptionChange={(e) => onDescriptionChange(e)}
                  onChangeUploadStatus={(files) => {
                    onChangeUploadStatus(files)
                  }}
                  addFile={(e) => addFileFunc(e)}
                  downloadFileFunc={(e) => downloadFileFunc(e)}
                  proposalFileTypes={ACCEPTED_FILE_TYPES}
                />
                {errors?.attachments &&
                  touched?.attachments &&
                  _isEmpty(attachments) && (
                    <p className="error">{optionsTranslated.noFiles}</p>
                  )}
              </div>

              <TextAreaInput
                labelText="Supporting Notes"
                value={note}
                isRequired={false}
                maxLength={2000}
                placeholder=""
                onChange={(event) => {
                  setFieldValue('note', event?.target?.value)
                }}
                wrapperClassName="wrapperClass"
                testName={getDocTestName('supporting-notes-')}
              />

              <HrTag className="doc-seperator-two" />
            </Content>

            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              width={width}
              border="none"
            >
              {toolbar()}
            </Content>
          </CustomContainer>
        </form>
      }
      <StandardDialogs
        confirm={confirm}
        onCloseDialog={() => setConfirm(null)}
      />
    </InlineEditForm>
  )
}
export default Documents
