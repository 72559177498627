import { Flex } from '@aws-amplify/ui-react'
import * as Plot from '@observablehq/plot'
import { useCallback } from 'react'

// Project imports
import { useEnergyIntensityBenchmarksAndMarkersContext } from './hooks/use-energy-intensity-benchmarks-and-markers-context'

// Denali component imports
import PlotChart from 'src/denali-components/PlotChart/PlotChart'

// Local imports
import { linearGradientDef } from './constants'
import {
  cleanupPlotEvents,
  createBenchmarkRule,
  createMarkerRule,
  createXDomainConfig,
  ECI_MARKS_ORDER,
  EUI_MARKS_ORDER,
  formatEuiYAxisValues,
  setupPlotEvents
} from './helpers'
import {
  ChartDataItem,
  EnergyIntensityChartProps,
  MarkNames,
  PlotChartValueType
} from './types.d'
import { useEnergyIntensityMarksOrder } from './hooks/use-energy-intensity-marks-order'

export const EnergyIntensityBarChart = ({
  chartData,
  chartDates,
  euiEnabled,
  eciEnabled,
  diffDays,
  isDefaultYAxis = false,
  customMinMax
}: EnergyIntensityChartProps) => {
  // Marks order hook for eui.
  const { marksOrder: euiMarksOrder, setPlotValueType: setEuiPlotValueType } =
    useEnergyIntensityMarksOrder(EUI_MARKS_ORDER, getEuiOrder)

  // Marks order hook for eci.
  const { marksOrder: eciMarksOrder, setPlotValueType: setEciPlotValueType } =
    useEnergyIntensityMarksOrder(ECI_MARKS_ORDER, getEciOrder)

  const { benchmarks, markers } =
    useEnergyIntensityBenchmarksAndMarkersContext()

  // @NOTE: Maybe refactor to a hook to compute the items, domains and marks by passing in the chart data, benchmarks and markers.
  const euiItems = chartData.filter((d) => d.type === 'eui' && d?.eui !== null)
  const eciItems = chartData.filter((d) => d.type === 'eci' && d?.eci !== null)

  // If we have a custom min mix, we need to create a domain of values for the Y axis.
  const euiYDomain =
    customMinMax && !isDefaultYAxis
      ? [customMinMax.section1min, customMinMax.section1max]
      : undefined
  const eciYDomain =
    customMinMax && !isDefaultYAxis
      ? [customMinMax.section2min, customMinMax.section2max]
      : undefined

  // Need to take the filtered benchmarks and turn them into ruleY marks.
  const filteredBenchmarks = benchmarks?.filter((b) => b.checked)
  const enabledBenchmarkMarks = filteredBenchmarks.map((b) =>
    createBenchmarkRule({ value: b.value, name: b.name })
  )
  const enabledBenchmarkTriggerMarks = filteredBenchmarks.map((b) =>
    createBenchmarkRule({
      value: b.value,
      name: b.name,
      stroke: 'transparent',
      strokeWidth: 10,
      showTip: false,
      className: 'benchmarkRuleTrigger'
    })
  )

  // Need to take the filtered markers and turn them into ruleX marks. We need to make sure we filter
  const filteredMarkers = markers?.filter((m) => m.checked)
  const enabledMarkerMarks = filteredMarkers.map((m) =>
    createMarkerRule({ value: m.markerDate, name: m.name })
  )
  // Create another set of marks that are wider and invisible and this will be what trigger the hover events.
  const enabledMarkerTriggerMarks = filteredMarkers.map((m) =>
    createMarkerRule({
      value: m.markerDate,
      name: m.name,
      stroke: 'transparent',
      strokeWidth: 10,
      strokeDasharray: 0,
      showTip: false,
      className: 'markerRuleTrigger'
    })
  )

  // The size of the bars we want to be smaller when we have less than 15 months of data.
  const barInsets = diffDays < 365 * 1.5 ? 10 : 0

  // To better control the range we'll use d3 to create a UTC scale for the dates.
  const { xConfig } = createXDomainConfig({ chartDates, diffDays })

  // Takes in the order of the marks and creates an array of all the renderable marks to be
  // passed to the plot chart.
  const getOrderedMarks = useCallback(
    (marks: MarkNames[]): Plot.Markish[] => {
      return marks.reduce((acc, m) => {
        switch (m) {
          case MarkNames.GRADIENT:
            return [...acc, () => linearGradientDef]
          case MarkNames.MARKER:
            return [...acc, enabledMarkerMarks, enabledMarkerTriggerMarks]
          case MarkNames.BENCHMARK:
            return [...acc, enabledBenchmarkMarks, enabledBenchmarkTriggerMarks]
          case MarkNames.EUI:
            return getRenderEuiBarMarks(acc, euiItems, euiEnabled, barInsets)
          case MarkNames.EUI_TIP:
            return getRenderTipMarks(
              acc,
              euiItems,
              (d) => `${d?.eui?.toFixed(0)} KBtu/ft²/year`,
              euiEnabled
            )
          case MarkNames.ECI:
            return getRenderEciBarMarks(acc, eciItems, eciEnabled, barInsets)
          case MarkNames.ECI_TIP:
            return getRenderTipMarks(
              acc,
              eciItems,
              (d) => `${d?.eci?.toFixed(2)} $/ft²/year`,
              eciEnabled
            )
          default:
            return acc
        }
      }, [])
    },
    [
      euiMarksOrder,
      eciMarksOrder,
      eciEnabled,
      euiEnabled,
      euiItems,
      eciItems,
      barInsets,
      enabledMarkerMarks,
      enabledBenchmarkMarks
    ]
  )

  const euiMarks = [...getOrderedMarks(euiMarksOrder), Plot.ruleY[0]]

  const eciMarks = [...getOrderedMarks(eciMarksOrder), Plot.ruleY[0]]

  // Configure event handlers for the elements that trigger the reordering of the marks.
  const euiBeforeAppendCallback = useCallback(
    (plot: Plot.Plot) => setupPlotEvents(plot, setEuiPlotValueType),
    []
  )
  const eciBeforeAppendCallback = useCallback(
    (plot: Plot.Plot) => setupPlotEvents(plot, setEciPlotValueType),
    []
  )

  // Remove the event listeners from the benchmarkRuleTrigger and markerRuleTrigger marks.
  const euiBeforeRemoveCallback = useCallback(cleanupPlotEvents, [])
  const eciBeforeRemoveCallback = useCallback(cleanupPlotEvents, [])

  return (
    <Flex direction="column" gap={8}>
      {euiEnabled && (
        <PlotChart
          clip={false}
          y={{
            grid: true,
            label: 'KBtu/ft²/year',
            ticks: 8,
            tickSize: 0,
            domain: euiYDomain,
            tickFormat: formatEuiYAxisValues,
            nice: true
          }}
          x={xConfig}
          marks={euiMarks}
          beforeAppend={euiBeforeAppendCallback}
          beforeRemove={euiBeforeRemoveCallback}
          autoSize={true}
        />
      )}
      {eciEnabled && (
        <PlotChart
          clip={false}
          y={{
            grid: true,
            label: '$/ft²/year',
            ticks: 8,
            tickSize: 0,
            domain: eciYDomain,
            tickFormat: (d) => `${d.toFixed(2)}`,
            nice: true
          }}
          x={xConfig}
          marks={eciMarks}
          beforeAppend={eciBeforeAppendCallback}
          beforeRemove={eciBeforeRemoveCallback}
          autoSize={true}
        />
      )}
    </Flex>
  )
}

// Takes in the accumulated marks, the eui items, and the eui enabled flag and returns the marks for the eui tip.
// If EUI is disabled we simply return the accumulated marks.
const getRenderTipMarks = (
  acc: Plot.Markish[],
  items: ChartDataItem[],
  title: string | ((d: any) => string),
  enabled: boolean
) => {
  if (enabled) {
    return [
      ...acc,
      Plot.tip(
        items,
        Plot.pointerX({
          x: 'date',
          stroke: 'none',
          frameAnchor: 'bottom',
          maxRadius: 10,
          title: title
        })
      )
    ]
  }

  return acc
}

const getRenderEuiBarMarks = (
  acc: Plot.Markish[],
  items: ChartDataItem[],
  enabled: boolean,
  barInsets?: number
) => {
  if (enabled) {
    return [
      ...acc,
      Plot.barY(items, {
        x: 'date',
        y: 'eui',
        fill: 'url(#chartLinearGradient)',
        rx: '10',
        ry: '10',
        insetRight: barInsets,
        insetLeft: barInsets,
        clip: true
      })
    ]
  }

  return acc
}

const getRenderEciBarMarks = (
  acc: Plot.Markish[],
  items: ChartDataItem[],
  enabled: boolean,
  barInsets?: number
) => {
  if (enabled) {
    return [
      ...acc,
      Plot.barY(items, {
        x: 'date',
        y: 'eci',
        fill: '#FFF1E0',
        rx: '10',
        ry: '10',
        insetRight: barInsets,
        insetLeft: barInsets,
        clip: true
      })
    ]
  }

  return acc
}

// Take in the a plot chart value type and return the order of the ECI marks.
const getEciOrder = (valueType: PlotChartValueType) => {
  switch (valueType) {
    case PlotChartValueType.BENCHMARK:
    case PlotChartValueType.MARKER:
      return [MarkNames.ECI, MarkNames.MARKER, MarkNames.ECI_TIP]
    default:
      return ECI_MARKS_ORDER
  }
}

// Take in the a plot chart value type and return the order of the EUI marks.
const getEuiOrder = (valueType: PlotChartValueType) => {
  switch (valueType) {
    case PlotChartValueType.BENCHMARK:
    case PlotChartValueType.MARKER:
      return [
        MarkNames.GRADIENT,
        MarkNames.EUI,
        MarkNames.BENCHMARK,
        MarkNames.MARKER,
        MarkNames.EUI_TIP
      ]
    default:
      return EUI_MARKS_ORDER
  }
}
