export const light = {
  backgroundColor: '{colors.white}',
  color: '{colors.neutral.90}',
  borderRadius: '30px',
  borderWidth: '0',

  hover: {
    backgroundColor: '{colors.neutral.20}'
  }
}
