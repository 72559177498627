import 'react-image-gallery/styles/scss/image-gallery.scss'
import { ImageGalleryItem } from 'src/denali-components/ImageGallery/ImageGalleryItem'
import { ImageGalleryWrapper } from 'src/denali-components/ImageGallery/ImageGalleryWrapper'
import _get from 'lodash/get'
import { DetailFindingDescription } from 'src/denali-pages/Opportunities/OpportunityDetail/DetailFindingDescription'
import { useMemo } from 'react'

interface DetailFindingsProps {
  images: Array<any>
  findings: Array<any>
}

export const DetailFindings = ({ images, findings }: DetailFindingsProps) => {
  const items = getItems(findings, images)

  return (
    <ImageGalleryWrapper
      items={items}
      images={images}
      findings={findings}
      isLazyLoading={true}
      showBullets={true}
      showNav={false}
      showPlayButton={false}
      showThumbnails={false}
      autoPlay={true}
      slideInterval={10000}
      infinite={true}
    />
  )
}

const getItems = (findings, images) => {
  return useMemo(() => {
    return [
      ...findings.map((finding, i) => ({
        renderItem: () => (
          <ImageGalleryItem
            key={i}
            item={{
              original: finding?.original,
              originalTitle: finding?.name,
              originalAlt: finding?.name
            }}
            renderDescription={<DetailFindingDescription finding={finding} />}
            isLoadingImage={true}
          />
        )
      })),
      ...images.map(
        ({ originalTitle, caption, original, name, buildingId }) => ({
          original,
          originalTitle,
          caption,
          name,
          buildingId
        })
      )
    ]
  }, [findings, images])
}
