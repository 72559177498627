export const light = {
  1: {
    fontSize: '{fontSizes.xl}',
    fontWeight: '{fontWeights.bold}',
    lineHeight: 'normal'
  },
  2: {
    fontSize: '{fontSizes.xl}',
    fontWeight: '{fontWeights.medium}',
    lineHeight: 'normal'
  }
}
