import configs from 'src/components/legacy/common/datepicker/configs.js'
import { emailValidation } from 'src/components/legacy/common/form'
import {
  MAX_SIZE_OF_NUMBERS,
  MAX_LENGTH_OF_NUMBERS
} from 'src/components/legacy/common/consultation'
import moment from 'moment'
import { Trans } from 'react-i18next'

export const validateIfExists = (validation) => (value, values, options) =>
  isRequired(value) ? validation(value, values, options) : true

export const isRequired = (val) =>
  isNotNull(val) && isNotUndefined(val) && String(val).length > 0

export const isNotNull = (val) => val !== null

export const isNotUndefined = (val) => val !== undefined

const toFloat = (val) => parseFloat(val, 10)

const isNumber = (val) => !isNaN(val)

const isPositive = (number) => number > 0

const isNonNegative = (number) => number >= 0

const isInteger = (val) =>
  ((number) => isNumber(number) && number % 1 === 0)(toFloat(val))

export const isFloat = (val) => Number(val) === val && val % 1 !== 0

export const isPositiveNumber = (val) =>
  ((number) => isNumber(number) && isPositive(number))(toFloat(val))

export const isPositiveInteger = (val) =>
  ((number) => isInteger(number) && isPositive(number))(toFloat(val))

export const isNonNegativeInteger = (val) =>
  ((number) => isInteger(number) && isNonNegative(number))(toFloat(val))

export const isValidDate = (date) =>
  moment(date, configs.DATE_DISPLAY_FORMAT).isValid()

export const isDateBeforeToday = (date) => isDateLessThan(date, moment())

export function isDateLessThan(date1, date2, dateDiff = 0) {
  const startDate = moment(date1)
  startDate.add(dateDiff, 'days')
  const result = startDate.isBefore(date2, 'day')
  return result
}

export function isDateGreaterThan(date1, date2, dateDiff = 0) {
  const startDate = moment(date1)
  startDate.add(dateDiff, 'days')
  const result = startDate.isAfter(date2, 'day')
  return result
}

export function isValidName(name) {
  return Boolean(name.trim())
}

export function numberInputValidation(event) {
  if (event.charCode > 57 || event.charCode < 48) {
    event.preventDefault()
  }
}

export function validateEmail(value) {
  let error
  const { isValid, errorMessage } = emailValidation(value)()
  if (!isValid) {
    error = errorMessage
  }
  return error
}

export const valueAssumptionValidationMessages = (
  value,
  { allowEmpty = true } = {}
) => {
  const notCorrectValue = allowEmpty ? value === undefined : !value
  if (notCorrectValue) {
    return "Please input correct value."
  } else if (
    Number(value) > MAX_SIZE_OF_NUMBERS ||
    String(value).length > MAX_LENGTH_OF_NUMBERS
  ) {
    return (
      <Trans
        i18nKey={'errors:enterValidNumber'}
        values={{ maxNumber: MAX_SIZE_OF_NUMBERS }}
      />
    )
  } else if (Number(value) < 0) {
    return <Trans i18nKey={'errors:enterPositiveValue'} />
  } else if (isFloat(value)) {
    return <Trans i18nKey={'errors:enterIntegerValue'} />
  }
}
