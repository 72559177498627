import translate, { TranslateComponent } from 'src/common/translations'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import { ACTIONS } from 'src/constants'
import { Chip } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import ClientSideTable from '../../components/Table/clientSideTable'
import { faTachographDigital } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Flex } from '@aws-amplify/ui-react'
import { IconCell, IconSize } from 'src/denali-components/Table/IconCell'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import styles from 'src/denali-components/Table/table.module.scss'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'

export const NotesList = ({
  rows,
  rowAction,
  search,
  searchFields,
  isDenaliTable,
  testName,
  listDataIsLoading,
  noTableDataAction,
  isFilterChange,
  optionsTranslated,
  tableHandlerOptions,
  userInfo,
  userAccess,
  setDeleteNote,
  mode,
  setMode,
  notesInput,
  setNotesInput,
  initialValues
}) => {
  const noteTypes = getNoteTypes()
  const filtersList = getFiltersList(noteTypes)
  const hasEditAccess = accessControlFunc({
    id: 'tc.pages.notes.delete',
    userAccess
  })
  const hasDeleteAccess = accessControlFunc({
    id: 'tc.pages.notes.delete',
    userAccess
  })

  return (
    <ClientSideTable
      rows={rows}
      rowAction={rowAction}
      header={getNameHeadings(optionsTranslated)}
      defaultSortColumn={1}
      columnOrderDown={false}
      search={search}
      searchFields={searchFields}
      rowControl={(row) =>
        getRowControls(
          row,
          userInfo,
          setDeleteNote,
          setMode,
          setNotesInput,
          initialValues,
          hasEditAccess,
          hasDeleteAccess,
          tableHandlerOptions
        )
      }
      filtersList={filtersList}
      isDenaliTable={isDenaliTable}
      testName={testName}
      tableClassName={styles.notesPage}
      listDataIsLoading={listDataIsLoading}
      noTableDataTitleText={tableHandlerOptions.noNotes}
      noTableDataAction={noTableDataAction}
      isFilterChange={isFilterChange}
    />
  )
}

const getFiltersList = (options) => {
  return [
    {
      key: 'type',
      filterLabel: 'All Note Types',
      id: 'typeSelectorFilter',
      defaultLabel: 'All Note Types',
      selectedValue: 'default',
      options: options
    }
  ]
}

const getNoteTypes = () => {
  return [
    { name: 'Building', value: 'Building' },
    { name: 'Equipment', value: 'Equipment' },
    { name: 'Service Advisory', value: 'Service Advisory' },
    { name: 'Performance', value: 'Performance' }
  ]
}

const getNameHeadings = (optionsTranslated) => {
  return [
    {
      title: optionsTranslated.noteName,
      key: 'name',
      width: '50%',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.lastUpdated,
      key: 'updatedAt',
      type: 'date',
      width: '15%',
      sortField: 'createdAtEpoch',
      customComponent: lastUpdatedCell
    },
    {
      title: optionsTranslated.createdBy,
      key: 'createdByName',
      width: '15%',
      customComponent: createdByCell
    },
    {
      title: optionsTranslated.attachments,
      key: 'attachmentNames',
      width: '20%',
      hideFromSort: true,
      customComponent: attachmentsCell
    }
  ]
}

const titleCell = (row) => {
  return (
    <IconCell iconType={row.type} iconSize={IconSize.small}>
      <div className={styles.title} data-testid="NotesRowTitle">
        <Tooltip>
          <TooltipTrigger>
            {/* TODO: This tooltip needs to be conditional once we get that setup */}
            <TranslateComponent>
              {row.name.length > 35
                ? `${row.name.substring(0, 35)}...`
                : row.name}
            </TranslateComponent>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>{row.name}</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      </div>
      <Chips>
        {row.type && (
          <Tooltip>
            <TooltipTrigger>
              <Chip text={row.type} icon={row.type} />
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Type</TranslateComponent>
            </TooltipContent>
          </Tooltip>
        )}
        {row.equipment && (
          <Tooltip>
            <TooltipTrigger>
              <Chip text={row.equipmentName} icon={faTachographDigital} />
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Equipment</TranslateComponent>
            </TooltipContent>
          </Tooltip>
        )}
      </Chips>
    </IconCell>
  )
}

const lastUpdatedCell = (row) => {
  return (
    <Flex direction="column" gap={4}>
      <div className="body-md no-wrap">
        <TranslateComponent>Last Updated</TranslateComponent>
      </div>
      <div className="body-lg no-wrap">
        <TranslateComponent>{row.updatedAt}</TranslateComponent>
      </div>
    </Flex>
  )
}

const createdByCell = (row) => {
  return (
    <Flex gap={16} alignItems="center">
      <UserNameBubble
        key="createdBy"
        showFullName={false}
        showTooltip={false}
        firstName={
          row?.createdByName.length > 2 ? row.createdByName : 'Unknown'
        }
        lastName={''}
        subLine={''}
        iconOnly
      />
      <Flex direction="column" gap={4}>
        <Tooltip>
          <TooltipTrigger>
            <div className="body-lg no-wrap">
              <TranslateComponent>
                {row.createdByName.length > 2 ? row.createdByName : 'Unknown'}
              </TranslateComponent>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Created By</TranslateComponent>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <div className="body-md no-wrap">
              <TranslateComponent>{row.updatedAt}</TranslateComponent>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Created On</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      </Flex>
    </Flex>
  )
}

const attachmentsCell = (row) => {
  return <>{row.attachmentNames}</>
}

const getRowControls = (
  control,
  userInfo,
  setDeleteNote,
  setMode,
  setNotesInput,
  initialValues,
  hasEditAccess,
  hasDeleteAccess,
  tableHeaderOptions
) => {
  const controlOutput = []
  {
    userInfo?.id === control.userId &&
      hasEditAccess &&
      controlOutput.push({
        text: tableHeaderOptions.edit,
        action: (control) => {
          setMode(ACTIONS.EDIT)
          setNotesInput({
            ...initialValues,
            id: control.id,
            name: control.name,
            histories: control.histories.items,
            userId: control?.user?.id,
            currentUserId: userInfo?.id,
            equipment: control?.equipment,
            type: control?.type,
            createdAtDetailTimestamp: control?.createdAtDetailTimestamp,
            createdByName: `${
              control?.user?.firstName ? control.user.firstName : ''
            } ${control?.user?.lastName ? control.user.lastName : ''}`,
            attachments: control.attachments.items.map((item) => ({
              ...item,
              fileName: item.name,
              filePath: `${item.buildingId}/${item.name}`,
              fileSize: item.sizeInBytes,
              title: item.title,
              timestamp: item.attachedAt,
              buildingId: item.buildingId,
              description: item.description
            }))
          })
        }
      })
  }
  {
    userInfo?.id === control.userId &&
      hasDeleteAccess &&
      controlOutput.push({
        text: tableHeaderOptions.delete,
        action: (data) => {
          setDeleteNote(data)
        }
      })
  }

  return controlOutput
}
