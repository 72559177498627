import { DenaliLoader } from './DenaliLoader'

import styles from './loader-overlay.module.scss'

export const LoaderOverlay = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div
      className={`${styles.loaderOverlay} ${isLoading ? styles.visible : ''}`}
    >
      <DenaliLoader />
    </div>
  )
}
