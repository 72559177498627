import {
  faEye,
  faEyeSlash,
  faQuestion
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './table.module.scss'

export const Visibility = ({ value }: Props) => {
  const normalizedValue = value.trim().toLowerCase()
  const icon =
    normalizedValue === 'on'
      ? faEye
      : normalizedValue === 'off'
      ? faEyeSlash
      : faQuestion

  return (
    <div className={styles.visibilityIcon} data-visible={normalizedValue}>
      <FontAwesomeIcon icon={icon} />
    </div>
  )
}

interface Props {
  value: 'ON' | 'OFF'
}
