import Table from '../../components/Table/clientSideTable'
import { ACTIONS } from 'src/constants'
import { IMarker } from './types'
import { useEffect, useState } from 'react'
import translate, { TranslateComponent } from 'src/common/translations'
import { UiMode } from 'src/redux/types/AppTypes'
import { EventMarkersList } from 'src/denali-pages/EventMarkers/EventMarkersList'
import store, { useAppDispatch } from 'src/redux/store'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/RootState'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import moment from 'moment'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

const EventMarkers = ({
  uiMode,
  responseTime,
  rows,
  setMode,
  markerTypes,
  buildingId,
  accountId,
  setMarkerInput,
  reloadEvents,
  setReloadEvents,
  setDeleteMarkerId,
  setShowDeleteDialog,
  showSpinner
}) => {
  const isDenali = uiMode === UiMode.denali

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
    }
  }, [reloadEvents])

  let selectedLanguage = store?.getState().appData?.selectedLanguage
  selectedLanguage = selectedLanguage ? selectedLanguage : "en"

  const nameHeadings = [
    {
      title: <TranslateComponent>Marker Name</TranslateComponent>,
      key: 'name',
      maxWidth: '200px'
    },
    {
      title: '',
      key: 'descriptionIcon',
      type: 'icon',
      disabled: true
    },
    {
      title: <TranslateComponent>Building Name</TranslateComponent>,
      key: 'buildingNames',
      maxWidth: '120px'
    },
    {
      title: <TranslateComponent>Marker Date</TranslateComponent>,
      key: 'markerDate',
      maxWidth: '120px',
      sortField: 'markerDateSort',
      customComponent: (row) => {
        return (selectedLanguage === "en" 
          ? row.markerDate 
          : moment(row.markerDate).format(getDateFormatByLocale('MM/DD/YYYY')))}
    },
    {
      title: <TranslateComponent>Marker Type</TranslateComponent>,
      key: 'type',
      maxWidth: '120px'
    }
  ]

  const optionsTranslated = {
    name: translate('Marker Name'),
    building: translate('Building Name'),
    date: translate('Date Created'),
    description: translate('Description'),
    descriptionIcon: translate('Description Icon'),
    type: translate('Marker Type'),
    copyOfTranslate: translate("Copy of"),
    copyMarkerTranslate: translate("Copy Marker"),
    editTranslate: translate("Edit"),
    deleteTranslate: translate("Delete")
  }

  const appDispatch = useAppDispatch();
  const { translatedJSON } = useSelector((state: RootState) => state?.translationCombinedSlice)


  useEffect(()=>{
    const translateFinalSummary = markerTypes.reduce((acc, item) => {
      if (item.key && !acc.hasOwnProperty(item.key)) {
        acc[item.key] = item.key;
      }
      if (item.value && !acc.hasOwnProperty(item.value)) {
        acc[item.value] = item.value;
      }
      if (item.name && !acc.hasOwnProperty(item.name)) {
        acc[item.name] = item.name;
      }
      return acc;
    }, {});

    appDispatch(translateJSON({ json: translateFinalSummary }) as any);
  },[])

  const [markerTranslate,setMarkerTranslate] = useState(markerTypes)

  useEffect(()=>{
    if(translateJSON){
      setMarkerTranslate(markerTypes.map((item)=>{
        return{
          key: translatedJSON[item.key] || item.key,
          name: translatedJSON[item.name] || item.name,
          value: translatedJSON[item.value] || item.value
        }
      }))
    }
  },[translatedJSON])

  const rowControl = [
    {
      text: isDenali ? optionsTranslated.copyMarkerTranslate : optionsTranslated.copyMarkerTranslate,
      action: (data: IMarker) => {
        const { name, markerDate, description, type, id, buildingIds } = data
        setMode(ACTIONS.COPY)
        setMarkerInput({
          accountId,
          markerDate,
          name: `${optionsTranslated.copyOfTranslate} ${name}`,
          type,
          description,
          id,
          buildingsList: buildingIds,
          buildingIds: buildingIds
        })
      }
    },
    {
      text: optionsTranslated.editTranslate,
      action: (data: IMarker) => {
        const { name, markerDate, description, type, id, buildingIds } = data
        setMode(ACTIONS.EDIT)
        setMarkerInput({
          id,
          buildingsList: buildingIds,
          accountId,
          buildingIds: buildingIds,
          markerDate,
          name,
          type,
          description
        })
      }
    },
    {
      text: optionsTranslated.deleteTranslate,
      action: (data: IMarker) => {
        if (data?.id) {
          setDeleteMarkerId(data?.id)
          const { name, markerDate, description, type, buildingIds } = data
          setMarkerInput({
            buildingIds: buildingIds,
            accountId,
            markerDate,
            name,
            type,
            description
          })
          setShowDeleteDialog(true)
        }
        // to be handled when id is missing and show loader?
      }
    }
  ]

  const filtersList = [
    {
      label: 'All Marker Types',
      key: 'type',
      id: 'typeSelectorFilter',
      filterLabel: 'Marker Types',
      defaultLabel: 'All Marker Types',
      selectedValue: 'default',
      options: markerTranslate
    }
  ]

  if (isDenali) {
    return (
      <EventMarkersList
        rows={rows}
        optionsTranslated={optionsTranslated}
        rowControl={rowControl}
        filtersList={filtersList}
        showSpinner={showSpinner}
        listDataIsLoading={showSpinner}
      />
    )
  }

  return (
    <Table
      key={`eventMarkersTable-${buildingId}`}
      rows={rows}
      header={nameHeadings}
      loadTime={responseTime}
      search={true}
      showSpinner={showSpinner}
      searchFields={['name', 'building.name']}
      rowControl={rowControl}
      filtersList={filtersList}
      isFilterChange={true}
    />
  )
}

export default EventMarkers
