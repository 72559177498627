import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { useFormik } from 'formik'
import { ChangeEvent, useState } from 'react'
import { useMutation } from 'src/hooks/APIHooks'
import { ERROR_MESSAGES } from "./formValidator"
// components
import Modal from 'src/components/legacy/components/modal/modal'

import { ACTIONS } from 'src/constants'

import validationSchema from './formValidator'
import { CREATE_GROUP, UPDATE_BUILDING_func, UPDATE_GROUP } from '../graphql'
import styled from 'styled-components'
import CheckBox from 'src/denali-ui/components/Checkbox'
import SelectBuildings from "../../organizationManagement/createOrganization/select-buildings"
import Table from "src/components/Table/clientSideTable"
import { API } from 'aws-amplify'
import { stitchedAWSJSONQuery } from 'src/common/graphqlHelperFunctions'
import Select from 'src/components/legacy/components/select/select'
import translate, { TranslateComponent } from 'src/common/translations'

const getQueryResponseMap = (values) => (response) =>  response.map((res) => ({
  value: res.id,
  children: res.name,
  selected: values.some(e => e.id === res.id),
  disabled: false,
  ...res
}))

const nameHeadings = [
  {
    name: 'name',
    title: <TranslateComponent>Building Name</TranslateComponent>,
    key: 'name',
    maxWidth: '120px'
  },
  {
    name: 'address',
    title: <TranslateComponent>Address</TranslateComponent>,
    key: 'address',
    maxWidth: '120px'
  },
  {
    name: 'city',
    title:<TranslateComponent>City</TranslateComponent> ,
    key: 'city',
    maxWidth: '120px'
  },
  {
    name: 'state',
    title: <TranslateComponent>State/Region</TranslateComponent>,
    key: 'state',
    maxWidth: '120px'
  },
  {
    name: 'country',
    title:<TranslateComponent>Country</TranslateComponent> ,
    key: 'country',
    maxWidth: '120px'
  },
  {
    name: "actions",
    title: "",
    key: "actions",
    sortField: false,
    disabled: true
  }
]


const ModalDivSpacer = styled.div`
& .modal-text-wrapper .custom-label{
  padding: 0px !important;
  }
`

const ACTION_HEADERS = {
  [ACTIONS.ADD]: 'Create Group',
  [ACTIONS.EDIT]: 'Edit Group',
  [ACTIONS.VIEW]: 'View Group',
  [ACTIONS.COPY]: 'Copy Group'
}

const PageDetail = ({
  mode,
  setMode,
  input,
  setInput,
  initialValues,
  setReloadEvents
}) => {

  const labelsTranslated = {
    buildingGroup: translate("BuildingGroup"),
    applicationGroup: translate("ApplicationGroup")
  }

  const [buildings, setBuildings] = useState([])

  const getModalHeading = () => {
    const heading =
      mode === ACTIONS.ADD || mode === ACTIONS.COPY
        ? ``
        : input?.name && `: ${input?.name}`
    return translate(`${ACTION_HEADERS[mode]}${heading}`)
  }

  const { onSubmit: createMutation } = useMutation({
    query: CREATE_GROUP,
  })

  const { onSubmit: updateMutation } = useMutation({
    query: UPDATE_GROUP,
  })
  const formik = useFormik({
    initialValues: input,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const {
        name,
        description,
        accessType,
        resourceType,
        isActive,
        selectedBuildings,
        initialSelectedBuildings
      } = values
      if (mode === ACTIONS.EDIT) {
        const buildingsToUpdate = selectedBuildings.map(e => {
          let groupAdmin = e?.groupAdmin || []
          let groupUser = e?.groupUser || []
          let modified = true
          if (values.accessType === "Admin") {
            if (!groupAdmin?.length) {
            groupAdmin = [name]
            } else if (groupAdmin.findIndex(i => i === name) === -1) {
              groupAdmin.push(name)
            } else {
              modified = false
            }
          } else {
          if (!groupUser?.length) {
            groupUser = [name]
            } else if (groupUser.findIndex(i => i === name) === -1) {
              groupUser.push(name)
            } else {
              modified = false
            }
          }
          return modified ? ({id: e.id, groupAdmin, groupUser}) : null
        }).filter(x => x !== null)
        const buildingsToRemove = initialSelectedBuildings.filter(e => selectedBuildings.findIndex(i => i.id === e.id) === -1).map(e => ({id: e.id, groupAdmin: e?.groupAdmin?.filter(e => e !== name) || [], groupUser: e?.groupUser?.filter(e => e !== name) || []}))
        Promise.all([
          buildingsToUpdate?.length || buildingsToRemove?.length ? stitchedAWSJSONQuery(API.graphql({
          query: UPDATE_BUILDING_func([...buildingsToUpdate || [], ...buildingsToRemove || []]), 
        })) : [],
        updateMutation({
          input: {
            name,
            description,
            accessType,
            resourceType,
            isActive,
            nameAuth: name
          }
        })
        ]).then(res2 => {
            if (res2){
              closeDialog()
              setReloadEvents(true)
            }
          })
      } else {
        createMutation({
          input: {
            name,
            description,
            accessType,
            resourceType,
            isActive,
            nameAuth: name
          }
        }).then(async res => {
          if (res) {
            const buildingsToUpdate = selectedBuildings.map(e => {
            let groupAdmin = e?.groupAdmin || []
            let groupUser = e?.groupUser || []
            // values.accessType === "User" ? "Admin" : "User"
            if (values.accessType === "Admin") {
              if (!groupAdmin) {
              groupAdmin = [name]
              } else if (groupAdmin.findIndex(i => i === name) === -1) {
                groupAdmin.push(name)
              }
            } else {
            if (!groupUser) {
              groupUser = [name]
              } else if (groupUser.findIndex(i => i === name) === -1) {
                groupUser.push(name)
              }
            }
            return ({id: e.id, groupAdmin: groupAdmin || [], groupUser: groupUser || []})
          })
          await Promise.all([
            buildingsToUpdate?.length ? stitchedAWSJSONQuery(API.graphql({
              query: UPDATE_BUILDING_func(buildingsToUpdate)
            })) : []
          ]).then(res2 => {
              if (res2){
                closeDialog()
                setReloadEvents(true)
              }
            })
          }
          
        })
      }
    }
  })

  const { values, errors } = formik

  const getButtons = (submitForm, isFormValid = false) => {
    return mode === ACTIONS.VIEW
      ? [{ text: "Close", handleClick: closeDialog }]
      : [
          {
            text: "Save",
            type: 'submit',
            handleClick: submitForm,
            disabled: !isFormValid
          },
          {
            text: "Cancel",
            type: 'cancel',
            handleClick: closeDialog
          }
        ]
  }

  const closeDialog = () => {
    setMode(null)
    setInput(initialValues)
  }

  const modalConfig = {
    gray: true,
    className: 'wide-modal',
    heading: getModalHeading(),
    buttons: getButtons(formik.handleSubmit, formik.isValid),
    handleClose: closeDialog
  }

  const handleInputChange = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  return (
    <Modal {...modalConfig}>
      <ModalDivSpacer>
        <div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextInput
              labelText="Group Name"
              defaultValue={values?.name}
              name="name"
              disabled={mode === ACTIONS.EDIT}
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(name, value)
              }
              hasError={errors?.['name']}
              errorMessage={ERROR_MESSAGES["name"]}
            />
            <TextAreaInput
              labelText="Group Description"
              defaultValue={values?.description}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('description', value)
              }
              name="description"
              isRequired={false}
            />
           <TranslateComponent> Resource Type: </TranslateComponent>
            <br />
            <Select
           options={[
            { key: 'BuildingGroup', value: labelsTranslated.buildingGroup },
            { key: 'ApplicationGroup', value: labelsTranslated.applicationGroup }
          ]}
            onChange={e => {
                handleInputChange('resourceType', e)
                handleInputChange('accessType', e === "ApplicationGroup" ? "Application" : "User")
            }}
            selectedItem={<TranslateComponent>{values.resourceType}</TranslateComponent>}
            isUserdefinedOptions={true}
            />
            
            {values.resourceType === "BuildingGroup" ? <><br/>
            <br/>
            <TranslateComponent>Admin:</TranslateComponent> &nbsp;
            <CheckBox
                onClick={() => {
                  handleInputChange('accessType', values.accessType === "User" ? "Admin" : "User")
                }}
              checked={values.accessType === "Admin"}
              />
            </>
            : ""
              }
            {values.resourceType === "BuildingGroup" ? <>
              <SelectBuildings
                initialValues={values?.selectedBuildings}
                onButtonClick={(e) => {
                  const newSelectedBuildings = [...e]
                  formik.setFieldValue("selectedBuildings", newSelectedBuildings )
                  setBuildings(buildings.filter(b => !newSelectedBuildings.map(e => e.id).includes(b.id)))
                }}
                title={"Add Building to Group"}
                description={"You must have permission to a building in order to add it to this group."}
                buttonTitle={"Add"}
                queryResponseMap={input => getQueryResponseMap(input)}
              />
              <Table
              key="locationDevicesTable"
              rows={values.selectedBuildings}
              header={nameHeadings}
              search={false}
              rowControl={[
                {
                  text: <TranslateComponent>Remove</TranslateComponent>,
                  action: (e) => {
                    formik.setFieldValue("selectedBuildings", values.selectedBuildings.filter(b => b.id !== e.id) )
                    setBuildings(buildings.filter(b => b.id !== e.id))
                  }
                }
              ]}
              
            />
            </> : ""}
            
          </form>
        </div>
      </ModalDivSpacer>
    </Modal>
  )
}
export default PageDetail
