import React, { useEffect } from "react"
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import Modal from 'src/denali-ui/components/Modal'
import { func, bool } from "prop-types"

/**
 * Component to prompt user when navigating away from a page witout saving changes
 * Referenced emerald-ui/src/react/shared/RouterPrompt/RouterPrompt.jsx from Gen4 TraneConnect
 * Needed to make changes due to newer version of react-router-dom. For that, referenced example at:
 * https://github.com/remix-run/react-router/blob/main/examples/navigation-blocking/src/app.tsx
 */

const RouterPrompt = props => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.shouldBlockNavigation() && currentLocation.pathname !== nextLocation.pathname
  })

  useEffect(() => {
    return () => {
      blocker?.reset?.()
    }
  }, [])

  return (
    <>
      {blocker.state === "blocked"
      && <Modal
        heading="Continue without saving?"
        modalHeight="auto"
        handleClose={() => blocker.reset()}
        buttons={[
          {
            variant: 'secondary',
            text: 'Continue without saving',
            handleClick: () => { 
              blocker.proceed()
            }
          },
          {
            variant: 'primary',
            text: 'Save',
            handleClick: () => {
              props.handleSave?.()
              props.setNextLocation?.(blocker?.location?.pathname)
              props.proceedOnSave ? blocker.proceed() : blocker.reset()
            }
          }
        ]}>
        <p>If you continue without saving, any changes will be lost.</p>
        <br />
        <p>Would you like to save?</p>
      </Modal>}
    </>
  )
}

RouterPrompt.propTypes = {
  /** Function to determine if user should be prompted about unsaved changes */
  shouldBlockNavigation: func.isRequired,

  /** Extra action to take place when clicking "Save" */
  handleSave: func,

  /** Boolean to indicate if navigation should proceed when clicking save or not so that it can be handled differently by page */
  proceedOnSave: bool,

  /** Function to set next location in page component if page needs to handle navigation differently */
  setNextLocation: func,
}


export default RouterPrompt
