import {
  TIS_EQUIPMENT_LIST,
  DATA_SOURCE_NAME,
  NAME,
  AUTOMATION_SYSTEM_LIST
} from 'src/pages/equipmentSetup/constants'
import { accessControlFunc } from 'src/components/accessControl'
import DeleteEquipmentDialog from 'src/pages/equipmentSetup/DeleteEquipmentDialogue/DeleteEquipmentDialog.jsx'
import translate, { TranslateComponent } from 'src/common/translations'
import ClientSideTable from 'src/components/Table/clientSideTable'
import { Tabs, TabItem } from '@aws-amplify/ui-react'
import tabStyles from 'src/denali-components/Tabs/tabs.module.scss'
import tableStyles from 'src/denali-components/Table/table.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faWavePulse
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { DenaliTableStyle } from 'src/denali-components/Table/table.d'
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from 'src/denali-components/Tooltip/Tooltip'

export const EquipmentSetupTabbedList = ({
  setListType,
  setActiveColumn,
  rowData,
  rowActionFunc,
  activeColumn,
  selectedFieldFilter,
  isFilterChange,
  searchAction,
  optionsTranslated,
  toggleDeleteDialog,
  userAccess,
  listDataIsLoading,
  // Delete Equipment Dialog props
  isDenali,
  isDeleteDialogShown,
  equipmentToDelete,
  setCurrentBuildingID,
  setEquipmentData,
  setEquipmentTISData,
  settisListData,
  getEquipments,
  equipmentListType,
  setEquipmentAutoData,
  setAllAutoListData
}) => {
  const tisListColumn = getTisListColumn(optionsTranslated)
  const automationColumn = getAutomationColumn(optionsTranslated)

  return (
    <>
      <div className={tableStyles.tableWrapper}>
        <Tabs
          defaultIndex={0}
          testId="equipSetup-tabs"
          className={tabStyles.denaliTabs}
        >
          <TabItem
            title={
              <>
                <FontAwesomeIcon icon={faWavePulse} />
                <TranslateComponent>TIS Equipment</TranslateComponent>
              </>
            }
            onClick={() => {
              setListType(TIS_EQUIPMENT_LIST)
              trackEvent(
                USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH,
                { tab: 'TIS Equipment' }
              )
            }}
            className={tabStyles.denaliTabItem}
          >
            <ClientSideTable
              testName={'EquipmentSetupPage'}
              tableClassName={'collapsibleRowsTable'}
              onUpdateControlsFunc={(controls) =>
                setActiveColumn(controls.activeColumn || 0)
              }
              rows={rowData}
              rowAction={rowActionCallback(rowActionFunc)}
              defaultSortColumn={activeColumn}
              header={tisListColumn}
              fixedLayout={false}
              search={true}
              searchFields={[NAME]}
              filtersList={selectedFieldFilter}
              isFilterChange={isFilterChange}
              searchAction={searchAction}
              listDataIsLoading={listDataIsLoading}
              isDenaliTable={true}
              denaliTableStyle={DenaliTableStyle.classic}
              tableRowOverflowHidden={false}
              rowControl={(row) =>
                getRowControl(
                  row,
                  userAccess,
                  optionsTranslated,
                  toggleDeleteDialog
                )
              }
            />
          </TabItem>
          <TabItem
            title={
              <>
                <FontAwesomeIcon icon={faClock} />
                <TranslateComponent>Automation System</TranslateComponent>
              </>
            }
            onClick={() => {
              setListType(AUTOMATION_SYSTEM_LIST)
              trackEvent(
                USER_EVENTS.UTILITY_MANAGEMENT.events.UTILITY_DATA_SWITCH,
                { tab: 'Automation System' }
              )
            }}
            className={tabStyles.denaliTabItem}
          >
            <ClientSideTable
              testName={'EquipmentSetupPage'}
              tableClassName={'collapsibleRowsTable'}
              onUpdateControlsFunc={(controls) =>
                setActiveColumn(controls.activeColumn || 0)
              }
              rows={rowData}
              rowAction={rowActionCallback(rowActionFunc)}
              defaultSortColumn={activeColumn}
              header={automationColumn}
              fixedLayout={false}
              search={true}
              searchFields={[DATA_SOURCE_NAME]}
              filtersList={selectedFieldFilter}
              isFilterChange={isFilterChange}
              searchAction={searchAction}
              listDataIsLoading={listDataIsLoading}
              isDenaliTable={true}
              denaliTableStyle={DenaliTableStyle.classic}
              tableRowOverflowHidden={false}
              rowControl={undefined}
            />
          </TabItem>
        </Tabs>
      </div>
      {isDeleteDialogShown && (
        <DeleteEquipmentDialog
          equipmentToDelete={equipmentToDelete}
          toggleDeleteDialog={toggleDeleteDialog}
          isDenali={isDenali}
          refetchEquipments={(status, equipmentId) => {
            if (status) {
              setCurrentBuildingID(null)
              setEquipmentData((equipmentData) =>
                equipmentData.filter((data) => data.id !== equipmentId)
              )
              if (equipmentListType === 'TISEquipmentList') {
                setEquipmentTISData((equipmentTISData) =>
                  equipmentTISData.filter((data) => data.id !== equipmentId)
                )
                settisListData((equipmentTISData) =>
                  equipmentTISData.filter((data) => data.id !== equipmentId)
                )
                getEquipments((equipmentTISData) =>
                  equipmentTISData.filter((data) => data.id !== equipmentId)
                )
              } else {
                setEquipmentAutoData((equipmentAutoData) =>
                  equipmentAutoData.filter((data) => data.id !== equipmentId)
                )
                setAllAutoListData((equipmentAutoData) =>
                  equipmentAutoData.filter((data) => data.id !== equipmentId)
                )
              }
            } else {
              setEquipmentData([])
              setCurrentBuildingID(null)
            }
          }}
        />
      )}
    </>
  )
}

const getTisListColumn = (optionsTranslated) => [
  {
    title: optionsTranslated.equipmentName,
    key: 'name',
    name: 'name',
    maxWidth: '400px'
  },
  {
    title: optionsTranslated.equipmentType,
    key: 'familyUI',
    name: 'family',
    customComponent: (row) => {
      return checkEmptyColumn(row.family)
    }
  },
  {
    title: optionsTranslated.serialNumber,
    key: 'unitSerialNumber',
    name: 'unitSerialNumber',
    customComponent: (row) => {
      return warningColumn(row?.family, row?.unitSerialNumber)
    }
  },
  {
    title: optionsTranslated.modelNumber,
    key: 'unitModelNumber',
    name: 'unitModelNumber',
    customComponent: (row) => {
      return warningColumn(row?.family, row?.unitModelNumber)
    }
  },
  {
    title: optionsTranslated.automation,
    key: 'automationSystem',
    name: 'automationSystem',
    customComponent: (row) => {
      return checkEmptyColumn(row.automationSystem)
    }
  },
  {
    title: optionsTranslated.dataName,
    key: 'dataSourceName',
    name: 'dataSourceName',
    customComponent: (row) => {
      return checkEmptyColumn(row.dataSourceName)
    }
  },
  {
    title: optionsTranslated.dataType,
    key: 'dataSourceType',
    name: 'dataSourceType',
    customComponent: (row) => {
      return checkEmptyColumn(row.dataSourceType)
    }
  },
  {
    title: optionsTranslated.controller,
    key: 'controllerType',
    name: 'controllerType',
    customComponent: (row) => {
      return checkEmptyColumn(row.controllerType)
    }
  }
]

const getAutomationColumn = (optionsTranslated) => [
  {
    title: optionsTranslated.objectName,
    key: 'dataSourceName',
    name: 'dataSourceName'
  },
  {
    title: optionsTranslated.automationSystem,
    key: 'automationSystem',
    name: 'automationSystem',
    customComponent: (row) => {
      return checkEmptyColumn(row.automationSystem)
    }
  },
  {
    title: optionsTranslated.objectType,
    key: 'dataSourceTypeUI',
    name: 'dataSourceType',
    customComponent: (row) => {
      return checkEmptyColumn(row.dataSourceType)
    }
  },
  {
    title: optionsTranslated.controllerType,
    key: 'controllerType',
    name: 'controllerType',
    customComponent: (row) => {
      return checkEmptyColumn(row.controllerType)
    }
  },
  {
    title: optionsTranslated.defaultCount,
    key: 'defaultCount',
    name: 'defaultCount'
  }
]

const warningColumn = (family, number) => {
  if (number) {
    return <span>{number}</span>
  }

  if (family === 'Chiller' && !number) {
    return <TranslateComponent>Undefined</TranslateComponent>
  } else if (family !== 'Chiller' && !number) {
    return <span>--</span>
  }
}

const checkEmptyColumn = (field) => {
  return field ? (
    <Tooltip>
      <TooltipTrigger>
        <TranslateComponent>{field}</TranslateComponent>
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>{field}</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  ) : (
    <span>--</span>
  )
}

const rowActionCallback = (rowActionFunc) => {
  return (event, row) => {
    if (row.id) {
      rowActionFunc(row)
    }
    return
  }
}

const getRowControl = (
  row,
  userAccess,
  optionsTranslated,
  toggleDeleteDialog
) => {
  const isStandalone = row?.equipmentType === 'StandAlone'
  const isCreattionTypeUser = row?.creationType === 'User'
  const isShowRowControl =
    !isStandalone &&
    isCreattionTypeUser &&
    accessControlFunc({
      id: 'tc.pages.equipment-setup.delete',
      userAccess
    })
  if (isShowRowControl) {
    return [
      {
        text: optionsTranslated.delete,
        action: (data: any) => {
          toggleDeleteDialog(
            {
              equipmentName: data.name,
              equipmentId: data.id
            },
            null
          )
        }
      }
    ]
  } else {
    return []
  }
}
