import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Loading } from '../Table/Loading'
import { useSelector } from 'react-redux'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'
import { useLocation, useMatches } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { DenaliRouteHandle } from '../Sidebar/MainNavigation'
import {
  getBuildingAndOrgFromId,
  getOrganizationFromId,
  LAST_BUILDING_KEY,
  LAST_ORGANIZATION_KEY,
  updateReduxBuilding,
  updateReduxOrganization
} from '../BuildingSelector/use-selected-building'
import { RouteTokenWrapper } from './RouteTokenWrapper'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { RouteBuildingOfferingsWrapper } from './RouteBuildingOfferingsWrapper'
import { useDispatch } from 'react-redux'
import { RouteHistoryWrapper } from './RouteHistoryWrapper'

type RouteUrlParamWrapperProps = {
  children: ReactNode
}

export const RouteUrlParamWrapper = ({
  children
}: RouteUrlParamWrapperProps) => {
  const {
    isLoading: storageLoading,
    currentId,
    skipTokensWhileLoading
  } = useSyncUrlAndLocalStorageWithRedux()
  const { isLoading: urlLoading } = useUpdateReduxOnSearchChange()
  const isLoading = useMemo(
    () => !!(storageLoading || urlLoading),
    [storageLoading, urlLoading]
  )

  return (
    <div data-testid="RouteUrlParamWrapper">
      <RouteHistoryWrapper>
        <RouteBuildingOfferingsWrapper>
          {isLoading && <Loading />}
          {!isLoading && (
            // Key forces tokens to refresh when a building changes
            // Replicates the way the old picker render the page so dependencies don't need to be changed on every page migration
            // skipTokensWhileLoading avoids a race condition with Cognito tokens, including the key ensures a rerender
            <RouteTokenWrapper key={`${currentId}-${skipTokensWhileLoading}`}>
              {children}
            </RouteTokenWrapper>
          )}
        </RouteBuildingOfferingsWrapper>
      </RouteHistoryWrapper>
    </div>
  )
}

const useUpdateReduxOnSearchChange = () => {
  const selectedFromRedux = useSelector(selectBuilding)
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedFromRedux) {
      // on first load, URL will populate from the building selector initialization and useSyncUrlAndLocalStorageWithRedux below
      setIsLoading(false)
      return
    }

    const { reduxOrganizationId, reduxBuildingId } = getIds(selectedFromRedux)
    const searchOrg = searchParams.get('organization')
    const searchBuilding = searchParams.get('location')
    const isOrgMatch = searchOrg === reduxOrganizationId
    const isBuildingMatch = searchBuilding === reduxBuildingId

    if (searchBuilding && !isBuildingMatch) {
      getBuildingAndOrgFromId(searchBuilding)
        .then(({ building, organization }) => {
          if (!building && !organization) return
          if (!building) {
            updateReduxOrganization(organization, dispatch)
            return
          }
          updateReduxBuilding(building, dispatch)
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.info('Error updating building from URL change', error)
          setIsLoading(false)
        })
      return
    }

    if (searchOrg && !isOrgMatch) {
      getOrganizationFromId(searchOrg)
        .then(({ organization }) => {
          if (!organization) return
          if (organization?.buildings?.length === 1) {
            updateReduxBuilding(organization.buildings[0], dispatch)
            return
          }
          updateReduxOrganization(organization, dispatch)
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          console.info('Error updating organization from URL change', error)
          setIsLoading(false)
        })
      return
    }

    setIsLoading(false)
  }, [searchParams])

  return {
    isLoading
  }
}

const useSyncUrlAndLocalStorageWithRedux = () => {
  const [currentId, setCurrentId] = useState(null)
  const selectedFromRedux = useSelector(selectBuilding)
  const matches = useMatches()
  const [, setSearchParams] = useSearchParams()
  const uiMode = useSelector(selectUiMode)
  const { state } = useLocation()
  const navigationProps = getNavigationProps(matches)
  const isUrlControlled = checkIsUrlControlled(navigationProps, uiMode)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isUrlControlled) {
      setIsLoading(false)
      setCurrentId(selectedFromRedux?.id || null)
      return
    }

    setIsLoading(true)
    if (!selectedFromRedux) return
    const { reduxOrganizationId, reduxBuildingId } = getIds(selectedFromRedux)

    setQueryParams(setSearchParams, reduxOrganizationId, reduxBuildingId, state)
    setLocalStorage(reduxOrganizationId, reduxBuildingId)
    setIsLoading(false)
    setCurrentId(selectedFromRedux?.id)
  }, [selectedFromRedux])

  return {
    isLoading,
    currentId,
    skipTokensWhileLoading: selectedFromRedux?.skipTokensWhileLoading
  }
}

const setQueryParams = (setSearchParams, orgId, buildingId, state) => {
  setSearchParams(
    (params) => {
      orgId ? params.set('organization', orgId) : params.delete('organization')

      buildingId
        ? params.set('location', buildingId)
        : params.delete('location')

      return params
    },
    {
      replace: true,
      // preserve any state passed via react router navigation
      state
    }
  )
}

const setLocalStorage = (orgId, buildingId) => {
  orgId
    ? localStorage.setItem(LAST_ORGANIZATION_KEY, orgId)
    : localStorage.removeItem(LAST_ORGANIZATION_KEY)

  buildingId
    ? localStorage.setItem(LAST_BUILDING_KEY, buildingId)
    : localStorage.removeItem(LAST_BUILDING_KEY)
}

const getIds = (selectedFromRedux) => {
  const reduxOrganizationId =
    selectedFromRedux?.type === 'organization'
      ? selectedFromRedux.id
      : selectedFromRedux.accountId

  const reduxBuildingId =
    selectedFromRedux?.type === 'location' ? selectedFromRedux.id : null

  return { reduxOrganizationId, reduxBuildingId }
}

const getNavigationProps = (matches) => {
  if (!matches) return
  const config = matches?.[matches.length - 1]?.handle as DenaliRouteHandle
  return config?.page?.navigationProps
}

const checkIsUrlControlled = (navigationProps, uiMode: UiMode): boolean => {
  // Old UI always lets the old picker control the URL
  if (uiMode !== UiMode.denali) return false

  // If we're using the new picker, move URL responsibility here instead of the picker
  if (navigationProps?.denaliBuildingPicker === true) return true

  // let the page render immediately
  return false
}
