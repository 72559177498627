import { useEffect, useState } from 'react'

import { UseCollapsibleStateProps } from '../CollapsibleWithActions/types'

// Custom hook to store some ephemeral state for the collapsible component.
export const useCollapsibleState = ({
  initialState,
  useLocalStorage,
  localStorageKey
}: UseCollapsibleStateProps) => {
  // Let's look up the state in local storage if it's set.
  if (useLocalStorage && localStorageKey) {
    const storedState = localStorage.getItem(localStorageKey)
    if (storedState !== null) {
      initialState = storedState === 'true'
    }
  }
  const [isCollapsed, setIsCollapsed] = useState(initialState)

  // If the stoage items or isCollapsed changes, we need to update the local storage.
  useEffect(() => {
    if (useLocalStorage && localStorageKey) {
      localStorage.setItem(localStorageKey, isCollapsed.toString())
    }
  }, [useLocalStorage, localStorageKey, isCollapsed])

  return {
    isCollapsed,
    setIsCollapsed
  }
}
