import {
  faBallotCheck,
  faBullseyeArrow,
  faChartMixed,
  faDumbbell,
  faTemperatureList,
  faZap
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { ImpactData, ImpactDataOptions } from './types'

export const DenaliTextEditorToolbarOptions = {
  options: ['inline', 'textAlign', 'link', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline']
  },
  link: {
    options: ['link']
  },
  list: {
    options: ['unordered', 'ordered']
  },
  textAlign: {
    options: ['left', 'center', 'right']
  }
}

export const getImpactData = (type: string): ImpactData => {
  return impactDataOptions[type] ?? impactDataOptions.fallback
}

export const impactDataOptions: ImpactDataOptions = {
  energyUsage: {
    icon: faZap,
    label: 'Energy',
    color: 'orange'
  },
  comfort: {
    icon: faTemperatureList,
    label: 'Comfort',
    color: 'gold'
  },
  reliability: {
    icon: faDumbbell,
    label: 'Reliability',
    color: 'purple'
  },
  performance: {
    icon: faChartMixed,
    label: 'Performance',
    color: 'blue'
  },
  compliance: {
    icon: faBallotCheck,
    label: 'Compliance',
    color: 'green'
  },
  custom: {
    icon: faBullseyeArrow,
    label: 'Custom',
    color: 'turquoise'
  },
  fallback: {
    icon: faBullseyeArrow,
    label: 'Custom',
    color: 'transparent-black'
  }
}
