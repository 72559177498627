import { useState, useCallback, useMemo, useEffect } from 'react'
import Select from 'src/components/legacy/components/select/select'
import {
  MainContainer,
  HeaderSection,
  TableSection,
  StyledLabel
} from './style'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { GET_DATA_MAPPING_PROPERTIES_PAGINATED } from '../../graphql/queries'
import Tooltip from 'src/denali-ui/components/Tooltip'
import Icon from 'src/denali-ui/components/Icon'
import PageListFilter from 'src/components/legacy/components/page-list-filter/page-list-filter'
import Label from 'src/components/legacy/components/label/label'
import i18next from 'i18next'
import { TableWrapper, TableStyle } from './style'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import DataMappingEditProperty from './edit-property/data-mapping-edit-property.jsx'
import uniqBy from 'lodash/uniqBy'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import {
  UPDATE_EQUIPMENT_SYSTEM_PROPERTY_MAPPING,
  UPDATE_EQUIPMENT_CONSTANT_PROPERTY_MAPPING,
  RESET_DATA_MAPPING
} from '../../graphql/mutations'
import {
  GET_EQUIPMENT_PROPERTIES_BY_EQUIPMENT_ID,
  GET_NIAGARA_EQUIPMENT_LIST_WITH_DATA_SOURCE_FILTER,
} from '../../graphql/queries'
import {
  getDiscardChangesPromptConfig,
  getResetAllPromptConfig,
  getResetSinglePropertyConfig,
  resetToDefaultsByProperties
} from '../helper.js'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { DATA_SOURCE_TYPES } from 'src/pages/equipmentSetup/EquipmentDetails/DataMapping/constants'
import { NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY } from './constants'
import clsx from 'clsx'
import translate, { TranslateComponent } from 'src/common/translations'
import { useAppDispatch } from 'src/redux/store'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { siUnitMapping, ConvertConstantToSI } from 'src/common/UOMConversions';

const tableName = 'Properties'

const defaultDisplayControls = {
  limit: 500,
  from: 0,
  page: 0
}

const textTransform = (text)=>{
  if(text?.includes("/")){
    const convertToArray = text.split("/")
  const lastTwoElements = convertToArray.splice(-2,2).join("/")
  const finalArray = convertToArray.join("/")
  return {finalArray, lastTwoElements}
  }
}

const SelectrixSelection = ({ label, isNiagra }) => {
  return (
    <div tabIndex={0} className="rs-toggle rs-selected">
    {typeof label === "number" ? label: <>
      {(label?.includes("/") && isNiagra) ?
       <>
       <>{textTransform(label).finalArray}</>{"/"} 
       <TranslateComponent>{textTransform(label).lastTwoElements}</TranslateComponent>
       </>
       :<TranslateComponent>{label}</TranslateComponent>
       }
      </>}
    </div>
  )
}

const defaultValueArr = []

// Setting a maximum amount of points for a Niagara device to show in the Setting dropdown
// If there are too many, it will take many API requests and each request finishing will close
// the dropdown if it is open, leading to a bad user experience.
// Consider switching to the denali-ui Selectrix component instead of legacy component and
// fetching data based on search input in the future
const MAX_NIAGARA_DROPDOWN_POINTS = 1000

const DataMapping = ({
  equipmentDetails,
  atsPage = false,
  editable = true,
  displayNameListProperties = defaultValueArr,
  onEdit = () => {},
  atsDataMappingProperties = defaultValueArr,
  defaultDimensionGroup,
  onSavePropertiesATSChangesHandler,
  datamapping_properties = defaultValueArr,
  refetchDataMappingProperties,
  loadingPropertyLatestValues = false,
  loadingDataMappingProperties = false,
  UOMbyProfile = "IP"
}) => {
  const isNiagara = equipmentDetails?.device?.type === "NAX"
  const dispatch = useAppDispatch();

  const [selectedFilter, setFilter] = useState('All Data Types')
  const [filterText, setFilterText] = useState('')
  const [tableData, setTableData] = useState([])
  const { equipment: equipmentId } = getSearchParams()
  const [ascending, setSortAscendingDescending] = useState(true)
  const [
    isDataMappingEditPropertyDialogShown,
    setIsDataMappingEditPropertyDialogShown
  ] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [binarysourceDisplayNameList, setBinarySourceDisplayNameList] =
    useState([])
  const [analogSourceDisplayNameList, setAnalogSourceDisplayNameList] =
    useState([])
  const [selectedSource, setSelectedSource] = useState([])
  const [rows, setRows] = useState([])
  const [isEditable, setIsEditable] = useState(false)
  const [updatedAlienRow, setUpdatedAlienRow] = useState([])
  const [confirmDialog, setConfirmDialog] = useState(null)
  const [alienDetails, setAlienDetails] = useState({})
  const [alienAutomationName, setAlienAutomationName] = useState('')
  const [uomSymbolPairs, setUomSymbolPairs] = useState({})
  const [niagaraEquipment, setNiagaraEquipment] = useState([])
  const [showPointsInDropdown, setShowPointsInDropdown] = useState(true)

  const [t] = useTranslation()
  const analog = 'Analog'
  const binary = 'Binary'
  const multiState = 'Multi-State'
  const multiState2 = 'Multistate'

  const noPropertySelected = 'No Property Selected'
  const calculated = 'Calculated'
  const applicationCalculated = 'Application Calculated'
  const binaryMultistate = 'Binary/Multistate'
  const NA = 'NA'

  const { onSubmit: onResetDataMapping } = useMutation({
    query: RESET_DATA_MAPPING
  })
  const {
    data: responseNiagaraEquipmentData,
    refetch: refetchNiagaraEquipmentsData,
  } = useQuery({
    query: GET_NIAGARA_EQUIPMENT_LIST_WITH_DATA_SOURCE_FILTER,
    dataPath: 'data.searchEquipment',
    variables: {
      buildingId: equipmentDetails?.building?.id,
      deviceId: equipmentDetails?.deviceId,
      defaultDataSourceName: (equipmentDetails?.dataSourceName === "None" || !equipmentDetails?.dataSourceName )  ? undefined : equipmentDetails?.dataSourceName
    },
  })

  //Translation related changes start
  const createTempDisplayName = (table)=>{
    const tempDisplayNames = table.map(el=>{
      return {
        translatedValue : el.tisDisplayName,
        key : el.tisDisplayName
      }
    }) 
    return tempDisplayNames
  }

  const createSourceDisplayName = (table)=>{
    const tempSourceDisplayList = table.map(el=>{
      return {
        translatedValue : el.value,
        key : el.value
      }
    })
    return tempSourceDisplayList
  }
  const matchFinder = (payload, actualItem)=>payload.find(it=>it.key === actualItem.tisDisplayName)
  const sourceMatchFinder = (payload, actualItem)=>payload.find(it=>it.key === actualItem.key)

  const translationHelper = async (tableToTranslate)=>{
    const tempDisplayNames = createTempDisplayName(tableToTranslate)
    let finalData;
    const translatedTable = await dispatch<any>(translateJSON({ json: tempDisplayNames }))
          .then(res=>{
            const mergedList = tableToTranslate?.map(item=>{
            const match = matchFinder(res.payload, item)
            return {
            ...item,
            tisDisplayName : match.translatedValue || item.value,
            }
            })
            finalData =  mergedList?.sort((a, b) => a?.tisDisplayNameUI?.localeCompare(b?.tisDisplayNameUI))
          })
    return finalData
  }

  const sourceTranslationHelper = async (dataTotranslate)=>{
    let finalData;
    const tempList = createSourceDisplayName(dataTotranslate)
    const analaogList = await dispatch<any>(translateJSON({ json: tempList })).then(res=>{
      const mergedList = dataTotranslate?.map(item=>{
        const match = sourceMatchFinder(res.payload, item)
        return {
          ...item,
          value : match?.translatedValue || item.value
        }
      })
    finalData = mergedList
    })  
    return finalData
  }
  //Translation related changes End

  useEffect(() => {
    try {
      if (isNiagara && responseNiagaraEquipmentData && responseNiagaraEquipmentData?.items.length) {
        setNiagaraEquipment(currentList => ([
          ...currentList,
          ...(responseNiagaraEquipmentData?.items)
        ]))
      }
      if (isNiagara && responseNiagaraEquipmentData && responseNiagaraEquipmentData.total <= MAX_NIAGARA_DROPDOWN_POINTS && responseNiagaraEquipmentData.nextToken !== null) {
        refetchNiagaraEquipmentsData({
          buildingId: equipmentDetails?.building?.id,
          deviceId: equipmentDetails?.deviceId,
          defaultDataSourceName: equipmentDetails?.dataSourceName === "None" ? undefined : equipmentDetails?.dataSourceName,
          nextToken: responseNiagaraEquipmentData?.nextToken,
        })
      } else if (isNiagara && responseNiagaraEquipmentData && responseNiagaraEquipmentData.total > MAX_NIAGARA_DROPDOWN_POINTS) {
        setShowPointsInDropdown(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [responseNiagaraEquipmentData])

  const {
    data: niagaraDataMappingSource,
    loading: niagaraDataMappingSourceLoading,
    refetch: niagaraRefetchSource,
  } = useQuery({
    query: GET_EQUIPMENT_PROPERTIES_BY_EQUIPMENT_ID,
    dataPath: 'data.getPropertyMappingByEquipmentID',
    disableInitialLoad: true,
  })

  const analogNiagaraPoints = useMemo(() =>
    niagaraEquipment.filter(equipment => equipment.dataSourceType === DATA_SOURCE_TYPES.ANALOG)
    .sort((a, b) => a.dataSourceName.localeCompare(b.dataSourceName))
    .map(equipment => ({...equipment, key: equipment.dataSourceName, value: equipment.dataSourceName }))
  , [niagaraEquipment])
  const binaryMultistateNiagaraPoints = useMemo(() =>
    niagaraEquipment.filter(equipment => [DATA_SOURCE_TYPES.BINARY, DATA_SOURCE_TYPES.MULTISTATE].includes(equipment.dataSourceType))
    .sort((a, b) => a.dataSourceName.localeCompare(b.dataSourceName))
    .map(equipment => ({...equipment, key: equipment.dataSourceName, value: equipment.dataSourceName }))
  , [niagaraEquipment])

  useEffect(() => {
    if (displayNameListProperties?.length) {
      // Adding Application Calculated to source list
      const obj = [
        {
          sourceDisplayName: noPropertySelected,
          propertyType: '',
          value: noPropertySelected,
          key: noPropertySelected,
          targetKey: null
        },
        {
          sourceDisplayName: applicationCalculated,
          propertyType: calculated,
          value: applicationCalculated,
          key: applicationCalculated,
          targetKey: null
        }
      ]

      const data_mapping_source_List = [...displayNameListProperties]?.filter(
        (x) => x?.targetKey === null
      )
      const analogFilteredData = data_mapping_source_List?.filter(
        // NEED TO CONFIRM USECASE TO DO -> (source) => source.sourceDisplayName !== null &&  source.targetDataGroup === analog && source.sourceDataGroup === analog
        (source) => source.sourceDataGroup && source.sourceDataGroup === analog
      )

      const analogSourceData = uniqBy(analogFilteredData, (obj) =>
        obj?.sourceDisplayName?.length ? obj?.sourceDisplayName : obj?.sourceKey
      )
        ?.map((obj) => ({
          value: obj?.sourceDisplayName?.length
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          key: obj.sourceDisplayName?.length
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          id: obj.id
        }))
        ?.sort((a, b) => a?.key?.localeCompare(b?.key))
      const filteredAnalogSourceData = analogSourceData?.filter(
        (item) => item.key !== null && item.value !== null
      )
      const analogData = [...obj, ...filteredAnalogSourceData]
      sourceTranslationHelper(analogData).then(res=>setAnalogSourceDisplayNameList(res))
      // Filtering Binary source data to list possible "Source" in dropdown
      const binaryFilteredData = data_mapping_source_List?.filter(
        // NEED TO CONFIRM USECASE TO DO -> (source) =>  source.targetDataGroup !== analog && source.sourceDataGroup !== analog
        (source) =>
          source.sourceDataGroup &&
          (source.sourceDataGroup === binary ||
            source.sourceDataGroup === multiState ||
            source.sourceDataGroup === multiState2)
      )

      // Making Key , value pair for options
      const binarySourceData = uniqBy(binaryFilteredData, (obj) =>
        obj?.sourceDisplayName?.length ? obj?.sourceDisplayName : obj?.sourceKey
      )
        ?.map((obj) => ({
          value: obj.sourceDisplayName?.length
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          key: obj.sourceDisplayName?.length
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          id: obj.id
        }))
        ?.sort((a, b) => a?.key?.localeCompare(b?.key))
      const filteredBinarySourceData = binarySourceData?.filter(
        (item) => item.key !== null && item.value !== null
      )
      const binaryData = [...obj, ...filteredBinarySourceData]
      sourceTranslationHelper(binaryData).then(res=>setBinarySourceDisplayNameList(res))
    } else {
      const noProperty = [
        {
          key: noPropertySelected,
          value: noPropertySelected
        },
        {
          key: applicationCalculated,
          value: applicationCalculated
        }
      ]
      setAnalogSourceDisplayNameList(noProperty)
      setBinarySourceDisplayNameList(noProperty)
    }
  }, [displayNameListProperties, rows, selectedSource, updatedAlienRow])

  useEffect(() => {
    refetchDataMappingProperties?.()
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_EQUIPMENT_DATA_MAPPING)
  }, [])

  useEffect(() => {
    if (datamapping_properties?.length > 0) {
      if (UOMbyProfile === "SI") {
        const SiConvertedTableData = datamapping_properties?.map((item) => {
          let lConevrtedConstantValue = item?.value
          if (item?.value?.toLocaleString()?.length && item?.targetMeasureType && item?.targetMeasureType !== "none" && item?.targetMeasureType !== "0") {
            lConevrtedConstantValue = Number(ConvertConstantToSI(Number(item?.value), item?.targetMeasureUnit, item?.targetMeasureType).toFixed(3)) 
          }
          return {
            ...item,
            ConevrtedConstantValue: lConevrtedConstantValue,
          }
        })
        if (SiConvertedTableData?.length !== 0) {  
          translationHelper(SiConvertedTableData).then(res=>setTableData(res))
        }else{
          translationHelper(datamapping_properties).then(res=>setTableData(res))
        }
      } else {
        translationHelper(datamapping_properties).then(res=>setTableData(res))
      }
    }
  }, [datamapping_properties])

  useEffect(() => {
    try {
      const uomSymbolPairs = {}
      const units = defaultDimensionGroup
      for (const unitType in units) {
        const unitArray = units[unitType]
        for (const unit of unitArray) {
          const unitValue = unit?.value
          if (unitValue?.UoM && unitValue?.Symbol) {
            uomSymbolPairs[unitValue?.UoM] = unitValue?.Symbol
          }
        }
      }
      setUomSymbolPairs(uomSymbolPairs)
    } catch (error) {}
  }, [defaultDimensionGroup])

  // Called when the data property list changes, mostly on undefined selection from edit dialog
  useEffect(() => {
    if (atsPage && atsDataMappingProperties) {
      if (UOMbyProfile === "SI") {
          const SiConvertedTableData = atsDataMappingProperties?.map((item) => {
          let lConevrtedConstantValue = item?.value
          if (item?.value?.toLocaleString()?.length && item?.targetMeasureType && item?.targetMeasureType !== "none" && item?.targetMeasureType !== "0") {
            lConevrtedConstantValue = Number(ConvertConstantToSI(Number(item?.value), item?.targetMeasureUnit, item?.targetMeasureType).toFixed(3)) 
          }
          return {
            ...item,
            ConevrtedConstantValue: lConevrtedConstantValue,
          }
        })
        if (SiConvertedTableData?.length !== 0) {
          setTableData(SiConvertedTableData)
        }else{
          setTableData(atsDataMappingProperties)
        }
      } else {
        setTableData(atsDataMappingProperties)
      }
    }
  }, [atsDataMappingProperties])

  const availableFilters = [
    { value: 'All Data Types', key: 'All Data Types' },
    { value: analog, key: analog },
    { value: binaryMultistate, key: binaryMultistate }
  ]

  const onSelectFilter = useCallback((value: string) => {
    setFilter(value)
  }, [])

  const onChangeFilter = useCallback((value: string) => {
    setFilterText(value)
  }, [])

  // DATA MAPPING TABLE
  const filteredRow = useMemo(() => {
    // Using modified rows for table after EDIT
    const mRows = rows?.length ? rows : tableData

    const filteredData =
      selectedFilter === analog
        ? mRows?.filter((item) => item?.targetDataGroup === analog)
        : selectedFilter === binaryMultistate
        ? mRows?.filter(
            (item) =>
              item?.targetDataGroup === binary ||
              item?.targetDataGroup === multiState ||
              item.targetDataGroup === multiState2
          )
        : mRows
    // FilterText Logic
    const filteredProperty = filteredData?.filter((item: any) =>
      item?.tisDisplayName
        ? item?.tisDisplayName.toLowerCase().includes(filterText?.toLowerCase())
        : item?.dataForDisplayPurpose === 'ui'
        ? true
        : false
    )

    const rowsData = filteredProperty?.map((id) => {
      if (id?.dataForDisplayPurpose === 'ui') return id

      let sourceName

      if (
        id?.value?.length &&
        !(
          id?.value ===
          NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY
        )
      ) {
        sourceName = UOMbyProfile === "SI" ? id?.ConevrtedConstantValue : id?.value
      } else if (id?.sourceEquipmentId?.length && id?.creationType !== 'Auto') {
        if (
          id?.sourceDisplayName === noPropertySelected ||
          id?.sourceDisplayName === applicationCalculated
        ) {
          sourceName = id?.sourceDisplayName
        } else if (
          id?.sourceEquipment?.name &&
          !(id?.sourceEquipmentId === equipmentDetails.dataMappingEquipmentId)
        ) {
          if (id?.subPropertyDisplayName?.length) {
            sourceName = `${id?.sourceEquipment?.name}${
              id?.sourceDisplayName !== undefined
                ? '/' + id?.sourceDisplayName
                : ''
            }${id?.subPropertyDisplayName ? '/' + id?.subPropertyDisplayName : ''}`
          } else {
            sourceName = `${id?.sourceEquipment?.name}${
              id?.sourceDisplayName !== undefined
                ? '/' + id?.sourceDisplayName
                : ''
            }`
          }
        } else {
          sourceName = id?.sourceDisplayName
        }
      } else if (id?.subPropertyDisplayName) {
        if (id?.isModifiedSubProperty) {
          if (
            id?.sourceDisplayName === noPropertySelected ||
            id?.sourceDisplayName === applicationCalculated
          ) {
            sourceName = id?.sourceDisplayName
          } else if (id.sourceEquipment && id.sourceEquipment?.name) {
            sourceName = `${id?.sourceEquipment?.name}${
              id?.sourceDisplayName !== undefined
                ? '/' + id?.sourceDisplayName
                : ''
            }`
          } else {
            sourceName = id?.sourceDisplayName
          }
        } else {
          if (
            id?.sourceDisplayName === noPropertySelected ||
            id?.sourceDisplayName === applicationCalculated
          ) {
            sourceName = id?.sourceDisplayName
          } else if (id?.sourceEquipment && id?.sourceEquipment?.name) {
            sourceName =
              id?.sourceEquipment.name +
              '/' +
              id?.sourceDisplayName +
              '/' +
              id?.subPropertyDisplayName
          } else if (id?.sourceDisplayName.includes("/")) {
            sourceName = id?.sourceDisplayName
          } else {
            sourceName = `${id?.sourceDisplayName}${
              id?.subPropertyDisplayName ? '/' + id?.subPropertyDisplayName : ''
            }`
          }
        }
      } else if (
        id.sourceDisplayName &&
        id.sourceDisplayName.toLocaleString() &&
        id.sourceDisplayName.toLocaleString().length
      ) {
        sourceName = id.sourceDisplayName
      } else {
        sourceName = id.sourceKey
      }

      return {
        id: id?.id,
        creationType: id?.creationType,
        targetDataGroup: id?.targetDataGroup,
        sourceDisplayName: sourceName,
        targetMeasureType:
          id?.targetMeasureType !== '0' ? id?.targetMeasureType : '',
        tisDisplayName: id?.tisDisplayName,
        //tisDisplayNameUI: id?.tisDisplayNameUI,
        value: id?.value,
        propertyType: id?.propertyType,
        targetKey: id?.targetKey,
        isConstant: id?.value?.toLocaleString()?.length // '' ,  Perfer value while checking CONSTANT if both were present sourceDisplayName / value
          ? true
          : false,
        targetMeasureUnit:
          id?.targetMeasureUnit === 'noUnit' ? NA : id?.targetMeasureUnit,
        maxValue: id?.maxValue,
        minValue: id?.minValue,
        sourceKey: id?.sourceKey,
        sourceDataGroup: id?.sourceDataGroup,
        sourceMeasureType: id?.sourceMeasureType,
        sourceMeasureUnit: id?.sourceMeasureUnit,
        sourceEquipmentId: id?.sourceEquipmentId,
        sourceEquipmentName: id?.sourceEquipment?.name,
        sourceEquipmentDataSourceType: id?.sourceEquipment?.dataSourceType
          ?.length
          ? id?.sourceEquipment?.dataSourceType
          : id?.sourceEquipmentDataSourceType,
        sourceEquipmentDataSourceName: id?.sourceEquipment?.dataSourceName
          ?.length
          ? id?.sourceEquipment?.dataSourceName
          : id?.sourceEquipmentDataSourceName,
        alienSourcePropertyName: id?.sourceEquipmentId?.length
          ? typeof id?.sourceDisplayName === 'string' &&
            id?.sourceDisplayName?.includes('/')
            ? id?.sourceDisplayName?.substring(
                id?.sourceDisplayName?.lastIndexOf('/') + 1
              )
            : id?.sourceDisplayName
          : id?.subPropertyDisplayName?.length
          ? id?.sourceDisplayName
          : '',
        automationName: alienAutomationName?.length
          ? alienAutomationName
          : id?.sourceEquipment?.device?.name || '',
        subPropertyDisplayName: id?.subPropertyDisplayName,
        isModifiedSubProperty: false,
        polarityNormal: (id?.polarityNormal === true || id?.polarityNormal === '1' || id?.polarityNormal === null) ? '1' : '0',
        lastCollectedValue: id?.lastCollectedValue,
        lastCollectedTime: id?.lastCollectedTime,
        // properties below come from AT Status dialog, used for UI style purpose only<tr> / <td>
        isGroupedByATStatus: id?.isGroupedByATStatus,
        textIndentToRight: id?.textIndentToRight,
        ConevrtedConstantValue: id?.ConevrtedConstantValue
      } as any
    })

    if (atsPage) return rowsData

    // Table sorting
    if (rowsData?.length) {
      if (!ascending) {
        const data = rowsData
          ?.filter?.((data) => data?.tisDisplayName !== null)
          .sort((a, b) => (b?.tisDisplayName > a?.tisDisplayName ? 1 : -1))
        return data
      } else {
        const data = rowsData
          ?.filter?.((data) => data?.tisDisplayName !== null)
          .sort((a, b) => (a?.tisDisplayName > b?.tisDisplayName ? 1 : -1))
        return data
      }
    } else {
      return rowsData
    }
  }, [
    tableData,
    filterText,
    selectedFilter,
    ascending,
    selectedSource,
    rows,
    atsPage
  ])

  // Validate Duplicate Entry before adding modified soruce
  const isDuplicate = (id) => {
    return selectedSource?.find((item) => item?.id === id)
  }

  const isDuplicateOption = (id) => {
    return updatedAlienRow?.find((item) => item?.id === id)
  }

  const { onSubmit: updateSystemDataMapping } = useMutation({
    query: UPDATE_EQUIPMENT_SYSTEM_PROPERTY_MAPPING
  })

  const { onSubmit: updateCosntantDataMapping } = useMutation({
    query: UPDATE_EQUIPMENT_CONSTANT_PROPERTY_MAPPING
  })

  const handleSave = async () => {
    setIsEditable(false)
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_EQUIPMENT_DATA_MAPPING)
    const finalSaveList = []
    const duplicateItem = []
    selectedSource?.forEach((element) => {
      const filteredTableData = tableData?.find((x) => x.id === element.id)
      if (
        (filteredTableData?.sourceDisplayName !== null
          ? filteredTableData?.sourceDisplayName === element?.sourceDisplayName
          : filteredTableData?.sourceKey === element?.sourceDisplayName) &&
        filteredTableData?.value === element?.value
      ) {
        const elementSourceId = element?.sourceEquipmentId?.length
          ? element?.sourceEquipmentId
          : 'sameId'
        const filteredSourceId = filteredTableData?.sourceEquipmentId
          ? filteredTableData?.sourceEquipmentId
          : 'sameId'

        if (
          elementSourceId !== filteredSourceId ||
          element?.sourceDisplayName !== filteredTableData?.sourceDisplayName ||
          element?.sourceMeasureType !== filteredTableData?.sourceMeasureType ||
          element?.sourceMeasureUnit !== filteredTableData?.sourceMeasureUnit ||
          element?.subPropertyDisplayName !==
            filteredTableData?.subPropertyDisplayName
        ) {
          finalSaveList?.push(element)
        } else duplicateItem?.push(element)
      } else {
        finalSaveList?.push(element)
      }
    })

    if (finalSaveList?.length) {
      for (const sourceItem of finalSaveList) {
        const {
          id,
          sourceDisplayName,
          sourceKey,
          sourceDataGroup,
          sourceMeasureType,
          sourceMeasureUnit,
          sourceEquipmentId,
          sourceUri,
          value,
          reset,
          polarityNormal,
          sourceDeviceId,
          creationType = 'User'
        } = sourceItem
        const subPropertyDisplayName =
          sourceItem?.subPropertyDisplayName === null
            ? undefined
            : sourceItem?.subPropertyDisplayName

        if (sourceItem?.isAlienConstant) {
          let lvalue = value
          if(UOMbyProfile === 'SI' && sourceItem.targetMeasureType && sourceItem.targetMeasureType !== "none" && sourceItem.targetMeasureUnit !== "NA"){
            lvalue = ConvertConstantToSI(value, sourceItem.targetMeasureUnit, sourceItem.targetMeasureType, true)
          }
          
          await updateCosntantDataMapping({
            id,
            lvalue,
            creationType,
            sourceEquipmentId,
            sourceDisplayName: '',
            sourceKey: '',
            sourceUri: '',
            sourceDataGroup: '',
            sourceMeasureType: '',
            sourceMeasureUnit: ''
          })
        } else {
          await updateSystemDataMapping({
            id,
            sourceDisplayName,
            ...(!reset && { sourceDeviceId }),
            sourceKey,
            sourceUri,
            sourceDataGroup,
            sourceMeasureType,
            sourceMeasureUnit,
            sourceEquipmentId,
            polarityNormal,
            value,
            creationType,
            subPropertyDisplayName
          })
        }
      }
      if (atsPage) {
        onSavePropertiesATSChangesHandler?.()
      } else {
        setRows([])
        refetchDataMappingProperties?.(true)
      }
    }
    setSelectedSource([])
  }

  const getSelectedSourceDetails = (key) => {
    // TO  GET DETAILS OF SELECTED RESOURCE data_mapping_source_List
    const details = displayNameListProperties?.filter(
      (source) => source.sourceDisplayName === key || source.sourceKey === key
    )
    return details
  }

  const handleEdit = () => {
    onEdit?.()
    setIsEditable(true)
    trackEvent(
      USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_EDIT_EQUIPMENT_DATA_MAPPING
    )
  }

  const handleCancel = () => {
    if (selectedSource?.length) {
      showDiscardChangesPrompt()
    } else {
      setIsEditable(false)
      setSelectedSource([])
      setRows([])
    }
  }
  const translatedOptionsModal = {
    title: 'Warning',
    text: translate(
      'You have made changes which have not been saved. Do you wish to Continue?'
    ),
    confirmText: 'Yes',
    cancelText: 'No'
  }

  const getDiscardChangesPromptConfig = ({ handleConfirm, handleCancel }) => ({
    title: translatedOptionsModal.title,
    text: translatedOptionsModal.text,
    confirmText: translatedOptionsModal.confirmText,
    cancelText: translatedOptionsModal.cancelText,
    handleConfirm,
    handleCancel
  })

  const showDiscardChangesPrompt = () => {
    setConfirmDialog(
      getDiscardChangesPromptConfig({
        handleConfirm: () => {
          setIsEditable(false)
          setSelectedSource([])
          setRows([])
          hideConfirmDialog()
        },
        handleCancel: hideConfirmDialog
      })
    )
  }
  const hideConfirmDialog = () => {
    setConfirmDialog(null)
  }

  const ArrowsForSorting = ({ active }) => {
    return (
      <span style={{ paddingLeft: '10px' }}>
        {active === 'ascending' ? (
          <Icon name="arrow-up" color="#fff" width="9px" height="9px" />
        ) : (
          <Icon name="arrow-down" color="#fff" width="9px" height="9px" />
        )}
      </span>
    )
  }

  const optionsTranslated = {
    modalBody: translate('Are you sure you want to Reset Property to Default?'),
    modalSubBody: translate(
      'The default property for this value will be restored and any user selected property will be lost.'
    )
  }

  const showSinglePropertyResetPrompt = (item) => {
    setConfirmDialog(
      getResetSinglePropertyConfig({
        t,
        modalBody: optionsTranslated.modalBody,
        modalSubBody: optionsTranslated.modalSubBody,
        handleConfirm: () => {
          resetSingleProperty(item)
          setIsEditable(false)
          hideConfirmDialog()
        },
        handleCancel: hideConfirmDialog
      })
    )
  }
  const resetSingleProperty = async (item) => {
    const eqData = equipmentDetails
    const resetPropertyBody = {
      data: {
        equipmentId: equipmentId,
        dataMappingEquipmentId: eqData?.dataMappingEquipmentId,
        propertyId: item?.id,
        deviceId: eqData?.deviceId,
        propertyType: 'Property'
      }
    }

    await onResetDataMapping({
      input: JSON.stringify(resetPropertyBody)
    }).then((res) => {
      res
    })
    if (atsPage) {
      onSavePropertiesATSChangesHandler?.()
    } else {
      refetchDataMappingProperties?.(true)
    }
  }

  const resetOptions = {
    title: 'Reset All to Defaults',
    subTitle: translate('Are you sure want to Reset All to Defaults?'),
    description: translate(
      'All the default values for these properties will be restored and any user selected properties will be lost.'
    ),
    confirm: 'Yes',
    cancel: 'No'
  }

  const getResetAllPromptConfig = ({ handleConfirm, handleCancel }) => ({
    title: resetOptions.title,
    text: resetOptions.subTitle,
    description: resetOptions.description,
    confirmText: resetOptions.confirm,
    cancelText: 'No',
    handleConfirm,
    handleCancel
  })

  const showResetAllPrompt = () => {
    setConfirmDialog(
      getResetAllPromptConfig({
        handleConfirm: () => {
          resetAllToDefault()
          setIsEditable(false)
          hideConfirmDialog()
        },
        handleCancel: hideConfirmDialog
      })
    )
  }

  const resetAllToDefault = async () => {
    const eqData = equipmentDetails

    if (atsPage) {
      await resetToDefaultsByProperties(onResetDataMapping, {
        equipmentId: equipmentId,
        dataMappingEquipmentId: eqData?.dataMappingEquipmentId,
        deviceId: eqData?.deviceId,
        propertyType: 'Property',
        propertyIds: (filteredRow || []).reduce((av, char) => {
          char.id && av.push(char.id)
          return av
        }, [])
      })

      onSavePropertiesATSChangesHandler?.()

      return
    }

    const resetPropertyBody = {
      data: {
        equipmentId: equipmentId,
        dataMappingEquipmentId: eqData?.dataMappingEquipmentId,
        propertyId: 'None',
        deviceId: eqData?.deviceId,
        propertyType: 'Property'
      }
    }
    await onResetDataMapping({
      input: JSON.stringify(resetPropertyBody)
    }).then((res) => {
      res
    })

    refetchDataMappingProperties?.(true)
  }

  const toggleEditPropertyDialog = (item, index) => {
    setIsEditable(true)
    setIsDataMappingEditPropertyDialogShown(
      !isDataMappingEditPropertyDialogShown
    )
    setSelectedItem(item)
    setSelectedIndex(index)
  }

  const handleDropdownChange = (index, selectedOption) => {
    let updatedRows = [...filteredRow]
    updatedRows[index] = {
      ...updatedRows[index],
      sourceDisplayName: selectedOption,
      value: ''
    }

    // Appending Analog / Binary if the user filtered before editing
    if (selectedFilter === analog) {
      const analogData = tableData?.filter(
        (item) => item?.targetDataGroup === analog
      )
      updatedRows = [...updatedRows]
    } else if (selectedFilter === binaryMultistate) {
      const binaryData = tableData?.filter(
        (item) =>
          item?.targetDataGroup === binary ||
          item?.targetDataGroup === multiState ||
          item?.targetDataGroup === multiState2
      )
      updatedRows = [...updatedRows]
    }

    setRows(updatedRows)
  }

  const handleAlienProperty = (
    propertyDetails,
    data,
    selectedAlienPropertyId,
    isAlienConstant,
    pName,
    pIndex,
    selectedDeviceId,
    selectedDimension,
    selectedUOM,
    dataSourceName,
    dataSourceType,
    selectedAutomationName,
    alienDetails,
    isCancel
  ) => {
    if (!isCancel) {
      setSelectedIndex(pIndex)
      setAlienDetails(alienDetails)
      setAlienAutomationName(selectedAutomationName)
      const dataTest = {
        id: propertyDetails?.id,
        selectedIndex: pIndex,
        updatedSource: data,
        updatedPropertyName: pName,
        selectedAlienPropertyId: selectedAlienPropertyId,
        sourceDataGroup: propertyDetails?.sourceDataGroup,
        value: isAlienConstant ? data : ''
      }

      if (!isDuplicateOption(propertyDetails?.id)) {
        setUpdatedAlienRow([...updatedAlienRow, dataTest])
      } else {
        const updatedSourceList = updatedAlienRow?.filter(
          (source) => source.id !== propertyDetails.id
        )
        setUpdatedAlienRow([...updatedSourceList, dataTest])
      }
      let updatedRows = [...filteredRow]
      if (isAlienConstant) {
        updatedRows[pIndex] = {
          ...updatedRows[pIndex],
          sourceDisplayName: data,
          value: data
        }
      } else {
        updatedRows[pIndex] = {
          ...updatedRows[pIndex],
          sourceDisplayName: data,
          sourceMeasureType: propertyDetails?.sourceMeasureType,
          sourceMeasureUnit: propertyDetails?.sourceMeasureUnit,
          sourceEquipmentId: selectedAlienPropertyId,
          alienSourcePropertyName: pName,
          sourceEquipmentDataSourceType:
            propertyDetails?.sourceEquipmentDataSourceType,
          sourceEquipmentDataSourceName:
            propertyDetails?.sourceEquipmentDataSourceName,
          automationName: propertyDetails?.automationName,
          polarityNormal:
            propertyDetails?.polarityNormal === '1' ? true : false,
          value: ''
        }
      }

      // Appending Analog / Binary if the user filtered before editing
      if (selectedFilter === analog) {
        const analogData = tableData?.filter(
          (item) => item?.targetDataGroup === analog
        )
        updatedRows = [...updatedRows, ...analogData]
      } else if (selectedFilter === binaryMultistate) {
        const binaryData = tableData?.filter(
          (item) =>
            item?.targetDataGroup === binary ||
            item?.targetDataGroup === multiState ||
            item?.targetDataGroup === multiState2
        )
        updatedRows = [...updatedRows, ...binaryData]
      }

      setRows(updatedRows)

      let newSource

      if (isAlienConstant) {
        newSource = {
          id: propertyDetails?.id,
          value: data,
          sourceEquipmentId: '',
          isAlienConstant: true,
          creationType: 'User',
          targetMeasureType: propertyDetails?.targetMeasureType,
          targetMeasureUnit: propertyDetails?.targetMeasureUnit
        }
      } else {
        newSource = {
          id: propertyDetails?.id || '',
          sourceDisplayName: propertyDetails?.sourceDisplayName || '',
          sourceUri: propertyDetails?.sourceUri || '',
          sourceKey: propertyDetails?.sourceKey || '',
          creationType: 'User',
          sourceDataGroup: propertyDetails?.sourceDataGroup || '',
          sourceMeasureType: selectedDimension?.length
            ? selectedDimension
            : propertyDetails?.sourceMeasureType || '',
          sourceMeasureUnit: selectedUOM?.length
            ? selectedUOM
            : propertyDetails?.sourceMeasureUnit || '',
          sourceEquipmentId: selectedAlienPropertyId || '',
          isAlienConstant: false,
          sourceDeviceId: selectedDeviceId || '',
          value: '',
          polarityNormal:
            propertyDetails?.polarityNormal === '1' ? true : false,
          subPropertyDisplayName:
            propertyDetails?.subPropertyDisplayName || null
        }
      }

      if (!isDuplicate(propertyDetails?.id)) {
        setSelectedSource((prevSourceList) => [...prevSourceList, newSource])
      } else {
        const updatedSourceList = selectedSource?.filter(
          (source) => source.sourceDisplayName !== newSource?.sourceDisplayName
        )
        setSelectedSource([...updatedSourceList, newSource])
      }
    } else {
      const updatedRows = [...filteredRow]
      updatedRows[pIndex] = {
        ...updatedRows[pIndex],
        sourceDisplayName: propertyDetails?.sourceDisplayName,
        sourceMeasureType: propertyDetails?.sourceMeasureType,
        sourceMeasureUnit: propertyDetails?.sourceMeasureUnit,
        sourceEquipmentId: propertyDetails?.sourceEquipmentId,
        targetMeasureUnit: propertyDetails?.targetMeasureUnit,
        alienSourcePropertyName: propertyDetails?.alienSourcePropertyName,
        sourceEquipmentDataSourceType:
          propertyDetails?.sourceEquipmentDataSourceType,
        sourceEquipmentDataSourceName:
          propertyDetails?.sourceEquipmentDataSourceName,
        automationName: propertyDetails?.automationName,
        value: propertyDetails?.value,
        targetKey: propertyDetails?.targetKey,
        polarityNormal: propertyDetails?.polarityNormal === '1' ? true : false,
        isConstant: propertyDetails?.isConstant,
        ConevrtedConstantValue: propertyDetails?.ConevrtedConstantValue
      }
      setRows(updatedRows)
    }
  }

  const handleAlienOption = (options, index, item) => {
    if (updatedAlienRow?.length) {
      const filteredData1 = updatedAlienRow?.find(
        (uItem) => uItem?.selectedIndex === index
      )
      if (filteredData1 !== undefined) {
        const newOption = []

        options?.map((optionItem) => {
          if (
            optionItem?.value === filteredData1?.updatedPropertyName ||
            optionItem?.id === filteredData1?.id
          ) {
            if (
              !newOption?.find(
                (i) => i.value === filteredData1?.updatedSource
              ) &&
              !options?.find((i) => i.value === filteredData1?.updatedSource)
            ) {
              newOption.push({
                value: filteredData1?.updatedSource,
                key: filteredData1?.updatedSource,
                id: optionItem?.id
              })
            }
          } else {
            if (
              !options?.find((i) => i.value === filteredData1?.updatedSource) &&
              !newOption?.find((i) => i.value === filteredData1?.updatedSource)
            ) {
              newOption.push({
                value: filteredData1?.updatedSource,
                key: filteredData1?.updatedSource,
                id: filteredData1.id
              })
            }
            return newOption
          }
        })

        return options?.find(
          (item) =>
            item.id === newOption[0]?.id && item.value === newOption[0]?.value
        )
          ? options
          : [...newOption, ...options]
      } else {
        const newOption = []
        options?.map((optionItem) => {
          newOption.push(optionItem)
        })
        if (
          !options.find((source) => source.value === item?.sourceDisplayName)
        ) {
          newOption.push({
            value: item?.sourceDisplayName,
            key: item?.sourceDisplayName,
            id: item.id
          })

          return newOption
        } else {
          return newOption
        }
      }
    } else {
      const newOption = []
      options?.map((item) => {
        newOption.push(item)
      })
      if (item?.sourceEquipmentId) {
        if (
          !options.find((source) => source.value === item?.sourceDisplayName)
        ) {
          newOption.push({
            value: item?.sourceDisplayName,
            key: item?.sourceDisplayName,
            id: item?.id
          })
        }
      } else {
        if (
          !options.find((source) => source.value === item?.sourceDisplayName)
        ) {
          newOption.push({
            value: item?.sourceDisplayName,
            key: item?.sourceDisplayName,
            id: item?.id
          })
        }
      }

      return newOption
    }
  }

  const translatedOptions = {
    findSettings: translate('Find a Setting...'),
    noResult: translate('No result found'),
    noProperty: translate('No Property Selected')
  }

  const RenderRowForGroupUI = ({ item, index }) => {
    const translatedToolTip = translate(item.tooltip)
    return (
      <tr className={item?.className} key={index}>
        <td
          className="grp-td"
          colSpan={item?.colSpan}
          style={{
            paddingLeft: `${10 + (item?.textIndentToRight ?? 0) * 20}px`
          }}
        >
          <span className="name-block title">
            <TranslateComponent>{item?.content}</TranslateComponent>
          </span>
          {item?.isTooltip && (
            <Tooltip position="right" content={translatedToolTip}>
              <span className="tooltip-icon">
                <Icon name="infocircle2" color="#bfbfbf" />
              </span>
            </Tooltip>
          )}
        </td>
      </tr>
    )
  }

  const RenderRow = ({ item, index, isAtsPage = false }) => {
    const translatedToolTip = !isAtsPage ? item.tisDisplayName : translate(item.tisDisplayName)
    return (
      <tr
        className={
          isAtsPage && item?.isGroupedByATStatus ? 'ats-grouped-property' : ''
        }
      >
        <td
          style={{
            paddingLeft: `${
              isAtsPage ? 10 + (item?.textIndentToRight ?? 0) * 20 : 10
            }px`
          }}
        >
          <span className="name-block">{!isAtsPage ? item?.tisDisplayName : <TranslateComponent>{item?.tisDisplayName}</TranslateComponent>}</span>
          <Tooltip position="right" content={translatedToolTip}>
            <span>
              <Icon name="infocircle2" color="#bfbfbf" />
            </span>
          </Tooltip>{' '}
        </td>
        <td
          style={{
            overflow: !isEditable ? 'hidden' : undefined,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {isEditable ? (
            <>
              <div style={{ display: 'flex' }}>
                {/*EDITABLE SOURCE*/}

                <div
                  className="setting-wrapper"
                  style={{ width: '100%', flex: '1 0 100%' }}
                >
                  <EditableView
                    type={EditableView.COMPONENT_TYPE.DEFAULT}
                    name="selectedSourceName"
                    allowOnChangeDirty={true}
                    options={
                      isNiagara
                        ? item?.targetDataGroup === analog
                          ? item.propertyType === calculated
                            ? [...analogSourceDisplayNameList, ...(showPointsInDropdown ? analogNiagaraPoints : [])]
                            : [...analogSourceDisplayNameList.filter(source => source.value !== applicationCalculated), ...(showPointsInDropdown ? analogNiagaraPoints : [])]
                          : item.propertyType === calculated
                            ? [...binarysourceDisplayNameList, ...(showPointsInDropdown ? binaryMultistateNiagaraPoints : [])]
                            : [...binarysourceDisplayNameList.filter(source => source.value !== applicationCalculated), ...(showPointsInDropdown? binaryMultistateNiagaraPoints : [])]
                        : item?.targetDataGroup === analog
                          ? item.propertyType === calculated
                            ? analogSourceDisplayNameList
                            : analogSourceDisplayNameList.filter(
                                (source) => source.value !== applicationCalculated
                              )
                          : item.propertyType === calculated
                          ? binarysourceDisplayNameList
                          : binarysourceDisplayNameList.filter(
                              (source) => source.value !== applicationCalculated
                            )
                    }
                    showErrors={false}
                    component={({ onBlur, onFocus, options }) => {
                      const fieldValueSelected =
                        item?.sourceDisplayName || 'No Property Selected'
                      return (
                        <SelectrixWrapper
                          arrow={true}
                          className="selectrix-wrapper--type-autocomplete"
                          customKeys={{
                            key: 'key',
                            label: 'value'
                          }}
                          defaultValue={item?.sourceDisplayName}
                          inputPlaceholder={translatedOptions.findSettings}
                          notFoundPrompt={translatedOptions.noResult}
                          onChange={async ({ key }) => {
                            item['creationType'] = 'User'
                            if (typeof key === 'string' && key?.includes(']')) {
                              toggleEditPropertyDialog(item, index)
                            } else {
                              const SourceDetails =
                                getSelectedSourceDetails(key)
                              let newSourceData
                              const sameConstantKey = selectedSource?.find(
                                (item) => item?.value === key
                              )
                              // If the user selects noPropertySelected
                              if (
                                key === noPropertySelected ||
                                key === applicationCalculated
                              ) {
                                newSourceData = {
                                  id: item?.id || '',
                                  sourceDisplayName: '',
                                  sourceKey: '',
                                  sourceUri: '',
                                  creationType: 'User',
                                  sourceDataGroup: '',
                                  sourceMeasureType: '',
                                  sourceMeasureUnit: '',
                                  sourceEquipmentId: '',
                                  isAlienConstant: false,
                                  // When 'no property selected' option is selected, set value as ''
                                  value:
                                    item.propertyType === calculated &&
                                    key === noPropertySelected
                                      ? NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY
                                      : '',
                                  reset: true
                                }
                              } else if (
                                typeof key === 'string' &&
                                !sameConstantKey &&
                                !filteredRow?.find((x) => x.value === key)
                              ) {
                                // Condition to check if same constant value is added
                                newSourceData = {
                                  id: item?.id || '',
                                  sourceDisplayName:
                                    SourceDetails[0].sourceDisplayName || '',
                                  sourceUri: SourceDetails[0]?.sourceUri || '',
                                  sourceKey: SourceDetails[0]?.sourceKey || '',
                                  creationType: 'User',
                                  sourceDataGroup:
                                    SourceDetails[0]?.sourceDataGroup || '',
                                  sourceMeasureType:
                                    SourceDetails[0]?.sourceMeasureType || '',
                                  sourceMeasureUnit:
                                    SourceDetails[0]?.sourceMeasureUnit || '',
                                  sourceEquipmentId:
                                    SourceDetails[0]?.sourceEquipmentId || '',
                                  isAlienConstant: false,
                                  value: '',
                                  sourceDeviceId:
                                    SourceDetails[0]?.sourceDeviceId || ''
                                }
                              } else if (
                                // Condition to check if key includes / in source DisplayName
                                typeof key === 'string' &&
                                key?.includes('/') &&
                                tableData?.find(
                                  (x) => x.sourceDisplayName === key
                                )
                              ) {
                                newSourceData = {
                                  id: item?.id || '',
                                  sourceDisplayName:
                                    SourceDetails[0].sourceDisplayName || '',
                                  sourceUri: SourceDetails[0]?.sourceUri || '',
                                  sourceKey: SourceDetails[0]?.sourceKey || '',
                                  creationType: 'User',
                                  sourceDataGroup:
                                    SourceDetails[0]?.sourceDataGroup || '',
                                  sourceMeasureType:
                                    SourceDetails[0]?.sourceMeasureType || '',
                                  sourceMeasureUnit:
                                    SourceDetails[0]?.sourceMeasureUnit || '',
                                  sourceEquipmentId:
                                    SourceDetails[0]?.sourceEquipmentId || '',
                                  isAlienConstant: false,
                                  value: '',
                                  sourceDeviceId:
                                    SourceDetails[0]?.sourceDeviceId || ''
                                }
                              }

                              if (!isDuplicate(item.id) && newSourceData) {
                                setSelectedSource((prevSourceList) => [
                                  ...prevSourceList,
                                  newSourceData
                                ])
                              } else if (newSourceData) {
                                const updatedSourceList =
                                  selectedSource?.filter(
                                    (source) => source?.id !== newSourceData?.id
                                  )

                                setSelectedSource([
                                  ...updatedSourceList,
                                  newSourceData
                                ])
                              } else if (isNiagara) {
                                  // Save a data mapping setting from the dropdown rather than the edit popup
                                  // Need to default some values since we can't select them like in the popup
                                  const selectedEquipment = niagaraEquipment.find(eq => eq.name === key)
                                  const id = selectedEquipment?.id
                                  // Get source property data, assuming there will only be one at this point
                                  const source = await niagaraRefetchSource({ equipmentId: id })
                                  const dataSourceName = selectedEquipment?.dataSourceName
                                  key = `${key}/${source?.items?.[0]?.sourceDisplayName}`
                                  handleAlienProperty(
                                    { ...item, ...source?.items?.[0], id: item.id },
                                    key,
                                    id,
                                    false,
                                    source?.items?.[0]?.sourceDisplayName,
                                    index,
                                    equipmentDetails?.deviceId,
                                    "",
                                    "",
                                    "",
                                    dataSourceName,
                                    equipmentDetails?.device?.name,
                                    {
                                      Dimension: "",
                                      UOM: "",
                                      dataSourceType: "",
                                      dataSourceName: dataSourceName,
                                      automationName: equipmentDetails?.device?.name
                                    },
                                    false
                                  )
                              }

                              handleDropdownChange(index, key)
                            }
                          }}
                          onClose={onBlur}
                          onOpen={onFocus}
                          options={options}
                          // options={handleAlienOption(
                          //   options,
                          //   index,
                          //   item
                          // )}
                          onRenderSelection={() => (
                            <SelectrixSelection label={fieldValueSelected} isNiagra={isNiagara}/>
                          )}
                          searchable={true}
                          searchBoxInside={true}
                          searchIndex={false}
                        ></SelectrixWrapper>
                      )
                    }}
                  />
                </div>
                <button
                  className="icon-edit"
                  style={{
                    marginTop: '6px',
                    marginLeft: '6px',
                    background: 'none',
                    border: 'none',
                    color: '#000'
                  }}
                  onClick={() => toggleEditPropertyDialog(item, index)}
                />
              </div>
            </>
          ) : item?.sourceDisplayName == 'No Property Selected' ? (
            '--'
          ) : item?.isConstant ? (
            item?.sourceDisplayName
          ) : (
            typeof item?.sourceDisplayName === "number" ? item?.sourceDisplayName :(item?.sourceDisplayName?.includes("/") && isNiagara) ? <><>{textTransform(item?.sourceDisplayName).finalArray}</>{"/"}<TranslateComponent>{textTransform(item?.sourceDisplayName).lastTwoElements}</TranslateComponent></>: <TranslateComponent>{item?.sourceDisplayName}</TranslateComponent>
          )}
        </td>
        <td>
          {item?.creationType === 'Auto' ? (
            ''
          ) : (
            <>
              {!isEditable ? (
                <Icon name="check" color="grey" />
              ) : (
                <button
                  className="icon-loader"
                  style={{
                    marginTop: '6px',
                    marginLeft: '6px',
                    background: 'none',
                    border: 'none',
                    color: '#000'
                  }}
                  onClick={() => showSinglePropertyResetPrompt(item)} // TO DO
                />
              )}
            </>
          )}
        </td>
        <td>
          {typeof item?.lastCollectedValue === 'string' &&
          isNaN(item?.lastCollectedValue) ? (
            <TranslateComponent>{item?.lastCollectedValue}</TranslateComponent>
          ) : (
            item?.lastCollectedValue
          )}
        </td>
        <td>
          {item?.targetMeasureUnit === 'noUnit'
            ? ''
            : UOMbyProfile === "SI" ? siUnitMapping.get(item?.targetMeasureType) ? uomSymbolPairs[siUnitMapping.get(item?.targetMeasureType)] : uomSymbolPairs[item?.targetMeasureUnit] : uomSymbolPairs[item?.targetMeasureUnit]}
        </td>
        <td style={{textTransform: "capitalize"}}>{item?.lastCollectedTime}</td>
      </tr>
    )
  }

  const formTable = () => {
    return (
      <Formik
        initialValues={{
          rows: filteredRow
        }}
        enableReinitialize={true}
        onSubmit={() => {
          // TO DO
        }}
      >
        {({ values }) => (
          <Form>
            <TableStyle
              data-testid={`${DETAILS_TEXT_NAME_PREFIX()}_dataMapping_table`}
              className="sc-iMfspA ehbQpl data-mapping"
            >
              <thead>
                <tr>
                  <th
                    style={{ paddingLeft: '10px' }}
                    onClick={() => {
                      // disable for ATS

                      !atsPage && setSortAscendingDescending(!ascending)
                    }}
                  >
                    <TranslateComponent>PROPERTY</TranslateComponent>
                    {!atsPage && (
                      <ArrowsForSorting
                        active={!ascending ? 'descending' : 'ascending'}
                      />
                    )}
                  </th>
                  <th>
                    <TranslateComponent>SETTING</TranslateComponent>
                  </th>
                  <th style={{ width: 250 }}>
                    <TranslateComponent>USER DEFINED</TranslateComponent>{' '}
                    <Tooltip
                      width="200px"
                      className="tooltip-overflow"
                      position="center-top"
                      content={translate(
                        'A checkmark in this column indicates that the mapped setting for this property is not the system default. It has been overridden by the user.'
                      )}
                    >
                      <span>
                        <Icon name="infocircle2" color="white" />
                      </span>
                    </Tooltip>{' '}
                  </th>
                  <th style={{ width: 180 }}>
                    <div className="header-center">
                      <TranslateComponent>LAST VALUE</TranslateComponent>
                      {loadingPropertyLatestValues && (
                        <Spinner className="table-header-spinner"></Spinner>
                      )}
                    </div>
                  </th>
                  <th style={{ width: 130 }}>
                    <TranslateComponent>UNITS</TranslateComponent>
                  </th>
                  <th style={{ width: 180 }}>
                    <div className="header-center">
                      <TranslateComponent>DATE/TIME</TranslateComponent>
                      {loadingPropertyLatestValues && (
                        <Spinner className="table-header-spinner"></Spinner>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              {filteredRow?.length ? (
                <tbody>
                  {values?.rows?.map((item, index) =>
                    item?.dataForDisplayPurpose === 'ui' ? (
                      <RenderRowForGroupUI
                        key={index}
                        item={item}
                        index={index}
                      />
                    ) : (
                      <RenderRow
                        key={index}
                        item={item}
                        index={index}
                        isAtsPage={atsPage}
                      />
                    )
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    {' '}
                    <td
                      colSpan={6}
                      style={{
                        textAlign: 'center',
                        background: '#fff',
                        fontWeight: 'bold'
                      }}
                    >
                      <TranslateComponent>
                        There are currently no properties to display.
                      </TranslateComponent>
                    </td>
                  </tr>
                </tbody>
              )}
            </TableStyle>
          </Form>
        )}
      </Formik>
    )
  }

  const translatedPlaceholder = {
    propertyPlaceholder: translate('Find a property...')
  }

  return (
    <>
      {loadingDataMappingProperties ? (
        <Spinner />
      ) : (
        <MainContainer>
          <HeaderSection
            data-testid={`${DETAILS_TEXT_NAME_PREFIX('dataMapping_filters')}`}
          >
            <div className="filter-section">
              {!atsPage ? (
                <PageListFilter
                  // Filter Properties
                  onChange={(props) => onChangeFilter(props?.searchText)}
                  searchTextLabel={translatedPlaceholder.propertyPlaceholder}
                  isShowSearchLabel={false}
                  // Custom Component Properties
                  isCustomComponentBeforeFilter={true}
                  customComponent={
                    <div className="filter-component">
                      <Label text={`${i18next.t('common:Filter')}:`} />
                      <Select
                        options={availableFilters}
                        selectedItem={selectedFilter}
                        onChange={onSelectFilter}
                      />
                    </div>
                  }
                  // Counter Properties
                  totalCountLabel={tableName}
                  totalCount={filteredRow?.length ?? 0}
                  displayControls={defaultDisplayControls}
                />
              ) : (
                <StyledLabel className="ats-title">
                  <TranslateComponent>Properties</TranslateComponent>
                </StyledLabel>
              )}
            </div>

            {!isEditable ? (
              <button
                disabled={!editable}
                type="button"
                className="edit editButton primary"
                onClick={handleEdit}
              >
                <TranslateComponent>EDIT</TranslateComponent>
              </button>
            ) : (
              <table>
                <tr>
                  <td colSpan={4}>
                    <button
                      type="button"
                      className="cancel secondary"
                      style={{ textTransform: 'inherit', marginRight: '10px' }}
                      onClick={handleCancel}
                    >
                      <TranslateComponent>Cancel</TranslateComponent>
                    </button>
                    <button
                      type="submit"
                      className="edit editButton"
                      onClick={handleSave}
                    >
                      <TranslateComponent>Save</TranslateComponent>
                    </button>
                  </td>
                </tr>{' '}
              </table>
            )}
          </HeaderSection>
          <TableSection className="ats-container">
            <TableWrapper
              className={clsx('tableFixHead', {
                'overflow-scroll': (filteredRow || []).length > 9
              })}
            >
              {formTable()}
            </TableWrapper>
          </TableSection>
          {isDataMappingEditPropertyDialogShown && (
            <DataMappingEditProperty
              propertyDetails={selectedItem}
              toggleDialog={toggleEditPropertyDialog}
              equipmentDetails={equipmentDetails}
              defaultdimensionGroup={defaultDimensionGroup}
              handleAlienProperty={handleAlienProperty}
              selectedIndex={selectedIndex}
              alienDetails={alienDetails}
              uomSymbolPairs={uomSymbolPairs}
              UOMbyProfile={UOMbyProfile}
            />
          )}

          <button
            hidden={!isEditable}
            type="button"
            className="cancel secondary btn-right"
            style={
              atsPage
                ? {}
                : {
                    textTransform: 'inherit',
                    marginRight: '10px',
                    marginTop: '10px'
                  }
            }
            onClick={showResetAllPrompt}
          >
            <TranslateComponent>Reset All to Defaults</TranslateComponent>
          </button>
        </MainContainer>
      )}

      <StandardDialogs confirm={confirmDialog} />
    </>
  )
}

export default DataMapping
