import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole
} from '@floating-ui/react'
import { OrganizationsPopup } from './OrganizationsPopup'
import { BuildingsPopup } from './BuildingsPopup'
import { SelectedDisplay } from './SelectedDisplay'
import { NothingSelected } from './NothingSelected'
import { useState } from 'react'
import { useSelectedBuilding } from './use-selected-building'

export const BuildingSelector = ({ sidebarIsExpanded }) => {
  const { organization, building, error } = useSelectedBuilding()

  // Popups Open
  const [isOrgOpen, setIsOrgOpen] = useState(false)
  const [isBuildingOpen, setIsBuildingOpen] = useState(false)
  // Organization popup
  const {
    refs: orgRefs,
    floatingStyles: orgFloatingStyles,
    context: orgContext
  } = setupFloating(isOrgOpen, setIsOrgOpen)
  const {
    getReferenceProps: orgGetReferenceProps,
    getFloatingProps: orgGetFloatingProps
  } = setupInteractions(orgContext)
  // Building popup
  const {
    refs: buildingRefs,
    floatingStyles: buildingFloatingStyles,
    context: buildingContext
  } = setupFloating(isBuildingOpen, setIsBuildingOpen)
  const {
    getReferenceProps: buildingGetReferenceProps,
    getFloatingProps: buildingGetFloatingProps
  } = setupInteractions(buildingContext)

  if (error)
    return (
      <NothingSelected
        {...{ sidebarIsExpanded, orgGetReferenceProps, orgRefs }}
      />
    )
  if (!organization) return <></>

  return (
    <div className="building-selector" data-testid="buildingSelector">
      {organization && (
        <SelectedDisplay
          {...{
            sidebarIsExpanded,
            orgGetReferenceProps,
            orgRefs,
            buildingGetReferenceProps,
            buildingRefs,
            organization,
            building
          }}
        />
      )}
      {!organization && (
        <NothingSelected
          {...{
            sidebarIsExpanded,
            organization,
            orgRefs,
            orgGetReferenceProps
          }}
        />
      )}

      {isOrgOpen && (
        <OrganizationsPopup
          {...{
            setOrganizationPopupOpen: setIsOrgOpen,
            selectedOrganization: organization,
            selectedBuilding: building,
            orgGetFloatingProps,
            orgRefs,
            orgFloatingStyles
          }}
        />
      )}
      {isBuildingOpen && (
        <BuildingsPopup
          {...{
            setBuildingsPopupOpen: setIsBuildingOpen,
            organization,
            buildingGetFloatingProps,
            buildingRefs,
            buildingFloatingStyles
          }}
        />
      )}
    </div>
  )
}

export function setupFloating(isOpen, setIsOpen) {
  return useFloating({
    placement: 'right-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(20), flip(), shift()]
  })
}

export function setupInteractions(context) {
  const hover = useHover(context, {
    handleClose: safePolygon()
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([hover, focus, dismiss, role])
}
