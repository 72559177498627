import { useEffect, useState, useMemo } from 'react'
import BaselineComparisonWidgetChart from 'src/pages/widgets/widget-library/baseLineComparison/BaselineComparisonWidgetChart'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { useQuery } from 'src/hooks/APIHooks'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import { formatData } from 'src/common/chartHelperFunctions'
import { BaselineSavingsOrOverageInfo } from './style'
import { getSelectedPeriodDisplayName } from 'src/common/chartHelperFunctions'
import moment from 'moment/moment'
import { useWidgetsContext } from 'src/pages/widgets/WidgetsContextProvider'
import translate, { TranslateComponent } from '../../../../common/translations'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import store from 'src/redux/store';
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

const listBaselinesByBuilding = /* GraphQL */ `
  query listBaselinesByBuilding(
    $buildingId: ID!
    $filter: ModelBaselineFilterInput
  ) {
    listBaselinesByBuilding(
      buildingId: $buildingId
      filter: $filter
      limit: 1000
    ) {
      items {
        name
        id
        equipmentId
        startPeriod
        endPeriod
      }
    }
  }
`

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

const GET_BASELINE_DETAILS = `
query getBaseline($id: ID!) {
  getBaseline(id: $id) {
    startPeriod
    buildingId
    endPeriod
  }
}`

export const BaselineComparisonWidgetPreview = ({ widgetDetails }) => {
  const [dataAvailable, setDataAvailable] = useState(false)

  const { refetch: getChartDataForBuilding, loading: loadingChartData } =
    useQuery({
      query: GET_CHART_DATA,
      dataPath: 'data.getChartData',
      disableInitialLoad: true
    })

  const { selectedBaseLine } = useWidgetsContext()

  const { data: baselinesList, refetch: refetchBaselinesList } = useQuery({
    query: listBaselinesByBuilding,
    dataPath: 'data.listBaselinesByBuilding.items',
    disableInitialLoad: true
  })

  const {
    data: baselineDetails,
    refetch: getBaselineDetails,
    loading: loadingBaselineDetails
  } = useQuery({
    query: GET_BASELINE_DETAILS,
    dataPath: 'data.getBaseline',
    disableInitialLoad: true
  })

  const [widgetChartData, setWidgetChartData] = useState(null)

  const [baselineDelta, setBaselineDelta] = useState(0)

  const selectedPeriod = widgetDetails?.selectedTimePeriod

  useEffect(() => {
    if (selectedPeriod === 'total') {
      getBaselineDetails({ id: widgetDetails?.baselineId })
    }
  }, [])

  useEffect(() => {
    // will be executed only at total is selected
    if (baselineDetails?.endPeriod) {
      getCurrentPeriodChartData()
    }
  }, [baselineDetails])

  const getTotalBaselineDates = () => {
    // for total baseline prediction - baseline creation date to current date
    return {
      startDate: moment(baselineDetails?.endPeriod)
        ?.add(1, 'days')
        ?.format('YYYY-MM-DD'),
      endDate: moment()?.format('YYYY-MM-DD')
    }
  }

  const { startDate, endDate } = useMemo(() =>
    selectedPeriod === 'total'
      ? getTotalBaselineDates()
      : getStartAndEndDates(selectedPeriod, false, null, widgetDetails?.timeZone)
  )

  const selectedAggrValue = widgetDetails?.selectedAggrValue

  const [barChartData, setBarChartData] = useState({})

  // index property is missed in baseline data, so push the index props
  const formatBaselineDataWithIndex = (baselineData) => {
    const newBaselineData = {}
    for (const id in baselineData) {
      const equipmentData = baselineData[id]
      const properties = {}
      const existingPropertiesObj = equipmentData?.properties || {}
      for (const propertyKey in existingPropertiesObj) {
        const index = existingPropertiesObj[propertyKey]
        properties[propertyKey] = {
          index: index,
          name: propertyKey
        }
      }
      newBaselineData[id] = {
        properties,
        values: equipmentData?.values
      }
    }
    return newBaselineData
  }

  const getCurrentPeriodChartData = async () => {
    try {
      if (selectedPeriod === 'total' && !baselineDetails?.endPeriod) {
        return
      }
      if (widgetDetails?.equipmentId) {
        let selectedLanguage = store?.getState().appData?.selectedLanguage;
        selectedLanguage = selectedLanguage ? selectedLanguage : "en";
        const chartDataRequestFormat = {
          startDate: selectedLanguage === 'en' ? startDate : moment(startDate).locale('en').format(BACKEND_DATE_FORMAT),
          endDate: selectedLanguage === 'en' ? endDate : moment(endDate).locale('en').format(BACKEND_DATE_FORMAT),
          category: 'equipment', // equipment or building level data needed
          chartType: 'line',
          building: {
            id: widgetDetails?.buildingId,
            pId: [],
            type: ''
          },
          equipment: [],
          baseline: {
            eId: widgetDetails?.equipmentId,
            pId: ['BaselinePrediction', 'ConsumptionInterval']
          },
          equipmentType: widgetDetails?.equipmentType,
          weather: [],
          settings: [],
          buildingDateTime: '2024-01-08 23:26:43'
        }

        const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
        const chartData = await getChartDataForBuilding(requestBody)
        const parsedChartData = JSON.parse(chartData)
        setWidgetChartData(parsedChartData)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (widgetDetails?.equipmentId) {
      const equipmentId = widgetDetails?.equipmentId
      const filter = { equipmentId: { eq: equipmentId } }
      const buildingId = widgetDetails?.buildingId
      refetchBaselinesList({ buildingId, filter })
      getCurrentPeriodChartData()
    }
  }, [widgetDetails])

  const baselinePeriod = translate("Baseline Period")
  const baselineIntervalInfo = useMemo(() => {
    try {
      const lDateFormat = getDateFormatByLocale('MM/DD/YYYY');
      const equipmentBaseline = baselinesList?.find(
        (x) => x.equipmentId === widgetDetails?.equipmentId
      )
      const startPeriod = moment(equipmentBaseline?.startPeriod).format(
        lDateFormat ? lDateFormat : 'MM/DD/YYYY'
      )
      const endPeriod = moment(equipmentBaseline?.endPeriod).format(
        lDateFormat ? lDateFormat : 'MM/DD/YYYY'
      )
      return `${baselinePeriod}: ${startPeriod || ''} - ${endPeriod || ''}`
    } catch (error) {
      return ''
    }
  }, [baselinesList, baselinePeriod])

  const actual = translate("Actual")
  const predicted = translate("Predicted")

  const formBarChartData = (actualValue, predictedValue) => {
    const barData = {
      measurement: widgetDetails?.symbol,
      barData: [
        {
          name: actual,
          value: actualValue,
          color: '#03b2cb'
        },
        {
          name: predicted,
          value: predictedValue,
          color: '#fb9a09'
        }
      ],
      plotLineData: null
    }
    setBarChartData(barData)
  }

  useEffect(() => {
    try {
      if (widgetChartData) {
        const formattedDataWithProperIndexCurrent = formatBaselineDataWithIndex(
          widgetChartData?.body?.baselineData
        )

        const baselineData = formatData(
          formattedDataWithProperIndexCurrent || [],
          startDate,
          endDate,
          moment.tz.guess(),
          '15m'
        )

        const actualConsumption = baselineData?.find(
          (x) => x?.propertyKey === 'ConsumptionInterval'
        )

        const prediction = baselineData?.find(
          (x) => x?.propertyKey === 'BaselinePrediction'
        )

        if (actualConsumption?.isDataAvailable || prediction?.isDataAvailable) {
          setDataAvailable(true)
        } else {
          setDataAvailable(false)
          return
        }

        const actualSum =
          actualConsumption?.values?.[0]?.reduce(
            (accumulator, { value }) => accumulator + Number(value),
            0
          ) || 0

        const predictionSum =
          prediction?.values?.[0]?.reduce(
            (accumulator, { value }) => accumulator + Number(value),
            0
          ) || 0

        const delta = Number(predictionSum - actualSum) || 0

        setBaselineDelta(delta?.toFixed(2))

        formBarChartData(actualSum, predictionSum)
      }
    } catch (error) {}
  }, [widgetChartData, actual, predicted])

  return loadingChartData || loadingBaselineDetails ? (
    <Spinner />
  ) : barChartData && Object.keys(barChartData)?.length > 0 && dataAvailable ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        equipmentType={widgetDetails?.equipmentType}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <BaselineComparisonWidgetChart data={barChartData} />
      <BaselineSavingsOrOverageInfo
        consumption={<TranslateComponent>{baselineDelta > 0 ? 'savings' : 'overage'}</TranslateComponent>}
      >
        <span className="label">
          <TranslateComponent>{baselineDelta > 0 ? 'Energy Savings' : 'Energy Overage'}</TranslateComponent>
        </span>
        <span className="energy-value">{baselineDelta} kWh</span>
      </BaselineSavingsOrOverageInfo>
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        intervalInfo={baselineIntervalInfo}
        footerInfo={`${getSelectedPeriodDisplayName(
          selectedPeriod
        )} Actual vs Predicted`}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      accountName={widgetDetails?.accountName}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      intervalInfo={baselineIntervalInfo}
      footerInfo={`${getSelectedPeriodDisplayName(
        selectedPeriod
      )} Actual vs Predicted`}
    />
  )
}
