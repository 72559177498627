import styled from 'styled-components'
import { TbDownload } from "react-icons/tb"
import { useMutation } from 'src/hooks/APIHooks'
import { GET_PRE_SIGNED_URL } from '../queries'
import { saveAs } from 'file-saver'
import { PRIMARY_BLUE } from 'src/components/color.js'

const DownloadButton = styled.button`
  color: ${PRIMARY_BLUE};
  border: none;
  background: none;
`

export const DownloadStatementButton = ({ utilityStatementId, saveFileName, usageTrackingCallback = () => {} }) => {
  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })

  const downloadFile = async (id, fileName) => {
    try {
      usageTrackingCallback()
      return await getPreSignedUrlMutation({ input: `{"action": "DOWNLOAD", "type": "energy", "statement_id": "${id}.pdf" }` })
        .then(async (res) => {
          const requestOptions: RequestInit = {
            method: 'GET',
            redirect: 'follow',
            headers: { 'Content-Type': '' }
          }
          return await fetch(
            JSON.parse(res.data.getPreSignedUrl).body,
            requestOptions
          ).then(async (response) => {
            const responseBlob = await response.blob()
            return await saveAs(responseBlob, `${fileName}.pdf`)
          })
            .catch((error) => { })
        })
    } catch (error) { }

  }

  return <DownloadButton onClick={() => downloadFile(utilityStatementId, saveFileName)}><TbDownload viewBox="3 5 20 20" /> Download</DownloadButton>
} 