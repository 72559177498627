import { EventMarkerDetail } from './EventMarkerDetail'
import { ACTIONS } from 'src/constants'
import EventMarkerList from './EventMarkerList'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import Modal from 'src/components/legacy/components/modal/modal'
import { useMutation } from 'src/hooks/APIHooks'
import { DELETE_EVENT_MARKER } from './graphql'
import { ConfirmationText } from 'src/components/layouts'
// import { AccessControl } from 'src/components/accessControl'
import translate, { TranslateComponent } from 'src/common/translations'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { useSelector } from 'react-redux'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'

// Denali components
import { EventMarkersContainer } from 'src/denali-pages/EventMarkers/EventMarkersContainer'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { Dialog } from 'src/denali-components/Dialog/Dialog'

const EventMarkers = {
  CONFIRMATION_TYPES: {
    DELETE: 'delete',
    CREATE: 'create',
    UPDATE: 'update',
    EMPTY: ''
  }
}

const EventMarkerContainer = ({
  rows,
  responseTime,
  markerTypes,
  setMode,
  mode,
  setReloadEvents,
  deleteMarkerId,
  setShowDeleteDialog,
  showDeleteDialog,
  markerInput,
  showConfirmation,
  confirmationType,
  setConfirmationType,
  setShowConfirmation,
  reloadEvents,
  accountId,
  buildingId,
  setDeleteMarkerId,
  setMarkerInput,
  initialValues,
  showSpinner
}) => {
  const optionsTranslated = {
    deleteSuccess: translate('You have successfully deleted '),
    createSuccess: translate('You have successfully created '),
    updateSuccess: translate('You have successfully updated ')
  }

  const { onSubmit: deleteMarker } = useMutation({
    query: DELETE_EVENT_MARKER,
    onSuccess: () => {
      setReloadEvents(true)
      setShowConfirmation(true)
      trackEvent(USER_EVENTS.EVENT_MARKERS.events.DELETE_EVENT_MARKER)
    }
  })
  const deleteMarkerHandler = () => {
    setConfirmationType(EventMarkers.CONFIRMATION_TYPES.DELETE)
    deleteMarker({ input: { id: deleteMarkerId } })
    hideDeleteDialog()
  }
  const hideDeleteDialog = () => {
    setShowDeleteDialog(false)
  }
  const deleteModalConfig = {
    heading: 'Delete Marker',
    buttons: [
      { text: 'Yes', handleClick: deleteMarkerHandler, type: 'valid' },
      { text: 'No', handleClick: hideDeleteDialog, type: 'cancel' }
    ],
    handleClose: hideDeleteDialog
  }
  const hideConfirmationDialog = () => {
    setShowConfirmation(false)
  }
  const confirmationConfig = {
    heading: (() => {
      switch (confirmationType) {
        case EventMarkers.CONFIRMATION_TYPES.DELETE:
          return 'Marker Deleted'
        case EventMarkers.CONFIRMATION_TYPES.CREATE:
          return 'Marker Created'
        case EventMarkers.CONFIRMATION_TYPES.UPDATE:
          return 'Marker Updated'
      }
    })(),
    buttons: [
      { text: 'Close', handleClick: hideConfirmationDialog, type: 'cancel' }
    ],
    handleClose: hideConfirmationDialog,
    autoClose: 8000
  }

  const uiMode = useSelector(selectUiMode)
  const isDenali = uiMode === UiMode.denali

  const renderDetail = () => (
    <>
      {mode && (
        <EventMarkerDetail
          rows={rows}
          mode={mode}
          setMode={(e) => setMode(e)}
          markerInput={markerInput}
          setMarkerInput={(e) => setMarkerInput(e)}
          initialValues={initialValues}
          markerTypes={markerTypes}
          buildingId={buildingId}
          accountId={accountId}
          setReloadEvents={(e) => setReloadEvents(e)}
          setShowConfirmation={setShowConfirmation}
          setConfirmationType={setConfirmationType}
        />
      )}
    </>
  )

  const denaliConfirmationConfig = {
    title: (() => {
      switch (confirmationType) {
        case EventMarkers.CONFIRMATION_TYPES.DELETE:
          return 'Marker Deleted'
        case EventMarkers.CONFIRMATION_TYPES.CREATE:
          return 'Marker Created'
        case EventMarkers.CONFIRMATION_TYPES.UPDATE:
          return 'Marker Updated'
      }
    })(),
    onClose: hideConfirmationDialog,
    onAgree: hideConfirmationDialog,
    autoClose: 8000
  }

  const denaliDeleteConfig = {
    title: 'Delete Marker',
    onClose: hideDeleteDialog,
    onAgree: deleteMarkerHandler
  }

  const renderDenaliModals = () => (
    <>
      {renderDetail()}
      {showConfirmation ? (
        <DialogConfirm {...denaliConfirmationConfig}>
          {(() => {
            switch (confirmationType) {
              case EventMarkers.CONFIRMATION_TYPES.DELETE:
                return optionsTranslated.deleteSuccess
              case EventMarkers.CONFIRMATION_TYPES.CREATE:
                return optionsTranslated.createSuccess
              case EventMarkers.CONFIRMATION_TYPES.UPDATE:
                return optionsTranslated.updateSuccess
            }
          })()}
          {markerInput?.name}
        </DialogConfirm>
      ) : null}
      {showDeleteDialog ? (
        <DialogConfirm {...denaliDeleteConfig}>
          <TranslateComponent>
            {`Are you sure you want to delete marker`}
          </TranslateComponent> {` ${markerInput?.name}`}
        </DialogConfirm>
      ) : null}
    </>
  )

  const renderModals = () => (
    <>
      {renderDetail()}
      {showConfirmation ? (
        <Modal {...confirmationConfig}>
          <ConfirmationText>
            {(() => {
              switch (confirmationType) {
                case EventMarkers.CONFIRMATION_TYPES.DELETE:
                  return `${optionsTranslated.deleteSuccess}: `
                case EventMarkers.CONFIRMATION_TYPES.CREATE:
                  return `${optionsTranslated.createSuccess}: `
                case EventMarkers.CONFIRMATION_TYPES.UPDATE:
                  return `${optionsTranslated.updateSuccess}`
              }
            })()}
            {markerInput?.name}
          </ConfirmationText>
        </Modal>
      ) : null}
      {showDeleteDialog ? (
        <Modal {...deleteModalConfig}>
          <ConfirmationText>
            <TranslateComponent>Are you sure you want to delete marker</TranslateComponent>: {markerInput?.name}
          </ConfirmationText>
        </Modal>
      ) : null}
    </>
  )

  if (isDenali) {
    return (
      <>
        <EventMarkersContainer
          createDisabled={!accountId && !buildingId}
          onCreateMarker={() => setMode(ACTIONS.ADD)}
        >
          <EventMarkerList
            uiMode={uiMode}
            responseTime={responseTime}
            rows={rows}
            setMode={(e) => setMode(e)}
            markerTypes={markerTypes}
            buildingId={buildingId}
            accountId={accountId}
            setMarkerInput={(e) => setMarkerInput(e)}
            reloadEvents={reloadEvents}
            setReloadEvents={(e) => setReloadEvents(e)}
            setDeleteMarkerId={(e) => setDeleteMarkerId(e)}
            setShowDeleteDialog={(e) => setShowDeleteDialog(e)}
            showSpinner={showSpinner}
          />
        </EventMarkersContainer>
        {renderDenaliModals()}
      </>
    )
  }

  return (
    <>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={<TranslateComponent>Event Markers</TranslateComponent>}
            titleTools={
              <Button
                disabled={!accountId && !buildingId}
                onClick={() => setMode(ACTIONS.ADD)}
              >
                <TranslateComponent>CREATE MARKER</TranslateComponent>
              </Button>
            }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <EventMarkerList
            uiMode={uiMode}
            responseTime={responseTime}
            rows={rows}
            setMode={(e) => setMode(e)}
            markerTypes={markerTypes}
            buildingId={buildingId}
            accountId={accountId}
            setMarkerInput={(e) => setMarkerInput(e)}
            reloadEvents={reloadEvents}
            setReloadEvents={(e) => setReloadEvents(e)}
            setDeleteMarkerId={(e) => setDeleteMarkerId(e)}
            setShowDeleteDialog={(e) => setShowDeleteDialog(e)}
            showSpinner={showSpinner}
          />
        </Content>
      </Container>
      {renderModals()}
    </>
  )
}

export default EventMarkerContainer