import { useEffect, useState } from 'react'
import ClientSideTable from 'src/components/Table/clientSideTable'
import { isUndefined } from 'src/components/legacy/common/helpers'
import {getUniqueItems} from "src/common/helperFunctions"
import { getOfferingName,isOfferingDisabled } from 'src/pages/contracts-and-offerings/common/contracts-and-offerings'
import { translate } from 'src/common/translations'


export const BuildingsList = ({
    formik,
buildings,
newOfferingsToBuildinsMap,
config
}) => 
{

    const isCheckboxEnabled = value =>  value === true || isUndefined(value) 


    const [buildingsList,setBuildingsList] = useState([])
    useEffect(() => {
        if(buildings?.length > 0 ){
            const selectedBuildings = buildings.filter(building =>  
                newOfferingsToBuildinsMap.some(item =>  item.buildingId === building.key ))
const list = selectedBuildings.map(building => {
    const offerings = newOfferingsToBuildinsMap.filter(item =>  item.buildingId === building.key )
    const checkBoxEnabled  = offerings.every(off => isCheckboxEnabled(off.checkboxCheckedEnabled))
    const intermediate =   offerings.some(off => isCheckboxEnabled(off.checkboxCheckedEnabled))
    return {...building,
        intermediate : !checkBoxEnabled && intermediate,
        checkboxCheckedEnabled:checkBoxEnabled,
        subData : offerings.map(offering => ({
            label :  getOfferingName(config,offering.name) || offering.name ,
            key: offering.name,
            checkboxCheckedEnabled:isCheckboxEnabled(offering.checkboxCheckedEnabled),
            buildingId:building.key ,
            isSubData:true,
            rowCheckbox : true,
            checkboxClick: (row,buildingsList) => {
                const updatedBuildingList = buildingsList.map(building => {
                    const buildingToUpdate = building.key === row.buildingId ;
                    const checkboxCheckedEnabled = !row.checkboxCheckedEnabled
                    if(buildingToUpdate) {
                        const selectedOfferings =  building.subData.filter(o => o.checkboxCheckedEnabled)
                        const offeringsToUncheck = selectedOfferings.filter(offering => isOfferingDisabled([offering.key],row.key))
                    const updatedSubData = building.subData.map(sub => {
                        const offeringToUpdate = sub.key === row.key
                        
                        if(offeringToUpdate){
                        return {...sub,
                       checkboxCheckedEnabled
                    }
                        }
                        else{
                            const offeringToUncheck = offeringsToUncheck.some(o => o.key === sub.key)
                            return offeringToUncheck ?  {
                                ...sub,
                                checkboxCheckedEnabled:false
                            } : 
                             sub
                        }
                    })
                        
                            const buildingCheckboxCheckedEnabled = updatedSubData.every(sub => sub.checkboxCheckedEnabled)
                            const buildingCheckboxCheckedDisabled = updatedSubData.every(sub => !sub.checkboxCheckedEnabled)
                            const intermediate =  updatedSubData.some(sub => sub.checkboxCheckedEnabled)
                    return {...building,
                        intermediate : !buildingCheckboxCheckedEnabled && intermediate,
                        checkboxCheckedEnabled:buildingCheckboxCheckedEnabled ? true : buildingCheckboxCheckedDisabled ? false : building.checkboxCheckedEnabled ,
                        subData : updatedSubData
                    }
                }else {
    return building
                }
                })

                setBuildingsList(updatedBuildingList)
                updateForm(updatedBuildingList)
            } 
        }))}
})
setBuildingsList(list)
    }
    },[buildings,newOfferingsToBuildinsMap])


   const  updateForm = updatedBuildingList => {
    const mappings = updatedBuildingList.reduce((acc,building) => {
        const mappings = building.subData.map(off => ({
            name: off.key,
            checkboxCheckedEnabled : off.checkboxCheckedEnabled,
            buildingId: building.key
        }))
        acc = [...acc,...mappings]
        return acc;
    },[])
    formik.setValues({...formik.values,
        buildingIds : getUniqueItems([...formik.values.buildingIds,...mappings.map(mapping => mapping.buildingId)]),
        offerings : getUniqueItems([...mappings.filter(_mapping => _mapping.checkboxCheckedEnabled)?.map(mapping => mapping.name)]),
        "newOfferingsToBuildinsMap":[...mappings]
        })
 }

const nameHeadings = [
    {
        name: 'label',
        title: translate("Building Name"),
        key: 'label',
        maxWidth: '75px'
      },
    {
      name: 'crmCustId',
      title: translate("CRM Cust ID"),
      key: 'crmCustId',
      maxWidth: '75px'
    },
   
    {
      name: 'address',
      title: translate("Address"),
      key: 'address',
      maxWidth: '100px'
    },
]

return (buildingsList?.length > 0 &&  <ClientSideTable	
		fixedLayout={false}
		rowCheckbox ={true}
        header={nameHeadings}
        search={true}
		searchFields = {["label"]}
        rows={buildingsList || []}
        checkboxClick={row => 
            {
                if(row.isSubData){
                    row.checkboxClick(row,buildingsList)
                }else {
                    const updatedBuildingList = buildingsList.map(building => {
                        const buildingToUpdate = building.key === row.key ;
                        if(!buildingToUpdate){
                            return building
                        }
                        return {...building,
                            checkboxCheckedEnabled:!building.checkboxCheckedEnabled ,
                            subData : building.subData.map(sub => 
                                ({...sub,checkboxCheckedEnabled : buildingToUpdate ? !building.checkboxCheckedEnabled
                                    :
                                    sub.checkboxCheckedEnabled}))
                        }
                    })
                    setBuildingsList(updatedBuildingList)
                    updateForm(updatedBuildingList)
                }
            }
        }
	/>
)
}