import React, { PureComponent } from 'react'
import Modal from '../modal/modal'
import PropTypes from 'prop-types'
import { TranslateComponent } from 'src/common/translations'

export const ConfirmDialog = (props) => (
  <Modal
    error={props.error}
    className={props.className}
    heading={props.title || <TranslateComponent>Confirm</TranslateComponent>}
    buttons={[
      {
        type: 'confirm',
        text: props.confirmText || <TranslateComponent>Confirm</TranslateComponent>,
        handleClick: () => props.handleConfirm(),
        isHideConfirmButton: props?.isHideConfirmButton ?? false
      },
      {
        type: 'cancel',
        text: props.cancelText || <TranslateComponent>Cancel</TranslateComponent>,
        handleClick: () => props.handleCancel(),
        isHideCancelButton: props?.isHideCancelButton ?? false
      }
    ]}
    handleClose={props.handleCancel}
    eventNames={[
      { eventName: 'keyup', eventCode: 27 },
      { eventName: 'mousedown', eventCode: 1 }
    ]}
  >
    <div className="confirm-dialog">{props.isLoading || props.children}</div>
  </Modal>
)

ConfirmDialog.defaultProps = {
  isLoading: false,
  error: false
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool
}

export default ConfirmDialog
