import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
const screen_max_extra_small = '633px'

export const FileUploadWrapper = styled.div`
  & .uploading-file {
    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;
      & > * {
        flex-shrink: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    & label[for='description'] {
      margin-right: 6px;
    }
    & input[name='description'] {
      flex-grow: 1;
      flex-basis: 255px;
      max-width: 100%;
      margin-left: 6px;
    }

    // reset basic typography styles
    label + input {
      margin-left: 0;
    }

    & label.file {
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.27px;
      text-transform: uppercase;
      color: ${colors.WHITE};
      padding: 4px 10px;
      margin: 0 6px;
      cursor: pointer;
      background-color: ${colors.TBA_MEDIUM_GREY};
      border: 1px solid ${colors.TBA_DARK_GREY};
      border-radius: 2px;
    }

    & label.file.selected {
      background-color: ${colors.TBA_LIGHTER_GREY};
    }

    & .remove-icon {
      min-width: 22px;
      height: 22px;
      font-size: 12px;
    }

    & .upload-button {
      background: ${colors.GREEN};
      min-width: 90px;
    }

    & .upload-button.disabled:hover {
      cursor: auto;
      background: ${colors.GREEN};
    }

    & .error-upload {
      background: ${colors.RED};
      min-width: 90px;
      & .icon-error {
        margin-right: 0;
        font-size: 14px;
      }

      &:hover {
        background: ${colors.RED};
        cursor: auto;
      }
    }

    & .filename {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      max-width: 160px;
      white-space: nowrap;
    }

    & .filename.uploading {
      color: ${colors.TBA_LIGHTER_GREY};
    }

    & .spinner {
      color: ${colors.WHITE};
    }

    & .tooltip-container {
      display: inline-block;
    }

    & .error-message {
      padding: 0;
      border: none;
      background: none;
    }

    @media (max-width: ${screen_max_extra_small}) {
      & label.file {
        margin-right: 0;
      }
      & > div {
        & > label {
          margin-bottom: 6px;
        }
        & > div {
          display: flex;
          flex-flow: column;
          align-items: flex-end;
        }
      }
    }

    &-title {
      margin-left: 30px;
    }

    &-data {
      margin-right:6px;
      margin-left:0px
    }
    &-content {
      display: flex;
      align-items: center;
      width: 345px;
    }
    & label.selctedFile{
       white-space: nowrap;
       text-overflow: ellipsis;
       overflow: hidden;
       width: 45%;
       margin: 0px 5px 0px 0px;
    }     
  }
`
