import { createContext, useContext } from 'react'
import { InlineBuildingSelectorContextType } from '../types'

export const InlineBuildingSelectorContext =
  createContext<InlineBuildingSelectorContextType>(null)

export const useInlineBuildingSelectorContext = () => {
  const context = useContext(InlineBuildingSelectorContext)

  if (context == null) {
    throw new Error(
      'InlineBuildingSelector component must be wrapped in <InlineBuildingSelectorProvider />'
    )
  }

  return context
}
