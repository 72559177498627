import colors from 'src/components/layouts/colors.json'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import XYChart from 'src/chart-library/Charts/XYChart'
import { getBarDataCategory } from 'src/chart-library/CommonComponents/Bar/helper'
import {
  ChartDataTransformation,
  euiMeasurement,
  eciMeasurement
} from './helpers'
import { min, max, isNumber } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import * as d3 from 'd3'
import translate from 'src/common/translations'

const DataChart = ({
  markers,
  benchmarks,
  chartDates,
  data,
  isDefaultYaxis,
  customMinMax,
  updateChartMinMax,
  chartViewType,
  showBenchmarks,
  toggleBenchmarks,
  setAxisValues,
}) => {
  const startDate = chartDates?.chartStartDate
    ? Date.parse(chartDates?.chartStartDate)
    : null
  const endDate = chartDates?.chartEndDate
    ? Date.parse(chartDates?.chartEndDate)
    : null
  const { eciList, euiList, dateX, euiBarData, eciBarData } =
    ChartDataTransformation(
      data,
      chartDates?.chartStartDate,
      chartDates?.chartEndDate
    )
  const yearText = translate("year")
  const barCategories = getBarDataCategory(euiBarData) ?? []
  useEffect(() => {
    const benchmarkValues = benchmarks?.map((marker) => marker.value)
    const benchmarkMin = min(benchmarkValues)
    const benchmarkMax = max(benchmarkValues)
    const chartMinMax = {
      section2min: min(eciList) || 0,
      section2max: max(eciList) || 100,
      section1min: min([min(euiList), benchmarkMin]) || 0,
      section1max: max([max(euiList), benchmarkMax]) || 100
    }
    updateChartMinMax?.(chartMinMax)
  }, [eciList, euiList, benchmarks])

  const plotLinesX1 = markers?.map((marker, index) => {
    return {
      axisKey: 'x1',
      show: true,
      visible: true,
      stroke: colors.TBA_LIGHT_GREY,
      strokeWidth: 3,
      value: Date.parse(marker.markerDate),
      dashType: 'longDash',
      id: `plx1-${index}`,
      name: marker?.name,
      type: 'plotLine',
      canvasIndex: '0',
      text: {
        dominantBaseline: 'middle',
        fontSize: 11,
        fontColor: 'gray',
        background: {
          fill: '#F5F5F5',
          fillOpacity: '0.8',
          stroke: 'gray',
          strokeWidth: '1'
        }
      }
    }
  })
  const plotLinesY1 = benchmarks?.map((marker, index) => {
    return {
      axisKey: 'y1',
      stroke: 'blue',
      strokeWidth: 3,
      considerPlotLine: true,
      value: marker.value,
      dashType: 'solid',
      hideTooltip: true,
      show: true,
      visible: true,
      id: `ply1-${index}`,
      name: marker?.name,
      type: 'plotLine',
      canvasIndex: '0',
      text: {
        dominantBaseline: 'middle',
        fontSize: 11,
        fontColor: 'gray',
        background: {
          fill: '#F5F5F5',
          fillOpacity: '0.8',
          stroke: 'gray',
          strokeWidth: '1'
        }
      }
    }
  })
  const createXAxisConfig = () => {
    const ticketRange = d3.utcMonth.count(startDate, endDate)

    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleTime',
            defaultRange: [startDate, endDate] // Required
          },
          scaleSettings: {
            //... D3 scale Settings optional
          },
          categories: dateX,
          d3Ticks:
            ticketRange === 1
              ? d3.utcWeek.every(1)
              : ticketRange === 0
              ? d3.timeDay.every(1)
              : ticketRange < 4
              ? d3.utcWeek.every(2)
              : d3.utcMonth.every(1),
          ...(chartViewType === 'line' ? '' : { scaleBand: 'x2' })
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: true
        },
        grid: [
          {
            stroke: colors.TBA_LIGHTEST_GREY,
            includeAxis: false
          }
        ]
      },
      {
        key: 'x2',
        scale: {
          props: {
            type: 'scaleBand'
            // defaultRange:  [startDate, endDate], // Required
          },
          scaleSettings: {
            padding: '0.2'
          },
          categories: barCategories
        }
      }
    ]
  }

  const createYAxis = () => {
    const benchmarkValues = benchmarks?.map((marker) => marker.value)
    const benchmarkMin = min(benchmarkValues) - min(benchmarkValues) * 0.1
    const benchmarkMax = max(benchmarkValues) + max(benchmarkValues) * 0.01
    const getY1MinMax = () => {
      if (isDefaultYaxis) {
        return [
          min([min(euiList), benchmarkMin]) || 0,
          max([max(euiList), benchmarkMax]) || 100
        ]
      } else {
        return [customMinMax?.section1min, customMinMax?.section1max]
      }
    }

    const getY2MinMax = () => {
      if (isDefaultYaxis) {
        return eciList?.filter((i) => isNumber(i) && i)?.length > 0
          ? null
          : [0, 100]
      } else {
        return [customMinMax?.section2min, customMinMax?.section2max]
      }
    }
    
    const Y1Axis =  {
      key: 'y1',
      scale: {
        props: {
          defaultRange: getY1MinMax(),
          rangeFixed: true, // Optional
          type: SCALE_LINEAR,
        }
      },
      axis: {
        name: {
          symbol: euiMeasurement,
          text: 'EUI',
          alignment: 'start', // start middle end --> default to Middle
          isTranslate: false
        },
        hideTicks: true,
        hideLine: true,
        visibleAt: ['leftLeft'], // Optional,
        notD3: true
      },
      grid: [
        {
          stroke: colors.TBA_LIGHTEST_GREY,
          includeAxis: false
        }
      ]
    }
    const Y2Axis = {
      key: 'y2',
      scale: {
        props: {
          defaultRange: getY2MinMax(),
          type: SCALE_LINEAR,
        }
      },
      axis: {
        notD3: true,
        name: {
          symbol: eciMeasurement,
          text: 'ECI',
          alignment: 'start', // start middle end --> default to Middle
          isTranslate: false
        },
        hideTicks: true,
        hideLine: true,
        // format: (value) => parseFloat(value)?.toFixed(0), //can be enabled once step breakdown done
        visibleAt: ['rightRight'] // Optional,
      },
      grid: [
        {
          stroke: colors.TBA_LIGHTEST_GREY,
          includeAxis: false
        }
      ]
    }
    if (toggleBenchmarks?.includes('eui') && toggleBenchmarks?.includes('eci')) {
      return []
    }
    else if (toggleBenchmarks?.includes('eui')) {
      return [Y2Axis]
    }
    else if (toggleBenchmarks?.includes('eci')) {
      return [Y1Axis]
    }
    else{
      return [
        Y1Axis,
        Y2Axis
      ]
    }   
  }

  const chartLegend = useMemo(() => {
    const legends = []
    if (chartViewType === 'line') {
      const lineLegends = [
        {
          id: '1',
          name: `EUI (KBtu/ft2/${yearText})`,
          seriesKey: '1',
          elementProps: {
            stroke: '#6E86CF',
            strokeWidth: 3
          },
          xAxisKey: 'x1',
          yAxisKey: 'y1',
          canvasIndex: '0',
          dashType: 'solid',
          properties: {},
          settings: {
            curve: {
              type: 'curveLinear', // curveCardinal, curveLinear, curveBasis, curveStep, curveNatural,curveLinear
              settings: {
                alpha: 0.5
              }
            },

            digits: {
              value: 3
            }
          },
          shape: 'Dots',
          show: chartViewType === 'line',
          visible: true,
          type: 'line',
          tooltipDataModelFormatter: ({ value, xValue }) => ({
            value: value ? value.toFixed(2) : value,
            title: format(new Date(xValue), 'MMMM, yyyy')
          })
        },
        {
          id: '2',
          name: `ECI ($/ft2/${yearText})`,
          elementProps: {
            stroke: '#CB4689',
            strokeWidth: 3
          },
          xAxisKey: 'x1',
          yAxisKey: 'y2',
          canvasIndex: '0',
          symbol: '%',
          dashType: 'solid',
          settings: {
            curve: {
              type: 'curveLinear', // curveCardinal, curveLinear, curveBasis, curveStep, curveNatural,
              settings: {
                alpha: 0.5
              }
            },

            digits: {
              value: 3
            }
          },
          shape: 'Dots',
          show: chartViewType === 'line',
          visible: true,
          seriesKey: '2',
          type: 'line',
          tooltipDataModelFormatter: ({ value, xValue }) => ({
            value: value ? value.toFixed(2) : value,
            title: format(new Date(xValue), 'MMMM, yyyy')
          })
        }
      ]
      legends.push(...lineLegends)
    } else if (chartViewType === 'bar') {
      const barLegends = [
        {
          id: '3',
          name: 'EUI (KBtu/ft2/year)',
          seriesKey: '3',
          elementProps: {
            stroke: '#00B9E4',
            strokeWidth: 3
          },
          xAxisKey: 'x2',
          yAxisKey: 'y1',
          canvasIndex: '0',
          properties: {},
          show: chartViewType === 'bar',
          visible: true,
          type: 'comparisonBar'
        },
        {
          id: '4',
          name: 'ECI ($/ft2/year)',
          seriesKey: '4',
          elementProps: {
            stroke: '#61AE34',
            strokeWidth: 3
          },
          xAxisKey: 'x2',
          yAxisKey: 'y2',
          canvasIndex: '0',
          properties: {},
          show: chartViewType === 'bar',
          visible: true,
          type: 'comparisonBar'
        }
      ]
      legends.push(...barLegends)
    }
    legends.push(...(plotLinesX1 || []), ...(plotLinesY1 || []))
    return legends
  }, [chartViewType, plotLinesX1, plotLinesY1])

  const chartData = {
    1: { data: euiList },
    2: { data: eciList },
    3: { data: euiBarData ?? [] },
    4: { data: eciBarData ?? [] }
  }

  const getData = () => {
    return {
      types: chartLegend,
      data: chartData
    }
  }

  const chartConfigData = {
    canvas: {
      canvasDetails: {
        0: {
          renderAreaShade: {
            fill: colors.WHITE,
            opacity: '1'
          },
          tooltip: {
            type: 'x', // x, xy - xy not yet supported
            referenceLineV: true,
            hide: chartViewType === 'bar'
          }
        }
      }
    },
    x: createXAxisConfig(),
    y: createYAxis(),
    series: getData(),
    width: '98%',
    height: 600,
    container: {
      className: '',
      attributes: {}
    },
    legends: {
      className: 'engery-intensity-chart-legend',
      getSelectedItems: (selectedLegend) => {
        showBenchmarks(selectedLegend)
      }
    },
  }

  return <XYChart {...chartConfigData} setAxisValues={setAxisValues} />
}

export default React.memo(DataChart)
