import { Flex, Input, Label, RadioGroupField } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, forwardRef, memo, useCallback } from 'react'

// Project imports.
import translate, { TranslateComponent } from 'src/common/translations'

// Denali imports.
import { CardRadio } from 'src/denali-components/Form/CardRadio'
import formStyles from 'src/denali-components/Form/form.module.scss'
import {
  faBuildings,
  faMagnifyingGlass,
  faRotateRight
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { useInlineBuildingSelectorContext } from './hooks/use-inline-building-selector-context'
import { InlineBuildingSelectorProps } from './types'

const InlineBuildingSelectorComponentWrapper = forwardRef(
  function InlineBuildingSelectorComponent(
    { value, onChange, disabled, onAfterChange }: InlineBuildingSelectorProps,
    _
  ) {
    const {
      buildings,
      iconColors,
      searchBuildings,
      setSearchTerm,
      buildingsGroup,
      activeScroll,
      setActiveScroll,
      resetSearch
    } = useInlineBuildingSelectorContext()

    const searchPlaceholder = translate('Search')

    const onChangeHandler = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
        onAfterChange(event)
      },
      [onChange, onAfterChange]
    )

    return (
      <Flex direction="column" data-testid="inlineBuildingSelector">
        <Flex className={formStyles.inlineBuildingSelectorHeader}>
          <FontAwesomeIcon icon={faBuildings} />
          <Flex direction="column" gap="0">
            <TranslateComponent>{buildings[0]?.accountName}</TranslateComponent>
            <span className={formStyles.subHeader}>
              <TranslateComponent>Organization</TranslateComponent>
            </span>
          </Flex>
        </Flex>
        <hr className={formStyles.subSectionSpacer} />
        <Label htmlFor="searchBuildings" position="relative">
          <TranslateComponent>Select a Building*</TranslateComponent>
          <div className={formStyles.searchInner}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <Input
              id="searchBuildings"
              placeholder={searchPlaceholder}
              // aria-description={searchDesc}
              type="search"
              ref={searchBuildings}
              onChange={(event) => {
                onBuildingSearchChange(event, buildings, setSearchTerm)
              }}
            />
          </div>
          {activeScroll && buildings.length > 0 && (
            <div className={formStyles.scrollRightOverlay}></div>
          )}
          {buildings.length === 0 && (
            <Flex>
              <button
                className={formStyles.noBuildingResults}
                onClick={resetSearch}
              >
                <TranslateComponent>No Buildings found</TranslateComponent>
                <div className={formStyles.noBuildingsSubText}>
                  <FontAwesomeIcon icon={faRotateRight}></FontAwesomeIcon>
                  <TranslateComponent>Reset Filters</TranslateComponent>
                </div>
              </button>
            </Flex>
          )}
          {buildings.length > 0 && (
            <Flex
              className={formStyles.horizontalWrapper}
              ref={buildingsGroup}
              onScroll={() => {
                handleInnerScrollFade(buildingsGroup, setActiveScroll)
              }}
            >
              <RadioGroupField
                label="Buildings"
                labelHidden
                name="location.locationId"
                value={value}
                onChange={onChangeHandler}
                className={formStyles.cardRadioGroup}
              >
                <div className={formStyles.cardCheckboxWrapper}>
                  {buildings.map((building, index) => {
                    const iconColor = iconColors ? iconColors[index] : null

                    return (
                      <CardRadio
                        key={building.key}
                        name={building.value}
                        value={building.key}
                        color={iconColor}
                        disabled={disabled}
                      />
                    )
                  })}
                </div>
              </RadioGroupField>
            </Flex>
          )}
        </Label>
      </Flex>
    )
  }
)

export const InlineBuildingSelector = memo(
  InlineBuildingSelectorComponentWrapper,
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value
  }
)

function onBuildingSearchChange(event, buildings, setSearchTerm) {
  const searchTerm = event.target.value.toLowerCase()
  if (searchTerm.length > 0) {
    buildings.searchOptions = buildings.filter((building) =>
      building?.value?.toLowerCase().includes(searchTerm)
    )
  } else {
    buildings.searchOptions = undefined
  }

  setSearchTerm(searchTerm)
}

function handleInnerScrollFade(buildingsGroup, setActiveScroll) {
  const optionsWrapper = buildingsGroup?.current
  const optionsList = optionsWrapper?.firstElementChild
  const optionsRight = optionsList?.clientWidth - optionsWrapper?.scrollLeft

  setActiveScroll(optionsRight - 10 >= optionsWrapper?.clientWidth)
}
