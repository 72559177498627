import styles from './table.module.scss'

export const Skeleton = ({ isClassic }: { isClassic: boolean }) => {
  return (
    <tr className={styles.skeletonTr}>
      <td className={styles.skeletonTd}>
        <div
          className={styles.skeletonRoot}
          data-testid="skeletonTableRoot"
          data-classic={isClassic}
        >
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </div>
      </td>
    </tr>
  )
}

const SkeletonRow = () => {
  return <div className={styles.skeletonRow}></div>
}
