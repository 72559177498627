import React from "react"
import PropTypes from "prop-types"
import moment from "moment/moment"
import classNames from "classnames"
import i18next from "i18next"
import _get from "lodash/get"
import _set from "lodash/set"
import _find from "lodash/find"
import _compact from "lodash/compact"
// import DateInput from "react/shared/datepicker-input"
// import { DebounceInput } from "react/shared/debounce-input"
// import { getConfig, isBeta } from "common/config"
// import { CAP_OFFERING_TYPE, OPTICS_OFFERING_TYPE } from "common/permissions/tis-offerings"
// import { BACKEND_DATE_FORMAT } from "common/time-helpers"
import Label from 'src/components/legacy/components/label/label'
import { DATE_FORMAT, DEFAULT_DATE } from "src/components/legacy/common/time-helpers.js"
// import { reSubDomain, MAX_SUBDOMAIN_LENGTH } from "react/screens/location-management-page/location-management-details/common/validation.js"
import { AcceptDeclineValidate } from './approval/formValidator'
import { getTranslatedValue } from "../common/contracts-and-offerings"
// import { removeDeepKeys } from "common/helpers"
// import { cloneDeep } from "@apollo/client/utilities"
// import { trackClickRequestDeclined, trackClickActivateOfferings, trackClickActiveEditContractSubmit } from "react/screens/contracts-and-offerings/track"

const needFormatDateString = (value = "") => value.includes("T")

const getFormattedDate = (value) => {
	if (value) {
		return typeof value === "string"
			? needFormatDateString(value) && moment(value).format(DATE_FORMAT) || value
			: moment(value).format(DATE_FORMAT)
	}
}

export const DEFAULT_PICKER_DATE = getFormattedDate(DEFAULT_DATE)

export const TSDialog = "TSDialog"
export const BPEPDialog = "BPEPDialog"
export const BPEPEditDialog = "BPEPEditDialog"
export const ActiveContractEditDialog = "ActiveContractEditDialog"
export const BPEPApproveDialog = "BPEPApproveDialog"
export const OEDialog = "OEDialog"
export const CRMSiteForm = "CRMSiteForm"

export const OFFERING_REQUEST_CREATED = "offering-request-created"
export const OFFERING_REQUEST_CREATED_UI_NOTIFICATION_ONLY = "offering-request-created-ui-notification-only"
export const OFFERING_REQUEST_UPDATED = "offering-request-updated"
export const OFFERING_REQUEST_UPDATED_UI_NOTIFICATION_ONLY = "offering-request-updated-ui-notification-only"
export const OFFERING_REQUEST_DELETED = "offering-request-deleted"
export const OFFERING_REQUEST_ACTIVATED = "offering-request-activated"
export const OFFERING_REQUEST_ACTIVE_UPDATE = "offering-request-active-update"
export const OFFERING_REQUEST_CANCELED = "offering-request-canceled"
export const OFFERING_REQUEST_DECLINED = "offering-request-declined"

const MAX_LENGTH_OF_NUMBER = 50
const MAX_LENGTH_OF_PACT_NUMBER = 50
const MAX_LENGTH_OF_MSA_NUMBER = 50
const MAX_LENGTH_OF_CRM_SITE_ID_NUMBER = 10

export const NO_SALES_OFFICE_ERROR = "noSalesOffice"

export const REVIEW = "review"
export const REQUEST = "request"

export const HISTORY_PARAMS_OFFERING_MAP = new Map([
	[REVIEW, BPEPApproveDialog],
	[REQUEST, BPEPDialog]
])

export const getLocationOption = ({ locationId, locationName, address = {}, line1 = "", city = "", country = "", customerCRMSiteId }) => ({
	key: locationId,
	label: locationName,
	line1: address.line1 || address.locationAddressString || line1,
	city: address.city || city,
	country: address.country || country,
	customerCRMSiteId
})

export const ContractLength = {
	"Year": "{{count}} Year",
	"Year_plural": "{{count}} Years",
	"Trial": "3 months",
	"14months": "14 months",
	"Custom": "Custom"
}

export const CONTRACT_PERIODS = [
	{ key: "trial", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Trial"), days: [89, 90, 91], value: { key: "M", duration: 3 } },
	{ key: "1-year", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Year", { count: 1 }), days: [364, 365], value: { key: "Y", duration: 1 } },
	{ key: "14-month", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>14months", { count: 1 }), days: [423, 424, 425], value: { key: "M", duration: 14 } },
	{ key: "2-year", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Year", { count: 2 }), days: [729, 730], value: { key: "Y", duration: 2 } },
	{ key: "3-year", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Year", { count: 3 }), days: [1094, 1095], value: { key: "Y", duration: 3 } },
	{ key: "4-year", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Year", { count: 4 }), days: [1460], value: { key: "Y", duration: 4 } },
	{ key: "5-year", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Year", { count: 5 }), days: [1825, 1826], value: { key: "Y", duration: 5 } },
	{ key: "custom", label: i18next.t("building-configuration:BuildingSetupPage>ContractLength>Custom"), days: [0], value: { key: "d", duration: 0 } }
]

export const ACCEPTED_FILE_TYPES = ["doc", "docx", "xls", "xlsx", "ppt", "pot", "pptx", "potx", "jpg", "png", "gif", "bmp", "mpg", "pdf", "txt", "csv", "jpeg"]

export const getContractPeriod = lengthInDays => {
	const contractPeriodData = _find(CONTRACT_PERIODS, contract => (contract.days).includes(lengthInDays))
	return contractPeriodData?.key || "custom"
}

export const getDaysDiffBetweenDates = (startDate, endDate) => moment(endDate).diff(moment(startDate).startOf("day"), "days")

export const getExpirationDateByContractPeriod = (contractStartDate, contractPeriod, expDate) => {
	if (contractPeriod !== "custom") {
		const lengthInMonths = CONTRACT_PERIODS.find(({ key }) => key === contractPeriod).value
		const expirationDate = moment(moment(contractStartDate).add(moment.duration({ [lengthInMonths.key]: lengthInMonths.duration })).format("MM/DD/YYYY")).subtract(1, "day").format("MM/DD/YYYY")
		return expirationDate
	}
	return expDate
}

// export const getSubscriptionLengthInDaysByPeriod = (contractPeriod, contractStartDate, expirationDate) => CONTRACT_PERIODS.find(({ key }) => key === contractPeriod).days || getDaysDiffBetweenDates(contractStartDate, expirationDate)

// Calculate the subscription length based on calendar months instead of days (TC-810)
export const getSubscriptionLengthInDaysByPeriod = (contractPeriod, contractStartDate, expirationDate, activeOfferEdit = false) => {
	if (contractPeriod !== "custom" && !activeOfferEdit) {
		const expirationDate = getExpirationDateByContractPeriod(contractStartDate, contractPeriod, expirationDate)
		const totalSubDays = moment(expirationDate).diff(moment(contractStartDate).startOf("day"), "days")
		return totalSubDays
	} else {
		return getDaysDiffBetweenDates(contractStartDate, expirationDate)
	}
}

export const getPeriodBySubscriptionLengthInDays = (contractStartDate, expirationDate) => {
	const lengthInDays = getDaysDiffBetweenDates(contractStartDate, expirationDate)
	return CONTRACT_PERIODS.find(({ days }) => days === lengthInDays)?.key || "custom"
}

export const DEFAULT_EXPIRATION_DATE = (365 * 6) - 1
export const getExpirationDate = (contractStartDate, subscriptionLengthInDays = DEFAULT_EXPIRATION_DATE) => moment(contractStartDate).add(subscriptionLengthInDays, "days").format(DATE_FORMAT)

const ALL_AM = ["AM DX 30min", "AM DX 1hr", "AM DX 2h", "AM Chiller 30min", "AM Chiller 1hr", "AM Chiller 2h", "AM DX/Chiller 30min", "AM DX/Chiller 1hr", "AM DX/Chiller 2h"]
const ALL_CAP = ["CAP TestDrive", "CAP Data", "CAPxPRO", "CAPxM&V"]
const ALL_CMSA = ["DI-CH"]

// Default Contract Period is set here but can be updated with a data from API
export const OFFERING_LIST = new Map([
	["CAP TestDrive", {
		order: 0,
		allowedSubscriptions: [],
		defaultContractPeriod: 90
	}],
	["CAP Data", {
		order: 1,
		allowedSubscriptions: [...ALL_AM],
		defaultContractPeriod: 365
	}],

	["CAPxPRO", {
		order: 2,
		allowedSubscriptions: [...ALL_AM],
		defaultContractPeriod: 423
	}],
	["CAPxM&V", {
		order: 3,
		allowedSubscriptions: [...ALL_AM],
		defaultContractPeriod: 365
	}],
	["DI-CH", {
		order: 4,
		allowedSubscriptions: [...ALL_AM]
	}],
	["AM DX 30min", {
		order: 5,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM DX 1hr", {
		order: 6,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM DX 2h", {
		order: 7,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM Chiller 30min", {
		order: 8,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM Chiller 1hr", {
		order: 9,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM Chiller 2h", {
		order: 10,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM DX/Chiller 30min", {
		order: 11,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM DX/Chiller 1hr", {
		order: 12,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}],
	["AM DX/Chiller 2h", {
		order: 13,
		allowedSubscriptions: [...ALL_CAP, ...ALL_CMSA],
		defaultContractPeriod: null
	}]
])

export const dialogsConfig = new Map([
	[TSDialog, {
		title: "Activate Tenant Services",
		initialValues: values => {
			if (!values) {
				values = {}
			}
			const { startDate = DEFAULT_PICKER_DATE, endDate = DEFAULT_PICKER_DATE, subdomain = "" } = values
			return { startDate, endDate, subdomain }
		},
		validate: ({ startDate, endDate, subdomain } = {}) => {
			const errors = {}
			if (startDate && endDate && moment(endDate).startOf("day") < moment(startDate).startOf("day")) {
				_set(errors, "startDate", getTranslatedValue("Start date should be earlier than Expiration date"))
				_set(errors, "endDate", getTranslatedValue("End date should be later than Start date"))
			}
			if (typeof subdomain == "undefined" || (!subdomain.length || subdomain.length > MAX_SUBDOMAIN_LENGTH || !reSubDomain.test(subdomain))) {
				_set(errors, "subdomain", getTranslatedValue("Subdomains can use characters a-z, A-Z, 0-9 and hyphen ( - ). It can be at most 63 characters in length"))
			}
			return errors
		},
		className: TSDialog,
		name: "Tenant Services",
		save(...args) {
			this.setState({ saveChangesInProgress: true })
			this.saveNewTSOffering(...args)
				.finally(() => this.setState({ requestDialogType: null, saveChangesInProgress: false }))
		},
		update: data => function (t, updatedValues) {
			this.setState({ saveChangesInProgress: true })
			this.saveNewTSOffering({ ...data, ...updatedValues })
				.finally(() => this.setState({ requestDialogType: null, saveChangesInProgress: false }))
		}
	}],
	[BPEPDialog, {
		title: "Request Activation",
		initialValues: values => {
			if (!values) {
				values = {}
			}
			const {
				offeringRequestId,
				contractStartDate = DEFAULT_PICKER_DATE,
				expirationDate,
				analyticsStartDate,
				contractPeriod,
				subscriptionLengthInDays = 365,
				subscriptions = [],
				locations = [],
				businessStream = "Service",
				serviceContractNumber = "",
				pactContractNumber = "",
				mechanicalServiceAgreementNumber = "",
				notes = "",
				purchaseOrderNumber = "",
				agreement = false,
				primaryContact = {
					username: ""
				},
				settings = {
					attachments: [],
					deviceCount: 0
				},
				denialReason = "",
				primaryLocation,
				locationWithSubscription = []
			} = values
			return {
				offeringRequestId,
				contractStartDate: moment(contractStartDate).format(DATE_FORMAT),
				analyticsStartDate, // not visible on dialog, just keep this value to be displayed on Approve dialog
				contractPeriod: contractPeriod || getContractPeriod(subscriptionLengthInDays),
				expirationDate: expirationDate || getExpirationDate(contractStartDate, subscriptionLengthInDays), // used in UI only, save "subscriptionLengthInDays" in API
				subscriptions,
				locations,
				agreement,
				serviceContractNumber,
				notes,
				pactContractNumber,
				mechanicalServiceAgreementNumber,
				purchaseOrderNumber,
				primaryContact,
				businessStream,
				settings: { ...settings },
				denialReason,
				primaryLocation,
				locationWithSubscription
			}
		},
		validate: ({ locations, subscriptions, businessStream, purchaseOrderNumber, serviceContractNumber, pactContractNumber, mechanicalServiceAgreementNumber, agreement, contractStartDate, expirationDate, contractPeriod, primaryContact, settings: { deviceCount } } = {}) => {
			const errors = {}

			if (!locations.length) {
				_set(errors, "locations", getTranslatedValue("Please choose at least one option"))
			}

			// Different businessStreams selected goes with different fields
			if (businessStream === "Independent") {
				if (!purchaseOrderNumber) {
					_set(errors, "purchaseOrderNumber", getTranslatedValue("Purchase order number is required"))
				} else if (purchaseOrderNumber.length > MAX_LENGTH_OF_NUMBER) {
					_set(errors, "purchaseOrderNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
				}
			}
			if (businessStream !== "Independent" && !(subscriptions?.some(sub => sub.name.includes("DI-CH")) && subscriptions.length == 1)) {
				if (!serviceContractNumber) {
					_set(errors, "serviceContractNumber", getTranslatedValue("Required"))
				} else if (serviceContractNumber.length > MAX_LENGTH_OF_NUMBER) {
					_set(errors, "serviceContractNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
				} /* else if (serviceContractNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(serviceContractNumber)) { // eslint-disable-line
					_set(errors, "serviceContractNumber", i18next.t("users:SpCharacterNotAllowed"))
				} */

				/* if (!crmSiteId) {
					_set(errors, "crmSiteId", getTranslatedValue("Required"))
				}  else if (crmSiteId.length > MAX_LENGTH_OF_CRM_SITE_ID_NUMBER) {
					_set(errors, "crmSiteId", getTranslatedValue(`Field shouldn’t contain more than ${number} symbols`) MAX_LENGTH_OF_CRM_SITE_ID_NUMBER }} />)
				} */
			}
			if (subscriptions?.some(sub => sub.name.includes("CAPxM&V"))) {
				if (!pactContractNumber) {
					_set(errors, "pactContractNumber", getTranslatedValue("Required"))
				} else if (pactContractNumber.length > MAX_LENGTH_OF_PACT_NUMBER) {
					_set(errors, "pactContractNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_PACT_NUMBER} symbols`))
				} /* else if (pactContractNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(pactContractNumber)) { // eslint-disable-line
					_set(errors, "pactContractNumber", i18next.t("users:SpCharacterNotAllowed"))
				} */
			}
			if (subscriptions?.some(sub => sub.name.includes("DI-CH")) && subscriptions.length == 1) {
				if (!mechanicalServiceAgreementNumber) {
					_set(errors, "mechanicalServiceAgreementNumber", getTranslatedValue("Required"))
				} else if (mechanicalServiceAgreementNumber.length > MAX_LENGTH_OF_MSA_NUMBER) {
					_set(errors, "mechanicalServiceAgreementNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_MSA_NUMBER} symbols`))
				} /* else if (mechanicalServiceAgreementNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(mechanicalServiceAgreementNumber)) { // eslint-disable-line
					_set(errors, "mechanicalServiceAgreementNumber", i18next.t("users:SpCharacterNotAllowed"))
				} */
			}

			if (!subscriptions.length) {
				_set(errors, "subscriptions", getTranslatedValue("Please choose at least one option"))
			}

			if (!contractPeriod) {
				_set(errors, "contractPeriod", getTranslatedValue("Contract Length is required"))
			}

			if (contractStartDate && getExpirationDateByContractPeriod(contractStartDate, contractPeriod, expirationDate) && moment(getExpirationDateByContractPeriod(contractStartDate, contractPeriod, expirationDate)).startOf("day") < moment(contractStartDate).startOf("day")) {
				_set(errors, "contractStartDate", getTranslatedValue("Start date should be earlier than Expiration date"))
			}

			if (!agreement) {
				_set(errors, "agreement", getTranslatedValue("Required"))
			}

			if (subscriptions?.some(sub => sub.name.includes("AM"))) {
				if (deviceCount < 1 || deviceCount > 99) {
					_set(errors, "settings.deviceCount", getTranslatedValue("Device Count should be a number from 1 to 99"))
				}
				if (!deviceCount) {
					_set(errors, "settings.deviceCount", getTranslatedValue("Required"))
				}
			}

			if (primaryContact?.username) {
				const emailRegEx = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(tranetechnologies|irco|trane|Boland|Damuth)\.(com)$/gmi) // eslint-disable-line
				if (!emailRegEx.test(primaryContact.username)) {
					_set(errors, "primaryContact", getTranslatedValue("This email address is not valid"))
				}
			}

			return errors
		},
		confirmButtonText: "Request Activation",
		className: BPEPDialog,
		name: "Building or Energy Performance",
		save(t, fields, setLoading, saveNewBPEPOffering, dialogTypes, setDialogTypes, mode) {
			const offeringFields = { ...fields, mode }
			setLoading(true)
			saveNewBPEPOffering(offeringFields)
				.then(response => {
					const errorReason = _get(response[0], "extensions.response.body.reason", "")[0] || _get(response[0], "extensions.response.body.validationErrors[0].reason", "")
					let locId, contractNo, activeSubscriptionList
					const custCRMIdRegex = /\bCRM Id\b/gi
					const duplicateContractNoRegex = /\bservice contract\b/gi
					if (custCRMIdRegex.test(errorReason)) {
						locId = errorReason.match(/(?<=location:).[0-9]+/gi)[0]
					} else if (duplicateContractNoRegex.test(errorReason)) {
						contractNo = errorReason.match(/(?<=number:).[0-9]+/gi)[0]
					} else if (errorReason) {
						const errorObj = errorReason.split("\n")
						activeSubscriptionList = errorObj.reduce((arr, el) => {
							const subScriptionName = el.match(/offeringSubscriptionName:(.*?)}/i)[1]
							const subScriptionId = el.match(/(?<=offeringSubscriptionId:).[0-9]+/gi)[0]
							const locationName = el.match(/locationName:(.*?)}/i)[1]
							const locationId = el.match(/(?<=locationId:).[0-9]+/gi)[0]
							if (subScriptionId !== "null") {
								const offeringObj = {
									subScriptionName,
									subScriptionId,
									locationId,
									locationName
								}
								arr.push(offeringObj)
							}
							return arr
						}, [])
						activeSubscriptionList = _compact(activeSubscriptionList)
					}
					if (!errorReason) {
						setDialogTypes({
							...dialogTypes,
							notification: {
								title: `${getTranslatedValue("Success")}!`,
								text: getTranslatedValue("Your request for activation has been sent to Trane Intelligent Services to verify the contract and bill your sales office. You will receive a notification when the request has been processed.")
							}
						})
					} else {
						const errorText = contractNo
							? getTranslatedValue(`Service contract number ${contractNo} already exists. Please update the service contract number in Step 1`)
							: locId ? getTranslatedValue(`CRM Site ID for ${locId} is already associated with another location`)
								: activeSubscriptionList.length > 0
									? (
										<>
											{getTranslatedValue("Duplicate active offering found for the following locations:")}
											<ul>
												{(activeSubscriptionList.map(subscription => subscription.locationName)).map((sub, i) => (<li key={`${sub}-${i}`}>{sub}</li>))}
											</ul>
											{getTranslatedValue("Please review the building and offering selections on Step 1.")}
										</>
									)
									: errorReason ? errorReason
										: getTranslatedValue("There is a problem saving this offering request.")

						setDialogTypes({
							...dialogTypes,
							error: {
								text: errorText,
								translateText: false
							}
						})
					}
				})
				.catch(error => setDialogTypes({
					...dialogTypes?.error,
					error: {
						text: error === NO_SALES_OFFICE_ERROR
							? getTranslatedValue("The offering request cannot be made in building without sales office")
							: getTranslatedValue("There is a problem saving this offering request."),
						translateText: false
					}
				}))
				.finally(() => {
					setTimeout(() => {
						setLoading(false)
					}, 0)
				})
		},
		edit(t, fields, setLoading, updateBPEPOffering, dialogTypes, setDialogTypes, mode) {
			const offeringFields = { ...fields, mode }
			setLoading(true)
			updateBPEPOffering(offeringFields)
				.then(response => {
					const errorReason = _get(response[0], "extensions.response.body.reason", "")[0] || _get(response[0], "extensions.response.body.validationErrors[0].reason", "")
					let locId, contractNo, activeSubscriptionList
					const custCRMIdRegex = /\bCRM Id\b/gi
					const duplicateContractNoRegex = /\bservice contract\b/gi
					if (custCRMIdRegex.test(errorReason)) {
						locId = errorReason.match(/(?<=location:).[0-9]+/gi)[0]
					} else if (duplicateContractNoRegex.test(errorReason)) {
						contractNo = errorReason.match(/(?<=number:).[0-9]+/gi)[0]
					} else if (errorReason) {
						const errorObj = errorReason.split("\n")
						activeSubscriptionList = errorObj.reduce((arr, el) => {
							const subScriptionName = el.match(/offeringSubscriptionName:(.*?)}/i)[1]
							const subScriptionId = el.match(/(?<=offeringSubscriptionId:).[0-9]+/gi)[0]
							const locationName = el.match(/locationName:(.*?)}/i)[1]
							const locationId = el.match(/(?<=locationId:).[0-9]+/gi)[0]
							if (subScriptionId !== "null") {
								const offeringObj = {
									subScriptionName,
									subScriptionId,
									locationId,
									locationName
								}
								arr.push(offeringObj)
							}
							return arr
						}, [])
						activeSubscriptionList = _compact(activeSubscriptionList)
					}

					if (!errorReason) {
						setDialogTypes({
							...dialogTypes,
							notification: {
								title: `${getTranslatedValue("Success")}!`,
								text: getTranslatedValue("Your request for activation has been sent to Trane Intelligent Services to verify the contract and bill your sales office. You will receive a notification when the request has been processed.")
							}
						})
					} else {
						const errorText = contractNo
							? getTranslatedValue(`Service contract number ${contractNo} already exists. Please update the service contract number in Step 1`)
							: locId ? getTranslatedValue(`CRM Site ID for ${locId} is already associated with another location`)
								: activeSubscriptionList.length > 0
									? (
										<>
											{getTranslatedValue("Duplicate active offering found for the following locations:")}
											<ul>
												{(activeSubscriptionList.map(subscription => subscription.locationName)).map((sub, i) => (<li key={`${sub}-${i}`}>{sub}</li>))}
											</ul>
											{getTranslatedValue("Please review the building and offering selections on Step 1.")}
										</>
									)
									: errorReason ? errorReason
										: getTranslatedValue("There is a problem saving this offering request.")

						setDialogTypes({
							...dialogTypes,
							error: {
								text: errorText,
								translateText: false
							}
						})
					}
				})
				.catch(error => setDialogTypes({
					...dialogTypes?.error,
					error: {
						text: error === NO_SALES_OFFICE_ERROR
							? getTranslatedValue("The offering request cannot be made in building without sales office")
							: `${getTranslatedValue("There is a problem updating this offering request.")} ${getTranslatedValue("Please wait a moment and try again. If the problem persists, please submit a trouble ticket from the Feedback/Support menu.")}`,
						translateText: false
					}
				}))
				.finally(() => {
					setTimeout(() => {
						setLoading(false)
					}, 0)
				})
		}
	}],
	[ActiveContractEditDialog, {
		title: "Request Activation",
		initialValues: values => {
			if (!values) {
				values = {}
			}
			const {
				offeringRequestId,
				contractStartDate = DEFAULT_PICKER_DATE,
				analyticsStartDate,
				subscriptionLengthInDays = 365,
				subscriptions = [],
				locations = [],
				businessStream = "Service",
				serviceContractNumber = "",
				pactContractNumber = "",
				mechanicalServiceAgreementNumber = "",
				notes = "",
				purchaseOrderNumber = "",
				primaryContact = {
					username: ""
				},
				settings = {
					attachments: [],
					deviceCount: 0,
					buildingCount: 0
				},
				denialReason = "",
				primaryLocation,
				locationWithSubscription = []
			} = values
			return {
				offeringRequestId,
				contractStartDate: moment(contractStartDate).format(DATE_FORMAT),
				analyticsStartDate, // not visible on dialog, just keep this value to be displayed on Approve dialog
				contractPeriod: getContractPeriod(subscriptionLengthInDays) || "custom",
				expirationDate: getExpirationDate(contractStartDate, subscriptionLengthInDays), // used in UI only, save "subscriptionLengthInDays" in API
				subscriptions,
				locations,
				serviceContractNumber,
				notes,
				pactContractNumber,
				mechanicalServiceAgreementNumber,
				purchaseOrderNumber,
				primaryContact,
				businessStream,
				settings: { ...settings },
				denialReason,
				primaryLocation,
				locationWithSubscription
			}
		},
		validate: ({ locations, subscriptions, businessStream, purchaseOrderNumber, serviceContractNumber, pactContractNumber, mechanicalServiceAgreementNumber, contractStartDate, expirationDate, contractPeriod, primaryContact, settings: { deviceCount, buildingCount }, primaryLocation } = {}) => {
			const errors = {}

			if (!locations.length) {
				_set(errors, "locations", getTranslatedValue("Please choose at least one option"))
			}

			if (!primaryLocation) {
				_set(errors, "primaryLocation", getTranslatedValue("Please select a new primary building"))
			}

			// Different businessStreams selected goes with different fields
			if (businessStream === "Independent") {
				if (!purchaseOrderNumber) {
					_set(errors, "purchaseOrderNumber", getTranslatedValue("Purchase order number is required"))
				} else if (purchaseOrderNumber.length > MAX_LENGTH_OF_NUMBER) {
					_set(errors, "purchaseOrderNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
				}
			}
			if (businessStream !== "Independent" && !(subscriptions?.some(sub => sub.name.includes("DI-CH")) && subscriptions.length == 1)) {
				if (!serviceContractNumber) {
					_set(errors, "serviceContractNumber", getTranslatedValue("Required"))
				} else if (serviceContractNumber.length > MAX_LENGTH_OF_NUMBER) {
					_set(errors, "serviceContractNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
				}

				/* if (!crmSiteId) {
					_set(errors, "crmSiteId", getTranslatedValue("Required"))
				}  else if (crmSiteId.length > MAX_LENGTH_OF_CRM_SITE_ID_NUMBER) {
					_set(errors, "crmSiteId", getTranslatedValue(`Field shouldn’t contain more than ${number} symbols`) MAX_LENGTH_OF_CRM_SITE_ID_NUMBER }} />)
				} */
			}
			if (subscriptions?.some(sub => sub.name.includes("CAPxM&V"))) {
				if (!pactContractNumber) {
					_set(errors, "pactContractNumber", getTranslatedValue("Required"))
				} else if (pactContractNumber.length > MAX_LENGTH_OF_PACT_NUMBER) {
					_set(errors, "pactContractNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_PACT_NUMBER} symbols`))
				}
			}
			if (subscriptions?.some(sub => sub.name.includes("DI-CH")) && subscriptions.length == 1) {
				if (!mechanicalServiceAgreementNumber) {
					_set(errors, "mechanicalServiceAgreementNumber", getTranslatedValue("Required"))
				} else if (mechanicalServiceAgreementNumber.length > MAX_LENGTH_OF_MSA_NUMBER) {
					_set(errors, "mechanicalServiceAgreementNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_MSA_NUMBER} symbols`))
				}
			}

			if (!subscriptions.length) {
				_set(errors, "subscriptions", getTranslatedValue("Please choose at least one option"))
			}

			if (!contractPeriod) {
				_set(errors, "contractPeriod", getTranslatedValue("Contract Length is required"))
			}

			if (contractStartDate && getExpirationDateByContractPeriod(contractStartDate, contractPeriod, expirationDate) && moment(getExpirationDateByContractPeriod(contractStartDate, contractPeriod, expirationDate)).startOf("day") < moment(contractStartDate).startOf("day")) {
				_set(errors, "contractStartDate", getTranslatedValue("Start date should be earlier than Expiration date"))
			}

			if (subscriptions?.some(sub => sub.name.includes("AM"))) {
				if (deviceCount < 1 || deviceCount > 99) {
					_set(errors, "settings.deviceCount", getTranslatedValue("Device Count should be a number from 1 to 99"))
				}
				if (!deviceCount) {
					_set(errors, "settings.deviceCount", getTranslatedValue("Required"))
				}
			}

			if (!buildingCount) {
				_set(errors, "settings.buildingCount", getTranslatedValue("Required"))
			}

			if (primaryContact?.username) {
				const emailRegEx = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(tranetechnologies|irco|trane|Boland|Damuth)\.(com)$/gmi) // eslint-disable-line
				if (!emailRegEx.test(primaryContact.username)) {
					_set(errors, "primaryContact", getTranslatedValue("This email address is not valid"))
				}
			}
			return errors
		},
		confirmButtonText: "Request Activation",
		className: ActiveContractEditDialog,
		name: "Building or Energy Performance",
		save(t, fields, setLoading, saveNewBPEPOffering, dialogTypes, setDialogTypes, mode) {
			const offeringFields = { ...fields, mode }
			setLoading(true)
			saveNewBPEPOffering(offeringFields)
				.then(response => {
					const errorReason = _get(response[0], "extensions.response.body.reason", "")[0] || _get(response[0], "extensions.response.body.validationErrors[0].reason", "")
					let locId, contractNo, activeSubscriptionList
					const custCRMIdRegex = /\bCRM Id\b/gi
					const duplicateContractNoRegex = /\bservice contract\b/gi
					if (custCRMIdRegex.test(errorReason)) {
						locId = errorReason.match(/(?<=location:).[0-9]+/gi)[0]
					} else if (duplicateContractNoRegex.test(errorReason)) {
						contractNo = errorReason.match(/(?<=number:).[0-9]+/gi)[0]
					} else if (errorReason) {
						const errorObj = errorReason.split("\n")
						activeSubscriptionList = errorObj.reduce((arr, el) => {
							const subScriptionName = el.match(/offeringSubscriptionName:(.*?)}/i)[1]
							const subScriptionId = el.match(/(?<=offeringSubscriptionId:).[0-9]+/gi)[0]
							const locationName = el.match(/locationName:(.*?)}/i)[1]
							const locationId = el.match(/(?<=locationId:).[0-9]+/gi)[0]
							if (subScriptionId !== "null") {
								const offeringObj = {
									subScriptionName,
									subScriptionId,
									locationId,
									locationName
								}
								arr.push(offeringObj)
							}
							return arr
						}, [])
						activeSubscriptionList = _compact(activeSubscriptionList)
					}
					if (!errorReason) {
						setDialogTypes({
							...dialogTypes,
							notification: {
								title: `${getTranslatedValue("Success")}!`,
								text: getTranslatedValue("Your request for activation has been sent to Trane Intelligent Services to verify the contract and bill your sales office. You will receive a notification when the request has been processed.")
							}
						})
					} else {
						const errorText = contractNo
							? getTranslatedValue(`Service contract number ${contractNo} already exists. Please update the service contract number in Step 1`)
							: locId ? getTranslatedValue(`CRM Site ID for ${locId} is already associated with another location`)
								: activeSubscriptionList.length > 0
									? (
										<>
											{getTranslatedValue("Duplicate active offering found for the following locations:")}
											<ul>
												{(activeSubscriptionList.map(subscription => subscription.locationName)).map((sub, i) => (<li key={`${sub}-${i}`}>{sub}</li>))}
											</ul>
											{getTranslatedValue("Please review the building and offering selections on Step 1.")}
										</>
									)
									: errorReason ? errorReason
										: getTranslatedValue("There is a problem saving this offering request.")

						setDialogTypes({
							...dialogTypes,
							error: {
								text: errorText,
								translateText: false
							}
						})
					}
				})
				.catch(error => setDialogTypes({
					...dialogTypes?.error,
					error: {
						text: error === NO_SALES_OFFICE_ERROR
							? getTranslatedValue("The offering request cannot be made in building without sales office")
							: getTranslatedValue("There is a problem saving this offering request."),
						translateText: false
					}
				}))
				.finally(() => {
					setTimeout(() => {
						setLoading(false)
					}, 0)
				})
		},
		edit(t, fields, setLoading, updateBPEPOffering, dialogTypes, setDialogTypes, mode, wholeContractCancel) {
			const offeringFields = { ...fields, mode }
			setLoading(true)
			updateBPEPOffering(offeringFields)
				.then(response => {
					const { locations, mechanicalServiceAgreementNumber, purchaseOrderNumber, serviceContractNumber, primaryLocation } = response
					const primaryLocationName = (_find(locations, location => location.locationId == primaryLocation))?.locationName
					const errorReason = _get(response[0], "extensions.response.body.reason", "")[0] || _get(response[0], "extensions.response.body.validationErrors[0].reason", "")
					let locId, contractNo, activeSubscriptionList
					const custCRMIdRegex = /\bCRM Id\b/gi
					const duplicateContractNoRegex = /\bservice contract\b/gi
					if (custCRMIdRegex.test(errorReason)) {
						locId = errorReason.match(/(?<=location:).[0-9]+/gi)[0]
					} else if (duplicateContractNoRegex.test(errorReason)) {
						contractNo = errorReason.match(/(?<=number:).[0-9]+/gi)[0]
					} else if (errorReason) {
						const errorObj = errorReason.split("\n")
						activeSubscriptionList = errorObj.reduce((arr, el) => {
							const subScriptionName = el.match(/offeringSubscriptionName:(.*?)}/i)[1]
							const subScriptionId = el.match(/(?<=offeringSubscriptionId:).[0-9]+/gi)[0]
							const locationName = el.match(/locationName:(.*?)}/i)[1]
							const locationId = el.match(/(?<=locationId:).[0-9]+/gi)[0]
							if (subScriptionId !== "null") {
								const offeringObj = {
									subScriptionName,
									subScriptionId,
									locationId,
									locationName
								}
								arr.push(offeringObj)
							}
							return arr
						}, [])
						activeSubscriptionList = _compact(activeSubscriptionList)
					}

					if (!errorReason) {
						if (wholeContractCancel) {
							setDialogTypes({
								...dialogTypes,
								notification: {
									title: "Cancelation Confirmation",
									text: `Contract ${serviceContractNumber} : has been canceled!`
								}
							})
						} else {
							setDialogTypes({
								...dialogTypes,
								notification: {
									title: `${getTranslatedValue("Success")}!`,
									text: "Your edits have been saved and the contract request has been updated!"
								}
							})
						}
						trackClickActiveEditContractSubmit({
							locationName: locations.map(({ locationName }) => locationName),
							purchaseOrderNumber,
							mechanicalServiceAgreementNumber,
							serviceContractNumber,
							primaryLocation: primaryLocationName
						})
					} else {
						const errorText = contractNo
							? getTranslatedValue(`Service contract number ${contractNo} already exists. Please update the service contract number in Step 1`)
							: locId ? getTranslatedValue(`CRM Site ID for ${locId} is already associated with another location`)
								: activeSubscriptionList.length > 0
									? (
										<>
											{getTranslatedValue("Duplicate active offering found for the following locations:")}
											<ul>
												{(activeSubscriptionList.map(subscription => subscription.locationName)).map((sub, i) => (<li key={`${sub}-${i}`}>{sub}</li>))}
											</ul>
											{getTranslatedValue("Please review the building and offering selections on Step 1.")}
										</>
									)
									: errorReason ? errorReason
										: getTranslatedValue("There is a problem saving this offering request.")

						setDialogTypes({
							...dialogTypes,
							error: {
								text: errorText,
								translateText: false
							}
						})
					}
				})
				.catch(error => setDialogTypes({
					...dialogTypes?.error,
					error: {
						text: error === NO_SALES_OFFICE_ERROR
							? getTranslatedValue("The offering request cannot be made in building without sales office")
							: `${getTranslatedValue("There is a problem updating this offering request.")} ${getTranslatedValue("Please wait a moment and try again. If the problem persists, please submit a trouble ticket from the Feedback/Support menu.")}`,
						translateText: false
					}
				}))
				.finally(() => {
					setTimeout(() => {
						setLoading(false)
					}, 0)
				})
		}
	}],
	[CRMSiteForm, {
		initialValues: values => {
			if (!values) {
				values = {}
			}
			const { locations, primaryLocation = null } = values
			const crmSiteList = locations.map(loc => ({ customerCRMSiteId: loc.customerCRMSiteId }))
			return {
				locations,
				primaryLocation,
				crmSiteList
			}
		},
		validate: ({ locations, primaryLocation } = {}) => {
			const errors = {}

			// Update the these validations later
			if (!primaryLocation) {
				_set(errors, "primaryLocation", getTranslatedValue("Please choose at least one option"))
			}

			if (!locations) {
				_set(errors, "locations", getTranslatedValue("Please choose at least one option"))
			} else if (locations) {
				locations.map((x, index) => {
					if ((x.customerCRMSiteId) == null || (x.customerCRMSiteId) == "") {
						_set(errors, `crmSiteList.${index}.customerCRMSiteId`, getTranslatedValue("Required"))
					} else if ((x.customerCRMSiteId).length > MAX_LENGTH_OF_CRM_SITE_ID_NUMBER) {
						_set(errors, `crmSiteList.${index}.customerCRMSiteId`, getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_CRM_SITE_ID_NUMBER} symbols`))
					}
				})
			}

			return errors
		}
	}],
	[OEDialog, {
		title: "Request Optics Evaluation",
		initialValues: values => {
			if (!values) {
				values = {}
			}
			const {
				evaluationType = "",
				reason = "",
				businessStream = "Service",
				paymentType = "ServiceCallNumber",
				neededByDate = moment().add(7, "days").format(DATE_FORMAT),
				locations = [],
				serviceCallNumber = "",
				serviceContractNumber = "",
				purchaseOrderNumber = "",
				agreement = false,
				primaryContact = {
					username: ""
				},
				settings = {
					attachments: []
				}
			} = values
			return {
				evaluationType,
				reason,
				businessStream,
				paymentType,
				neededByDate: moment(neededByDate).format(DATE_FORMAT),
				serviceCallNumber,
				serviceContractNumber,
				purchaseOrderNumber,
				locations,
				agreement,
				primaryContact,
				settings
			}
		},
		validate: ({ evaluationType, reason, businessStream, paymentType, neededByDate, serviceCallNumber, serviceContractNumber, purchaseOrderNumber, agreement, primaryContact } = {}) => {
			const errors = {}

			if (!serviceContractNumber && paymentType === "ServiceContractNumber") {
				_set(errors, "serviceContractNumber", getTranslatedValue("Service contract number is required"))
			} else if (serviceContractNumber.length > MAX_LENGTH_OF_NUMBER) {
				_set(errors, "serviceContractNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
			}

			if (!purchaseOrderNumber && paymentType === "PurchaseOrderNumber") {
				_set(errors, "purchaseOrderNumber", getTranslatedValue("Purchase order number is required"))
			} else if (purchaseOrderNumber.length > MAX_LENGTH_OF_NUMBER) {
				_set(errors, "purchaseOrderNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
			}

			if (!serviceCallNumber && paymentType === "ServiceCallNumber") {
				_set(errors, "serviceCallNumber", getTranslatedValue("Service call number is required"))
			} else if (purchaseOrderNumber.length > MAX_LENGTH_OF_NUMBER) {
				_set(errors, "serviceCallNumber", getTranslatedValue(`Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`))
			}

			if (!reason) {
				_set(errors, "reason", getTranslatedValue("Reason is required"))
			}

			if (!evaluationType) {
				_set(errors, "evaluationType", getTranslatedValue("Evaluation type"))
			}

			if (!businessStream) {
				_set(errors, "businessStream", getTranslatedValue("Business stream is required"))
			}

			if (!agreement) {
				_set(errors, "agreement", true)
			}

			if (!primaryContact.username) {
				_set(errors, "primaryContact", getTranslatedValue("Primary contact is required"))
			}

			if (neededByDate && moment(neededByDate).isBefore(moment().add(7, "days"), "day")) {
				_set(errors, "neededByDate", `${getTranslatedValue("Needed by should be bigger then")} ${moment().add(7, "days").format("MM/DD/YYYY")}`)
			}

			return errors
		},
		confirmButtonText: "Request Activation",
		className: OEDialog,
		name: "Optics Evaluations",
		save(t, fields) {
			this.setState({ saveChangesInProgress: true })
			this.saveNewOpticOffering(fields)
				.then(() => {
					this.setState({
						notification: {
							title: `${getTranslatedValue("Success")}!`,
							text: getTranslatedValue("Your request for activation has been sent to Trane Intelligent Services to verify the contract and bill your sales office. You will receive a notification when the request has been processed.")
						}
					})
				})
				.catch(error => {
					this.setState({ error: { ...error, text: getTranslatedValue("There is a problem saving this offering request.") } })
				})
				.finally(() => this.setState({
					requestDialogType: null,
					saveChangesInProgress: false,
				}))
		},
		edit(t, fields) {
			this.setState({ saveChangesInProgress: true })
			this.updateOpticOffering(fields)
				.then(() => {
					this.setState({
						notification: {
							title: `${getTranslatedValue("Success")}!`,
							text: getTranslatedValue("Your request for activation has been sent to Trane Intelligent Services to verify the contract and bill your sales office. You will receive a notification when the request has been processed.")
						}
					})
				})
				.catch(error => {
					this.setState({ error: { ...error, text: `${getTranslatedValue("There is a problem updating this offering request.")} ${getTranslatedValue("Please wait a moment and try again. If the problem persists, please submit a trouble ticket from the Feedback/Support menu.")}` } })
				})
				.finally(() => this.setState({
					requestDialogType: null,
					saveChangesInProgress: false,
				}))
		}
	}],
	[BPEPApproveDialog, {
		title: "Request Offering Activation",
		initialValues: values => {
			if (!values) {
				values = {}
			}

			const {
				offeringRequestId,
				// contractStartDate = DEFAULT_DATE,
				subscriptionLengthInDays = 365,
				subscriptions = [],
				locations = [],
				businessStream = "Service",
				contractPeriod = "",
				serviceContractNumber = "",
				purchaseOrderNumber = "",
				pactContractNumber = "",
				mechanicalServiceAgreementNumber = "",
				notes = "",
				agreement = false,
				primaryContact = {
					username: ""
				},
				settings = {
					attachments: [],
					deviceCount: 0,
					buildingCount: 0
				},
				denialReason = "",
				primaryLocation
			} = values

			return {
				offeringRequestId,
				contractStartDate: moment(contractStartDate).format(DATE_FORMAT),
				analyticsStartDate: moment(contractStartDate).format(DATE_FORMAT),
				contractPeriod: CONTRACT_PERIODS.find(({ days }) => days === subscriptionLengthInDays)?.key || contractPeriod,
				get expirationDate() {
					return getExpirationDate(contractStartDate, subscriptionLengthInDays)
				},
				subscriptions,
				locations,
				serviceContractNumber,
				purchaseOrderNumber,
				pactContractNumber,
				mechanicalServiceAgreementNumber,
				notes,
				agreement,
				primaryContact,
				businessStream,
				settings: { ...settings },
				denialReason,
				primaryLocation
			}
		},
		validate: AcceptDeclineValidate,
		confirmButtonText: "Activate",
		declineButtonText: "Decline",
		className: BPEPApproveDialog,
		name: "Building or Energy Performance",
		activate(t, fields) {
			this.setState({ saveChangesInProgress: true })
			this.approveBPEPOffering(fields)
				.then(data => {
					this.setState({
						notification: {
							title: `${getTranslatedValue("Success")}!`,
							text: `${getTranslatedValue("Contract number {{contractNumber}} has been activated. Analytics will begin running on ")} ${moment(data.analyticsStartDate).format(DATE_FORMAT)} \n \n ${getTranslatedValue("A message has been sent to the requestor.")}`,
						}
					})
					trackClickActivateOfferings({
						offeringName: data.subscriptions.map(({ name }) => name),
						businessStream: data.businessStream,
						buildingCount: data?.settings?.buildingCount,
						deviceCount: data?.settings?.deviceCount,
					})
				})
				.catch(error => {
					this.setState({
						error: {
							...error,
							text: error === NO_SALES_OFFICE_ERROR
								? getTranslatedValue("The offering request cannot be made in building without sales office")
								: `${getTranslatedValue("There is a problem approving this offering request.")} ${getTranslatedValue("Please wait a moment and try again. If the problem persists, please submit a trouble ticket from the Feedback/Support menu.")}`
						}
					})
				})
				.finally(() => this.setState({
					requestDialogType: null,
					saveChangesInProgress: false,
				}))
		},
		decline(t, fields) {
			this.setState({ saveChangesInProgress: true })
			this.declineBPEPOffering(fields)
				.then(data => {
					this.setState({
						notification: {
							title: `${getTranslatedValue("Success")}!`,
							text: getTranslatedValue("The activation request has been declined and sent back to the requester for revision.")
						}
					})
					trackClickRequestDeclined({
						offeringName: data.subscriptions.map(({ name }) => name),
						businessStream: data.businessStream,
						buildingCount: (data?.locations || []).length,
						deviceCount: data?.settings?.deviceCount,
					})
				})
				.catch(error => {
					this.setState({ error: { ...error, text: `${getTranslatedValue("There is a problem declining this offering request")} ${getTranslatedValue("Please wait a moment and try again. If the problem persists, please submit a trouble ticket from the Feedback/Support menu.")}` } })
				})
				.finally(() => this.setState({
					requestDialogType: null,
					saveChangesInProgress: false,
				}))
		},
	}]
])

export const emailContext = new Map([
	[OFFERING_REQUEST_CREATED, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_CREATED,
				// recipientEmail: getConfig("tisEmail"),
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					// traneSiteID: this.crmSiteId,
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: `${this.actionLinkUrl.replace("/add", "")}&offeringRequestId=${this.offeringRequestId}&dialogType=${REVIEW}`,
					userName: this.currentUserName,
					salesOfficeName: `${this.locationInfo?.salesOffice?.officeCode}, ${this.locationInfo?.salesOffice?.officeName}, ${this.locationInfo?.salesOffice?.district}`,
					attachments: this.settings.attachments || [],
					notes: this.notes,
					jsonData: getJSONData({
						locations: this.locations,
						primaryLocation: this.primaryLocation,
						contractStartDate: moment(this.contractStartDate).format(DATE_FORMAT),
						contractEndDate: moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT),
						businessStream: this.businessStream,
						serviceAgreementNumber: this.serviceContractNumber,
						purchaseOrderNumber: this.purchaseOrderNumber,
						pactContractNumber: this.pactContractNumber,
						mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
						officeCode: this.locationInfo?.salesOffice?.officeCode,
						subscriptions: this.subscriptions,
						primaryContact: this.primaryContact.username,
						bcuScCount: this.settings?.deviceCount || 0,
					}),
				}
			}
		}
	}],
	[OFFERING_REQUEST_CREATED_UI_NOTIFICATION_ONLY, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_CREATED_UI_NOTIFICATION_ONLY,
				recipientEmail: this.requestedBy.username,
				templateVars: {
					actionLinkUrl: `${this.actionLinkUrl.replace(/add/i, this.offeringRequestId)}&dialogType=${REQUEST}`
				}
			}
		}
	}],
	[OFFERING_REQUEST_UPDATED, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_UPDATED,
				// recipientEmail: getConfig("tisEmail"),
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					// traneSiteID: this.crmSiteId,
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: `${this.actionLinkUrl.replace(`/${this.offeringRequestId}`, "")}&offeringRequestId=${this.offeringRequestId}&dialogType=${REVIEW}`,
					userName: this.currentUserName,
					salesOfficeName: `${this.locationInfo?.salesOffice?.officeCode}, ${this.locationInfo?.salesOffice?.officeName}, ${this.locationInfo?.salesOffice?.district}`,
					attachments: this.settings.attachments || [],
					notes: this.notes,
					jsonData: getJSONData({
						locations: this.locations,
						primaryLocation: this.primaryLocation,
						contractStartDate: moment(this.contractStartDate).format(DATE_FORMAT),
						contractEndDate: moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT),
						businessStream: this.businessStream,
						serviceAgreementNumber: this.serviceContractNumber,
						purchaseOrderNumber: this.purchaseOrderNumber,
						pactContractNumber: this.pactContractNumber,
						mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
						officeCode: this.locationInfo?.salesOffice?.officeCode,
						subscriptions: this.subscriptions,
						primaryContact: this.primaryContact.username,
						bcuScCount: this.settings?.deviceCount || 0,
					}),
				}
			}
		}
	}],
	[OFFERING_REQUEST_UPDATED_UI_NOTIFICATION_ONLY, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_UPDATED_UI_NOTIFICATION_ONLY,
				recipientEmail: this.requestedBy.username,
				templateVars: {
					actionLinkUrl: `${this.actionLinkUrl}&dialogType=${REQUEST}`
				}
			}
		}
	}],
	[OFFERING_REQUEST_DELETED, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_DELETED,
				// recipientEmail: getConfig("tisEmail"),
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					// traneSiteID: this.crmSiteId,
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: this.actionLinkUrl,
					userName: this.currentUserName,
					notes: this.notes,
					salesOfficeName: `${this.locationInfo?.salesOffice?.officeCode}, ${this.locationInfo?.salesOffice?.officeName}, ${this.locationInfo?.salesOffice?.district}`,
					jsonData: getJSONData({
						locations: this.locations,
						primaryLocation: this.primaryLocation,
						contractStartDate: moment(this.contractStartDate).format(DATE_FORMAT),
						contractEndDate: moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT),
						businessStream: this.businessStream,
						serviceAgreementNumber: this.serviceContractNumber,
						purchaseOrderNumber: this.purchaseOrderNumber,
						pactContractNumber: this.pactContractNumber,
						mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
						officeCode: this.locationInfo?.salesOffice?.officeCode,
						subscriptions: this.subscriptions,
						primaryContact: this.primaryContact.username,
						bcuScCount: this.settings?.deviceCount || 0,
					}),
				}
			}
		}
	}],
	[OFFERING_REQUEST_ACTIVATED, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_ACTIVATED,
				recipientEmail: this.isContactSame ? this.requestedBy.username : this.primaryContact.username,
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					// traneSiteID: this.crmSiteId,
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: this.actionLinkUrl,
					userName: this.currentUserName,
					attachments: this.settings.attachments || [],
					notes: this.notes
				}
			}
		}
	}],
	[OFFERING_REQUEST_ACTIVE_UPDATE, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_ACTIVE_UPDATE,
				recipientEmail: (this.isContactSame || !this.primaryContact.userName) ? this.requestedBy.username : this.primaryContact.username,
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: this.actionLinkUrl,
					userName: this.currentUserName,
					attachments: this.settings.attachments || [],
					notes: this.notes
				}
			}
		}
	}],
	[OFFERING_REQUEST_CANCELED, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_CANCELED,
				recipientEmail: (this.isContactSame || !this.primaryContact.userName) ? this.requestedBy.username : this.primaryContact.username,
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: this.actionLinkUrl,
					userName: this.currentUserName,
					attachments: this.settings.attachments || [],
					notes: this.notes
				}
			}
		}
	}],
	[OFFERING_REQUEST_DECLINED, {
		body() {
			return {
				messageDefinitionKey: OFFERING_REQUEST_DECLINED,
				recipientEmail: this.isContactSame ? this.requestedBy.username : this.primaryContact.username,
				templateVars: {
					// isBeta: isBeta(),
					organizationName: this.organizationName,
					buildingNames: this.locations.map(b => b.locationName).join(", "),
					// traneSiteID: this.crmSiteId,
					offeringNames: this.subscriptions.map(b => b.name).join(", "),
					businessStream: this.businessStream,
					contractNumber: this.serviceContractNumber,
					mechanicalServiceAgreementNumber: this.mechanicalServiceAgreementNumber,
					notes: this.notes,
					subscriptionDates: `${moment(this.contractStartDate).format(DATE_FORMAT)} - ${moment(this.contractStartDate).add(this.subscriptionLengthInDays, "days").format(DATE_FORMAT)}`,
					purchaseOrderNumber: this.purchaseOrderNumber,
					actionLinkUrl: `${this.actionLinkUrl.replace("?", `/${this.offeringRequestId}?`)}&dialogType=${REQUEST}`,
					userName: this.currentUserName,
					denialReason: this.denialReason
				}
			}
		}
	}],
])

export const EVALUATION_TYPES = [
	{ key: "energyCheckUp", value: getTranslatedValue("Energy Check Up") },
	{ key: "opticsEnergyVisualizer", value: getTranslatedValue("Optics Energy Visualizer") },
	{ key: "opticsBMSVisualizer", value: getTranslatedValue("Optics BMS Visualizer") },
	{ key: "texasEnergyCoaching", value: getTranslatedValue("Texas Energy Coaching") },
	{ key: "GNPOpticsToEP", value: getTranslatedValue("GNP Optics to EP") },
	{ key: "mid-AmericaEnergyCoaching", value: getTranslatedValue("Mid-America Energy Coaching") },
	{ key: "nyserdaRemQa", value: getTranslatedValue("NYSERDA REM QA") },
	{ key: "nyserdaRemContract", value: getTranslatedValue("NYSERDA REM Contract") },
	{ key: "customOrderRequest", value: getTranslatedValue("Custom Order Request") },
]

export const REASONS = [
	{ key: "existingServiceContract", value: getTranslatedValue("Existing service contract") },
	{ key: "newCustomerAcquisition", value: getTranslatedValue("New customer acquisition") },
	{ key: "projectDevelopment/ECMIdentification", value: getTranslatedValue("Project development/ECM identification") },
	{ key: "M&VForPACTJob", value: getTranslatedValue("M&M&V for PACT job") },
	{ key: "M&VForNon-performanceGuaranteeJob", value: getTranslatedValue("M&VForNon-M&V for non-performance guarantee job") },
	{ key: "intelligentServicesProofOfConcept", value: getTranslatedValue("Intelligent services proof of concept") },
]

export const PAYMENT_TYPES = [
	{ key: "ServiceCallNumber", value: getTranslatedValue("Service Call Number") },
	{ key: "ServiceContractNumber", value: getTranslatedValue("Service Contract Number") },
	{ key: "PurchaseOrderNumber", value: getTranslatedValue("Purchase Order Number") },
]

export const BUSINESS_STREAM = [
	{ key: "Service", label: getTranslatedValue("Service") },
	{ key: "Contracting", label: getTranslatedValue("Contracting") },
	{ key: "Equipment", label: getTranslatedValue("Equipment") },
	{ key: "Independent", label: getTranslatedValue("Independent Office") },
]


const BUSINESS_STREAM_EMAIL = new Map([
	["Service", "SV"],
	["Contracting", "CT"],
	["Equipment", "EQ"],
	["Independent", "Independent Office"],
])

const replaceableChars = {
	"2h": "2 Hour",
	"1hr": "1 Hour",
	"min": " Minutes",
	"/": "&"
}

export const Subscription = {
	"CAP TestDrive": "CAP Test Drive",
	"CAPxPRO": "CAP x PRO",
	"CAPxM&V": "CAP M&V",
	"CAP Data": "CAP x Data",
	"DI-CH": "Digital Inspections - Chiller",
	"Baseline Report": "Baseline Report",
	"Coaching Services": "Coaching Services",
	"BP Test Drive": "BP Test Drive",
	"BP Sustain": "BP Sustain",
	"BP x2": "BP x2",
	"BP x4": "BP x4",
	"BP ISO": "BP ISO",
	"BP x Data": "BP x Data",
	"BP Pro Support": "BP Pro Support",
	"BP M&V": "BP M&V",
	"EP x Data": "EP x Data",
	"EP/BP x2": "EP/BP x2",
	"EP/BP x4": "EP/BP x4",
	"EP ISO": "EP ISO",
	"AM DX 30min": "AM DX 30min",
	"AM DX 1hr": "AM DX 1hr",
	"AM DX 2h": "AM DX 2h",
	"AM Chiller 30min": "AM Chiller 30min",
	"AM Chiller 1hr": "AM Chiller 1hr",
	"AM Chiller 2h": "AM Chiller 2h",
	"AM DX/Chiller 30min": "AM DX/Chiller 30min",
	"AM DX/Chiller 1hr": "AM DX/Chiller 1hr",
	"AM DX/Chiller 2h": "AM DX/Chiller 2h",
	"AM On Demand 2h": "AM On Demand 2h",
	"AM On Demand 30min": "AM On Demand 30min",
	"AM IVR/On Demand 2h": "AM IVR/On Demand 2h",
	"AM IVR/On Demand 30min": "AM IVR/On Demand 30min"
}

export const SubscriptionFull = {
	"CAP TestDrive": "CAP Test Drive",
	"CAPxPRO": "CAP x PRO",
	"CAPxM&V": "CAP M&V",
	"CAP Data": "CAP x Data",
	"DI-CH": "Digital Inspections - Chiller",
	"Baseline Report": "Baseline Report",
	"Coaching Services": "Coaching Services",
	"BP Test Drive": "BP Test Drive",
	"BP Sustain": "Building Performance Sustain",
	"BP x2": "Building Performance x2",
	"BP x4": "Building Performance x4",
	"BP ISO": "Building Performance ISO",
	"BP x Data": "Building Performance x Data",
	"BP Pro Support": "Building Performance Pro Support",
	"BP M&V": "Building Performance M&V",
	"EP x Data": "Energy Performance x Data",
	"EP/BP x2": "Energy Performance/Building Performance x2",
	"EP/BP x4": "Energy Performance/Building Performance x4",
	"EP ISO": "Energy Performance ISO",
	"AM DX 30min": "Active Monitoring DX 30min",
	"AM DX 1hr": "Active Monitoring DX 1hr",
	"AM DX 2h": "Active Monitoring DX 2h",
	"AM Chiller 30min": "Active Monitoring Chiller 30min",
	"AM Chiller 1hr": "Active Monitoring Chiller 1hr",
	"AM Chiller 2h": "Active Monitoring Chiller 2h",
	"AM DX/Chiller 30min": "Active Monitoring DX/Chiller 30min",
	"AM DX/Chiller 1hr": "Active Monitoring DX/Chiller 1hr",
	"AM DX/Chiller 2h": "Active Monitoring DX/Chiller 2h",
	"AM On Demand 2h": "Active Monitoring On Demand 2h",
	"AM On Demand 30min": "Active Monitoring On Demand 30min",
	"AM IVR/On Demand 2h": "Active Monitoring IVR/On Demand 2h",
	"AM IVR/On Demand 30min": "Active Monitoring IVR/On Demand 30min"
}

const getFormattedAMOfferings = name => {
	const offeringName = name.split(" ").reverse()
	offeringName.pop()
	return `AM * Standard * ${offeringName.join(" * ").replace(/2h|1hr|min|\//g, c => replaceableChars[c])}`
}

const getFormattedCAPOfferings = name => {
	const offeringName = subscriptionFull[name]
	if (name.includes("x")) {
		return `CAP * ${offeringName.split(" ").join("").replace("x", "X")}`
	} else {
		return `CAP * ${offeringName}`
	}
}

const getOfferingName = name => {
	if (name.includes("AM")) {
		return getFormattedAMOfferings(name)
	} else if (name.includes("DX")) {
		return name.toUpperCase().split(" ").join(" * ").replace("MIN", "M")
	} else if (name.includes("CAP")) {
		return getFormattedCAPOfferings(name)
	} else if (name.includes("DI-CH")) {
		return "CMSA"
	} else {
		return name.split(" ").join(" * ").replace("min", "M")
	}
}

const getBusinessStream = businessStream => BUSINESS_STREAM_EMAIL.get(businessStream)

const getJSONData = ({ locations, contractStartDate, contractEndDate, businessStream, serviceAgreementNumber, officeCode, subscriptions, primaryLocation, primaryContact, bcuScCount, purchaseOrderNumber, pactContractNumber, mechanicalServiceAgreementNumber }) => `{
	"primary_location": "${primaryLocation ?? ""}",
	"office_code": "${officeCode}",
	"business_stream": "${getBusinessStream(businessStream)}",
	"building_count": "${locations.length}",
	"bcu_sc_count": "${bcuScCount}",
	"service_contract_number": "${serviceAgreementNumber ?? ""}",
	"purchase_order_number": "${purchaseOrderNumber ?? ""}",
	"pact_contract_number": "${pactContractNumber ?? ""}",
	"mechanical_service_aggrement_number": "${mechanicalServiceAgreementNumber ?? ""}",
	"start_date": "${contractStartDate}",
	"expiration_date": "${contractEndDate}",
	"primary_contact": "${primaryContact ?? ""}",
	"offerings": [${subscriptions.map(({ name }) => `{\n\t\t"offering": "${getOfferingName(name)}"\n\t}`).join(",\n\t")}],
	"sites": [${locations.map(({ locationName, customerCRMSiteId, line1, city, country, regionCode, postalCode }) => `{\n\t\t"site_name": "${locationName ?? ""}", \n\t\t"site_crm_id": "${customerCRMSiteId ?? ""}", \n\t\t"site_address": "${line1 ?? ""}", \n\t\t"site_city": "${city ?? ""}", \n\t\t"site_state_id": "${regionCode ?? ""}", \n\t\t"site_state_zip": "${postalCode ?? ""}", \n\t\t"site_country": "${country ?? ""}"\n\t}`).join(",\n\t")}]
}`

const isPeriodDisabled = (subscription, currentPeriod) => {
	switch (subscription.name) {
		case "BP Test Drive":
			return currentPeriod !== "trial"
		default:
			return false
	}
}

export const filterContractPeriods = selectedSubscription => CONTRACT_PERIODS.map(({ key, label }) => ({
	key,
	label,
	disabled: selectedSubscription.length === 1 ? isPeriodDisabled(selectedSubscription[0], key) : false
}))

export const prepareBPEPOfferingRequestBody = ({
	offeringRequestId,
	businessStream,
	purchaseOrderNumber,
	serviceContractNumber,
	pactContractNumber,
	mechanicalServiceAgreementNumber,
	notes,
	contractStartDate,
	expirationDate,
	analyticsStartDate,
	contractPeriod,
	subscriptions,
	locations,
	primaryContact,
	settings,
	denialReason,
	primaryLocation,
	locationWithSubscription = [],
	cancelContract = false
}, activeOfferEdit = false) => ({
	activeOfferEdit,
	cancelContract,
	...(offeringRequestId ? { offeringRequestId } : null), // Create/Edit modes
	// type: CAP_OFFERING_TYPE,
	businessStream,
	...(businessStream === "Independent" ? {
		purchaseOrderNumber,
	} : {
		serviceContractNumber,
		// crmSiteId: Number(crmSiteId)
	}),
	...(subscriptions?.some(sub => sub.name.includes("CAPxM&V")) && { pactContractNumber }),
	...(subscriptions?.some(sub => sub.name.includes("DI-CH")) && { mechanicalServiceAgreementNumber }),
	// contractStartDate: moment(contractStartDate).format(BACKEND_DATE_FORMAT),
	// analyticsStartDate: moment(analyticsStartDate).format(BACKEND_DATE_FORMAT),
	subscriptionLengthInDays: getSubscriptionLengthInDaysByPeriod(contractPeriod, contractStartDate, expirationDate, activeOfferEdit),
	subscriptions: subscriptions.map(({ offeringSubscriptionId }) => ({ offeringSubscriptionId })),
	locations: locations.map(({ locationId, key, line1, city, country, regionCode, postalCode, customerCRMSiteId }) => ({ locationId: key || locationId, line1, city, country, regionCode, postalCode, customerCRMSiteId: Number(customerCRMSiteId) })),
	locationWithSubscription,
	// primaryContact: removeDeepKeys(cloneDeep(primaryContact), "__typename"),
	settings: {
		attachments: settings?.attachments?.map(({ fileId, fileName, fileDescription, fileType, fileSize, filePath, shouldBeDeleted }) => ({
			fileId, fileName, fileDescription, fileType, fileSize, filePath, shouldBeDeleted
		})),
		deviceCount: Number(settings?.deviceCount),
		buildingCount: Number(settings?.buildingCount)
	},
	invoiceAgreement: true,
	denialReason,
	primaryLocation,
	notes
})

export const prepareOpticOfferingRequestBody = ({
	offeringRequestId,
	evaluationType,
	reason,
	businessStream,
	paymentType,
	neededByDate,
	serviceCallNumber,
	serviceContractNumber,
	purchaseOrderNumber,
	primaryContact,
	locations,
	settings
}) => ({
	...(offeringRequestId ? { offeringRequestId } : null), // Create/Edit modes
	// type: OPTICS_OFFERING_TYPE,
	...(paymentType === "ServiceCallNumber" ? { serviceCallNumber } : paymentType === "ServiceContractNumber" ? { serviceContractNumber } : { purchaseOrderNumber }),
	invoiceAgreement: true,
	evaluationType,
	reason,
	paymentType,
	businessStream,
	// neededByDate: moment(neededByDate).format(BACKEND_DATE_FORMAT),
	primaryContact,
	settings: {
		attachments: settings?.attachments?.map(({ fileId, fileName, fileDescription, fileType, fileSize, filePath, shouldBeDeleted }) => ({
			fileId, fileName, fileDescription, fileType, fileSize, filePath, shouldBeDeleted: paymentType === "PurchaseOrderNumber" ? shouldBeDeleted : true
		})),
		deviceCount: settings.deviceCount
	},
	locations: locations.map(({ locationId, key, line1, city, country, regionCode, postalCode, customerCRMSiteId }) => ({ locationId: key || locationId, line1, city, country, regionCode, postalCode, customerCRMSiteId }))
})

export const dateInput = ({ disabled, disablePast, label, name, value, touched, setFieldValue, setFieldTouched, errors }) => (
	<div className={classNames({ "has-error": (touched && _get(errors, name, "")) })}>
		{label && <Label text={label} disabled={disabled} />}
		{/* <DateInput
			disabled={disabled}
			disablePast={disablePast}
			format={DATE_FORMAT}
			date={value ? getFormattedDate(value) : getFormattedDate(DEFAULT_DATE)}
			onChange={value => {
				setFieldTouched && setFieldTouched(name)
				setFieldValue(name, getFormattedDate(value))
			}}
			outerErrorMessage={touched && _get(errors, name, "")}
		/> */}
	</div>
)

dateInput.propTypes = {
	name: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	disabled: PropTypes.boolean,
	label: PropTypes.string,
	value: PropTypes.string,
	touched: PropTypes.boolean,
	setFieldTouched: PropTypes.func,
	dateDidChange: PropTypes.func,
	errors: PropTypes.object
}

export const subdomainInput = ({ label, name, value, touched, setFieldValue, setFieldTouched, errors }) => {
	// const ensembleServicesPrefix = getConfig("externalLinks.ensembleServicesPrefix")
	// const ensembleServicesDomain = getConfig("externalLinks.ensembleServicesDomain")
	const error = _get(errors, name, "")
	return <div className={classNames({ "has-error": (touched && error) })}>
		<Label text={label} />
		<div>
			{ensembleServicesPrefix}
			{/* <DebounceInput
				id={name}
				name={name}
				value={value}
				onChange={event => {
					setFieldTouched(name)
					setFieldValue(name, event.target.value)
				}}
			/> */}
			{ensembleServicesDomain}
		</div>
		{touched && error && <ErrorMessage errorMessage={error} />}
	</div>
}

subdomainInput.propTypes = {
	name: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	label: PropTypes.string,
	value: PropTypes.string,
	touched: PropTypes.boolean,
	setFieldTouched: PropTypes.func,
	errors: PropTypes.object
}

export const inputFieldValidation = e => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()

export const commonInput = ({ label, name, value, type, touched, setFieldValue, setFieldTouched, errors, isRequired = true, ...props }) => {
	const error = _get(errors, name, "")
	return (<div className={classNames({ "has-error": (touched && error) })}>
		<legend>{label}{isRequired ? "*" : ""}</legend>
		{/* <DebounceInput
			type={type}
			id={name}
			name={name}
			value={value}
			onChange={event => {
				setFieldTouched(name)
				setFieldValue(name, event.target.value)
				// TODO: it's not a good solution
				// and we should implement maxLength for type="number" in future
				if (type === "number" && event.target.value.length > props.maxLength) {
					event.target.value = event.target.value.slice(0, props.maxLength)
					setFieldValue(name, event.target.value)
				}
			}}
			{...props}
		/> */}
		{touched && error && <ErrorMessage errorMessage={error} />}
	</div>)
}

export const crmCommonInput = ({ index, label, name, value, key, type, touched, onChange, locations, setFieldValue, setFieldTouched, errors, isRequired = true, ...props }) => {
	const error = _get(errors, name, "")
	const isCRMSiteIdTouched = touched && touched.crmSiteList && touched.crmSiteList[index] && touched.crmSiteList[index].customerCRMSiteId
	return (<div className={classNames({ "has-error": (isCRMSiteIdTouched && error) })}>
		<legend>{label}{isRequired ? "*" : ""}</legend>
		{/* <DebounceInput
			type={type}
			id={name}
			name={name}
			value={value}
			onChange={event => onChange(setFieldTouched, setFieldValue, event, name, key, locations)}
			{...props}
		/> */}
		{isCRMSiteIdTouched && error && error !== "required" && <ErrorMessage errorMessage={error} />}
	</div>)
}

export const emailInput = ({ label, name, value, type, touched, setFieldValue, setFieldTouched, errors, isRequired = true, ...props }) => {
	const error = _get(errors, name, "")
	return (<div className={classNames({ "has-error": (touched && error) })}>
		<legend>{label}{isRequired ? "*" : ""}</legend>
		{/* <DebounceInput
			type={type}
			id={name}
			name={name}
			value={value}
			onChange={event => {
				setFieldTouched(name)
				setFieldValue(name, { username: event.target.value })
			}}
			{...props}
		/> */}
		{touched && error && <ErrorMessage errorMessage={error} />}
	</div>)
}

commonInput.propTypes = {
	name: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	label: PropTypes.string,
	value: PropTypes.string,
	type: PropTypes.string,
	touched: PropTypes.boolean,
	setFieldTouched: PropTypes.func,
	errors: PropTypes.object
}



const ErrorMessage = ({ errorMessage }) => (
	<div className="inline-error-message">
		{errorMessage}
	</div>
)

ErrorMessage.propTypes = {
	errorMessage: PropTypes.string.isRequired
}
