import { Flex, Input, Label, SelectField } from '@aws-amplify/ui-react'
import { useState } from 'react'

// Project imports
import translate, { TranslateComponent } from 'src/common/translations'

// Denali imports
import styles from 'src/denali-components/Form/form.module.scss'
import { SelectVisibility } from 'src/denali-components/Form/SelectVisibility'
import { IOpportunitySettingsCostSavings } from 'src/models/opportunity'

// Local imports
import { DenaliFormElement } from './DenaliFormElement'
import { DenaliFormElementError } from './DenaliFormElementErrors'

interface CostSavingsPaybackROIProps {
  values: IOpportunitySettingsCostSavings
  titleOptions?: { label: string; value: string }[]
  initialTitleValue?: string
  paybackFieldLabel?: string
  roiFieldLabel?: string
  paybackInputValue?: any
  paybackInputProperty: string
  roiInputValue?: any
  roiInputProperty: string
  paybackSelectValue?: any
  paybackSelectProperty?: string
  roiSelectValue?: any
  roiSelectProperty?: string
  icon?: any
  iconColor?: string
  paybackOptions?: { label: string; value: string }[]
  roiOptions?: { label: string; value: string }[]
  paybackFormula?: string
  roiFormula?: string
  paybackVisibility?: boolean
  paybackVisibilityProperty?: string
  roiVisibility?: boolean
  roiVisibilityProperty?: string
  previewBuilder?: (
    values: IOpportunitySettingsCostSavings,
    formula: string
  ) => React.ReactElement
}

export const CostSavingsPaybackROI = ({
  values,
  titleOptions,
  initialTitleValue,
  paybackFieldLabel = '',
  roiFieldLabel = '',
  paybackInputValue,
  paybackInputProperty,
  roiInputValue,
  roiInputProperty,
  paybackSelectValue,
  paybackSelectProperty,
  roiSelectValue,
  roiSelectProperty,
  icon,
  iconColor,
  paybackOptions,
  roiOptions,
  paybackFormula,
  roiFormula,
  paybackVisibility,
  paybackVisibilityProperty,
  roiVisibility,
  roiVisibilityProperty,
  previewBuilder
}: CostSavingsPaybackROIProps) => {
  const [titleValue, setTitleValue] = useState(initialTitleValue)
  const isSimplePayback = titleValue === 'Simple Payback'
  const fieldLabel = isSimplePayback ? paybackFieldLabel : roiFieldLabel
  const selectOptions = isSimplePayback ? paybackOptions : roiOptions
  const formula = isSimplePayback ? paybackFormula : roiFormula
  const inputValue = isSimplePayback ? paybackInputValue : roiInputValue
  const inputProperty = isSimplePayback
    ? paybackInputProperty
    : roiInputProperty
  const selectValue = isSimplePayback ? paybackSelectValue : roiSelectValue
  const selectProperty = isSimplePayback
    ? paybackSelectProperty
    : roiSelectProperty
  const visibilityValue = isSimplePayback ? paybackVisibility : roiVisibility
  const visibilityProperty = isSimplePayback
    ? paybackVisibilityProperty
    : roiVisibilityProperty

  const placeholderText = translate('Start typing here...')
  const placeholderSelect = translate('Select')

  return (
    <div className={styles.cardFinancial}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        width="100%"
      >
        <Flex alignItems="center" direction="row">
          <div className={styles.icon} data-color={iconColor}>
            {icon}
          </div>
          <DenaliFormElement id="title" name="title" customOnChange={true}>
            <SelectField
              label="title"
              labelHidden
              name="title"
              data-testid="title"
              value={titleValue}
              onChange={(e) => setTitleValue(e.target.value)}
            >
              {titleOptions.map(({ label, value }) => (
                <option key={label} value={value}>
                  <TranslateComponent>{label}</TranslateComponent>
                </option>
              ))}
            </SelectField>
          </DenaliFormElement>
        </Flex>
        <SelectVisibility
          visibility={visibilityValue}
          name={visibilityProperty}
          className={styles.smlSelect}
        />
      </Flex>
      <hr />
      <>
        <Flex alignItems="flex-end" direction="row" width="100%" gap="8px">
          <Flex
            direction="column"
            gap="0"
            width="56%"
            justifyContent="spaceBetween"
          >
            <Label className={styles.smlLabel} htmlFor={fieldLabel}>
              <TranslateComponent>{fieldLabel}</TranslateComponent>:
            </Label>
            <DenaliFormElement id={fieldLabel} name={inputProperty}>
              <Input
                type="text"
                name="primary"
                data-testid={fieldLabel.replace(' ', '-')}
                placeholder={placeholderText}
                value={inputValue}
              />
            </DenaliFormElement>
            <DenaliFormElementError name={inputProperty} />
          </Flex>
          <span className={styles.emptyLabelSpacer}></span>
          <DenaliFormElement
            id={`simple-payback-roi-input--${selectProperty}`}
            name={selectProperty}
          >
            <SelectField
              label={titleValue}
              labelHidden
              name={selectProperty}
              data-testid={selectProperty}
              placeholder={placeholderSelect}
              value={selectValue}
            >
              {selectOptions.map(({ label, value }) => (
                <option key={label} value={value}>
                  <TranslateComponent>{label}</TranslateComponent>
                </option>
              ))}
            </SelectField>
          </DenaliFormElement>
        </Flex>
        {previewBuilder && previewBuilder(values, formula)}
      </>
    </div>
  )
}
