import React, { PureComponent } from 'react'
import BuildingNavigation from './building-navigation'
import clickOutside from 'src/components/legacy/components/decorators/click-outside'
import {ORGANIZATION, LOCATION} from "./constants"


export class BuildingNavigationQueryWrapper extends PureComponent<BuildingNavigation> {
  setClickOutsideRef: any
  render() {
    const updateTokenOrg = this.props.updateTokenOrg
    const updateTokenOrgBuildings = this.props.updateTokenOrgBuildings
    const claims = this.props.claims
    const updateTokenSO = this.props.updateTokenSO
    const bypassUpdatingClaims = this.props.bypassUpdatingClaims
    const groupByTag = this.props.groupByTag || [ORGANIZATION, LOCATION];
    const showLocations = (typeof this.props.showLocations === 'undefined') ? true: false
    const isShowBuildingPicker = this.props?.isShowBuildingPicker
    return (
      <BuildingNavigation
        groupByTag={groupByTag}
        updateTokenSO={updateTokenSO}
        updateTokenOrg={updateTokenOrg}
        bypassUpdatingClaims={bypassUpdatingClaims}
        claims={claims}
        updateTokenOrgBuildings={updateTokenOrgBuildings}
        wrapperRef={this.setClickOutsideRef}
        allowDisabledLocations={this.props.allowDisabledLocations || false}
        showLocations={showLocations}
        isShowBuildingPicker={isShowBuildingPicker}
      />
    )
  }
}

type BuildingNavigation = {
  showOnlyOrganizations: boolean
  allowDisabledLocations: boolean
  showLocations: boolean
  updateTokenOrg: boolean,
  claims: Array<string>,
  updateTokenOrgBuildings: boolean,
  updateTokenSO: boolean,
  bypassUpdatingClaims: boolean,
  isShowBuildingPicker: false,
  groupByTag: "organization" | "sales-office"
}

const BuildingNavWithClickOutside = clickOutside(BuildingNavigationQueryWrapper)

BuildingNavWithClickOutside.defaultProps = {
  updateTokenOrg: false,
  claims: null,
  updateTokenOrgBuildings: false,
  updateTokenSO: false,
  bypassUpdatingClaims: false
}

export default React.memo(BuildingNavWithClickOutside)
