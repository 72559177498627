import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { Chip, ChipColor } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import { IconCell } from 'src/denali-components/Table/IconCell'
import styles from './device-connect.module.scss'
import { Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationDot,
  faLayerGroup,
  faRectangleBarcode
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { DeviceAvailability } from './DeviceAvailability'
import { DeviceConnectActions } from './DeviceConnectActions'

/**
 * DeviceConnectList
 * View replacement for the table portion of src/pages/connectToADevice/index.jsx
 */
export const DeviceConnectList = ({
  isLoading,
  rows,
  optionsTranslated,
  deviceTypes,
  selectedConnect,
  showServiceToolsOptions,
  openVPNAllowedTypes,
  serviceToolButtonHandler,
  nonSecClick
}) => {
  const filtersList = getFiltersList(deviceTypes, rows)

  return (
    <div className={styles.connectedDevices}>
      <ClientSideTable
        rows={rows}
        header={getHeadersWithRowRenderOverride(
          optionsTranslated,
          selectedConnect,
          showServiceToolsOptions,
          openVPNAllowedTypes,
          serviceToolButtonHandler,
          nonSecClick
        )}
        search={true}
        searchFields={[
          'name',
          'type',
          'building',
          'address',
          'serial',
          'state'
        ]}
        filtersList={filtersList}
        isDenaliTable={true}
        testName="deviceConnectPage"
        noTableDataTitleText={'No devices found'}
        listDataIsLoading={isLoading}
      />
    </div>
  )
}

const getFiltersList = (deviceTypes, rows) => {
  return [
    {
      key: 'type',
      filterName: 'type',
      filterLabel: 'Device Type',
      id: 'typeSelectorFilter',
      defaultLabel: 'All Types',
      selectedValue: 'default',
      translateOptions: false,
      options: getAvailableTypes(deviceTypes, rows)
    }
  ]
}

const getAvailableTypes = (types, rows) => {
  return Object.keys(types).reduce((acc, type) => {
    if (rows?.some((row) => row.type === types[type])) {
      acc.push({ name: types[type], value: types[type] })
    }
    return acc
  }, [])
}

const getHeadersWithRowRenderOverride = (
  optionsTranslated,
  selectedConnect,
  showServiceToolsOptions,
  openVPNAllowedTypes,
  serviceToolButtonHandler,
  nonSecClick
) => {
  return [
    {
      title: optionsTranslated.name,
      key: 'name',
      width: '100%',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.address,
      key: 'address',
      width: '250px',
      customComponent: addressCell
    },
    {
      title: optionsTranslated.actions,
      key: 'actions',
      width: '150px',
      customComponent: (row) =>
        actionsCell(
          row,
          selectedConnect,
          showServiceToolsOptions,
          openVPNAllowedTypes,
          serviceToolButtonHandler,
          nonSecClick
        )
    }
  ]
}

const titleCell = (row) => {
  return (
    <div data-testid={`reportsRowTitle-${row.device.id}`}>
      <IconCell
        iconType={
          row.availabilitySort === 'Available' ? row.type : 'deviceUnavailable'
        }
      >
        <div className={styles.title}>{row.name}</div>
        <Chips>
          {row.availability && (
            <Chip
              text={<DeviceAvailability device={row} />}
              color={ChipColor.none}
            />
          )}
          {row.type && (
            <Chip text={row.type} color={ChipColor.none} icon={faLayerGroup} />
          )}
          {row.serial && (
            <Chip
              text={row.serial}
              color={ChipColor.none}
              icon={faRectangleBarcode}
            />
          )}
        </Chips>
      </IconCell>
    </div>
  )
}

const addressCell = (row) => {
  return (
    <Flex
      gap={16}
      alignItems="center"
      data-testid={`reportsRowAddress-${row.device.id}`}
    >
      <div className={styles.locationIcon}>
        <FontAwesomeIcon icon={faLocationDot} color="gray" />
      </div>
      <Flex direction="column" gap={4}>
        <div className={styles.building}>
          {row.building && <span>{row.building}</span>}
        </div>
        <div className="body-md no-wrap">
          {row.address && <span>{row.address}</span>}
          {row.city && <span>, {row.city}</span>}
          {row.state && <span>, {row.state}</span>}
        </div>
      </Flex>
    </Flex>
  )
}

const actionsCell = (
  row,
  selectedConnect,
  showServiceToolsOptions,
  openVPNAllowedTypes,
  serviceToolButtonHandler,
  nonSecClick
) => {
  return (
    <div>
      <DeviceConnectActions
        row={row}
        selectedConnect={selectedConnect}
        showServiceToolsOptions={showServiceToolsOptions}
        openVPNAllowedTypes={openVPNAllowedTypes}
        serviceToolButtonHandler={serviceToolButtonHandler}
        nonSecClick={nonSecClick}
      />
    </div>
  )
}

export default DeviceConnectList
