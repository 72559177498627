export const CREATE_NEW_BUILDING_DATA_CHART = `
mutation createBuildingDataChart($input: CreateBuildingDataChartInput!) {
    createBuildingDataChart(input: $input) {
    id
  }
}
`

export const UPDATE_BUILDING_DATA_CHART = `
mutation updateBuildingDataChart($input: UpdateBuildingDataChartInput!) {
    updateBuildingDataChart(input: $input) {
    id
  }
}
`
export const DELETE_BUILDING_DATA_CHART = `
mutation deleteBuildingDataChart($input: DeleteBuildingDataChartInput!) {
    deleteBuildingDataChart(input: $input) {
    id
  }
}
`
export const LIST_CHARTS_BY_ACCOUNT = `
  query listChartsByAccount(
    $accountId: ID!,
    $filter: ModelBuildingDataChartFilterInput
  ) {
    listChartsByAccount(accountId: $accountId, filter: $filter) {
      items {
        name
        id
        equipmentId
        description
        defaultChart
        createdAt
        accountId
        properties
        sharable
        userId
      }
    }
  }
`

export const GET_DASHBOARDS = `
query listDashboardsByUser($userId: ID!, $limit: Int) {
  listDashboardsByUser(userId:$userId, limit: $limit) {
    items {
      Description
      id
      isDefault
      isPublic
      isKeyMetric
      isVisible
      name
      userId
    }
  }
}
`

export const GET_EQUIPMENT_PROPERTIES_BY_ID = `query GET_DEFAULT_DATA_SOURCE_PROPERTY ($equipmentId: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $equipmentId, filter: {targetDataGroup: {eq: "Analog"}}, limit: 10000, propertyType: {eq: "Property"}) {
    items {
      id
      propertyType
      tisDisplayName
      targetDataGroup
      targetKey
      targetMeasureUnit
      sourceMeasureUnit
    }
  }
}
`

export const CREATE_WIDGET = `mutation CreateWidget($input: CreateWidgetInput!) {
  createWidget(input: $input)
  {
    id
  }
}
`
export const UPDATE_WIDGET = `mutation UpdateWidget($input: UpdateWidgetInput!) {
  updateWidget(input: $input)
  {
    id
  }
}
`

export const CREATE_DASHBOARD_WIDGET = `mutation CREATE_DASHBOARD_WIDGET($input:CreateDashboardWidgetInput!) {
  createDashboardWidget(input: $input) {
    id
  }
}`

export const UPDATE_DASHBOARD = /* GraphQL */ `
  mutation updateDashboard($input: UpdateDashboardInput!) {
    updateDashboard(input: $input) {
      id
    }
  }
`

export const GET_DASHBOARD_BUILDING_DATA_CHARTS = /* GraphQL */ `
  query getDashboard($id: ID!) {
    getDashboard(id: $id) {
      buildingDataCharts
      id
    }
  }
`


export const ListBuildingEquipments = /* GraphQL */ `
  query listBuildingEquipments($id: ID!, $nextToken: String) {
    getBuilding(id: $id) {
      id
      name
      equipments(
        filter: { isActive: { eq: 1 }, isTIS: { eq: 1 } }
        nextToken: $nextToken
        limit: 1000
      ) {
        items {
          id
          name
          type
        }
        nextToken
      }
    }
  }
`

export const ListBuildingEquipmentsByFilter = /* GraphQL */ `
  query listBuildingEquipments($id: ID!, $nextToken: String) {
    getBuilding(id: $id) {
      id
      name
      equipments(
        filter: { isActive: { eq: 1 }, isTIS: { eq: 1 } }
        nextToken: $nextToken
        limit: 1000
      ) {
        items {
          id
          name
          type
        }
        nextToken
      }
    }
  }
`
