import { Flex, View } from '@aws-amplify/ui-react'
import { useMemo } from 'react'

import styles from './selection-wrapper.module.scss'
import { SelectionWrapperSelectedItemsProps } from './types'
import { SelectionWrapperSelectedItem } from './SelectionWrapperSelectedItem'

/**
 * A companion component to the [SelectionWrapper] that will render the selected items as
 * "chips" that can be removed from the list of selected items.
 *
 * @param selectedItems The array of selected items to render
 * @param itemBuilder A callback that takes a single selected item to be rendered
 * @param onRemoveSelectedItem A callback that when when called will remove the item
 * @param keyExtractor A function used to determine "uniqueness" for an item
 * @param nameExtractor A callback that takes in a single selected item and returns a "name"
 *
 * @returns React.ReactNode
 */
export const SelectionWrapperSelectedItems = <T extends NonNullable<unknown>>({
  selectedItems = [],
  itemBuilder,
  onRemoveSelectedItem,
  keyExtractor,
  nameExtractor
}: SelectionWrapperSelectedItemsProps<T>) => {
  // Memoize the rendered items using the keyExtractor to build each item
  // and assign
  const items = useMemo(() => {
    return selectedItems.map((item) => {
      const key = keyExtractor(item)
      if (itemBuilder !== undefined) {
        return itemBuilder(key, item, onRemoveSelectedItem)
      }

      return (
        <View key={key} flex={`1 1 ${100 / 5}`}>
          <SelectionWrapperSelectedItem
            item={item}
            nameExtractor={nameExtractor}
            keyExtractor={keyExtractor}
            onRemoveSelectedItem={onRemoveSelectedItem}
          />
        </View>
      )
    })
  }, [selectedItems, keyExtractor])

  return (
    <Flex
      className={styles.selectionWrapperSelectedItemsWrapper}
      direction="column"
    >
      <p className={styles.selectionWrapperSelectedItemsHeader}>Selected:</p>
      <Flex
        direction="row"
        gap="8"
        className={styles.selectionWrapperSelectedItems}
      >
        {items}
      </Flex>
    </Flex>
  )
}
