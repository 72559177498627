import { useState, useRef } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { RadioGroup } from 'src/components'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { selectUserInfo } from 'src/redux/slicers/appData'
import Label from 'src/components/legacy/components/label/label'
import { useSelector } from 'react-redux'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getTestSettingsSummary } from './graphql'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import Select from 'src/components/legacy/components/select/select'
import translate, { TranslateComponent } from 'src/common/translations'
import {
  LimitControls,
  SettingsAccordion,
  SettingsParameterEdit,
  CommentsEdit,
  DetectionRateControls,
  OscillationLimits
} from './TestSettingsComponents'
import {
  TestSettingsModalContainer,
  Title,
  InformationText,
  SettingModalHeaderContainer,
  SubTitle,
  SettingsNavigateButton,
  FlexContainer,
  ControlContainer,
  GridRow,
  CPTypeSelector,
  RadioGroupContainer,
  VerticalLine,
  SuppressionEditContainer,
  SuppressionNoteText
} from './style'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { createATSettings, updateATSettings } from './graphql'
import moment from 'moment/moment'
import { parameterScope } from './AutomatedTestSettingsList'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import store from 'src/redux/store';
import { siUnitMapping, convertUnits, roundOffConvertedValues } from 'src/common/UOMConversions'

const TEST_STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
    htmlFor: 'Active-Status'
  },
  {
    label: 'Suppressed',
    value: 'suppressed',
    htmlFor: 'Suppressed-Status'
  }
]

const APPLY_CHANGES_TO_OPTIONS = [
  {
    label: 'Selected Equipment',
    value: 'equipment',
    htmlFor: 'selected-equipment'
  },
  {
    label: 'Selected Buildings',
    value: 'buildings',
    htmlFor: 'selected-building'
  }
]

const CONDENSER_OPTIONS = {
  scroll: 'Scroll',
  helicalRotatry: 'Helical Rotary',
  centrifugal: 'Centrifugal'
}

export const AutomatedTestSettingsModal = ({
  buildingDetails,
  selectedTest,
  toggleSettingsModal,
  equipmentsList,
  selectedEquipment,
  buildingsList,
  refreshSettings,
  loadingTestDetails,
  onChangeCondenserType,
  selectedCptype,
  getTestDetails
}) => {
  const inputRef = useRef()

  // [TO-DO] We have check the feasibility to move the states to child components - optimize

  const [lastUsedInputElement, setLastUsedInputElement] = useState('')

  const [loading, setLoading] = useState(false)

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  const [showSuppressionModal, setShowSuppressionModal] = useState(false)

  const [isTestSuppressed, setIsTestSuppressed] = useState(
    selectedTest?.isSuppressed
  )

  const [validationError, setValidationError] = useState({})

  const [activeSuppressionHistory, setActiveSuppressionHistory] = useState(null)

  const [showComments, setShowComments] = useState(false)

  const [userComments, setUserComments] = useState('')

  const [suppressionOptionDetails, setSaveSuppressionOptionDetails] =
    useState(null)

  const [modifiedValuesObject, setModifiedValuesObject] = useState({})

  const [selectedOverrideLevel, setSelectedOverrideLevel] = useState(
    selectedEquipment ? 'equipment' : 'buildings'
  )

  const user = useSelector(selectUserInfo)

  const [activeEquipments, setActiveEquipments] = useState([])

  const [activeBuildings, setActiveBuildings] = useState([])

  const [pageNo, setPageNo] = useState(1)

  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const userSelectedUOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"
  // Dimension hook to get all Dimensions
  const { allDimensions = [], getAllDimensions } = useDimensionsHook()

  const {
    refetch: refetchSuppressionHistory,
    loading: loadingSuppressionHistory
  } = useQuery({
    query: getTestSettingsSummary,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getATSettings'
  })

  const { onSubmit: createATSettingsMutation } = useMutation({
    query: createATSettings,
    errorPolicy: 'global'
  })

  const { onSubmit: updateATSettingsMutation } = useMutation({
    query: updateATSettings,
    errorPolicy: 'global'
  })

  const getUpdatedByName = () => {
    const firstName =
      user.firstName?.charAt(0)?.toUpperCase() + user.firstName?.slice(1) || ''
    const lastName =
      user.lastName?.charAt(0)?.toUpperCase() + user.lastName?.slice(1) || ''
    return `${firstName} ${lastName}`
  }

  const onInputElementFocus = (paramName) => {
    setLastUsedInputElement(paramName)
  }

  const getConvertedATGlobalValue = (val, dimID) => {
    // let uom = 'No Unit'
    if (userSelectedUOM === 'SI') {
      const dimension = allDimensions?.find(
        (x) => x?.dimId === dimID && x?.UoM !== 'dimensionless'
      )
      if (dimension) {
        const uom = dimension.UoM //IP UoM
        const subType = dimension?.subType
        const siUoM = siUnitMapping.get(subType.toLowerCase()) //siUnitMapping.get(propertyDetails?.targetMeasureType)
        if (siUoM) {
          val = roundOffConvertedValues(convertUnits(val, uom, siUoM))
          // uom = siUoM
        }
      }
    }
    return val
  }

  useEffect(() => {
    getAllDimensions()
  }, [])

  useEffect(() => {
    if (lastUsedInputElement) {
      inputRef[lastUsedInputElement]?.focus()
    }
  }, [modifiedValuesObject, lastUsedInputElement, userComments])

  const getAndSetSuppressionHistory = async (buildingId, equipmentId) => {
    const activeSuppressionDetails = await getSuppressionHistoryData(
      buildingId,
      equipmentId
    )
    setActiveSuppressionHistory(activeSuppressionDetails)
    setSaveSuppressionOptionDetails({
      id: activeSuppressionDetails?.id,
      suppressionOption: activeSuppressionDetails?.endDate
        ? 'specify-date'
        : 'indefinitely',
      startDate: activeSuppressionDetails?.startDate
        ? activeSuppressionDetails?.startDate
        : moment().format(BACKEND_DATE_FORMAT),
      endDate: activeSuppressionDetails?.endDate || null
    })
  }

  const getSuppressionHistoryData = async (buildingId, equipmentId) => {
    try {
      const currentDate = moment()
      // Active suppression data, if user make it active, we have to make the isactive data to 0
      const input = {
        req_bid: buildingId,
        at_key: selectedTest?.atKey,
        req_type: 'sp',
        req_fid: selectedTest?.equipmentType,
        isSummary: 0
      }
      const historyDetails = await refetchSuppressionHistory({
        body: JSON.stringify(input)
      })
      const parsedData = JSON.parse(historyDetails)
      const historySettings = parsedData?.settings || []

      if (historySettings?.length > 0) {
        const buildingActiveSuppression = historySettings?.find(
          (x) =>
            x?.isActive === 1 &&
            x?.analyticId === selectedTest?.testName &&
            x?.equipmentId === 'NA' &&
            x?.subType === selectedTest?.subType &&
            (!x?.endDate || currentDate.isSameOrBefore(x?.endDate))
        )

        if (equipmentId) {
          const equipmentActiveSuppression = historySettings?.find(
            (x) =>
              x?.isActive === 1 &&
              x?.analyticId === selectedTest?.testName &&
              x?.equipmentId === equipmentId &&
              x?.subType === selectedTest?.subType &&
              (!x?.endDate || currentDate.isSameOrBefore(x?.endDate))
          )
          if (equipmentActiveSuppression) {
            return equipmentActiveSuppression
          } else {
            return buildingActiveSuppression
          }
        } else {
          return buildingActiveSuppression
        }
      }
    } catch (e) {}
  }

  useEffect(() => {
    setIsTestSuppressed(selectedTest?.isSuppressed)
    getAndSetSuppressionHistory(
      buildingDetails?.buildingId,
      selectedEquipment?.key
    )
    if (selectedTest?.isSuppressed) {
      getAndSetSuppressionHistory(
        buildingDetails?.buildingId,
        selectedEquipment?.key
      )
    }
  }, [selectedTest])

  useEffect(() => {
    if (isTestSuppressed) {
      // if the test is already suppressed, but still change in  suppressionOptionDetails
      // which means, we may need to update the dates
      setModifiedValuesObject({
        suppression: 'suppression'
      })
    } else {
      if (selectedTest?.isSuppressed) {
        setModifiedValuesObject({
          suppression: 'suppression'
        })
      } else {
        setModifiedValuesObject({})
      }
    }
  }, [suppressionOptionDetails, isTestSuppressed])

  const globalValues = useMemo(() => {
    if (selectedTest) {
      const globalValue = {}
      selectedTest?.gt?.map((param) => {
        if (param.isActive && param?.isDisplay) {
          // Logic to convert values into SI
          const valueToConvert = param?.intValue !== undefined ? param?.intValue : param?.floatValue;
          globalValue[param?.name] = getConvertedATGlobalValue(valueToConvert, param.dimID)
        }
      })

      // Check for single sided or two-sided limits
      const isSingleSided = selectedTest?.at?.some(
        (x) => x?.type === 'Limits' && x?.stringValue === 'NA'
      )
      if (isSingleSided) {
        // check with limits are not having values, and make them undefined
        // We are having condition based on undefined in Limits input boxes
        const notApplicableLimits = selectedTest?.at?.filter(
          (x) => x?.type === 'Limits' && x?.stringValue === 'NA'
        )
        notApplicableLimits?.map((limits) => {
          // All these are based on current backend config, any changes would coolpase entire logic :|
          const limitName = limits?.name?.replace('Operator', '')
          globalValue[limitName] = undefined
        })
      }
      return globalValue
    } else {
      return null
    }
  }, [selectedTest])

  const initialValues = useMemo(() => {
    if (selectedTest) {
      const initialValue = {}
      selectedTest?.at?.map((param) => {
        if (param.isActive && param?.isDisplay) {
          initialValue[param?.name] =
            param?.intValue !== undefined ? param?.intValue : param?.floatValue
        }
      })

      // Check for single sided or two-sided limits
      const isSingleSided = selectedTest?.at?.some(
        (x) => x?.type === 'Limits' && x?.stringValue === 'NA'
      )
      if (isSingleSided) {
        // check with limits are not having values, and make them undefined
        // We are having condition based on undefined in Limits input boxes
        const notApplicableLimits = selectedTest?.at?.filter(
          (x) => x?.type === 'Limits' && x?.stringValue === 'NA'
        )
        notApplicableLimits?.map((limits) => {
          // All these are based on current backend config, any changes would collapse entire logic :|
          const limitName = limits?.name?.replace('Operator', '')
          initialValue[limitName] = undefined
        })
      }
      return initialValue
    } else {
      return null
    }
  }, [selectedTest])

  const toggleSuppressionModal = () => {
    setShowSuppressionModal(!showSuppressionModal)
  }

  const modifiedValuesArray = useMemo(() => {
    const arrayValues = []
    Object.keys(modifiedValuesObject)?.map((paramerName) => {
      if (modifiedValuesObject[paramerName] === 'suppression') {
        const isTestAlreadySuppressed = selectedTest?.isSuppressed
        if (isTestAlreadySuppressed && isTestSuppressed) {
          //suppression date changes
          const oldValue = `Yes(${activeSuppressionHistory?.startDate}-${
            activeSuppressionHistory?.endDate
              ? activeSuppressionHistory?.endDate
              : 'indefinitely'
          })`
          const newValue = `Yes(${suppressionOptionDetails?.startDate}-${
            suppressionOptionDetails?.endDate
              ? suppressionOptionDetails?.endDate
              : 'indefinitely'
          })`
          arrayValues.push({
            name: 'suppression',
            displayName: `${selectedTest?.testDisplayName} Suppression`,
            oldValue: oldValue,
            newValue: newValue
          })
        } else {
          const oldValue = 'No'
          const newValue = `Yes(${suppressionOptionDetails?.startDate}-${
            suppressionOptionDetails?.endDate
              ? suppressionOptionDetails?.endDate
              : 'indefinitely'
          })`
          arrayValues.push({
            name: 'suppression',
            displayName: `${selectedTest?.testDisplayName} Suppression`,
            oldValue: isTestAlreadySuppressed ? newValue : oldValue,
            newValue: isTestAlreadySuppressed ? oldValue : newValue
          })
        }
      } else {
        const paramDetails = selectedTest?.at?.find(
          (x) => x?.name === paramerName
        )
        arrayValues.push({
          ...paramDetails,
          oldValue: initialValues[paramerName],
          newValue: modifiedValuesObject[paramerName]
        })
      }
    })
    return arrayValues
  }, [modifiedValuesObject])

  const getATConvertedValue = (at) => {
    let keyName = "", val = at?.newValue

    if(userSelectedUOM === 'SI') {
      const dimension = allDimensions?.find(
        (x) => x?.dimId === at?.dimID && x?.UoM !== 'dimensionless'
      )
      if (dimension ) {
        const ipUom = dimension.UoM //IP UoM
        const subType = dimension?.subType
        const siUoM = siUnitMapping.get(subType.toLowerCase())
        if (siUoM) {
          val = roundOffConvertedValues(convertUnits(val, siUoM, ipUom))
          if (at.boundary && Object.keys(at.boundary)) {
            Object.keys(at.boundary).forEach(key => at.boundary[key] = roundOffConvertedValues(convertUnits(at.boundary[key], siUoM, ipUom), 2))
          }
          if (at.range && Object.keys(at.range)) {
            Object.keys(at.range).forEach(key => at.range[key] = roundOffConvertedValues(convertUnits(at.range[key], siUoM, ipUom)))
          }
        }
      }
    }

    // Determine the key (intValue or floatValue) and its value 
    if (at.hasOwnProperty('intValue')) { 
      keyName = 'intValue'
      val = Math.round(val)
    } else if (at.hasOwnProperty('floatValue')) { 
      keyName = 'floatValue'
    }

    return {keyName, val}
  }

  const saveSettingToBuildingOrEquipment = async (
    parameter,
    entity,
    isBuildingLevel,
    atDetails
  ) => {
    // check if the value comes from override global which is -1
    const globalOverrideRecord = atDetails?.gbTrace?.find(
      (x) =>
        x?.subType === parameter?.subType &&
        x?.analyticId === parameter?.analyticId &&
        x?.name === parameter?.name
    )
    // logic to convert modified value into IP
    const {keyName, val: convertedValue} = getATConvertedValue(parameter)
    const newValue = convertedValue

    const oldValue = initialValues[parameter?.name]

    const updatedName = getUpdatedByName()

    // We have prefixed subtype/oscillation for our identification
    // before saving those names to DB - remove our prefixes.
    const paramName = parameter?.name?.split('-')
    const actualDBName = paramName?.length > 1 ? paramName[1] : paramName[0]

    // Create new entry
    const allowedParams = {
      type: parameter?.type,
      name: actualDBName,
      [keyName]: newValue,
      subType: parameter?.subType,
      category: parameter?.category,
      accountId: parameter?.accountId,
      buildingId: isBuildingLevel ? entity?.key : buildingDetails?.buildingId,
      equipmentId: isBuildingLevel ? 'NA' : entity?.key || 'NA',
      familyId: parameter?.familyId,
      analyticId: parameter?.analyticId,
      createdBy: parameter?.createdBy,
      updatedBy: updatedName,
      isActive: 1,
      ...(userComments ? { comments: userComments } : {})
    }

    await createATSettingsMutation({ input: allowedParams })

    const checkIsItGlobal = parameter?.stType === 'G'
    allowedParams.isActive = 0
    allowedParams.comments = `${parameterScope[parameter?.stType]} to ${
      isBuildingLevel ? 'Building' : 'Equipment'
    }`
    allowedParams[keyName] = oldValue // saves old value
    allowedParams.checkPtValue = newValue // saves current value - for history
    allowedParams.checkPtDateTime = new Date().toISOString()

    if (checkIsItGlobal) {
      if (globalOverrideRecord) {
        allowedParams.id = globalOverrideRecord?.id
        await updateATSettingsMutation({ input: allowedParams })
      } else {
        // If it is global to building - but don't have any overrides earlier
        await createATSettingsMutation({ input: allowedParams })
      }
    } else {
      if (parameter?.stType === 'B' && isBuildingLevel) {
        allowedParams.id = parameter?.id
        await updateATSettingsMutation({ input: allowedParams })
      } else if (parameter?.stType === 'E' && !isBuildingLevel) {
        allowedParams.id = parameter?.id
        await updateATSettingsMutation({ input: allowedParams })
      } else {
        await createATSettingsMutation({ input: allowedParams })
      }
    }
  }

  const suppressionDateUpdate = async (entity, isBuildingLevel) => {
    try {
      const buildingId = isBuildingLevel
        ? entity?.key
        : buildingDetails?.buildingId
      const equipmentId = isBuildingLevel ? undefined : entity?.key
      const activeSuppression = await getSuppressionHistoryData(
        buildingId,
        equipmentId
      )
      if (activeSuppression) {
        const updatedName = getUpdatedByName()
        // update the suppression record to inactive
        const suppressInput = {
          id: activeSuppression?.id,
          startDate: suppressionOptionDetails?.startDate,
          endDate: suppressionOptionDetails?.endDate
            ? suppressionOptionDetails?.endDate
            : null,
          updatedBy: updatedName,
          ...(userComments ? { comments: userComments } : {})
        }
        await updateATSettingsMutation({ input: suppressInput })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const supressionToActive = async (entity, isBuildingLevel) => {
    try {
      const currentDate = moment()
      const buildingId = isBuildingLevel
        ? entity?.key
        : buildingDetails?.buildingId
      const equipmentId = isBuildingLevel ? undefined : entity?.key
      const activeSuppression = await getSuppressionHistoryData(
        buildingId,
        equipmentId
      )
      if (activeSuppression) {
        let suppressionStart = activeSuppression?.startDate
        let suppressionEndDate = moment(new Date()).subtract(1,'days').format(BACKEND_DATE_FORMAT)

        let isActive = 0
        // if the suppression start date is future date, which means suppression never happens
        // so we need to make it inactive
        const isSuppressedForFuture = moment(activeSuppression?.startDate)?.isSameOrAfter(
          moment(currentDate)
        )
        if (isSuppressedForFuture) {
          suppressionStart = suppressionEndDate
          isActive = 0
        } else {
          isActive = 1
        }

        const updatedName = getUpdatedByName()
        // update the suppression record to inactive
        const suppressInput = {
          id: activeSuppression?.id,
          startDate: suppressionStart,
          endDate: suppressionEndDate,
          isActive: isActive,
          checkPtDateTime: new Date().toISOString(),
          updatedBy: updatedName,
          ...(userComments ? { comments: userComments } : {})
        }
        await updateATSettingsMutation({ input: suppressInput })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const activeTestToSuppress = async (entity, isBuildingLevel) => {
    try {
      const updatedName = getUpdatedByName()
      // Create new entry for Active 1 - Active Suppression
      const allowedParams = {
        name: 'Suppression',
        accountId: 'NA',
        stringValue: 'Active',
        analyticId: selectedTest?.testName,
        createdBy: updatedName,
        updatedBy: updatedName,
        isActive: 1,
        familyId: selectedTest?.equipmentType,
        buildingId: isBuildingLevel
          ? entity?.key || 'NA'
          : buildingDetails?.buildingId,
        equipmentId: isBuildingLevel ? 'NA' : entity?.key || 'NA',
        subType: selectedTest?.subType,
        startDate: suppressionOptionDetails?.startDate,
        ...(suppressionOptionDetails?.endDate
          ? {
              endDate: suppressionOptionDetails?.endDate
            }
          : {}),
        category: 'Suppression Settings',
        type: 'TestSuppression',
        ...(userComments ? { comments: userComments } : {})
      }

      await createATSettingsMutation({ input: allowedParams })

      // allowedParams.isActive = 0
      // allowedParams.checkPtValue = 'Suppressed'
      // allowedParams.checkPtDateTime = new Date().toISOString()
      // // Active 0 - for Suppression History
      // await createATSettingsMutation({ input: allowedParams })
    } catch (e) {
      console.log(e)
    }
  }

  const saveATSettingToGlobal = async (
    parameter,
    globalValue,
    isBuildingLevel
  ) => {
    const updatedName = getUpdatedByName()

    let value = ''
    if (parameter.type === 'AnalyticsParameter') {
      value = 'intValue'
    } else {
      value = 'floatValue'
    }

    // We have prefixed subtype/oscillation for our identification
    // before saving those names to DB - remove our prefixes.
    const paramName = parameter?.name?.split('-')
    const actualDBName = paramName?.length > 1 ? paramName[1] : paramName[0]

    // if the override is Building, but reset equipment level
    // don't touch equipment level records
    if (
      (parameter.stType === 'B' && isBuildingLevel) ||
      (parameter.stType === 'E' && !isBuildingLevel)
    ) {
      // Update active to 0, set to global
      const allowedParams = {
        id: parameter?.id,
        type: parameter?.type,
        [value]: parameter?.[value],
        name: actualDBName,
        subType: parameter?.subType,
        category: parameter?.category,
        accountId: parameter?.accountId,
        buildingId: parameter?.buildingId,
        equipmentId: parameter?.equipmentId,
        familyId: parameter?.familyId,
        analyticId: parameter?.analyticId,
        createdBy: parameter?.createdBy,
        updatedBy: updatedName,
        isActive: -1, // Denotes it goes from override to global
        ...(userComments ? { comments: userComments } : {})
      }

      await updateATSettingsMutation({ input: allowedParams })

      delete allowedParams?.id

      allowedParams[value] = parameter?.[value]
      allowedParams.isActive = 0
      allowedParams.comments = `${parameterScope[parameter?.stType]} to Global`
      allowedParams.checkPtValue = globalValue
      allowedParams.checkPtDateTime = new Date().toISOString()

      await createATSettingsMutation({ input: allowedParams })
    } else {
      // Update active to 0, set to global
      const allowedParams = {
        type: parameter?.type,
        [value]: parameter?.[value],
        name: actualDBName,
        subType: parameter?.subType,
        category: parameter?.category,
        accountId: parameter?.accountId,
        buildingId: parameter?.buildingId,
        equipmentId: parameter?.equipmentId,
        familyId: parameter?.familyId,
        analyticId: parameter?.analyticId,
        createdBy: parameter?.createdBy,
        updatedBy: updatedName,
        isActive: -1, // Denotes it goes from override to global
        ...(userComments ? { comments: userComments } : {})
      }

      await createATSettingsMutation({ input: allowedParams })

      allowedParams[value] = parameter?.[value]
      allowedParams.isActive = 0
      allowedParams.comments = `${parameterScope[parameter?.stType]} to Global`
      allowedParams.checkPtValue = globalValue
      allowedParams.checkPtDateTime = new Date().toISOString()

      await createATSettingsMutation({ input: allowedParams })
    }
  }

  const resetToGlobal = async () => {
    try {
      const nonGlobalParam = selectedTest?.at?.filter(
        (x) => x?.isDisplay === 1 && x?.stType !== 'G'
      )

      const globalValuesObj = {}
      // Non global values set to global in modified object
      nonGlobalParam.forEach((param) => {
        const newValue = globalValues[param.name]
        globalValuesObj[param.name] = newValue
      })
      setModifiedValuesObject(globalValuesObj)
      trackEvent(USER_EVENTS.BUILDING_DATA.events.RESET_AUTOMATED_TEST_GLOBAL)
    } catch (e) {}
  }

  const getSelectedEntityTestData = async (entities, isBuildingLevel) => {
    try {
      const promises = []
      entities?.map((x) => {
        const equipmentId = !isBuildingLevel ? x?.key : undefined
        const buildingId = isBuildingLevel ? x?.key : undefined
        promises.push(getTestDetails(undefined, buildingId, equipmentId))
      })

      let result = await Promise.all(promises)

      const entityTestDetails = {}

      entities?.map((x, index) => {
        entityTestDetails[x?.key] = result[index]
      })

      return entityTestDetails
    } catch (e) {}
  }

  const SaveATSettings = async () => {
    try {
      setLoading(true)
      const isAllEquipments = activeEquipments?.find((x) => x?.key === 'ALL')
      const isBuildingLevel =
        selectedOverrideLevel === 'buildings' || isAllEquipments ? true : false

      // if all equipments selected, loop thorugh all eqipments list
      const entitiesLevel = isBuildingLevel
        ? activeBuildings
        : isAllEquipments
        ? equipmentsList
        : activeEquipments

      const promises = []

      const allSelectedEntytiesTestData = await getSelectedEntityTestData(
        entitiesLevel,
        isBuildingLevel
      )

      entitiesLevel?.forEach((entity) => {
        modifiedValuesArray.forEach((modifiedParameter) => {
          const atTestDetails = allSelectedEntytiesTestData[entity?.key]
          if (atTestDetails) {
            if (modifiedParameter?.name === 'suppression') {
              if (atTestDetails?.isSuppressed) {
                if (isTestSuppressed) {
                  promises.push(suppressionDateUpdate(entity, isBuildingLevel))
                } else {
                  promises.push(supressionToActive(entity, isBuildingLevel))
                }
              } else if (modifiedParameter?.newValue?.includes('Yes')) {
                promises.push(activeTestToSuppress(entity, isBuildingLevel))
              }
            } else {
              const atParamter = atTestDetails?.at?.find(
                (testEntry) =>
                  testEntry.name === modifiedParameter?.name &&
                  testEntry?.type === modifiedParameter?.type
              )
              if (atParamter) {
                const parameter = {
                  ...atParamter,
                  newValue: modifiedParameter?.newValue
                }
                const modifiedValue = parameter?.newValue
                const globalValue = globalValues[modifiedParameter?.name]
                // Just to be cautious, mistakenly don't add NaN to settings table
                if (!isNaN(modifiedValue)) {
                  if (modifiedValue === globalValue) {
                    // if the modified value and global values are same, make it inactive
                    parameter?.stType !== 'G' &&
                      promises.push(
                        saveATSettingToGlobal(
                          parameter,
                          modifiedValue,
                          isBuildingLevel
                        )
                      )
                  } else {
                    promises.push(
                      saveSettingToBuildingOrEquipment(
                        parameter,
                        entity,
                        isBuildingLevel,
                        atTestDetails
                      )
                    )
                  }
                }
              }
            }
          }
        })
      })
      await Promise.all(promises)
      toggleSettingsModal()
      setLoading(false)
      refreshSettings?.()
      trackEvent(USER_EVENTS.BUILDING_DATA.events.SAVE_AUTOMATED_TEST, {mode:"EDIT"})
    } catch (e) {
      console.log(e)
    }
  }

  const testSettingsModalConfig = {
    gray: true,
    className: 'wide-modal',
    isHideWhiteBackground: true,
    customHeaders: `${buildingDetails?.name} - ${translate(selectedTest?.testDisplayName) || ''}`,
    handleClose: toggleSettingsModal,
    formIsErrored: Object.keys(validationError)?.length > 0,
    buttons: [
      {
        text: 'Cancel',
        handleClick: toggleSettingsModal,
        type: 'cancel'
      },
      ...(pageNo === 2 &&
      modifiedValuesArray?.length > 0 &&
      (activeBuildings?.length > 0 || activeEquipments?.length > 0)
        ? [
            {
              text: 'Save',
              handleClick: SaveATSettings,
              type: 'save',
              disabled: loading
            }
          ]
        : [])
    ]
  }

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings)
  }

  const toggleComments = () => {
    setShowComments(!showComments)
  }

  const disableSuppressionSettings = useMemo(() => {
    // Checks the suppression level
    // if it is at Building level, and user seeing modal in Equipment level - disable
    if (activeSuppressionHistory) {
      const isBuildingLevel = selectedEquipment?.key ? false : true
      if (!isBuildingLevel && activeSuppressionHistory.spLevel === 'B') {
        return true
      } else {
        return false
      }
    } else if (selectedTest?.isSuppressed) {
      return true
    }
  }, [activeSuppressionHistory])

  // if the limits NA
  const isShowLimitControls =
    selectedTest?.at?.filter(
      (x) =>
        x?.type === 'Limits' &&
        x?.stringValue === 'NA' &&
        !x?.subType.includes('Oscillation') &&
        x?.analyticId === selectedTest?.testName
    )?.length > 2
      ? false
      : true

  const onAnalyticParamChange = (name, value) => {
    try {
      const modifiedStateValues = { ...modifiedValuesObject }
      // Not a number checks, only for negative number, only "-" comes, that time it fails
      const isNotNumber = isNaN(value)
      if (!isNotNumber) {
        // Removed Number(value) to allow input to accept floating values in case of SI selected
        const numberValue = value //Number(value)
        if (initialValues[name] === numberValue) {
          delete modifiedStateValues[name]
        }
        modifiedStateValues[name] = numberValue
      } else {
        modifiedStateValues[name] = value
      }

      setModifiedValuesObject({ ...modifiedStateValues })
    } catch (e) {}
  }

  const SettingsModalPage1 = () => {
    const onCpTypeChange = (item) => {
      onChangeCondenserType(item)
    }

    const getCPTypeOptions = useMemo(() => {
      const optionData = {}
      selectedTest?.availableCondensersType?.map((cpType) => {
        const optionDisplay = `${selectedTest?.testDisplayName}-${cpType}`
        optionData[cpType] = optionDisplay
      })
      return optionData || []
    }, [selectedCptype])

    return (
      <div style={{ overflowAnchor: 'none' }}>
        <SettingModalHeaderContainer>
          <div className="header-item">
            <SubTitle><TranslateComponent>Test</TranslateComponent></SubTitle>
            <span><TranslateComponent>{selectedTest?.testDisplayName}</TranslateComponent></span>
          </div>
          {loadingSuppressionHistory ? (
            <div>
              <Spinner className="suppression-edit-spinner" />
            </div>
          ) : (
            <div style={{ minWidth: 200 }} className="header-item">
              <SubTitle><TranslateComponent>Test Status</TranslateComponent></SubTitle>
              <RadioGroup
                handleSelection={({ target: { value, name } }) => {
                  if (value === 'active') {
                    setIsTestSuppressed(false)
                  } else {
                    setIsTestSuppressed(true)
                    toggleSuppressionModal()
                  }
                }}
                disabled={disableSuppressionSettings}
                radioKey="testStatus"
                options={TEST_STATUS_OPTIONS}
                selectedValue={isTestSuppressed ? 'suppressed' : 'active'}
              />
              {isTestSuppressed === true && (
                <>
                  <SuppressionEditContainer
                    isDisabled={disableSuppressionSettings}
                  >
                    {suppressionOptionDetails?.suppressionOption ===
                    'indefinitely' ? (
                      <div style={{ float: 'right', marginRight: 10 }}>
                        <span><TranslateComponent>Indefinitely</TranslateComponent></span>
                        <span
                          onClick={toggleSuppressionModal}
                          className="icon icon-edit"
                        />
                      </div>
                    ) : (
                      <div style={{ float: 'right', marginRight: 10 }}>
                        <span>
                          {moment(suppressionOptionDetails?.startDate).format(
                            'MM/DD/YYYY'
                          )}
                          {'-'}
                          {moment(suppressionOptionDetails?.endDate).format(
                            'MM/DD/YYYY'
                          )}
                        </span>
                        <span
                          onClick={toggleSuppressionModal}
                          className="icon icon-edit"
                        />
                      </div>
                    )}
                  </SuppressionEditContainer>
                  {disableSuppressionSettings && (
                    <SuppressionNoteText>
                      <TranslateComponent>*Suppressed at Building level</TranslateComponent>
                    </SuppressionNoteText>
                  )}
                </>
              )}
            </div>
          )}

          <div style={{ minWidth: 290 }} className="header-item">
            <SubTitle><TranslateComponent>Showing Analytics Parameter Values for</TranslateComponent></SubTitle>
            <span>
              <span style={{ fontWeight: 500 }}>{buildingDetails?.name}</span> /
              {selectedEquipment
                ? ` ${selectedEquipment?.label}`
                : <TranslateComponent>{` All ${selectedTest?.equipmentTypeUIName}s`}</TranslateComponent>}
            </span>
          </div>
        </SettingModalHeaderContainer>
        {selectedCptype && (
          <CPTypeSelector className="cptype-selector">
            <SubTitle><TranslateComponent>Sub-Test:</TranslateComponent></SubTitle>
            <Select
              options={getCPTypeOptions}
              onChange={onCpTypeChange}
              selectedItem={`${selectedTest?.testDisplayName}-${selectedCptype}`}
            />
          </CPTypeSelector>
        )}
        {selectedTest?.subTypeLimitGroups?.length > 0
          ? selectedTest?.subTypeLimitGroups?.map((subType) => (
              <LimitControls
                key={`${subType}limits`}
                initialValues={initialValues}
                limitValues={selectedTest?.at?.filter(
                  (x) => x?.type === 'Limits' && x?.subType === subType
                )}
                subType={subType}
                modifiedValuesObject={modifiedValuesObject}
                onAnalyticParamChange={onAnalyticParamChange}
                isSuppressed={isTestSuppressed}
                inputRef={inputRef}
                setValidationError={setValidationError}
                validationError={validationError}
                onInputElementFocus={onInputElementFocus}
              />
            ))
          : isShowLimitControls && (
              <LimitControls
                key="limits"
                initialValues={initialValues}
                limitValues={selectedTest?.at?.filter(
                  (x) => x?.type === 'Limits'
                )}
                modifiedValuesObject={modifiedValuesObject}
                onAnalyticParamChange={onAnalyticParamChange}
                isSuppressed={isTestSuppressed}
                inputRef={inputRef}
                setValidationError={setValidationError}
                validationError={validationError}
                onInputElementFocus={onInputElementFocus}
              />
            )}

        <DetectionRateControls
          key="detection-rate"
          initialValues={initialValues}
          detectionRates={selectedTest?.at?.filter(
            (x) => x?.type === 'DetectionRate'
          )}
          modifiedValuesObject={modifiedValuesObject}
          onAnalyticParamChange={onAnalyticParamChange}
          isSuppressed={isTestSuppressed}
          setValidationError={setValidationError}
          validationError={validationError}
          inputRef={inputRef}
          onInputElementFocus={onInputElementFocus}
        />
        {selectedTest?.OscillationLimitsPresents && (
          <OscillationLimits
            key="Oscillation-limits"
            initialValues={initialValues}
            OscillationLimits={selectedTest?.at?.filter(
              (x) => x?.subType?.includes('Oscillation') && x?.type === 'Limits'
            )}
            modifiedValuesObject={modifiedValuesObject}
            onAnalyticParamChange={onAnalyticParamChange}
            setValidationError={setValidationError}
            validationError={validationError}
            isSuppressed={isTestSuppressed}
            inputRef={inputRef}
            onInputElementFocus={onInputElementFocus}
          ></OscillationLimits>
        )}
        <SettingsAccordion
          key="advanced-settings"
          toggleOpen={toggleAdvancedSettings}
          name="Advanced settings"
          isOpen={showAdvancedSettings}
          disabled={isTestSuppressed}
        >
          {selectedTest?.at
            ?.filter(
              (x) =>
                (x?.isDisplay === 1 && x?.type === 'AnalyticsParameter') ||
                (x?.dailyRuntimeLimit && x?.isActive === 1)
            )
            ?.map((paramValue) => {
              return (
                <SettingsParameterEdit
                  key={paramValue?.name}
                  displayName={paramValue?.displayName}
                  parameterName={paramValue?.name}
                  identifierLevel={parameterScope[paramValue?.stType]}
                  value={
                    modifiedValuesObject[paramValue?.name] ||
                    initialValues[paramValue?.name]
                  }
                  acceptableRange={paramValue?.range}
                  onAnalyticParamChange={onAnalyticParamChange}
                  targetUnit={paramValue?.tUnit || paramValue?.targetUOM}
                  isModified={
                    modifiedValuesObject[paramValue?.name] ? true : false
                  }
                  range={paramValue?.range}
                  disabled={paramValue?.isNotEditable}
                  tooltip={paramValue?.toolTip}
                  tooltipText={paramValue?.tooltipText}
                  inputRef={inputRef}
                  onInputElementFocus={onInputElementFocus}
                  setValidationError={setValidationError}
                  validationError={validationError}
                />
              )
            })}
        </SettingsAccordion>
        <SettingsAccordion
          toggleOpen={toggleComments}
          name="Comments"
          isOpen={showComments}
          disabled={isTestSuppressed}
        >
          <CommentsEdit
            key="comments"
            componentName="comments1"
            setUserComments={setUserComments}
            userComments={userComments}
            onInputElementFocus={onInputElementFocus}
            inputRef={inputRef}
          />
        </SettingsAccordion>
      </div>
    )
  }

  const setDefaultEquipmentAndBuilding = () => {
    const defaultBuilding = buildingsList?.filter(
      (x) => x?.key === buildingDetails?.buildingId
    )
    setActiveBuildings(defaultBuilding)
    if (selectedEquipment) {
      const defaultEquipment = equipmentsList?.filter(
        (x) => x?.key === selectedEquipment?.key
      )
      setActiveEquipments(defaultEquipment)
    } else {
      setActiveEquipments([
        {
          key: 'ALL',
          label: <TranslateComponent>ALL EQUIPMENT</TranslateComponent>
        }
      ])
    }
  }

  useEffect(() => {
    setModifiedValuesObject({})
    setDefaultEquipmentAndBuilding()
  }, [selectedEquipment, equipmentsList])

  const onChangeBuilding = (buildings) => {
    const isDefaultPresent = buildings?.find(
      (x) => x?.key === buildingDetails?.buildingId
    )
    if (!isDefaultPresent) {
      const defaultBuilding = buildingsList?.filter(
        (x) => x?.key === buildingDetails?.buildingId
      )
      setActiveBuildings([...buildings, ...defaultBuilding])
    } else {
      setActiveBuildings(buildings)
    }
  }

  const onChangeEquipment = (equipments) => {
    if (selectedEquipment) {
      const isDefaultPresent = equipments?.find(
        (x) => x?.key === selectedEquipment?.key
      )
      if (!isDefaultPresent) {
        const defaultEquipment = equipmentsList?.filter(
          (x) => x?.key === selectedEquipment?.key
        )
        setActiveEquipments([...equipments, ...defaultEquipment])
      } else {
        setActiveEquipments(equipments)
      }
    } else {
      const allOptionIndex = equipments?.findIndex((x) => x?.key === 'ALL')
      if (
        equipments?.length === 0 ||
        allOptionIndex === equipments?.length - 1
      ) {
        setActiveEquipments([
          {
            key: 'ALL',
            label: <TranslateComponent>ALL EQUIPMENT</TranslateComponent>
          }
        ])
      } else {
        const equipmentsExceptAll = equipments?.filter((x) => x?.key !== 'ALL')
        setActiveEquipments(equipmentsExceptAll)
      }
    }
  }

  const modifiedNoteText = useMemo(() => {
    // Checks all the modified settings are from same level
    const isAllGlobalModified = modifiedValuesArray?.every(
      (x) => x?.stType === 'G'
    )
    if (isAllGlobalModified) {
      return <TranslateComponent>Currently using global settings</TranslateComponent>
    }
    const isAllBuildingsModified = modifiedValuesArray?.every(
      (x) => x?.stType === 'B'
    )
    if (isAllBuildingsModified) {
      return <TranslateComponent>Currently using buildings settings</TranslateComponent>
    }
    const isAllEquipmentModified = modifiedValuesArray?.every(
      (x) => x?.stType === 'E'
    )
    if (isAllEquipmentModified) {
      return <TranslateComponent>Currently using equipment settings</TranslateComponent>
    }
    return ''
  })

  const SettingsModalPage2 = () => {
    const allEquipmentOption = [
      {
        key: 'ALL',
        label: <TranslateComponent>ALL EQUIPMENTS</TranslateComponent>
      }
    ]

    return (
      <div>
        <SettingModalHeaderContainer>
          <div className="header-item">
            <SubTitle><TranslateComponent>Test</TranslateComponent></SubTitle>
            <span><TranslateComponent>{selectedTest?.testDisplayName}</TranslateComponent></span>
          </div>
        </SettingModalHeaderContainer>
        <div>
          <SubTitle><TranslateComponent>The following values will be updated.</TranslateComponent></SubTitle>
          {modifiedValuesArray?.length === 0 ? (
            <ControlContainer>
              <SubTitle><TranslateComponent>No new values to update</TranslateComponent></SubTitle>
            </ControlContainer>
          ) : (
            <>
              <GridRow header={true}>
                <div className="header"><TranslateComponent>PARAMETER NAME</TranslateComponent></div>
                <div className="header"><TranslateComponent>PREVIOUS VALUE</TranslateComponent></div>
                <div className="header"><TranslateComponent>NEW VALUE</TranslateComponent></div>
              </GridRow>
              {modifiedValuesArray?.map((value) => {
                const convertMinAndHours = (mins) => {
                  const hours = Math.trunc(mins / 60)
                  const minutes = Math.trunc(mins % 60)
                  return `${hours} Hours ${minutes} Minutes`
                }

                let scope = parameterScope[value?.stType]
                  ? `${parameterScope[value?.stType]}`
                  : ''

                return (
                  <GridRow key={value?.name}>
                    <div className="header">
                      <TranslateComponent>{value?.displayName || value?.name}</TranslateComponent>
                    </div>
                    <div>
                      {value?.tUnit === 'mins'
                        ? convertMinAndHours(value?.oldValue)
                        : `${value?.oldValue} ${value.tUnit || ''} (${translate(scope)})`}
                    </div>
                    <div>
                      <TranslateComponent>{value?.tUnit === 'mins'
                        ? convertMinAndHours(value?.newValue)
                        : `${value?.newValue} ${value.tUnit || ''} `}</TranslateComponent>
                    </div>
                  </GridRow>
                )
              })}
            </>
          )}
        </div>
        <FlexContainer>
          <div style={{ marginTop: 5 }}>
            <SubTitle><TranslateComponent>Apply new changes to:</TranslateComponent></SubTitle>
            <RadioGroupContainer>
              <RadioGroup
                className="entity-radio-group"
                handleSelection={({ target: { value, name } }) => {
                  setDefaultEquipmentAndBuilding()
                  setSelectedOverrideLevel(value)
                }}
                radioKey="selectedApplyItem"
                options={APPLY_CHANGES_TO_OPTIONS}
                selectedValue={selectedOverrideLevel}
              />
            </RadioGroupContainer>
          </div>
          <VerticalLine />
          <div style={{ flexGrow: 1 }}>
            <div className="selectrix-container">
              {selectedOverrideLevel === 'buildings' ? (
                <>
                  <Label text={<TranslateComponent>Select Building</TranslateComponent>} />
                  <i> ({activeBuildings?.length} <TranslateComponent>Selected</TranslateComponent>):</i>
                  <SelectrixWrapper
                    className="selectrix-wrapper--type-tags equipment"
                    multiple={true}
                    onChange={onChangeBuilding}
                    options={buildingsList}
                    defaultValue={activeBuildings?.map(({ key }) => key)}
                    placeholder={'Find an Building...'}
                    searchable={true}
                    searchBoxInside={true}
                    searchIndex={false}
                    stayOpen={true}
                  />
                </>
              ) : (
                <>
                  <Label text={<TranslateComponent>Select Equipment</TranslateComponent>} />
                  <i> ({activeEquipments?.length} <TranslateComponent>Selected</TranslateComponent>):</i>
                  <SelectrixWrapper
                    className="selectrix-wrapper--type-tags equipment"
                    multiple={true}
                    onChange={onChangeEquipment}
                    defaultValue={activeEquipments?.map(({ key }) => key)}
                    options={[...allEquipmentOption, ...equipmentsList]}
                    placeholder={<TranslateComponent>Find an Equipment...</TranslateComponent>}
                    searchable={true}
                    searchBoxInside={true}
                    searchIndex={false}
                    stayOpen={true}
                  />
                </>
              )}
            </div>
          </div>
        </FlexContainer>
        <div style={{ marginTop: 15 }}>
          <SubTitle><TranslateComponent>Comments</TranslateComponent></SubTitle>
          <CommentsEdit
            key="comments"
            componentName="comments2"
            setUserComments={setUserComments}
            userComments={userComments}
            onInputElementFocus={onInputElementFocus}
            inputRef={inputRef}
          />
        </div>
      </div>
    )
  }

  return (
    <ControlContainer>
      <Modal {...testSettingsModalConfig}>
        {loadingTestDetails || loading ? (
          <Spinner />
        ) : (
          <>
            <TestSettingsModalContainer>
              <Title>
                <TranslateComponent>Analytic Parameters</TranslateComponent>
                {modifiedNoteText && (
                  <InformationText> ({modifiedNoteText}) </InformationText>
                )}
              </Title>

              {pageNo === 1 ? (
                <SettingsModalPage1 key="modal-page-1" />
              ) : (
                <SettingsModalPage2 key="modal-page-2" />
              )}
            </TestSettingsModalContainer>
            <FlexContainer
              justfifyContent={`${pageNo === 1 ? 'space-between' : 'start'}`}
            >
              {pageNo === 1 ? (
                <>
                  <SettingsNavigateButton
                    onClick={resetToGlobal}
                    className="back-button"
                  >
                    <span><TranslateComponent>RESET ALL TO GLOBAL </TranslateComponent></span>
                  </SettingsNavigateButton>
                  <SettingsNavigateButton
                    onClick={() => setTimeout(setPageNo(2), 0)}
                    className={`next-button ${
                      Object.keys(validationError)?.length > 0
                        ? ' disabled'
                        : ''
                    }`}
                  >
                    <span><TranslateComponent>NEXT: </TranslateComponent></span>
                    <span style={{ marginLeft: 2 }}>
                      <b><TranslateComponent>APPLY SETTINGS</TranslateComponent></b>
                    </span>
                    <span className="next icon icon-expand" />
                  </SettingsNavigateButton>
                </>
              ) : (
                <SettingsNavigateButton
                  onClick={() => setPageNo(1)}
                  className="back-button"
                >
                  <span className="back icon icon-expand" />
                  <span><TranslateComponent>BACK: </TranslateComponent></span>
                  <span style={{ marginLeft: 2 }}>
                    <b><TranslateComponent>MODIFY SETTINGS</TranslateComponent></b>
                  </span>
                </SettingsNavigateButton>
              )}
            </FlexContainer>
          </>
        )}
      </Modal>
      {showSuppressionModal && (
        <SuppressionModal
          toggleSuppressionModal={toggleSuppressionModal}
          setSaveSuppressionOptionDetails={setSaveSuppressionOptionDetails}
          suppressionOptionDetails={suppressionOptionDetails}
        />
      )}
    </ControlContainer>
  )
}

const SuppressionModal = ({
  toggleSuppressionModal,
  setSaveSuppressionOptionDetails,
  suppressionOptionDetails
}) => {
  const [suppressionOption, setSuppressionOption] = useState(
    suppressionOptionDetails?.suppressionOption || 'indefinitely'
  )

  useEffect(() => {
    if (suppressionOption === 'indefinitely') {
      const dates = suppressionDates
      setSuppressionDates({
        ...dates,
        endDate: null
      })
    }
  }, [suppressionOption])

  const [suppressionDates, setSuppressionDates] = useState({
    startDate:
      suppressionOptionDetails?.startDate ||
      moment().format(BACKEND_DATE_FORMAT),
    endDate: suppressionOptionDetails?.endDate || null
  })

  const onStartChange = (date) => {
    const dates = suppressionDates
    setSuppressionDates({
      ...dates,
      startDate: moment(date).format(BACKEND_DATE_FORMAT)
    })
  }

  const onEndChange = (date) => {
    const dates = suppressionDates
    setSuppressionDates({
      ...dates,
      endDate: moment(date).format(BACKEND_DATE_FORMAT)
    })
  }

  const SUPPRESSION_OPTIONS = [
    {
      label: 'Indefinitely',
      value: 'indefinitely',
      htmlFor: 'Indefinitely'
    },
    {
      label: 'Specify Start/End Dates',
      value: 'specify-date',
      htmlFor: 'specify-date'
    }
  ]

  const saveDetails = () => {
    setSaveSuppressionOptionDetails({
      id: suppressionOptionDetails?.id,
      suppressionOption,
      startDate: suppressionDates?.startDate,
      endDate: suppressionDates?.endDate
    })
    toggleSuppressionModal()
  }

  const suppressionModalConfig = {
    gray: true,
    isHideWhiteBackground: false,
    heading: 'Suppression Details',
    handleClose: toggleSuppressionModal,
    buttons: [
      {
        text: 'Cancel',
        handleClick: toggleSuppressionModal,
        type: 'cancel'
      },
      {
        text: 'Save',
        handleClick: saveDetails,
        type: 'save',
        disabled:
          suppressionOption === 'specify-date' &&
          suppressionDates?.endDate === null
      }
    ]
  }

  return (
    <Modal {...suppressionModalConfig}>
      <RadioGroup
        handleSelection={({ target: { value, name } }) => {
          setSuppressionOption(value)
        }}
        radioKey="suppression-status"
        options={SUPPRESSION_OPTIONS}
        selectedValue={suppressionOption}
      />
      <div style={{ display: 'flex', margin: 10 }}>
        <div>
          <div>
            <strong><TranslateComponent>Start Date:</TranslateComponent></strong>
          </div>
          <ModalDatePickerInput
            isRequired={false}
            date={suppressionDates?.startDate}
            onChange={onStartChange}
            dateType="startDate"
            disableComponentErrorMessage={true}
            disableBeforeGivenDate={moment().format(BACKEND_DATE_FORMAT)}
          />
        </div>

        {suppressionOption === 'specify-date' && (
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong><TranslateComponent>End Date:</TranslateComponent></strong>
            </div>
            <ModalDatePickerInput
              isRequired={true}
              date={suppressionDates?.endDate}
              onChange={onEndChange}
              dateType="endDate"
              disableComponentErrorMessage={true}
              disableBeforeGivenDate={suppressionDates?.startDate}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}
