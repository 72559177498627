import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import './kpi.scss'
import { withTranslation } from 'react-i18next'
import { showCorrectPriority } from 'src/components/legacy/common/opportunity.js'
import colors from 'src/components/legacy/common/colors.json'
import IconSvg from 'src/components/legacy/components/icon-svg/icon'
import { Container, Content } from 'src/components/layouts'
import { TranslateComponent } from 'src/common/translations'

export const KPI_SETTINGS = (t) =>
  new Map([
    [
      'comfort',
      {
        icon: 'comfort-impact',
        name: 'Comfort',
        iconChar: '', // used for PDFs
        color: colors.COMFORT
      }
    ],
    [
      'reliability',
      {
        icon: 'reliability-impact',
        name: 'Reliability',
        iconChar: '', // used for PDFs
        color: colors.RELIABILITY
      }
    ],
    [
      'performance',
      {
        icon: 'performance-impact',
        name: 'Performance',
        iconChar: '', // used for PDFs
        color: colors.PERFORMANCE
      }
    ],
    [
      'energyUsage',
      {
        icon: 'energy-impact',
        name: 'Energy',
        iconChar: '', // used for PDFs
        color: colors.ENERGY_USAGE
      }
    ],
    [
      'compliance',
      {
        icon: 'compliance-impact',
        name: 'Compliance',
        iconChar: '', // used for PDFs
        color: colors.COMPLIANCE
      }
    ]
  ])

class KPI extends PureComponent {
  static propTypes = {
    kpi: PropTypes.array.isRequired,
    containerWidth: PropTypes.number
  }

  render() {
    const { kpi, t, containerWidth } = this.props
    const numberOfKPIs = kpi.filter(({ value }) => value).length
    const kpiSettings = KPI_SETTINGS(t)

    return numberOfKPIs ? (
      <Container
        className="kpi-block"
        xlColumns={6}
        lColumns={4}
        mColumns={3}
        sColumns={1}
        width={containerWidth}
      >
        {kpi.map(
          ({ name, custom, priority, value, savings }, i) =>
            value && (
              <Content
                width={containerWidth}
                key={i}
                className={`kpi ${
                  custom ? 'custom' : name
                } kpi-${numberOfKPIs} `}
              >
                <span className="name">
                  {!custom ? (
                    <TranslateComponent>{`${kpiSettings.get(name).name} Impact`}</TranslateComponent> 
                  ) : (
                    <ConditionalTooltip
                      type={ConditionalTooltip.TYPE.ICON}
                      conditional={true}
                      content={name.slice(0, 30)}
                      align={ConditionalTooltip.ALIGN.START}
                      multiline={true}
                    >
                      <TranslateComponent>{`${name} Impact`}</TranslateComponent>
                    </ConditionalTooltip>
                  )}
                </span>
                <IconSvg
                  name={
                    custom ? 'truck-rolls-impact' : kpiSettings.get(name).icon
                  }
                  color={colors.ORANGE_6}
                  hover={colors.ORANGE_6}
                  width="50px"
                  height="50px"
                  viewBox="0 0 65 65"
                  className="kpi-icons"
                  cursor="auto"
                />
                <span className="priority">
                  <TranslateComponent>{showCorrectPriority(priority, savings, t)}</TranslateComponent>
                </span>
              </Content>
            )
        )}
      </Container>
    ) : null
  }
}

export default withTranslation()(KPI)
