export const getSortTextByDataType = (type: string, isAscending: boolean) => {
  // Based on the data type and direction, return the matching "sort" text.
  switch (type) {
    case 'date':
      return isAscending ? 'Oldest first' : 'Newest first'
    case 'number':
      return isAscending ? 'Smallest first' : 'Largest first'
    default:
      return isAscending ? 'A-Z' : 'Z-A'
  }
}