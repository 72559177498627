import moment from "moment"
import { BACKEND_DATE_TIME_FORMAT } from "src/components/legacy/common/time-helpers"

// Check if offering is active based on the start date, end date, and grace period.
// Intention is that it would be done in the viewer's timezone, which is why this is being done in front end
// Also need to use BACKEND_DATE_TIME_FORMAT which will not use the trailing "Z" to parse the datetime as UTC
export const isOfferingActive = offering => {
  // No need for .startOf("day") or .endOf("day") because the contracts are already accounting for that
  const start = moment(offering.startDate, BACKEND_DATE_TIME_FORMAT)
  const end = moment(offering.endDate, BACKEND_DATE_TIME_FORMAT).add(offering.gracePeriod, "days")
  return moment().isBetween(start, end)
}
