import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StyledSpinner, NoImage, ImageChildren } from './image-preview.sc.js'
import i18next from 'i18next'
import ImageGalleryWrapper from '../image-gallery-wrapper/index.jsx'

export default class ImagePreview extends PureComponent {
  static propTypes = {
    src: PropTypes.string,
    caption: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node,
    isLazyLoading: PropTypes.bool,
    handleDownloadImage: PropTypes.func,
    items: PropTypes.array,
    isImageZooming: PropTypes.bool
  }
  static defaultProps = {
    src: '',
    loading: false,
    isLazyLoading: false,
    handleDownloadImage: null,
    items: null,
    isImageZooming: false
  }

  render() {
    const { src, caption, loading, children, items = null, isLazyLoading, handleDownloadImage, isImageZooming } = this.props
    const isChildren = children && Boolean(children)
    const image = loading ? (
      <StyledSpinner />
    ) : src || items ? (
      <ImageGalleryWrapper
        items={items ? items : [
          { original: src, originalTitle: '', description: caption ?? '' }
        ]}
        showPlayButton={false}
        showBullets={true}
        showThumbnails={false}
        isLazyLoading={isLazyLoading}
        isImageZooming={isImageZooming}
        handleDownloadImage={handleDownloadImage}
      />
    ) : (
      <NoImage>
        <span>{i18next.t('components:imagePreview>NoImage')}</span>
      </NoImage>
    )

    return (
      <>
        {image}
        {isChildren && <ImageChildren>{children}</ImageChildren>}
      </>
    )
  }
}
