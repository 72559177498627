import { useMemo } from 'react'
import Findings from 'src/models/findings'
import translate from 'src/common/translations'
import * as yup from 'yup'

export const useFormSchema = ({ values }: { values: Findings }) => {
  // THIS IS NOT COMPLETE
  // need an initial schema for testing
  const errors = {
    name: translate('Title is required'),
    location: translate('Location id is required'),
    organization: translate('Organization id is required'),
    savings: translate('Savings amount is required')
    // targetDate: translate('Please select a valid target date'),
    // proposalDate: translate('Please select a valid proposal date')
    // settings.kpi
  }

  const schema = useMemo(() => {
    return yup.object().shape({
      name: yup.string().required(errors.name).min(1).max(200),
      location: yup.object().shape({
        locationId: yup.string().required(errors.location)
      }),
      organization: yup.object().shape({
        organizationId: yup.string().required(errors.organization)
      }),
      settings: yup.object().shape({
        kpi: yup.array().of(
          yup.lazy((kpiValue) => {
            const { priority, value } = kpiValue
            // is value checked
            if (!value) {
              return yup.object().optional()
            }
            if (priority === 'specificValue') {
              return yup.object().shape({
                savings: yup.string().required(errors.savings).min(1),
                timePeriod: yup.string().default(() => 'one-time')
              })
            }
            return yup.object().shape({
              name: yup.string(),
              priority: yup.string(),
              value: yup.boolean()
            })
          })
        )
      })
      // targetDate: yup.date().required(errors.targetDate),
      // proposalDate: yup.date().required(errors.proposalDate),
    })
  }, [values])

  return { schema }
}
