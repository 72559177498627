import { PDF_PAGE_TYPES } from 'src/components/legacy/common/pdf'
import PDFGenerator from 'src/components/print-components/pdf-generator'
import PdfConsultationDetailView from './pdf-consultation-detail-view'
import { useAttachment } from 'src/hooks/attachment'
import { useEffect, useState } from 'react'

const ConsultationDetailDownload = (props) => {
  const { downloadData } = props
  const chartImages = null
  const { downloadFileURL } = useAttachment()
  const [pdfDownloadData, setPdfDownloadData] = useState(null)

  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId)
        .then((res) => {
          item.original = res
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
    return item
  }

  const fetchImageData = async () => {
    const DownloadDetail = downloadData
    // whatWeHaveDone Images
    const downloadHaveDoneImageData = []
    for (const image of DownloadDetail?.whatWeHaveDone) {
      let img = image
      if (!image?.original) {
        img = await fetchImage(image)
      }
      downloadHaveDoneImageData.push(img)
    }
    DownloadDetail.whatWeHaveDone = downloadHaveDoneImageData
    // whatWeHaveSaved Images
    const downloadHaveSavedImageData = []
    for (const image of DownloadDetail?.whatWeHaveSaved) {
      let img = image
      if (!image?.original) {
        img = await fetchImage(image)
      }
      downloadHaveSavedImageData.push(img)
    }
    DownloadDetail.whatWeHaveSaved = downloadHaveSavedImageData
    // whereWeHaveBeen Images
    const downloadHaveBeenImageData = []
    for (const image of DownloadDetail?.whereWeHaveBeen) {
      let img = image
      if (!image?.original) {
        img = await fetchImage(image)
      }
      downloadHaveBeenImageData.push(img)
    }
    DownloadDetail.whereWeHaveBeen = downloadHaveBeenImageData
    setPdfDownloadData(DownloadDetail)
  }

  const fetchImaging = async () => {
    await fetchImageData()
  }

  useEffect(() => {
    setPdfDownloadData(downloadData)
  }, [downloadData])

  return (
    <>
      {pdfDownloadData && (
        <PDFGenerator
          fileName={pdfDownloadData?.name}
          pageType={PDF_PAGE_TYPES.CUSTOM_PAGE}
          isAutoDownload={props?.isAutoDownload ?? false}
          onPreloadData={fetchImaging}
        >
          <PdfConsultationDetailView
            opportunities={pdfDownloadData?.opportunities}
            consultation={pdfDownloadData}
            isFooterCount={true}
            chartImage={chartImages}
            headerLocation={pdfDownloadData?.locations?.map(m => m?.locationName)?.join(", ")}
            headerOrganization={pdfDownloadData?.account?.name}
          />
        </PDFGenerator>
      )}
    </>
  )
}

export default ConsultationDetailDownload
