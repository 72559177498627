import { Button, Heading } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// Project imports
import { TranslateComponent } from 'src/common/translations'

// Denali imports
import { faClose } from 'src/denali-components/lib/pro-solid-svg-icons'

// Local imports
import styles from './dialog.module.scss'
import { DialogProps } from './types'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {}

export const Dialog = ({
  isOpen = true,
  onClose = emptyFunc,
  title = '',
  actions = null,
  headerActions = null,
  showHeader = true,
  className = '',
  width = null,
  height = null,
  children,
  ...props
}: DialogProps) => {
  const dialog = useRef(null)
  const [t] = useTranslation()
  useEffect(onOpenChange(isOpen, dialog), [isOpen])
  // Get possible CSS props to pass to set height/width if needed.
  const inlineStyles: CSSProperties = {}
  if (width) {
    inlineStyles.width = width
  }

  if (height) {
    inlineStyles.height = height
  }
  return (
    <dialog
      className={`denali-dialog ${styles.dialog} ${className}`}
      ref={dialog}
      onCancel={onClose}
      data-testid={props?.['data-testid']}
      style={inlineStyles}
    >
      {showHeader && (
        <div className={styles.title}>
          <div className={styles.titleHeading}>
            <Heading level={2} className={`${styles.titleHeadline} h4`}>
              <TranslateComponent>{title}</TranslateComponent>
            </Heading>
          </div>
          <div className={styles.titleButtons}>
            {headerActions}
            <Button
              onClick={onClose}
              aria-label={t('common:Close')}
              className={styles.closeButton}
              data-testid="dialogClose"
            >
              <FontAwesomeIcon icon={faClose} width={16} height={16} />
            </Button>
          </div>
        </div>
      )}

      {children}

      {actions && <div className={styles.footer}>{actions}</div>}
    </dialog>
  )
}

function onOpenChange(isOpen, dialog) {
  return () => {
    if (!dialog.current) return
    isOpen ? dialog.current?.showModal() : dialog.current?.close()
  }
}
