import translate, { TranslateComponent } from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotes } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { Button } from '@aws-amplify/ui-react'
import { ACTIONS } from 'src/constants'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import { NotesList } from 'src/denali-pages/Notes/NotesList'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import NoteDetails from 'src/pages/notes/NoteDetails/NoteDetails'
import { PageError } from 'src/denali-components/PageError/PageError'
import { CreateNoteButton } from './CreateNoteButton'

export const NotesContainer = ({
  rows,
  location,
  search,
  searchFields,
  isDenaliTable,
  testName,
  isFilterChange,
  listDataIsLoading,
  userInfo,
  userAccess,
  optionsTranslated,
  tableHandlerOptions,
  deleteNote,
  setDeleteNote,
  notesDeletionMutation,
  mode,
  setMode,
  notesInput,
  setNotesInput,
  initialValues,
  refetchData1,
  buildingData,
  downloadFileFunc,
  addFileFunc
}) => {
  const { organizationId } = getSearchParams()
  const hideModalDialog = () => {
    setDeleteNote(null)
  }

  return (
    <Page
      title={translate('Notes')}
      icon={<FontAwesomeIcon icon={faNotes} />}
      iconColor={IconColor.orange}
      actions={[
        <AccessControl id={'tc.pages.notes.add'} key="createNote">
          <CreateNoteButton
            setMode={setMode}
            setNotesInput={setNotesInput}
            initialValues={initialValues}
            userInfo={userInfo}
          />
        </AccessControl>
      ]}
    >
      {/* Existing Note Details Modal */}
      {mode && (
        <NoteDetails
          testName={`notes${notesInput?.id ? `_${notesInput.id}` : ''}`}
          refetchList={() => refetchData1({ id: location })}
          userInfo={userInfo}
          buildingId={location}
          setNotesInput={(i) => setNotesInput(i)}
          initialValues={initialValues}
          notesInput={notesInput}
          buildingName={buildingData?.name}
          currentUser={userInfo?.id}
          mode={mode}
          setMode={(mode) => setMode(mode)}
          downloadFileFunc={(e) => downloadFileFunc(e)}
          addFileFunc={(e) => addFileFunc(e)}
        />
      )}
      {location ?? null ? (
        <NotesList
          rows={rows}
          rowAction={rowActionCallback(
            setMode,
            setNotesInput,
            initialValues,
            userInfo
          )}
          search={search}
          searchFields={searchFields}
          isDenaliTable={isDenaliTable}
          testName={testName}
          listDataIsLoading={listDataIsLoading}
          noTableDataAction={
            <CreateNoteButton
              setMode={setMode}
              setNotesInput={setNotesInput}
              initialValues={initialValues}
              userInfo={userInfo}
            />
          }
          isFilterChange={isFilterChange}
          optionsTranslated={optionsTranslated}
          tableHandlerOptions={tableHandlerOptions}
          userInfo={userInfo}
          userAccess={userAccess}
          setDeleteNote={setDeleteNote}
          mode={mode}
          setMode={setMode}
          notesInput={notesInput}
          setNotesInput={setNotesInput}
          initialValues={initialValues}
        />
      ) : (
        <PageError
          buildingId={location}
          organizationId={organizationId}
          message={''}
        />
      )}

      {deleteNote ? (
        <DialogConfirm
          onAgree={() =>
            setTimeout(
              () => deleteNoteHandler(deleteNote, notesDeletionMutation),
              500
            )
          }
          title={tableHandlerOptions?.deleteNote}
          onClose={hideModalDialog}
        >
          <TranslateComponent>
            Are you sure you want to delete {deleteNote?.['name']}
          </TranslateComponent>
        </DialogConfirm>
      ) : null}
    </Page>
  )
}

const deleteNoteHandler = (deleteNote, notesDeletionMutation) => {
  notesDeletionMutation({
    input: JSON.stringify({
      modelType: 'Note',
      modelData: {
        delete: {
          id: deleteNote?.['id']
        }
      }
    })
  })
}

const rowActionCallback = (setMode, setNotesInput, initialValues, userInfo) => {
  return (event, row) => {
    setMode(ACTIONS.VIEW)
    setNotesInput({
      ...initialValues,
      id: row.id,
      name: row.name,
      type: row?.type,
      userId: row?.user?.id,
      currentUserId: userInfo?.id,
      equipment: row?.equipment,
      createdAtDetailTimestamp: row?.createdAtDetailTimestamp,
      createdByName: `${row?.user?.firstName ? row.user.firstName : ''} ${
        row?.user?.lastName ? row.user.lastName : ''
      }`,
      histories: row?.histories?.items,
      attachments: row?.attachments?.items.map((item) => ({
        ...item,
        fileName: item.name,
        filePath: `${item.buildingId}/${item.name}`,
        fileSize: item.sizeInBytes,
        title: item.title,
        timestamp: item.attachedAt,
        buildingId: item.buildingId,
        description: item.description
      }))
    })
  }
}
