import {
  Flex,
  Input,
  Label,
  RadioGroupField,
  View
} from '@aws-amplify/ui-react'
import { useCallback, ChangeEvent, ChangeEventHandler } from 'react'

// Project imports
import { TranslateComponent } from 'src/common/translations'
import { BuildingId } from 'src/components/buildingNavigation/graphql'
import { MODAL_TYPE } from 'src/pages/issuesFindings/add-opportunities-to-finding'
import OpportunityTablePicker from 'src/pages/issuesFindings/opportunity-table-picker/index'

// Denali imports
import {
  faGrid2Plus,
  faPlus
} from 'src/denali-components/lib/pro-solid-svg-icons'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { CardRadio } from 'src/denali-components/Form/CardRadio'
import { SelectionWrapper } from 'src/denali-components/SelectionWrapper/SelectionWrapper'

// Local imports
import { useIssuesAndFindingsAddToOpportunityDialogContext } from '../hooks/use-issues-findings-add-to-opportunity-dialog-context'

export const IssuesAndFindingsAddToOpportunityDialogBody = () => {
  const {
    accountId,
    buildingId,
    onChange,
    handleLoading: _,
    selectedItems,
    modalType,
    setOptionType,
    opportunityName,
    opportunityNameError,
    setOpportunityName
  } = useIssuesAndFindingsAddToOpportunityDialogContext()

  const handleOpportunityNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value
      setOpportunityName(name ?? '')
    },
    [setOpportunityName]
  )

  const radioOnChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOptionType(event.target.value)
    },
    [setOptionType]
  )

  const newOpportunityInputBuilder = useCallback(
    (modalType) => {
      return buildNewOpportunityInput(
        modalType,
        opportunityName,
        opportunityNameError,
        handleOpportunityNameChange
      )
    },
    [opportunityName, opportunityNameError, handleOpportunityNameChange]
  )

  const tablePickerBuilder = useCallback(
    (
      _: [],
      selectedItems: [],
      onChange: (item: NonNullable<unknown>) => void
    ) => {
      return buildTablePicker(accountId, buildingId, selectedItems, onChange)
    },
    [, accountId, buildingId]
  )

  switch (modalType) {
    case MODAL_TYPE.EXISTING:
      return (
        <SelectionWrapper
          selectedItems={selectedItems}
          onSelectedItemsChange={onChange}
          itemBuilder={tablePickerBuilder}
          keyExtractor={tablePickerKeyExtractor}
          nameExtractor={tablePickerNameExtractor}
          styles={{
            minWidth: '800px'
          }}
        />
      )
    default:
      return (
        <Flex direction="column" justifyContent="center" alignItems="stretch">
          <RadioGroupField
            className={formStyles.cardRadioGroup}
            name="modalType"
            label="Modal Type"
            labelHidden={true}
            onChange={radioOnChangeHandler}
            disabled={modalType !== MODAL_TYPE.INITIAL}
          >
            <Flex direction="row" justifyContent="spaceBetween">
              <View flex="1 1 50%">
                <CardRadio
                  name="Create a new blank Opportunity"
                  value={MODAL_TYPE.NEW}
                  icon={faPlus}
                  color="blue"
                  disabled={modalType !== MODAL_TYPE.INITIAL}
                />
              </View>
              <View flex="1 1 50%">
                <CardRadio
                  name="Add to an existing Opportunity"
                  value={MODAL_TYPE.EXISTING}
                  icon={faGrid2Plus}
                  color="purple"
                  disabled={modalType !== MODAL_TYPE.INITIAL}
                />
              </View>
            </Flex>
          </RadioGroupField>
          {newOpportunityInputBuilder(modalType)}
        </Flex>
      )
  }
}

const tablePickerKeyExtractor = (item: { key: string; value: string }) =>
  item.key
const tablePickerNameExtractor = (item: { key: string; value: string }) =>
  item.value

const buildTablePicker = (
  accountId: string,
  buildingId: BuildingId,
  selectedItems: [],
  onChange: (item: NonNullable<unknown>) => void
) => {
  return (
    <OpportunityTablePicker
      accountId={accountId}
      buildingId={buildingId}
      onSelect={onChange}
      selectedItems={selectedItems}
      isDenaliTable={true}
    />
  )
}

const buildNewOpportunityInput = (
  modalType: string,
  opportunityName: string,
  opportunityNameError: string,
  handleOpportunityNameChange: ChangeEventHandler
): React.ReactNode => {
  if (modalType !== MODAL_TYPE.NEW) {
    return <></>
  }

  return (
    <Flex direction="column" alignItems="stretch">
      <Label htmlFor="newOpportunityName">
        <TranslateComponent>Opportunity Name</TranslateComponent>
      </Label>
      <Input
        id="newOpportunityName"
        name="newOpportunityName"
        aria-label="New Opportunity name"
        onChange={handleOpportunityNameChange}
        value={opportunityName}
      />
      {opportunityNameError.length > 0 && <span>{opportunityNameError}</span>}
    </Flex>
  )
}
