import translate, { TranslateComponent } from 'src/common/translations'
import { AccessControl } from 'src/components/accessControl'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { Button } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersUp } from 'src/denali-components/lib/pro-solid-svg-icons'
import { BuildingSetupList } from 'src/denali-pages/BuildingSetup/BuildingSetupList'
import { useNavigate } from 'react-router-dom'

export const BuildingSetupContainer = ({
  sortDirection,
  onSortChange,
  buildings
}) => {
  const navigate = useNavigate()

  return (
    <Page
      title={translate('Building Setup')}
      icon={<FontAwesomeIcon icon={faSlidersUp} />}
      iconColor={IconColor.cobolt}
      testid="building-setup-page"
      actions={[
        <AccessControl id="tc.pages.building-setup.add" key="create">
          <Button
            data-testid="CreateBuildingButton"
            ariaLabel="Create Building"
            onClick={() => navigateToCreateBuilding(navigate)}
          >
            <TranslateComponent>Create Building</TranslateComponent>
          </Button>
        </AccessControl>
      ]}
    >
      <BuildingSetupList
        sortDirection={sortDirection}
        onSortChange={onSortChange}
        buildings={buildings}
      />
    </Page>
  )
}

const navigateToCreateBuilding = (navigate) => {
  navigate(`/building-setup/add`)
}
