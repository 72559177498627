import { useTranslation } from 'react-i18next'
import { useState, useCallback, useEffect } from 'react'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import {
  BUILDING,
  EQUIPMENT_TYPE,
  EQUIPMENT
} from 'src/components/object-picker/helpers.js'
import ObjectPickerWrapper from 'src/components/object-picker'
import { BaselineContainer, SelectedBuildingWrapper } from './styles'
import { BaselineOverview } from './BaselineOverview'
import { BaselineTimeFrameAndExclusions } from './BaselineTimeframeAndExclusions'
import { ACTIONS, getFormattedResponseData } from './helpers'
import { useBaseLinesContext } from '../BaseLinesContextProvider'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT,
  DATE_FORMAT_DASH
} from 'src/components/legacy/common/time-helpers'
import moment from 'moment/moment'
import { TranslateComponent } from 'src/common/translations'

export const getEquipment = /* GraphQL */ `
  query getEquipment($id: ID!) {
    getEquipment(id: $id) {
      createdAt
    }
  }
`

export const BaselineForm = ({ formik, activeTab, setIsTriggerValidation }) => {
  const {
    data: Equipment,
    refetch: refetchEquipment,
    loading: loadingEquipmentDetails
  } = useQuery({
    query: getEquipment,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getEquipment'
  })

  const { mode, baseLine, baselines, setDataAvailablityDates } =
    useBaseLinesContext()

  const { setFieldValue, values } = formik

  const { width } = useBreakPoint()
  const [t] = useTranslation()

  const handleObjectPickerChange = async (items) => {
    if (items?.length > 0) {
      const pickerValue = items[0] ?? {}
      if (pickerValue?.equipmentId) {
        let response
        const res = await refetchEquipment({
          id: pickerValue?.equipmentId
        })
        setDataAvailablityDates({
          startDate: moment(res?.createdAt)?.format(DATE_FORMAT_DASH),
          endDate: moment().subtract(1, 'day').format(DATE_FORMAT_DASH)
          // endDate: moment().format(DATE_FORMAT_DASH)
        })
      }
      setFieldValue('equipmentDetails', pickerValue)
    } else {
      setFieldValue('equipmentDetails', null)
    }
  }

  async function getEquipmentCreatedDate() {
    if (mode !== ACTIONS.ADD && values?.equipmentDetails?.equipmentId) {
      const res = await refetchEquipment({
        id: values?.equipmentDetails?.equipmentId
      })

      setDataAvailablityDates({
        startDate: moment(res?.createdAt)?.format(DATE_FORMAT_DASH),
        endDate: moment().subtract(1, 'day').format(DATE_FORMAT_DASH)
      })
    }
  }

  // Call this on EDIT / VIEW mode
  useEffect(() => {
    getEquipmentCreatedDate()
  }, [mode, values])

  const updateExclusionList = (exclusionsList) => {
    setFieldValue('exclusions', exclusionsList)
  }

  const getBaselineEquipmentIds = () => {
    let equipmentIds = []
    if (baselines && baselines?.length > 0) {
      equipmentIds = baselines.map((x) => x?.equipmentId)
      return equipmentIds.filter((x) => x)
    }
  }

  return (
    <BaselineContainer>
      <ContainerWrapper
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        background="white"
        padding="10px"
      >
        <div className={`${activeTab === 1 ? 'hide-picker' : ''}`}>
          <Content
            xlColumn={1}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
            background="#fff"
          >
            <div className="custom-object-picker">
              {mode === ACTIONS.EDIT ? (
                <div className="selected-equipment-container-edit">
                  <p>
                    <TranslateComponent>Please note: when a baseline is edited, the baseline object
                    cannot be changed.</TranslateComponent>
                  </p>
                  <p><TranslateComponent>Selected</TranslateComponent>:</p>
                  <span>
                    <strong>{baseLine?.baselineEquipment?.name}</strong>
                  </span>
                </div>
              ) : (
                <ObjectPickerWrapper
                  objects={['', BUILDING, EQUIPMENT_TYPE, EQUIPMENT]}
                  initialValues={getFormattedResponseData(values?.objectPicker)}
                  update={handleObjectPickerChange}
                  equipmentSelectLimit={1}
                  error={false}
                  baselineEquipments={getBaselineEquipmentIds()}
                  mode={ACTIONS.EDIT}
                  isEnergyWidget={true}
                />
              )}
            </div>
          </Content>
        </div>
        {activeTab === 0 ? (
          <Content
            xlColumn={11}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <BaselineOverview
              formik={formik}
              mode={mode}
              activeTab={activeTab}
              setIsTriggerValidation={setIsTriggerValidation}
            />
          </Content>
        ) : (
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <BaselineTimeFrameAndExclusions
              activeTab={activeTab}
              exclusions={values?.exclusions || []}
              updateExclusionList={updateExclusionList}
            />
          </Content>
        )}
      </ContainerWrapper>
    </BaselineContainer>
  )
}
