import { isFinite } from 'lodash'
import moment from 'moment'
import store from 'src/redux/store'
import {getDateFormatByLocale} from 'src/common/chartHelperFunctions'

const formatDateinMoment = (date) => {
  const expectedDateFormat = 'dddd, MMMM D, YYYY hh:mm A'
  const isValid = moment(date).isValid()
  if (isValid) {
    return moment(date).format(getDateFormatByLocale(expectedDateFormat))
  }
  return date
}

export function initTooltipDataFormatter(xFormatter) {
  return function formatter(data: HeatMapData): TooltipData[] {
    if (data === null) return []

    const d = (data.nonPrimaryData.slice(-1) || [])[0]

    const dateTime = (d.timeStamp || '').slice(0)

    return [
      {
        items: [
          // date time
          {
            content: xFormatter(new Date(dateTime))
          },
          // value
          {
            content: data.value
          },

          // color
          {
            content: data.color
          },
          // [legend, rawData]
          ...data.nonPrimaryData.map((d) => ({
            content: d
          }))
        ]
      }
    ]
  }
}

export default function TooltipRenderer({ data }: { data: TooltipData[] }) {
  const { items = [] } = data[0] || {}
  const selectedLanguage = store?.getState().appData?.selectedLanguage;
  const dateTime = selectedLanguage === 'en' ? items[0]?.content || '' : formatDateinMoment(items[0]?.content)
  const value = isFinite(Number(items[1]?.content))  ? Number(items[1]?.content) : ''
  const color = items[2]?.content || ''
  const legend = items[3]?.content || ''
  const unit = typeof value === 'string' ? '' : items[4]?.content?.unit
  
  return (
    <div className="heat-map-tooltip">
      <p className="title">{dateTime}</p>
      <div className="item">
        <span className="icon" style={{ background: color }}></span>
        {legend}:{' '}
        <span className="value">
        {value === '' ? "No data" : `${value} ${unit}`}
        </span>
      </div>
    </div>
  )
}
