import translate, { TranslateComponent } from 'src/common/translations'
import styles from './table.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

/**
 * DataError
 * Error component for table data
 */
export const DataError = ({ tableName }) => {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.error}>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        <span className={styles.errorText}>
          <TranslateComponent>
            Error loading {tableName} data
          </TranslateComponent>
        </span>
      </div>
    </div>
  )
}
