import moment from 'moment'
import * as yup from 'yup'
import { ACTIONS } from 'src/constants'
import store from 'src/redux/store';

const REQUIRED = 'required';

const normalizeDate=(date, selectedLanguage = 'en')=>{
  return moment(date, 'YYYY-MM-DD',true).isValid() ?  moment(date, 'YYYY-MM-DD')?.format('MM/DD/YYYY'): (selectedLanguage === 'en'? date: moment(date).locale('en').format("MM/DD/YYYY"));
}

const schema = (listEnabled, rows, mode) => {
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";

return (
    yup.object().shape({
    name: yup.string().required(ERROR_MESSAGES.markerName),
    description: yup.string().notRequired(),
    type: yup.string().required(REQUIRED),
    markerDate: yup
      .string()
      .required(REQUIRED)
      .test(
        'event-marker-date-test',
        ERROR_MESSAGES.duplicateDate,
        function (item) {
          if (listEnabled) {
            return !rows
              ?.filter((r) => r?.id !== this.parent.id)
              ?.map((r) => normalizeDate(r?.markerDate, selectedLanguage))
              ?.includes(selectedLanguage === 'en' ? item : moment(item).locale('en').format("MM/DD/YYYY"))
          } else {
            return !rows
              ?.filter((r) => r?.id !== this.parent.id)
              ?.map((r) => normalizeDate(r?.markerDate, selectedLanguage))
              ?.includes(selectedLanguage === 'en' ? item : moment(item).locale('en').format("MM/DD/YYYY"))
          }
        }
      )
      .test(
        'event-marker-date-before',
        ERROR_MESSAGES.futureDate,
        function (item) {
          const today = moment()
          return moment(item, 'MM/DD/YYYY').isSameOrBefore(today)
        }
      ),
    buildingId:
      (listEnabled && mode !== ACTIONS.EDIT) || ACTIONS.ADD
        ? yup.string().notRequired()
        : yup.string().required(REQUIRED),
    buildingsList: listEnabled
      ? yup.array().min(1).required(REQUIRED)
      : yup.array().notRequired()
  })
)
}

export const ERROR_MESSAGES = {
  duplicateDate: 'Each location can only have one marker per date',
  invalidDate: 'Please enter a valid date',
  futureDate: 'Please enter a date on or before today',
  markerName: 'Please enter a marker name'
}

export default schema
