import {
  Button,
  Fieldset,
  Flex,
  Input,
  Label,
  SelectField
} from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { UseFormReset } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Project imports
import { getSearchParams } from 'src/common/helperFunctions'
import translate, { TranslateComponent } from 'src/common/translations'
import Opportunity from 'src/models/opportunity'
import { selectUiMode } from 'src/redux/slicers/appData'
import { useAppDispatch, useAppSelector } from 'src/redux/store'
import {
  selectOpportunityData,
  setOpportunity,
  setOpportunityTisObjects
} from 'src/redux/slicers/formData'

// Legacy imports
import {
  NEXT_STEPS_OPTION_LIST,
  STATUS_OPTION_LIST
} from 'src/components/legacy/common/finding'
import { PRIORITY_LIST } from 'src/components/legacy/common/opportunity'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

// Denali components
import { DenaliAssignedTo } from 'src/denali-components/Form/DenaliAssignedTo'
import { DenaliFileUpload } from 'src/denali-components/Form/DenaliFileUpload'
import { DenaliForm } from 'src/denali-components/Form/DenaliForm'
import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import { DenaliFormElementError } from 'src/denali-components/Form/DenaliFormElementErrors'
import DenaliImpactCard from 'src/denali-components/Form/DenaliImpactCard'
import { DenaliFormSubmit } from 'src/denali-components/Form/DenaliFormSubmit'
import { DenaliSupportingImages } from 'src/denali-components/Form/DenaliSupportingImages'
import { DenaliFormTextEditor } from 'src/denali-components/Form/DenaliFormTextEditor'
import { InlineBuildingSelector } from 'src/denali-components/Form/InlineBuildingSelector'
import { InlineBuildingSelectorContextProvider } from 'src/denali-components/Form/InlineBuildingSelectorContext'
import { InlineEquipmentSelector } from 'src/denali-components/Form/InlineEquipmentSelector'
import { InlineEquipmentSelectorContextProvider } from 'src/denali-components/Form/InlineEquipmentSelectorContext'
import { getImpactData } from 'src/denali-components/Form/helpers'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { useInlineBuilding } from 'src/denali-components/Form/hooks/use-inline-building'
import { useInlineEquipment } from 'src/denali-components/Form/hooks/use-inline-equipment'
import { faBullseyeArrow } from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  Page,
  BackgroundColor,
  IconColor
} from 'src/denali-components/Page/Page'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'
import { OpportunityCreateFormFinancials } from 'src/denali-pages/Opportunities/OpportunityCreateFormFinancials'

// Local imports
import { SelectVisibility } from '../../denali-components/Form/SelectVisibility'
import { ManageOpportunityFormProps } from './types.d'
import { useOpportunitySchema } from './hooks/use-opportunity-schema'
import { DenaliLoader } from 'src/denali-components/Loaders/DenaliLoader'
import { PROPOSAL_TYPE_FILE } from 'src/components/file-upload/file-upload'

export const ManageOpportunityForm = ({
  userInfo,
  onSubmit,
  initialValues
}: ManageOpportunityFormProps) => {
  const { buildingId: locationId } = getSearchParams()
  const values = useAppSelector(selectOpportunityData)

  // Building/Equipment selectot stuff
  const {
    equipments,
    isEquipmentLoading,
    selectedTisObjects,
    setSelectedTisObjects,
    resetSearch: equipmentResetSearch,
    searchTerm: equipmentSearchTerm,
    setSearchTerm: setEquipmentSearchTerm,
    activeScroll: equipmentActiveScroll,
    setActiveScroll: setEquipmentActiveScroll,
    equipmentGroup
  } = useInlineEquipment({
    locationId: values?.location?.locationId ?? locationId
  })
  const {
    buildings,
    iconColors,
    searchBuildings,
    setSearchTerm,
    buildingsGroup,
    activeScroll,
    setActiveScroll,
    resetSearch
  } = useInlineBuilding(
    values?.organization?.organizationId,
    values?.location?.locationId
  )
  const uiMode = useSelector(selectUiMode)
  const schema = useOpportunitySchema()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const translatedTitlePlaceholder = translate(
    'Enter the title for the opportunity'
  )
  const translatedPageTitle = translate('Create Opportunity')
  const translatedInputPlaceholder = translate('Start typing here...')

  // the browswer will localize the display of date values
  // Diverging from the universal YYYY-MM-DD problematic.
  const sanitizedInitialValues = useMemo(() => {
    const costOfWaiting = initialValues.settings.costSavings.costOfWaiting
    const formattedCostOfWaitingDate = costOfWaiting.startDate
      ? moment(costOfWaiting.startDate).format(BACKEND_DATE_FORMAT)
      : ''

    return {
      ...initialValues,
      settings: {
        ...initialValues.settings,
        proposalDate: moment(initialValues.settings.proposalDate).format(
          BACKEND_DATE_FORMAT
        ),
        costSavings: {
          ...initialValues.settings.costSavings,
          costOfWaiting: {
            ...costOfWaiting,
            startDate: formattedCostOfWaitingDate
          }
        }
      }
    }
  }, [initialValues])

  const submitCallback = useCallback(
    async (_: Opportunity, reset: UseFormReset<Opportunity>) => {
      // Since we are calculating stuff in Redux, let's pass values from Redux to the passed handler.
      await onSubmit(values, { resetForm: reset })
      //  this addresses a very specific bug when transitioning from detail view to edit after creating a form
      dispatch(setOpportunity(null))
    },
    [values]
  )

  const handleCancel = () => {
    dispatch(setOpportunity(null))
    navigate(`/${PAGE_NAMES.OPPORTUNITIES}`)
  }

  const clearTisObjects = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== values.location.locationId) {
        dispatch(setOpportunityTisObjects([]))
      }
    },
    [values]
  )

  useEffect(() => {
    dispatch(setOpportunity(sanitizedInitialValues))
    // objects need to actually be different
  }, [JSON.stringify(sanitizedInitialValues)])

  if (!values) {
    return <DenaliLoader />
  }

  return (
    <DenaliForm
      id="denali-form-opportunities-new"
      className={formStyles.denaliForm}
      defaultValues={sanitizedInitialValues}
      schema={schema}
      onSubmit={submitCallback}
      updateReduxStore
      updateReduxStoreAction={setOpportunity}
    >
      <InlineBuildingSelectorContextProvider
        buildings={buildings}
        iconColors={iconColors}
        searchBuildings={searchBuildings}
        setSearchTerm={setSearchTerm}
        buildingsGroup={buildingsGroup}
        activeScroll={activeScroll}
        setActiveScroll={setActiveScroll}
        resetSearch={resetSearch}
      >
        <InlineEquipmentSelectorContextProvider
          equipments={equipments}
          isEquipmentLoading={isEquipmentLoading}
          selectedTisObjects={selectedTisObjects}
          setSelectedTisObjects={setSelectedTisObjects}
          resetSearch={equipmentResetSearch}
          searchTerm={equipmentSearchTerm}
          setSearchTerm={setEquipmentSearchTerm}
          activeScroll={equipmentActiveScroll}
          setActiveScroll={setEquipmentActiveScroll}
          equipmentGroup={equipmentGroup}
        >
          <Page
            title={translatedPageTitle}
            icon={<FontAwesomeIcon icon={faBullseyeArrow} />}
            iconColor={IconColor.orange}
            testid="create-opportunity-page"
            backgroundColor={BackgroundColor.invert}
            actions={[
              <Button
                key="cancel"
                onClick={handleCancel}
                className={formStyles.cancelButton}
              >
                <TranslateComponent>Cancel</TranslateComponent>
              </Button>,
              <DenaliFormSubmit testName="Opportunity_save-button" key="submit">
                <TranslateComponent>Save</TranslateComponent>
              </DenaliFormSubmit>
            ]}
          >
            <hr />
            <Flex className={formStyles.titleRow}>
              {/* Left side col */}
              <Flex
                direction={'column'}
                overflow={'hidden'}
                flex={'1 1 0%'}
                className={formStyles.titleColumn}
              >
                <Label htmlFor={'name'} className={formStyles.lrgLabel}>
                  <TranslateComponent>Title</TranslateComponent>*
                  <DenaliFormElement name="name">
                    <Input
                      id={'name'}
                      data-testid={'manageOpportunity-name'}
                      value={values?.name}
                      placeholder={translatedTitlePlaceholder}
                      className={formStyles.titleInput}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name={'name'} />
                </Label>
                {/* Select a building* */}
                {/* Search */}
                <Fieldset legendHidden legend variation="outlined">
                  <Fieldset
                    legend={'Inline Building Selector'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                    className={formStyles.selectorContainer}
                  >
                    <DenaliFormElement name="location.locationId">
                      <InlineBuildingSelector
                        value={values?.location?.locationId}
                        onAfterChange={clearTisObjects}
                      />
                    </DenaliFormElement>
                  </Fieldset>
                  <DenaliFormElementError name={'location.locationId'} />

                  <hr className={formStyles.inlineSubSectionSpacer} />

                  {/* Select an equipment */}
                  <Fieldset
                    legend={'Inline Equipment Selector'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                    className={formStyles.selectorContainer}
                  >
                    <DenaliFormElement
                      name={'tisObjects'}
                      customOnChange={true}
                    >
                      <InlineEquipmentSelector value={values?.tisObjects} />
                    </DenaliFormElement>
                  </Fieldset>
                </Fieldset>
              </Flex>
              {/* END Left side col */}
              {/* Right Side col */}
              <Flex
                direction={'column'}
                flex={'0 0 340px'}
                className={formStyles.titleColumn}
              >
                <div className={formStyles.emptyLabelSpacer}></div>

                <SelectVisibility
                  visibility={values?.isPublic}
                  name={'isPublic'}
                />

                {/* CARD section */}
                <Fieldset
                  legend={'Created'}
                  legendHidden
                  variation={'outlined'}
                  direction={'column'}
                >
                  <Label htmlFor={'createdBy'} className={formStyles.smlLabel}>
                    <TranslateComponent>Created by</TranslateComponent>

                    <Fieldset
                      legend="User"
                      legendHidden
                      className={formStyles.userBubble}
                    >
                      <UserNameBubble
                        firstName={userInfo?.firstName}
                        lastName={userInfo?.lastName}
                        subLine={userInfo?.type.name}
                        showFullName
                      />
                    </Fieldset>
                    <DenaliFormElement
                      name="createdBy"
                      className={formStyles.smlLabel}
                    >
                      <Input
                        type="hidden"
                        name="createdBy"
                        data-testid="createdByField"
                        placeholder={translatedInputPlaceholder}
                        autoComplete=""
                        value={`${userInfo?.firstName} ${userInfo?.lastName}`}
                      />
                    </DenaliFormElement>
                  </Label>
                  {/* TODO: Autofill with today's date */}
                  <Label htmlFor="proposalDate" className={formStyles.smlLabel}>
                    <TranslateComponent>Created Date</TranslateComponent>
                    <DenaliFormElement name="settings.proposalDate">
                      <Input
                        id={'proposalDate'}
                        type="date"
                        name="settings.proposalDate"
                        data-testid="proposalDateField"
                        value={values?.settings?.proposalDate}
                      />
                    </DenaliFormElement>
                    <DenaliFormElementError name="settings.proposalDate" />
                  </Label>
                </Fieldset>
              </Flex>
              {/* END Right side Col */}
            </Flex>

            <hr />
            <OpportunityCreateFormFinancials values={values} />
            <hr />
            {/* new row */}
            {/* Cards */}
            <Flex direction={'column'} className={formStyles.sectionRoot}>
              <Flex className={formStyles.impactContainer}>
                {values.settings.kpi?.map(
                  ({ name, value, priority, custom }, index) => {
                    const { icon, color, label } = getImpactData(
                      custom ? 'custom' : name
                    )

                    return (
                      <DenaliImpactCard
                        key={`kpi-option-${index}`}
                        index={index}
                        name={name}
                        value={value}
                        priority={priority}
                        showSpecificValue={false}
                        custom={custom}
                        cardLabel={label}
                      >
                        <div
                          data-color={color}
                          className={formStyles.impactIcon}
                        >
                          <FontAwesomeIcon icon={icon} size={'lg'} />
                        </div>
                      </DenaliImpactCard>
                    )
                  }
                )}
              </Flex>
              <Flex className={formStyles.settingsRow}>
                {/* Description */}
                <Fieldset
                  className={formStyles.descriptionContainer}
                  legendHidden
                  legend={'Description section'}
                  direction={'column'}
                  variation={'outlined'}
                >
                  <Label htmlFor={'settingsDescription'}>
                    <TranslateComponent>Description</TranslateComponent>
                  </Label>
                  <DenaliFormElement
                    id={'settingsDescription'}
                    name={'settings.description'}
                    customOnChange={true}
                  >
                    <DenaliFormTextEditor
                      uiMode={uiMode}
                      value={values?.settings?.description}
                    />
                  </DenaliFormElement>
                </Fieldset>
                {/* Supporting Images and Findings */}
                <Fieldset
                  legend={'Supporting Images'}
                  legendHidden
                  variation={'outlined'}
                  direction={'row'}
                  className={formStyles.imageContainer}
                >
                  <Flex direction="column" gap="12" flex="1 1 49%">
                    <Label htmlFor={'supportingImages'}>
                      <TranslateComponent>Supporting Images</TranslateComponent>
                    </Label>
                    <DenaliFormElement
                      id="supportingImages"
                      name={'settings.images'}
                      customOnChange={true}
                    >
                      <DenaliSupportingImages
                        value={values?.settings?.images}
                        findings={[]}
                        organizationId={values?.organization?.organizationId}
                        locationId={values?.location?.locationId}
                        showAddFindingButton={false}
                        uiMode={uiMode}
                      />
                    </DenaliFormElement>

                    <Flex direction="column" gap="0" width="100%">
                      <Label htmlFor={'findings'}>
                        <TranslateComponent>Findings</TranslateComponent>
                      </Label>
                      <DenaliFormElement
                        id="findings"
                        name={'findings'}
                        customOnChange={true}
                      >
                        <DenaliSupportingImages
                          value={[]}
                          findings={values?.findings}
                          organizationId={values?.organization?.organizationId}
                          locationId={values?.location?.locationId}
                          showAddFindingButton={true}
                          showAddImageButton={false}
                          uiMode={uiMode}
                        />
                      </DenaliFormElement>
                    </Flex>
                  </Flex>
                </Fieldset>
              </Flex>

              <Fieldset
                legend={'Attachement Section'}
                legendHidden
                className={formStyles.attatchmentSectionRow}
                variation="outlined"
              >
                <Fieldset
                  className={formStyles.attachmentContainer}
                  legend="Attach Files"
                  legendHidden
                  direction="column"
                >
                  <Label htmlFor="attachFile">
                    <TranslateComponent>Attach file</TranslateComponent>
                  </Label>
                  <DenaliFormElement
                    id={'attachFile'}
                    name={'settings.file'}
                    customOnChange={true}
                  >
                    <DenaliFileUpload
                      location={values?.location?.locationId}
                      value={values?.settings?.file}
                      allowedFileTypes={PROPOSAL_TYPE_FILE}
                      allowMultipleFiles={false}
                      includeDescription={true}
                      showOnlyAcceptableFileDesc={true}
                      showFileBadgeDate={true}
                      showFileBadgePreviewButton={false}
                      showFileBadgeDeleteButton={true}
                      fileBadgeDownloadStyle={''}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="settings.file" />
                </Fieldset>

                <div className={formStyles.verticalDivider}>
                  <span />
                </div>

                <Flex className={formStyles.miscContainer}>
                  <Flex>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Priority Level'}
                    >
                      <Label
                        htmlFor="priorityLevel"
                        className={formStyles.smlLabel}
                      >
                        <TranslateComponent>Priority Level</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'priority'}>
                        <SelectField
                          id={'priorityLevel'}
                          labelHidden
                          label={'Priority Level'}
                          value={values?.priority}
                        >
                          {PRIORITY_LIST.map((priority: string) => (
                            <option key={priority} value={priority}>
                              <TranslateComponent>
                                {priority}
                              </TranslateComponent>
                            </option>
                          ))}
                        </SelectField>
                      </DenaliFormElement>
                    </Fieldset>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Status'}
                    >
                      <Label htmlFor="status" className={formStyles.smlLabel}>
                        <TranslateComponent>Status</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'status'}>
                        <SelectField
                          id={'status'}
                          labelHidden
                          label={'Status'}
                          value={values?.status}
                        >
                          {STATUS_OPTION_LIST.map(({ key, value }) => (
                            <option key={key} value={key}>
                              <TranslateComponent>{value}</TranslateComponent>
                            </option>
                          ))}
                        </SelectField>
                      </DenaliFormElement>
                    </Fieldset>
                  </Flex>
                  <Flex>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Next Step'}
                    >
                      <Label htmlFor="nextStep" className={formStyles.smlLabel}>
                        <TranslateComponent>Next step</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'nextStep'}>
                        <SelectField
                          id={'nextStep'}
                          labelHidden
                          label={'Next step'}
                          value={values?.nextStep}
                        >
                          {NEXT_STEPS_OPTION_LIST.map(({ key, value }) => (
                            <option key={key} value={key}>
                              <TranslateComponent>{value}</TranslateComponent>
                            </option>
                          ))}
                        </SelectField>
                      </DenaliFormElement>
                    </Fieldset>
                  </Flex>
                  <Flex>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Assigned to'}
                    >
                      <Label
                        htmlFor={'assignedTo'}
                        className={formStyles.smlLabel}
                      >
                        <TranslateComponent>Assigned to</TranslateComponent>
                      </Label>
                      <DenaliFormElement
                        name={'assignedTo'}
                        customOnChange={true}
                      >
                        <DenaliAssignedTo
                          locationId={values?.location?.locationId}
                          value={values?.assignedTo}
                        />
                      </DenaliFormElement>
                    </Fieldset>
                  </Flex>
                </Flex>
              </Fieldset>
            </Flex>

            <hr />
            <Flex className={formStyles.footerActionsWrapper}>
              <Button
                key="cancel"
                onClick={handleCancel}
                className={formStyles.cancelButton}
              >
                <TranslateComponent>Cancel</TranslateComponent>
              </Button>
              <DenaliFormSubmit key="submit">
                <TranslateComponent>Save</TranslateComponent>
              </DenaliFormSubmit>
            </Flex>
          </Page>
        </InlineEquipmentSelectorContextProvider>
      </InlineBuildingSelectorContextProvider>
    </DenaliForm>
  )
}
