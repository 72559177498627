import { IUser } from "src/redux/types/AppTypes"

export const stringToBoolean = (value) => {
    return value === "1" ? true: false
}

export const booleanToString = (value) => {
    return value ? "1":"0"
}

export const getUserName = (userInfo: IUser) => {
    return `${userInfo?.lastName} ${userInfo?.firstName}`
}