import { Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from './table.module.scss'
import { useEffect } from 'react'

const allowedKeys = ['ArrowDown', 'ArrowUp', 'Enter']

export const InnerSearch = ({
  filter,
  popup,
  handleInnerScrollFade,
  setActiveScroll,
  searchTerm,
  setSearchTerm
}) => {
  // When first mounted, run the effect to make sure we update
  // filter.searchOptions if we have text or not.
  useEffect(() => {
    onInnerSearchChange(searchTerm, filter, popup, setSearchTerm)
  }, [])

  return (
    <div className={styles.searchInner}>
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <Input
        id={`Search ${filter.key}`}
        placeholder="Search..."
        aria-description={`Search ${filter.key}`}
        type="search"
        value={searchTerm}
        // Workaround for Floating UI to make sure certain events are allowed to bubble
        // but the rest we prevent from bubbling up to trigger closing the floating element.
        onKeyDown={(event) => {
          if (allowedKeys.includes(event.key)) {
            return
          }

          event.stopPropagation()
        }}
        onChange={(event) => {
          onInnerSearchChange(event.target.value, filter, popup, setSearchTerm)
        }}
      />
    </div>
  )

  function onInnerSearchChange(value, filter, popup, setSearchTerm) {
    searchTerm = value.toLowerCase()
    if (searchTerm.length > 0) {
      filter.searchOptions = filter.options.filter((option) =>
        option?.name?.toLowerCase().includes(searchTerm)
      )
    } else {
      filter.searchOptions = undefined
    }

    setTimeout(() => {
      handleInnerScrollFade(popup, setActiveScroll)
    }, 0) // 0 allows the popup to render before checking if the scroll is needed

    setSearchTerm(searchTerm)
  }
}
