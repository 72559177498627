import { TranslateComponent } from 'src/common/translations'
import styles from './table.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faMessage,
  faTachographDigital
} from '@fortawesome/free-solid-svg-icons'
import {
  faChartMixed,
  IconDefinition
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { ReactNode } from 'react'

export type ChipProps = {
  text?: string | ReactNode
  color?: ChipColor
  children?: ReactNode
  size?: ChipSize
  weight?: ChipWeight
  icon?: ChipIcon | IconDefinition
}

export const Chip = ({
  text = null,
  color = ChipColor.default,
  children = null,
  size = '',
  weight = ChipWeight.default,
  icon = null
}: ChipProps) => {
  return (
    <div
      className={styles.chip}
      data-color={color}
      data-size={size}
      data-weight={weight}
    >
      {icon && <FontAwesomeIcon icon={getIcon(icon)} />}
      {text != null && <RenderText text={text} />}
      {children}
    </div>
  )
}

const RenderText = ({ text }: { text: string | ReactNode }) => {
  if (typeof text === 'string') {
    return <TranslateComponent>{truncateText(text)}</TranslateComponent>
  }

  return <>{text}</>
}

export enum ChipColor {
  none = 'transparent',
  default = 'default',
  blue = 'blue',
  orange = 'orange',
  purple = 'purple',
  green = 'green',
  darkGreen = 'darkGreen',
  black = 'black',
  transparentBlack = 'transparentBlack',
  transparentGray = 'transparentGray',
  transparentWhite = 'transparentWhite',
  gold = 'gold',
  pink = 'pink',
  red = 'red',
  redInverse = 'redInverse',
  gray = 'gray'
}

export enum ChipWeight {
  default = 'default',
  w500 = '500',
  w600 = '600'
}

const truncateText = (text: string) => {
  return text.length > 30 ? text.substring(0, 30) + '...' : text
}

export type ChipSize = 'small' | '600' | 'medium' | 'large' | ''

export enum ChipIcon {
  default = 'default',
  Equipment = 'Equipment',
  Building = 'Building',
  Performance = 'Performance',
  ServiceAdvisory = 'Service Advisory'
}

const getIcon = (icon: ChipIcon | IconDefinition) => {
  if (typeof icon === 'string') {
    switch (icon) {
      case 'Equipment':
        return faTachographDigital
      case 'Building':
        return faBuilding
      case 'Performance':
        return faChartMixed
      case 'Service Advisory':
        return faMessage
      default:
        return faBuilding
    }
  } else {
    return icon
  }
}
