import translate, { TranslateComponent } from 'src/common/translations'
import styles from 'src/denali-components/File/file.module.scss'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Button } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import { ACTIONS } from 'src/constants/actions'

import ImagePreview from 'src/components/legacy/components/image-preview/index'
import { FindingHeadlinesView } from 'src/pages/opportunities/OpportunityDetail/cost-savings/finding-headlines-view/index'

interface FindingBadgeProps {
  findingId: string
  findingName?: string
  original?: string
  headlines?: string[]
  title?: string
  description?: string
  deleteFunc?: any
  isEditMode?: boolean
}

export const FindingBadge = ({
  findingId,
  findingName,
  original,
  headlines,
  title,
  description,
  deleteFunc,
  isEditMode
}: FindingBadgeProps) => {
  return (
    <div className={styles.fileBadge}>
      <div className={styles.fileContent}>
        {original && (
          <div className={styles.findingPreview}>
            {/* Currently using the old gallery */}
            <ImagePreview src={original}>
              {Boolean(headlines.length) && (
                <FindingHeadlinesView
                  name={findingName}
                  headlines={headlines}
                />
              )}
            </ImagePreview>
          </div>
        )}
        <div className={styles.fileInfo}>
          <Link
            to={{ pathname: `/${PAGE_NAMES.FINDINGS}/${findingId}` }}
            state={{ mode: ACTIONS.VIEW }}
            className={styles.findingLink}
          >
            {findingName}
          </Link>
          <span>{title}</span>
          <p>{description}</p>
        </div>
      </div>
      <div className={styles.fileActions}>
        {isEditMode && (
          <Tooltip key="remove-finding">
            <TooltipTrigger>
              <Button
                className="button-red button-icon"
                onClick={() => deleteFunc(findingName)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Remove Finding</TranslateComponent>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
