export const startMapDownload = `mutation createCustomConfiguration ($equipmentId: String!) {
        createCustomConfiguration(equipmentId: $equipmentId)
  }
  `

export const mapDownloadStatusFromConfigrationHistory = `query listConfigurationMessageHistoryByDevice($deviceId: ID!, $configurationMessageGroupId: Int) {
  listConfigurationMessageHistoryByDevice(deviceId: $deviceId, limit: 1000, configurationMessageGroupId: {eq: $configurationMessageGroupId},  sortDirection: DESC) {
    nextToken
    items {
      configurationMessageGroupId
      messageStatus
      createdAt
      updatedAt
    }
  }
}`

export const getConfigurationMessageQuery = `query getConfigurationMessage($id: ID!) {
  getConfigurationMessage(id: $id) {
      messageStatus
      deviceId
      id
      updatedAt
  }
}`


export const listConfigurationSuccessMessageQuery = `query listConfigurationSuccessMessageQuery($deviceId: ID!, $nextToken: String) {
  listConfigurationMessageByDevice(deviceId: $deviceId, nextToken: $nextToken, filter: {messageStatus: {eq: "SUCCESS"}}) {
    items {
      messageStatus
      deviceId
      id
      updatedAt
    }
    nextToken
  }
}`

export const getDeviceQuery = `query getDevice($id: ID!) {
    getDevice(id: $id) {
        deviceId
        id
        recentConfigurationMessageStatus
        deviceConnection {
            items {
              connectionTimeLocal
              connectionTimeUTC
        }
      }
      building {
        tz
      }
}
  }`
