import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Table from './table'
import { DenaliTableStyle } from 'src/denali-components/Table/table.d'
import moment from 'moment'

export const ClientSideTable = ({
  rows,
  rowAction,
  searchAction,
  action,
  actionsList,
  actionText,
  actionButtonType,
  actionButtonSize,
  iconType,
  title,
  filtersList,
  showItemCount,
  search,
  searchFields,
  searchPlaceholderText,
  header,
  rowControl,
  loadTime: loadTimeProp,
  updateRows,
  rowCheckbox,
  checkboxClick,
  fixedLayout,
  onUpdateControlsFunc,
  defaultSortColumn = 0,
  isShowPagination,
  tableWidth = '100%',
  containerWidth = '100%',
  containerPadding,
  columnOrderDown = true,
  checkboxChecked,
  testName = '',
  handleUpdatedTableRows = null,
  tableRowOverflowHidden = true,
  isFilterChange = false,
  tableClassName,
  disableTableScroll = true,
  isDenaliTable = false,
  denaliTableStyle = DenaliTableStyle.cards,
  denaliBulkActions,
  getDateRange,
  isTranslateFilters = true,
  isGap = false,
  listDataIsLoading,
  noTableDataTitleText,
  noTableDataAction,
  showSpinner,
  isTranslate = false,
  nestedArrayTranslate = false,
  isTableListChanges = false
}) => {
  const paginationOptions = useMemo(() => [30, 60, 90], [])
  const [loadTime, setLoadtime] = useState(0)
  const [tableRows, setRows] = useState(rows ? rows : null)
  const [tableRowsHaveBeenSet, setTableRowsHaveBeenSet] = useState(false)
  const [totalItems, setTotalItems] = useState(rows ? rows.length : 0)
  const [filter, setFilter] = useState({})

  const getSearchValue = (obj, field) => {
    const arrayOfKeys = field?.split('.')
    return arrayOfKeys?.reduce((accum, curr) => {
      accum = accum?.[curr]
      return accum
    }, obj)
  }

  useEffect(() => {
    if (listDataIsLoading) {
      return
    }
    setTableRowsHaveBeenSet(true)
  }, [listDataIsLoading, tableRows])

  const numberColumnSorting = (rows, columnOrderDown) => {
    // Currently it supports for one sort column
    // Have to work on supporting multiple sort columns
    // [TO-DO] Generalize this sort for other use cases
    try {
      return rows.sort((a, b) => {
        if (columnOrderDown) {
          if (a.year === b.year) {
            return a.month - b.month
          }
          return a.year > b.year ? 1 : -1
        } else {
          if (a.year === b.year) {
            return b.month - a.month
          }
          return a.year < b.year ? 1 : -1
        }
      })
    } catch (error) {
      return rows
    }
  }

  useEffect(() => {
    setLoadtime(loadTimeProp)
  }, [loadTimeProp])

  useEffect(() => {
    if (listDataIsLoading) {
      return
    }
    try {
      const sortField =
        header[defaultSortColumn].sortField || header[defaultSortColumn].key
      const sortColumnType = header[defaultSortColumn]?.columnType
      const numericSort = header[defaultSortColumn]?.numericSort ??  true
      let searchRows = []
      if (sortColumnType === 'number') {
        searchRows = numberColumnSorting(rows, columnOrderDown)
      } else {
        searchRows = sortField.includes('.')
          ? rows?.sort((a, b) => {
              if (columnOrderDown) {
                return getSearchValue(a, sortField).localeCompare(
                  getSearchValue(b, sortField),
                  undefined,
                  { sensitivity: 'base', numeric: numericSort }
                )
              } else {
                return getSearchValue(b, sortField).localeCompare(
                  getSearchValue(a, sortField),
                  undefined,
                  { sensitivity: 'base', numeric: numericSort }
                )
              }
            })
          : rows?.sort((a, b) => {
              if (columnOrderDown) {
                return a[sortField]?.localeCompare(b[sortField], undefined, {
                  sensitivity: 'base',
                  numeric: numericSort
                })
              } else {
                return b[sortField]?.localeCompare(a[sortField], undefined, {
                  sensitivity: 'base',
                  numeric: numericSort
                })
              }
            })
      }
      setRows([...searchRows.slice(0, paginationOptions[0])])
      setTotalItems(rows.length)
      onUpdateControls(filter)
    } catch (error) {
      setRows([...rows.slice(0, paginationOptions[0])])
      setTotalItems(rows.length)
      onUpdateControls(filter)
    }
  }, [rows, listDataIsLoading])

  const filterByDate = (filterBy, tableRowData) => {
    const data = filterBy?.selectedValue
    if (typeof getDateRange === 'function') {
      const date = getDateRange(data)
      const creationDate = tableRowData?.creationDateTranslate || tableRowData?.createdAt || tableRowData?.creationDate
      if (
        moment(creationDate) > date.startDate &&
        moment(creationDate) < date.endDate
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const onUpdateControls = (controls) => {
    try {
      onUpdateControlsFunc?.(controls)
      const controlsLength = Object.keys(controls).length
      if (controlsLength) {
        setFilter(controls)
        const filtersToApply = controls?.filters?.length
          ? controls.filters.filter(
              (e) => !e.sub && e.selectedValue && e.selectedValue !== 'default'
            )
          : []
        let searchRows = [...rows]

        if (filtersToApply?.length) {
          const actionFilters = filtersToApply.filter(
            (f) => typeof f.action === 'function'
          )
          const regularFilters = filtersToApply.filter(
            (f) => !f.action || !typeof f.action === 'function'
          )
          for (let i = 0; i < actionFilters.length; i++) {
            searchRows = actionFilters[i].action(
              searchRows,
              actionFilters[i],
              filtersToApply,
              i
            )
          }
          searchRows = searchRows?.filter((r) => {
            return regularFilters.every((f) => {
              if (f.type !== 'dateRangeFilter') {
                
                if (Array.isArray(f.selectedValue)) {
                  return r[f.key]
                  ?  f.selectedValue?.includes(r[f.key])
                  :  f.selectedValue?.includes(r[f.name])
                }else{
                return r[f.key]
                  ? r[f.key] === f.selectedValue
                  : r[f.name] === f.selectedValue
                }
              }
              return true
            })
          })

          const dateRangeFilter = regularFilters.find(
            (it) => it.type === 'dateRangeFilter'
          )
          if (dateRangeFilter) {
            searchRows = searchRows?.filter((r) =>
              filterByDate(dateRangeFilter, r)
            )
          }
        }

        if (controls.searchText && controls.searchText !== '') {
          if (typeof searchAction === 'function') {
            searchRows = searchAction(
              searchRows,
              controls.searchText,
              searchFields
            )
          } else {
            searchRows = searchRows.filter((r) => {
              return searchFields.some((f) => {
                return getSearchValue(r, f)
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(controls.searchText.toLowerCase())
              })
            })
          }
        }

        const sortField =
          header[controls.activeColumn].sortField ||
          header[controls.activeColumn].key

        const sortColumnType = header[controls.activeColumn]?.columnType
        const numericSort = header[controls.activeColumn]?.numericSort ??  true
        if (sortColumnType === 'number') {
          searchRows = numberColumnSorting(searchRows, controls.columnOrderDown)
        } else {
          searchRows = sortField.includes('.')
            ? searchRows?.sort((a, b) => {
                if (controls.columnOrderDown) {
                  return getSearchValue(a, sortField).localeCompare(
                    getSearchValue(b, sortField),
                    undefined,
                    { sensitivity: 'base', numeric: numericSort }
                  )
                } else {
                  return getSearchValue(b, sortField).localeCompare(
                    getSearchValue(a, sortField),
                    undefined,
                    { sensitivity: 'base', numeric: numericSort }
                  )
                }
              })
            : searchRows?.sort((a, b) => {
                if (controls.columnOrderDown) {
                  if (!a[sortField]) {
                    return -1
                  }
                  if (!b[sortField]) {
                    return 1
                  }
                  return a[sortField]?.localeCompare(b[sortField], undefined, {
                    sensitivity: 'base',
                    numeric: numericSort
                  })
                } else {
                  if (!a[sortField]) {
                    return 1
                  }
                  if (!b[sortField]) {
                    return -1
                  }
                  return b[sortField]?.localeCompare(a[sortField], undefined, {
                    sensitivity: 'base',
                    numeric: numericSort
                  })
                }
              })
        }

        setTotalItems(searchRows?.length)
        const filteredRows =
          searchRows.slice(
            controls.page * controls.paginationCount,
            controls.page * controls.paginationCount + controls.paginationCount
          ) ?? []
        setRows(filteredRows)
        if (handleUpdatedTableRows) {
          handleUpdatedTableRows(searchRows)
        }
      }
    } catch (error) {
      setTotalItems(rows?.length)
      const filteredRows =
        rows.slice(
          controls.page * controls.paginationCount,
          controls.page * controls.paginationCount + controls.paginationCount
        ) ?? []
      setRows(filteredRows)
      if (handleUpdatedTableRows) {
        handleUpdatedTableRows(searchRows)
      }
    }
  }

  return (
    <Table
      updateRows={updateRows}
      tableClassName={tableClassName}
      action={action}
      actionsList={actionsList}
      actionText={actionText}
      actionButtonType={actionButtonType}
      actionButtonSize={actionButtonSize}
      iconType={iconType}
      title={title}
      testName={testName}
      rows={tableRows}
      rowAction={rowAction}
      totalItems={totalItems}
      filtersList={filtersList}
      showItemCount={showItemCount}
      isTranslateFilters={isTranslateFilters}
      searchPlaceholderText={searchPlaceholderText}
      header={header}
      onUpdateControls={(controls) => {
        onUpdateControls(controls)
      }}
      activeColumn={defaultSortColumn}
      loadTime={loadTime}
      rowControl={rowControl}
      search={search}
      tableWidth={tableWidth}
      containerWidth={containerWidth}
      containerPadding={containerPadding}
      rowCheckbox={rowCheckbox}
      columnOrderDown={columnOrderDown}
      checkboxClick={(r) => checkboxClick(r)}
      checkboxChecked={checkboxChecked}
      fixedLayout={fixedLayout}
      isShowPagination={isShowPagination}
      tableRowOverflowHidden={tableRowOverflowHidden}
      isFilterChange={isFilterChange}
      disableTableScroll={disableTableScroll}
      isDenaliTable={isDenaliTable}
      denaliTableStyle={denaliTableStyle}
      denaliBulkActions={denaliBulkActions}
      isGap={isGap}
      listDataIsLoading={listDataIsLoading || !tableRowsHaveBeenSet}
      noTableDataTitleText={noTableDataTitleText}
      noTableDataAction={noTableDataAction}
      showSpinner={showSpinner}
      isTranslate={isTranslate}
      nestedArrayTranslate={nestedArrayTranslate}
      isTableListChanges={isTableListChanges}
    />
  )
}

export default ClientSideTable

ClientSideTable.propTypes = {
  checkboxChecked: PropTypes.func
}

ClientSideTable.defaultProps = {
  checkboxChecked() {}
}
