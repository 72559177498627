import { useQuery } from "src/hooks/APIHooks"
import { GET_USER_HISTORY } from "./graphql"
import { useDispatch } from "react-redux"
import { setRecentSearchedItems, setRecentUserHistoryFetched } from "src/redux/slicers/buildingPicker"
import moment from "moment"
import { getBuilding, getFormatVariables, getSalesOffice, itemFromHistory } from "./helper"
import { IBuildingOld } from "src/redux/types/BuildingPickerTypes"
import { ORGANIZATION, SALES_OFFICE } from './constants'

type useUserSearchHistoryOptions = {
  onEmpty?: () => void
  onResults?: (UserHistoryItems) => void
}

type UserHistoryItems = [IBuildingOld]

export const useUserSearchHistory = ( {onEmpty, onResults}:useUserSearchHistoryOptions = {} ) => {
  const dispatch = useDispatch()
  
  return useQuery({
    query: GET_USER_HISTORY,
    disableInitialLoad: true,
    dataPath: 'data.listUserHistoryByOwner.items',
    errorPolicy: 'ignore',
    onSuccess: async (data: any) => {
      if (data.length === 0) {
        dispatch(setRecentUserHistoryFetched(true))
        if(onEmpty) onEmpty()
        return
      }
      const newarr = data.sort((a, b) => {
        return moment(b.eTime).diff(a.eTime)
      })

      const newFormattedArray = await getOrgSalesOfficeDetails(newarr)

      const items = await newFormattedArray?.reduce(
        (recentItems: any, item: any, index: number) => {
          item.historyType = item.type
          const recentItem = itemFromHistory({ ...item }, false)
          if (recentItem?.primary) {
            recentItems.push(recentItem)
          }
          return recentItems
        },
        []
      )

      const userHistoryItems = { data: items }

      if(onResults) onResults(userHistoryItems)

      dispatch(
        setRecentSearchedItems({
          type: 'Success',
          data: items
        })
      )

      dispatch(setRecentUserHistoryFetched(true))
    },
    onError: () => {
      dispatch(
        setRecentSearchedItems({
          type: 'Failure'
        })
      )
    }
  })
}

// If the user don't have access to organization, org name and sales office object will be null in User History
// So get those details and merge with user history for UI
const getOrgSalesOfficeDetails = async (items: any): Promise<Array<any>> => {
  try {
    const recentItems = []
    for (let i = 0; i < items.length; i++) {
      const element = items[i]
      if (element.type === ORGANIZATION) {
        const inputVariables = getFormatVariables(
          element.accountId,
          ORGANIZATION
        )
        const response = await getBuilding(
          inputVariables,
          ORGANIZATION,
          false
        )
        if (response?.id)
          recentItems.push({
            ...element,
            account: { name: response.primary }
          })
      } else if (element.type === SALES_OFFICE) {
        const inputVariables = getFormatVariables(
          element.salesOfficeId,
          SALES_OFFICE
        )
        const response = await getSalesOffice(
          inputVariables,
          SALES_OFFICE,
          false
        )
        if (response?.id)
          recentItems.push({
            ...element,
            salesOffice: { name: response.primary }
          })
      } else {
        recentItems.push({ ...element })
      }
    }
    return recentItems
  } catch (error) {
    return []
  }
}