import _cloneDeep from "lodash/cloneDeep"
import _isEqual from "lodash/isEqual"
import _get from "lodash/get";
import {
	getAmountOfWorkingMonths,
	CALCULATION_READY_STATUSES,
	KPI_SPECIFIC_VALUE
} from "src/components/legacy/common/opportunity"
import { MODES } from '../../constants'
import moment from 'moment'


export const CHART_TYPES = {
	WHAT_WE_HAVE_SAVED: "WhatWeHaveSaved",
	WHERE_WE_HAVE_BEEN: "WhereWeHaveBeen",
	WHAT_WE_HAVE_DONE: "WhatWeHaveDone"
}
export const CHART_FIELD_TYPES = {
	WHAT_WE_HAVE_SAVED_DETAILS: "whatWeHaveSavedImageDetails",
	WHERE_WE_HAVE_BEEN_DETAILS: "whereHaveYouBeenImageDetails",
	WHAT_WE_HAVE_DONE_DETAILS: "whatWeHaveDoneImageDetails"
}

export const IMAGE = "IMAGE"
export const CHART = "CHART"

export const CHART_IMAGE_MODES = [CHART, IMAGE]

export const IMPLEMENTED_CHARTS = [CHART_TYPES.WHAT_WE_HAVE_SAVED, CHART_TYPES.WHAT_WE_HAVE_DONE]

export const CALCULATION_FORMULAS = {
	"one-time": value => (monthCount, lastStatus) => ({ value: CALCULATION_READY_STATUSES.includes(lastStatus) ? value : 0, timePeriod: "one-time" }),
	"per-month": value => monthCount => ({ value: value * monthCount, timePeriod: "per-month" }),
	"per-year": value => monthCount => ({ value: value / 12 * monthCount, timePeriod: "per-year" }),
	// There are keys in different format in DB, so keep this to avoid errorCALCULATION_FORMULAS
	OneTime() {
		return this["one-time"]
	},
	PerMonth() {
		return this["per-month"]
	},
	PerYear() {
		return this["per-year"]
	}
}

const WHAT_WE_HAVE_SAVED_CHART_OUTPUT = {
	kpi: {
		"energyUsage": 0,
		"performance": 0,
		"reliability": 0,
		"comfort": 0,
		"compliance": 0,
		"custom": 0,
	},
	truckRolls: 0,
	totalSavings: 0,
	savingsGoal: 0,
	currency: "$"
}

export const WHAT_WE_HAVE_DONE_CHART_OUTPUT = {
	"findingsFound": [],
	"findingsResolved": [],
	"opportunitiesIdentified": [],
	"opportunitiesImplemented": []
}

// TODO: review and remove old values when it will be removed from status history
export const STATUS_TO_CATEGORY = {
	"finding": new Map([
		["observed", "findingsFound"],
		["recommended", "findingsFound"],
		["inProgress", "findingsFound"],
		["dismissed", "findingsFound"],
		["hold", "findingsFound"],

		["complete", "findingsResolved"],
		["remoteResolved", "findingsResolved"],
		["validation", "findingsResolved"],
		["onSiteResolved", "findingsResolved"]
	]),
	"opportunity": new Map([
		["recommended", "opportunitiesIdentified"],
		["inProgress", "opportunitiesIdentified"],
		["customerDeferred", "opportunitiesIdentified"],
		["deferred", "opportunitiesIdentified"],
		["proposalDevelopment", "opportunitiesIdentified"],
		["proposalReady", "opportunitiesIdentified"],

		["validation", "opportunitiesImplemented"],
		["complete", "opportunitiesImplemented"]
	])
}

export const ALL_STATUSES = Array.from(new Set(Object.keys(STATUS_TO_CATEGORY).reduce((acc, category) => [...acc, ...STATUS_TO_CATEGORY[category].keys()], [])))

export function isStatusPreReadyOrReady(status) {
	return ALL_STATUSES.includes(status)
}

// What We Have Saved chart main calculations
export function aggregateDataByKPI({ data = [], startDate, endDate, calculationFormulas }) {
	const result = _cloneDeep(WHAT_WE_HAVE_SAVED_CHART_OUTPUT);

	data?.forEach(({ kpi = [], energySavings, history, creationDate, lastModifiedDate, status }) => {
		if (!kpi) {
			return;
		}

		const { workingMonthAmount, lastStatus } = getAmountOfWorkingMonths({ startDate, endDate, creationDate, lastModifiedDate, status, history });

		if (workingMonthAmount === 0) {
			return;
		}

		// Calculate only if key metric is active
		kpi?.filter(({ value }) => value)?.forEach(({ name, custom, priority, savings, timePeriod }) => {
			let value: any = 0;

			// Specific case Opportunity with Energy Savings listed
			if (name === "energyUsage" && energySavings && energySavings?.savings?.enabled) {
				const formula = calculationFormulas?.energySavings?.[energySavings?.savings?.tag];

				if (formula) {
					value = formula(energySavings?.savings?.value)(workingMonthAmount, lastStatus);
				}
			} else if (priority === KPI_SPECIFIC_VALUE && timePeriod) { // Specific value set on item's KPI
				value = CALCULATION_FORMULAS?.[timePeriod]?.(savings)(workingMonthAmount, lastStatus);
			} else { // Get info for calculating from Value Assumption
				//custom1
				if (custom) {
					value = calculationFormulas?.kpi?.[`custom${custom}`]?.[priority]?.(workingMonthAmount, lastStatus);

				} else {
					value = calculationFormulas?.kpi?.[name || custom]?.[priority]?.(workingMonthAmount, lastStatus);

				}
			}

			value = value?.value || value;

			result.kpi[custom ? "custom" : name] += parseFloat(Number(value).toFixed(2));
		});

		if (status === "remoteResolved") {
			result.truckRolls += calculationFormulas?.avoidedTruckRolls;
		}
	});

	result.totalSavings = Object.values(result?.kpi).reduce((acc: number, value) => {
		const numericValue = Number(value);
		if (!isNaN(numericValue)) {
			return acc + numericValue;
		}
		return acc;
	}, 0);

	return _isEqual(result, WHAT_WE_HAVE_SAVED_CHART_OUTPUT) ? null : { ...result, savingsGoal: calculationFormulas?.savingsGoal };
}


// What We Have Done chart main calculations
export function aggregateDataByItemStatus({ data = [], startDate, endDate, calculationFormulas }) {
	const result = _cloneDeep(WHAT_WE_HAVE_DONE_CHART_OUTPUT);
	const CALCULATION_READY_STATUS = CALCULATION_READY_STATUSES[0];
	data?.forEach(({ id, name, type, kpi = [], energySavings, history, creationDate, proposalDate, lastModifiedDate, status, title, isVisible }) => {
		if (!kpi) { return; }

		const { lastStatus } = getAmountOfWorkingMonths({ startDate, endDate, creationDate, lastModifiedDate, status, history });

		if (!isStatusPreReadyOrReady(status)) { return; }

		const workingMonthAmount = 1;
		let potentialSavingsMonth = 0;
		let potentialSavingsTotal = 0;
		const activeKPIs = kpi?.filter(({ value }) => value) ?? [];

		activeKPIs?.forEach(({ name, custom, priority, savings, timePeriod: period }) => {
			if (((name ?? "") === "energyUsage") && (energySavings ?? "") && (energySavings?.enabled ?? false)) {
				const formula = calculationFormulas?.energySavings?.[energySavings?.savings?.tag];
				if (formula) {
					const savings = formula(energySavings?.savings?.value)(workingMonthAmount, CALCULATION_READY_STATUS);
					savings?.timePeriod === "one-time" ? potentialSavingsTotal += savings?.value : potentialSavingsMonth += savings?.value;
				}
			} else if (priority === KPI_SPECIFIC_VALUE && (period ?? null)) {
				const { timePeriod, value } = CALCULATION_FORMULAS?.[period](savings)(workingMonthAmount, CALCULATION_READY_STATUS) || {};
				timePeriod ?? null === "one-time" ? potentialSavingsTotal += value : potentialSavingsMonth += value;
			} else {
				const kpiNameCustom = custom ? `custom${custom}` : name;
				const { timePeriod, value } = _get(calculationFormulas, `kpi.${kpiNameCustom}.${priority}`, () => { })(workingMonthAmount, CALCULATION_READY_STATUS) || {};

				if (timePeriod) {
					timePeriod === "one-time" ? potentialSavingsTotal += value : potentialSavingsMonth += value;
				}
			}
		});

		result[STATUS_TO_CATEGORY[type]?.get(status || lastStatus)]?.push({
			id,
			name: name || title,
			type,
			potentialSavingsMonth: potentialSavingsMonth,//parseFloat(potentialSavingsMonth.toFixed(2)) || 0,
			potentialSavingsTotal: parseFloat(potentialSavingsTotal.toFixed(2)) || 0,
			creationDate: moment(creationDate).format('MM/DD/YYYY'),
			inactive: activeKPIs?.length === 0,
			isVisible: isVisible
		});
	});

	return _isEqual(result, WHAT_WE_HAVE_DONE_CHART_OUTPUT) ? null : result;
}


// Make formulars from Value Assumption for quick calculations
export function getCalculationFormulas(valueAssumption) {
	const { avoidedTruckRollAmount, savingsGoal, kpi } = valueAssumption;

	const rr = {
		avoidedTruckRolls: avoidedTruckRollAmount,
		savingsGoal,
		kpi: kpi?.reduce((obj, { name, custom, low, medium, high }) => {
			const priorities = { low, medium, high };
			const prioritiesCalcFuncs = {};

			Object?.keys(priorities)?.forEach((priorityName) => {
				const { value, timePeriod } = priorities[priorityName] ?? {};

				if (timePeriod) {
					prioritiesCalcFuncs[priorityName] =
						CALCULATION_FORMULAS[timePeriod]?.(value);
				}
			});

			obj[name || custom] = prioritiesCalcFuncs;
			return obj;
		}, {}),
		energySavings: {
			total: CALCULATION_FORMULAS["one-time"],
			months: CALCULATION_FORMULAS["per-month"],
			years: CALCULATION_FORMULAS["per-year"],
		},
	};
	return rr;
}


export const urlOfPageWithItems = ({ organizationId, locations, startDate, endDate }) => (path, status) => {
	const locationIds = locations.map(({ locationId }) => locationId).join()
	return `/${path}?startDate=${startDate}&endDate=${endDate}&organization=${organizationId}&locationIds=${locationIds}&showStatuses=${status}`
}

export function filterHistoryItems(items = [], mode) {
	return items.filter(item => isVisibleDependingOnMode(item, mode))
}

// On VIEW version show items that are visible to the customer.
// On EDIT version show both customer visible and customer not visible ones.
function isVisibleDependingOnMode({ visibleToCustomer }, mode) {
	if (mode == MODES.EDIT) {
		return true
	} else {
		return visibleToCustomer
	}
}
