import {
  Flex,
  Input,
  Label,
  SelectField,
  TextAreaField
} from '@aws-amplify/ui-react'
import * as yup from 'yup'

// Project imports.
import { PRIORITY_LIST } from 'src/components/legacy/common/opportunity'
import {
  FINDING_ISSUE_FOUND_OPTION_LIST,
  STATUS_OPTION_LIST,
  NEXT_STEPS_OPTION_LIST
} from 'src/components/legacy/common/finding'
import { getSearchParams } from 'src/common/helperFunctions'
import translate, { TranslateComponent } from 'src/common/translations'
import { MAX_LENGTH_OF_NAME } from 'src/pages/documentsAndReports/helper'
import {
  selectIssuesAndFindingsData,
  setIssuesAndFindings
} from 'src/redux/slicers/formData'
import { useAppSelector } from 'src/redux/store'

// Denali imports.
import { EquipmentSelector } from 'src/denali-components/EquipmentSelector/EquipmentSelector'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { DenaliFormDisabledOverlay } from 'src/denali-components/Form/DenaliFormDisabledOverlay'
import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import { DenaliFormElementError } from 'src/denali-components/Form/DenaliFormElementErrors'
import { DenaliForm } from 'src/denali-components/Form/DenaliForm'
import { DenaliFormLoader } from 'src/denali-components/Form/DenaliFormLoader'

// Local imports.
import styles from './issues-findings.module.scss'
import { IssuesAndFindingsQuickAddFormProps } from './types'

export const IssuesFindingsQuickAddForm = ({
  onSubmit,
  defaultValues
}: IssuesAndFindingsQuickAddFormProps) => {
  const { buildingId: locationId } = getSearchParams()
  const values = useAppSelector(selectIssuesAndFindingsData)
  const nameMaxError = translate('Name must be less than 100 characters')
  const nameRequiredError = translate('Name is required')
  const orgRequiredError = translate('Organization is required')
  const buildingRequiredError = translate('Building is required')

  const schema = yup
    .object({
      name: yup
        .string()
        .max(MAX_LENGTH_OF_NAME, nameMaxError)
        .required(nameRequiredError),
      organization: yup.object({
        organizationId: yup.string().required(orgRequiredError)
      }),
      location: yup.object({
        locationId: yup.string().required(buildingRequiredError)
      })
    })
    .required()

  const optionsTranslated = {
    placeholderText: translate('Start typing here...'),
    findingLabel: translate('About this Finding'),
    priorityLabel: translate('Priority Level'),
    priorityPlaceholder: translate('Choose a priority'),
    statusLabel: translate('Status'),
    statusPlaceholder: translate('Choose a status'),
    nextStepLabel: translate('Next Step'),
    nextStepPlaceholder: translate('Select next step'),
    foundLabel: translate('Found during'),
    foundPlaceholder: translate('Found during...')
  }

  return (
    <DenaliForm
      id="issues-findings-quick-add-form"
      values={values}
      className={[styles.quickAddForm, formStyles.denaliForm].join(' ')}
      schema={schema}
      onSubmit={(value, reset) => onSubmit(value, { resetForm: reset })}
      defaultValues={defaultValues}
      updateReduxStore={true}
      updateReduxStoreAction={setIssuesAndFindings}
      disabled={!locationId}
    >
      <Flex
        className={styles.quickAddFormElements}
        direction="column"
        justifyContent="stretch"
        alignItems="flex-start"
        gap="var(--amplify-space-medium)"
      >
        <Flex
          className={styles.quickAddFormRow}
          direction="row"
          alignItems="flex-start"
          gap="var(--amplify-space-small)"
          flex="1 1 100%"
        >
          <Flex
            direction="column"
            justifyContent="stretch"
            flex="1 1 50%"
            gap="var(--amplify-space-xs)"
          >
            <Label htmlFor="name">
              <TranslateComponent>Issue/Findings Name</TranslateComponent>{' '}
              <sup>*</sup>
            </Label>
            <DenaliFormElement name="name">
              <Input
                id="name"
                value={values?.name}
                placeholder={optionsTranslated.placeholderText}
              />
            </DenaliFormElement>
            <DenaliFormElementError name="name" />
          </Flex>
          <Flex direction="column" justifyContent="stretch" flex="1 1 25%">
            <DenaliFormElement name="priority">
              <SelectField
                label={optionsTranslated.priorityLabel}
                placeholder={optionsTranslated.priorityPlaceholder}
                id="priority"
                value={values?.priority}
              >
                {PRIORITY_LIST.map((priority) => {
                  return (
                    <option key={priority} value={priority}>
                      <TranslateComponent>{priority}</TranslateComponent>
                    </option>
                  )
                })}
              </SelectField>
            </DenaliFormElement>
            <DenaliFormElementError name="priority" />
          </Flex>
          <Flex direction="column" justifyContent="stretch" flex="1 1 25%">
            <DenaliFormElement name="status">
              <SelectField
                label={optionsTranslated.statusLabel}
                placeholder={optionsTranslated.statusPlaceholder}
                id="status"
                value={values?.status}
                className={formStyles.smlLabel}
              >
                {STATUS_OPTION_LIST.map((status) => {
                  return (
                    <option key={status.key} value={status.key}>
                      <TranslateComponent>{status.value}</TranslateComponent>
                    </option>
                  )
                })}
              </SelectField>
            </DenaliFormElement>
            <DenaliFormElementError name="status" />
          </Flex>
        </Flex>
        <Flex
          className={styles.quickAddFormRow}
          direction="row"
          alignItems="flex-start"
          gap="var(--amplify-space-small)"
          flex="1 1 100%"
        >
          <Flex
            className={styles.quickAddFormComment}
            direction="column"
            justifyContent="stretch"
            flex="1 1 50%"
            gap="var(--amplify-space-xs)"
          >
            <DenaliFormElement name="comment" customOnChange={true}>
              <TextAreaField
                label={optionsTranslated.findingLabel}
                placeholder={optionsTranslated.placeholderText}
                id="description"
                rows={6}
                value={values?.comment}
              />
            </DenaliFormElement>
            <DenaliFormElementError name="settings.description" />
          </Flex>
          <Flex
            direction="column"
            justifyContent="stretch"
            flex="1 1 50%"
            gap="var(--amplify-space-medium)"
          >
            <Flex direction="row" flex="1 1 100%">
              <Flex direction="column" justifyContent="stretch" flex="1 1 50%">
                <DenaliFormElement name="nextStep">
                  <SelectField
                    label={optionsTranslated.nextStepLabel}
                    placeholder={optionsTranslated.nextStepPlaceholder}
                    id="nextStep"
                    value={values?.nextStep}
                    className={formStyles.smlLabel}
                  >
                    {NEXT_STEPS_OPTION_LIST.map((nextStep) => {
                      return (
                        <option key={nextStep.key} value={nextStep.key}>
                          <TranslateComponent>
                            {nextStep.value}
                          </TranslateComponent>
                        </option>
                      )
                    })}
                  </SelectField>
                </DenaliFormElement>
              </Flex>
              <Flex direction="column" justifyContent="stretch" flex="1 1 50%">
                <DenaliFormElement name="foundDuring">
                  <SelectField
                    label={optionsTranslated.foundLabel}
                    placeholder={optionsTranslated.foundPlaceholder}
                    id="foundDuring"
                    value={values?.foundDuring}
                    className={formStyles.smlLabel}
                  >
                    {FINDING_ISSUE_FOUND_OPTION_LIST.map((foundDuring) => {
                      return (
                        <option key={foundDuring.key} value={foundDuring.key}>
                          <TranslateComponent>
                            {foundDuring.value}
                          </TranslateComponent>
                        </option>
                      )
                    })}
                  </SelectField>
                </DenaliFormElement>
                <DenaliFormElementError name="foundDuring" />
              </Flex>
            </Flex>
            <Flex
              direction="column"
              justifyContent="stretch"
              flex="1 1 100%"
              gap="0"
            >
              <DenaliFormElement name="tisObjects" customOnChange={true}>
                <EquipmentSelector
                  locationId={locationId}
                  value={values?.tisObjects}
                />
              </DenaliFormElement>
              <DenaliFormElementError name="tisObjects" />
            </Flex>
          </Flex>
        </Flex>
        <DenaliFormLoader />
        <DenaliFormDisabledOverlay>
          <h5>
            <TranslateComponent>
              Please select a building to continue
            </TranslateComponent>
          </h5>
        </DenaliFormDisabledOverlay>
      </Flex>
    </DenaliForm>
  )
}
