import PropTypes from "prop-types"
import { MODES } from "../../constants"
import ImagePreview from 'src/components/legacy/components/image-preview/index'
import ConsultationToggleBox from '../consultationToggleBox/index'
import { CHART, CHART_IMAGE_MODES } from "./common"
import { CHART_TYPES } from './common';

export const CHART_IMAGE_PATHS = {
	[CHART_TYPES.WHAT_WE_HAVE_SAVED]: "settings.whatWeHaveSaved",
	[CHART_TYPES.WHERE_WE_HAVE_BEEN]: "settings.whereWeHaveBeen",
	[CHART_TYPES.WHAT_WE_HAVE_DONE]: "settings.whatWeHaveDone"
}


const ChartImage = (props) => {
	const path = () => {
		return CHART_IMAGE_PATHS[props.chartType]
	}

	const showChart = () => {
		const { organization: { valueAssumption } } = props

		if (!valueAssumption) {
			return undefined
		}
		return <></>
	}


	const showImage = (() => {
		const { settings, isChartVisible, items, isLazyLoading, isImageZooming, handleDownloadImage } = props
		const { original } = settings
		return !isChartVisible ? <ImagePreview
			handleDownloadImage={handleDownloadImage} isImageZooming={isImageZooming} isLazyLoading={isLazyLoading}
			items={items} src={original && original} /> : null;
	})



	const showContentAccordingToModes = () => {
		const { settings: { mode: chartImageMode } } = props
		const content = chartImageMode === CHART ? showChart() : showImage()
		return content
	}
	const title = props?.settings?.title || ""
	const enabled = props?.settings?.enabled || false
	const { mode, chartType, errors, onUpdateFields } = props
	// Do not show disabled component component in View/Copy? mode
	if (mode !== MODES.EDIT && !enabled) {
		return null
	}

	return <ConsultationToggleBox
		onChangeToggleButton={mode === MODES.EDIT ? value => onUpdateFields(`${path}.enabled`, value) : undefined}
		onChangeTitle={mode === MODES.EDIT ? value => onUpdateFields(`${path}.title`, value) : undefined}
		title={title}
		errors={errors}
		checkedToggleButton={enabled}
		translationLabel={`consultations:${chartType}`}>
		{showContentAccordingToModes()}
	</ConsultationToggleBox>
}
ChartImage.propTypes = {
	organization: PropTypes.shape({
		organizationId: PropTypes.number
	}).isRequired,
	locations: PropTypes.array.isRequired,
	consultationId: PropTypes.number.isRequired,
	valueAssumption: PropTypes.object,
	rangeTo: PropTypes.string.isRequired,
	rangeFrom: PropTypes.string.isRequired,
	settings: PropTypes.shape({
		original: PropTypes.string,
		enabled: PropTypes.bool,
		mode: PropTypes.oneOf(CHART_IMAGE_MODES),
		title: PropTypes.string,
	}).isRequired,
	mode: PropTypes.string,
	onUpdateFields: PropTypes.func,
	chartType: PropTypes.oneOf(Object.values(CHART_TYPES)),
	errors: PropTypes.object,
	isLazyLoading: PropTypes.bool,
	handleDownloadImage: PropTypes.func,
	items: PropTypes.array,
	isImageZooming: PropTypes.bool
}
ChartImage.defaultProps = {
	mode: MODES.EDIT,
	locations: [],
	isLazyLoading: false,
	handleDownloadImage: null,
	items: null,
	isImageZooming: false
}

export default ChartImage;

