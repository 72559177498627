import React, { useState, useCallback } from "react"
import { NotificationsSettingsDialog } from "./notifications-settings/notification-settings-dialog"
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import "./index.scss"
import translate, { TranslateComponent } from 'src/common/translations'
import { useSelector } from "react-redux"

export const NotificationItem = ({ categoryKey, index, showSettingsLink, disableChange, onToggleSwitchChange, linkText, enabled, title, text, onSave, values }) => {
	const [isDialogOpened, setIsDialogOpened] = useState(false)
	const toggleNotificationsSettingsDialog = useCallback(() => setIsDialogOpened(!isDialogOpened), [isDialogOpened])
	const onSwitch = useCallback(({ target: { checked } }) => onToggleSwitchChange(index, checked, categoryKey), [index, onToggleSwitchChange])
	let selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage)
        selectedLanguage = selectedLanguage ? selectedLanguage : 'en'

	 const optionsTranslated = {
	 	on : translate('ON'),
		off : translate('OFF')
	 }

	return (
		<div className="notification-item">
			<div className="notification-description">
				<h3 className="title"><TranslateComponent>{title}</TranslateComponent></h3>
				<p className="text"><TranslateComponent>{text}</TranslateComponent></p>
			</div>
			<div className="notification-actions"  style={{width: selectedLanguage !== 'en' && '270px'}}>
				<ToggleButtonHorizontal
					on={optionsTranslated.on}
					off={optionsTranslated.off}
					disabled={disableChange}
					checked={enabled
					}
					onChange={onSwitch}
				/>
				{showSettingsLink && enabled && <a className="link" onClick={toggleNotificationsSettingsDialog}><TranslateComponent>{linkText}</TranslateComponent></a>}
			</div>

			{isDialogOpened && <NotificationsSettingsDialog
				categoryKey={categoryKey}
				values={values}
				onClose={toggleNotificationsSettingsDialog}
				onSave={onSave}
			/>}
		</div>
	)
}

NotificationItem.propTypes = {
}

export default NotificationItem
