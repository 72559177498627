import XYChart from 'src/chart-library/Charts/XYChart'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import moment from 'moment/moment'
import colors from 'src/components/layouts/colors.json'
import { format } from 'date-fns'
import { NoDataMessage } from './styles.js'
import { chartDateFormat } from './EnergyConsumptionContainer'
import { useCallback, useMemo } from 'react'
import TimelineWithChart from './Timeline'
import './styles.scss'
import * as d3 from 'd3'
import { max } from 'lodash'
import { endDateDecider } from './helper.js'
import { TranslateComponent } from 'src/common/translations'
import store from 'src/redux/store'
import { formatDateByMomentInEng } from '../../chart-library/CommonComponents/Timeline/helpers'

const propertyAxis = {
  BaselinePrediction: {
    xAxis: 'x1',
    yAxis: 'y1',
    canvasIndex: '0',
    color: '#fb9a09',
    chartType: 'line',
    UoM: 'kWh'
  },
  BaselinePredictionCost: {
    xAxis: 'x1',
    yAxis: 'y1',
    canvasIndex: '0',
    color: '#fb9a09',
    chartType: 'line',
    UoM: 'kWh'
  },
  TotalConsumption: {
    xAxis: 'x1',
    yAxis: 'y1',
    canvasIndex: '0',
    color: '#03b2cb',
    chartType: 'line',
    UoM: 'kWh'
  },
  ConsumptionInterval: {
    xAxis: 'x1',
    yAxis: 'y1',
    canvasIndex: '0',
    color: '#03b2cb',
    chartType: 'line',
    UoM: 'kWh'
  },
  ConsumptionCost: {
    xAxis: 'x1',
    yAxis: 'y1',
    canvasIndex: '0',
    color: '#03b2cb',
    chartType: 'line',
    UoM: 'kWh'
  },
  Temperature: {
    xAxis: 'x1',
    yAxis: 'y2',
    canvasIndex: '0',
    color: '#fff787',
    chartType: 'areaRange',
    UoM: '°F'
  },
  PreviousYear: {
    xAxis: 'x1',
    yAxis: 'y1',
    canvasIndex: '0',
    color: '#fb9a09',
    chartType: 'line',
    UoM: 'kWh'
  },
  EnergySavings: {
    xAxis: 'x2',
    yAxis: 'y3',
    canvasIndex: '1',
    color: '#89C506',
    chartType: 'columnNew',
    UoM: 'kWh'
  },
  EnergyOverage: {
    xAxis: 'x2',
    yAxis: 'y3',
    canvasIndex: '1',
    color: '#FEA7AA',
    chartType: 'columnNew',
    UoM: 'kWh'
  },
  TotalSavings: {
    xAxis: 'x3',
    yAxis: 'y4',
    canvasIndex: '1',
    chartType: 'line',
    color: '#3C3C3C',
    UoM: 'kWh'
  }
}

export const EnergyConsumptionLineChart = ({
  chartData,
  timeSliderChartData,
  width,
  isDataAvailableForChart,
  eventMarkers,
  compareTo,
  unit,
  meterType,
  chartDates,
  setTimeSliderStartTime,
  setTimeSliderEndTime,
  energyConsumptionPropertyName,
  testName = ''
}) => {
  const xAxisTicks = 8

  const getDataAvailability = (data) => {
    if (!data) return
    let actualConsumptionDataAvailable = false
    let baselinePredictionDataAvailable = false
    let actualConsumptionPreviousDataAvailable = false
    if (unit === '$') {
      actualConsumptionDataAvailable = data?.data?.find(
        (x) => x?.propertyKey === 'ConsumptionCost'
      )?.isDataAvailable
      baselinePredictionDataAvailable = data?.data?.find(
        (x) => x?.propertyKey === 'BaselinePredictionCost'
      )?.isDataAvailable
      actualConsumptionPreviousDataAvailable = data?.data?.find(
        (x) => x?.propertyName === 'Previous Year'
      )?.isDataAvailable
    } else {
      actualConsumptionDataAvailable = data?.data?.find(
        (x) => x?.propertyKey === energyConsumptionPropertyName
      )?.isDataAvailable
      baselinePredictionDataAvailable = data?.data?.find(
        (x) => x?.propertyKey === 'BaselinePrediction'
      )?.isDataAvailable
      actualConsumptionPreviousDataAvailable = data?.data?.find(
        (x) => x?.propertyName === 'Previous Year'
      )?.isDataAvailable
    }

    let canShowBarChart = false

    if (compareTo === 'baseline') {
      canShowBarChart = baselinePredictionDataAvailable || false
    } else if (compareTo === 'previous year') {
      canShowBarChart = actualConsumptionPreviousDataAvailable || false
    } else {
      canShowBarChart = false
    }
    return {
      actualConsumptionDataAvailable,
      canShowBarChart
    }
  }

  const { chartDataAvailability, timeSliderChartDataAvailability } =
    useMemo(() => {
      const chartDataAvailability = getDataAvailability(chartData)
      const timeSliderChartDataAvailability =
        getDataAvailability(timeSliderChartData)
      return { chartDataAvailability, timeSliderChartDataAvailability }
    }, [chartData, timeSliderChartData])

  const createXLineChartAxis = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const availability = isTimeSliderChartConfig
      ? timeSliderChartDataAvailability
      : chartDataAvailability
    return [
      {
        key: 'x1',
        canvasIndex: '0',
        scale: {
          props: {
            type: 'scaleTime'
          },
          d3Ticks: d3.timeDay,
          categories: lineChartData?.timestamps || [],
          scaleSettings: {}
        },
        axis: {
          ...(!availability?.canShowBarChart && {
            visibleAt: ['bottomBottom']
          }),
          hideTicks: false,
          hideLine: false
        }
      }
    ]
  }

  const newTimestamps = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    return lineChartData?.timestamps?.slice(0, -1)
  }

  const customBarRenderer = useCallback((obj) => {
    const cX =
      obj?.x -
      (obj?.subScaleX?.bandwidth ? obj?.subScaleX?.bandwidth() : 0) -
      (obj?.subScaleX?.paddingInner
        ? obj?.subScaleX?.paddingInner() * obj?.subScaleX?.bandwidth()
        : 0) -
      (obj?.subScaleX?.paddingOuter
        ? obj?.subScaleX?.paddingOuter() * obj?.subScaleX?.bandwidth()
        : 0)
    return {
      cX
    }
  }, [])

  const getLegends = (data) => {
    if (!data) return
    const legends = []
    for (let i = 0; i < data?.data?.length; i++) {
      const propertyKey = data?.data[i].propertyKey

      const isFillNeeded = [
        'Temperature',
        'EnergySavings',
        'EnergyOverage'
      ].includes(propertyKey)

      legends.push({
        isTranslate: true,
        id: i + 1,
        name: data?.data[i]?.propertyName,
        seriesKey: i + 1,
        elementProps: {
          stroke: propertyAxis[propertyKey]?.color,
          strokeWidth: 2,
          ...(isFillNeeded && {
            fill: propertyAxis[propertyKey]?.color
          })
        },
        xAxisKey: propertyAxis[propertyKey]?.xAxis,
        yAxisKey: propertyAxis[propertyKey]?.yAxis,
        canvasIndex: propertyAxis[propertyKey]?.canvasIndex,
        dashType: 'solid',
        properties: {},
        settings: {
          curve: {
            type:
              propertyKey === 'Temperature' ? 'curveCardinal' : 'curveLinear',
            settings: {
              tension: 0.5
            }
          }
        },
        seriesDetails: {
          propertyKey
        },
        shape: 'Line',
        show: true,
        visible: true,
        type: propertyAxis[propertyKey]?.chartType,
        customSettings: {
          customRenderer: customBarRenderer
        },
        tooltipUnit: unit,
        tooltipDataModelFormatter:
          propertyKey === 'Temperature'
            ? ({ value, xValue, yValue, ...props }) => {
                return {
                  // value: value,
                  value: `${yValue[0]} °F / ${yValue[1]} °F`,
                  title: format(new Date(xValue), 'EEEE MMMM, do yyyy')
                }
              }
            : ({ xValue, yValue, tooltipUnit, ...props }) => {
                return {
                  value: `${Number(yValue).toFixed(2)} ${tooltipUnit}`,
                  title: format(new Date(xValue), 'EEEE MMMM, do yyyy')
                }
              }
      })
    }
    const plotLines = eventMarkers?.map((marker, index) => {
      return {
        axisKey: 'x1',
        show: true,
        visible: true,
        id: `pl-${index}`,
        stroke: 'lightgray',
        strokeWidth: 3,
        value: Date.parse(marker.markerDate),
        dashType: 'solid',
        name: marker?.name,
        type: 'plotLine',
        canvasIndex: '0',
        text: {
          dominantBaseline: 'middle',
          fontSize: 11,
          fontColor: 'gray',
          background: {
            fill: '#F5F5F5',
            fillOpacity: '0.3',
            stroke: 'gray',
            strokeWidth: '1'
          }
        }
      }
    })
    legends.push(...plotLines)
    return legends
  }

  const { chartDataLegendsArray, timeSliderChartDataLegendsArray } =
    useMemo(() => {
      try {
        const chartDataLegendsArray = getLegends(chartData)
        const timeSliderChartDataLegendsArray = getLegends(timeSliderChartData)
        return { chartDataLegendsArray, timeSliderChartDataLegendsArray }
      } catch (error) {
        return []
      }
    }, [
      chartData,
      timeSliderChartData,
      format,
      unit,
      customBarRenderer,
      propertyAxis
    ])

  const getDataObjects = (dataObj, legendsArray) => {
    const data = {}

    // Temperature data, always comes first
    dataObj?.data
      ?.filter((x) => x?.propertyKey === 'Temperature')
      .map((element) => {
        data[1] = {
          data: element?.values?.[0].map((x) => x?.value ?? null)
        }
      })

    // Other chart data
    dataObj &&
      dataObj.data &&
      dataObj?.data
        ?.filter((x) => x?.propertyKey !== 'Temperature')
        .map((element, index) => {
          data[index + 2] = {
            data: element?.values?.[0].map((x) => {
              if (x?.value !== undefined && x?.value !== null) {
                if (meterType === 'ElectricMeter' && unit === 'kBTUs') {
                  return Number(x?.value)
                } else if (meterType !== 'ElectricMeter' && unit === 'kWh') {
                  return Number(x?.value)
                } else {
                  return Number(x?.value)
                }
              } else {
                return null
              }
            })
          }
        })

    const barPropertyKeys = ['EnergySavings', 'EnergyOverage']
    const barDataSeriesKeys = legendsArray?.filter((obj) =>
      barPropertyKeys?.includes(obj?.seriesDetails?.propertyKey)
    )

    const barData = []

    barDataSeriesKeys?.forEach((obj) => {
      const values = data?.[obj?.seriesKey]?.data || []
      barData.push(...values)
    })

    let barExtent = d3.extent(barData)

    barExtent = barExtent?.map((val) => (val < 0 ? -val : val))

    const maxNumber = max(barExtent)

    function roundUpToNearestTen(number) {
      return Math.ceil(number / 10) * 10
    }

    const roundedMax = roundUpToNearestTen(maxNumber)

    const defaultRangeBarAxis = [-roundedMax, roundedMax * 2]

    return {
      defaultRangeBarAxis,
      configDataObject: data
    }
  }

  // const { configDataObject, defaultRangeBarAxis } = useMemo(() => {
  const { chartDataObject, timeSliderChartDataObject } = useMemo(() => {
    try {
      const chartDataObject = getDataObjects(chartData, chartDataLegendsArray)
      const timeSliderChartDataObject = getDataObjects(
        timeSliderChartData,
        timeSliderChartDataLegendsArray
      )
      return { chartDataObject, timeSliderChartDataObject }
    } catch (error) {
      console.log(error)
      return {
        configDataObject: {}
      }
    }
  }, [
    chartData,
    timeSliderChartData,
    unit,
    chartDataLegendsArray,
    timeSliderChartDataLegendsArray
  ])

  const createBarChartAxis = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    return [
      {
        key: 'x2',
        canvasIndex: '1',
        scale: {
          props: {
            type: 'scaleBand'
          },
          subScaleSettings: {
            paddingInner: '0.1',
            paddingOuter: '0.4'
          },
          categories: newTimestamps(isTimeSliderChartConfig) || []
        },
        axis: {
          hideTicks: false,
          hideLine: false
        },
        grid: [
          // {
          //   tickCount: 8,
          //   visibleAt: ['bottomBottom'],
          //   stroke: '#F4F4F4',
          //   includeAxis: false
          // }
        ]
      },
      {
        key: 'x3',
        canvasIndex: '1',
        scale: {
          props: {
            type: 'scaleTime'
            // defaultRange: ['2024-01-01T00:00:00', '2024-06-01T00:00:00']
          },
          d3Ticks: d3.timeDay,
          scaleSettings: {},
          categories: lineChartData?.timestamps || []
        },
        axis: {
          hideTicks: false,
          hideLine: true,
          visibleAt: ['bottomBottom']
        }
      }
    ]
  }

  const createXAxis = (isTimeSliderChartConfig) => {
    const lineChartAxis = createXLineChartAxis(isTimeSliderChartConfig)
    const availability = isTimeSliderChartConfig
      ? timeSliderChartDataAvailability
      : chartDataAvailability
    let barChartAxis = []
    if (availability?.canShowBarChart) {
      barChartAxis = createBarChartAxis(isTimeSliderChartConfig)
    }
    return [...lineChartAxis, ...barChartAxis]
  }

  const createYAxisLineChart = () => {
    return [
      {
        key: 'y1',
        canvasIndex: '0',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          name: {
            symbol: unit,
            text: 'Daily use'
          },
          hideLine: true,
          hideTicks: true,
          visibleAt: ['leftLeft'],
          notD3: true
          // format: (value) => parseFloat(value)?.toFixed(0)
        },
        grid: [
          {
            stroke: 'gray',
            opacity: 0.3,
            includeAxis: true
          }
        ]
      },
      {
        key: 'y2',
        canvasIndex: '0',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          name: {
            symbol: '°F',
            text: 'Temperature',
            alignment: 'start'
          },
          hideLine: true,
          hideTicks: true,
          visibleAt: ['rightRight'],
          notD3: true
        },
        grid: []
      }
    ]
  }

  const createYAxisBarLineChart = (isTimeSliderChartConfig) => {
    const dataObject = isTimeSliderChartConfig
      ? timeSliderChartDataObject
      : chartDataObject
    return [
      {
        key: 'y3',
        canvasIndex: '1',
        scale: {
          props: {
            defaultRange: dataObject?.defaultRangeBarAxis,
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          tickCount: 4,
          name: {
            symbol: unit,
            text:
              compareTo === 'baseline'
                ? 'Actual vs Predicted'
                : 'Actual vs Previous',
            alignment: 'start'
          },
          hideLine: true,
          hideTicks: true,
          visibleAt: ['leftLeft'],
          notD3: true
        },
        grid: [
          {
            tickCount: 4,
            stroke: 'lightgray',
            opacity: 0.5,
            includeAxis: true
          }
        ]
      },
      {
        key: 'y4',
        canvasIndex: '1',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          tickCount: 4,
          name: {
            symbol: unit,
            text: 'Total savings',
            alignment: 'start'
          },
          hideLine: true,
          hideTicks: true,
          visibleAt: ['rightRight'],
          notD3: true
        },
        grid: []
      }
    ]
  }

  const createYAxis = (isTimeSliderChartConfig) => {
    const lineChartYAxis = createYAxisLineChart()
    const availability = isTimeSliderChartConfig
      ? timeSliderChartDataAvailability
      : chartDataAvailability
    let barYAxis = []
    if (availability?.canShowBarChart) {
      barYAxis = createYAxisBarLineChart(isTimeSliderChartConfig)
    }
    return [...lineChartYAxis, ...barYAxis]
  }

  const chartConfigData = (isTimeSliderChartConfig = false) => {
    const availability = isTimeSliderChartConfig
      ? timeSliderChartDataAvailability
      : chartDataAvailability
    const dataObject = isTimeSliderChartConfig
      ? timeSliderChartDataObject
      : chartDataObject
    const legendsArray = isTimeSliderChartConfig
      ? timeSliderChartDataLegendsArray
      : chartDataLegendsArray
    return {
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: colors.WHITE,
              opacity: '1'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              link: true
            }
          },
          1: {
            renderAreaShade: {
              fill: colors.WHITE,
              opacity: '1'
            },
            tooltip: {
              type: 'x',
              referenceLineV: true,
              link: true
            }
          }
        },
        share: availability?.canShowBarChart ? [70, 30] : [70],
        spaceInBetween: [50]
      },
      x: createXAxis(isTimeSliderChartConfig),
      y: createYAxis(isTimeSliderChartConfig),
      series: {
        types: legendsArray,
        data: dataObject?.configDataObject
      },
      width: '100%',
      height: 550,
      // Required to set below config when displaying timeline
      // svgPadding: { bottom: 120 },
      // container: {
      //   className: 'energy-consumption-line-chart-wrapper'
      // },
      dataLastUpdatedAt: moment().unix(),
      legends: {
        className: 'engery-consumption-chart-legend'
      }
    }
  }

  // Integration team has to pass start & end date and a listener to revice the current selected date time from timeline
  // Based on recevied date range from timeline, need to filter the data and pass to main chart.
  // timeline chart data should not be filtered based on timeline date range.
  const timelineProps = useMemo(() => {
    return {
      trackStartTime: '01-01-2023',
      trackEndTime: '06-30-2023',
      getBrushPosition: (d) => {}
    }
  }, [])

  // Integration team has to pass required data that needs to be rendered in timeline chart
  // Display only lines, area charts, hide other elements like axis, tooltip, legend, markers etc...
  const timelineChartConfigData = useMemo(
    () => ({
      ...{ ...chartConfigData(true) },
      height: 55,
      legends: null,
      svgPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      container: {},
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: 'transparent',
              opacity: '0'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              hide: true
            }
          }
        }
      }
    }),
    [timeSliderChartData]
  )
  let selectedLanguage = store?.getState().appData?.selectedLanguage
  selectedLanguage = selectedLanguage ? selectedLanguage : "en"

  return chartConfigData()?.series.types?.length > 0 &&
    isDataAvailableForChart &&
    (chartDataAvailability?.actualConsumptionDataAvailable ||
      chartDataAvailability?.canShowBarChart) ? (
    <div data-testid={testName}>
      <XYChart {...chartConfigData()}>
        <TimelineWithChart
          // hideChart
          startTime={chartDates?.chartStartDate}
          endTime={chartDates?.chartEndDate}
          getSelctedRange={(d) => {
            const endTime = endDateDecider(
              d?.startTime,
              d?.endTime,
              chartDateFormat
            )
            setTimeSliderStartTime(selectedLanguage === "en" ? d?.startTime : formatDateByMomentInEng(d?.startTime))
            setTimeSliderEndTime(selectedLanguage === "en" ? endTime : formatDateByMomentInEng(endTime))
          }}
          xAxis={timelineChartConfigData?.x ?? []}
          yAxis={timelineChartConfigData?.y ?? []}
          series={timelineChartConfigData?.series ?? {}}
          className={'energy-consumption-time-slider'}
        />
      </XYChart>
      {compareTo === 'baseline' && !chartDataAvailability?.canShowBarChart && (
        <NoDataMessage><TranslateComponent>Baseline data not available</TranslateComponent></NoDataMessage>
      )}
      {compareTo === 'previous year' &&
        !chartDataAvailability?.canShowBarChart && (
          <NoDataMessage><TranslateComponent>Previous year data not available</TranslateComponent></NoDataMessage>
        )}
    </div>
  ) : (
    <NoDataMessage><TranslateComponent>No data available for selected period</TranslateComponent></NoDataMessage>
  )
}
