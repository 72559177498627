import COLORS from 'src/components/legacy/common/colors.json'
import moment from 'moment'
import { filterMap } from 'src/components/legacy/common/helpers'
import { getParams } from 'src/components/legacy/common/history'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import {
  CALCULATION_PREREADY_FINDING_STATUSES,
  CALCULATION_READY_FINDING_STATUSES
} from './finding'
import _union from 'lodash/union'

export const MAX_LENGTH_OF_NAME = 200

export const MAX_PAGE_WIDTH = 1220

export const STATUS_LIST = [
  'recommended',
  'deferred',
  'customerDeferred',
  'inProgress',
  'validation',
  'complete'
] // order of items in this array matters

export const KPI_LIST_KEYS = {
  EnergyUsage: 'energyUsage',
  Comfort: 'comfort',
  Reliability: 'reliability',
  Performance: 'performance',
  Compliance: 'compliance'
}
export const KPI_KEYS = {
  EnergyUsage: 'Energy',
  Comfort: 'Comfort',
  Reliability: 'Reliability',
  Performance: 'Performance',
  Compliance: 'Compliance'
}
export const STATUS_OTHER_ELEMENT = 'other'
export const KPI_LEVEL_LIST = ['high', 'medium', 'low']
export const KPI_SPECIFIC_VALUE = 'specificValue'
export const KPI_LIST = [
  { name: 'energyUsage', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'comfort', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'reliability', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'performance', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'compliance', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: '', value: false, priority: KPI_LEVEL_LIST[2], custom: 1 },
  { name: '', value: false, priority: KPI_LEVEL_LIST[2], custom: 2 }
]

export function formatKPILevel(id, t) {
  return t(`components:kpi>${id}`) || id
}

export const PRICE_SAVINGS_LIST = ['estimated', 'proposed']
export const COST_SAVINGS_LIST = {
  savings: {
    enabled: false,
    tag: 'total',
    value: 0
  },
  timeToUse: {
    tag: 'months',
    value: '0'
  },
  costOfWaiting: {
    value: false
  },
  calculationNotes: '',
  costToImplement: {
    enabled: false,
    value: 0,
    priceType: 'estimated'
  },
  paybackCalculation: {
    enabled: false,
    value: 0
  },
  roiCalculation: {
    enabled: false,
    value: 0
  },
  lifecycle: {
    value: '0',
    tag: 'years'
  }
}

export const HEADLINE = {
  title: '',
  description: ''
}

export const NEXT_STEPS = new Map([
  ['traneOfficeReview', 'Trane Office Review'],
  ['buildingPersonnelReview', 'Building Personnel Review'],
  ['remoteService', 'Remote Service'],
  ['BASTechOnSite', 'BAS Tech On-Site'],
  ['mechTechOnSide', 'Mech Tech On-Site'],
  ['complete', 'Complete'],
  ['archive', 'Archive']
])

export const NEXT_STEPS_LIST = [...NEXT_STEPS.keys()]

export const NEXT_STEPS_OPTION_LIST = Array.from(
  NEXT_STEPS,
  ([key, value]) => ({ key, value })
)

export function nextStepOptionList(translateFunc) {
  return NEXT_STEPS_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc ? translateFunc(`components:nextSteps>${key}`) : value
  }))
}

export function formatNextStep(id) {
  return NEXT_STEPS.get(id)
}

export function formatNextStepWithTranslateFunc(key, translateFunc) {
  return translateFunc(`components:nextSteps>${key}`) || key
}

export const PRIORITY_COLORS = new Map([
  ['Not Prioritized', COLORS.TBA_LIGHT_GREY],
  ['URGENT!', COLORS.URGENT],
  ['High', COLORS.HIGH],
  ['Medium', COLORS.MEDIUM],
  ['Low', COLORS.LOW]
])

export const PRIORITY_LIST = [...PRIORITY_COLORS.keys()]

export function priorityOptionList(translateFunc) {
  return PRIORITY_LIST.map((item) => ({
    key: item,
    value: translateFunc ? translateFunc(`components:priority>${item}`) : item
  }))
}

export function formatPriority(id) {
  return PRIORITY_COLORS.get(id) || id
}

export function formatSavingsView(id, t) {
  return t(`components:savings>${id}`) || id
}

export function getSavingsStatus(value) {
  value = parseFloat(value)
  if (isNaN(value)) {
    return ''
  }
  return value < 0 ? 'negative' : 'positive'
}

export function costOfWaitingValue(costSavings) {
  const settings = costSavings.costOfWaiting && costSavings.costOfWaiting.value

  if (!settings) {
    return null
  }

  let savingsDays = getTimeConversionDays(costSavings.savings.tag)
  if (!savingsDays) {
    if (costSavings?.timeToUse?.value) {
      savingsDays = getTimeConversionDays(
        costSavings.timeToUse.tag,
        costSavings.timeToUse.value
      )
    } else {
      return null
    }
  }

  const savingsPerDay = getSavingsPerDay(savingsDays, costSavings.savings.value)
  const startDateFormat = moment(
    costSavings.costOfWaiting.startDate
  ).toISOString()
  const costOfWaitingDays = moment().diff(startDateFormat, 'days')

  if (!savingsPerDay || costOfWaitingDays < 1 || startDateFormat === null) {
    return null
  }

  return {
    value: savingsPerDay * costOfWaitingDays,
    date: moment(costSavings.costOfWaiting.startDate).format('MMM D, YYYY')
  }
}
function getSavingsPerDay(days, value) {
  return value && days ? value / days : null
}

// TODO refactor concept of formulas
const TIME_CONVERSION = new Map([
  ['hours-days', 1 / 24],
  ['days-days', 1],
  ['days-weeks', 1 / 7],
  ['days-months', 1 / 30.44],
  ['days-years', 1 / 365.25],
  ['weeks-days', 7],
  ['weeks-weeks', 1],
  ['weeks-months', 1 / 4.35],
  ['weeks-years', 1 / 52.18],
  ['months-days', 30.44],
  ['months-weeks', 4.35],
  ['months-months', 1],
  ['months-years', 1 / 12],
  ['years-days', 365.25],
  ['years-weeks', 52.18],
  ['years-months', 12],
  ['years-years', 1]
])

export function convertTime(id) {
  return TIME_CONVERSION.get(id)
}

export function getTimeConversionDays(tag, value) {
  const daysCount = TIME_CONVERSION.get(`${tag}-days`)

  if (!daysCount) {
    return null
  }

  return value ? value * daysCount : daysCount
}

export const STATUS = new Map([
  ['recommended', 'Recommended'],
  ['deferred', 'Trane Deferred'],
  ['customerDeferred', 'Customer Deferred'],
  ['inProgress', 'In Progress'],
  ['validation', 'Validation'],
  ['complete', 'Complete'],
  ['archive', 'Archive']
])

export const CALCULATION_PREREADY_OPPORTUNITY_STATUSES = [
  'recommended',
  'deferred',
  'customerDeferred'
]
export const CALCULATION_READY_OPPORTUNITY_STATUSES = [
  'inProgress',
  'validation',
  'complete'
]
const statusTranslate = {
  allStatuses: "All Statuses",
  recommended: "Recommended",
  deferred: "Trane Deferred",
  proposalDevelopment: "Recommended",
  proposalReady: "Recommended",
  inProgress: "In Progress",
  validation: "Validation",
  complete: "Complete",
  customerDeferred: "Customer Deferred",
  collectingData: "Collecting Data",
  readyForReview: "Ready for Review",
  archive: "Archive",
  other: "Other"
}

export function formatStatus(id) {
  return STATUS.get(id)
}
export function formatStatusWithTranslateFunc(key) {
  return statusTranslate[key] || key
}
export function statusOptionList(translateFunc) {
  return STATUS_LIST.map((item) => ({
    key: item,
    value: translateFunc
      ? statusTranslate[item]
      : formatStatus(item)
  }))
}
export function statusOptionListForTree(translateFunc) {
  return STATUS_LIST.map((item) => ({
    value: item,
    label: translateFunc
      ? translateFunc(`components:statuses>${item}`)
      : formatStatus(item)
  }))
}

export const STATUS_OPTION_LIST = statusOptionList()

export function formatPrice(id, t) {
  return t(`components:costSavings>${id}`) || id
}

// Map with full list of time periods
const TIME_VALUES = new Map([
  ['hours', 'Hours'],
  ['days', 'Days'],
  ['weeks', 'Weeks'],
  ['months', 'Months'],
  ['years', 'Years'],
  ['total', 'Total']
])

const TIME_TO_USE = filterMap(TIME_VALUES, ([key]) =>
  ['days', 'weeks', 'months', 'years'].includes(key)
)

export const TIME_VALUES_LIST = [...TIME_VALUES.keys()]
export const TIME_TO_USE_LIST = [...TIME_TO_USE.keys()]
export const LIFECYCLE_LIST = [
  ...filterMap(TIME_VALUES, ([key]) => key !== 'total').keys()
]
export const TIME_TO_FIX_LIST = [
  ...filterMap(
    TIME_VALUES,
    ([key]) => key !== 'hours' && key !== 'total'
  ).keys()
]
export const SAVINGS_LIST = [
  ...filterMap(
    TIME_VALUES,
    ([key]) => key !== 'hours' && key !== 'days' && key !== 'weeks'
  ).keys()
]

export function formatTimeValues(id) {
  return TIME_VALUES.get(id) || id
}

export const SORTING_LIST = [
  'status',
  'priceLowToHigh',
  'priceHighToLow',
  'roiLowToHigh',
  'roiHighToLow'
]

export const DEFAULT_SORT = SORTING_LIST[0]

export function sortingList(translateFunc) {
  return SORTING_LIST.map((item) => ({
    key: item,
    value: translateFunc
      ? translateFunc(`opportunities:SortingList>${item}`)
      : item
  }))
}

export function getSortValueByKey(key) {
  const item = SORTING_LIST.find((s) => s.key === key)

  return item ? item.value : key
}

export const paramsNeededForHistory = ['locationIds', 'startDate', 'endDate']

export function prepareVariablesToGetHistory() {
  const queryParams = getParams()
  return queryParams['locationIds']
    ? queryParams['locationIds'].split(',').map((l) => Number(l))
    : []
}

export function filterByIncludedToConsultation(array) {
  const { startDate, endDate, showStatuses } = getParams()
  const filteredArray = array.filter(
    ({
      history,
      creationDate,
      lastChangeTimestamp: lastModifiedDate,
      status
    }) =>
      isItemInConsultation({
        history,
        startDate,
        endDate,
        creationDate,
        lastModifiedDate,
        status,
        showStatuses
      })
  )

  return filteredArray
}

function isItemInConsultation({
  history,
  startDate,
  endDate,
  creationDate,
  lastModifiedDate,
  status,
  showStatuses = READY
}) {
  const { workingMonthAmount, lastStatus } = getAmountOfWorkingMonths({
    startDate,
    endDate,
    creationDate,
    lastModifiedDate,
    status,
    history
  })

  if (showStatuses == READY) {
    return Boolean(workingMonthAmount)
  }
  if (showStatuses == PRE_READY) {
    return isStatusPreReady(lastStatus)
  }
}

// Specific case when no history logged
// Applied to items which were created before logging was introduced
function historyFallback({
  statusHistory,
  creationDate,
  lastModifiedDate,
  endDateOfCalculations,
  status
}) {
  if (!statusHistory.length) {
    let historyChangeTimestamp = null
    if (
      lastModifiedDate &&
      moment(lastModifiedDate).endOf('month') <= endDateOfCalculations
    ) {
      // Do the best guess we can
      // There is a possibility that item has current status before lastModifiedDate
      // as we don't know whether last modification included status changing or not
      historyChangeTimestamp = lastModifiedDate
    } else if (
      creationDate &&
      moment(creationDate).endOf('month') <= endDateOfCalculations
    ) {
      // There is a possibility that item got current status after endDate
      // as there are some modifications after endDate
      // but we add one history item within the scope of date range
      historyChangeTimestamp = creationDate
    }

    if (historyChangeTimestamp) {
      statusHistory.push({
        createdByTimestamp: historyChangeTimestamp,
        status
      })
    }
  }
}

export function getAmountOfWorkingMonths({
  startDate,
  endDate,
  creationDate,
  lastModifiedDate,
  status,
  history: statusHistory
}) {
  // Here we'll calculate amount of effective months (with calculation ready statuses)
  // Only last status in month matters (if there were few in one)
  const startDateOfCalculations = moment(startDate, DATE_FORMAT).endOf('month')
  const endDateOfCalculations = moment(endDate, DATE_FORMAT).endOf('month')

  if (!statusHistory) {
    statusHistory = []
  }
  historyFallback({
    statusHistory,
    creationDate,
    lastModifiedDate,
    endDateOfCalculations,
    status
  })

  let currentMonth = startDateOfCalculations
  let currentStatus = null
  let changeStatusTime = null
  let lastStatusInMonth = null
  let workingMonthAmount = 0

  for (let i = 0; i < statusHistory.length; i++) {
    changeStatusTime = moment(statusHistory[i].createdByTimestamp)
    if (changeStatusTime > endDateOfCalculations) {
      break
    }
    currentStatus = statusHistory[i].status

    // Save each status until reach the end of current month
    if (changeStatusTime <= currentMonth) {
      lastStatusInMonth = currentStatus
    } else {
      // Being here means current status changing time is beyond calculated month
      // We check whether last saved status was calculation ready
      // if yes - we count previous month(s) as working
      if (CALCULATION_READY_STATUSES.includes(lastStatusInMonth)) {
        workingMonthAmount += diffOfMonths(currentMonth, changeStatusTime)
      }
      // Important logic, described here https://tranetis.jira.com/browse/TE-24382
      // if an item goes from “Complete” back to “Recommended” “Trane Deferred” or “Customer Deferred”,
      // we should remove the record that it was “Complete”
      if (STATUSES_TO_RESET_PREVIOUS_STATUSES.includes(lastStatusInMonth)) {
        workingMonthAmount = 0
      }

      if (changeStatusTime < endDateOfCalculations) {
        currentMonth = moment(changeStatusTime).endOf('month')
        lastStatusInMonth = currentStatus
      }
    }
  }

  // If last status is working - count month(s) till the endDate
  // +1 includes the last month
  if (CALCULATION_READY_STATUSES.includes(currentStatus)) {
    workingMonthAmount += diffOfMonths(currentMonth, endDateOfCalculations) + 1
  }

  return { lastStatus: lastStatusInMonth, workingMonthAmount }
}

function diffOfMonths(startDate, endDate) {
  // Moment.js month diff issues
  // https://github.com/moment/moment/issues/3029
  return Math.ceil(
    moment(endDate)
      .startOf('month')
      .diff(moment(startDate).startOf('month'), 'month', true)
  )
}

export const CALCULATION_PREREADY_STATUSES = _union(
  CALCULATION_PREREADY_FINDING_STATUSES,
  CALCULATION_PREREADY_OPPORTUNITY_STATUSES
)
export const CALCULATION_READY_STATUSES = _union(
  CALCULATION_READY_FINDING_STATUSES,
  CALCULATION_READY_OPPORTUNITY_STATUSES
)
const STATUSES_TO_RESET_PREVIOUS_STATUSES = [
  'recommended',
  'customerDeferred',
  'deferred'
]

export function isStatusPreReady(status) {
  return CALCULATION_PREREADY_STATUSES.includes(status)
}
export function isStatusReady(status) {
  return CALCULATION_READY_STATUSES.includes(status)
}

export const READY = 'ready'
export const PRE_READY = 'preready'

export const TRACK_CHANGED_FIELDS = new Map([
  ['name', 'Name'],
  ['isPublic', 'Visible to Customer'],
  ['settings.proposedBy', 'Proposed By'],
  ['settings.proposalDate', 'Creation Date'],
  ['organization.organizationId', 'Organization'],
  ['location.locationId', 'Building(s)'],
  ['tisObjects', 'Equipment(s)'],
  ['settings.costSavings', 'Cost Savings Metrics'],
  ['settings.kpi', 'KPIs'],
  ['settings.headlines', 'Headlines'],
  ['settings.priority', 'Priority level'],
  ['settings.file', 'Upload file'],
  ['settings.images', 'Images'],
  ['status', 'Status'],
  ['settings.nextStep', 'Next Step'],
  ['settings.assignedTo', 'Assigned To']
])

export const TRACK_CHANGED_FIELDS_LIST = [...TRACK_CHANGED_FIELDS.keys()]

export function formatTrackChangedFields(id) {
  return TRACK_CHANGED_FIELDS.get(id) || id
}

export const showCorrectPriority = (priority, savings, t) =>
  priority === KPI_SPECIFIC_VALUE ? `$${savings}` : formatKPILevel(priority, t)

export const TEXT_EDITOR_TOOLBAR_OPTIONS = {
  options: ['blockType', 'inline', 'textAlign', 'link', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline']
  },
  link: {
    options: ['link']
  },
  list: {
    options: ['unordered', 'ordered']
  },
  textAlign: {
    options: ['left', 'center', 'right']
  }
}
