import { useEffect, useState } from 'react'

// Local imports
import {
  MarkNames,
  PlotChartValueType,
  EnergyIntensityMarksOrderState
} from '../types.d'

export const useEnergyIntensityMarksOrder = (
  initialMarksOrder: MarkNames[],
  setOrder: (valueType: PlotChartValueType) => MarkNames[]
): EnergyIntensityMarksOrderState => {
  // State values to keep track of the render order of the marks.
  const [marksOrder, setMarksOrder] = useState<MarkNames[]>(initialMarksOrder)
  //const [eciMarksOrder, setEciMarksOrder] = useState<MarkNames[]>(marksOrder ?? ECI_MARKS_ORDER)

  // Keep track of the most recent mark we are focusing on.
  const [plotValueType, setPlotValueType] = useState<PlotChartValueType>(
    PlotChartValueType.NONE
  )

  // If the type of value changes, then we need to update the order of the marks.
  useEffect(() => {
    // If none we default to the initial marks order.
    if (plotValueType === PlotChartValueType.NONE) {
      setMarksOrder(initialMarksOrder)
      return
    }

    const updatedMarksOrder = setOrder(plotValueType)
    setMarksOrder(updatedMarksOrder)
  }, [plotValueType])

  return {
    marksOrder,
    setMarksOrder,
    plotValueType,
    setPlotValueType
  }
}
