import { DropZone } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faBan
} from 'src/denali-components/lib/pro-solid-svg-icons'

export const DropZoneAccepted = ({ children }) => {
  return (
    <DropZone.Accepted>
      <FontAwesomeIcon icon={faDownload} />
      {children}
    </DropZone.Accepted>
  )
}

export const DropZoneRejected = ({ children }) => {
  return (
    <DropZone.Rejected>
      <FontAwesomeIcon icon={faBan} />
      {children}
    </DropZone.Rejected>
  )
}

export const DropZoneDefault = ({ children }) => {
  return <DropZone.Default>{children}</DropZone.Default>
}
