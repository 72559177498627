/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { connect } from 'react-redux'
import './user-management.scss'
import AddUserStage from './user-management-add-user-stage'
import PermissionStage from './user-management-add-permission-stage'
import ApplicationStage from './user-management-add-application-stage'
import LocationStage from './user-management-add-locations-stage'
import AccessStage from './user-management-add-access-stage'
import i18next from 'i18next'
import Modal from 'src/denali-ui/components/Modal'
import { Views } from './Views'
import { NewStyles } from 'src/NewStyles'
import TraneTheme from 'src/denali-ui/components/ThemeProvider'
import {
  CREATE_OR_EDIT_B2C_COGNITO_USER,
  CREATE_USER,
  updateUser as updateUserMutation,
  CREATE_BULK_CUSTOM_USER_ACCESS,
} from '../graphql/mutations'
import { useNavigate, useParams } from 'react-router-dom'
import { IS_USER_UNIQUE, GET_USER_BY_ID, LIST_USERTYPES, LIST_APPLICATIONS, LIST_CUSTOM_USER_ACCESS_BY_USER, SEARCH_BUILDINGS, GET_USERS_LAMBDA, GET_UNIQUE_USER_LAMBDA } from '../graphql/queries'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { pages } from 'src/pages/pages.js'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import moment from "moment"
import { API } from 'aws-amplify'
import { setGlobalError } from 'src/redux/slicers/globalErrorSlice';
import { useAppDispatch } from 'src/redux/store'
import { TranslateComponent } from '../../../common/translations'
import config from 'src/aws-exports.js'
import { EMEA_PROJECT_REGION } from 'src/components/Header/constants'

const baseUserInfoObj = {
  firstName: { value: '', validationType: 'text' },
  lastName: { value: '', validationType: 'text' },
  email: { value: '', validationType: 'email' },
  secondaryEmail: { value: null, validationType: 'email', optional: true },
  userType: { value: '', validationType: 'userType', modified: false },
  userAsset: { value: '', validationType: 'userAsset' },
  applications: {}
}

export const CreateUser = (props) => {
  const userInfoStore = useSelector(selectUserInfo)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cancelDialog, setCancelDialog] = useState(false)
  const stage = useState(0)
  const { id, loc } = useParams()
  const dispatch = useAppDispatch();
  const {data: userTypesList} = useQuery({
    query: LIST_USERTYPES,
    errorPolicy: 'all',
    dataPath: 'data.listUserTypes.items'
  })

  const {data: applicationList} = useQuery({
    query: LIST_APPLICATIONS,
    errorPolicy: 'all',
    dataPath: 'data.listApplications.items'
  })

  const { data: UserData, refetch: fetchEditUser } = useQuery({
    query: GET_USER_BY_ID,
    variables: { id: id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getUser'
  })

  const { refetch: fetchUserAccesses } = useQuery({
    query: LIST_CUSTOM_USER_ACCESS_BY_USER,
    variables: { id: id },
    disableInitialLoad: true,
    errorPolicy: 'all',
  })

  const {
    data: IsUserUnique,
    refetch: checkIsUserUnique,
    loading: loadingUniq
  } = useQuery({
    query: GET_UNIQUE_USER_LAMBDA, //IS_USER_UNIQUE,
    disableInitialLoad: true,
    dataPath: 'data.findUserByEmail'
  })

  const { refetch: fetchBuildings } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items'
  })

  const { onSubmit: createUser } = useMutation({
    query: CREATE_USER
  })

  const { onSubmit: createOrEditCognitoUser } = useMutation({
    query: CREATE_OR_EDIT_B2C_COGNITO_USER
  })

  const { onSubmit: createBulkCustomUserAccess } = useMutation({
    query: CREATE_BULK_CUSTOM_USER_ACCESS,
    onSuccess: (e) => {
      const parsed = JSON.parse(e.data.mutateBulkCustomUserAccess)
      const values = JSON.parse(parsed.body)
      if (values?.addErrorItems?.length || values?.deleteErrorItems?.length){
        const baseAddError = "Encountered the following errors:"
        const errorNames = values.addErrorItems.map(e => {
          return `Adding ${resourceControl[0][e.resType][e.resource].applications[e.appId][e.roleId].name} role to ${resourceControl[0][e.resType][e.resource].name}`
        })
        const errorRemoveNames = values?.deleteErrorItems?.length ? values?.deleteErrorItems?.map(e => {
          const value = currUserAccess[currUserAccess.findIndex(x => x.id === e)]
          return `Removing ${resourceControl[0][value.resType][value.resource].applications[value.appId][value.roleId].name} role from ${resourceControl[0][value.resType][value.resource].name}`
        }) : []
        errorNames.concat(errorRemoveNames)
        dispatch(setGlobalError({error: `${baseAddError} ${errorNames.concat(errorRemoveNames).join(", ")}`}))
      }
    }
  })

  const { onSubmit: updateUser } = useMutation({
    query: updateUserMutation
  })

  const [userInfoObject, setUserInfoObject] = useState()
  const resourceControl = useState({})
  const [currUserAccess, setCurrUserAccess] = useState({})
  const [urlToNavTo, setURLToNavTo] = useState(null)

  useEffect(() => {
    if (urlToNavTo) {
      navigate(urlToNavTo)
    }
  }, [urlToNavTo])

  const setEditResourceControlObj = (response) => {
    return response.items.reduce((accum, curr) => {
      if (!accum[curr.resType]) {
        accum[curr.resType] = {
          [curr.resource]: {
            applications: {
              [curr.appId]: {
                [curr.roleId]: {...curr.role}
              }
            }
          }
        }
      } else if (!accum[curr.resType][curr.resource]) {
        accum[curr.resType][curr.resource] = {
          applications: {
            [curr.appId]: {
              [curr.roleId]: {...curr.role}
            }
          }
      }
      } else if (!accum[curr.resType][curr.resource].applications[curr.appId]) {
        accum[curr.resType][curr.resource].applications[curr.appId] = {
          [curr.roleId]: {...curr.role}
        }
      } else {
        accum[curr.resType][curr.resource].applications[curr.appId] = {
          ...accum[curr.resType][curr.resource].applications[curr.appId],
          [curr.roleId]: {...curr.role}
        }
      }
      return accum
    }, {})
  }

  const getOldUserAppAccess = (response) => response.apps.items.reduce((accum, curr) => {
      if (accum.findIndex(e => e.applicationID === curr.applicationID) === -1) {
        accum.push(curr)
      }
      return accum
    }, [])

  const setEditUserAccess = (response, access) => {
    const applications = access.items.filter(r => Boolean(r.role)).reduce((accum, curr) => {
      if (!accum[curr.appId]) {
        accum[curr.appId] = {
          id: curr.appId,
          name: curr.app.name,
          description: curr.app.description,
          roles: {
            [curr.roleId]: {
              description: curr?.role?.description,
              resourceTypes: curr?.role?.resourceTypes,
              userTypes: curr?.role?.userTypes,
              id: curr.roleId,
              name: curr.role.name,
              type: curr.roleType,
              isActive: curr.role.isActive
            }
          }
        }
      } else {
        accum[curr.appId].roles[curr.roleId] = {
          description: curr?.role?.description,
          resourceTypes: curr?.role?.resourceTypes,
          userTypes: curr?.role?.userTypes,
          id: curr.roleId,
          name: curr.role.name,
          type: curr.roleType,
          isActive: curr.role.isActive
        }
      }
      return accum
    }, {})


    setUserInfoObject({
      firstName: { ...baseUserInfoObj.firstName, value: response.firstName },
      lastName: { ...baseUserInfoObj.lastName, value: response.lastName },
      email: { ...baseUserInfoObj.email, value: response.email },
      secondaryEmail: {
        ...baseUserInfoObj.secondaryEmail,
        value: response.secondaryEmail
      },
      userType: { ...baseUserInfoObj.userType, value: response?.userTypeId ?? '' },
      userAsset: {
        ...baseUserInfoObj.userAsset,
        value: response?.defaultUniqueAssetId ?? ''
      },
      applications: applications || {}
    })

    resourceControl[1](setEditResourceControlObj(access))
  }
  useEffect(() => {
    // This use effect sets up the base userInfoObject
    const setUserFunc = async () => {
      if (id) {
        await Promise.all([fetchEditUser(), fetchUserAccesses()]).then(response => {
          const tempCurrUserAccess = JSON.parse(JSON.parse(response[1].data.listCustomUserAccessByUser).body)
          setCurrUserAccess(tempCurrUserAccess)
          setEditUserAccess(response[0], tempCurrUserAccess)
        })
      } else {
        setUserInfoObject({
          ...baseUserInfoObj
        })
      }
  }
  setUserFunc()
  }, [])

  const validateIfUserEmailExists = async (value) => {
    const email =
      !userInfoObject.email.error && (value)
    return email
      ? checkIsUserUnique({
          email: value
        })
      : Promise.resolve(true)
  }

  const checkIfIsUserUnique = (value = IsUserUnique) => {
    if (IsUserUnique) {
      const objRoot = JSON.parse(IsUserUnique)
      const obj = JSON.parse(objRoot?.body)
      if (obj?.data?.searchUsers?.items) {
        if (obj.data.searchUsers.items?.length === 0) {
          return true
        }
      }
    }
    return false
  }
  const buildUserRequest = async () => {
    const salesOfficeIds = resourceControl[0].SalesOffice ? Object.keys(resourceControl[0].SalesOffice) : []
    const regionIds = resourceControl[0].Region ? Object.keys(resourceControl[0].Region) : []
    if (resourceControl[0].Region) {
      regionIds.push(...Object.keys(resourceControl[0].Region))
    }
    if (resourceControl[0].SalesOffice) {
      Object.keys(resourceControl[0].SalesOffice).forEach(e => {
        if (resourceControl[0].SalesOffice[e].regionId && regionIds.indexOf(resourceControl[0].SalesOffice[e].regionId) === -1) {
          regionIds.push(resourceControl[0].SalesOffice[e].regionId)
        }
        if (resourceControl[0].SalesOffice[e].globalRegionId && regionIds.indexOf(resourceControl[0].SalesOffice[e].globalRegionId) === -1) {
          regionIds.push(resourceControl[0].SalesOffice[e].globalRegionId)
        }
      })
    }
    const accountIds = resourceControl[0].Account ? Object.keys(resourceControl[0].Account) : []
    const buildingIds = resourceControl[0].Building ? Object.keys(resourceControl[0].Building) : []
    const contractorCompanyIds = resourceControl[0].ContractorCompany ? Object.keys(resourceControl[0].ContractorCompany) : []
    const bGroupsBld = resourceControl[0].Building ? Object.keys(resourceControl[0].Building).reduce((accum, curr) => {
      return [...accum, ...resourceControl[0].Building[curr].groupAdmin || [], ...resourceControl[0].Building[curr].groupUser || []]
    }, []) : []
    const bGroupsGrp = resourceControl[0].BuildingGroup ? Object.keys(resourceControl[0].BuildingGroup) : []
    const bGroups = [...bGroupsBld || [], ...bGroupsGrp || []]
    if (resourceControl[0]?.Building && Object.keys(resourceControl[0].Building).length) {
      await fetchBuildings({limit: 1000, filter: {or: Object.keys(resourceControl[0].Building).map(e => ({id: {eq: e}}))}}).then(res => {
        res.forEach(e => {
          if (e.salesOfficeId && salesOfficeIds.indexOf(e.salesOfficeId) === -1) {
            salesOfficeIds.push(e.salesOfficeId)
          }
          if (e.contractorCompanyId && contractorCompanyIds.indexOf(e.contractorCompanyId) === -1) {
            contractorCompanyIds.push(e.contractorCompanyId)
          }
          if (e.accountId && accountIds.indexOf(e.accountId) === -1) {
            accountIds.push(e.accountId)
          }
          if (e.regionId && regionIds.indexOf(e.regionId) === -1) {
            regionIds.push(e.regionId)
          }
          if (e.globalRegionId && regionIds.indexOf(e.globalRegionId) === -1) {
            regionIds.push(e.globalRegionId)
          }
        })
      })
    }
    if (resourceControl[0]?.Account && Object.keys(resourceControl[0].Account).length) {
      await fetchBuildings({limit: 1000, filter: {or: Object.keys(resourceControl[0].Account).map(e => ({accountId: {eq: e}}))}}).then(res => {
        res.forEach(e => {
          if (e.salesOfficeId && salesOfficeIds.indexOf(e.salesOfficeId) === -1) {
            salesOfficeIds.push(e.salesOfficeId)
          }
          if (e.contractorCompanyId && contractorCompanyIds.indexOf(e.contractorCompanyId) === -1) {
            contractorCompanyIds.push(e.contractorCompanyId)
          }
          if (e.regionId && regionIds.indexOf(e.regionId) === -1) {
            regionIds.push(e.regionId)
          }
          if (e.globalRegionId && regionIds.indexOf(e.globalRegionId) === -1) {
            regionIds.push(e.globalRegionId)
          }
        })
      })
    }
    // The logic for buildingsIds, salesOfficeIds, contractorCompanyIds, and accountIds should be built into the lambda


    return {
      firstName: userInfoObject.firstName.value,
      lastName: userInfoObject.lastName.value,
      userTypeId: userInfoObject.userType.value,
      email: userInfoObject.email.value,
      secondaryEmail: userInfoObject?.secondaryEmail?.value || "",
      userCreatedById: UserData?.userCreatedById || userInfoStore.id,
      userUpdatedById: userInfoStore.id,
      regionIds: regionIds.length ? regionIds : undefined,
      salesOfficeIds: salesOfficeIds.length ? salesOfficeIds : undefined,
      accountIds: accountIds.length ? accountIds : undefined,
      buildingIds: buildingIds.length ? buildingIds : undefined,
      contractCompanyIds: contractorCompanyIds.length ? contractorCompanyIds : undefined,
      bGroups: bGroups && bGroups.length ? [...new Set(bGroups)] : undefined,
      startDate: moment().format(),
      endDate: moment().add(1, "year").format(),
      isActive: 1
    }
  }

  const buildUserResources = (userId) => {
    //Add resources to remove
    if (!id) {
      // Create
      const resourcesToSet = Object.keys(resourceControl[0]).reduce((accum, resType) => accum.concat(Object.keys(resourceControl[0][resType]).reduce((accum2, resource) => accum2.concat(Object.keys(resourceControl[0][resType][resource].applications).reduce((accum3, appId) => accum3.concat(Object.keys(resourceControl[0][resType][resource].applications[appId]).map(roleId => ({
            appId,
            resource,
            resType,
            roleId,
            userId,
            type: resourceControl[0][resType][resource].applications[appId][roleId].type
          })))
          , []))
        , []))
      , [])
      const applicationsToSet = resourcesToSet.reduce((accum, curr) => {
        if (accum.findIndex(e => e.applicationID === curr.appId) === -1) {
          accum.push({applicationID: curr.appId, userID: userId})
        }
        return accum
      }, [])
    return [resourcesToSet, [], applicationsToSet, []]
    } else {
      // Edit
      const oldUserAccess = setEditResourceControlObj(currUserAccess)
      const oldAppAccess = getOldUserAppAccess(UserData)
      const resourcesToSet = Object.keys(resourceControl[0]).reduce((accum, resType) => accum.concat(Object.keys(resourceControl[0][resType]).reduce((accum2, resource) => accum2.concat(Object.keys(resourceControl[0][resType][resource].applications).reduce((accum3, appId) => accum3.concat(Object.keys(resourceControl[0][resType][resource].applications[appId]).map(roleId => {
        if (!resourceControl[0][resType][resource].applications[appId][roleId]?.userTypes.includes(userInfoObject?.userType?.value)) {
          return null
        }
        if (!resourceControl[0][resType][resource].applications[appId][roleId]?.resourceTypes.includes(resType)) {
          return null
        }
        return userInfoObject?.applications?.[appId]?.roles?.[roleId] ? ({
        appId,
        resource,
        resType,
        roleId,
        userId,
        type: resourceControl[0][resType][resource].applications[appId][roleId].type
      }) : null
    }).filter(e => e !== null)
    )
      , []))
    , []))
  , [])

  const allApplications = resourcesToSet.reduce((accum, curr) => {
    if (accum.findIndex(e => e.applicationID === curr.appId) === -1) {
      accum.push({applicationID: curr.appId, userID: userId})
    }
    return accum
  }, [])

  const applicationsToSet = allApplications.reduce((accum, curr) => {
    if (oldAppAccess.findIndex(e => e.applicationID === curr.applicationID) === -1 && accum.findIndex(e => e.applicationID === curr.applicationID) === -1) {
      accum.push({applicationID: curr.applicationID, userID: userId})
    }
    return accum
  }, [])

  const applicationsToRemove = oldAppAccess.reduce((accum, curr) => {
    if (allApplications.findIndex(e => e.applicationID === curr.applicationID) === -1 && accum.findIndex(e => e.applicationID === curr.applicationID) === -1) {
      accum.push({id: curr.id, applicationID: curr.applicationID})
    }
    return accum
  }, [])

  const oldResources = Object.keys(oldUserAccess).reduce((accum, resType) => accum.concat(Object.keys(oldUserAccess[resType]).reduce((accum2, resource) => accum2.concat(Object.keys(oldUserAccess[resType][resource].applications).reduce((accum3, appId) => accum3.concat(Object.keys(oldUserAccess[resType][resource].applications[appId]).map(roleId => ({
        appId, 
        resource, 
        resType, 
        roleId, 
        userId
      })))
      , []))
    , []))
    , [])

    const filteredRemoval = oldResources.filter(r => resourcesToSet.findIndex(s => s.roleId == r.roleId && s.resource == r.resource && s.resType == r.resType && s.appId == r.appId) === -1
        ).map(e => {
        const index = currUserAccess?.items?.findIndex(i => 
          e.resource == i.resource
          && e.roleId == i.roleId 
          && e.resType == i.resType
          && e.appId == i.appId
          )
        return {...e, userAccessId: currUserAccess.items[index].id}
      })
    const filteredSet = resourcesToSet?.filter(r => oldResources.findIndex(s => s.roleId == r.roleId 
      && s.resource == r.resource 
      && s.resType == r.resType
      && s.appId == r.appId
      ) === -1)
    return [filteredSet, filteredRemoval, applicationsToSet, applicationsToRemove]
    }
    
  }

  const saveExistingUser = async () => {
    setLoading(true)
    const [resourcesToSet, resourcesToRemove, applicationsToSet, applicationsToRemove] = buildUserResources(id)
    const user = {...await buildUserRequest(), id: id, userId: id }
    await Promise.all([
    resourcesToSet?.length || resourcesToRemove?.length ? createBulkCustomUserAccess({userId: id, addItems: resourcesToSet.map(e => JSON.stringify({
        appId: e.appId,
        resource: e.resource,
        resType: e.resType,
        roleId: e.roleId,
        roleType: e.type
      })
    ), deleteItems: resourcesToRemove.map(e => e.userAccessId) })
    : [],
    createOrEditCognitoUser({firstName: user.firstName, lastName: user.lastName, email: user.email, userId: id}),
    updateUser({input: user})
  ]).then((res) => {
    if (res.length) {
      setURLToNavTo(pages['User Management/user-details/:id'].href.replace(':id', id)) 
    }
  }).catch(err => {
    setLoading(false)
  })
  }

  const saveNewUser = async () => {
    setLoading(true)
    const l_user = await buildUserRequest()
    let user = l_user
    if(config.aws_project_region === EMEA_PROJECT_REGION){
      user = {...user, profileSettings: JSON.stringify({ "unitOfMeasurement": "si"})} 
    }
    await createOrEditCognitoUser({firstName: user.firstName, lastName: user.lastName, email: user.email.toLowerCase()}).then(async res => {
      await createUser({input: {...user, email: user.email.toLowerCase(), id: res.data.createNewUser, userId: res.data.createNewUser}}).then(async result => {    
        const [resourcesToSet, resourcesToRemove, applicationsToSet, applicationsToRemove]  = buildUserResources(result.data.createUser.id)
        await Promise.all([
          resourcesToSet?.length ? createBulkCustomUserAccess({userId: res.data.createNewUser, addItems: resourcesToSet.map(e => JSON.stringify({
            appId: e.appId,
            resource: e.resource,
            resType: e.resType,
            roleId: e.roleId,
            roleType: e.type
          })
        ) })
        : [],
      ]).then((res) => {
        if (res.length) {
          setURLToNavTo(pages['User Management/user-details/:id'].href.replace(':id', result.data.createUser.id)) 
        }
        }).catch(err => {
          setLoading(false)
        })
      })
    })
  }


  const validateFields = (obj, fieldsToValidate) => {
    const clonedObj = { ...obj }
    for (let i = 0; i < fieldsToValidate.length; i++) {
      switch (clonedObj[fieldsToValidate[i]].validationType) {
        case 'text':
          clonedObj[fieldsToValidate[i]].value === ''
            ? (clonedObj[fieldsToValidate[i]].error = true)
            : (clonedObj[fieldsToValidate[i]].error = false)
          break
        case 'email':
          let regex =
            /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i // eslint-disable-line
          if (
            clonedObj[fieldsToValidate[i]].value === '' ||
            !clonedObj[fieldsToValidate[i]].value
          ) {
            clonedObj[fieldsToValidate[i]].error =
              !clonedObj[fieldsToValidate[i]].optional
          } else {
            !regex.test(clonedObj[fieldsToValidate[i]].value)
              ? (clonedObj[fieldsToValidate[i]].error = true)
              : (clonedObj[fieldsToValidate[i]].error = false)
          }
          break
        case 'userType': {
          const userType = clonedObj[fieldsToValidate[i]].value
          if (
            UserData &&
            !(UserData?.userType === clonedObj[fieldsToValidate[i]].value)
          ) {
            clonedObj[fieldsToValidate[i]].modified = true
            clonedObj[fieldsToValidate[i]].needsApproval = true
          } else {
            clonedObj[fieldsToValidate[i]].modified = false
          }
          userType === ''
            ? (clonedObj[fieldsToValidate[i]].error = true)
            : (clonedObj[fieldsToValidate[i]].error = false)
          break
        }
        default:
          // Handle any unexpected validation types
          console.error(
            `Unexpected validation type: ${
              clonedObj[fieldsToValidate[i]].validationType
            }`
          )
      }
    }
    return clonedObj
  }

  const cancel = () => {
    if (loc) {
      navigate(`${pages['User Management'].href}/user-details/${id}`)
    } else {
      navigate(pages['User Management'].href)
    }
  }

  const onViewChange = ({ currentView, move, direction }) => {
    if (direction === -1) {
      if (currentView === 3 && !Object.keys(userInfoObject.applications).length) {
        move()
        move()
      } else if (currentView === 3 && Object.keys(userInfoObject.applications).every(e => !Object.keys(userInfoObject.applications[e].roles).length)) {
        move()
        move()
      } else if (loc && currentView === 0) {
        setCancelDialog(true)
      } else if (currentView === 0) {
        cancel()
      } else if (currentView !== 0) {
        move()
      }
    } else if (currentView === 1 && (!Object.keys(userInfoObject.applications).length || Object.keys(userInfoObject.applications).every(e => !Object.keys(userInfoObject.applications[e].roles)).length)) {
      move()
      move()
    } else {
      currentView === 3
        ? loc
          ? saveExistingUser()
          : saveNewUser()
        : move()
    }
  }
  return (
    <>
      <TraneTheme>
        <NewStyles>
          {error ? (
            <Modal
              heading={i18next.t('errors:Error')}
              handleClose={() => {
                setError(false)
              }}
              buttons={[
                {
                  variant: 'cancel',
                  text: <TranslateComponent>Close</TranslateComponent>,
                  handleClick: () => {
                    setError(false)
                  }
                }
              ]}
              isAlert={true}
            >
              <p><TranslateComponent>We've encountered an error.</TranslateComponent></p>
            </Modal>
          ) : (
            ''
          )}
          {cancelDialog ? (
            <Modal className="usermodel"
              heading={<TranslateComponent>{`${loc ? 'Incomplete Edit User' : 'Incomplete User Creation'}`}</TranslateComponent>              }
              handleClose={() => {
                setCancelDialog(false)
              }}
              buttons={[
                {
                  variant: 'cancel',
                  text: <TranslateComponent>{`${loc ? 'Continue Editing User' : 'Continue Creating User'}`}</TranslateComponent>,
                  handleClick: () => {
                    setCancelDialog(false)
                  }
                },
                {
                  variant: 'cancel',
                  text: <TranslateComponent>{`${loc ? 'Leave Editing Process' : 'Leave Creation Process'}`}</TranslateComponent>,
                  handleClick: () => {
                    cancel()
                  }
                }
              ]}
              isAlert={true}
            >
              <p style={{ width: '400px' }}>
                <TranslateComponent>{`${loc ? 'Trane Connect has not yet saved changes to user. To save your user changes, click save.' : 'Trane Connect has not yet created a new user. To finish the user creation process, click continue.'}`}</TranslateComponent>
              </p>
            </Modal>
          ) : (
            ''
          )}
          {
            userInfoObject ? (
              loading ? <Spinner /> : <Views onViewChange={onViewChange} currentView={stage}>
                <AddUserStage
                  edit={loc}
                  userTypesList={userTypesList || []}
                  userEmail={UserData?.email}
                  userInfoObject={userInfoObject}
                  setUserInfoObject={setUserInfoObject}
                  width={props.width}
                  key="addUserStage"
                  validateFields={validateFields}
                  validateIfUserEmailExists={validateIfUserEmailExists}
                  loadingUniq={loadingUniq}
                  checkIfIsUserUnique={checkIfIsUserUnique}
                />
                <ApplicationStage 
                  key="applicationsStage"
                  width={props.width}
                  userInfoObject={userInfoObject}
                  setUserInfoObject={e => setUserInfoObject(e)}
                  applicationList={applicationList}
                  resourceControl={resourceControl}
                  typeModified={
                    userInfoObject &&
                    userInfoObject.userType &&
                    userInfoObject.userType.modified
                  }
                  edit={loc && loc === 'applications'}
                />
                <LocationStage
                  key="locationStage"
                  width={props.width}
                  userInfoObject={userInfoObject}
                  setUserInfoObject={setUserInfoObject}
                  resourceControl={resourceControl}
                  edit={loc && loc === 'locations'}
                  validateFields={validateFields}
                />
                <AccessStage
                  key="permissionsStage"
                  width={props.width}
                  userInfoObject={userInfoObject}
                  setUserInfoObject={e => setUserInfoObject(e)}
                  applicationList={applicationList}
                  resourceControl={resourceControl}
                  typeModified={
                    userInfoObject &&
                    userInfoObject.userType &&
                    userInfoObject.userType.modified
                  }
                  edit={loc && loc === 'permissions'}
                />
              </Views>
            ) : null
          }
        </NewStyles>
      </TraneTheme>
    </>
  )
}

CreateUser.propTypes = {}

export const mapStateToProps = ({
  dimensions: { width },
  user: { userInfo }
}) => ({
  userInfo,
  width
})

export default connect(mapStateToProps, {})(CreateUser)
