import { Content } from 'src/components/layouts/'
import BaseLinesList from './BaseLinesList'
import { useTranslation } from 'react-i18next'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useBaseLinesContext } from './BaseLinesContextProvider'
import { PAGE_TITLE } from './constants'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { ToolBarWrapper } from './styles'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { useMemo } from 'react'
import { useMutation } from 'src/hooks/APIHooks'
import { DELETE_BASELINE } from './graphql'
import translate, { TranslateComponent } from 'src/common/translations'
import  BaseLineModal  from "./BaseLineCreationAndEdit/BaseLineModal"
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'

// Denali components
import { useSelector } from 'react-redux'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { BaselineLibraryContainer } from 'src/denali-pages/BaselineLibrary/BaselineLibraryContainer'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { Dialog } from 'src/denali-components/Dialog/Dialog'

const BaseLinesContainer = () => {
  const [t] = useTranslation()
  const { width } = useBreakPoint()

  const optionsTranslated = {
    delete: translate("Delete Baseline"),
  }

  const { setReloadEvents, modalType, setModalType, baseLine, setBaselines, setMode, showBaselineModal, setShowBaselineModal, testName } =
    useBaseLinesContext()
  const renderConfirmationText = (modalType) => {
    const translateDeleteConfirmation = (
      <><TranslateComponent>Are you sure you want to delete</TranslateComponent> {baseLine?.name}</>
    )
    const translateDeleteSuccess = (
      <><TranslateComponent>You have successfully deleted</TranslateComponent> {baseLine?.name}</>
    )
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return translateDeleteConfirmation
      default:
        return translateDeleteSuccess
    }
  }
  const hideDeleteDialog = () => {
    setModalType('')
  }

  const { onSubmit: deleteBaseLine } = useMutation({
    query: DELETE_BASELINE,
    onSuccess: () => {
      setReloadEvents(true)
      setModalType(MODAL_TYPE.SUCCESS)
      trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.DELETE_BASELINE)
    }
  })

  const deleteBaseLineHandler = () => {
    deleteBaseLine({ input: { id: baseLine?.id } })
  }

  const modalConfig = useMemo(
    () => ({
      heading: "Delete BaseLine",
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: "YES",
                handleClick: deleteBaseLineHandler,
                type: 'valid'
              },
              {
                text: "NO",
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ]
          : [
              {
                text: "CLOSE",
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ],
      handleClose: hideDeleteDialog
    }),
    [modalType]
  )

  const denaliModalConfig = {
    title: optionsTranslated.delete,
    onClose: hideDeleteDialog,
    onAgree: deleteBaseLineHandler
  }

  const denaliConfirmationConfig = {
    title: optionsTranslated.delete,
    onClose: hideDeleteDialog,
    onAgree: hideDeleteDialog,
    autoClose: 8000
  }

  const handleGetBaseLineRecords = (rows) => {
    setBaselines(rows)
  }

  const onCreateBaseline = () => {
    setMode(ACTIONS.ADD)
    setShowBaselineModal(!showBaselineModal)
    trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.CLICK_CREATE_BASELINE)
  }

  const uiMode = useSelector(selectUiMode)
  const isDenali = uiMode === UiMode.denali

  if (isDenali) {
    return (
      <BaselineLibraryContainer onCreate={onCreateBaseline}>
        <BaseLinesList
          handleGetBaseLineRecords={handleGetBaseLineRecords}
          uiMode={uiMode}
        />
        <>
          {[MODAL_TYPE.CONFIRMATION].includes(modalType) && (
            <DialogConfirm {...denaliModalConfig}>
              <TranslateComponent>
                {renderConfirmationText(modalType)}
              </TranslateComponent>
            </DialogConfirm>
          )}
          {[MODAL_TYPE.SUCCESS].includes(modalType) && (
            <Dialog {...denaliConfirmationConfig}>
              <TranslateComponent>
                {renderConfirmationText(modalType)}
              </TranslateComponent>
            </Dialog>
          )}
        </>
        {showBaselineModal && <BaseLineModal />}
      </BaselineLibraryContainer>
    )
  }

  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <PageHeader
          pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
          titleTools={
            <ToolBarWrapper>
              <Button onClick={onCreateBaseline}>
                <TranslateComponent>CREATE BASELINE</TranslateComponent>
              </Button>
            </ToolBarWrapper>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <BaseLinesList
          handleGetBaseLineRecords={handleGetBaseLineRecords}
          uiMode={uiMode}
        />
      </Content>
      {[MODAL_TYPE.CONFIRMATION, MODAL_TYPE.SUCCESS].includes(modalType) ? (
        <Modal testName = {testName} {...modalConfig}>
          <ConfirmationText>
            {renderConfirmationText(modalType)}
          </ConfirmationText>
        </Modal>
      ) : null}
      {showBaselineModal && <BaseLineModal  />}
    </ContainerWrapper>
  )
}


export default withFeatureRestrictions(
  BaseLinesContainer
)
