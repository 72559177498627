import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Header from '../../../components/pageHeader/header'
import { Container, Content, Block } from '../../../components/layouts'
import { useSelector } from 'react-redux';
import './user-management.scss'
import Button from 'src/denali-ui/components/Button'
import ProgressIndicator from './create-user-progress-indicator'
import Notification from 'src/denali-ui/components/Notification'
import { Accordion } from '@trane/trane-components/dist'
import Checkbox from 'src/denali-ui/components/Checkbox'
import { API } from 'aws-amplify'
import { GET_RESOURCES } from '../graphql/queries'
import { TranslateComponent } from '../../../common/translations'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { AccessControl } from 'src/components/accessControl'

const FieldDiv = styled.div`
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  text-align: end;
`

export const AddUserStage = ({
  userInfoObject,
  width,
  prev,
  next,
  resourceControl,
  edit
}) => {
  const [error, setError] = useState(false)
  const notificationRefs = useRef(null)
  const showError = (error) => {
    const notification = notificationRefs.current
    notification.showToast({
      type: 'error',
      title: <TranslateComponent>Error</TranslateComponent>,
      position: 'bottom',
      message: <TranslateComponent>{error}</TranslateComponent>
    })
  }
  useEffect(() => {
    error && showError(error)
  }, [error])

  useEffect(() => {
    let namesToBeLoaded = {}
    Object.keys(resourceControl[0])?.map(resource => {
      Object.keys(resourceControl[0][resource])?.map(resourceId => {
        if (!resourceControl[0][resource][resourceId].name) {
          if (!namesToBeLoaded[resource]) {
            namesToBeLoaded[resource] = [resourceId]
          } else {
            namesToBeLoaded[resource].push(resourceId)
          }
        }
      })
    })

    // Deleting buildingGroup becuase the name is the id for this one. Thus, we can just display the key in the object rather than having to actually asign a name.
    if (namesToBeLoaded["BuildingGroup"]) {
      delete namesToBeLoaded["BuildingGroup"]
    }
    if (namesToBeLoaded["ApplicationGroup"]) {
      delete namesToBeLoaded["ApplicationGroup"]
    }
    let newResourceControl = JSON.parse(JSON.stringify(resourceControl[0]))
    Promise.all(
      Object.keys(namesToBeLoaded).map(async resource => {
        return await API.graphql({
          query: GET_RESOURCES(resource),
          variables: {filter: {or:  namesToBeLoaded[resource].map((e) => ({id: {eq: e}})) } }
        })
        .then(e => {
          e.data[`search${resource === "ContractorCompany" ? "ContractorCompanie" : resource}s`]?.items?.forEach(i => {
            newResourceControl[resource][i.id] = {...newResourceControl[resource][i.id], name: i.name, salesOfficeId: i?.salesOfficeId, accountId: i?.accountId, contractorCompanyId: i?.contractorCompanyId, groupUser: i?.groupUser, groupAdmin: i?.groupAdmin}
          })
          return e
        })
      })
    ).then(res => {
      if (res) {
        resourceControl[1](newResourceControl)
      }
    })
  }, [])
  const nonTranslatableWords = ['Command Center','Trane Connect','Trane Service','Clockwork-POC','Trane'];
  const getTranslatedContent = (text) => {
    const selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage);
    if (selectedLanguage == 'en'){
      return text;
    }
    const splitText = text.split(new RegExp(`(${nonTranslatableWords.join('|')})`, 'g'));
    return splitText.map((word, index) => {
      if (nonTranslatableWords.includes(word)) {
        return <span key={index}>{word}</span>; // Do not translate this part
      }
      return <TranslateComponent key={index}>{word}</TranslateComponent>; // Translate this part
    });
  };
  const accordionData = Object.keys(resourceControl[0])?.map(resource => {
    const singleDataObj = Object.keys(resourceControl[0][resource])?.map(resourceId => ({
      header: resourceControl[0][resource][resourceId].name ? resourceControl[0][resource][resourceId].name?.includes('Application') ? <TranslateComponent>{resourceControl[0][resource][resourceId].name}</TranslateComponent> : resourceControl[0][resource][resourceId].name : resourceId,   
    content: Object.keys(userInfoObject.applications).map(appKey => {
  const rolesToShow = Object.keys(userInfoObject.applications[appKey].roles).filter(e => userInfoObject.applications[appKey].roles?.[e]?.resourceTypes?.includes(resource)).filter(e => userInfoObject.applications[appKey].roles?.[e]?.userTypes?.includes(userInfoObject?.userType?.value))
  return rolesToShow?.length ? <div key={`resourceControlHeading${appKey}`}>
    <span style={{fontWeight: "bold"}}>{getTranslatedContent(userInfoObject.applications[appKey].name)}</span>
      {rolesToShow.map(roleKey => {
        const roleName = userInfoObject.applications[appKey].roles[roleKey].name;
    return <div key={`${resource}-${appKey}-${roleKey}`}>
      {resource === "ApplicationGroup" 
      ? <Checkbox
      key={`resourceControl${resource}-${appKey}-${roleKey}`}
      checked={Boolean(resourceControl[0][resource][resourceId].applications?.[appKey]?.[roleKey])}
      onClick={() => {
        let newResourceControl = JSON.parse(JSON.stringify(resourceControl[0]))
        if (!newResourceControl[resource][resourceId].applications?.[appKey]) {
          newResourceControl[resource][resourceId].applications[appKey] = {[roleKey]: userInfoObject.applications[appKey].roles[roleKey]}
        } else if (!newResourceControl[resource][resourceId].applications[appKey]?.[roleKey]) {
          newResourceControl[resource][resourceId].applications[appKey] = {...newResourceControl[resource][resourceId].applications[appKey], [roleKey]: userInfoObject.applications[appKey].roles[roleKey]}
        } else {
          if (Object.keys(newResourceControl[resource][resourceId].applications[appKey]).length === 1) {
            delete newResourceControl[resource][resourceId].applications[appKey]
          } else {
            delete newResourceControl[resource][resourceId].applications[appKey][roleKey]
          }
        }
        resourceControl[1](newResourceControl)
      }
    }
      testName={`${resource}-${appKey}-${roleKey}_checkbox`}
    />
      : <AccessControl
              id={[`tc.pages.user-admin.Application`, 
              `tc.pages.user-admin.${resourceId}`, 
              `tc.pages.user-admin.${resourceControl[0][resource][resourceId]?.accountId}`, 
              `tc.pages.user-admin.${resourceControl[0][resource][resourceId]?.salesOfficeId}`, 
              `tc.pages.user-admin.${resourceControl[0][resource][resourceId]?.contractorCompanyId}`, 
              `tc.pages.user-admin.${resourceControl[0][resource][resourceId]?.regionId}`,
              `tc.pages.user-admin.${resourceControl[0][resource][resourceId]?.globalRegionId}`,
              ...resourceControl[0][resource][resourceId]?.regionIds ? resourceControl[0][resource][resourceId]?.regionIds.map(x => `tc.pages.user-admin.${x}`) : [],
              ...resourceControl[0][resource][resourceId]?.globalRegionIds ? resourceControl[0][resource][resourceId]?.globalRegionIds.map(x => `tc.pages.user-admin.${x}`) : [],
              ...resourceControl[0][resource][resourceId]?.salesOfficeIds ? resourceControl[0][resource][resourceId]?.salesOfficeIds.map(x => `tc.pages.user-admin.${x}`) : [],
              ...resourceControl[0][resource][resourceId]?.groupUser ? resourceControl[0][resource][resourceId]?.groupUser.map(x => `tc.pages.user-admin.${x}`) : [],
              ...resourceControl[0][resource][resourceId]?.groupAdmin ? resourceControl[0][resource][resourceId]?.groupAdmin.map(x => `tc.pages.user-admin.${x}`) : [],
            ]}
            >
        <Checkbox
          key={`resourceControl${resource}-${appKey}-${roleKey}`}
          checked={Boolean(resourceControl[0][resource][resourceId].applications?.[appKey]?.[roleKey])}
          onClick={() => {
            let newResourceControl = JSON.parse(JSON.stringify(resourceControl[0]))
            if (!newResourceControl[resource][resourceId].applications?.[appKey]) {
              newResourceControl[resource][resourceId].applications[appKey] = {[roleKey]: userInfoObject.applications[appKey].roles[roleKey]}
            } else if (!newResourceControl[resource][resourceId].applications[appKey]?.[roleKey]) {
              newResourceControl[resource][resourceId].applications[appKey] = {...newResourceControl[resource][resourceId].applications[appKey], [roleKey]: userInfoObject.applications[appKey].roles[roleKey]}
            } else {
              if (Object.keys(newResourceControl[resource][resourceId].applications[appKey]).length === 1) {
                delete newResourceControl[resource][resourceId].applications[appKey]
              } else {
                delete newResourceControl[resource][resourceId].applications[appKey][roleKey]
              }
            }
            resourceControl[1](newResourceControl)
          }
        }
          testName={`${resource}-${appKey}-${roleKey}_checkbox`}
        />
      </AccessControl>}
     {getTranslatedContent(roleName)}
    </ div> 
})}
</div> : false
}).filter(e => e)
})).filter(e => e?.content?.length) || [] 
    return singleDataObj?.length ? ({header: <TranslateComponent>{resource}</TranslateComponent>, content: <Accordion data={singleDataObj} />}) : false
}).filter(e => e)
  return (
    <>
      <Notification ref={notificationRefs} autoHideDuration={5000} />
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        width={width}
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <Header hideToolBar={false} pageTitle={<TranslateComponent>Resource Access</TranslateComponent>} />
        </Content>
          <Content
            xlColumn={9}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Block margin="0 0 46px 0">
              <ProgressIndicator stage={3} />
            </Block>
          </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <TranslateComponent>{accordionData.length ? "Apply selected roles to selected resources." : "No applications and/or no roles were selected."}</TranslateComponent>
        </Content>
        <Content
          xlColumn={12}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
          style={{ gridRowStart: 4, gridRowEnd: 6 }}
        >
          <FieldDiv>
          <Accordion data={accordionData || []} />
        </FieldDiv>
            
        </Content>
        <Content
          xlColumn={12}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <ButtonContainer>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => prev()}
              style={{ marginRight: '14px' }}
            >
              <TranslateComponent>Back</TranslateComponent>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                if (Object.keys(userInfoObject.applications).length <= 0) {
                  setError("Please select at least one application.")
                } else if (Object.keys(resourceControl[0]).length <= 0) {
                  setError("Please select at least one resource and assign a role to it.")
                } else {
                  next()
                  trackEvent(USER_EVENTS.ADMIN_TOOLS.events.SAVE_USER, {mode:"ADD"})
                }
              }}
            >
              <TranslateComponent>Save</TranslateComponent>
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </>
  )
}
AddUserStage.propTypes = {}

export default AddUserStage
