// Legacy imports
import InlineHelp from 'src/components/legacy/components/inline-help/inline-help'

import translate, { TranslateComponent } from 'src/common/translations'
import { faShieldCheck } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Switch } from 'src/denali-components/Switch/Switch'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { DELETE_SUBSCRIBER_CONFIRMATION } from 'src/pages/alarmNotifications/AlarmContainer'
import {
  MODAL_TYPES,
  ALARM_ACTIVATE_CONFIRMATION,
  ALARM_DEACTIVATE_CONFIRMATION
} from 'src/pages/alarmNotifications/AlarmContainer'
import { AlarmNotificationList } from 'src/denali-pages/AlarmNotifications/AlarmNotificationsList'
import { useAlarmContext } from 'src/pages/alarmNotifications/AlarmContextProvider'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { Dialog } from 'src/denali-components/Dialog/Dialog'

import styles from './alarm-notifications.module.scss'

export const AlarmNotificationsContainer = ({
  alarmVisible,
  onAlarmCheckboxChange,
  inlineHelpText,
  uiMode,
  renderConfirmationText,
  optionsTranslated,
  getModalHeading,
  hideDeleteDialog,
  deleteSubscriberHandler,
  updateAlarmHandler,
  children
}) => {
  const { confirmationModalType } = useAlarmContext()
  return (
    <Page
      titleTools={
        <InlineHelp inlineHelpText={inlineHelpText} uiMode={uiMode} />
      }
      className={styles.alarmNotificationContainer}
      title={translate('Alarm Notifications (Building Contacts)')}
      icon={<FontAwesomeIcon icon={faShieldCheck} />}
      iconColor={IconColor.orange}
      testid="alarm notifications page"
      actions={
        <AlarmNotificationInfo
          alarmVisible={alarmVisible}
          onAlarmCheckboxChange={onAlarmCheckboxChange}
          optionsTranslated={optionsTranslated}
        />
      }
    >
      {children}
      <AlarmNotificationList />
      {showDenaliModals(
        confirmationModalType,
        renderConfirmationText,
        denaliConfirmationConfig(
          getModalHeading,
          confirmationModalType,
          optionsTranslated,
          hideDeleteDialog
        ),
        denaliDeleteConfig(
          optionsTranslated,
          hideDeleteDialog,
          deleteSubscriberHandler
        ),
        denaliAlarmActivationConfig(
          optionsTranslated,
          hideDeleteDialog,
          updateAlarmHandler
        ),
        denaliAlarmDeactivationConfig(
          optionsTranslated,
          hideDeleteDialog,
          updateAlarmHandler
        )
      )}
    </Page>
  )
}

const AlarmNotificationInfo = ({
  alarmVisible,
  onAlarmCheckboxChange,
  optionsTranslated
}) => {
  return (
    <div className={styles.infoContainer}>
      <div className={`${styles.infoTitle} body-sm`}>
        <TranslateComponent>Alarm Notification</TranslateComponent>
        <Tooltip>
          <TooltipTrigger>
            <FontAwesomeIcon icon={faInfoCircle} color="#6B6B6B" />
          </TooltipTrigger>
          <TooltipContent>
            {alarmVisible
              ? optionsTranslated.transSwitchOff
              : optionsTranslated.transSwitchOn}
          </TooltipContent>
        </Tooltip>
      </div>
      <div
        // Wrap to handle mouse and keyboard (Enter/Space) for accessibility.
        onClick={(e) => {
          e.preventDefault()
          onAlarmCheckboxChange()
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            onAlarmCheckboxChange()
          }
        }}
      >
        <Switch
          data-testid="alarmToggleSwitch"
          label={translate('Alarm Notification')}
          defaultChecked={alarmVisible}
          isLabelHidden={true}
          size="large"
        />
      </div>
    </div>
  )
}

const denaliConfirmationConfig = (
  getModalHeading,
  modalType,
  optionsTranslated,
  hideDeleteDialog
) => ({
  title: getModalHeading(modalType, optionsTranslated),
  onClose: hideDeleteDialog,
  onAgree: hideDeleteDialog,
  autoClose: 8000
})

const denaliDeleteConfig = (
  optionsTranslated,
  hideDeleteDialog,
  deleteSubscriberHandler
) => ({
  title: optionsTranslated.transDeleteSub,
  onClose: hideDeleteDialog,
  onAgree: deleteSubscriberHandler
})

const denaliAlarmActivationConfig = (
  optionsTranslated,
  hideDeleteDialog,
  updateAlarmHandler
) => ({
  title: optionsTranslated.transAlarmActivate,
  onClose: hideDeleteDialog,
  onAgree: updateAlarmHandler,
  onAgreeTitle: optionsTranslated.transActivate,
  onCloseTitle: optionsTranslated.transCancel
})

const denaliAlarmDeactivationConfig = (
  optionsTranslated,
  hideDeleteDialog,
  updateAlarmHandler
) => ({
  title: optionsTranslated.transAlarmDeactivate,
  onClose: hideDeleteDialog,
  onAgree: updateAlarmHandler,
  onAgreeTitle: optionsTranslated.transDeactivate,
  onCloseTitle: optionsTranslated.transCancel
})

const showDenaliModals = (
  confirmationModalType,
  renderConfirmationText,
  denaliConfirmationConfig,
  denaliDeleteConfig,
  denaliAlarmActivationConfig,
  denaliAlarmDeactivationConfig
) => {
  let modalProps = null
  switch (confirmationModalType) {
    case DELETE_SUBSCRIBER_CONFIRMATION:
      modalProps = {
        component: DialogConfirm,
        config: denaliDeleteConfig,
        content: renderConfirmationText(confirmationModalType)
      }
      break
    case ALARM_ACTIVATE_CONFIRMATION:
      modalProps = {
        component: DialogConfirm,
        config: denaliAlarmActivationConfig,
        content: renderConfirmationText(confirmationModalType)
      }
      break
    case ALARM_DEACTIVATE_CONFIRMATION:
      modalProps = {
        component: DialogConfirm,
        config: denaliAlarmDeactivationConfig,
        content: renderConfirmationText(confirmationModalType)
      }
      break
    default:
      if (MODAL_TYPES.includes(confirmationModalType)) {
        modalProps = {
          component: Dialog,
          config: denaliConfirmationConfig,
          content: renderConfirmationText(confirmationModalType)
        }
      }
      break
  }

  const ModalComponent = modalProps ? modalProps.component : null

  return (
    <>
      {ModalComponent && (
        <ModalComponent {...modalProps.config}>
          {modalProps.content}
        </ModalComponent>
      )}
    </>
  )
}
