import { useState, useRef, useEffect, useMemo } from 'react'
import { SwitchField, useTheme } from '@aws-amplify/ui-react'
import {
  NavItem,
  Nav,
  IconHolder,
  StyledTextSpan,
  StyledNavUL,
  StyledHeaderSection,
  TextSpanFlex,
  StyledNavPageLink,
  SubNavDiv,
  UiToggleWrapper,
  UiToggleButton
} from './style'
import styled from 'styled-components'
import IconSvg from '../Icon'
import { useOutSideClick } from 'src/hooks'
import { StyledAside } from './style'
import { Link, useNavigate } from 'react-router-dom'
import { pages } from 'src/pages/pages'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDashboards,
  setDashboard,
  setDashboardIdForWidget,
  setDashboardPath
} from 'src/redux/slicers/dashboard'
import { accessControlFunc } from '../accessControl'
import { selectUiMode, selectUserAccess, setUiMode } from 'src/redux/slicers/appData'
import translate, { TranslateComponent } from 'src/common/translations'
import { trackEvent, identifyEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import config from 'src/aws-exports.js'
import { UiMode } from 'src/redux/types/AppTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSparkles } from 'src/denali-components/lib/pro-solid-svg-icons'
import { AccessControl } from 'src/components/accessControl'
import { isUBMEnabled } from 'src/common/featureFlags'
import { EMEA_PROJECT_REGION } from 'src/components/Header/constants'
import _sortBy from "lodash/sortBy";

const TooltipSpan = styled.span`
  left: 75%;
  display: none;
  // TODO make tooltip work
`

const Sidebar = () => {
  const { tokens } = useTheme()
  const { data: dashboards } = useSelector(selectDashboards)
  const userAccess = useSelector(selectUserAccess)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const uiMode = useSelector(selectUiMode);
  const IsEMEAInstance = config.aws_project_region === EMEA_PROJECT_REGION;

  const optionsTranslated = {
    createDash: translate('CREATE DASHBOARD'),
    widget: translate('Widget Library')
  }

  const sidebarRef = useRef<HTMLInputElement>()
  const dispatch = useDispatch()
  useOutSideClick(sidebarRef, setOpen)
  const dashboardList = _sortBy(localStorage.getItem('dashboards')
    ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
    : dashboards ?? [], (s) => s?.name?.toLowerCase())
  const menus = useMemo(
    () => ({
      Dashboards: {
        type: "Dashboards",
        name: 'Dashboards',
        icon: 'dashboard2',
        links: [
          ...(accessControlFunc({ id: pages['Dashboards'].access, userAccess })
            ? dashboardList?.reduce((acc, dashboard: any) => {
              const dashboardParams = {
                name: dashboard?.name,
                href: `dashboard/${dashboard.id}`,
                icon: dashboard.isDefault === '1' ? `favorite active` : ''
              }
              acc.push(dashboardParams)
              return acc
            }, []) || []
            : []),
          {
            access: pages['Widget Library'].access,
            name: optionsTranslated.widget,
            href: '/widget-library'
          }
        ],
        buttons: [
          {
            name: optionsTranslated.createDash,
            eventCallback: () => {
              dispatch(
                setDashboard({
                  actionType: 'Create'
                })
              )
              trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_CREATE_DASHBOARD)
            }
          }
        ]
      },
      Service: {
        type: "Services",
        name: "Service",
        icon: 'tools2',
        links: [
          pages['Opportunities'],
          pages['Issues/Findings']
        ]
      },
      ...(isUBMEnabled ? {
        'Utility & Energy': {
          type: "UtilityEnergy",
          name: "Utility & Energy",
          icon: 'energy-performance',
          links: [
            pages['Portfolio Scorecard'],
            pages['Energy Intensity'],
            pages['Connected Utility Accounts'],
            pages['Utility Bill Upload'],
            pages['Energy Cost Summary'],
            pages['Energy Consumption'],
            pages['Electrical Demand'],
            pages['Load Analysis'],
            pages['Rate Structure Library']
          ]
        }
      }
        : {
          'Energy Performance': {
            type: "EnergyPerformance",
            name: "Energy Performance",
            icon: 'energy-performance',
            links: [
              pages['Portfolio Scorecard'],
              pages['Energy Cost Summary'],
              pages['Energy Consumption'],
              pages['Electrical Demand'],
              pages['Load Analysis'],
              pages['Rate Structure Library']
            ]
          },
          'Utility Management': {
            type: "UtilityManagement",
            name: "Utility Management",
            icon: 'utility-management',
            links: [pages['Utility Bill Data'], pages['Energy Intensity']]
          },
        }),
      Reports: {
        type: "Reports",
        name: "Reports",
        icon: 'clipboard',
        links: [pages['Documents & Reports']]
      },
      'Building Performance': {
        type: "BuildingPerformance",
        name: "Building Performance",
        icon: 'building-performance',
        links: [
          pages['Building Data'],
          pages['Automated Test Settings'],
          pages['Automated Test Suppressions'],
          pages['Command Center']
        ]
      },
      'Remote Access': {
        type: "RemoteAccess",
        name: "Remote Access",
        icon: 'building-automation',
        links: [
          pages['Connect to a Device']
          // pages['Manage Devices']
        ]
      },
      'Building Configuration': {
        type: "BuildingConfiguration",
        name: "Building Configuration",
        icon: 'facility-settings',
        links: [
          pages['Building Setup'],
          pages['Alarm Notification'],
          pages['Equipment Setup'],
          pages['Notes'],
          pages['Event Markers'],
          pages['Baseline Library']
        ]
      },
      'Admin Tools': {
        type: "AdminTools",
        name: "Admin Tools",
        icon: 'set-up',
        links: [
          pages['User Management'],
          pages['Service Provider Management'],
          pages['Organization Management'],
          pages['Contracts & Offerings'],
          pages['Role Management'],
          pages['Permission Management'],
          pages['Application Management'],
          pages['User Type Management'],
          pages['Group Management'],
          pages['Data Reprocess'],
          pages['Property Metadata']
        ]
      }
    }),
    [dispatch, dashboardList]
  )
  const filteredAccessSidebarItems = Object.keys(menus)
    .map((s) => menus[s])
    .map((s) => ({
      ...s,
      links: s.links.filter((l) =>
        l.access ? accessControlFunc({ id: l.access }) : true
      )
    }))
    .filter(item => IsEMEAInstance ? (item?.name !== 'Utility & Energy' && item?.name !== 'Energy Performance' && item?.name !== 'Utility Management') : item)

  return (
    <StyledAside
      className={open ? 'open' : ''}
      onClick={() => {
        if (!open) {
          setOpen(true)
        }
      }}
      bg={tokens.colors.TBA_MEDIUM_GREEN}
      ref={sidebarRef}
    >
      <Nav as="nav">
        <div>
          {filteredAccessSidebarItems?.length &&
            filteredAccessSidebarItems
              ?.filter((s) => s.links?.length)
              ?.map((item) => (
                <NavObj
                  parentOpen={open}
                  item={item}
                  key={item?.name}
                  navigate={(e) => {
                    const id = e?.split('/')?.pop()
                    dispatch(
                      setDashboardIdForWidget({
                        dashboardId: id
                      })
                    )
                    dispatch(
                      setDashboardPath({
                        dashboardPath: e
                      })
                    )
                    setOpen(false)
                  }}
                />
              ))}
        </div>
        {uiMode === UiMode.denali ? <UiToggle isMenuOpen={open} />
          : <AccessControl id="tc.poc.clockwork-theme">
            <UiToggle isMenuOpen={open} />
          </AccessControl>
        }
      </Nav>
    </StyledAside>
  )
}

const UiToggle = ({ isMenuOpen }) => {
  const uiMode = useSelector(selectUiMode);
  const dispatch = useDispatch();
  const isChecked = (uiMode === UiMode.denali);
  const setIsChecked = (newIsChecked) => {
    if (!isMenuOpen) return;
    dispatch(setUiMode(newIsChecked ? UiMode.denali : UiMode.standard))
    identifyEvent({ "user interface": newIsChecked ? 'denali' : 'legacy' })
    trackEvent(USER_EVENTS.NAVIGATION.events.CLICK_CHANGE_UI, { "changed to": newIsChecked ? 'denali' : 'legacy' })
  }
  return (
    <UiToggleWrapper className={isMenuOpen && 'open'}>
      <UiToggleButton
        onClick={() => setIsChecked(true)}
        className={isMenuOpen && 'open'}
        data-testid="uiToggleButton"
      >
        <FontAwesomeIcon icon={faSparkles} />
        <SwitchField
          label={translate('New Design')}
          labelPosition='start'
          size='large'
          isChecked={isChecked}
          onChange={() => { setIsChecked(true); }}
          data-testid="uiToggleSwitch"
        />
      </UiToggleButton>
    </UiToggleWrapper>
  )
}

export const NavObj = ({ item, parentOpen, navigate }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    !parentOpen && setOpen(false)
  }, [parentOpen])

  const commandCenterUrl =
    config?.appEnvironment?.externalLinks?.commandCenterBaseURL
  return (
    <NavItem as={'section'} key={item.name}>
      <StyledHeaderSection
        as="h1"
        onClick={() => {
          setOpen(!open)
          if (open == false) {
            if (item.name == 'Dashboards') {
              trackEvent(USER_EVENTS.NAVIGATION.events.EXPAND_DASHBOARDS)
            } else if (item.name == 'Service') {
              trackEvent(USER_EVENTS.NAVIGATION.events.EXPAND_SERVICE)
            } else if (item.name == 'Utility & Energy') {
              trackEvent(
                USER_EVENTS.NAVIGATION.events.EXPAND_UTILITY_AND_ENERGY
              )
            } else if (item.name == 'Energy Performance') {
              trackEvent(
                USER_EVENTS.NAVIGATION.events.EXPAND_ENERGY_PERFORMANCE
              )
            } else if (item.name == 'Utility Management') {
              trackEvent(
                USER_EVENTS.NAVIGATION.events.EXPAND_UTILITY_MANAGEMENT
              )
            } else if (item.name == 'Reports') {
              trackEvent(USER_EVENTS.NAVIGATION.events.EXPAND_REPORTS)
            } else if (item.name == 'Building Performance') {
              trackEvent(
                USER_EVENTS.NAVIGATION.events.EXPAND_BUILDING_PERFORMANCE
              )
            } else if (item.name == 'Remote Access') {
              trackEvent(USER_EVENTS.NAVIGATION.events.EXPAND_REMOTE_ACCESS)
            } else if (item.name == 'Building Configuration') {
              trackEvent(
                USER_EVENTS.NAVIGATION.events.EXPAND_BUILDING_CONFIGURATION
              )
            } else if (item.name == 'Admin Tools') {
              trackEvent(USER_EVENTS.NAVIGATION.events.EXPAND_ADMIN_TOOLS)
            }
          }
        }}
      >
        <TextSpanFlex as="span">
          <IconSvg
            name={'expand'}
            width="44px"
            color="#fff"
            hover="#fff"
            transition="all .4s ease"
            transform={open ? 'rotate(180deg)' : undefined}
          />
          <StyledTextSpan display={parentOpen === false && 'none'} as="span"><TranslateComponent>{item?.name}</TranslateComponent></StyledTextSpan>
        </TextSpanFlex>
        <IconHolder as="span" className=" tooltip-container">
          <IconSvg name={item?.icon} color="#fff" hover="#fff" />
          <TooltipSpan>Dashboards</TooltipSpan>
          <div className="tooltip right dark nav-toggle-tooltip">
            <TranslateComponent>{item?.name}</TranslateComponent>
          </div>
        </IconHolder>
      </StyledHeaderSection>
      <SubNavDiv
        className={open ? 'open' : ''}
        childrencount={item?.links?.length + (item?.buttons?.length || 0)}
      >
        <StyledNavUL as="ul">
          {item?.links?.map((l: any, key: number) => {
            return (
              <li key={`${l?.name?.props?.children || key}`}>
                <StyledNavPageLink>
                  {l?.type === 'commandCenter' ? (
                    <a
                      onClick={() => {
                        window.open(`${commandCenterUrl}${l?.href}`, '_blank')
                        trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.NAVIGATE_TO_COMMAND_CENTER)
                      }}
                    >
                      {l.icon && <i className={`icon icon-${l.icon}`} />}
                      {item?.type !== 'Dashboards' ? l?.name.includes('Command Center') ? l?.name : <TranslateComponent>{l?.name}</TranslateComponent> : l?.name}
                    </a>
                  ) : (
                    <Link to={l?.href} onClick={() => navigate(l?.href)}>
                      {l.icon && <i className={`icon icon-${l.icon}`} />}
                      {item?.type !== 'Dashboards' ? l?.name.includes('Command Center') ? l?.name : <TranslateComponent>{l?.name}</TranslateComponent> : l?.name}
                    </Link>
                  )}
                </StyledNavPageLink>
              </li>
            )
          })}
          {item?.buttons?.map((l: any) => {
            return (
              <li key={l?.name}>
                <StyledNavPageLink>
                  <button onClick={() => l.eventCallback()}>{l?.name}</button>
                </StyledNavPageLink>
              </li>
            )
          })}
        </StyledNavUL>
      </SubNavDiv>
    </NavItem>
  )
}

export default Sidebar
