import { ChangeEvent } from 'react'

export type CollapsibleWithToggleComponentProps = {
  className?: string
  children: React.ReactNode
  // passing this prop as true will auto expand each card's children content upon load
  autoExpand?: boolean
  // If the collapsible header should have a larger set height. 50% taller than the default.
  style?: CollapsibleStyle

  // Optional callbacks for when the collapsible is opened or closed. Only used when not providing the
  // header builder and the default header is used.
  header?: React.ReactNode
  onToggle?: () => ChangeEvent
  // Optional icon can be passed in inserted between the chevron (expand) and header title
  icon?: React.ReactNode
  // Optional isToggled to control toggle state (switchField)
  isToggled?: boolean
}

export enum CollapsibleStyle {
  default = 'default',
  tall = 'tall'
}
