export const applicationIcons = {
    '60e47448-3407-40b7-8228-85a9ce0db08a': {icon: 'faLeaf', color: '#52C352', name: 'Sustainability'},
    '0b12c499-83ad-4ace-a3bb-98209dbd0911': {icon: 'faCloudArrowUp', color: '#54A9CB', name: 'Nuvolo'},
    'fc65c0d1-e0b0-4ebb-a0bd-be2efd787d80': {icon: 'faNetworkWired', color: '#6D2FD0', name: 'Ensemble'},
    '10e04b38-626a-479f-a152-2c0db0c16441': {icon: 'faMagnifyingGlassChart', color: '#FF00F7', name: 'Command Center'},
    'cde37f9c-e66f-4170-99f4-9ed68a34ee97': {icon: 'faBrain', color: '#E6AF41', name: 'Brain Box'},
    'ea86c119-69a0-469b-aa87-abd8bc490990': {icon: 'faShoePrints', color: '#52C352', name: 'Low Carb'},
    'fdc7fce5-7676-46a9-b998-ae98b3bfe877': {icon: 'faRobot', color: '#FD6108', name: 'Autonomous Controls'},
    'pivot': {icon: 'faTabletScreenButton', color: '#FD6108', name: 'Pivot'},
    '21745985-554d-4fcc-9daa-af2b3bc4a25c': {icon: 'faTruckArrowRight', color: '#3176F6', name: 'TK Connect'},
    '9b308188-33ad-4a84-8974-a412dc03e3f6': {icon: 'faCircleNodes', color: '#54A9CB', name: 'Trane Connect'},
    'default': {icon: 'faCircleNodes', color: '#54A9CB', name: 'default'}
}