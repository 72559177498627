import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from '@trane/trane-components/dist/simple/button/index.js'
import { useEffect, useState, useMemo, useRef } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { LIST_OFFERINGS_BY_ACCOUNT } from 'src/pages/contracts-and-offerings/queries'
import { GET_UTILITY_ACCOUNTS_BY_ORG_ID, GET_UTILITY_METERS_BY_ACCOUNT } from "../organizationManagement/createOrganization/queries/queries"
import { DownloadStatementButton } from 'src/pages/organizationManagement/associateUtilityAccounts/components/utilityStatementDownload'
import { LIST_UTILITY_STATEMENTS } from "src/pages/organizationManagement/associateUtilityAccounts/queries"
import { Link, useNavigate } from "react-router-dom"
import Table from "src/components/Table/clientSideTable"
import { pages } from 'src/pages/pages.js'
import { getSearchParams } from "src/common/helperFunctions.js"
import { isOfferingActive } from "../organizationManagement/helpers"
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getConfig } from "src/config"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const getBuildingText = buildingCount => `${buildingCount ?? 0} ${buildingCount !== 1 ? "buildings" : "building"}`

export const ConnectedUtilityAccounts = () => {
  const [accountRowData, setAccountRowData] = useState([])
  const [statementData, setStatementData] = useState([])
  const navigate = useNavigate()
  const { organizationId: orgIdFromPicker } = getSearchParams()

  const { data: organizationOfferingData = [], refetch: fetchOfferings, loading: loadingOfferings } = useQuery({
    query: LIST_OFFERINGS_BY_ACCOUNT,
    disableInitialLoad: true,
    variables: { id: orgIdFromPicker },
    errorPolicy: 'global',
    dataPath: 'data.listOfferingsByAccount.items'
  })

  const hasUBMOffering = useMemo(() => organizationOfferingData?.some(offering => offering.code === "UBM" && isOfferingActive(offering)), [organizationOfferingData])

  const { data: utilityAccounts, refetch: refetchUtilityAccounts, loading: loadingAccounts } = useQuery({
    query: GET_UTILITY_ACCOUNTS_BY_ORG_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listUtilityAccountByAccount'
  })

  const { data: utilityMeters, refetch: fetchUtilityMeters } = useQuery({
    query: GET_UTILITY_METERS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listUtilityMeterByAccount.items'
  })

  const { data: utilityStatements, refetch: fetchUtilityStatements } = useQuery({
    query: LIST_UTILITY_STATEMENTS,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listUtilityStatements'
  })

  useEffect(() => {
    if (orgIdFromPicker) {
      fetchOfferings({ id: orgIdFromPicker })
    }
  }, [orgIdFromPicker])

  useEffect(() => {
    if (hasUBMOffering && orgIdFromPicker && !utilityAccounts?.items) {
      refetchUtilityAccounts({ orgId: orgIdFromPicker, limit: 1000 })
    }
    if (utilityAccounts?.items?.length) {
      setAccountRowData(accounts => [...accounts, ...utilityAccounts.items.map(account => ({ ...account, account: account.accountName ? account.accountName : account.accountNumber }))])
    }
    if (utilityAccounts?.nextToken) {
      refetchUtilityAccounts({ orgId: orgIdFromPicker, limit: 1000, nextToken: utilityAccounts.nextToken })
    }
  }, [orgIdFromPicker, hasUBMOffering, utilityAccounts])

  useEffect(() => {
    if (hasUBMOffering && orgIdFromPicker && utilityAccounts?.items?.length) {
      fetchUtilityMeters({ orgId: orgIdFromPicker, limit: 1000 })
    }
  }, [orgIdFromPicker, hasUBMOffering, utilityAccounts])

  useEffect(() => {
    if (utilityMeters && accountRowData.some(account => !account.buildings)) {
      setAccountRowData(accounts => accounts?.map(account => ({ ...account, buildings: getBuildingText(utilityMeters.filter(meter => meter.buildingId && meter.utilityAccountId === account.id).length) })))
    }
  }, [utilityMeters, accountRowData])


  const getLastStatementId = id => {
    const accountStatements = statementData.filter(statement => statement.utilityAccountIds.includes(id)).sort((a, b) => !a.statementDate ? 1 : !b.statementDate ? -1 : -1 * a.statementDate.localeCompare(b.statementDate))
    return accountStatements[0]?.id
  }

  useEffect(() => {
    if (!utilityStatements?.items && utilityAccounts?.items?.length) {
      fetchUtilityStatements()
    }
    if (utilityStatements?.items?.length) {
      setStatementData(statements => ([...statements, ...utilityStatements.items]))
    }
    if (utilityStatements?.nextToken) {
      fetchUtilityStatements({ nextToken: utilityStatements.nextToken })
    }
  }, [utilityAccounts, utilityStatements])

  const trackStatementDownload = (accountId, accountNumber) => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.DOWNLOAD_STATEMENT, { "utility account id": accountId, "utility account number": accountNumber })

  useEffect(() => {
    if (statementData.length && accountRowData.some(account => !account.statement)) {
      setAccountRowData(prev => [...prev.map(accountRow => ({ ...accountRow, statement: <DownloadStatementButton saveFileName={`${accountRow.accountNumber}_Statement`} utilityStatementId={getLastStatementId(accountRow.id)} usageTrackingCallback={() => trackStatementDownload(accountRow.id, accountRow.accountNumber)} /> }))])
    }
  }, [statementData, accountRowData])

  const trackCredentialsClick = () => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.CLICK_ENTER_UTILITY_CREDENTIALS)
  const utilityHeadings = [
    {
      name: 'account',
      title: 'Account',
      key: 'account',
      maxWidth: '120px',
      onDataClick: (rowData) => {
        trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ACCOUNT_LINK, { "utility account number": rowData?.accountNumber, "utility account id": rowData?.id })
        navigate(pages["Account Details/:id"].href.replace(":id", rowData.id))
      },
    },
    {
      name: 'id',
      title: 'Account ID',
      key: 'id',
      maxWidth: '120px'
    },
    {
      name: 'providerName',
      title: 'Provider Name',
      key: 'providerName',
      maxWidth: '120px'
    },
    {
      name: 'buildings',
      title: 'Buildings',
      key: 'buildings',
      maxWidth: '120px'
    },
    {
      name: 'statement',
      title: 'Last Statement PDF',
      key: 'statement',
      maxWidth: '120px'
    },
  ]

  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} padding="0">
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        position="relative"
      >
        <PageHeader pageTitle="Connected Utility Accounts" />
        {Boolean(hasUBMOffering && accountRowData?.length) && <a href={`${getConfig("externalLinks.arcadiaUrl")}?correlationId=Account:${orgIdFromPicker}`} target="_blank" rel="noreferrer" style={{ position: "absolute", bottom: "10px", right: "0px" }}><Button type="ghost" onClick={trackCredentialsClick}>Enter Utility Credentials</Button></a>}
      </Content>
      {loadingAccounts || loadingOfferings ?
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <Spinner />
        </Content>
        :
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          display="flex"
        >
          {
            Boolean(!hasUBMOffering || !accountRowData?.length)
              ?
              <div data-testid="no-accounts-message" style={{ height: "200px", width: "100%", display: "inline-flex", justifyContent: "space-between", alignItems: "flex-start", padding: "24px", flexDirection: "column", border: "1px solid rgb(226, 226, 226)", borderRadius: "10px", background: 'white' }}>
                <p style={{ fontSize: "14px" }}>
                  {!hasUBMOffering ?
                    "There are currently no connected utility accounts.  If you would like to connect your utility accounts to view energy data, reach out to your Trane Sales representative to discuss Utility Bill Management."
                    : "There are currently no utility accounts to display at this time."}
                </p>
                <p style={{ fontSize: "14px" }}>By clicking the "Enter Utility Credentials" button and connecting an account, customer fully authorizes Trane to access, discuss, receive any and all billing information on the Customer’s account(s), and to use such information as necessary to provide services.</p>
                <a href={`${getConfig("externalLinks.arcadiaUrl")}?correlationId=Account:${orgIdFromPicker}`} target="_blank" rel="noreferrer"><Button onClick={trackCredentialsClick}>Enter Utility Credentials</Button></a>
              </div>
              : <Table
                testName="utilityAccountsTable"
                key="utilityAccountsTable"
                rows={accountRowData}
                header={utilityHeadings}
                search={true}
                searchFields={['providerName', 'account', 'id']}
                fixedLayout={false}
                showItemCount={true}
                title=" "
                alignWithFilters={true}
                actionText="Save"
                iconType="none"
                headerPadding="0 0 10px 0"
              />
          }
        </Content>
      }
    </Container>
  )
}
