import { ReactNode } from 'react'
import { RouteUrlParamWrapper } from './RouteUrlParamWrapper'

type RouteWrapperProps = {
  children: ReactNode
  // key is required when using <RouteWrapper> but React does not let us access it inside the component to verify.
  // This ensures that loading is reset for each route rather than detecting route change causing flashes of blank screens.
  key: string
}

export const RouteWrapper = ({ children }: RouteWrapperProps) => {
  return (
    <div data-testid="RouteWrapper">
      <RouteUrlParamWrapper>{children}</RouteUrlParamWrapper>
    </div>
  )
}
