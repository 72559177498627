import { type IndexRouteObject } from 'react-router'
import { type ReactI18NextChild } from 'react-i18next'
import { pages } from 'src/pages/pages'
import { USER_EVENTS } from 'src/amplitude-categories'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGrid2,
  faCloudExclamation,
  faBolt,
  faCircleBolt,
  faMessageExclamation,
  faServer,
  faSignalStream,
  faUserGear,
  faToolbox
} from 'src/denali-components/lib/pro-solid-svg-icons'
import styles from './sidebar.module.scss'
import { accessControlFunc } from 'src/components/accessControl'
import { NavigationItem } from './NavigationItem'
import { faGauge, faPlus, faStar } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import {
  selectDashboards,
  setDashboard,
  setDashboardIdForWidget,
  setDashboardPath
} from 'src/redux/slicers/dashboard'
import { trackEvent } from 'src/amplitude.js'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { isUBMEnabled } from 'src/common/featureFlags'
import config from 'src/aws-exports.js'
import { EMEA_PROJECT_REGION } from 'src/components/Header/constants'

const IsEMEAInstance = config.aws_project_region === EMEA_PROJECT_REGION
export const MainNavigation = ({ className, menuIsOpen }) => {
  const userAccess = useSelector(selectUserAccess)
  const pages = getPagesList()
    .filter(filterProtectedRoutes(userAccess))
    .map(filterProtectedRouteSubPageLinks(userAccess))
    .filter(filterBlankSectionsAfterPermissionsCheck)
    .filter(filterUtilityAndEnergyForEMEAInstance)

  return (
    <nav className={`${className} ${styles.mainNavigation}`}>
      <ul>
        {pages.map((item, i) => (
          <NavigationItem
            key={i}
            item={item}
            i={i}
            menuIsOpen={menuIsOpen}
            isRootLevel
          />
        ))}
      </ul>
    </nav>
  )
}

function filterProtectedRoutes(userAccess) {
  return (page) =>
    page?.access ? accessControlFunc({ id: page.access, userAccess }) : true
}

function filterProtectedRouteSubPageLinks(userAccess) {
  // Migrated from src/components/Sidebar/index.tsx
  return (item: MenuItem) => {
    return {
      ...item,
      links: item.links?.filter((link) =>
        link?.access ? accessControlFunc({ id: link.access, userAccess }) : true
      )
    }
  }
}

function filterBlankSectionsAfterPermissionsCheck(item: MenuItem) {
  return !!item?.links?.length
}

function filterUtilityAndEnergyForEMEAInstance(item: MenuItem) {
  return IsEMEAInstance
    ? item?.name !== 'Utility & Energy' &&
        item?.name !== 'Energy' &&
        item?.name !== 'Utility Management'
    : item
}

const getPagesList = (): MenuItem[] => {
  const dispatch = useDispatch()
  const { data: dashboards } = useSelector(selectDashboards)
  const dashboardList = localStorage.getItem('dashboards')
    ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
    : dashboards

  return useMemo(() => {
    // Migrated from src/components/Sidebar/index.tsx
    const filteredDashboards = dashboardList
      ?.map((dashboard) => ({
        name: dashboard?.name,
        href: `/dashboard/${dashboard.id}`,
        onClick: () => {
          dispatch(
            setDashboardIdForWidget({
              dashboardId: dashboard.id
            })
          )
          dispatch(
            setDashboardPath({
              dashboardPath: `/dashboard/${dashboard.id}`
            })
          )
        },
        iconRight:
          dashboard.isDefault === '1' ? (
            <FontAwesomeIcon icon={faStar} />
          ) : undefined
      }))
      .sort((a, b) => (a?.name < b?.name ? -1 : 1))

    return [
      {
        name: 'Home',
        icon: <FontAwesomeIcon icon={faGrid2} />,
        links: [pages['Home']],
        neverUsePopup: true
      },
      {
        name: 'Dashboard',
        icon: <FontAwesomeIcon icon={faGauge} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_DASHBOARDS,
        access: 'tc.pages.dashboards',
        links: [
          ...filteredDashboards,
          {
            ...pages['Widget Library'],
            icon: <FontAwesomeIcon icon={faPlus} />,
            topDivider: filteredDashboards.length > 0
          },
          {
            name: 'Create Dashboard',
            icon: <FontAwesomeIcon icon={faPlus} />,
            onClick: (event) => {
              event.preventDefault()
              dispatch(
                setDashboard({
                  actionType: 'Create'
                })
              )
              trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_CREATE_DASHBOARD)
            }
          }
        ]
      },
      {
        name: 'Service',
        icon: <FontAwesomeIcon icon={faCloudExclamation} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_SERVICE,
        links: [
          pages['Opportunities'],
          pages['Issues/Findings'],
          pages['Automated Inspections']
        ]
      },
      ...(isUBMEnabled
        ? [
            {
              name: 'Utility & Energy',
              icon: <FontAwesomeIcon icon={faBolt} />,
              analytics:
                USER_EVENTS.NAVIGATION.events.EXPAND_UTILITY_AND_ENERGY,
              links: [
                pages['Portfolio Scorecard'],
                pages['Energy Intensity'],
                pages['Connected Utility Accounts'],
                pages['Utility Bill Upload'],
                pages['Energy Cost Summary'],
                pages['Energy Consumption'],
                pages['Electrical Demand'],
                pages['Load Analysis'],
                pages['Rate Structure Library']
              ]
            }
          ]
        : [
            {
              name: 'Energy',
              icon: <FontAwesomeIcon icon={faBolt} />,
              analytics:
                USER_EVENTS.NAVIGATION.events.EXPAND_ENERGY_PERFORMANCE,
              links: [
                pages['Portfolio Scorecard'],
                pages['Energy Cost Summary'],
                pages['Energy Consumption'],
                pages['Electrical Demand'],
                pages['Load Analysis'],
                pages['Rate Structure Library']
              ]
            },
            {
              name: 'Utility Management',
              icon: <FontAwesomeIcon icon={faCircleBolt} />,
              analytics:
                USER_EVENTS.NAVIGATION.events.EXPAND_UTILITY_MANAGEMENT,
              links: [pages['Utility Bill Data'], pages['Energy Intensity']]
            }
          ]),
      {
        name: 'Reports',
        icon: <FontAwesomeIcon icon={faMessageExclamation} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_REPORTS,
        links: [pages['Documents & Reports']],
        neverUsePopup: true
      },
      {
        name: 'Performance',
        icon: <FontAwesomeIcon icon={faServer} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_BUILDING_PERFORMANCE,
        links: [
          pages['Building Data'],
          pages['Automated Test Settings'],
          pages['Automated Test Suppressions'],
          pages['Command Center']
        ]
      },
      // {
      //   name: 'Facility',
      //   icon: <IconBuildingFill />,
      //   analytics: '',
      //   links: ['/404']
      // },
      {
        name: 'Remote Access',
        icon: <FontAwesomeIcon icon={faSignalStream} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_REMOTE_ACCESS,
        links: [
          pages['Connect to a Device']
          // pages['Manage Devices']
        ],
        neverUsePopup: true
      },
      {
        name: 'Configuration',
        icon: <FontAwesomeIcon icon={faToolbox} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_BUILDING_CONFIGURATION,
        links: [
          pages['Building Setup'],
          pages['Alarm Notification'],
          pages['Equipment Setup'],
          pages['Notes'],
          pages['Event Markers'],
          pages['Baseline Library']
        ]
      },
      {
        name: 'Admin Tools',
        icon: <FontAwesomeIcon icon={faUserGear} />,
        analytics: USER_EVENTS.NAVIGATION.events.EXPAND_ADMIN_TOOLS,
        links: [
          pages['User Management'],
          pages['Service Provider Management'],
          pages['Organization Management'],
          pages['Contracts & Offerings'],
          pages['Role Management'],
          pages['Permission Management'],
          pages['Application Management'],
          pages['User Type Management'],
          pages['Group Management'],
          pages['Data Reprocess'],
          pages['Property Metadata']
        ]
      }
    ]
  }, [dashboardList])
}

export interface MenuItem extends Omit<DenaliRoute, 'name' | 'href' | 'index'> {
  name?: string
  icon?
  iconRight?
  links?: MenuItem[]
  analytics?: string
  onClick?: (event) => void
  bottomDivider?: boolean
  topDivider?: boolean
  href?: string
  target?: string
  access?: string
  neverUsePopup?: boolean
}

export type DenaliRouteNavigationProps = {
  denaliBuildingPicker?: boolean
  groupByTag?: string[]
  claims?: string[]
  showLocations?: boolean
  updateTokenOrgBuildings?: boolean
  updateTokenOrg?: boolean
}

/* see src/pages/pages.js */
export interface DenaliRoute extends IndexRouteObject {
  name: ReactI18NextChild
  href
  access?: string
  type?: string
  navigationProps?: DenaliRouteNavigationProps
}

export type DenaliRouteHandle = {
  page?: DenaliRoute
}
