import { useCallback, useEffect, useState } from 'react'
import { ACTIONS } from 'src/constants'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_OPPORTUNITY_BY_ID } from './graphql'
import { useOpportunityContext } from './OpportunityContextProvider'
import {
  FileUploadType,
  getInitialOpportunity,
  updateOpportunity
} from './helpers'
import OpportunityDetailEdit from './OpportunityDetailEdit'
import { PAGE_TYPE } from './constants'
import OpportunityDetailView from './OpportunityDetailView'
import { useLocation } from 'react-router'
import { useAttachment } from 'src/hooks/attachment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import _cloneDeep from 'lodash/cloneDeep'
import _sortBy from 'lodash/sortBy'
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { accessControlFunc } from 'src/components/accessControl'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import Opportunity from 'src/models/opportunity'
import translate from 'src/common/translations'

const OpportunityCreateEditCopy = ({
    uiMode,
    handleCreateOpportunityClicked,
    deleteOpportunityHandler
  }) => {
  const {
    mode,
    setMode,
    setOpportunity,
    currentDate,
    userName,
    accountId,
    buildingId,
    opportunity,
    setError
  } = useOpportunityContext()
  const { state, pathname } = useLocation()
  const [action, setAction] = useState(null)
  const [imageDetail, setImageDetail] = useState(null)
  const [pageMode, setPageMode] = useState(null)
  const { downloadFileURL } = useAttachment()
  const userAccess = useSelector(selectUserAccess)
  const translateCopyOf=translate("Copy of")
  const { refetch: refetchOpportunity } = useQuery({
    query: GET_OPPORTUNITY_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getOpportunity',
    onSuccess: (opportunityData) => { 
      if (pageMode?.mode === PAGE_TYPE.VIEW) {
        if (opportunityData) {
          if (
            !opportunityData?.isVisible &&
            !accessControlFunc({
              id: 'tc.pages.findings.view-private',
              userAccess
            })
          ) {
            setError({
              errorMsg: 'The opportunity you are looking for is not accessible.',
              isError: true
            })
          }

          trackEvent(USER_EVENTS.OPPORTUNITIES.events.VIEW_OPPORTUNITY, {
            "opportunity id": opportunityData.id,
            "building id": opportunityData.buildingId,
            "organization id": opportunityData.accountId,
            "status":opportunityData.status,
            "next step": opportunityData.nextStep,
          })
        } else {
          setError({
            errorMsg: 'The opportunity you are looking for does not exist.',
            isError: true
          })
        } 
      }
      if (opportunityData) {
        (async () => {
          await fetchingOpportunityWithImages(opportunityData)
        })()
      }
    }
  })

  const fetchingOpportunityWithImages = async (opportunityData) => {
    const opportunityImages = []
    const findings = []
    const attachments = opportunityData?.attachments?.items ?? []
    for (const cur of attachments) {
      if (cur?.category === FileUploadType.ADD_IMAGES) {
        if (mode === ACTIONS.VIEW) {
          // Fetching Images In View Mode
          if (cur?.attachmentOrder === 0) {
            setImageDetail(cur)
            const img = await fetchImage(cur)
            opportunityImages.push(img)
          } else {
            const img = {
              original: null,
              imageId: cur?.id,
              originalImageId: cur?.id,
              originalTitle: cur?.title,
              description: cur?.description,
              name: cur?.name,
              buildingId: cur?.buildingId,
              title: cur?.title,
              caption: cur?.description,
              imageUrl: null,
              displayName: cur?.displayName,
              fileName: cur?.name,
              attachmentOrder: cur?.attachmentOrder
            }
            opportunityImages.push(img)
          }
        } else {
          // Fetching Images In Edit Mode
          setImageDetail(cur)
          const img = await fetchImage(cur)
          opportunityImages.push(img)
        }

      }
    }
    // Findings
    for (const issue of opportunityData?.issues?.items) {
      if (issue?.issue?.id) {
        const findingsImagesList = []
        const findingObj = {
          original: null,
          issueOpportunityId: issue?.id,
          findingId: issue?.issue?.id,
          name: issue?.issue?.title,
          images: issue?.issue?.attachments?.items
        }
        const findingsAttachments = issue?.issue?.attachments?.items ?? []
        for (const cur of findingsAttachments) {
          if (cur?.category === FileUploadType.ADD_IMAGES) {
            // Fetching Images In View Mode
            if (cur?.attachmentOrder === 0) {
              setImageDetail(cur)
              const img = await fetchImage(cur)
              findingsImagesList.push(img)
            } else {
              const img = {
                original: null,
                imageId: cur?.id,
                originalImageId: cur?.id,
                originalTitle: cur?.title,
                description: cur?.description,
                name: cur?.name,
                buildingId: cur?.buildingId,
                title: cur?.title,
                caption: cur?.description,
                imageUrl: null,
                displayName: cur?.displayName,
                fileName: cur?.name,
                attachmentOrder: cur?.attachmentOrder
              }
              findingsImagesList.push(img)
            }
          }
        }
        findingObj.original = findingsImagesList?.find(f=>f?.attachmentOrder === 0)?.original
        findingObj.images = [...findingsImagesList];
        findings.push({...findingObj})
      }
    }

    const convertToOpportunityObject = updateOpportunity(
      opportunityData,
      pageMode?.mode,
      userName,
      currentDate,
      translateCopyOf
    )
    const settings = _cloneDeep(convertToOpportunityObject.settings)
    settings.images = _sortBy(opportunityImages, "attachmentOrder") ?? []
    convertToOpportunityObject.findings = _cloneDeep(findings ?? [])
    convertToOpportunityObject.settings = _cloneDeep(settings)
    setOpportunity(convertToOpportunityObject)
  }

  const fetchImage = useCallback(
    async (image) => {
      let newFileName = image?.name
      if(mode !== ACTIONS.EDIT)
        newFileName = updateFileNameWithEpoch(image?.displayName)
      const img = {
        original: null,
        imageId: image?.id,
        originalImageId: image?.id,
        originalTitle: image?.title,
        description: image?.description,
        name: image?.name,
        buildingId: image?.buildingId,
        title: image?.title,
        caption: image?.description,
        imageUrl: null,
        displayName: image?.displayName,
        fileName: newFileName,
        attachmentOrder: image?.attachmentOrder
      }
      if(image?.name && image?.buildingId) {
        await downloadFileURL(image?.name, image?.buildingId).then((res) => {
          img.imageUrl = res
          img.original = res
        })
      }
      return img
    },
    [imageDetail]
  )

  useEffect(() => {
    if (!state) return
    setOpportunity(
      getInitialOpportunity(accountId, buildingId, currentDate, userName)
    )
    setPageMode(state)
    setMode(state?.mode)
    setAction(state?.action)
  }, [state])

  useEffect(() => {
    // It is triggered while paste url in new tab or coming from other third parties
    if (pathname && !state) { 
      // Getting Opportunity Id From URL
      const opportunityId = pathname?.split("/")?.pop()
      // If opportunityId is "add" then we set modes for adding and exit out.
      if (opportunityId === "add") {
        setPageMode({ mode: PAGE_TYPE.ADD, id: null })
        setMode(ACTIONS.ADD)
        return
      }
      setOpportunity(
        getInitialOpportunity(accountId, buildingId, currentDate, userName)
      )
      setPageMode({ mode: ACTIONS.VIEW, id: opportunityId })
      setMode(ACTIONS.VIEW)
    }
  }, [pathname])

  useEffect(() => {
    if (!pageMode) return
    if (
      pageMode?.id &&
      [PAGE_TYPE.COPY, PAGE_TYPE.EDIT, PAGE_TYPE.VIEW].includes(pageMode?.mode)
    ) {
      refetchOpportunity({ id: pageMode?.id })
    } else if (pageMode?.mode === PAGE_TYPE.ADD) {
      setOpportunity(
        getInitialOpportunity(accountId, buildingId, currentDate, userName)
      )
    }
  }, [pageMode])

  const isEditingOrCopying = [ACTIONS.EDIT, ACTIONS.COPY].includes(mode) && opportunity?.opportunityId;
  const isAdding = mode === ACTIONS.ADD && !opportunity?.opportunityId;

  return (
    <div>
      {mode === ACTIONS.VIEW && opportunity?.opportunityId ? (
        <OpportunityDetailView
          opportunity={opportunity}
          setOpportunity={v => setOpportunity(v)}
          action={action}
          uiMode={uiMode}
          handleCreateOpportunityClicked={handleCreateOpportunityClicked}
          deleteOpportunityHandler={deleteOpportunityHandler}
        />
      ) : (isEditingOrCopying || isAdding) ? (
        <OpportunityDetailEdit />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default OpportunityCreateEditCopy
