import { Button } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router'
import translate, { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPrintMagnifyingGlass,
  faPlus
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import DocumentsAndReportsList from 'src/pages/documentsAndReports/DocumentsAndReportsList'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { CreateReportButton } from './CreateReportButton'

/**
 * ReportsContainer
 * View replacement for src/pages/documentsAndReports/DocumentsAndReportsContainer.tsx
 */
export const ReportsContainer = ({
  organizationId,
  buildingId,
  checkDisableReport,
  addButtonLabel,
  optionsList,
  handleDropClick,
  uiMode
}) => {
  const navigate = useNavigate()

  return (
    <Page
      title={translate('Documents & Reports')}
      icon={<FontAwesomeIcon icon={faPrintMagnifyingGlass} />}
      iconColor={IconColor.purple}
      testid="reports page"
      actions={[
        <Tooltip key="add">
          <TooltipTrigger>
            <Button
              className="button-icon"
              data-testid="addDocumentButton"
              ariaLabel={addButtonLabel}
              onClick={() =>
                navigate(
                  `/reports/add?organization=${organizationId}${
                    buildingId ? `&location=${buildingId}` : ''
                  }`
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Add Document</TranslateComponent>
          </TooltipContent>
        </Tooltip>,
        <CreateReportButton
          key="create"
          optionsList={optionsList}
          handleDropClick={handleDropClick}
          checkDisableReport={checkDisableReport}
          buildingId={buildingId}
        />
      ]}
    >
      <DocumentsAndReportsList
        uiMode={uiMode}
        noTableDataAction={
          <CreateReportButton
            key="create"
            optionsList={optionsList}
            handleDropClick={handleDropClick}
            checkDisableReport={checkDisableReport}
            buildingId={buildingId}
          />
        }
      />
    </Page>
  )
}
