// Switch to role.jsx and delete this once selectable tasks are implemented.
import React, { useEffect, useState } from 'react'
import { Card, Block } from '@trane/trane-components/dist'
import Box from 'src/denali-ui/components/Box'
import CheckBox from 'src/denali-ui/components/Checkbox'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useQuery } from 'src/hooks/APIHooks'
import { LIST_CUSTOM_ROLES_BY_APP_ID } from '../graphql/queries'
import { TranslateComponent } from '../../../common/translations'
import { useSelector } from 'react-redux';


export const ROLES = {
  REMOTE_ACCESS: 'RemoteAccess',
  GENERAL_USER: 'GeneralUser',
  GS_PRIMARY_USER: 'GSPrimaryUser',
  GS_TECHNICIAN: 'GSTechnician',
  GS_VIEWER: 'GSViewer',
  GS_EXTERNAL_TECHNICIAN: 'GSExternalTechnician',
  TECHNICIAN: 'Technician',
  TECH_SUPPORT: 'TechSupport',
  SUPER_ADMIN: 'SuperAdmin',
  TCC4_USER: 'TCC4',
  TCC_TECHNICIAN: 'TCC_Technician',
  ANALYTICS_TECHNICIAN: 'AnalyticsTechnician',
  CUSTOMER_TECHNICIAN: 'CustomerTechnician',
  CUSTOMER_ASSETS_TECHNICIAN: 'CustomerAssetsTechnician',
  KIOSK: 'Kiosk',
  DEVICEMANAGER: 'DeviceManager',
  ENSEMBLE_ADMINISTRATOR: 'EnsembleAdministrator',
  ENSEMBLE_ADVANCE_BUILDING_OPERATOR: 'EnsembleAdvanceBuildingOperator',
  ENSEMBLE_BUILDING_OPERATOR: 'EnsembleBuildingOperator',
  ENSEMBLE_VIEW: 'EnsembleView',
  ENSEMBLE_TENANT_EMPLOYEE: 'EnsembleTenantEmployee',
  RC_TECHNICIAN: 'RCTechnician',
  RC_ADMIN: 'RC_ADMIN',
  RC_DATABASE_ADMIN: 'RC_DB_ADMIN',
  CONTRACTOR_TECHNICIAN: 'ContractorTechnician',
  SERVICE_COMPANY_ADMIN: 'ServiceCompanyAdmin',
  DMS_BUILDINGOWNER: 'DMS_BUILDINGOWNER',
  DMS_SERVICER: 'DMS_SERVICER',
  CONTRACT_MANAGER: 'ContractManager',
  OFFERING_MANAGER: 'OfferingManager'
}

const StyledRoleBox = styled(Box)`
  & > :nth-child(odd) {
    margin-right: 16px;
    flex-basis: calc(50% - 16px);
  }
`

const StyledRoleCardBlock = styled(Block)`
  flex-basis: 50%;
`

const StyledUl = styled.ul`
  list-style: inside;
`

const RoleShape = {
  roleName: PropTypes.string,
  roleDescription: PropTypes.string,
  selected: PropTypes.bool,
  tasks: PropTypes.arrayOf(PropTypes.string)
}

  const nonTranslatableWords = ['Command Center','Trane Service','Clockwork-POC','Trane Connect ','Trane'];
  const getTranslatedContent = (text) => {
  const selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage);
    if (selectedLanguage == 'en'){
      return text;
    }
    const splitText = text.split(new RegExp(`(${nonTranslatableWords.join('|')})`, 'g'));
    return splitText.map((word, index) => {
      if (nonTranslatableWords.includes(word)) {
        return <span key={index}>{word}</span>; // Do not translate this part
      }
      return <TranslateComponent key={index}>{word}</TranslateComponent>; // Translate this part
    });
  };
const Role = ({ name, userInfoObject, id, appId, setUserInfoObject, type, resourceTypes, userTypes }) => <CheckBox
    onClick={() => {
      const clonedUserInfoObj = JSON.parse(JSON.stringify(userInfoObject))
      if (!userInfoObject.applications[appId] || !userInfoObject.applications[appId].roles[id]) {
        // Easy/peformant way to deep clone
        clonedUserInfoObj.applications[appId].roles[id] = {name, id, appId, type, resourceTypes, userTypes}

        // const newApplications = {...userInfoObject.applications, [appId]: {
        //   ...userInfoObject.applications[appId] || {},
        //   [id]: {name, id, appId}
        // } }


        setUserInfoObject(clonedUserInfoObj)
      } else {
        delete clonedUserInfoObj.applications[appId].roles[id]
        setUserInfoObject(clonedUserInfoObj)
      }
    }}
    checked={Boolean(userInfoObject.applications?.[appId]?.roles?.[id])}
    label={<Box fontWeight="bold">{getTranslatedContent(name)}</Box>}
    disabled={!Boolean(userInfoObject.applications[appId])}
  />

const RoleDetail = ({
  type,
  description,
  tasks,
  name,
  resourceTypes,
  userTypes,
  userInfoObject,
  setUserInfoObject,
  id,
  appId
}) => (
  <StyledRoleCardBlock margin="0 0 16px 0">
    <Card>
      <Block padding="16px">
        <Role
          name={name}
          type={type}
          resourceTypes={resourceTypes}
          userTypes={userTypes}
          userInfoObject={userInfoObject}
          setUserInfoObject={e => setUserInfoObject(e)}
          appId={appId}
          id={id}
        />
        <Box padding="16px 0"><TranslateComponent>{description}</TranslateComponent></Box>
        {tasks?.length > 0 && (
          <>
            <Box paddingBottom="16px" fontWeight="bold">
              <TranslateComponent>Tasks:</TranslateComponent>
            </Box>
            <StyledUl>
              {tasks.map((task, key) => (
                <li key={key}><TranslateComponent>{task}</TranslateComponent></li>
              ))}
            </StyledUl>
          </>
        )}
      </Block>
    </Card>
  </StyledRoleCardBlock>
)

RoleDetail.propTypes = RoleShape

const Roles = ({ userInfoObject, setUserInfoObject, appId }) => {
  const [roleList, setRoleList] = useState([])

  const {data: roleListNew} = useQuery({
    query: LIST_CUSTOM_ROLES_BY_APP_ID,
    errorPolicy: 'global',
    variables: {id: appId},
    dataPath: 'data.listCustomUserRolesByApp'
  })

  useEffect(() => {
    if (roleListNew) {
      try {
        const parsedRoles = JSON.parse(JSON.parse(roleListNew).body)
        if (parsedRoles?.data?.adminRoles?.items?.length)
          setRoleList(parsedRoles.data.adminRoles.items.filter(e => e?.userTypes?.includes(userInfoObject?.userType?.value)))
        else if (parsedRoles?.data?.userRoles?.items?.length) {
          setRoleList(parsedRoles.data.userRoles.items.map(e => e).filter(e => e?.userTypes?.includes(userInfoObject?.userType?.value)).reduce((accum, curr) => {
            if (accum.findIndex(e => e.id === curr.id) === -1) {
              accum.push(curr)
            }
            return accum
          }, []) || [])
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [roleListNew])

  return <StyledRoleBox display="flex" flexWrap="wrap">
    {roleList?.length > 0 ?
      roleList.map((role) => {
        return (
          <RoleDetail
            key={role.id}
            {...role}
            appId={appId}
            userInfoObject={userInfoObject}
            setUserInfoObject={e => setUserInfoObject(e)}
          />
        )
      }) : <div><TranslateComponent>No roles for this application</TranslateComponent></div>}
  </StyledRoleBox>

    }

Roles.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape(RoleShape))
}

export { Roles }
