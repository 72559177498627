import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import SelectInput from 'src/components/legacy/components/modal-select-input'
import DateInput from 'src/components/legacy/components/modal-datepicker-input'
import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
// components
import Modal from 'src/components/legacy/components/modal/modal'
import ObjectPicker from './objectPickerNew'

import { ACTIONS } from 'src/constants'
import { useTranslation } from 'react-i18next'

import validationSchema from './formValidator'
import { CREATE_EVENT_MARKER, UPDATE_EVENT_MARKER } from './../graphql'
import { GET_BUILDINGS_BY_ACCOUNTS } from '../../connectToADevice/graphql/queries'
import { IMarker } from '../types'
import { tranformDateToISO } from 'src/utils'
import styled from 'styled-components'
import { newRefreshToken } from 'src/redux/slicers/appData'
import { useDispatch } from 'react-redux'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import configs from 'src/components/legacy/common/datepicker/configs'
import translate from 'src/common/translations'
import store from 'src/redux/store';

const ModalDivSpacer = styled.div<{ orgView: boolean }>`
  ${(props) =>
    props.orgView
      ? `display: flex;
flex-wrap: wrap;
border-top: 1px solid #ffffff;
border-bottom: 1px solid #eeeeee;
> * {
	&:last-child {
			background-color: transparent;
		    margin-left: 20px;
	}
  }
`
      : ''}
`

const ACTION_HEADERS = {
  [ACTIONS.ADD]: 'Create Marker',
  [ACTIONS.EDIT]: 'Edit Marker',
  [ACTIONS.VIEW]: 'View Marker',
  [ACTIONS.COPY]: 'Copy Marker'
}
const EventMarkers = {
  CONFIRMATION_TYPES: {
    CREATE: 'create',
    UPDATE: 'update',
    EMPTY: ''
  }
}
const EventMarkerDetail = ({
  rows,
  mode,
  setMode,
  markerInput,
  setMarkerInput,
  initialValues,
  markerTypes,
  buildingId,
  accountId,
  setReloadEvents,
  setShowConfirmation = (e: any) => {},
  setConfirmationType = (e: any) => {}
}) => {
  console.log('buildingId', buildingId)
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [buildingsCheckList, setBuildingsCheckList] = useState([])
  const [isNoDateFormatApply, setIsNoDateFormatApply] = useState(true)

  let selectedLanguage = store?.getState().appData?.selectedLanguage
  selectedLanguage = selectedLanguage ? selectedLanguage : "en"

  const getModalHeading = () => {
    return ACTION_HEADERS[mode]
  }
  const customHeading = () => {
    const heading =
      mode === ACTIONS.ADD || mode === ACTIONS.COPY
        ? ``
        : markerInput?.name && `: ${markerInput?.name}`
    return heading
  }

  const translatedOptions = {
    eventMarker : translate("Marker Type")
  }

  const {
    data: accountData,
    refetch: refetchAccount,
    loading
  } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNTS,
    dataPath: 'data.listBuildingsByAccount.items',
    disableInitialLoad: true,
    errorPolicy: 'ignore'
  })

  const { onSubmit: createEventsMutation, loading: createMarkerLoading } =
    useMutation({
      query: CREATE_EVENT_MARKER,
      onSuccess: () => {
        closeMarkerDialog()
        setReloadEvents(true)
        setConfirmationType(EventMarkers.CONFIRMATION_TYPES.CREATE)
        setShowConfirmation(true)
        {
          mode === ACTIONS.ADD
            ? trackEvent(USER_EVENTS.EVENT_MARKERS.events.SAVE_EVENT_MARKER, {mode:"CREATE"})
            : mode === ACTIONS.COPY
            ? trackEvent(USER_EVENTS.EVENT_MARKERS.events.SAVE_EVENT_MARKER, {mode:"COPY"})
            : null
        }
      }
    })

  const { onSubmit: updateEventsMutation } = useMutation({
    query: UPDATE_EVENT_MARKER,
    onSuccess: () => {
      closeMarkerDialog()
      setReloadEvents(true)
      setConfirmationType(EventMarkers.CONFIRMATION_TYPES.UPDATE)
      setShowConfirmation(true)
      trackEvent(USER_EVENTS.EVENT_MARKERS.events.SAVE_EVENT_MARKER, {mode:"EDIT"})
    }
  })
  const modifiedValues =
    mode === ACTIONS.ADD
      ? { ...initialValues }
      : {
          ...markerInput,
          type: markerInput?.type,
          markerDate: markerInput?.markerDate,
          name: markerInput?.name,
          description: markerInput?.description,
          buildingIds: markerInput?.buildingIds,
          id: mode === ACTIONS.COPY ? null : markerInput.id
        }

  const formik = useFormik({
    initialValues: modifiedValues,
    validationSchema: validationSchema(Boolean(!buildingId), rows, mode),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: IMarker) => {
      const {
        name,
        markerDate: rawMarkerDate,
        description,
        type,
        buildingsList,
        buildingIds: storedBuildingIds
      } = values
      const markerDate = tranformDateToISO(rawMarkerDate)
      mode === ACTIONS.EDIT
        ? await dispatch<any>(
            newRefreshToken(null, [accountId], null, ["EventMarkerAdmin"])
          ).then(() => {
            updateEventsMutation({
              input: {
                id: markerInput.id,
                name,
                description,
                markerDate: moment(markerDate).locale("en").format("YYYY-MM-DD"),
                type,
                accountId,
                buildingIds: storedBuildingIds
              }
            })
          })
        : buildingsList?.length
        ? await dispatch<any>(newRefreshToken(null, [accountId], null, ["EventMarkerAdmin"])).then(
            () => {
              buildingsList.forEach((b) =>
                createEventsMutation({
                  input: {
                    name,
                    description,
                    markerDate: moment(markerDate).locale("en").format("YYYY-MM-DD"),
                    type,
                    accountId,
                    buildingIds: [b]
                  }
                })
              )
            }
          )
        : createEventsMutation({
            input: {
              name,
              description,
              markerDate: moment(markerDate).locale("en").format("YYYY-MM-DD"),
              type,
              accountId,
              buildingIds: storedBuildingIds?.length
                ? storedBuildingIds
                : [buildingId]
            }
          })
    }
  })

  const { values, errors } = formik

  useEffect(() => {
    if (!buildingId) {
      refetchAccount({ id: accountId })
    }
  }, [])

  useEffect(() => {
    if (mode === ACTIONS.COPY) {
      //On Edit click, to initiate the validation for markerDate duplicate check
      formik.setTouched({
        ...formik.touched,
        markerDate: true
      })
    }
  }, [values])

  useEffect(() => {
    if (accountData?.length) {
      setBuildingsCheckList(
        accountData.map((a) => ({
          name: a.name,
          id: a.id,
          checked: values?.buildingsList?.includes(a.id)
        }))
      )
    }
  }, [accountData])

  const onCheckboxClick = (item) => {
    let itemsList = [...buildingsCheckList]
    const index = itemsList.findIndex((i) => i.id === item.id)
    itemsList[index].checked = !itemsList[index].checked
    if (mode === ACTIONS.EDIT) {
      itemsList = itemsList?.map((i) =>
        i.id === item.id ? i : { ...i, checked: false }
      )
      formik.setFieldValue(
        'buildingIds',
        itemsList[index].checked ? [itemsList[index].id] : null
      )
    }

    setBuildingsCheckList(itemsList)
    formik.setFieldValue(
      'buildingsList',
      itemsList.filter((i) => i.checked).map((i) => i.id)
    )
  }

  const onCheckboxUnclick = (item) => {
    const itemsList = [...buildingsCheckList]
    itemsList[itemsList.findIndex((i) => i.id === item.id)].checked = false
    setBuildingsCheckList(itemsList)
    formik.setFieldValue(
      'buildingsList',
      itemsList.filter((i) => i.checked).map((i) => i.id)
    )
  }

  const getButtons = (submitForm, isFormValid = false) => {
    return mode === ACTIONS.VIEW
      ? [{ text: t('common:Close'), handleClick: closeMarkerDialog }]
      : [
          {
            testName: 'eventMarker',
            text: mode === ACTIONS.ADD ? t('common:Create') : t('common:Save'),
            type: 'submit',
            handleClick: submitForm,
            disabled: !isFormValid || createMarkerLoading || !formik.values.name
          },
          {
            testName: 'eventMarker',
            text: t('common:Cancel'),
            type: 'cancel',
            handleClick: closeMarkerDialog
          }
        ]
  }

  const closeMarkerDialog = () => {
    setMode(null)
    setMarkerInput({ ...formik?.values })
  }

  const modalConfig = {
    gray: true,
    className: 'wide-modal',
    heading: getModalHeading(),
    customHeaders: customHeading(),
    buttons: getButtons(formik.handleSubmit, formik.isValid),
    handleClose: closeMarkerDialog
  }

  const handleInputChange = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  return (
    <Modal {...modalConfig}>
      <ModalDivSpacer orgView={Boolean(!buildingId && mode !== ACTIONS.EDIT)}>
        {!buildingId && (
          <div>
            <ObjectPicker
              values={buildingsCheckList}
              onCheckboxClick={(e) => onCheckboxClick(e)}
              onCheckboxUnclick={(e) => onCheckboxUnclick(e)}
              error={errors?.['buildingsList']}
            />
          </div>
        )}
        <div className="is-iconError">
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextInput
              testName="Marker_events"
              labelText="Marker Name"
              defaultValue={values?.name}
              name="name"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(name, value)
              }
              hasError={errors?.['name']}
              errorMessage={errors?.name}
            />
            <TextAreaInput
              testName={'Marker_Description_events'}
              labelText="Marker Description"
              defaultValue={values?.description}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('description', value)
              }
              name="description"
              isRequired={false}
            />
            <SelectInput
              labelText={translatedOptions.eventMarker}
              name="type"
              options={markerTypes}
              selectedItem={values.type}
              onChange={(value: any) => handleInputChange('type', value)}
            />
            <DateInput
              labelText="Marker Date"
              date={selectedLanguage === "en" ? values?.markerDate : moment(values?.markerDate).locale('en').format("MM/DD/YYYY")}
              onChange={(value: any) => (
                handleInputChange(
                  'markerDate',
                  moment(value).locale('en').format('MM/DD/YYYY')
                 ),
                 setIsNoDateFormatApply(true)
                )
              }
              name="markerDate"
              hasError={errors['markerDate']}
              errorMessage={errors?.markerDate}
              isNoDateFormatApply={isNoDateFormatApply}
              formatApply={'MM/DD/YYYY'}
            />
          </form>
        </div>
      </ModalDivSpacer>
    </Modal>
  )
}
export default EventMarkerDetail
