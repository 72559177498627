import { createContext, useContext } from 'react'
import { InlineEquipmentSelectorContextType } from '../types'

export const InlineEquipmentSelectorContext =
  createContext<InlineEquipmentSelectorContextType>(null)

export const useInlineEquipmentSelectorContext = () => {
  const context = useContext(InlineEquipmentSelectorContext)

  if (context == null) {
    throw new Error(
      'InlineEquipmentSelector component must be wrapped in <InlineEquipmentSelectorProvider />'
    )
  }

  return context
}
