import { Button, Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Project imports
import { getField } from 'src/components/legacy/common/helpers'
import { MAX_LENGTH_OF_NAME } from 'src/components/legacy/common/opportunity.js'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import ToggleBox from 'src/components/legacy/components/toggle-box'
import { getSearchParams } from "src/common/helperFunctions.js"
import { translate, TranslateComponent } from 'src/common/translations'
import { useMutation } from 'src/hooks/APIHooks'
import Findings from 'src/models/findings'
import { NewStyles } from 'src/NewStyles'
import { selectUiMode, selectUserInfo } from 'src/redux/slicers/appData'
import { setIssuesAndFindings } from 'src/redux/slicers/formData'
import { TStore, useAppDispatch } from 'src/redux/store'
import { UiMode } from 'src/redux/types/AppTypes'

// Denali imports
import { faBolt } from 'src/denali-components/lib/pro-solid-svg-icons'
import { CollapsibleWithActions } from 'src/denali-components/CollapsibleWithActions/CollapsibleWithActions'
import { IssuesFindingsQuickAddForm } from 'src/denali-pages/IssuesAndFindings/IssuesAndFindingsQuickAddForm'
import styles from 'src/denali-pages/IssuesAndFindings/issues-findings.module.scss'

// Local imports
import { CREATE_FINDING_QUICK_ADD, CREATE_BULK_EQUIPMENT_ISSUES } from '../graphql'
import { FormatComment, deriveTestName, } from '../helpers'
import { handleAddDetailClick } from './helpers'
import { useIssuesFindingsContext } from '../IssuesFindingsContextProvider'
import QuickAddForm from './QuickAddForm'
import { QUICK_ADD_FORM_KEY } from 'src/denali-pages/IssuesAndFindings/constants'

const QuickAdd = () => {
  const { buildingId: locationId, organizationId } = getSearchParams()
  const { setReloadEvents, isFindingAdding, setIsFindingAdding } = useIssuesFindingsContext()
  const [initialValues, setInitialValues] = useState(new Findings({ organizationId, locationId, comment: '', tisObjects: [] }))
  const [t] = useTranslation()
  const userInfo = useSelector(selectUserInfo)
  const getUserName = () => {
    return `${userInfo?.lastName} ${userInfo?.firstName}`
  }
  const userName = getUserName()
  const uiMode = useSelector(selectUiMode)
  const useDenaliTheme = uiMode == UiMode.denali
  const store = useStore<TStore>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const optionsTranslated = {
    hideQuickAdd: translate('Hide Quick Add'),
    expandQuickAdd: translate('Expand Quick Add')
  }

  const { onSubmit: createFindingQuickAddMutation } = useMutation({
    query: CREATE_FINDING_QUICK_ADD
  })

  const { onSubmit: createBulkEquipmentIssue } = useMutation({
    query: CREATE_BULK_EQUIPMENT_ISSUES
  })

  // if the org ID or location ID changes, update the initial values
  useEffect(() => {
    setInitialValues(new Findings({ organizationId, locationId, comment: '', tisObjects: [] }))
    dispatch(setIssuesAndFindings(initialValues))
  }, [organizationId, locationId])

  const validateForm = (values) => {
    const errors: any = {}
    const organization = getField(values, 'organization.organizationId')
    const location = getField(values, 'location.locationId')

    if (
      !values.name ||
      typeof values?.name !== 'string' ||
      values.name?.length === 0 ||
      (values.name?.length > 0 && values.name.trim().length === 0)
    ) {
      errors.name =<TranslateComponent>The name is required.</TranslateComponent>
    }
    if (values.name.length > MAX_LENGTH_OF_NAME) {
      errors.name = (
        <Trans
          i18nKey={'errors:TooLongName'}
          values={{ number: MAX_LENGTH_OF_NAME }}
        />
      )
    }
    if (!location) {
      errors.location = <Trans i18nKey={'errors:buildingIsRequired'} />
    }
    if (!organization) {
      errors.organization = "Organization is required."
    }

    return errors
  }

  // Create the findings using the GraphQL mutations.
  const createFindings = useCallback(async (values: Findings) => {
    const {
      name,
      comment,
      priority,
      status,
      foundDuring,
      nextStep,
      location,
      organization
    } = values
    await createFindingQuickAddMutation({
      input: {
        accountId: organization?.organizationId,
        buildingId: location?.locationId,
        title: name,
        comment: FormatComment(comment),
        priority,
        status,
        nextStep,
        foundDuring,
        createdBy: userName,
        creationDate: moment().format(BACKEND_DATE_FORMAT)
      }
    }).then(async (res) => {
      const issueID = res.data?.createIssue?.id
      const insertInput = {
        equipmentList: values?.tisObjects?.map(e => e?.tisObjectId),
        opportunityList: [],
        modelType: 'Issue',
        modelId: issueID,
        action: 'Create',
        accountId: organization?.organizationId,
        buildingId: location?.locationId
      }
      await createBulkEquipmentIssue({
        input: JSON.stringify(insertInput)
      })
      setReloadEvents(true)
    })
  }, [createFindingQuickAddMutation, createBulkEquipmentIssue, setReloadEvents])

  const onSubmit = async (values, { resetForm }) => {
    setIsFindingAdding(true)
    await createFindings(values)
    resetForm()
    setIsFindingAdding(false)
  }

  const cancel = (closeCollapsible: () => void) => {
    // Reset values and close the collapsible.
    dispatch(setIssuesAndFindings(initialValues))
    closeCollapsible()
  }

  const onSubmitFromDenaliUi = useCallback(() => {
    document?.getElementById('issues-findings-quick-add-form')?.dispatchEvent(
      new Event('submit', {
        bubbles: true,
        cancelable: true,
      }),
    )
  }, [])

  if (useDenaliTheme) {
    return (
      <div className={styles.quickAddCollapsible}>
        <CollapsibleWithActions
          openToggleTitle={optionsTranslated.hideQuickAdd}
          closedToggleTitle={optionsTranslated.expandQuickAdd}
          useLocalStorage
          localStorageKey={QUICK_ADD_FORM_KEY}
          header={
            <>
              <Flex direction="row" alignItems="center">
                <FontAwesomeIcon icon={faBolt} color="var(--amplify-colors-blue-50)" />
                <span className={styles.quickAddText}>
                  <TranslateComponent>Quick Add</TranslateComponent>
                </span>
              </Flex>
            </>
          }
          actionsBuilder={
            (_: boolean, onToggle: () => void) => {
              return (
                <>
                  <Flex className={styles.quickAddHeaderButtons}>
                    <Button disabled={isFindingAdding} onClick={() => handleAddDetailClick(store.getState().formData.issuesAndFindings, locationId, organizationId, navigate)} size="small"><TranslateComponent>Add Details</TranslateComponent></Button>
                    <Button disabled={isFindingAdding} onClick={() => cancel(onToggle)} size="small"><TranslateComponent>Cancel</TranslateComponent></Button>
                    <Button disabled={isFindingAdding} className={styles.quickAddSaveButton} onClick={onSubmitFromDenaliUi} size="small" variation="primary" colorTheme="success"><TranslateComponent>Save</TranslateComponent></Button>
                  </Flex>
                </>
              )
            }
          }>
          <IssuesFindingsQuickAddForm defaultValues={initialValues} onSubmit={onSubmit} />
        </CollapsibleWithActions>
      </div>
    )
  }

  return (
    <NewStyles>
      <ToggleBox
        label="QUICK ADD ISSUE/FINDING"
        showOnlyLabel={true}
        className="findings-quick-add-box"
        opened={true}
        testName={deriveTestName() + '_quick-add'}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validateForm}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
          validateOnMount={true}
        >
          {(props) => (
            <Form>
              <QuickAddForm {...props} />
            </Form>
          )}
        </Formik>
      </ToggleBox>
    </NewStyles>
  )
}

export default QuickAdd
