import { useEffect, useState } from 'react'
import { useTooltipContext } from './TooltipProvider'
import './styles.scss'
import clsx from 'clsx'
import translate, { TranslateComponent } from 'src/common/translations'

type HighlightDots = {
  x: number
  y: number
  color?: string
}
export interface TooltipDataModel {
  higlightDots: boolean
  highlightDots: HighlightDots[]
  x: null | number
  xValue: null | number
  y: null | number
  yValue: null | number
  title?: null | any
  value: null | any
  label: null | string | any
  color?: string
  groupBy?: 'title' | 'value' | 'label' | 'color' | null | undefined | false
  [k: string]: any
}

const DEFAULT_MODEL: TooltipDataModel = {
  higlightDots: false,
  highlightDots: [],
  x: null,
  xValue: null,
  y: null,
  yValue: null,
  title: null,
  value: null,
  label: null,
  color: 'steelblue',
  groupBy: 'title'
}

export function createTooltipDataModel<T = TooltipDataModel>(props: T): T {
  return {
    ...DEFAULT_MODEL,
    ...props
  }
}

export function TooltipTemplate({
  canvasWidth,
  canvasHeight,
  Template = null,
  hide = false,
  className,
  forigenObjectProps,
  renderingAreaClipPath,
  isTranslate=false,
  ...rest
}) {
  const context = useTooltipContext()

  const [data, setData] = useState([])

  useEffect(() => {
    context.subscribe(setData)
  }, [context])


  let xy = data.reduce(
    (av, point) => {

    // if position is optional, tooltip will not consider XY postion of the points. 
    // Instead use other points xy to position the tooptip
    // Incase if no other XY available, this will be used for the positioning
      if(data.length > 1 && point?.tooltipPositon === 'optional') {
        return av;
      }

      return {
        x: Math.min(av.x, point.x),
        y: Math.min(av.y, point.y)
      }
    },
    { x: Infinity, y: Infinity }
  )

  // For optinal tooltip position
  if(xy.x === Infinity || xy.y === Infinity) {

    const isAllOptional = data.every(point=> point?.tooltipPositon === 'optional')

    if(isAllOptional)
    xy = data.reduce(
      (av, point) => {
  
        return {
          x: Math.min(av.x, point.x),
          y: Math.min(av.y, point.y)
        }
      },
      { x: Infinity, y: Infinity }
    )
  }

  if (!data.length || xy.x === Infinity || xy.y === Infinity || hide)
    return null

  const { coords, containerSize } = calcPostion(
    xy.x,
    xy.y,
    canvasWidth,
    canvasHeight
  )

  const { title = [], ...groupedByProp } = groupByProp(data)
  const groupedData = Object.entries(groupedByProp || {})

  return (
    <g clipPath={`url(#${renderingAreaClipPath})`}>
      {data.length &&
        data.map((point: TooltipDataModel, i) => {
          return (
            point.higlightDots &&
            point.highlightDots.map((dot, j) => (
              <circle
                r="4"
                className="t-chart-tooltip-highlight-dot"
                cx={dot.x}
                cy={dot.y}
                fill={dot?.color || point?.color || 'steelblue'}
                key={'_key' + i + '_' + j}
              />
            ))
          )
        })}
      <foreignObject
        width={containerSize.width}
        height={containerSize.height}
        pointerEvents="none"
        {...forigenObjectProps}
        className={clsx(
          't-chart-tooltip-foreignObject',
          forigenObjectProps?.className
        )}
        transform={`translate(${containerSize.widthStartsAt} 0)`}
      >
        <div className={clsx('t-chart-tooltip-wrapper', className)} {...rest}>
          <div
            className={`t-chart-tooltip-arrow t-chart-tooltip-arrow-${
              coords.x ? 'right' : 'left'
            }`}
            style={{
              ...(coords.x ? { right: 3 } : { left: coords.x + 3 }),
              top: coords.y + 4
            }}
          ></div>
          <div
            className="t-chart-tooltip-content-area"
            style={{
              left: coords.x,
              top: coords.y
            }}
          >
            {Template ? (
              <Template data={data} />
            ) : (
              <>
                {groupedData.map(
                  ([key, points = []]: [string, TooltipDataModel[]], i) => (
                    <WithTitleTemplate points={points} key={i} title={key} isTranslate={isTranslate} />
                  )
                )}
                {title.map((point: TooltipDataModel, idx) => (
                  <SingleItemTemplate data={point} key={idx} isTranslate={isTranslate} />
                ))}
              </>
            )}
          </div>
        </div>
      </foreignObject>
    </g>
  )
}

function groupByProp(points: TooltipDataModel[] = []) {
  return points.reduce(
    (av, point) => {
      let k = 'title'

      if (
        point &&
        point.groupBy !== undefined &&
        point.groupBy !== null &&
        point.groupBy !== false
      ) {
        const key =
          point[point.groupBy] === undefined ? point[k] : point[point.groupBy]

        if (!av[key]) av[key] = []

        k = key
      }

      av[k].push({ ...point })

      return av
    },
    {
      // Items which are not grouped or disabled to group will be stored into title and render them without any title
      title: []
    }
  )
}

function calcPostion(x, y, width, height) {
  const w2 = width / 2
  const gap = 5

  const isOnTheLeftArea = x < w2

  return {
    coords: {
      x: isOnTheLeftArea ? 0 : x,
      y: y - 12
    },
    containerSize: {
      widthStartsAt: isOnTheLeftArea ? x + gap : 0,
      width: isOnTheLeftArea ? width - x - gap : width - (width - x) - gap,
      height: height //+ 12
    }
  }
}

function SingleItemTemplate({ data, isTranslate }: { data: TooltipDataModel, isTranslate: boolean }) {
  return (
    <div className="t-chart-tooltip-content-item">
      <p>
        {data.color !== null && (
          <i className="icon" style={{ background: data.color }}></i>
        )}
        <span className="label">{data?.label?.props?.children ? <TranslateComponent>{data.label.props.children}</TranslateComponent> : data.label !== null && <TranslateComponent>{data.label}</TranslateComponent>}</span>{' '}
        <span className="value">{data.value}</span>
      </p>
    </div>
  )
}

function WithTitleTemplate({ title, points, isTranslate }) {
  return (
    <>
      <div className="t-chart-tooltip-content-item title">
        {(title !== null || title !== undefined || title !== false) && (
          <p>
            <span className="label">{translate(title)}</span>
          </p>
        )}
      </div>
      {points.map((d, i) => (
        <SingleItemTemplate data={d} key={i} isTranslate={isTranslate} />
      ))}
    </>
  )
}
