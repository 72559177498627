import * as Yup from 'yup'
import i18next from 'i18next'
import moment from 'moment'

export const MAX_NAME_LENGTH = 80
export const MAX_DESC_LENGTH = 250

export const OverviewSchema = ({ initialStartDate, initialEndDate } = {}) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(i18next.t('errors:TheNameIsRequired'))
      .max(
        MAX_NAME_LENGTH,
        i18next.t('errors:maxAllowedFieldLength', {
          field: i18next.t('Name'),
          number: MAX_NAME_LENGTH
        })
      ),
    description: Yup.string().max(
      MAX_DESC_LENGTH,
      i18next.t('errors:maxAllowedFieldLength', {
        field: i18next.t('Description'),
        number: MAX_DESC_LENGTH
      })
    ),
    equipmentDetails: Yup.object().required(
      i18next.t('Equipment Id is required')
    ),
    startPeriod: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/,
        'Start date must be in MM-DD-YYYY format'
      )
      .test({
        name: 'startBeforeEnd',
        exclusive: true,
        message: 'Start date must be before end date',
        test: function (startPeriod) {
          const endPeriod = this.parent.endPeriod
          if (endPeriod && moment(endPeriod, 'MM-DD-YYYY', true).isValid()) {
            if (
              moment(startPeriod, 'MM-DD-YYYY').isAfter(
                moment(endPeriod, 'MM-DD-YYYY')
              )
            ) {
              return false
            }
          }
          return true
        }
      }),

    endPeriod: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/,
        'End date must be in MM-DD-YYYY format'
      )
      .test({
        name: 'endAfterStart',
        exclusive: true,
        message: 'End date must be after start date',
        test: function (endPeriod) {
          const startPeriod = this.parent.startPeriod
          if (
            startPeriod &&
            moment(startPeriod, 'MM-DD-YYYY', true).isValid()
          ) {
            if (
              moment(endPeriod, 'MM-DD-YYYY').isBefore(
                moment(startPeriod, 'MM-DD-YYYY')
              )
            ) {
              return false
            }
          }
          return true
        }
      })
  })
}
