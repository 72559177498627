import styled from 'styled-components'

// ask if gap and padding based on screen width or maxWidth of container element
export const Container = styled.section`
  margin: 0;
  background: ${(props) => props.background && props.background};
  display: grid;
  overflow-y: ${(props) => props.overflow && props.overflow};
  height: ${(props) => props.height && props.height};
  ${(props) => props.placeItems && `place-items: ${props.placeItems};`}
  ${(props) =>
    props.width < 599 &&
    `width: calc(100%);
      max-width: ${props.maxWidth ? `calc(${props.maxWidth})` : 'none'};
      grid-template-columns: repeat(${
        props.sColumns ? props.sColumns : 4
      }, 1fr);
      padding: ${props.padding ? `calc(${props.padding})` : '15px 0px'};
      column-gap: 15px;
      row-gap: 15px;
    `}
    ${(props) =>
    props.width > 599 &&
    props.width < 840 &&
    `width: calc(100%);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth})` : 'none'};
        grid-template-columns: repeat(${
          props.mColumns ? props.mColumns : 8
        }, 1fr);
        padding: ${props.padding ? `calc(${props.padding})` : '20px 0px'};
        column-gap: 20px;
        row-gap: 20px;
        `}
    ${(props) =>
    props.width > 839 &&
    props.width < 1220 &&
    `width: calc(100%);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth})` : 'none'};
        grid-template-columns: repeat(${
          props.lColumns ? props.lColumns : 12
        }, 1fr);
        padding: ${props.padding ? `calc(${props.padding})` : '20px 0px'};
        column-gap: 20px;
        row-gap: 20px;
        `}
    ${(props) =>
    props.width > 1219 &&
    `width: calc(100%);
        max-width: ${props.maxWidth ? `calc(${props.maxWidth})` : 'none'};
        padding: ${props.padding ? `calc(${props.padding})` : '30px 0px'};
        column-gap: 30px;
        row-gap: 30px;
        grid-template-columns: repeat(${
          props.xlColumns ? props.xlColumns : 12
        }, 1fr);
        `}
`

export const StyledContainer = styled.div`
  padding: 20px 20px 0;
`

export const Content = styled.article`
  border: ${(props) => (props.border ? props.border : '1px solid lightgray')};
  border-radius: ${(props) => (props.radius ? props.radius : '4px')};
  margin-right: ${(props) => (props.mRight ? `${props.mRight}px` : '0')};
  margin-left: ${(props) => (props.mLeft ? `${props.mLeft}px` : '0')};
  padding: ${(props) => props.padding && props.padding};
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) => props.placeSelf && `place-self: ${props.placeSelf};`}
  ${(props) => props.position && `position: ${props.position};`}
  ${(props) =>
    props.showDisplayGrid &&
    `
    display: grid;
    justify-items: end;

    `}

  background: ${(props) => props.background && props.background};
  ${(props) =>
    props.width < 599 &&
    `
            grid-column: auto / span ${props.sColumn ? props.sColumn : 1};
            grid-row: auto / span ${props.sRow ? props.sRow : 1};
        `}
  ${(props) =>
    props.width > 599 &&
    props.width < 1220 &&
    `
            grid-column: auto / span ${props.mColumn ? props.mColumn : 1};
            grid-row: auto / span ${props.mRow ? props.mRow : 1};
        `}
    ${(props) =>
    props.width > 839 &&
    props.width < 1220 &&
    `
            grid-column: auto / span ${props.lColumn ? props.lColumn : 1};
            grid-row: auto / span ${props.lRow ? props.lRow : 1};
        `}
    ${(props) =>
    props.width > 1219 &&
    `
            margin-top:${props.marginTop ? props.marginTop : 'none'};

            grid-column: auto / span ${props.xlColumn ? props.xlColumn : 1};
            grid-row: auto / span ${props.xlRow ? props.xlRow : 1};
        `}
    ${(props) =>
      props.gridArea &&
      `
        grid-area: ${props.rowStart} / ${props.colStart} / ${props.rowEnd} / ${props.colEnd};
      `}
`

export const Block = styled.div`
  width: ${(props) => (props.width ? props.width : 'auto')};
  flex-direction: ${(props) => props.flexDirection && props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap && props.flexWrap};
  flex: ${(props) => props.flex && props.flex};
  height: ${(props) => props.height && props.height};
  min-width: ${(props) => props.minWidth && props.minWidth};
  min-height: ${(props) => props.minHeight && props.minHeight};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  max-height: ${(props) => props.maxHeight && props.maxHeight};
  display: ${(props) => (props.display ? props.display : 'block')};
  padding: ${(props) => props.padding && props.padding};
  background: ${(props) => props.background && props.background};
  border-radius: ${(props) => props.radius && props.radius};
  border: ${(props) => props.border && props.border};
  border-bottom: ${(props) => props.borderBottom && props.borderBottom};
  border-top: ${(props) => props.borderTop && props.borderTop};
  border-right: ${(props) => props.borderRight && props.borderRight};
  border-left: ${(props) => props.borderLeft && props.borderLeft};
  margin: ${(props) => props.margin && props.margin};
  position: ${(props) => (props.position ? props.position : 'relative')};
  z-index: ${(props) => (props.z ? props.z : 'auto')};
  top: ${(props) => props.top && props.top};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};
  left: ${(props) => props.left && props.left};
  cursor: ${(props) => props.cursor && props.cursor};
  grid-template-columns: ${(props) => props.columns && props.columns};
  column-gap: ${(props) => props.columnGap && props.columnGap};
  row-gap: ${(props) => props.rowGap && props.rowGap};
  align-items: ${(props) => props.align && props.align};
  justify-content: ${(props) => props.justify && props.justify};
  flex-flow: ${(props) => props.flexFlow && props.flexFlow};
  overflow-y: ${(props) => props.overflow && props.overflow};
  text-align: ${(props) => props.textAlign && props.textAlign};
  box-sizing: ${(props) => (props.boxSizing ? props.textAlign : 'border-box')};
`

// confirmation text for modal content description

export const ConfirmationText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
`
