import {
  TabItem as AmplifyTabItem,
  BaseTabItemProps
} from '@aws-amplify/ui-react'
import styles from './tabs.module.scss'

export const TabItem = (props: typeof TabItem) => {
  return (
    <AmplifyTabItem
      className={styles.denaliTabItem}
      {...props}
    ></AmplifyTabItem>
  )
}
