import { Loader } from '@aws-amplify/ui-react'
import styles from 'src/denali-components/ImageGallery/image-gallery.module.scss'

export const ImageGallerySpinner = () => {
  return (
    <div className={styles.spinnerWrapper}>
      <Loader className={styles.imageGallerySpinner} />
    </div>
  )
}
