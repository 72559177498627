import moment from "moment"
//import { registerLocale, setDefaultLocale } from "react-datepicker"

const LangugeShortForms = {
    "fr-CA": 'fr-ca',
    "pt-PT": 'pt',
    "es-MX": 'es-mx',
    "zh-TW": 'zh-tw',
    "zh": 'zh-cn',
    "no": 'nb'
}

export const setMomentLocale = async (selectedLanguage) => {
    const l_selectedLanguage = selectedLanguage ? selectedLanguage : "en";
    //set userPreferred Language in intial Pageload
    if (l_selectedLanguage === "en") {
        moment.locale(l_selectedLanguage)
    } else {
        const lang = LangugeShortForms[l_selectedLanguage] ? LangugeShortForms[l_selectedLanguage] : l_selectedLanguage
        await import(`moment/locale/${lang}`)
        //const selectedDateLocale = await import(`date-fns/locale/${lang}`)
        //registerLocale(`${lang}`, selectedDateLocale.default)
        //setDefaultLocale(`${lang}`)
        moment.locale(lang)
    }
}

export const getMoment = () => moment;