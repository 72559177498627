import { Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'

// Denali imports
import { getImpactData } from 'src/denali-components/Form/helpers'

// Project imports
import { TranslateComponent } from 'src/common/translations'

// Local imports
import styles from './card-impact.module.scss'
import { CardImpactProps } from './types'

export const CardImpact = ({ kpi, count }: CardImpactProps) => {
  const formattedType = useMemo(() => {
    return formatType(kpi.name)
  }, [kpi.name])

  const impactData = useMemo(() => {
    return getImpactData(kpi.name)
  }, [kpi.name])

  const formattedImpact = useMemo(() => {
    if (kpi.priority === 'specificValue') {
      return `$${kpi.savings.toString()} ${formatTimePeriod(kpi.timePeriod)}`
    }

    return kpi.priority
  }, [kpi.priority, kpi.savings])

  return (
    <div
      className={styles.cardImpact}
      data-impact={kpi.priority}
      data-count={count >= 5 ? 'multiRow' : ''}
    >
      {impactData.icon !== null && (
        <Flex
          className={styles.icon}
          justifyContent="center"
          alignItems="center"
        >
          <FontAwesomeIcon icon={impactData.icon} size="xl" />
        </Flex>
      )}
      <span className={styles.impact}>
        <TranslateComponent>
          {formattedImpact.charAt(0).toUpperCase() + formattedImpact.slice(1)}
        </TranslateComponent>
      </span>
      <span className={styles.type}>
        <TranslateComponent>{`${formattedType} Impact`}</TranslateComponent>
      </span>
    </div>
  )
}

const formatType = (type) => {
  if (type === 'energyUsage') {
    return 'Energy'
  }

  return type.charAt(0).toUpperCase() + type.slice(1)
}

const formatTimePeriod = (timePeriod: string): string => {
  return `${timePeriod.charAt(0).toUpperCase()}${timePeriod
    .slice(1)
    .replace('-', ' ')}`
}
