import { InlineBuildingSelectorContext } from './hooks/use-inline-building-selector-context'
import { InlineBuildingSelectorContextProviderProps } from './types'

export const InlineBuildingSelectorContextProvider = ({
  children,
  buildings,
  iconColors,
  searchBuildings,
  setSearchTerm,
  buildingsGroup,
  activeScroll,
  setActiveScroll,
  resetSearch
}: InlineBuildingSelectorContextProviderProps) => {
  const contextData = {
    buildings,
    iconColors,
    searchBuildings,
    setSearchTerm,
    buildingsGroup,
    activeScroll,
    setActiveScroll,
    resetSearch
  }

  return (
    <InlineBuildingSelectorContext.Provider value={contextData}>
      {children}
    </InlineBuildingSelectorContext.Provider>
  )
}
