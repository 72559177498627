import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Modal from 'src/components/legacy/components/modal/modal'
import FindingsTablePicker from 'src/pages/opportunities/OpportunityDetail/findings-table-picker'
import { FileUploadType, getOriginal } from 'src/pages/opportunities/helpers'
import CustomPicker from 'src/components/legacy/components/custom-picker'
import { useQuery } from 'src/hooks/APIHooks'
import Icon from 'src/denali-ui/components/Icon'
import { listIssueFindingsOrganization } from 'src/pages/issuesFindings/graphql'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import { useAttachment } from 'src/hooks/attachment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useTranslation } from 'react-i18next'
import translate from 'src/common/translations'

export function FindingsDialog({
  organizationId,
  handleClose,
  selectedFindings,
  onSubmit,
  handleLoading,
  isLoading,
  maxSize,
  handleDialogModal
}) {
  const [selectedItems, setSelectedItems] = useState([])
  const [findings, setFindings] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [responseTime, setResponseTime] = useState(null)
  const { downloadFileURL } = useAttachment()
  const [t] = useTranslation()
  const {
    data: findingData,
    refetch: refetchFindingsList,
    responseTime: findingResponseTime
  } = useQuery({
    query: listIssueFindingsOrganization,
    disableInitialLoad: true,
    variables: { accountId: organizationId },
    dataPath: 'data.searchIssues.items',
    errorPolicy: 'ignore'
  })

  const priority = {
    "Not Prioritized": translate("Not Prioritized"),
    "URGENT!": translate("URGENT!"),
    "High": translate("High"),
    "Medium": translate("Medium"),
    "Low": translate("Low")
  }
  const findingStatuses = {
    "recommended": translate("Recommended"),
    "dismissed": translate("Dismissed"),
    "inProgress": translate("In Progress"),
    "complete": translate("Complete"),
    "remoteResolved": translate("Remote Resolved"),
    "onSiteResolved": translate("On-Site Resolved"),
    "validation": translate("Validation"),
    "hold": translate("Hold"),
    "other": translate("Other"),
    "archive": translate("Archive")
  }
  const nextStepsFinding = {
    "buildingPersonnelReview": translate("Building Personnel Review"),
    "RSSReview": translate("Remote JDI"),
    "remoteJDI": translate("Remote JDI"),
    "BASTechOnSite": translate("BAS Tech On-Site"),
    "mechTechOnSide": translate("Mech Tech On-Site"),
    "buildingPersonnelToHandle": translate("Building Personnel to Handle"),
    "archive": translate("Archive"),
    "complete": translate("Complete")
  }
  const getInfo = (data) => {
    const fieldValue = `${priority[data?.priority] || ''} | ${
      findingStatuses[data?.status] || ''} | ${nextStepsFinding[data?.nextStep] || ''}`.trim()
    return (
      <ConditionalTooltip
        type={ConditionalTooltip.TYPE.ICON}
        content={fieldValue}
      >
        <Icon name="infocircle2" color="#bfbfbf" />
      </ConditionalTooltip>
    )
  }

  //Retrieve original url
  const getImageOriginalURL = useCallback(async (images) => {
    const findingImage = images?.filter((i) => i?.category === FileUploadType.ADD_IMAGES)?.[0] ?? {}
    if (findingImage) {
      const img = await getOriginal(findingImage, downloadFileURL)
      if (img.original) {
        return img.original
      }
    }
  }, [])

  useEffect(() => {
    if (findingData) {
      setFindings(
        !findingData
          ? []
          : findingData?.map((f) => ({
            findingId: f?.id,
            name: f?.title,
            images: f?.attachments?.items,
            aboutFinding: getAboutFinding(f?.comment),
            descriptionIcon: getInfo(f),
            checkboxCheckedEnabled: selectedItems
              ?.map((i) => i.key)
              ?.includes(f?.id)
          })))
      setResponseTime(findingResponseTime)
    }
  }, [findingData])

  const getAboutFinding = (value) => {
    try {
      return JSON.parse(value)?.hasOwnProperty('blocks')
      ? JSON.parse(value)
          ?.blocks?.map((m) => m?.text)
          ?.join(' ') ?? ''
      : JSON.parse(value)
    } catch(error) {
      return ''
    }
  }

  useEffect(() => {
    if (organizationId) {
      refetchFindingsList()
    }
  }, [organizationId])

  useEffect(() => {
    const selectedItems = selectedFindings.map(
      ({ findingId: key, name: value }) => ({ key, value })
    )
    setSelectedItems(selectedItems)
  }, [])

  const prepareFindingsToSubmit = async (selectedFindings) => {
    setShowSpinner(true)

    const foundFindings = findings || []

    const filteredFindings = foundFindings.filter(({ findingId }) =>
    selectedFindings.some(({ key }) => key === findingId))

    const findingsWithImages = await Promise.all(filteredFindings.map(async (f) => ({
      ...f,
      original: await getImageOriginalURL(f?.images)
    })))

    setShowSpinner(false)
    handleDialogModal(false)

    return findingsWithImages
  }

  return (
    <Modal
      heading="Add Finding to Opportunity"
      buttons={[
        {
          type: 'confirm',
          text: 'Save',
          handleClick: async () => onSubmit(await prepareFindingsToSubmit(selectedItems)),
          disabled: selectedItems.length > maxSize || showSpinner
        },
        {
          type: 'cancel',
          text: 'Cancel',
          handleClick: handleClose,
          disabled: showSpinner
        }
      ]}
      gray={false}
      handleClose={handleClose}
      loading={isLoading}
      errorMessage={`You are limited to ${maxSize} findings per opportunity`}
      formIsErrored={selectedItems.length > maxSize}
    >
      <div>
        {showSpinner
          ? <Spinner />
          : <CustomPicker
            selectedItems={selectedItems}
            onChange={(selectedItems) => {
              setFindings(
                findings?.map((row) => ({
                  ...row,
                  checkboxCheckedEnabled: selectedItems
                    ?.map((i) => i.key)
                    ?.includes(row?.findingId)
                }))
              )
              setSelectedItems(selectedItems)
            }}
            handleLoading={handleLoading}
            limit={maxSize}
          >
            <FindingsTablePicker
              findings={findings}
              responseTime={responseTime}
              organizationId={organizationId}
            />
          </CustomPicker>
        }
      </div>
    </Modal>
  )
}

FindingsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedFindings: PropTypes.array,
  onSubmit: PropTypes.func,
  handleLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  findings: PropTypes.shape({
    Findings: PropTypes.array,
    loading: PropTypes.bool
  }).isRequired,
  maxSize: PropTypes.number
}

FindingsDialog.defaultProps = {
  onSubmit: () => {},
  handleLoading: () => {},
  isLoading: false,
  maxSize: 6
}

export default FindingsDialog
