import Spinner from '../../components/legacy/components/spinner/spinner'
import EngeryHeatMap from './EngeryHeatMap'
import { HEAT_MAP_TOOLTIP_TITLE } from './constants'

const ElectricalDemandHeatChart = ({
  chartData = { data: [], timeStamps: [] },
  unit,
  loadingChartData,
  selectedEquipmentId,
  toolTip
}) => {
  return loadingChartData || !chartData || selectedEquipmentId === null ? (
    <Spinner />
  ) : (
    <EngeryHeatMap
      testName="electrical-demand-heat-chart"
      chartData={chartData}
      TOOLTIP_TITLE={toolTip}
      unit={unit}
    />
  )
}
export default ElectricalDemandHeatChart
