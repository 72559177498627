import moment from 'moment'
import * as yup from 'yup'
import { TranslateComponent } from 'src/common/translations'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    name: yup.string().test((value, { createError }) => {
      if (!value || value === "") {
        return createError({
          message: `Please enter a name.`,
        })
      }
      return true
    }),
    utilityId: yup.string().required(REQUIRED).test("utility-include-none", "Utility Company is Required", (value) => value !== "none"),
    demandRatchet: yup.object().test((value, { createError }) => {
      const activeRatchets = Object.keys(value).filter(e => value[e].active)
      if (!activeRatchets.every((e) => value[e].value && value[e].type)) {
        return createError({
          message: <TranslateComponent>Please enter all fields for enabled demand ratchets.</TranslateComponent>,
        })
      }
      if (!activeRatchets.every((e) => value[e].value <= 100)) {
        return createError({
          message: <TranslateComponent>A demand ratchet % can be a max of 100%.</TranslateComponent>,
        })
      }
      if (!activeRatchets.every((e) => /^\d{1,3}(\.\d{1,5})?$/gm.test(value[e].value))) {
        return createError({
          message: <TranslateComponent>A max of 5 decimal places may be used.</TranslateComponent>,
        })
      }
      return true
    }),
    seasons: yup.object().test((value) => {
      if (value["noSeasonalVariation"].active){
        return true
      } else {
        let pass = true
        const activeVals = Object.keys(value).filter(e => value[e].active)
        const vals = {}
        activeVals.forEach((e, k) => {
          const startTime = value[e].startDate !== "Last Day" ? moment(`${value[e].startMonth} ${value[e].startDate}`,'MMM DD') : moment(`${value[e].startMonth}`,'MMM').endOf("month")
          const endTime = value[e].endDate !== "Last Day" ? moment(`${value[e].endMonth} ${value[e].endDate}`,'MMM DD') : moment(`${value[e].endMonth}`,'MMM').endOf("month")

          vals[e] = {
            startTime,
            endTime,
            days: startTime.isBefore(endTime) ? endTime.diff(startTime, 'days') : endTime.clone().add(1, "year").diff(startTime, 'days')
          }

          vals[e].days = vals[e].startTime.isBefore(endTime) ? vals[e].endTime.diff(vals[e].startTime, 'days') : vals[e].endTime.clone().add(1, "year").diff(vals[e].startTime, 'days')
        })
        const valKeys = Object.keys(vals)
        valKeys.forEach((e, k) => {
          for (let i = 0; i < valKeys.length; i++) {
            if (i !== k) {
              if (vals[e].startTime.isBetween(vals[valKeys[i]].startTime, vals[valKeys[i]].endTime) || vals[e].endTime.isBetween(vals[valKeys[i]].startTime, vals[valKeys[i]].endTime)) {
                pass = false
              }
            }
          }
        })

        const sumOfDates = valKeys.reduce((accum, curr) => {
          accum += vals[curr].days
          return accum
        }, 0)
        if (!(sumOfDates >= 365 - activeVals.length && sumOfDates <= 366 - activeVals.length)) {
          pass = false
        }
        return pass
      }
    }),
  })

export default schema
