import { Button } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate, { TranslateComponent } from 'src/common/translations'

// Denali components
import { faBooks } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'

export const BaselineLibraryContainer = ({ onCreate, children }) => {
  return (
    <Page
      title={translate('Baseline Library')}
      icon={<FontAwesomeIcon icon={faBooks} />}
      iconColor={IconColor.orange}
      testid="baseline library page"
      actions={
        <Button onClick={onCreate} data-testid={'create-new-baseline-button'}>
          <TranslateComponent>Create Baseline</TranslateComponent>
        </Button>
      }
    >
      {children}
    </Page>
  )
}
