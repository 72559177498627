import { useState } from 'react'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import styles from 'src/denali-components/ImageGallery/image-gallery.module.scss'
import { ImageGalleryImage } from 'src/denali-components/ImageGallery/ImageGalleryImage'
import { ImageGalleryMissing } from 'src/denali-components/ImageGallery/ImageGalleryMissing'
import { ImageGallerySpinner } from 'src/denali-components/ImageGallery/ImageGallerySpinner'
import { DetailImageDescription } from 'src/denali-pages/Opportunities/OpportunityDetail/DetailImageDescription'

interface ImageGalleryItemProps {
  item: any
  isLoadingImage?: boolean
  renderDescription?: any
}

export const ImageGalleryItem = ({
  item,
  isLoadingImage,
  renderDescription
}: ImageGalleryItemProps) => {
  const [imageError, setImageError] = useState(false)

  const handleError = () => {
    setImageError(true)
  }

  return (
    <>
      {isLoadingImage && !renderDescription ? (
        <ImageGallerySpinner />
      ) : (
        <div
          className={styles.imageGalleryItemWrapper}
          data-testid="image-gallery"
        >
          {!imageError && item.original ? (
            <ImageGalleryImage item={item} handleError={handleError} />
          ) : (
            <ImageGalleryMissing />
          )}
          {renderDescription
            ? renderDescription
            : (item.description || item.originalTitle) && (
                <DetailImageDescription
                  title={item.originalTitle}
                  caption={item.caption}
                />
              )}
        </div>
      )}
    </>
  )
}
