import { useEffect, useRef } from 'react'
import moment from 'moment'

import './style.scss'
import { HEIGHT, MARGIN } from './constants'
import { isInvalidEl } from './helpers'
import { initalizeTrackSettings } from './trackSettings'
import { useSelector } from 'react-redux'

type TrackProps = {
  startTime: number
  endTime: number
  svgWidth: number
  children: React.ReactNode
  brush: React.ReactNode
}

export function Track({
  brush,
  children = null,
  endTime,
  startTime,
  svgWidth
}: TrackProps) {
  const axisTickTopRef = useRef(null)
  const axisTickBottomRef = useRef(null)
  const axisLabelRef = useRef(null)
  const selectedLanguage = useSelector((state : any) => state?.appData?.selectedLanguage)
  //----------------------------------- On Comp load, initialize track and set axis label -----------------------------------

  useEffect(() => {
    if (
      (isInvalidEl(axisTickTopRef) ||
        isInvalidEl(axisTickBottomRef) ||
        isInvalidEl(axisLabelRef),
      !startTime || !endTime)
    ) {
      return () => null
    }

    const init = initalizeTrackSettings(
      axisTickTopRef,
      axisTickBottomRef,
      axisLabelRef
    )

    if (init === null) {
      return () => null
    }

    const daysRangeBetween =
      moment(endTime).diff(moment(startTime), 'days') >= 2 ? 2 : 1

    init.setTrackSize(startTime, endTime, svgWidth)
    init.updateAxisLabel(daysRangeBetween, startTime, endTime, selectedLanguage)
  }, [
    axisTickTopRef.current,
    axisTickBottomRef.current,
    axisLabelRef.current,
    startTime,
    endTime,
    svgWidth,
    selectedLanguage
  ])

  return (
    <g transform={`translate(${MARGIN.left}, 1)`}>
      {/* Track gradients */}
      <defs>
        <linearGradient x1="0%" y1="0%" x2="0" y2="100%" id="barGradient">
          <stop className="stop1" offset="0%"></stop>
          <stop className="stop2" offset="100%"></stop>
        </linearGradient>
      </defs>
      <defs>
        <linearGradient x1="0%" y1="0%" x2="0" y2="100%" id="buttonGradient">
          <stop className="stop1" offset="0%"></stop>
          <stop className="stop2" offset="100%"></stop>
        </linearGradient>
      </defs>
      <rect
        id="rangeBar"
        fill="url(#barGradient)"
        className="range-bar"
        height={HEIGHT}
        width={svgWidth}
      ></rect>
      {children}
      <g className="grid axis-tick-top" ref={axisTickTopRef}></g>
      <g className="grid axis-tick-bottom" ref={axisTickBottomRef}></g>
      <g className="axis axis-label" ref={axisLabelRef}></g>
      {brush}
    </g>
  )
}
