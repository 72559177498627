import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// import { SelectrixWrapper } from "react/shared/selectrix-wrapper/selectrix-wrapper"
import { commonInput, getLocationOption, EVALUATION_TYPES, REASONS, BUSINESS_STREAM, PAYMENT_TYPES } from "./common"
// import { dateInput, ACCEPTED_FILE_TYPES } from "react/screens/contracts-and-offerings/offering-dialogs/common.js"
import Select from 'src/components/legacy/components/select/select'
// import { GET_USER_LOCATIONS_WITH_ADDRESS, GET_USERS_BY_LOCATIONS } from "graphql/user"
// import { useQuery } from "@apollo/client"
import CheckBox from 'src/denali-ui/components/Checkbox'
// import MultiFileUploaderWithTable from "react/shared/multi-file-upload-with-table"
// import { ACTIONS } from "react/shared/multi-file-upload-with-table/common"
import _get from "lodash/get"
import { translate } from 'src/common/translations'

export function OpticsEvaluationDialog({
	handleSubmitForm,
	errors,
	values,
	values: { evaluationType, reason, businessStream, paymentType, neededByDate, serviceCallNumber, serviceContractNumber, purchaseOrderNumber, agreement, locations, settings },
	locationInfo: { locationName, address, locationId: currentLocationId, organizationId: currentLocationOrganizationId },
	locationInfo,
	currentUser,
	setFieldValue,
	setFieldTouched,
	touched,
	modalElement
}) {
	const [t] = useTranslation()

	const optionsTranslated = {
		evaluationType: translate("Evaluation type"),
		selectOne: translate("— Select One —"),
		evaluationTypes: {
			"energyCheckUp": translate("Energy Check Up"),
			"opticsEnergyVisualizer": translate("Optics Energy Visualizer"),
			"opticsBMSVisualizer": translate("Optics BMS Visualizer"),
			"texasEnergyCoaching": translate("Texas Energy Coaching"),
			"GNPOpticsToEP": translate("GNP Optics to EP"),
			"mid-AmericaEnergyCoaching": translate("Mid-America Energy Coaching"),
			"nyserdaRemQa": translate("NYSERDA REM QA"),
			"nyserdaRemContract": translate("NYSERDA REM Contract"),
			"customOrderRequest": translate("Custom Order Request")
		},
		reasons: {
			"existingServiceContract": translate("Existing service contract"),
			"newCustomerAcquisition": translate("New customer acquisition"),
			"projectDevelopment/ECMIdentification": translate("Project development/ECM identification"),
			"M&VForPACTJob": translate("M&V for PACT job"),
			"M&VForNon-performanceGuaranteeJob": translate("M&V for non-performance guarantee job"),
			"intelligentServicesProofOfConcept": translate("Intelligent services proof of concept")
		},
		reason: translate("Reason"),
		businessStream: translate("Business stream"),
		paymentType: translate("Payment Type"),
		paymentTypes: {
			"ServiceCallNumber": translate("Service Call Number"),
			"ServiceContractNumber": translate("Service Contract Number"),
			"PurchaseOrderNumber": translate("Purchase Order Number")
		},
		neededBy: translate("Needed By"),
		opticDialogTipText: translate("Please enter the appropriate Service Call Number here. Your request for Intelligence Services Offering activation will not be approved until a valid Service Account Number that includes Intelligence services has been  transitioned to fulfillment and the appropriate customer account number is also in Trane Connect."),
		attachPurchaseOrder: translate("Attach Purchase Order"),
		independentOfficesOnly: translate("Independent Offices Only"),
		for: translate("For"),
		at: translate("at"),
		"in": translate("in"),
		evaluateOtherBuildingsOnThisContract: translate("Evaluate other buildings on this contract"),
		primaryTraneContact: translate("Primary Trane Contact"),
		checkboxLabel: translate("I understand that by activating this offering on these buildings, the sales office associated with these building(s) will be invoiced."),
	}

	const { loading: locationsLoading, data: { User: { locations: buildings = [] } = {} } = {} } = useQuery(GET_USER_LOCATIONS_WITH_ADDRESS)

	const { loading: loadingUsers, data: usersData } = useQuery(GET_USERS_BY_LOCATIONS, {
		variables: {
			locationIds: [Number(currentLocationId)],
		}
	})

	const usersForSelector = useMemo(() => {
		const users = _get(usersData, "LocationsUsers", [])
		return users.map(({ username, firstName, lastName }) => ({ key: username, label: `${firstName}, ${lastName}, ${username}` }))
	}, [usersData])

	const attachments = _get(settings, "attachments", []).map(attachment => ({
		...attachment,
		isEditable: true
	}))

	const buildingsOptionList = useMemo(() => buildings
		.filter(({ locationId, organizationId }) => locationId !== currentLocationId && Number(currentLocationOrganizationId) === organizationId)
		.map(getLocationOption), [buildings])

	const defaultBuildings = locations
		.filter(({ key, locationId }) => locationId ? locationId !== currentLocationId : key !== currentLocationId)
		.map(({ key, locationId }) => locationId || key)

	useEffect(() => setFieldValue("primaryContact", {
		username: currentUser,
	}), [currentUser])

	useEffect(() => {
		if (!locations.length) {
			setFieldValue("locations", [getLocationOption(locationInfo)])
		}
	}, [locationInfo, setFieldValue])

	const setPrimaryContact = ({ key, label }) => {
		const [firstName, lastName] = label.split(", ")
		setFieldValue("primaryContact", {
			username: key,
			firstName,
			lastName,
		})
	}

	return (
		<form onSubmit={handleSubmitForm}>
				<div className="field-group">
					<fieldset className={`inline-3 ${Boolean(errors.evaluationType && touched.evaluationType) && "invalid"}`}>
						<legend>{optionsTranslated.evaluationType}*</legend>
						<Select
							placeholder={optionsTranslated.selectOne}
							includePlaceholderOption={false}
							selectedItem={optionsTranslated.evaluationTypes[evaluationType]}
							onChange={item => {
								setFieldTouched("evaluationType")
								setFieldValue("evaluationType", item)
							}}
							options={EVALUATION_TYPES}
						/>
						{Boolean(errors.evaluationType && touched.evaluationType)
						&& <p className="error">{errors.evaluationType}</p>}
					</fieldset>
					<fieldset className={`inline-3 ${Boolean(errors.reason && touched.reason) && "invalid"}`}>
						<legend>{optionsTranslated.reason}*</legend>
						<Select
							placeholder={optionsTranslated.selectOne}
							includePlaceholderOption={false}
							selectedItem={optionsTranslated.reasons[reason]}
							onChange={item => {
								setFieldTouched("reason")
								setFieldValue("reason", item)
							}}
							options={REASONS}
						/>
						{Boolean(errors.reason && touched.reason)
						&& <p className="error">{errors.reason}</p>}
					</fieldset>
					<fieldset className={`inline-3 ${Boolean(errors.businessStream && touched.businessStream) && "invalid"}`}>
						<legend>{optionsTranslated.businessStream}*</legend>
						{/* <SelectrixWrapper
							className="selectrix-wrapper selectrix-wrapper--type-autocomplete"
							searchable={false}
							defaultValue={businessStream}
							options={BUSINESS_STREAM}
							onChange={item => {
								setFieldTouched("businessStream")
								setFieldValue("businessStream", item.key)
							}}
						/> */}
						{Boolean(errors.businessStream && touched.businessStream)
						&& <p className="error">{errors.businessStream}</p>}
					</fieldset>
				</div>
				<div className="field-group">
					<fieldset className="inline-3">
						<legend>{optionsTranslated.paymentType}*</legend>
						<Select
							selectedItem={optionsTranslated.paymentTypes[paymentType]}
							onChange={item => {
								setFieldTouched("paymentType")
								setFieldValue("paymentType", item)
							}}
							options={PAYMENT_TYPES}
						/>
						{Boolean(errors.paymentType && touched.paymentType)
						&& <p className="error">{errors.paymentType}</p>}
					</fieldset>
					<fieldset className={`inline-3 name ${Boolean((errors.serviceCallNumber && touched.serviceCallNumber) || Boolean(errors.serviceContractNumber && touched.serviceContractNumber) || Boolean(errors.purchaseOrderNumber && touched.purchaseOrderNumber)) && "invalid"}`}>
						{commonInput({
							className: "number",
							name: paymentType === "ServiceCallNumber"
								? "serviceCallNumber" : paymentType === "ServiceContractNumber"
									? "serviceContractNumber" : "purchaseOrderNumber",
							label: optionsTranslated.paymentTypes[paymentType],
							value: paymentType === "ServiceCallNumber"
								? serviceCallNumber : paymentType === "ServiceContractNumber"
									? serviceContractNumber : purchaseOrderNumber,
							touched: paymentType === "ServiceCallNumber"
								? touched.serviceCallNumber : paymentType === "ServiceContractNumber"
									? touched.serviceContractNumber : touched.purchaseOrderNumber,
							setFieldValue,
							setFieldTouched,
							errors
						})}
					</fieldset>
					<fieldset className={`inline-3 ${Boolean(errors.neededByDate && touched.neededByDate) && "invalid"}`}>
						<legend>{optionsTranslated.neededBy}*</legend>
						{dateInput({
							name: "neededByDate",
							value: neededByDate,
							touched: touched.neededByDate,
							setFieldValue,
							setFieldTouched,
							errors
						})}
					</fieldset>
				</div>
			{paymentType === "ServiceContractNumber" && <div className="text-container">
				<div className="inline-2 bp-ep-dialog-tip-text">
					{optionsTranslated.opticDialogTipText}
				</div>
			</div>}
			{paymentType === "PurchaseOrderNumber" && <fieldset>
				<legend>{optionsTranslated.attachPurchaseOrder} {optionsTranslated.independentOfficesOnly}</legend>
				{/* <MultiFileUploaderWithTable
					mode={ACTIONS.EDIT}
					attachments={attachments || []}
					onChangeAttachments={items => setFieldValue("settings.attachments", items)}
					fieldsToDisplay={["fileName", "fileSize", "fileDescription"]}
					tooltipRenderNode={modalElement}
					proposalFileTypes={ACCEPTED_FILE_TYPES}
				/> */}
			</fieldset>}
			<p className="location-details">
				{`${optionsTranslated.for} ${locationName} ${optionsTranslated.at} ${address.line1} ${optionsTranslated.in} ${address.city}, ${address.region}`}
			</p>
			<fieldset>
				{(Boolean(buildingsOptionList.length) || locationsLoading)
					&& <>
						<legend>{optionsTranslated.evaluateOtherBuildingsOnThisContract}</legend>
						{/* <SelectrixWrapper
							className="buildings selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select"
							placeholder={t("common:SelectOneOrMore")}
							searchable={true}
							searchBoxInside={true}
							defaultValue={defaultBuildings}
							options={buildingsOptionList}
							onChange={list => {
								setFieldTouched("locations")
								setFieldValue("locations", [...list, getLocationOption(locationInfo)]) // always add current location
							}}
							stayOpen={true}
							multiple={true}
							isLoading={locationsLoading}
							notFoundPrompt={t("common:NoResult")}
						/> */}
					</>
				}
			</fieldset>
			<fieldset className={`${Boolean(errors.primaryContact && touched.primaryContact) && "invalid"} trane-primary-contact`}>
				{(Boolean(usersForSelector.length) || loadingUsers)
				&& <>
					<legend>{optionsTranslated.primaryTraneContact}</legend>
					{/* <SelectrixWrapper
						className="primary-contact selectrix-wrapper selectrix-wrapper--type-autocomplete"
						placeholder={t("common:FindItem", { item: t("service-providers:PrimaryContact") })}
						inputPlaceholder={t("common:FindItem", { item: t("service-providers:PrimaryContact") })}
						searchable={true}
						searchIndex={false}
						searchBoxInside={true}
						options={usersForSelector}
						onChange={setPrimaryContact}
						isLoading={loadingUsers}
						defaultValue={_get(values, "primaryContact.username", false)}
					/> */}
					{Boolean(errors.primaryContact && touched.primaryContact)
					&& <p className="error primary-contact">{errors.primaryContact}</p>}
				</>
				}
			</fieldset>
			<CheckBox
				id="agreement"
				label={optionsTranslated.checkboxLabel}
				toggleCheck={() => {
					setFieldTouched("agreement")
					setFieldValue("agreement", !agreement)
				}}
				isChecked={agreement}
				{...errors.agreement && touched.agreement && { customClassName: "has-error" }}
			/>
		</form>
	)
}

OpticsEvaluationDialog.propTypes = {
	handleSubmitForm: PropTypes.func.isRequired,
	touched: PropTypes.object,
	setFieldTouched: PropTypes.func,
	setFieldValue: PropTypes.func.isRequired,
	errors: PropTypes.object,
	locationInfo: PropTypes.object,
	values: PropTypes.object
}
