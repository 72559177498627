// A component that renders a handful of controls for the energy intensity page.
import { Button, Flex } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Denali component imports
import {
  faCalendarDays,
  faChartSimple,
  faChartLineUp
} from 'src/denali-components/lib/pro-solid-svg-icons'

// Local imports
import styles from './plot-bar-chart-controls.module.scss'

// First we have, left aligned, date ranges (1M, 6M, YTD, 1Y, ALL) and the a calendar
// icon that opens a calendar popup to select a custom date range.

// Then we have a right aligned section of button for zoom in, zoom out and a 3 dot menu.
// Next to that we have two buttons for changing the chart type (bar, line).

type PlotBarChartControlsProps = {
  testName: string
  activeRange: string
  onUpdateActiveRange: (dateRange: string) => void
  rangesList: string[]
  onUpdateDateRanges: (dateRanges: string[]) => void
  chartViewType: string
  onChangeChartViewType: (chartView: string) => void
  onShowDatePickerDialog: () => void
}

const PlotBarChartControls = ({
  testName,
  onUpdateActiveRange,
  activeRange,
  rangesList,
  chartViewType,
  onChangeChartViewType,
  onShowDatePickerDialog
}: PlotBarChartControlsProps) => {
  const chartIsBarType = chartViewType === 'bar'

  return (
    <Flex
      direction="row"
      gap={16}
      justifyContent="space-between"
      alignItems="flex-start"
      data-testid={testName}
    >
      {/* Left aligned section */}
      <div className={styles.barChartControlsLeft}>
        {rangesList.map((range) => (
          <Button
            key={range}
            onClick={() => onUpdateActiveRange(range)}
            className={activeRange === range ? 'active' : ''}
            size="small"
            {...(activeRange !== range ? { variation: 'link' } : {})}
          >
            {range}
          </Button>
        ))}
        <Button
          size="small"
          onClick={onShowDatePickerDialog}
          {...(activeRange !== 'CUSTOM' ? { variation: 'link' } : {})}
        >
          <FontAwesomeIcon icon={faCalendarDays} />
        </Button>
      </div>
      {/* Right aligned section */}
      <Flex direction="row" gap={8} className={styles.barChartControlsRight}>
        <div className={styles.barChartControlsDisplayToggle}>
          <Button
            size="small"
            {...(chartIsBarType ? {} : { variation: 'link' })}
            onClick={() => onChangeChartViewType('bar')}
          >
            <FontAwesomeIcon size="xl" icon={faChartSimple} />
          </Button>
          <Button
            size="small"
            {...(chartIsBarType ? { variation: 'link' } : {})}
            onClick={() => onChangeChartViewType('line')}
          >
            <FontAwesomeIcon size="xl" icon={faChartLineUp} />
          </Button>
        </div>
      </Flex>
    </Flex>
  )
}

export default PlotBarChartControls
