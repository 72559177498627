import _get from 'lodash/get'

const CONFIGURATION = {
  featureToggles: {},
  termsAndConditionsCurrentVersion: 1,
  amplitudeKey: '8f482709c11b1fe3a521591a575109d4',
  // Enable for maintenance testing
  // maintenance: true,
  // maintenanceMessage: "Scheduled maintenance from xx/xx/xxxx x:xxpm - xx/xx/xxxx x:xxpm.",
  externalLinks: {
    ensembleServicesPrefix: 'https://',
    ensembleServicesDomain: '.ensemble-dev.net',
    ensembleServicesSuffix: '/#/sso?token=',
    ensembleServicesSSOSuffix: '/api/transitSecurityContext',
    tenantServices: 'http://demo.ensemble-dev.net/sso',
    gen3BaseUrl: 'https://staging.mybuilding.trane.com',
    authServiceUrl:
      process.env.LOCAL_GATEWAY === 'true' ? '/_/local-gateway' : '/_/gateway',
    workfrontUrl: 'https://traneus.my.workfront.com/login?nextURL=%2Fhome',
    arcadiaUrl: 'https://arc-connect.arcadia.com/tagvy5ua13QmRpIuJAjw',
  },
  msalConfig: {
    auth: {
      clientId: 'f3567b70-57cf-47e6-884a-35d207b941c2',
      authority:
        'https://corpextdev.b2clogin.com/corpextdev.onmicrosoft.com/B2C_1A_OIDC_USERINFO_DENALI',
      knownAuthorities: ['corpextdev.b2clogin.com'],
      redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  },
  tisEmail: 'tranetistest+techSupport.lohika@gmail.com',
  allowUserSettingAPI: true,
  openVPN: {
    origin: 'http://localhost:944',
    url: 'http://localhost:944/jsProxy.html',
    rpcURL: 'http://localhost:944/RPC2',
    timeout: 10000
  },
  weatherIconS3Url: 'https://d3k53p032ogl5t.cloudfront.net/Weather/Aeris_WxIcons_55x55'
}

export const getConfig = (config) => {
  const fileConfig = _get(CONFIGURATION, config)
  return typeof _get(window, 'config') !== 'undefined'
    ? _get(window, `config.${config}`) || fileConfig
    : fileConfig
}

export default CONFIGURATION


