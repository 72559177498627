import styles from './number-badge.module.scss'
import { NumberBadgeProps } from './types.d'

// A component that wraps another component and displays an icon in one of the
// four "corners" (Top right, bottom right, bottom left, top left)
export const NumberBadge = ({ number, children }: NumberBadgeProps) => {
  const isOpen = number > 0

  return (
    <div className={styles.badgeWrapper}>
      {isOpen && <div className={styles.badge}>{number.toString()}</div>}
      {children}
    </div>
  )
}
