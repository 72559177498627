import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Spinner from 'src/components/Spinner'// components
import Modal from 'src/components/legacy/components/modal/modal'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import RadioButton from 'src/denali-ui/components/Radio'
import { RadioGroup } from 'src/components'
import { StyledSpinner,StyledTree, DataSourceEditableView, LabelFormat, colorFromDataSourceType} from "./styles"

import StandardDialogs from 'src/components/legacy/components/standard-dialogs'

import { getDiscardChangesPromptConfig } from '../../helper'
import { NA, INTERVAL_OPTIONS, getPolarityOptions, DATA_SOURCE_TYPES } from '../constants'

import { Formik, Form } from 'formik'
import Select from 'src/components/legacy/components/select/select'
import _isEmpty from 'lodash/isEmpty'
import _uniqBy from 'lodash/uniqBy'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import { useQuery } from 'src/hooks/APIHooks'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import { LabelDiv } from '../style'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { getSearchParams } from 'src/common/helperFunctions.js'

import {
  SORT_TYPE,
  PROPERTY_VALUE,
  EMPTY
} from '../../../CreateEquipment/constants'

//graphQL
import {
  GET_AUTOMATION_SYSTEM_DETAILS,
  GET_PROPERTIES_BY_EQ_ID,
  SEARCH_PROPERTY_METADATA,
  GET_EQUIPMENT_PROPERTIES_BY_EQUIPMENT_ID,
  GET_NIAGARA_EQUIPMENT_LIST_WITH_DATA_SOURCE_FILTER,
} from '../../../graphql/queries'

const getPointColor = point => colorFromDataSourceType[point?.dataSourceType]
import translate, { TranslateComponent, fetchTranslate } from '../../../../../common/translations'
import { useSelector } from 'react-redux'
import { siUnitMapping, ConvertConstantToSI } from 'src/common/UOMConversions';
import { translateJSON } from '../../../../../redux/slicers/translationCombinedSlice'
import { useAppDispatch } from '../../../../../redux/store'

export default function DataMappingEditProperty({
  equipmentDetails,
  toggleDialog,
  propertyDetails,
  handleAlienProperty,
  selectedIndex,
  defaultdimensionGroup,
  uomSymbolPairs,
  UOMbyProfile
}) {
  const dispatch = useAppDispatch()
  const isNiagara = equipmentDetails?.device?.type === "NAX"
  const naxInitialValues = isNiagara
    ? {
      dataSourceObjectName: propertyDetails?.sourceEquipmentDataSourceName
        ? propertyDetails.sourceEquipmentDataSourceName
        : propertyDetails?.sourceDisplayName
          ? typeof propertyDetails.sourceDisplayName !== "number" ? propertyDetails.sourceDisplayName.substring(0, propertyDetails.sourceDisplayName.lastIndexOf("/")): undefined
          : undefined,
      propertyName: propertyDetails?.alienSourcePropertyName,
      unitOfMeasurement: (UOMbyProfile === "SI" && siUnitMapping.get(propertyDetails?.targetMeasureType))
        ? uomSymbolPairs[siUnitMapping.get(propertyDetails?.targetMeasureType)] 
        : uomSymbolPairs[propertyDetails?.targetMeasureUnit]
    } : {}
  const equipmentDetailsData = equipmentDetails

  //Handling SI Units Conversion for Unit Symbol
  let targetMeasureUOM = NA, lConstValue = propertyDetails?.value, lminValue = propertyDetails?.minValue, lmaxValue = propertyDetails?.maxValue;
  targetMeasureUOM = propertyDetails?.targetKey === "PresentValue"
    ? uomSymbolPairs[propertyDetails?.sourceMeasureUnit]
    : (UOMbyProfile === "SI" && siUnitMapping.get(propertyDetails?.targetMeasureType))
      ? uomSymbolPairs[siUnitMapping.get(propertyDetails?.targetMeasureType)]
      : uomSymbolPairs[propertyDetails?.targetMeasureUnit]
  if(propertyDetails?.isConstant && UOMbyProfile === "SI"){
    lConstValue = propertyDetails?.ConevrtedConstantValue
    const lMinValue = ConvertConstantToSI(Number(propertyDetails?.minValue), propertyDetails?.targetMeasureUnit, propertyDetails?.targetMeasureType)
    lminValue = Number(lMinValue.toFixed(1))
    const lMaxValue = ConvertConstantToSI(Number(propertyDetails?.maxValue), propertyDetails?.targetMeasureUnit, propertyDetails?.targetMeasureType)
    lmaxValue = Number(lMaxValue.toFixed(1))
  }
  const [t] = useTranslation()
  const NONE = 'None'
  const [confirmDialog, setConfirmDialog] = useState(null)
  const [isSomeFieldChanged, setSomeFieldChanged] = useState(false)
  const { buildingId } = getSearchParams()
  const analog = 'Analog'
  const SYSTEM_PROPERTY = 'SYSTEM_PROPERTY'
  const CONSTANT = 'CONSTANT'
  const ANALOG_POINTS = 'Analog Points'
  const multiState = 'Multi-State'
  const multiState2 = 'Multistate'
  const binary = 'Binary'
  
  const [automationList, setAutomationList] = useState([])
  const [dataSourceTypeList, setDataSourceTypeList] = useState([])
  const [deviceId, setDeviceId] = useState('')
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState('')

  const [selectedAutomationName, setSelectedAutomationName] = useState(
    equipmentDetailsData?.device?.name
  )
  const [dataSourceType, setDataSourceType] = useState('')
  const [dataSourceList, setdataSourceList] = useState([])
  const [dataSourceName, setDataSourceName] = useState('')
  const [niagaraEquipmentId, setNiagaraEquipmentId] = useState('')
  const [propertyName, setPropertyName] = useState('')
  const [selectedPropertyId, setSelectedPropertyId] = useState('')
  const [selectedNodeId, setSelectedNodeId] = useState('')
  const [expandedNodes, setExpandedNodes] = useState([{ id: equipmentDetails?.dataSourceName }])
  const [binarysourceList, setBinarySourceList] = useState([])
  const [analogSourceList, setAnalogSourceList] = useState([])
  const [multiStateSourceList, setmultiStateSourceList] = useState([])
  const [constantList, setConstantList] = useState([])
  const [constantValue, setConstantValue] = useState('')
  const [isConstant, setIsConstant] = useState(propertyDetails?.isConstant)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [isValueOutOfRange, setIsValueOutOfRange] = useState(false)
  const [dimensionList, setDimensionList] = useState([])
  const [sourceUOMList, setSourceUOMList] = useState([])
  const [selectedDimension, setSelectedDimension] = useState('')
  const [selectedUOM, setSelectedUOM] = useState('')
  const [propertyOptionList, setPropertyOptionList] = useState([])
  const [niagaraPropertyOptionList, setNiagaraPropertyOptionList] = useState([])
  const [clonePropertyDetails, setClonePropertyDetails] = useState({})
  const [isEditConstant, setIsEditConstant] = useState(false)
  const [subPropertyOptions, setSubPropertyOptions] = useState([])
  const [niagaraEquipment, setNiagaraEquipment] = useState([])
  let selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage)
  selectedLanguage = selectedLanguage ? selectedLanguage : 'en'
  const {
    data: systemPropertyData,
    loading: automationDataLoading,
    refetch: refetchSystemProperty
  } = useQuery({
    query: GET_AUTOMATION_SYSTEM_DETAILS,
    errorPolicy: 'all',
    dataPath: 'data.getBuilding.devices.items',
    disableInitialLoad: true
  })

  const { data: constantData, refetch: refetchConstantData } = useQuery({
    query: SEARCH_PROPERTY_METADATA,
    errorPolicy: 'all',
    dataPath: 'data.searchPropertyMetadata.items',
    disableInitialLoad: true
  })

  const {
    data: responseNiagaraEquipmentData,
    refetch: refetchNiagaraEquipmentsData,
  } = useQuery({
    query: GET_NIAGARA_EQUIPMENT_LIST_WITH_DATA_SOURCE_FILTER,
    dataPath: 'data.searchEquipment',
    variables: {
      buildingId: equipmentDetails?.building?.id,
      deviceId: equipmentDetails?.deviceId,
      defaultDataSourceName: (equipmentDetails?.dataSourceName === "None" || !equipmentDetails?.dataSourceName )  ? undefined : equipmentDetails?.dataSourceName
    },
  })

  useEffect(() => {
    if (systemPropertyData?.length) {
      const sourceDetails = findItemByDataSource(
        dataSourceType !== ''
          ? dataSourceType
          : propertyDetails?.sourceEquipmentDataSourceType?.length
          ? propertyDetails?.sourceEquipmentDataSourceType
          : equipmentDetailsData?.dataSourceType,
        dataSourceName != ''
          ? dataSourceName
          : propertyDetails?.sourceEquipmentDataSourceName
          ? propertyDetails?.sourceEquipmentDataSourceName
          : equipmentDetailsData?.dataSourceName
      )
      const id = sourceDetails?.id || propertyDetails?.sourceEquipmentId
       || equipmentDetailsData?.dataMappingEquipmentId 
      id &&
        refetchsource({
          id
        })
    }
  }, [equipmentDetailsData, propertyDetails, systemPropertyData])

  useEffect(() => {
    if(propertyDetails?.sourceEquipmentId){
    propertyDetails?.sourceEquipmentId
       !== equipmentDetailsData?.dataMappingEquipmentId && setSelectedPropertyId(propertyDetails?.sourceEquipmentId)
       setDataSourceName(propertyDetails?.sourceEquipmentDataSourceName )
    }
    let pName = ''
    if (!isConstant) {
      if (propertyDetails?.sourceEquipmentId?.length && propertyDetails?.sourceEquipmentId !== equipmentDetails?.sourceEquipmentId) {
        if (propertyDetails?.subPropertyDisplayName?.length) {
          pName = propertyDetails?.sourceDisplayName?.includes('/') 
          && propertyDetails?.sourceDisplayName?.split('/')[1]
        } else {
          pName = propertyDetails?.sourceDisplayName?.includes('/') 
          && propertyDetails?.sourceDisplayName?.split('/')[1]
        }
      } else {
        if(propertyDetails?.subPropertyDisplayName?.length) {
          pName = propertyDetails?.sourceDisplayName?.includes('/') 
          ? propertyDetails?.sourceDisplayName?.split('/')[0]
          : propertyDetails?.sourceDisplayName
        } else {
          pName = propertyDetails?.sourceDisplayName
        }
      } 
    } else {
      pName = sourceDisplayName
    }
    setPropertyName(pName)
     
  }, [equipmentDetailsData, propertyDetails])


  useEffect(() => {
    if (propertyDetails?.isConstant) {
      refetchConstantData({
        equipmentType: equipmentDetailsData?.type,
        tisDisplayName: propertyDetails?.tisDisplayName
      })
      setIsEditConstant(true)
    } else {
      refetchSystemProperty({
        buildingId: buildingId
      })
    }
    const eqDetails = { ...propertyDetails }
    setClonePropertyDetails(eqDetails)
  }, [])

  useEffect(() => {
    if (constantData?.length && constantData[0]?.possibleState !== null) {
      var possibleStateStr = constantData?.length
        ? constantData[0]?.possibleState
        : ''
      var possibleStateData = possibleStateStr?.length
        ? JSON?.parse(possibleStateStr)
        : ''
      const parse = possibleStateData?.length
        ? JSON?.parse(possibleStateData)
        : ''
      var allValues = parse?.allvalues
      var keyValuePairs = allValues?.map(function (value) {
        return { key: value, value: value }
      })
      const fetchTranslations = async () => {
        const translations = await Promise.all(keyValuePairs.map(async (v) => {
          if (selectedLanguage !== 'en' && v?.value ) {
            const translatedName = await fetchTranslate(v?.value, selectedLanguage)
            return { key: translatedName, value: translatedName }
          }
          return { key :translatedName, value: translatedName }
        }))
        setConstantList(translations)
      }
      if(selectedLanguage!=='en')
        fetchTranslations()
      else
        setConstantList(keyValuePairs)
    } else {
      setConstantList([])
    }
  }, [constantData])

  const [
    data_mapping_source_Loading,
     refetchsource,
     data_mapping_source
   ] = useAPIPagination( GET_PROPERTIES_BY_EQ_ID,
    'data.getPropertyMappingByEquipmentID'
  )

  const {
    data: niagaraDataMappingSource,
    loading: niagaraDataMappingSourceLoading,
    refetch: niagaraRefetchSource,
  } = useQuery({
    query: GET_EQUIPMENT_PROPERTIES_BY_EQUIPMENT_ID,
    dataPath: 'data.getPropertyMappingByEquipmentID',
    disableInitialLoad: true,
    variables: {
      equipmentId: niagaraEquipmentId,
    },
  })

  useEffect(() => {
    try {
      const requestEquipmentId = niagaraEquipmentId ? niagaraEquipmentId : propertyDetails?.sourceEquipmentId
      if (isNiagara && requestEquipmentId && !niagaraDataMappingSourceLoading) {
        niagaraRefetchSource({
          equipmentId: requestEquipmentId,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [niagaraEquipmentId, propertyDetails])

  const createTempDisplayName = (table)=>{
    const tempDisplayNames = table.map(el=>{
      return {
        translatedValue : el.sourceDisplayName,
        key : el.sourceDisplayName
      }
    }) 
    return tempDisplayNames
  }
  const matchFinder = (payload, actualItem)=>payload.find(it=>it.key === actualItem.sourceDisplayName)
  const translationHelper = async (tableToTranslate)=>{
    const tempDisplayNames = createTempDisplayName(tableToTranslate)
    let finalData;
    const translatedTable = await dispatch(translateJSON({ json: tempDisplayNames }))
          .then(res=>{
            const mergedList = tableToTranslate?.map(item=>{
            const match = matchFinder(res.payload, item)
            return {
            ...item,
            key : match.key || item.key,
            sourceDisplayName : match.translatedValue || item.value,
            }
            })
            finalData =  mergedList
          })
    return finalData
  }

  useEffect(() => {
    try {
      const requestEquipmentId = niagaraEquipmentId ? niagaraEquipmentId : propertyDetails?.sourceEquipmentId
      if (isNiagara && niagaraDataMappingSource && niagaraDataMappingSource?.items?.length) {
        // setNiagaraPropertyOptionList(currentList => ([
        //   ...currentList,
        //   ...(niagaraDataMappingSource.items.filter(item => item.equipmentId === requestEquipmentId).map(item => ({ ...item, key: item.sourceDisplayName, value: item.sourceDisplayName })))
        // ]))
        const tempList = niagaraDataMappingSource.items.filter(item => item.equipmentId === requestEquipmentId)
        translationHelper(tempList).then(res=>
          setNiagaraPropertyOptionList(currentList => ([
            ...currentList,
            ...(res.map(item => ({ ...item, key: item.sourceDisplayName, value: item.sourceDisplayName, actualValue : item.key})))
         ]))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }, [niagaraDataMappingSource])


  useEffect(() => {
    if (data_mapping_source?.length) {
      // Filtering Analog source data to list possible "Source" in dropdown
      const analogFilteredData = data_mapping_source?.filter(
        (source) => source.sourceDataGroup === analog
      )
      const analogSourceData = _uniqBy(analogFilteredData, (obj) =>
        obj?.sourceDisplayName ? obj?.sourceDisplayName : obj?.sourceKey
      )
        ?.map((obj) => ({
          value: obj?.sourceDisplayName
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          key: obj?.sourceDisplayName ? obj?.sourceDisplayName : obj?.sourceKey,
          sourceDeviceId: obj?.sourceDeviceId
        }))
        ?.sort((a, b) => a?.key?.localeCompare(b?.key))
      const filteredAnalogSourceData = analogSourceData?.filter(
        (item) => item?.key !== null && item?.value !== null
      )
      setAnalogSourceList(filteredAnalogSourceData)

      // Filtering Binary source data to list possible "Source" in dropdown
      const binaryFilteredData = data_mapping_source?.filter(
        (source) =>   source.sourceDataGroup === binary ||
          source.sourceDataGroup === multiState ||
          source.sourceDataGroup === multiState2
      )

      // Making Key , value pair for options
      const binarySourceData = _uniqBy(binaryFilteredData, (obj) =>
        obj?.sourceDisplayName ? obj?.sourceDisplayName : obj?.sourceKey
      )
        ?.map((obj) => ({
          value: obj?.sourceDisplayName
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          key: obj?.sourceDisplayName ? obj?.sourceDisplayName : obj?.sourceKey,
          sourceDeviceId: obj?.sourceDeviceId
        }))
        ?.sort((a, b) => a?.key?.localeCompare(b?.key))
      const filteredBinarySourceData = binarySourceData?.filter(
        (item) => item?.key !== null && item?.value !== null
      )
      setBinarySourceList(filteredBinarySourceData)

      // Filtering MultiState source data to list possible "Source" in dropdown
      // multistate properties displayed for both binary and multistate,A separate
      //state is not required , this can be cleaned up
      const multistateFilteredData = data_mapping_source?.filter(
        (source) =>
          source.sourceDataGroup === multiState ||
          source.sourceDataGroup === multiState2
      )

      // Making Key , value pair for options
      const multistateSourceData = _uniqBy(multistateFilteredData, (obj) =>
        obj?.sourceDisplayName ? obj?.sourceDisplayName : obj?.sourceKey
      )
        ?.map((obj) => ({
          value: obj?.sourceDisplayName
            ? obj?.sourceDisplayName
            : obj?.sourceKey,
          key: obj?.sourceDisplayName ? obj?.sourceDisplayName : obj?.sourceKey,
          sourceDeviceId: obj?.sourceDeviceId
        }))
        ?.sort((a, b) => a?.key?.localeCompare(b?.key))
      const filteredMultistateSourceData = multistateSourceData?.filter(
        (item) => item?.key !== null && item?.value !== null
      )
      setmultiStateSourceList(filteredMultistateSourceData)
    }
  }, [data_mapping_source, data_mapping_source_Loading])

  const valueSorter = (item) => item?.value?.toLowerCase()
  const labelSorter = (item) => item?.label?.toLowerCase()
  useEffect(() => {
    if (_isEmpty(systemPropertyData)) return
    const automationSystemList = []
    systemPropertyData?.forEach((device) => {
      const automationName = device?.name ?? ''
      if (
        !_isEmpty(automationName) 
        && device?.isActive
        && device?.type !== 'ES'
        && device?.type !== 'BCU'
        )
        automationSystemList.push({
          key: automationName,
          value: automationName
        })
    })
    setAutomationList(
      _orderBy(
        _uniqBy(automationSystemList, (obj) => obj?.key),
        [valueSorter],
        SORT_TYPE.asc
      )
    )

    handleGetDataSourceTypeList(
      propertyDetails?.automationName
        ? propertyDetails?.automationName
        : equipmentDetailsData?.device?.name
    ) //equipmentDetailsData?.device?.name
    handleGetDataSourceList(
      propertyDetails?.automationName
        ? propertyDetails?.automationName
        : equipmentDetailsData?.device?.name,
      propertyDetails?.sourceEquipmentDataSourceType
        ? propertyDetails?.sourceEquipmentDataSourceType
        : equipmentDetailsData?.dataSourceType
    )

    if (equipmentDetailsData?.family === ANALOG_POINTS) {
      handleDimensionList(
        propertyDetails?.alienSourcePropertyName?.length
          ? propertyDetails?.alienSourcePropertyName
          : ' '
      )
      handleSourceUOMList(
        propertyDetails?.alienSourcePropertyName?.length
          ? propertyDetails?.alienSourcePropertyName
          : ' '
      )
    }
  }, [systemPropertyData, automationDataLoading, data_mapping_source])

  useEffect(() => {
    handleSourceUOMList(
      propertyName ? propertyName : propertyDetails?.alienSourcePropertyName
    )
  }, [selectedDimension])

  // prevents to reload or close page when user made some changes on Data Mapping Tab
  useEffect(() => {
    const preventDiscardDataMappingChanges = (e) => {
      if (isSomeFieldChanged) {
        e.preventDefault()
        e.returnValue = true
        return true
      }
    }
    window.addEventListener('beforeunload', preventDiscardDataMappingChanges)

    return () => {
      window.removeEventListener(
        'beforeunload',
        preventDiscardDataMappingChanges
      )
    }
  }, [isSomeFieldChanged])

  const onCloseEditPropertyDialog = () => {
    if (isSomeFieldChanged) {
      showDiscardChangesPrompt()
    } else {
      toggleDialog()
      handleAlienProperty(
        clonePropertyDetails,
        null,
        null,
        null,
        null,
        selectedIndex,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true
      )
    }
  }

  const translatedOptionsModal = {
    title : translate("Warning"),
    text: translate(
     'You have made changes which have not been saved. Do you wish to Continue?'
   ),
   confirmText: 'Yes',
   cancelText: 'No',
   noApplicableProperties: translate("No applicable properties are available for mapping from the selected controller"),
   none: translate("None"),
   interval: "Interval",
   selectOne : translate("--Select One--"),
   findProperty : translate("Find a Property"),
   noResult: translate("No Results found"),
   uom : "Unit Of Measure",
   findAproperty: translate("Find a Property")+"..."
   }
 
  const getDiscardChangesPromptConfig = ({
     handleConfirm,
     handleCancel
   }) => ({
     title: "Warning",
     text: translatedOptionsModal.text,
     confirmText: translatedOptionsModal.confirmText,
     cancelText: translatedOptionsModal.cancelText,
     handleConfirm,
     handleCancel
   })
  

  const showDiscardChangesPrompt = () => {
    setConfirmDialog(
      getDiscardChangesPromptConfig({
        handleConfirm: () => {
          propertyDetails = { ...clonePropertyDetails }
          toggleDialog()
          handleAlienProperty(
            clonePropertyDetails,
            null,
            null,
            null,
            null,
            selectedIndex,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true
          )
          hideConfirmDialog()
        },
        handleCancel: hideConfirmDialog
      })
    )
  }

  const hideConfirmDialog = () => setConfirmDialog(null)

  const modalConfig = {
    className: 'data-mapping-edit-property',
    gray: true,
    heading: `${translate("Edit Property")}: ${
      propertyDetails?.tisDisplayName
    }`,
    buttons: [
      {
        text: "Cancel",
        handleClick: () => {
          propertyDetails['isModifiedSubProperty'] = true
          propertyDetails = { ...clonePropertyDetails }
          onCloseEditPropertyDialog()
        },
        type: 'cancel'
      },
      {
        text: "Save",
        handleClick: (values) => {
          let clonedPropertyDetails = { ...propertyDetails }
          clonedPropertyDetails['creationType'] = 'User'
          let pName = !propertyName
            ? clonedPropertyDetails?.sourceDisplayName
            : propertyName
          const dName = !dataSourceName
            ? equipmentDetailsData?.dataSourceName
            : dataSourceName

          let alienProperty
          if (!isConstant) {
            if (clonedPropertyDetails?.sourceEquipmentId === equipmentDetails?.sourceEquipmentId) {
              pName = pName?.includes('/') 
              ? pName?.split('/')[0]
              : pName
              if (clonedPropertyDetails?.subPropertyDisplayName?.length) {
                alienProperty =
                pName + '/' + clonedPropertyDetails?.subPropertyDisplayName
              } else {
                alienProperty = pName
              }
            } else  {
              pName = pName?.includes('/') 
              ? pName?.split('/')[1]
              : pName
              if (clonedPropertyDetails?.subPropertyDisplayName?.length) {
                alienProperty = dName + '/' + pName + '/' + clonedPropertyDetails?.subPropertyDisplayName
              } else {
                alienProperty = !isConstant
                ? selectedPropertyId.length
                  ? dName + '/' + pName
                  : propertyName
                : constantValue
              }
            }
          } else {
            alienProperty = constantValue
          }

          const isSameEquipment =
            dataSourceName?.length &&
            equipmentDetailsData?.dataSourceName !== dataSourceName
              ? false
              : true

          if (!isConstant) {
            clonedPropertyDetails = {
              ...clonedPropertyDetails,
              ...selectedPropertyDetails,
              id: clonedPropertyDetails?.id
            }
          }

          const details = {
            Dimension: selectedDimension,
            UOM: selectedUOM,
            dataSourceType: dataSourceType,
            dataSourceName: dataSourceName,
            automationName: selectedAutomationName
          }
          handleAlienProperty(
            clonedPropertyDetails,
            alienProperty,
            selectedPropertyId,
            isConstant,
            pName,
            selectedIndex,
            deviceId,
            selectedDimension,
            selectedUOM,
            dataSourceType,
            dataSourceName,
            selectedAutomationName,
            details,
            false
          )

          toggleDialog()
        },
        type: 'confirm',
        disabled: isSaveDisabled
      }
    ].filter(Boolean),
    handleClose: () => {
      propertyDetails['subPropertyDisplayName'] = null
      propertyDetails['isModifiedSubProperty'] = true
      propertyDetails = { ...clonePropertyDetails }
      onCloseEditPropertyDialog()
    },
    loading: automationDataLoading
  }

  const propertyTypes = [
    {
      key: 'SYSTEM_PROPERTY',
      value: translate('System Property')
    },
    {
      key: 'CONSTANT',
      value: translate('Constant')
    }
  ]

  const getDropDownDataOrEquipmentDetail = (
    automationName,
    propertyName_selected = EMPTY,
    dataSourceType = EMPTY,
    dataSourceName = EMPTY,
    isIncludeUnmapped = false,
    defaultDataSource = false
  ) => {
    const equipmentDetail = {
      dropDownList: [],
      uri: EMPTY,
      deviceId: EMPTY,
      controllerType: EMPTY
    }
    const autoCreatedEquipments = []
    const userCreatedEquipments = []
    const automationsListByName = systemPropertyData?.filter(
      (a) => a?.name === automationName
    )
    automationsListByName?.forEach((device) => {
      const equipments = device?.equipments?.items ?? []
      if (equipments?.length > 0) {
        equipments?.forEach((equipment) => {
          if (!_isEmpty(propertyName_selected) && equipment?.isActive === 1) {
            const propertyValue = equipment[propertyName_selected] ?? ''

            if (!_isEmpty(propertyValue)) {
              if (
                equipment.dataSourceType === dataSourceType &&
                dataSourceType !== EMPTY
              ) {
                autoCreatedEquipments.push(equipment)
              }
              if (dataSourceType === EMPTY)
                equipmentDetail.dropDownList.push({
                  key: propertyValue,
                  value: propertyValue
                })
            }
          } else {
            if (
              equipment?.dataSourceName === dataSourceName &&
              equipment?.dataSourceType === dataSourceType
            ) {
              equipmentDetail.uri = equipment?.uri
              equipmentDetail.deviceId = equipment?.deviceId
              equipmentDetail.controllerType = equipment?.controllerType
            }
          }
        })
      }
    })
    if (defaultDataSource) {
      const userCreatedDSNList = (
        userCreatedEquipments ? userCreatedEquipments : []
      )?.map((obj) => obj?.dataSourceName)
      const isIncludeCheckList = autoCreatedEquipments?.filter(
        (obj) => !userCreatedDSNList?.includes(obj?.dataSourceName)
      )
      let dropDownList = isIncludeUnmapped
        ? isIncludeCheckList
        : autoCreatedEquipments
      dropDownList = dropDownList?.map((obj) => {
        const propertyValue = obj[propertyName_selected] ?? ''
        return {
          key: propertyValue,
          label: propertyValue,
          value: propertyValue
        }
      })
      equipmentDetail.dropDownList = dropDownList
    }
    return equipmentDetail
  }

  const handleGetDataSourceTypeList = (automationName) => {
    if (_isEmpty(automationName)) return
    const dataSourceTypeList = getDropDownDataOrEquipmentDetail(
      automationName,
      PROPERTY_VALUE.DATA_SOURCE_TYPE
    )?.dropDownList
    const dataSourceTypeListWithNone = [{ key: NONE, value: NONE }].concat(
      _orderBy(
        _uniqBy(dataSourceTypeList, (obj) => obj?.key),
        [valueSorter],
        SORT_TYPE.asc
      )
    )
    setDataSourceTypeList(dataSourceTypeListWithNone)
  }

  const handleGetDataSourceList = (
    automationName,
    dataSourceType,
    isIncludeUnmapped = false
  ) => {
    if (_isEmpty(automationName)) return
    const dataSourceList = getDropDownDataOrEquipmentDetail(
      automationName,
      PROPERTY_VALUE.DATA_SOURCE_NAME,
      dataSourceType,
      EMPTY,
      isIncludeUnmapped,
      true
    )?.dropDownList
    setdataSourceList(
      _orderBy(
        _uniqBy(dataSourceList, (obj) => obj?.key),
        [labelSorter],
        SORT_TYPE.asc
      )
    )
  }

  // To find source details based on dataSourceType and dataSourceName
  const findItemByDataSource = (dataSourceType, dataSourceName) => {
    if (systemPropertyData?.length) {
      for (const item of systemPropertyData) {
        const sourceEquipments = item?.equipments?.items?.filter(item => item.creationType === "Auto")
        const equipment = sourceEquipments?.find(
          (equipment) =>
            equipment?.dataSourceType === dataSourceType &&
            equipment?.dataSourceName === dataSourceName
        )
        if (equipment) {
          return equipment
        }
      }
    }
    return null
  }

  const handleSubPropertyList = (selectedPropertyName) => {
    const subPropertyArray = []
    if (selectedPropertyName) {
      const filterSubPropertyList = data_mapping_source?.filter(
        (propertyData) =>
          propertyData?.sourceDisplayName === selectedPropertyName
      )
      for (const obj in filterSubPropertyList) {
        if (filterSubPropertyList[obj].subPropertyDisplayName) {
          subPropertyArray.push({
            key: filterSubPropertyList[obj]?.subPropertyDisplayName,
            value: filterSubPropertyList[obj]?.subPropertyDisplayName
          })
        }
      }
    }
    const sortedsubPropertyList = _orderBy(subPropertyArray, (item) => {
      const match = item?.key?.match(/\d+/);
      return match ? parseInt(match[0]) : 0
    }, SORT_TYPE.asc)
    setSubPropertyOptions(
      _uniqBy(sortedsubPropertyList, (obj) => obj?.key),
      [valueSorter]
    )
  }

  const handleDimensionList = (selectedPropertyName) => {
    const dimensions = []
    if (selectedPropertyName) {
      for (const obj in data_mapping_source) {
        if (
          data_mapping_source[obj]?.sourceDisplayName === selectedPropertyName
        ) {
          if (
            data_mapping_source[obj]?.sourceMeasureType &&
            data_mapping_source[obj]?.sourceMeasureType !== 'none'
          ) {
            dimensions.push({
              key: data_mapping_source[obj]?.sourceMeasureType,
              value: data_mapping_source[obj]?.sourceMeasureType
            })
          } else {
            Object.keys(defaultdimensionGroup).map((data) => {
              try {
                const defaultDimensionId = defaultdimensionGroup[data][0]?.value
                dimensions?.push({
                  key: data,
                  value: data,
                  dimensionId: defaultDimensionId['Dimension']
                })
              } catch (error) {}
            })
          }
        } else {
          setSelectedDimension('')
        }
      }
    }
    const dimensionList = _orderBy(
      _uniqBy(dimensions, (obj) => obj?.key),
      [valueSorter],
      SORT_TYPE.asc
    )
    setSourceUOMList('')
    if (dimensionList && selectedPropertyName) {
      handleSourceUOMList(selectedPropertyName)
    }
    propertyDetails['sourceMeasureType'] = dimensionList?.length
      ? dimensionList[0].value
      : 'None'
    setDimensionList(dimensionList)
  }

  const handleSourceUOMList = (selectedPropertyName) => {
    const sourceUOMs = []
    data_mapping_source?.map((property) => {
      if (property?.sourceDisplayName === selectedPropertyName) {
        if (
          property?.sourceMeasureUnit &&
          property?.sourceMeasureType !== 'none'
        ) {
          sourceUOMs?.push({
            key: property?.sourceMeasureUnit,
            value: property?.sourceMeasureUnit
          })
        } else {
          const dimension = selectedDimension
            ? selectedDimension
            : propertyDetails?.sourceMeasureType

          defaultdimensionGroup[dimension]?.map((dimensionData) => {
            try {
              const value = dimensionData?.value
              sourceUOMs?.push({
                key: value['Name'],
                value: value['Name'],
                dimensionId: value['Dimension']
              })
            } catch (error) {}
          })
        }
      }
    })
    const UoMList = _orderBy(
      _uniqBy(sourceUOMs, (obj) => obj?.key),
      [valueSorter],
      SORT_TYPE.asc
    )
    const finalUOMList = UoMList?.filter(
      (item) => item?.dimensionId !== undefined
    )

    if (!propertyDetails['sourceMeasureUnit']?.length) {
      propertyDetails['sourceMeasureUnit'] = finalUOMList?.length
        ? finalUOMList[0].value
        : 'None'
    }
    setSourceUOMList(finalUOMList)
  }

  const createTreeId = (components, endAtIndex) => components.slice(0, endAtIndex + 1).join("/")
  // Function to create heirarchy structure for TreeView component
  const formatForTree = data => {
    const validDataSourceTypes = propertyDetails?.targetDataGroup === analog 
    ? [DATA_SOURCE_TYPES.ANALOG]
    : [binary, multiState].includes(propertyDetails?.targetDataGroup)
      ? [DATA_SOURCE_TYPES.BINARY, DATA_SOURCE_TYPES.MULTISTATE]
      : [DATA_SOURCE_TYPES.ANALOG, DATA_SOURCE_TYPES.BINARY, DATA_SOURCE_TYPES.MULTISTATE]
    const returnData = []
    data.forEach(point => {
      if (validDataSourceTypes.includes(point.dataSourceType)) {
        const comps = point.name.split("/")
        let found = returnData.find(el => el.name === comps[0])
        if (!found) {
          // If first component of name is not found, create new object for it
          const newObj = {
            name: comps[0],
            id: comps[0],
            children: comps.length > 1 ? [] : null,
            color: comps.length > 1 ? null : getPointColor(point),
            equipmentId: comps.length > 1 ? null : point.id,
          }

          let objRef = newObj
          // Iterate through the rest of the path for this point to fill out the structure, adding children as needed
          for (let i = 1; i < comps.length; i++) {
            const newChild = {
              name: comps[i],
              id: createTreeId(comps, i), children: comps.length > i + 1 ? [] : null,
              color: comps.length > i + 1 ? null : getPointColor(point),
              equipmentId: comps.length > i + 1 ? null : point.id,
            }
            const insertPosition = objRef.children.findIndex(item => item.name?.localeCompare(newChild.name) >= 0)
            objRef.children.splice(insertPosition >= 0 ? insertPosition : objRef.children.length, 0, newChild)
            objRef = objRef.children[0]
          }

          // Add point to the list, represented by the object and its children
          const insertPosition = returnData.findIndex(item => item.name?.localeCompare(newObj.name) >= 0)
          returnData.splice(insertPosition >= 0 ? insertPosition : returnData.length, 0, newObj)

        } else {
          // If the first component of the path already exists, check for the rest of the path
          for (let i = 1; i < comps.length; i++) {
            let nextLevelFound = found.children?.find(el => el.name === comps[i])
            if (!nextLevelFound) {
              // If any component of the path for this point is not found, create it
              const newObj = {
                name: comps[i],
                id: createTreeId(comps, i),
                children: comps.length > i + 1 ? [] : null,
                color: comps.length > i + 1 ? null : getPointColor(point),
                equipmentId: comps.length > i + 1 ? null : point.id,
              }
              const insertPosition = found.children.findIndex(item => item.name?.localeCompare(newObj.name) >= 0)
              found.children.splice(insertPosition >= 0 ? insertPosition : found.children.length, 0, newObj)

              let objRef = newObj
              // After creating a new object, continue through the point's path, adding children as needed
              for (let j = i + 1; j < comps.length; j++) {
                const newChild = {
                  name: comps[j],
                  id: createTreeId(comps, j),
                  children: comps.length > j + 1 ? [] : null,
                  color: comps.length > j + 1 ? null : getPointColor(point),
                  equipmentId: comps.length > j + 1 ? null : point.id,
                }
                const insertPosition = objRef.children.findIndex(item => item.name?.localeCompare(newChild.name) >= 0)
                objRef.children.splice(insertPosition >= 0 ? insertPosition : objRef.children.length, 0, newChild)

                objRef = objRef.children[0]
              }
              break

            } else {
              if (comps.length > i + 1 && !nextLevelFound.children) {
                // There could be a case where we have two objects with the same name, but one is a point and one is just an expandable parent
                // To account for this, give a different ID for the expandable parent and place it before the selectable point
                // when sorting so that later points will correctly find the parent
                const newChild = {
                  name: comps[i],
                  id: `${createTreeId(comps, i)}:parent`,
                  children: comps.length > i + 1 ? [] : null,
                  color: comps.length > i + 1 ? null : getPointColor(point),
                  equipmentId: comps.length > i + 1 ? null : point.id,
                }
                const insertPosition = found.children.findIndex(item => item.name?.localeCompare(newChild.name) >= 0)
                found.children.splice(insertPosition >= 0 ? insertPosition : found.children.length, 0, newChild)
                found = newChild
              } else {
                found = nextLevelFound
              }
            }

          }

        }
      }
    })
    return returnData
  }
  useEffect(() => {
      if (data_mapping_source?.length) {
        const listOfText = propertyDetails?.targetDataGroup === analog
        ? analogSourceList
        : propertyDetails?.targetDataGroup === binary
        ? binarysourceList
        : multiStateSourceList

        const fetchTranslations = async () => {
          const translations = await Promise.all(listOfText.map(async (v) => {
            if (selectedLanguage !== 'en' && v?.value ) {
              const translatedName = await fetchTranslate(v?.value, selectedLanguage)
              return { ...v, value: translatedName }
            }
            return { ...v, value: v.value }
          }))
          setPropertyOptionList(translations)
      }
      if(selectedLanguage!=='en')
        fetchTranslations()
      else
        setPropertyOptionList(listOfText)
    }
  }, [data_mapping_source, propertyDetails, selectedLanguage,analogSourceList,binarysourceList,multiStateSourceList])

  useEffect(() => {
    if (propertyDetails?.subPropertyDisplayName?.length) {
      handleSubPropertyList(propertyName)
    }
  }, [data_mapping_source])

  useEffect(() => {
    try {
      if (isNiagara && responseNiagaraEquipmentData && responseNiagaraEquipmentData?.items.length) {
        setNiagaraEquipment(currentList => ([
          ...currentList,
          ...(responseNiagaraEquipmentData?.items)
        ]))
      }
      if (isNiagara && responseNiagaraEquipmentData && responseNiagaraEquipmentData.nextToken !== null) {
        refetchNiagaraEquipmentsData({
          buildingId: equipmentDetails?.building?.id,
          deviceId: equipmentDetails?.deviceId,
          defaultDataSourceName: equipmentDetails?.dataSourceName === "None" ? undefined : equipmentDetails?.dataSourceName,
          nextToken: responseNiagaraEquipmentData?.nextToken,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [responseNiagaraEquipmentData])

  const formattedTreeData = useMemo(() => {
    if (niagaraEquipment?.length) {
      return formatForTree(niagaraEquipment)
    } else {
      return []
    }
  }, [niagaraEquipment])

  return (
    <>
      <Modal {...modalConfig}>
        <Formik
          initialValues={{
            propertyType: propertyDetails.isConstant
              ? CONSTANT
              : SYSTEM_PROPERTY,
            defaultAutomationSystem: selectedAutomationName
          }}
          enableReinitialize={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            // Submit TO - DO
          }}
        >
          {({ values, handleChange, setValues }) => (
            <Form>
              <div>
                <EditableView
                  id="propertyType"
                  type={EditableView.COMPONENT_TYPE.SELECT}
                  className="default-automation-system required"
                  labelName={t(
                    'equipment-setup:DataMapping:EditProperty>PropertyType'
                  )}
                  name="propertyType"
                  placeholder={translatedOptionsModal.selectOne}
                  value={_get(
                    propertyTypes?.find(({ key }) =>
                      values.propertyType?.includes(key)
                    ),
                    'value'
                  )}
                  options={propertyTypes}
                  onChange={(e) => {
                    setIsSaveDisabled(true)
                    handleChange('propertyType', e)
                    if (e === CONSTANT) {
                      setIsConstant(true)
                      refetchConstantData({
                        equipmentType: equipmentDetailsData?.type,
                        tisDisplayName: propertyDetails?.tisDisplayName
                      })
                      propertyDetails['isConstant'] = true
                    } else {
                      propertyDetails['isConstant'] = false
                      propertyDetails['value'] = ''
                      propertyDetails['ConevrtedConstantValue'] = ''
                      refetchSystemProperty({
                        buildingId: buildingId
                      })
                      setIsConstant(false)
                    }
                  }}
                />
              </div>

              {/* Niagara System Property */}
              {isNiagara && values.propertyType === SYSTEM_PROPERTY ? (
                <>
                  {/* Default Data Source Type */}
                  <div>
                    <DataSourceEditableView
                      type={EditableView.COMPONENT_TYPE.DEFAULT}
                      component={
                        () =>
                          <StyledTree
                            data={formattedTreeData}
                            propNames={{
                              label: "name",
                            }}
                            components={{
                              Label: bag => {
                                return <LabelFormat treeChildren={bag?.data?.children} color={bag?.data?.color} label={bag?.data?.name} language={selectedLanguage} />
                              }
                            }}
                            selectable={true}
                            onSelect={node => {
                              // If selecting an expandable value rather than a point, do nothing
                              if (node.data?.children?.length) {
                                return false
                              }
                              // If selecting the same point, do nothing
                              if (node.data?.equipmentId === niagaraEquipmentId) {
                                return false
                              }
                              setNiagaraPropertyOptionList([])
                              setDataSourceName(node.data.id)
                              setNiagaraEquipmentId(node.data.equipmentId)
                              setSelectedPropertyId(node.data.equipmentId)
                              setSelectedNodeId(node.data.id)
                              setValues({
                                ...values,
                                dataSourceObjectName: node.data.id,
                                propertyName: undefined,
                                sourceUnitOfMeasurementId: "",
                                dimensionId: "",
                              })
                            }}
                            onToggle={bag => {
                              if (bag.data.expanded) {
                                setExpandedNodes(curr => [...curr, { id: bag.data.id }])
                              } else {
                                setExpandedNodes(curr => curr.filter(item => item.id !== bag.data.id))
                              }
                            }
                            }
                            selectedNodes={values.dataSourceObjectName ? [{ id: values.dataSourceObjectName }] : naxInitialValues?.dataSourceObjectName ? [{ id: naxInitialValues.dataSourceObjectName }] : selectedNodeId ? [{ id: selectedNodeId }] : [{ id: `${equipmentDetails?.dataSourceName}` }]}
                            expandedNodes={expandedNodes}
                          />
                      }
                      labelName="Data Source"
                      name="dataSource"
                    />
                  </div>

                  <div>
                    {(
                      <div>
                        {/* Property */}
                        {
                          !niagaraPropertyOptionList?.length
                            ? (
                              <div className="edit-view empty-label" style={{ marginBottom: "16px" }}>
                                <label className="edit-view__label">
                                  <span className="edit-view__label-name">{translatedOptionsModal.noApplicableProperties}</span>
                                </label>
                              </div>
                            )
                            : (
                              <EditableView
                                id="propertyName"
                                className="property required"
                                labelName={t(
                                  'equipment-setup:DataMapping:EditProperty>Property'
                                )}
                                name="propertyName"
                                placeholder={t('common:SelectOne')}
                                value={
                                  values?.propertyName !== undefined
                                    ? niagaraPropertyOptionList.find(({ key }) => key === values.propertyName)?.value
                                    : propertyDetails?.sourceEquipmentId && (!selectedPropertyId || selectedPropertyId === propertyDetails?.sourceEquipmentId)
                                      ? niagaraPropertyOptionList.find(({ key, actualValue }) => key === propertyDetails.alienSourcePropertyName || actualValue === propertyDetails.alienSourcePropertyName)?.value 
                                      : propertyDetails?.sourceDisplayName
                                }
                                options={niagaraPropertyOptionList}
                                onChange={(propertyName, { values, setValues }) => {
                                  handleSubPropertyList(propertyName)
                                  setSourceUOMList([])
                                  setDimensionList([])
                                  setSomeFieldChanged(true)
                                  setPropertyName(propertyName)
                                  const selectedNiagaraPropertyDetails =
                                    niagaraPropertyOptionList?.find(
                                      (item) =>
                                        item?.sourceDisplayName === propertyName
                                    ) || {}
                                  propertyDetails['sourceMeasureUnit'] =
                                  selectedNiagaraPropertyDetails?.sourceMeasureUnit
                                      ? selectedNiagaraPropertyDetails?.sourceMeasureUnit
                                      : null
                                  setIsSaveDisabled(false)
                                  setDeviceId(selectedNiagaraPropertyDetails?.sourceDeviceId || "")
                                  setSelectedPropertyDetails(selectedNiagaraPropertyDetails)
                                  if (
                                    equipmentDetailsData?.family === ANALOG_POINTS
                                  ) {
                                    handleDimensionList(propertyName)

                                    if (subPropertyOptions?.length)
                                      setIsSaveDisabled(true)
                                    else setIsSaveDisabled(false)
                                  }
                                  setTimeout(() => {
                                    propertyDetails['alienSourcePropertyName'] =
                                      propertyName
                                    propertyDetails['value'] = ''
                                    propertyDetails['ConevrtedConstantValue'] = ''
                                    propertyDetails['subPropertyDisplayName'] = null

                                    setValues({
                                      ...values,
                                      propertyName,
                                      dimensionId: propertyDetails?.sourceMeasureType,
                                      sourceUnitOfMeasurementId: propertyDetails?.sourceMeasureUnit,
                                    })
                                  }, 5)
                                }}
                                component={({
                                  inputId,
                                  onBlur,
                                  onFocus,
                                  onChange,
                                  options,
                                  value
                                }) => (
                                  <SelectrixWrapper
                                    arrow={true}
                                    className="selectrix-wrapper--type-autocomplete"
                                    customKeys={{ key: 'key', label: 'value' }}
                                    defaultValue={value}
                                    id={inputId}
                                    inputPlaceholder={translatedOptionsModal.findAproperty}
                                    notFoundPrompt={translatedOptionsModal.noResult}
                                    onChange={({ key }) => onChange(key)}
                                    onClose={onBlur}
                                    onOpen={onFocus}
                                    options={options}
                                    searchable={true}
                                    searchBoxInside={true}
                                    searchIndex={false}
                                  />
                                )}
                              />
                            )
                        }
                      </div>
                    )}

                    <div>
                      {data_mapping_source?.length &&
                        equipmentDetailsData?.family === ANALOG_POINTS ? (
                        <>
                          {/* Sub Property */}
                          {subPropertyOptions?.length ? (
                            <EditableView
                              className="sub-property required"
                              type={EditableView.COMPONENT_TYPE.SELECT}
                              labelName={t(
                                'equipment-setup:DataMapping:EditProperty>SubProperty'
                              )}
                              name="subPropertyDisplayName"
                              placeholder={t(
                                'equipment-setup:DataMapping>NoPropertySelected'
                              )}
                              value={
                                values?.subPropertyDisplayName !== undefined
                                  ? values?.subPropertyDisplayName
                                  : propertyDetails?.subPropertyDisplayName
                              }
                              options={subPropertyOptions}
                              onChange={(
                                subPropertyDisplayName,
                                { values, setValues }
                              ) => {
                                setIsSaveDisabled(false)
                                setTimeout(() => {
                                  propertyDetails['subPropertyDisplayName'] =
                                    subPropertyDisplayName
                                  propertyDetails['value'] = ''
                                  propertyDetails['ConevrtedConstantValue'] = ''
                                  propertyDetails[
                                    'isModifiedSubProperty'
                                  ] = true
                                  setValues({
                                    ...values,
                                    subPropertyDisplayName
                                  })
                                }, 5)
                              }}
                            />
                          ) : (
                            ''
                          )}

                          {/* Dimension */}
                          <EditableView
                            className="source-uom required"
                            type={EditableView.COMPONENT_TYPE.SELECT}
                            labelName={t(
                              'equipment-setup:CreateEquipment>Dimension'
                            )}
                            name="dimensionId"
                            value={
                              values?.dimensionId?.length
                                ? values?.dimensionId
                                : propertyDetails?.sourceMeasureType
                            }
                            options={dimensionList}
                            placeholder="None"
                            onChange={(value) => {
                              setSelectedDimension(value)
                              setIsSaveDisabled(true)
                              propertyDetails['sourceMeasureUnit'] = null
                              handleSourceUOMList(propertyName)
                              setTimeout(() => {
                                propertyDetails['sourceMeasureType'] = value
                                setValues({
                                  ...values,
                                  dimensionId: value,
                                  sourceUnitOfMeasurementId: ''
                                })
                              }, 5)
                            }}
                          />
                          {/* Source Unit Of Measure */}
                          <EditableView
                            className="source-uom required"
                            type={EditableView.COMPONENT_TYPE.SELECT}
                            labelName={t(
                              'equipment-setup:DataMapping:EditProperty>SourceUoM'
                            )}
                            name="sourceUnitOfMeasurementId"
                            placeholder={t(
                              'equipment-setup:DataMapping:EditProperty>NoUoMSelected'
                            )}
                            value={
                              values?.sourceUnitOfMeasurementId !== undefined
                                ? values?.sourceUnitOfMeasurementId
                                : propertyDetails?.sourceMeasureUnit
                            }
                            options={sourceUOMList}
                            onChange={(value) => {
                              setIsSaveDisabled(false)
                              setSelectedUOM(value)
                              setTimeout(() => {
                                propertyDetails['sourceMeasureUnit'] = value
                                propertyDetails['targetMeasureUnit'] = value
                                setValues({
                                  ...values,
                                  sourceUnitOfMeasurementId: value
                                })
                              }, 5)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* TargetUom */}
                  <EditableView
                    id="targetUom"
                    className="uom-name"
                    disabled
                    labelName="Target UOM"
                    name="targetUom"
                    readOnly
                    type={EditableView.COMPONENT_TYPE.INPUT}
                    value={values?.unitOfMeasurement ? values?.unitOfMeasurement : naxInitialValues?.unitOfMeasurement ?? NA}
                  />
                  {/* Interval */}
                  <div>
                    <EditableView
                      id="interval"
                      className="interval"
                      type={EditableView.COMPONENT_TYPE.DEFAULT}
                      labelName={t(
                        'equipment-setup:DataMapping:EditProperty>Interval'
                      )}
                      name="interval"
                      value={'15'}
                      component={({ value, onChange }) => (
                        <>
                          <div style={{ width: '105px' }}>
                            <Select
                              options={INTERVAL_OPTIONS}
                              placeholder={t('common:SelectOne')}
                              includePlaceholderOption={true}
                              selectedItem={value}
                              onChange={onChange}
                              isDisabled={true}
                            />
                          </div>
                          <LabelDiv>
                            {t(
                              'equipment-setup:DataMapping:EditProperty>Minutes'
                            )}
                          </LabelDiv>
                        </>
                      )}
                    />
                  </div>
                  {/* Polarity Options */}
                  {(propertyDetails?.targetDataGroup === binary ||
                    propertyDetails?.targetDataGroup === multiState) && (
                      <div>
                        <RadioGroup
                          label="Polarity"
                          handleSelection={({ target: { value } }) => {
                            propertyDetails['polarityNormal'] = value
                            setValues({ ...values, polarityNormal: value })
                          }}
                          name="polarityNormal"
                          radioKey="polarityNormal"
                          options={getPolarityOptions}
                          selectedValue={propertyDetails['polarityNormal']}
                        />
                      </div>
                    )}
                </>
              )
                : values.propertyType === SYSTEM_PROPERTY ? (
                <>
                  <div>
                    {/* Default Automation System */}
                    <EditableView
                      type={EditableView.COMPONENT_TYPE.SELECT}
                      className="default-automation-system required"
                      labelName={t('equipment-setup:DefaultAutomationSystem')}
                      name="defaultAutomationSystem"
                      placeholder={translatedOptionsModal.selectOne}
                      value={
                        propertyDetails?.automationName ||
                        automationList.find(
                          ({ key }) => key === values?.defaultAutomationSystem
                        )?.value
                      }
                      options={automationList}
                      onChange={(
                        defaultAutomationSystem,
                        { values, setValues }
                      ) => {
                        refetchsource({
                          id: null
                        })
                        setDataSourceTypeList([])
                        setdataSourceList([])
                        setPropertyOptionList([])
                        setSomeFieldChanged(true)
                        setIsSaveDisabled(true)
                        setSourceUOMList([])
                        setDimensionList([])
                        setTimeout(() => {
                          propertyDetails['automationName'] =
                            defaultAutomationSystem
                          propertyDetails['sourceEquipmentDataSourceType'] = ''
                          propertyDetails['sourceEquipmentId'] = ''
                          propertyDetails['sourceEquipmentDataSourceName'] = ''
                          propertyDetails['alienSourcePropertyName'] = ''
                          propertyDetails['subPropertyDisplayName'] = null
                          setSelectedAutomationName(defaultAutomationSystem)
                          setValues({
                            ...values,
                            defaultAutomationSystem,
                            dataSourceType: '',
                            dataSourceName: '',
                            propertyName: '',
                            dimensionId: '',
                            sourceUnitOfMeasurementId: ''
                          })
                          handleGetDataSourceTypeList(defaultAutomationSystem)
                        }, 5)
                      }}
                    />
                  </div>
                  {/* Default Data Source Type */}
                  <div>
                    <EditableView
                      type={EditableView.COMPONENT_TYPE.SELECT}
                      className="default-data-source-type required"
                      labelName={t('equipment-setup:DefaultDataSourceType')}
                      name="dataSourceType"
                      placeholder={translatedOptionsModal.selectOne}
                      value={
                        propertyDetails?.sourceEquipmentDataSourceType !==
                        undefined
                          ? propertyDetails?.sourceEquipmentDataSourceType
                          : equipmentDetailsData?.dataSourceType
                      } // equipmentDetailsData?.dataSourceType
                      options={dataSourceTypeList}
                      onChange={(dataSourceType, { values, setValues }) => {
                        propertyDetails['sourceEquipmentId'] = ''
                        propertyDetails['sourceEquipmentDataSourceName'] = ''
                        propertyDetails['alienSourcePropertyName'] = ''
                        propertyDetails['subPropertyDisplayName'] = null
                        setdataSourceList([])
                        setPropertyOptionList([])
                        setSourceUOMList([])
                        setDimensionList([])
                        setSomeFieldChanged(true)
                        setIsSaveDisabled(true)
                        setDataSourceType(dataSourceType)
                        handleGetDataSourceList(
                          values?.defaultAutomationSystem !== undefined
                            ? values?.defaultAutomationSystem
                            : equipmentDetailsData?.device?.name,
                          dataSourceType
                        )
                        setTimeout(() => {
                          propertyDetails['sourceEquipmentDataSourceType'] =
                            dataSourceType
                          setValues({
                            ...values,
                            dataSourceType,
                            dataSourceName: '',
                            propertyName: '',
                            dimensionId: '',
                            sourceUnitOfMeasurementId: ''
                          })
                        }, 5)
                      }}
                    />
                  </div>

                  <div>
                    {/* Name */}
                    <EditableView
                      id="dataSourceName"
                      type={EditableView.COMPONENT_TYPE.SELECT}
                      className="default-automation-system required"
                      labelName={t(
                        'equipment-setup:DataMapping:EditProperty>Name'
                      )}
                      name="dataSourceName"
                      placeholder={translatedOptionsModal.selectOne}
                      value={
                        propertyDetails?.sourceEquipmentDataSourceName !==
                        undefined
                          ? propertyDetails?.sourceEquipmentDataSourceName
                          : equipmentDetailsData?.dataSourceName
                      }
                      options={dataSourceList}
                      onChange={(dataSourceName, { values, setValues }) => {
                        setSomeFieldChanged(true)
                        setIsSaveDisabled(true)
                        setDataSourceName(dataSourceName)
                        const sourceDetails = findItemByDataSource(
                          dataSourceType !== ''
                            ? dataSourceType
                            : propertyDetails?.sourceEquipmentDataSourceType
                                ?.length
                            ? propertyDetails?.sourceEquipmentDataSourceType
                            : equipmentDetailsData?.dataSourceType,
                          dataSourceName != ''
                            ? dataSourceName
                            : propertyDetails?.sourceEquipmentDataSourceName
                        )
                        const id = sourceDetails?.id
                        refetchsource({
                          id
                        })
                        propertyDetails['sourceEquipmentId'] = id
                        id !== equipmentDetailsData?.dataMappingEquipmentId ? setSelectedPropertyId(id) : setSelectedPropertyId('')
                        setSourceUOMList([])
                        setDimensionList([])
                        setSubPropertyOptions([])

                        setTimeout(() => {
                          propertyDetails['sourceEquipmentDataSourceName'] =
                            dataSourceName
                          propertyDetails['subPropertyDisplayName'] = null
                          propertyDetails['sourceMeasureUnit'] = null
                          propertyDetails['sourceMeasureTypeUI'] = null
                          setValues({
                            ...values,
                            dataSourceName,
                            propertyName: '',
                            dimensionId: '',
                            sourceUnitOfMeasurementId: ''
                          })
                        }, 5)
                      }}
                    />
                  </div>
                  <div>
                    {automationDataLoading || data_mapping_source_Loading ||
                    propertyOptionList?.length > 0 ? (
                      <div>
                        {/* Property */}
                        {automationDataLoading || data_mapping_source_Loading ? <StyledSpinner><Spinner  /></StyledSpinner> :
                        <EditableView
                          id="propertyName"
                          className="property required"
                          labelName={"Property"}
                          name="propertyName"
                          placeholder={translatedOptionsModal.selectOne}
                          value={
                            values?.propertyName !== undefined
                              ? values?.propertyName
                              : propertyName
                                ? propertyName
                                : propertyDetails.isConstant
                                  ? ' '
                                  : isEditConstant
                                    ? ''
                              : propertyDetails?.sourceDisplayName
                          }
                          options={propertyOptionList}
                          onChange={(propertyName, { values, setValues }) => {
                            handleSubPropertyList(propertyName)
                            setSourceUOMList([])
                            setDimensionList([])
                            setSomeFieldChanged(true)
                            setPropertyName(propertyName)
                            const selectedPropertyDetails =
                              data_mapping_source?.find(
                                (item) =>
                                  item?.sourceDisplayName === propertyName || item?.sourceKey === propertyName
                              )
                            propertyDetails['sourceMeasureTypeUI'] = selectedPropertyDetails?.sourceMeasureType
                            propertyDetails['sourceMeasureUnit'] =
                              selectedPropertyDetails?.sourceMeasureUnit
                                ? selectedPropertyDetails?.sourceMeasureUnit
                                : null
                            setIsSaveDisabled(false)
                            setDeviceId(
                              selectedPropertyDetails?.sourceDeviceId || ''
                            )
                            setSelectedPropertyDetails(selectedPropertyDetails)
                            if (
                              equipmentDetailsData?.family === ANALOG_POINTS
                            ) {
                              handleDimensionList(propertyName)

                              if (subPropertyOptions?.length)
                                setIsSaveDisabled(true)
                              else setIsSaveDisabled(false)
                            }
                            setTimeout(() => {
                              propertyDetails['alienSourcePropertyName'] =
                                propertyName
                              propertyDetails['value'] = ''
                              propertyDetails['ConevrtedConstantValue'] = ''
                              propertyDetails['subPropertyDisplayName'] = null

                              setValues({
                                ...values,
                                propertyName,
                                dimensionId: propertyDetails?.sourceMeasureType,
                                sourceUnitOfMeasurementId:
                                  propertyDetails?.sourceMeasureUnit,
                                targetUom: propertyDetails?.sourceMeasureUnit
                              })
                            }, 5)
                          }}
                          component={({
                            inputId,
                            onBlur,
                            onFocus,
                            onChange,
                            options,
                            value
                          }) => (
                            <SelectrixWrapper
                              arrow={true}
                              className="selectrix-wrapper--type-autocomplete"
                              customKeys={{ key: 'key', label: 'value' }}
                              defaultValue={value}
                              id={inputId}
                              inputPlaceholder={translatedOptionsModal.findProperty}
                              notFoundPrompt={translatedOptionsModal.noResult}
                              onChange={({ key }) => onChange(key)}
                              onClose={onBlur}
                              onOpen={onFocus}
                              options={options}
                              searchable={true}
                              searchBoxInside={true}
                              searchIndex={false}
                            />
                          )}
                        />
                          }
                      </div>
                    ) : (
                      !automationDataLoading && <div
                        className="edit-view empty-label"
                        style={{ paddingBottom: '18px' }}
                      >
                        <label className="edit-view__label">
                          <span className="edit-view__label-name">
                            {translatedOptionsModal.noApplicableProperties}
                          </span>
                        </label>
                      </div>
                    )}

                    <div>
                      {data_mapping_source?.length ? (
                        <>
                          {/* Sub Property */}
                          {subPropertyOptions?.length ? (
                            <EditableView
                              className="sub-property required"
                              type={EditableView.COMPONENT_TYPE.SELECT}
                              labelName={t(
                                'equipment-setup:DataMapping:EditProperty>SubProperty'
                              )}
                              name="subPropertyDisplayName"
                              placeholder={t(
                                'equipment-setup:DataMapping>NoPropertySelected'
                              )}
                              value={
                                values?.subPropertyDisplayName !== undefined
                                  ? values?.subPropertyDisplayName
                                  : propertyDetails?.subPropertyDisplayName
                              }
                              options={subPropertyOptions}
                              onChange={(
                                subPropertyDisplayName,
                                { values, setValues }
                              ) => {
                                setIsSaveDisabled(false)
                                setTimeout(() => {
                                  propertyDetails['subPropertyDisplayName'] =
                                    subPropertyDisplayName
                                  propertyDetails['value'] = ''
                                  propertyDetails['ConevrtedConstantValue'] = ''
                                  propertyDetails[
                                    'isModifiedSubProperty'
                                  ] = true
                                  const selectedPropertyDetails =
                                  data_mapping_source?.find(
                                    (item) => {
                                      return propertyName
                                      ?  ((item?.sourceDisplayName === propertyName && item?.subPropertyDisplayName === subPropertyDisplayName)
                                      || (item?.sourceKey === propertyName && item?.subPropertyDisplayName === subPropertyDisplayName))
                                      :  ((item?.sourceDisplayName === propertyDetails?.sourceDisplayName?.split("/")[0] && item?.subPropertyDisplayName === subPropertyDisplayName)
                                      || (item?.sourceKey === propertyDetails?.sourceDisplayName?.split("/")[0] && item?.subPropertyDisplayName === subPropertyDisplayName))
                                    }
                                  )
                                  setDeviceId(
                                    selectedPropertyDetails?.sourceDeviceId || ''
                                  )
                                  setSelectedPropertyDetails(selectedPropertyDetails)
                                  setValues({
                                    ...values,
                                    subPropertyDisplayName
                                  })
                                }, 5)
                              }}
                            />
                          ) : (
                            ''
                          )}
                          {equipmentDetailsData?.family === ANALOG_POINTS && (
                            <>
                          {/* Dimension */}
                          <EditableView
                            className="source-uom required"
                            type={EditableView.COMPONENT_TYPE.SELECT}
                            labelName={t(
                              'equipment-setup:CreateEquipment>Dimension'
                            )}
                            name="dimensionId"
                            value={
                              values?.dimensionId?.length
                                ? values?.dimensionId
                                : propertyDetails?.sourceMeasureType
                            }
                            options={dimensionList}
                            placeholder={translatedOptionsModal.none}
                            onChange={(value) => {
                              setSelectedDimension(value)
                              setIsSaveDisabled(true)
                              propertyDetails['sourceMeasureUnit'] = null
                              handleSourceUOMList(propertyName)
                              setTimeout(() => {
                                propertyDetails['sourceMeasureType'] = value
                                setValues({
                                  ...values,
                                  dimensionId: value,
                                  sourceUnitOfMeasurementId: ''
                                })
                              }, 5)
                            }}
                          />
                            {/* Source Unit Of Measure */}
                            <EditableView
                            className="source-uom required"
                            type={EditableView.COMPONENT_TYPE.SELECT}
                            labelName={t(
                              'equipment-setup:DataMapping:EditProperty>SourceUoM'
                            )}
                            name="sourceUnitOfMeasurementId"
                            placeholder={t(
                              'equipment-setup:DataMapping:EditProperty>NoUoMSelected'
                            )}
                            value={
                              values?.sourceUnitOfMeasurementId !== undefined
                                ? values?.sourceUnitOfMeasurementId
                                : propertyDetails?.sourceMeasureUnit
                            }
                            options={sourceUOMList}
                            onChange={(value) => {
                              setIsSaveDisabled(false)
                              setSelectedUOM(value)
                              setTimeout(() => {
                                propertyDetails['sourceMeasureUnit'] = value
                                propertyDetails['targetMeasureUnit'] = value
                                setValues({
                                  ...values,
                                  sourceUnitOfMeasurementId: value
                                })
                              }, 5)
                            }}
                          />
                            </>
                          )
                          }
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* TargetUom */}
                  <EditableView
                    id="targetUom"
                    className="uom-name"
                    disabled
                    labelName="Target UOM"
                    name="targetUom"
                    readOnly
                    type={EditableView.COMPONENT_TYPE.INPUT}
                    value={
                      targetMeasureUOM
                    }
                  />
                  {/* Interval */}
                  <div>
                    <EditableView
                      id="interval"
                      className="interval"
                      type={EditableView.COMPONENT_TYPE.DEFAULT}
                      labelName={translatedOptionsModal.interval}
                      name={translatedOptionsModal.interval}
                      value={'15'}
                      component={({ value, onChange }) => (
                        <>
                          <div style={{ width: '105px' }}>
                            <Select
                              options={INTERVAL_OPTIONS}
                              placeholder={translatedOptionsModal.selectOne}
                              includePlaceholderOption={true}
                              selectedItem={value}
                              onChange={onChange}
                              isDisabled={true}
                            />
                          </div>
                          <LabelDiv>
                           <TranslateComponent>Minutes</TranslateComponent>
                          </LabelDiv>
                        </>
                      )}
                    />
                  </div>
                  {/* Polarity Options */}
                  {(propertyDetails?.targetDataGroup === binary ||
                    propertyDetails?.targetDataGroup === multiState ||
                    propertyDetails?.targetDataGroup === multiState2) && (
                    <div>
                      <RadioGroup
                        label="Polarity"
                        handleSelection={({ target: { value } }) => {
                          propertyDetails['polarityNormal'] = value
                          setValues({ ...values, polarityNormal: value })
                        }}
                        name="polarityNormal"
                        radioKey="polarityNormal"
                        options={getPolarityOptions}
                        selectedValue={propertyDetails['polarityNormal']}
                      />
                    </div>
                  )}
                </>
              ) : (
                // CONSTANT
                <div>
                  <div>
                    <EditableView
                      style={{ 'text-align': 'left' }}
                      id="propertyValue"
                      className={
                        isValueOutOfRange ? 'validation-error' : 'rx-col-auto'
                      }
                      labelName={
                        t('equipment-setup:DataMapping:EditProperty>Value') +
                        '*'
                      }
                      name="propertyValue"
                      type={
                        propertyDetails?.targetDataGroup !== analog
                          ? EditableView.COMPONENT_TYPE.SELECT
                          : EditableView.COMPONENT_TYPE.INPUT
                      }
                      value={
                        !constantValue ? lConstValue : constantValue
                      }
                      options={constantList}
                      inputType={
                        propertyDetails?.targetDataGroup === analog
                          ? EditableView.INPUT_TYPE.NUMBER
                          : ''
                      }
                      onChange={(propertyValue) => {
                        propertyDetails['value'] = propertyValue
                        propertyDetails['ConevrtedConstantValue'] = propertyValue
                        setSomeFieldChanged(true)
                        if (
                          propertyValue < lminValue ||
                          propertyValue > lmaxValue
                        ) {
                          setIsSaveDisabled(true)
                          setIsValueOutOfRange(true)
                        } else {
                          setIsSaveDisabled(false)
                          setIsValueOutOfRange(false)
                        }
                        setConstantValue(propertyValue)
                      }}
                    />
                    {isValueOutOfRange ? (
                      <div
                        style={{
                          marginLeft: '9px',
                          marginTop: '-11px',
                          height: '16px',
                          color: '#d52b1e',
                          fontWeight: '700',
                          fontStyle: 'italic'
                        }}
                      >
                        {t('equipment-setup:EnterTheValueWithinTheRange', {
                          lower: lminValue,
                          upper: lmaxValue
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div style={{ marginTop: '6px' }}>
                    <EditableView
                      id="unitOfMeasurement"
                      className="uom-name"
                      disabled={true}
                      labelName={translatedOptionsModal.uom}
                      name="unitOfMeasurement"
                      readOnly={true}
                      type={EditableView.COMPONENT_TYPE.INPUT}
                      value={
                        propertyDetails?.targetMeasureUnit === 'noUnit' || NA
                          ? NA
                          : uomSymbolPairs[
                              propertyDetails?.targetMeasureUnit
                            ] !== 0
                          ? uomSymbolPairs[propertyDetails?.targetMeasureUnit]
                          : NA
                      }
                    />
                  </div>
                  <div>
                    <EditableView
                      className="interval"
                      type={EditableView.COMPONENT_TYPE.DEFAULT}
                      labelName={translatedOptionsModal.interval}
                      name="interval"
                      value={'15'}
                      component={({ value, onChange }) => (
                        <>
                          <div style={{ width: '105px' }}>
                            <Select
                              options={INTERVAL_OPTIONS}
                              placeholder={translatedOptionsModal.selectOne}
                              includePlaceholderOption={true}
                              selectedItem={value}
                              onChange={onChange}
                              isDisabled={true}
                            />
                          </div>
                          <LabelDiv>
                          <TranslateComponent>Minutes</TranslateComponent>
                          </LabelDiv>
                        </>
                      )}
                    />
                  </div>
                </div>
              )}
              <button hidden type="submit">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </Modal>

      <StandardDialogs confirm={confirmDialog} />
    </>
  )
}

DataMappingEditProperty.propTypes = {
  equipmentDetails: PropTypes.any,
  toggleDialog: PropTypes.func,
  propertyDetails: PropTypes.any,
  handleAlienProperty: PropTypes.any,
  alienDetails: PropTypes.any,
  selectedIndex: PropTypes.any,
  defaultdimensionGroup: PropTypes.any,
  uomSymbolPairs: PropTypes.any
}
