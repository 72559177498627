import { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uuid from 'react-uuid'
import moment from 'moment'
import { useDimensionsHook } from 'src/hooks/dimensionHook'

// Component imports
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import ContainerWrapper from 'src/components/Container'
import { Button } from 'src/components/inputs/button'
import { Block, ConfirmationText, Content } from 'src/components/layouts'
import COLORS from 'src/components/layouts/colors.json'
import { ChartObjectPicker } from 'src/components/object-picker/chartObjectPicker'
import {
  BUILDING,
  EQUIPMENT_TYPE,
  EQUIPMENT,
  PROPERTY
} from 'src/components/object-picker/helpers.js'
import PageHeader from 'src/components/pageHeaderNew/header'

// Legacy components
import {
  BACKEND_DATE_FORMAT,
  TIME_FORMAT_TWELVE_HOUR_1
} from 'src/components/legacy/common/time-helpers'
import Modal from 'src/components/legacy/components/modal/modal'
import Select from 'src/components/legacy/components/select/select'
import Spinner from 'src/components/legacy/components/spinner/spinner'

// Hooks and Redux imports
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { useGetChartData } from 'src/hooks/chartDataHook'
import { selectUserInfo } from 'src/redux/slicers/appData'
import {
  selectPageReduxObject,
  setPageReduxObject
} from 'src/redux/slicers/buildingPicker'

// Constants
import { ACTIONS } from 'src/constants'

// Local imports
import { AddChartToDashboardModal } from './AddToDashboardModal'
import { BuildingDataChart } from './BuildingDataChart'
import { PAGE_TITLE } from './constants'
import { CalculatedPointsList } from './CalculatedPointsList'
import { CalculatedPointsModal } from './CalculatedPointsModal'
import ExpressionUtil from './CalculationPointUtil'
import {
  LIST_CHARTS_BY_ACCOUNT,
  UPDATE_BUILDING_DATA_CHART,
  DELETE_BUILDING_DATA_CHART,
  GET_DASHBOARDS,
  ListBuildingEquipmentsByFilter,
  GET_EQUIPMENT_PROPERTIES_BY_ID
} from './graphql'
import { SaveChartModal } from './SaveChartModal'
import {
  ToolBarWrapper,
  SelectWrapper,
  BuildingDataHeader,
  NewChartText,
  ObjectPickerArea,
  PickerButtonsWrapper,
  ActionIcons,
  CSVLinkDownload,
  ChartContainer,
  LegendLine,
  ChartLegendsContainer,
  ChartObjectPickerArea,
  NoDataMessage
} from './styles'
import {
  groupByPropertykey,
  formatPropertiesReponse,
  formatCalculatedProperties,
  formatMigratedProperties,
  formatMigratedCalcProperties
} from './util'

// Other
import { getRandomColor } from 'src/common/chartHelperFunctions'
import { getSearchParams } from 'src/common/helperFunctions.js'
import translate from 'src/common/translations'
import {
  getUserSettingByUserId,
  createUserSettings,
  updateUserSettings,
  deleteUserSettings
} from 'src/common/userSettingAPI'

export const PREVIEW_BUILDING_DATA_PAGE_NAMES = {
  buildingData: 'building-data',
  dashboard: 'dashboard'
}

export const ChartLegends = ({
  properties = [],
  height,
  pageName = 'building-data'
}) => {
  return (
    <ChartLegendsContainer
      pageName={pageName}
      height={pageName === 'dashboard' ? height : 'auto'}
    >
      {properties?.map((property) => {
        return (
          <Block display="flex" key={property?.propertyNameWithSymbol}>
            <LegendLine color={property?.propertyColor} />
            <div>
              <span>
                <b>{property?.propertyNameWithSymbol}</b>
              </span>
              <span>{property?.otherDetails}</span>
            </div>
          </Block>
        )
      })}
    </ChartLegendsContainer>
  )
}

const BuildingDataContainer = () => {
  const { width } = useBreakPoint()

  const dispatch = useDispatch()

  const optionsTranslated = {
    yes: translate('Yes'),
    no: translate('No'),
    datachart: translate('Data Chart'),
    newdatachart: translate('New Data Chart'),
    createCalculated: translate('CREATE CALCULATED POINT')
  }
  const userInfo = useSelector(selectUserInfo)

  const pageReduxObject = useSelector(selectPageReduxObject)

  const { buildingId, organizationId } = getSearchParams()

  const [isDataAvailable, setIsDataAvailable] = useState(false)

  const [allChartData, setAllChartData] = useState({})

  const [uniqueUOMs, setUniqueUOMs] = useState([])

  const [calculatedPointsUOMs, setCalculatedPointsUOMs] = useState([])

  const [chartDates, setChartDates] = useState({
    chartStartDate: moment().subtract(1, 'months').format(BACKEND_DATE_FORMAT),
    chartEndDate: moment().format(BACKEND_DATE_FORMAT),
    dateError: false,
    activeRange: '1M'
  })

  // Initially it is edit, since we may have to load default chart
  const [mode, setMode] = useState(ACTIONS.EDIT)

  const [availableDataCharts, setAvailableDataCharts] = useState([])
  const [selectedDataChartId, setSelectedDataChartId] = useState(null)
  const [calculatedPointsState, setCalculatedPointsState] = useState([])
  const [showCalculatedPointModal, setShowCalculatedPointModal] =
    useState(false)
  const [showSaveChartModal, setShowSaveChartModal] = useState(false)
  const [showAddDashboardModal, setShowAddDashboardModal] = useState(false)
  const [objectPickerSelection, setObjectPickerSelection] = useState([])
  const [selectedCalculatedPointForEdit, setSelectedCaclulatedPointForEdit] =
    useState(null)
  const [propertiesListForPlot, setPropertiesListForPlot] = useState([])
  const [saveChartMode, setSaveChartMode] = useState(ACTIONS.ADD)
  const [showDeleteChartModal, setShowDeleteChartModal] = useState(false)
  const [successNotification, setSuccessNotification] = useState({})
  const [buildingDataChartData, setBuildingDataChartData] = useState(null)
  const [timeSliderStartTime, setTimeSliderStartTime] = useState(null)
  const [timeSliderEndTime, setTimeSliderEndTime] = useState(null)
  const [defaultChartSettings, setDefaultChartSettings] = useState(null)

  const [hasChartChanges, setChartHasChanges] = useState(false)

  const [reloadingObjectPicker, setReloadingObjectPicker] = useState(false)

  const {
    getChartData,
    getChartDataByTimeSlider,
    chartData,
    chartDataByTimeSlider,
    resetChartData,
    loadingChartData
  } = useGetChartData()

  const resetAllState = async () => {
    setBuildingDataChartData(null)
    setTimeSliderStartTime(null)
    setTimeSliderEndTime(null)
    setAvailableDataCharts([])
    setSelectedDataChartId(null)
    setCalculatedPointsState([])
    setObjectPickerSelection([])
    setPropertiesListForPlot([])
    setUniqueUOMs([])
    setAllChartData({})
    reInitializeObjectPicker()
    resetChartData()
    await getChartsList()
    setChartDates({
      chartStartDate: moment()
        .subtract(1, 'months')
        .format(BACKEND_DATE_FORMAT),
      chartEndDate: moment().format(BACKEND_DATE_FORMAT),
      dateError: false,
      activeRange: '1M'
    })
  }

  const createNewBuildingDataChartHandler = () => {
    resetAllState()
    setObjectPickerSelection([])
    setMode(ACTIONS.ADD)
    reInitializeObjectPicker()
  }

  const { refetch: getBuildingCharts } = useQuery({
    query: LIST_CHARTS_BY_ACCOUNT,
    disableInitialLoad: true,
    dataPath: 'data.listChartsByAccount.items'
  })

  const { data: userDashboards, refetch: getUserDashboards } = useQuery({
    query: GET_DASHBOARDS,
    disableInitialLoad: true,
    variables: { userId: userInfo?.id, limit: 100 },
    dataPath: 'data.listDashboardsByUser.items'
  })

  const { onSubmit: updateBuildingDataChart } = useMutation({
    query: UPDATE_BUILDING_DATA_CHART,
    dataPath: 'data.updateBuildingDataChart'
  })

  const { onSubmit: deleteBuildingDataChart } = useMutation({
    query: DELETE_BUILDING_DATA_CHART,
    dataPath: 'data.deleteBuildingDataChart'
  })

  const {
    refetch: fetchEquipmentsByBuildingId,
    loading: loadingEquipmentsByBuildingId
  } = useQuery({
    query: ListBuildingEquipmentsByFilter,
    dataPath: 'data.getBuilding',
    disableInitialLoad: true
  })

  const getuserDefaultChartByAccountId = async () => {
    const userSettings = await getUserSettingByUserId(
      userInfo?.id,
      'BuildingDataChart',
      organizationId
    )
    if (userSettings?.settings?.id) {
      setSelectedDataChartId(userSettings?.settings?.id)
      setDefaultChartSettings(userSettings)
    } else {
      setDefaultChartSettings(null)
    }
    return userSettings
  }

  const closeSuccessNotification = () => {
    setSuccessNotification({})
  }

  const successModalConfig = useMemo(
    () => ({
      heading: successNotification?.title
        ? successNotification?.title
        : 'Success',
      buttons: [
        {
          text: 'Close',
          handleClick: closeSuccessNotification,
          type: 'cancel'
        }
      ],
      handleClose: closeSuccessNotification
    }),
    [successNotification]
  )

  const getChartsList = async () => {
    try {
      const allChartsList = await getBuildingCharts({
        accountId: organizationId,
        filter: {
          or: [
            {
              sharable: { eq: true }
            },
            {
              userId: { eq: userInfo?.id }
            }
          ]
        }
      })
      setAvailableDataCharts(
        allChartsList.sort((a, b) => a?.name?.localeCompare(b?.name))
      )
    } catch (error) {}
  }

  const calcPointsValuesCalculations = (formula, bindings) => {
    return ExpressionUtil.execute(formula, bindings)
  }

  useEffect(() => {
    if (chartData) {
      // check all any one property has data
      const isDataAvailable = chartData?.data?.some((x) => x?.isDataAvailable)

      if (isDataAvailable) {
        setIsDataAvailable(true)
      } else {
        setIsDataAvailable(false)
        return
      }

      const calculatedPropertiesArray = []
      const calcPoints = calculatedPointsState
      if (calcPoints?.length > 0) {
        calcPoints.forEach((calcFormula) => {
          const properties = calcFormula?.propertiesList
          const chartValues = {}
          properties?.forEach((property) => {
            const propertyIdentifier = `${property?.propertyName}_${property?.identifier}`
            const chartPropertyData =
              chartData?.data?.find(
                (x) =>
                  x.equipmentId === property?.equipmentId &&
                  x?.propertyKey === property?.propertyName
              )?.values?.[0] || []
            chartValues[propertyIdentifier] = chartPropertyData?.map((x) => {
              return { value: x?.value, timestamp: x?.timeStamp }
            })
          })
          const values = calcPointsValuesCalculations(
            calcFormula?.formula,
            chartValues
          )
          const valuesArray = values === false ? [[0]] : [values]
          calculatedPropertiesArray.push({
            equipmentId: calcFormula?.propertiesList?.[0]?.equipmentId,
            propertyKey: calcFormula?.name,
            propertyName: calcFormula?.name,
            values: valuesArray
          })
        })
      }

      const finalDataArray = []
      finalDataArray?.push(...calculatedPropertiesArray)

      allSelectedPropertiesForPlot
        ?.filter((x) => !x.calcProperty)
        ?.forEach((properties) => {
          const dataArray = chartData?.data?.find(
            (x) =>
              properties?.equipmentId === x.equipmentId &&
              properties.propertyKey === x?.propertyKey
          )
          if (dataArray) {
            finalDataArray?.push(dataArray)
          }
        })

      chartData.data = finalDataArray

      setAllChartData(chartData)
      setBuildingDataChartData(chartData)
    }
  }, [chartData])

  useEffect(() => {
    if (!chartDates?.dateError) {
      plotChart()
    }
  }, [chartDates?.chartStartDate, chartDates?.chartEndDate, chartDates?.error])

  useEffect(() => {
    plotChart()
  }, [calculatedPointsState])

  useEffect(() => {
    if (
      selectedDataChartId === null &&
      mode !== ACTIONS.ADD &&
      availableDataCharts?.length > 0
    ) {
      let selectedChartId

      if (
        Object.keys(pageReduxObject)?.length > 0 &&
        pageReduxObject?.chartId
      ) {
        selectedChartId = pageReduxObject?.chartId
        // once get the chart id from redux, make it null
        dispatch(
          setPageReduxObject({
            type: 'Success',
            chartId: null
          })
        )
      }
      if (selectedChartId) {
        setMode(ACTIONS.EDIT)
        setSelectedDataChartId(selectedChartId)
      }
    }
  }, [availableDataCharts])

  const reInitializeObjectPicker = () => {
    // This is a work around to load data for edit
    // Have to revisit again in chart picker to make it work properly
    setReloadingObjectPicker(true)
    setTimeout(() => {
      setReloadingObjectPicker(false)
    }, 100)
  }

    const {
      refetch: getPropertyMappingByEquipmentID,
      loading: loadingCalculatedProperties
    } = useQuery({
      query: GET_EQUIPMENT_PROPERTIES_BY_ID,
      dataPath: 'data.getPropertyMappingByEquipmentID.items',
      disableInitialLoad: true
    })

      const { loadingDimensions, allDimensions, getAllDimensions } = useDimensionsHook()

      useEffect(() => {
        getAllDimensions()
      }, [])

  const setSelectedChartProperties = async () => {
    try {
      const chartDetails = selectedChartDetails
      const parsedResponse = JSON.parse(chartDetails?.properties)
      const propertiesList = formatPropertiesReponse(parsedResponse?.properties)
      const updatePropertiesList = await Promise.all(
        propertiesList?.map(async (property) => {
          const response = await getPropertyMappingByEquipmentID({
            equipmentId: property?.equipmentId
          })
          let UoM = allDimensions?.find(
            (x) =>
              x.UoM ===
              (response?.[0]?.targetKey === 'PresentValue'
                ? response?.[0]?.sourceMeasureUnit
                : response?.[0]?.targetMeasureUnit)
          )
          return {
            ...property,
            symbol: UoM?.Symbol || property?.symbol,
            uom: UoM?.UoM || property?.uom
          }
        })
      )
      
      const propertiesWithEquipmentNames = await formatMigratedProperties(
        updatePropertiesList,
        fetchEquipmentsByBuildingId
      )
      const calcPropertiesList = formatCalculatedProperties(
        parsedResponse?.calculatedProperties
      )
      const calcPropWithEquipmentNames = await formatMigratedCalcProperties(
        calcPropertiesList,
        fetchEquipmentsByBuildingId
      )
      setCalculatedPointsState(calcPropWithEquipmentNames || [])
      const formatResponse = formatPickerDataToChartRequest(
        propertiesWithEquipmentNames || [],
        calcPropertiesList || []
      )
      setObjectPickerSelection(propertiesWithEquipmentNames || [])
      setPropertiesListForPlot(formatResponse)

      reInitializeObjectPicker()
    } catch (error) {}
  }

  const setSelectedUniqueUOMsToParent = (objectPikcerUOMs) => {
    // not called for Calc points chart picker
    const uoms = [...objectPikcerUOMs]
    const uniqueUnits = [...new Set(uoms)]
    setUniqueUOMs(uniqueUnits)
  }

  useEffect(() => {
    if (selectedDataChartId) {
      setSelectedChartProperties(selectedDataChartId)
    }
  }, [selectedDataChartId])

  useEffect(() => {
    if (propertiesListForPlot?.length > 0) {
      const chartDataDetails = propertiesListForPlot

      const chartDataRequest = {
        startDate: chartDates?.chartStartDate,
        endDate: chartDates?.chartEndDate,
        category: 'Equipment', // equipment or building level data needed
        chartType: 'Line',
        chartDetails: chartDataDetails
      }
      getChartData(chartDataRequest)
    }
  }, [propertiesListForPlot])

  useEffect(() => {
    getUserDashboards()
  }, [])

  const initializeChartListData = async () => {
    await resetAllState()
    await getuserDefaultChartByAccountId(organizationId)
    getChartsList()
  }

  useEffect(() => {
    if (organizationId || buildingId) {
      initializeChartListData()
    }
  }, [organizationId, buildingId])

  useEffect(() => {
    const startTime = new Date(timeSliderStartTime).getTime()
    const endTime = new Date(timeSliderEndTime).getTime()

    const adjustedEndTime = new Date(endTime)
    adjustedEndTime.setDate(adjustedEndTime.getDate() + 1)
    
    getChartDataByTimeSlider(startTime, adjustedEndTime.getTime())
  }, [timeSliderStartTime, timeSliderEndTime])

  useEffect(() => {
    if (chartDataByTimeSlider?.data?.length > 0)
      setBuildingDataChartData(chartDataByTimeSlider)
  }, [chartDataByTimeSlider])

  const handleDataChartChange = (selectedChartId) => {
    setMode(ACTIONS.EDIT)
    setSelectedDataChartId(selectedChartId)
  }

  const getDataChartOptions = () => {
    try {
      let defaultChartId = ''
      if (defaultChartSettings) {
        defaultChartId = defaultChartSettings?.settings?.id || ''
      }
      return availableDataCharts?.map((x) => ({
        key: x.id,
        value: x.name,
        itemEndIcon:
          defaultChartId !== '' && x?.id === defaultChartId
            ? 'icon-favorite'
            : null
      }))
    } catch (e) {
      console.log(e)
    }
  }

  const handleCalculatedPointEdit = (id) => {
    try {
      const selectedPoint = calculatedPointsState?.find((x) => x?.id === id)
      setSelectedCaclulatedPointForEdit(selectedPoint)
      toggleCalculatedPointsModal()
    } catch (error) {}
  }

  const deleteCalculatedPoint = () => {
    const newCalculatedPoints = calculatedPointsState.filter(
      (x) => x.id !== selectedCalculatedPointForEdit?.id
    )
    setCalculatedPointsState(newCalculatedPoints || [])
    plotChart()
  }

  const allSelectedPropertiesForPlot = useMemo(() => {
    try {
      const selectedProperties = objectPickerSelection
      const allPropertiesList = []

      selectedProperties?.forEach((property) => {
        allPropertiesList.push({
          ...property,
          propertyNameWithSymbol: `${property?.propertyName}${
            property?.symbol ? ' (' + property?.symbol + ')' : ''
          }`,
          otherDetails: ` in ${property?.equipmentName} (${property?.buildingName})`
        })
      })

      calculatedPointsState?.forEach((calcPoints) => {
        const propertiesList = calcPoints?.propertiesList || []
        const uom =
          calcPoints?.uom === 'custom' ? calcPoints?.customUOM : calcPoints.uom
        const property = propertiesList?.[0] || {}
        allPropertiesList.push({
          ...property,
          color: calcPoints?.color,
          propertyKey: calcPoints?.name,
          name: calcPoints?.name,
          calcProperty: true,
          symbol: uom,
          propertyNameWithSymbol: `${calcPoints?.name}(${uom || ''})`,
          otherDetails: ``
        })
      })

      return allPropertiesList
    } catch (error) {
      return []
    }
  }, [propertiesListForPlot])

 

  const formatPickerDataToChartRequest = (
    objectPickerSelection,
    calcProperties
  ) => {
    try {
      const calculatedpropertiesList = []
      calcProperties?.map((calcPoint) => {
        const properties = calcPoint.propertiesList?.map((x) => {
          return {
            ...x,
            propertyKey: x?.propertyName, 
            equipmentType: x?.equipmentType || x?.equipmentFamily
          }
        })
        calculatedpropertiesList.push(...properties)
      })
      const objectPickerProperties = [
        ...objectPickerSelection,
        ...calculatedpropertiesList
      ]
      const chartFormatDetails = []
      const propertiesByBuildings = groupByPropertykey(
        objectPickerProperties,
        'buildingId'
      )

      propertiesByBuildings.forEach((building) => {
        const equipmentDetails = []

        // select properties of equipments from this building 
        const buildingEquipmentProperties = objectPickerProperties?.filter(x => x?.buildingId === building?.buildingId)
        
        const groupByEquipmentId = groupByPropertykey(
          buildingEquipmentProperties || [],
          'equipmentId'
        )
        groupByEquipmentId?.forEach((equipment) => {
          const propertiesByEquipment = equipment?.properties
          propertiesByEquipment?.length > 0 &&
            propertiesByEquipment?.[0].equipmentType &&
            equipmentDetails.push({
              type: propertiesByEquipment?.[0].equipmentType,
              equipmentName: propertiesByEquipment?.[0]?.equipmentName,
              eId: [{ id: propertiesByEquipment?.[0].equipmentId }],
              pId: [...propertiesByEquipment?.map((x) => x?.propertyKey)]
            })
        })


        chartFormatDetails.push({
          buildingId: building?.buildingId,
          equipmentDetails: equipmentDetails
        })
      })
      return chartFormatDetails
    } catch (error) {
      return []
    }
  }

  const plotChart = () => {
    setAllChartData({})
    if (
      (objectPickerSelection && objectPickerSelection.length > 0) ||
      (calculatedPointsState && calculatedPointsState?.length > 0)
    ) {
      const formatChartPickerData = formatPickerDataToChartRequest(
        objectPickerSelection || [],
        calculatedPointsState || []
      )
      setPropertiesListForPlot(formatChartPickerData)
    } else {
      setPropertiesListForPlot([])
    }
  }

  const refreshChartList = (lastUsedChartId) => {
    getChartsList()
    setMode(ACTIONS.EDIT)
    setSelectedDataChartId(lastUsedChartId)
  }

  const handleObjectPickerChange = (selectedItem) => {
    setChartHasChanges(false)
    setObjectPickerSelection(selectedItem)
  }

  const toggleCalculatedPointsModal = () => {
    if (showCalculatedPointModal) {
      // close the modal now
      setSelectedCaclulatedPointForEdit(null)
    }
    setShowCalculatedPointModal(!showCalculatedPointModal)
  }

  const setOrRemoveDefaultHandler = async (newlyCreatedChartId) => {
    try {
      let chartId =
        typeof newlyCreatedChartId === 'string'
          ? newlyCreatedChartId
          : selectedChartDetails?.id
      const settings = {
        id: chartId
      }
      if (defaultChartSettings) {
        if (isDefaultChartSelected) {
          await deleteUserSettings(defaultChartSettings?.id)
          setDefaultChartSettings(null)
        } else {
          await updateUserSettings(defaultChartSettings?.id, settings)
        }
      } else {
        await createUserSettings(
          'BuildingDataChart',
          userInfo?.id,
          settings,
          organizationId
        )
      }
      await getuserDefaultChartByAccountId(selectedDataChartId)
    } catch (error) {}
  }

  const toggleDeleteChartModal = () => {
    setShowDeleteChartModal(!showDeleteChartModal)
  }

  const closeDeleteChartModal = () => {
    setShowDeleteChartModal(false)
  }

  const deleteChartHandler = async () => {
    try {
      const input = {
        id: selectedDataChartId
      }
      const deletedBuildingDataChart = await deleteBuildingDataChart({ input })
      // delete default chart if the deleted one is default
      isDefaultChartSelected &&
        defaultChartSettings?.id &&
        (await deleteUserSettings(defaultChartSettings?.id))
      setShowDeleteChartModal(false)
      initializeChartListData()
      if (deletedBuildingDataChart?.id) {
        setSuccessNotification({
          type: 'DeleteSuccess',
          message: `You have sucessfully deleted your basic data chart.`,
          title: 'Success'
        })
      }
    } catch (error) {}
  }

  const selectedChartDetails = useMemo(() => {
    return availableDataCharts?.find((x) => x.id === selectedDataChartId)
  }, [selectedDataChartId, availableDataCharts])

  // selected chart item and default chart is equal
  const isDefaultChartSelected = useMemo(() => {
    return (
      defaultChartSettings?.settings?.id &&
      selectedChartDetails?.id === defaultChartSettings?.settings?.id
    )
  }, [defaultChartSettings, selectedChartDetails])

  const deleteChartModalConfig = useMemo(
    () => ({
      heading: selectedChartDetails?.name,
      buttons: [
        {
          text: optionsTranslated.yes,
          handleClick: deleteChartHandler,
          type: 'valid'
        },
        {
          text: optionsTranslated.no,
          handleClick: closeDeleteChartModal,
          type: 'cancel'
        }
      ],
      handleClose: closeDeleteChartModal
    }),
    [selectedChartDetails]
  )

  const toggleSaveChartModal = () => {
    setShowSaveChartModal(!showSaveChartModal)
  }

  const toggleAddDashboardModal = () => {
    setShowAddDashboardModal(!showAddDashboardModal)
  }

  const editBuildingDataChartHandler = () => {
    setSaveChartMode(ACTIONS.EDIT)
    toggleSaveChartModal()
  }

  const addNewBuildingDataChartHandler = () => {
    setSaveChartMode(ACTIONS.ADD)
    toggleSaveChartModal()
  }

  const getEquipmentName = (equipmentId) => {
    try {
      let equipmentName
      propertiesListForPlot?.forEach((building) => {
        const equipmentDetails = building?.equipmentDetails
        equipmentDetails.forEach((equipment) => {
          if (equipment?.eId?.[0]?.id === equipmentId) {
            equipmentName = equipment?.equipmentName || ''
          }
        })
      })
      return equipmentName || ''
    } catch (error) {
      return ''
    }
  }

  const getCSVColumnNames = () => {
    let columns = ['Date', 'Time']

    buildingDataChartData?.data?.map((x) => {
      let columnName = `${x.propertyName} in ${getEquipmentName(x.equipmentId)}`
      columns.push(columnName)
    })
    return columns
  }

  const getCSVColumnData = () => {
    let data = []
    buildingDataChartData?.timeStamps?.map((timeStamp, timeStampIndex) => {
      const singleRow = []
      singleRow.push(moment(timeStamp).format(BACKEND_DATE_FORMAT))
      singleRow.push(moment(timeStamp).format(TIME_FORMAT_TWELVE_HOUR_1))
      buildingDataChartData?.data?.map((x, index) => {
        singleRow.push(x.values[0][timeStampIndex]?.value)
      })
      data.push(singleRow)
    })
    return data
  }

  const formatChartDataToPicker = () => {
    return objectPickerSelection
  }

  const legendHeight = useMemo(() => {
    try {
      // This method dynamically finds space required for Legends
      // We create a container and append legends, find height and removes container
      let occupancyHeight = 10
      if (allSelectedPropertiesForPlot?.length > 0) {
        const containerWidth = width - 40 // Chart object picker width

        const legendsContainer = document.createElement('div')
        legendsContainer.style.visibility = 'hidden'
        legendsContainer.style.fontSize = '14px'
        legendsContainer.style.display = 'flex'
        legendsContainer.style.flexWrap = 'wrap'
        legendsContainer.style.width = `${containerWidth}px`
        document.body.appendChild(legendsContainer)
        for (let i = 0; i < allSelectedPropertiesForPlot?.length; i++) {
          const spanElem = document.createElement('span')
          spanElem.innerHTML = `${allSelectedPropertiesForPlot[i]?.propertyNameWithSymbol}${allSelectedPropertiesForPlot[i]?.otherDetails}`
          legendsContainer.appendChild(spanElem)
        }
        let rect = legendsContainer.getBoundingClientRect()
        document.body.removeChild(legendsContainer)
        occupancyHeight = rect?.height || 10
      }
      // margin 20, padding 20 added
      // Here some numbers are based on trail and error only
      return occupancyHeight + 27 * allSelectedPropertiesForPlot?.length // some buffer
    } catch (error) {
      return 0
    }
  }, [allSelectedPropertiesForPlot])

  const getCSVDataForExport = useCallback(() => {
    return [getCSVColumnNames(), ...getCSVColumnData()]
  }, [buildingDataChartData])

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError,
    activeRange
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError,
      activeRange
    })
  }

  const createOrUpdateCalculatedPoints = (formValues) => {
    if (selectedCalculatedPointForEdit) {
      // Add update existing points
      const existingCalculatedPoints = [...calculatedPointsState]
      const index = existingCalculatedPoints.findIndex(
        (x) => x.id === selectedCalculatedPointForEdit?.id
      )
      existingCalculatedPoints[index] = { ...formValues }
      setCalculatedPointsState(existingCalculatedPoints)
    } else {
      // Add new point
      const existingCalculatedPoints = [...calculatedPointsState]
      const newCalculatedPoints = [
        { ...formValues, id: uuid(), color: getRandomColor() }
      ]
      setCalculatedPointsState([
        ...existingCalculatedPoints,
        ...newCalculatedPoints
      ])
    }
    setSelectedCaclulatedPointForEdit(null)
    // Whenever there is a change in Calc props, update unique UOMs so that it will be available
    // in both object picker - calc one and building chart one
    const uom =
      formValues?.uom === 'custom'
        ? formValues?.customUOM
        : formValues.uom?.replace(' / sq ft', '')?.replace(' / sq m', '')

    setCalculatedPointsUOMs(new Set([...calculatedPointsUOMs, uom]))
    // setUniqueUOMs([...new Set(currentUOMs)])
    setChartHasChanges(false)
    toggleCalculatedPointsModal()
  }

  const chartHeight = screen.availHeight - 420

  const getCalcPointsHeight = () => {
    if (calculatedPointsState?.length > 0) {
      return calculatedPointsState?.length * 23 + 25 + 70
    } else {
      return 70
    }
  }

  const selectedItemEndIcon = isDefaultChartSelected ? 'icon-favorite' : null

  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        {' '}
        <PageHeader
          pageTitle={
            <div>
              {selectedDataChartId
                ? availableDataCharts.find((x) => x.id === selectedDataChartId)
                    ?.name
                : PAGE_TITLE}
            </div>
          }
          titleTools={
            <ToolBarWrapper>
              <BuildingDataHeader className="building-data-header">
                <SelectWrapper width={width}>
                  <label>{optionsTranslated.datachart}</label>
                  <Select
                    options={getDataChartOptions()}
                    selectedItemEndIcon={selectedItemEndIcon}
                    placeholder="--"
                    className="dropdown-select"
                    selectedItem={selectedChartDetails?.name}
                    onChange={handleDataChartChange}
                  />
                </SelectWrapper>
                <NewChartText onClick={createNewBuildingDataChartHandler}>
                  {optionsTranslated.newdatachart}
                </NewChartText>
              </BuildingDataHeader>
              <Button
                disabled={propertiesListForPlot.length <= 0}
                onClick={
                  selectedDataChartId && hasChartChanges
                    ? editBuildingDataChartHandler
                    : addNewBuildingDataChartHandler
                }
                testName="buildingDataSave"
              >
                {selectedDataChartId && hasChartChanges
                  ? 'Save Chart changes'
                  : 'Save As New Chart'}
              </Button>
              {selectedDataChartId && (
                <>
                  {userDashboards &&
                    userDashboards?.length > 0 &&
                    selectedDataChartId && (
                      <ActionIcons
                        title="Add Chart to Dashboard"
                        className="action-icons icon icon-add-to-dashboard tooltip-container"
                        onClick={toggleAddDashboardModal}
                      />
                    )}

                  <ActionIcons
                    title={
                      isDefaultChartSelected
                        ? 'Remove as Default'
                        : 'Set as Default'
                    }
                    isSelected={isDefaultChartSelected}
                    className="icon icon-favorite tooltip-container"
                    onClick={setOrRemoveDefaultHandler}
                  />
                  <ActionIcons
                    title="Edit chart"
                    className="icon icon-edit tooltip-container"
                    onClick={editBuildingDataChartHandler}
                  />
                  <ActionIcons
                    title="Delete chart"
                    className="icon icon-trash tooltip-container"
                    onClick={toggleDeleteChartModal}
                  />
                </>
              )}
              {chartData && Object.keys(allChartData)?.length > 0 && (
                <CSVLinkDownload
                  data={getCSVDataForExport()}
                  filename={`${
                    availableDataCharts?.find(
                      (x) => x?.id === selectedDataChartId
                    )?.name || ''
                  } ${moment(chartDates?.chartStartDate).format(
                    BACKEND_DATE_FORMAT
                  )} - ${moment(chartDates?.chartEndDate).format(
                    BACKEND_DATE_FORMAT
                  )}.csv`}
                  className="csv-link"
                  sheetName="sample-sheet"
                >
                  <ActionIcons
                    title="Export as CSV"
                    className="icon icon-download tooltip-container"
                  />
                </CSVLinkDownload>
              )}
            </ToolBarWrapper>
          }
        />
      </Content>
      <Content
        xlColumn={2}
        lColumn={4}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
        //background={COLORS?.WHITE}
      >
        <ObjectPickerArea height={screen.availHeight - 400}>
          <ChartObjectPickerArea
            calcPointsHeight={getCalcPointsHeight()}
            className="object-picker-area-1"
          >
            {reloadingObjectPicker ? (
              <Spinner />
            ) : (
              <ChartObjectPicker
                objects={['', BUILDING, EQUIPMENT_TYPE, EQUIPMENT, PROPERTY]}
                initialValues={formatChartDataToPicker()}
                update={handleObjectPickerChange}
                equipmentSelectLimit={1}
                setSelectedUniqueUOMsToParent={setSelectedUniqueUOMsToParent}
                availableUniqueUOMs={calculatedPointsUOMs}
                error={false}
                claims={['BuildingDataChartAdmin']}
                mode={mode}
                propertyUOMLimit={4}
              />
            )}

            {calculatedPointsState.length > 0 && (
              <CalculatedPointsList
                pointsList={calculatedPointsState}
                handleCalculatedPointEdit={handleCalculatedPointEdit}
              />
            )}
            <PickerButtonsWrapper>
              <Button
                onClick={toggleCalculatedPointsModal}
                testName="calculate"
              >
                {optionsTranslated.createCalculated}
              </Button>
              <Button
                onClick={plotChart}
                disabled={chartDates?.dateError}
                testName={`${
                  objectPickerSelection.length > 0 ||
                  Object.keys(allChartData)?.length > 0 ||
                  calculatedPointsState?.length > 0
                    ? 'plot'
                    : 'disabled'
                }`}
              >
                PLOT
              </Button>
            </PickerButtonsWrapper>
          </ChartObjectPickerArea>
        </ObjectPickerArea>
      </Content>
      <Content
        xlColumn={10}
        lColumn={8}
        mColumn={12}
        sColumn={12}
        border="1px solid darkgray"
        background={COLORS?.WHITE}
        padding="0px 10px 10px 35px"
        width={width}
      >
        {loadingChartData || loadingEquipmentsByBuildingId ? (
          <Spinner />
        ) : propertiesListForPlot?.length > 0 ? (
          <>
            <ChartContainer>
              <ChartDateControls
                onUpdate={onChartControlUpdate}
                startDate={chartDates?.chartStartDate}
                endDate={chartDates?.chartEndDate}
                activeRange={chartDates?.activeRange}
                startDisableAfterGivenDate={chartDates?.chartEndDate}
                endDisableBeforeGivenDate={chartDates?.chartStartDate}
                rangesList={['1W', '1M', '3M', '6M', 'YTD', '1Y']}
              />
              {isDataAvailable ? (
                <div style={{ flex: 1 }}>
                  <BuildingDataChart
                    propertiesList={allSelectedPropertiesForPlot}
                    chartData={buildingDataChartData}
                    chartDates={chartDates}
                    timeSliderChartData={allChartData}
                    height={chartHeight}
                    width={width - 340} // 340px legend width, to avoid x-axis overlapping
                    loadingChartData={loadingChartData}
                    setTimeSliderStartTime={setTimeSliderStartTime}
                    setTimeSliderEndTime={setTimeSliderEndTime}
                  />
                </div>
              ) : (
                <NoDataMessage>
                  No data available in the selected date range.
                </NoDataMessage>
              )}
            </ChartContainer>
            {/* <ChartLegends properties={allSelectedPropertiesForPlot} /> */}
          </>
        ) : (
          <h2>
            Select data sources and then click Plot to view the data on a chart.
          </h2>
        )}
      </Content>
      {showCalculatedPointModal && (
        <CalculatedPointsModal
          selectedCalculatedPoint={selectedCalculatedPointForEdit}
          toggleCalculatedPointsModal={toggleCalculatedPointsModal}
          createOrUpdateCalculatedPoints={createOrUpdateCalculatedPoints}
          deleteCalculatedPoint={deleteCalculatedPoint}
          availableUniqueUOMs={uniqueUOMs}
          showCalculatedPointModal={showCalculatedPointModal}
        />
      )}

      {showSaveChartModal && (
        <SaveChartModal
          refreshChartList={refreshChartList}
          objectPickerSelection={objectPickerSelection}
          calculatedPointsList={calculatedPointsState}
          toggleSaveChartModal={toggleSaveChartModal}
          chartDetails={selectedChartDetails}
          availableCharts={availableDataCharts}
          setSuccessNotification={setSuccessNotification}
          mode={saveChartMode}
          isDefaultChartSelected={isDefaultChartSelected}
          setOrRemoveDefaultHandler={setOrRemoveDefaultHandler}
          accountId={organizationId}
        />
      )}
      {showAddDashboardModal && (
        <AddChartToDashboardModal
          userDashboards={userDashboards}
          toggleAddDashboardModal={toggleAddDashboardModal}
          chartDetails={selectedChartDetails}
          accountId={organizationId}
          userId={userInfo?.ID}
          setSuccessNotification={setSuccessNotification}
        />
      )}
      {showDeleteChartModal && (
        <Modal {...deleteChartModalConfig}>
          <ConfirmationText>
            <p>
              {`Are you sure you want to delete ${selectedChartDetails?.name}?`}{' '}
              <br></br>
              {selectedChartDetails?.defaultChart &&
                `Warning: Since this chart is default, this action will remove this chart.`}{' '}
              <br></br>
              Note: Deleting a chart will also delete any widgets that were made
              from this chart.
            </p>
          </ConfirmationText>
        </Modal>
      )}
      {successNotification?.type !== undefined && (
        <Modal {...successModalConfig}>
          <ConfirmationText>{successNotification?.message}</ConfirmationText>
        </Modal>
      )}
    </ContainerWrapper>
  )
}

export default BuildingDataContainer
