import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DialogPolyfill from 'dialog-polyfill'
import RequireRole from '../decorators/require-role'
import { ConditionalTooltip } from '../tooltip/conditional-tooltip'
import 'dialog-polyfill/dialog-polyfill.css'
import '../dialog/dialog.scss'
import './modal.scss'
import i18next from 'i18next'
import { TranslateComponent } from '../../../../common/translations'

class Modal extends PureComponent {
  static propTypes = {
    animationType: PropTypes.string,
    buttons: PropTypes.array,
    handleClose: PropTypes.func,
    heading: PropTypes.string,
    children: PropTypes.node,
    modal: PropTypes.bool,
    removeFooter: PropTypes.bool,
    error: PropTypes.bool,
    gray: PropTypes.bool,
    formHasRequiredFields: PropTypes.bool,
    formIsErrored: PropTypes.bool,
    loading: PropTypes.bool,
    autoClose: PropTypes.number,
    footer: PropTypes.element,
    className: PropTypes.string,
    eventNames: PropTypes.arrayOf(
      PropTypes.shape({
        eventName: PropTypes.string,
        eventCode: PropTypes.number
      })
    ),
    errorMessage: PropTypes.string,
    headingIcon: PropTypes.string,
    testName: PropTypes.string,
    isHideWhiteBackground: PropTypes.bool,
    isColorPickerModal: PropTypes.bool,
    customHeaders : PropTypes.string,
    isHeaderTranslate: PropTypes.bool
  }

  static defaultProps = {
    animationType: 'slide',
    buttons: [],
    modal: true,
    error: false,
    gray: false,
    formHasRequiredFields: false,
    formIsErrored: false,
    loading: false,
    removeFooter: false,
    autoClose: 0,
    className: '',
    eventNames: [{ eventName: 'keyup', eventCode: 27 }],
    errorMessage: i18next.t('errors:correct-errors-above'),
    headingIcon: '',
    isHideWhiteBackground: false,
    isColorPickerModal: false,
    testName: "",
    customHeaders : "",
    isHeaderTranslate: true
  }

  constructor(props) {
    super(props)
    this.el = document.createElement('dialog')
    this.el.className = `${
      this.props.modal ? `modal ${this.props.className}` : ''
    } ${props.animationType}`
    this.escapeClose = this.escapeClose.bind(this)
    this.anchor = document.body
  }

  componentDidMount = () => {
    this.props.eventNames.forEach((elem) => {
      window.addEventListener(elem.eventName, this.escapeClose)
    })

    this.anchor.appendChild(this.el)
    if (typeof jest !== 'undefined') {
      delete window.HTMLDialogElement
    }
    // force showModal method from polyfill to work in Chrome also,
    // because current mix of css and native method has some problems
    if(!!document.querySelector('[data-amplify-theme="tc-theme"]')) {
			this.el.showModal = undefined
		}
    DialogPolyfill.registerDialog(this.el)
    if (this.props.modal) {
      this.el.showModal()
    } else {
      this.el.show()
    }
    if (this.props.autoClose > 0) {
      this.autoClose = setTimeout(
        this.props.handleClose,
        parseInt(this.props.autoClose)
      )
    }

    // click outside of modal to handle close
    this.el.addEventListener('click', (e) => {
      if (e.target === this.el) {
        this.props?.handleClose(e)
      }
    })
  }

  componentWillUnmount = () => {
    this.props.eventNames.forEach((elem) => {
      window.removeEventListener(elem.eventName, this.escapeClose)
    })

    if (this.props.modal) {
      this.el.addEventListener('animationend', () =>
        this.anchor.removeChild(this.el)
      )
      this.el.classList.add('hide')
    } else {
      this.anchor.removeChild(this.el)
    }
  }

  escapeClose = (e) => {
    const modal = this.refs.modal
    if (e.which === 27) {
      this.props.handleClose(e)
    } else if (
      this.props.eventNames.filter((elem) => elem.eventCode == e.which)
        .length &&
      !modal.contains(e.target)
    ) {
      this.props.handleClose(e)
    }
  }

  render() {
    const {
      handleClose,
      heading,
      children,
      buttons,
      errorMessage = i18next.t('errors:correct-errors-above'),
      gray,
      error,
      removeFooter,
      loading,
      footer,
      formHasRequiredFields,
      formIsErrored,
      headingIcon,
      testName,
      isWideModal = false,
      isHideWhiteBackground = false,
      isColorPickerModal = false,
      isHeaderTranslate = true,
    } = this.props

    return ReactDOM.createPortal(
      <div
        data-testid={`${testName}-modal-container`}
        className={classNames(
          `${
            isWideModal
              ? 'modal-container wide-modal center'
              : 'modal-container'
          }`,
          {
            'is-gray': gray,
            'has-error': error
          },
          `${isColorPickerModal ? 'color-picker-modal' : ''
          }`
        )}
        ref="modal"
      >
        {/* Below code should be enabled while loader will enable */}
        {/* {loading
						&& <div className="working page-loading-area"><span className="spinner" /></div>
					} */}
        <span
          onClick={() => {
            clearTimeout(this.autoClose)
            handleClose()
          }}
          className="close-button icon icon-close"
          role="button"
          name="closeButton"
          data-testid={`${testName}_closeIconbutton`}
          aria-label={i18next.t('components:modal>CloseDialog')}
        />
       {isHeaderTranslate === true  ? <div className="modal-header" role="header">
          {headingIcon && <span className={`icon icon-${headingIcon}`} />}
          {this.props.customHeaders ? <><TranslateComponent>{heading}</TranslateComponent>{" "}{this.props.customHeaders}</> : <TranslateComponent>{heading}</TranslateComponent>}
        </div>
      :
      <div className="modal-header" role="header">
          {headingIcon && <span className={`icon icon-${headingIcon}`} />}
          {heading === "Connecting" ? <><TranslateComponent>{heading}</TranslateComponent>...</> : heading}
        </div>  
      }
        <div className={classNames('text modal-content', { error })}>
          <div className={`${isHideWhiteBackground?'modal-panel-no-background':'modal-panel'}`}>{children}</div>
        </div>
        {
          !removeFooter && <div className="modal-button-row">
          {footer || (
            <React.Fragment>
              {formHasRequiredFields && (
                <label className="tip">
                  *<TranslateComponent>required fields</TranslateComponent>
                </label>
              )}
              {formIsErrored && (
                <div className="error-message">
                  <span className="icon icon-error" />
                  <span className="error-text"><TranslateComponent>{errorMessage}</TranslateComponent></span>
                </div>
              )}
              {buttons.length > 0 &&
                buttons.map((button) => {
                  if ((button?.isHideConfirmButton ?? false) || (button?.isHideCancelButton ?? false)) return
                  const className = button.className
                    ? ` ${button.className}`
                    : ''
                  let buttonElement = (
                    <button
                      key={button.text}
                      className={`modal-button type-${
                        button.type || 'confirm'
                      }${className}`}
                      onClick={(e) => {
                        clearTimeout(this.autoClose)
                        button.handleClick(e)
                      }}
                      disabled={button.disabled}
                      type={button.buttonType || 'button'}
                      data-testid={`${button.testName || testName}_${button.text}_button`}
                    >
                      {/* {button.disabled ? <div className="not-active" /> : button.text} */}
                   {typeof button.text === 'object' ? <>{button.text}</>  : <TranslateComponent>{button.text === "Cancel" ? "CANCEL": button.text === "Confirm" ? "CONFIRM" : button.text}</TranslateComponent>}
                    </button>
                  )

                  if (button.tooltip) {
                    buttonElement = (
                      <ConditionalTooltip
                        renderNode={this.el}
                        content={button.tooltip}
                      >
                        {buttonElement}
                      </ConditionalTooltip>
                    )
                  }

                  return button.requiredRoles ? (
                    <RequireRole
                      key={button.text}
                      requiredRoles={button.requiredRoles}
                      render={() => buttonElement}
                    />
                  ) : (
                    buttonElement
                  )
                })}
            </React.Fragment>
          )}
        </div> 
        }
      </div>,
      this.el
    )
  }
}

export default Modal
