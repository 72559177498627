import moment from 'moment'
import _set from "lodash/set"
import {
  hasCMSA,
  getTranslatedValue
} from "src/pages/contracts-and-offerings/common/contracts-and-offerings"


const MAX_LENGTH_OF_NUMBER = 50
const MAX_LENGTH_OF_PACT_NUMBER = 50
const MAX_LENGTH_OF_MSA_NUMBER = 50

export const CONTRACT_PERIODS = [
	{ key: "trial", days: [89, 90, 91], value: { key: "M", duration: 3 } },
	{ key: "1-year", days: [364, 365], value: { key: "Y", duration: 1 } },
	{ key: "14-month", days: [423, 424, 425], value: { key: "M", duration: 14 } },
	{ key: "2-year", days: [729, 730], value: { key: "Y", duration: 2 } },
	{ key: "3-year", days: [1094, 1095], value: { key: "Y", duration: 3 } },
	{ key: "4-year", days: [1460], value: { key: "Y", duration: 4 } },
	{ key: "5-year", days: [1825, 1826], value: { key: "Y", duration: 5 } },
	{ key: "custom", days: [0], value: { key: "d", duration: 0 } }
]

export const getExpirationDateBylength = (contractStartDate, contractPeriod, expDate) => {
	if (contractPeriod !== "custom") {
		const lengthInMonths = CONTRACT_PERIODS.find(({ key }) => key === contractPeriod)?.value
    if(lengthInMonths){
		const expirationDate = moment(moment(contractStartDate).add(moment.duration({ [lengthInMonths.key]: lengthInMonths.duration })).format("MM/DD/YYYY")).subtract(1, "day").format("MM/DD/YYYY")
		return expirationDate
    }
    return contractStartDate
	}
	return expDate
}


  export const validate =  ({ firstForm,buildingIds, offerings, businessStream, totalBaselineReportCount, coachingServicesValue, serviceContractNumber, pactContractNumber, mechanicalServiceAgreementNumber, termsAccepted, startDate, expirationDate, length, accountManager, primaryContact,primaryBuilding } = {},
    
    ) => {
    const errors = {}

    if (!offerings.length) {
      _set(errors, "offerings", getTranslatedValue("Please choose at least one option"))
    }

    if (!firstForm && !primaryBuilding) {
      _set(errors, "primaryBuilding", getTranslatedValue("Please choose at least one option"))
    }


    if (!buildingIds.length) {
      _set(errors, "buildingIds", getTranslatedValue("Please choose at least one option"))
    }

    // Different businessStreams selected goes with different fields
    if (/Independent/i.test(businessStream) ) {
      if (!serviceContractNumber) {
        _set(errors, "serviceContractNumber", getTranslatedValue("Required"))
      }
    }
    if (offerings?.some(sub => sub ===("BR"))) {
      if (!totalBaselineReportCount) {
        _set(errors, "totalBaselineReportCount", getTranslatedValue("Required"))
      }
    }
    if (offerings?.some(sub => sub ===("CS"))) {
      if (!coachingServicesValue) {
        _set(errors, "coachingServicesValue", getTranslatedValue("Required"))
      }
    }
    if ((!/Independent/i.test(businessStream)) && !((hasCMSA(offerings)) && offerings.length == 1)) {
      if (!serviceContractNumber) {
        _set(errors, "serviceContractNumber", getTranslatedValue("Required"))
      } else if (serviceContractNumber.length > MAX_LENGTH_OF_NUMBER) {
        _set(errors, "serviceContractNumber", "")
      } /* else if (serviceContractNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(serviceContractNumber)) { // eslint-disable-line
        _set(errors, "serviceContractNumber", i18next?.t?("users:SpCharacterNotAllowed"))
      } */
    }
    if (offerings?.some(sub => sub === "CAPxM&V")) {
       if (!pactContractNumber) {
         _set(errors, "pactContractNumber", getTranslatedValue("Required"))
      } else if (pactContractNumber.length > MAX_LENGTH_OF_PACT_NUMBER) {
        _set(errors, "pactContractNumber", "")
      } /* else if (pactContractNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(pactContractNumber)) { // eslint-disable-line
        _set(errors, "pactContractNumber", i18next?.t?("users:SpCharacterNotAllowed"))
      } */
    }
    if (hasCMSA(offerings) && offerings.length == 1) {
      if (!mechanicalServiceAgreementNumber) {
        _set(errors, "mechanicalServiceAgreementNumber", getTranslatedValue("Required"))
      } else if (mechanicalServiceAgreementNumber.length > MAX_LENGTH_OF_MSA_NUMBER) {
        _set(errors, "mechanicalServiceAgreementNumber", "")
      } /* else if (mechanicalServiceAgreementNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(mechanicalServiceAgreementNumber)) { // eslint-disable-line
        _set(errors, "mechanicalServiceAgreementNumber", i18next?.t?("users:SpCharacterNotAllowed"))
      } */
    }

    if (!length) {
      _set(errors, "length",getTranslatedValue("Required"))
    }

    if (startDate && getExpirationDateBylength(startDate, length, expirationDate) && moment(getExpirationDateBylength(startDate, length, expirationDate)).startOf("day") < moment(startDate).startOf("day")) {
      _set(errors, "startDate", getTranslatedValue("Start date should be earlier than Expiration date"))
    }

    if (!termsAccepted) {
      _set(errors, "termsAccepted", getTranslatedValue("Required"))
    }

    if (offerings?.some(sub => sub ===("AM"))) {
      if (numberOfDevices < 1 || numberOfDevices > 99) {
        _set(errors, "numberOfDevices", getTranslatedValue("Required"))
      }
      if (!numberOfDevices) {
        _set(errors, "numberOfDevices", getTranslatedValue("Required"))
      }
    }

    if (accountManager) {
      const emailRegEx = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(tranetechnologies|irco|trane|Boland|Damuth)\.(com)$/gmi) // eslint-disable-line
      if (!emailRegEx.test(accountManager)) {
        _set(errors, "accountManager", getTranslatedValue("This email address is not valid"))
      }
    }

    if (primaryContact) {
      const emailRegEx = new RegExp(/^([A-Za-z0-9.-]+@(tranetechnologies|irco|trane|Boland|Damuth)+\.(com)+)(,[A-Za-z0-9.-]+@(tranetechnologies|irco|trane|Boland|Damuth)+\.(com)+)*$/g) // eslint-disable-line
      const testEmail = primaryContact?.username.split(",").map(email => email.trim()).join(",")

      if (!testEmail.match(emailRegEx)) {
        _set(errors, "primaryContact", getTranslatedValue("This email address is not valid"))
      }
    }

    return errors
  }

