import EventMarkerContainer from './EventMarkerContainer'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { getSearchParams } from "src/common/helperFunctions.js"
import { IMarkerTypes, IConfirmationType } from './types'
import { GET_BUILDINGS_BY_ACCOUNT_ID, LIST_EVENT_MARKERS_BY_ACCOUNT } from './graphql'
import { useQuery } from 'src/hooks/APIHooks'
import { ConditionalTooltip } from '../../components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import { getDateFormatByLocale } from '../../common/chartHelperFunctions'
import translate from 'src/common/translations'

// Denali imports
import { useSelector } from 'react-redux'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { TranslateComponent } from 'src/common/translations'

export const initialValues = {
  name: '',
  description: '',
  type: 'Comment',
  markerDate: moment().locale('en').format('MM/DD/YYYY')
}

const markerTypes: IMarkerTypes[] = [
  { key: 'Comment', value: 'Comment', name: 'Comment'},
  {
    key: 'Energy Conservation Measure (ECM)',
    value: 'Energy Conservation Measure (ECM)',
    name: 'ECM',
  }
]

export const EventMarkers = () => {
  const [mode, setMode] = useState(null)
  const {buildingId, organizationId: accountId} = getSearchParams()
  const [reloadEvents, setReloadEvents] = useState(false)
  const [markerInput, setMarkerInput] = useState(initialValues)
  const [rows, setRows] = useState([])
  const [responseTime, setResponseTime] = useState(null)
  const [deleteMarkerId, setDeleteMarkerId] = useState<string>('')
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const [confirmationType, setConfirmationType] =
    useState<IConfirmationType>('')
  const uiMode = useSelector(selectUiMode)

  const {
    data: data2,
    refetch: refetchData2,
    loading: loading2,
    responseTime: responseTime2
  } = useQuery({
    query: LIST_EVENT_MARKERS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listEventMarkersByAccount.items'
  })

  const {
    data: data3,
    refetch: refetchData3,
    loading: loading3,
    responseTime: responseTime3
  } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    variables: {id: accountId},
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })

  useEffect(() => {
    if (buildingId && !loading2) {
      setIsLoading(false)
      refetchData2({ accountId: accountId, filter: {buildingIds: {contains: buildingId}} })
      refetchData3({id: accountId}) 
    } else if (accountId && !loading2) {
      setIsLoading(false)
      refetchData2({ accountId: accountId })
      refetchData3({id: accountId})
    } else {
      setRows([])
    }
  }, [accountId, buildingId])

  useEffect(() => {
    if (reloadEvents) {
      if (!buildingId) {
        setReloadEvents(false)
        refetchData2({ accountId: accountId })
      } else {
        setReloadEvents(false)
        refetchData2({ accountId: accountId, filter: {buildingIds: {contains: buildingId}} })
      }
    }
  }, [reloadEvents])

  const commentTranslate = translate("Comment")
  const ecmTranslate = translate("Energy Conservation Measure (ECM)")

  useEffect(() => {
      setRows(
        data2
          ? data2.map((d) => ({
              ...d,
            buildingNames: data3
              ?.filter((e) => d.buildingIds.includes(e.id))
              .map((e) => e.name)
              .join(', '),
            type: d.type.toLowerCase().includes('comment')
              ? commentTranslate
              : ecmTranslate,
							markerDate: moment(d.markerDate, 'YYYY-MM-DD').format(
								'MM/DD/YYYY'
							),
							typeIcon: d.type,
            descriptionIcon: d.description ? (
              uiMode === UiMode.denali ? (
                <Tooltip>
                  <TooltipTrigger>
                    <FontAwesomeIcon icon={faInfoCircle} color="#6B6B6B" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <TranslateComponent>{d.description}</TranslateComponent>
                  </TooltipContent>
                </Tooltip>
              ) : (
                <ConditionalTooltip
                  type={ConditionalTooltip.TYPE.ICON}
                  content={d.description}
                >
                  <Icon name="infocircle2" color="#bfbfbf" />
                </ConditionalTooltip>
              )
            ) : null
          }))
          : []
      )
      setResponseTime(responseTime2)
  }, [data2, data3, commentTranslate, ecmTranslate])

  return (
    <EventMarkerContainer
      setMode={(e) => setMode(e)}
      mode={mode}
      rows={rows}
      responseTime={responseTime}
      markerTypes={markerTypes}
      reloadEvents={reloadEvents}
      accountId={accountId}
      buildingId={buildingId}
      setReloadEvents={(e) => setReloadEvents(e)}
      deleteMarkerId={deleteMarkerId}
      setDeleteMarkerId={(e) => setDeleteMarkerId(e)}
      setShowDeleteDialog={(e) => setShowDeleteDialog(e)}
      showDeleteDialog={showDeleteDialog}
      markerInput={markerInput}
      setMarkerInput={(e) => setMarkerInput(e)}
      showConfirmation={showConfirmation}
      confirmationType={confirmationType}
      setConfirmationType={(e) => setConfirmationType(e)}
      setShowConfirmation={(e) => setShowConfirmation(e)}
      initialValues={initialValues}
      showSpinner={loading2 || loading3 || isLoading}
    />
  )
}
