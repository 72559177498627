import React, { useEffect, useState } from 'react'
import { Content } from '../../../components/layouts'
// import Table from 'src/components/Table/graphQLTable'
import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { GET_BUILDINGS, GET_RESOURCES } from '../graphql/queries'
import { accessControlFunc } from 'src/components/accessControl'

import '../createUser/user-management.scss'
import { useSelector } from 'react-redux'
import { selectUserAccess } from '../../../redux/slicers/appData'
import { useQuery } from '../../../hooks/APIHooks'
import { createWildCardFilterMultiple } from 'src/components/layouts/Table/wildCardFilter'


export const AccountsByBuildingsTable = ({
  width,
  resource,
  resourceControl
}) => {
  const tableHeaders = [
    {
      title: `${resource} Name`,
      key: 'name'
    },
    // // Commenting below until resource management is done
    // {
    //   title: 'Address',
    //   key: 'address'
    // },
    // {
    //   title: 'City',
    //   key: 'city'
    // },
    // {
    //   title: 'State',
    //   key: 'state'
    // },
    // {
    //   title: 'Service Team',
    //   key: 'salesOfficeName'
    // },
    // {
    //   title: 'Organization Name',
    //   key: 'accountName'
    // }
  ]

  const [tableData, setTableData] = useState([])
  const [searchText, setSearchText] = useState("")
  const userAccess = useSelector(selectUserAccess)

  const queryToUse = GET_RESOURCES(resource)

  const {
    data: data1,
    refetch: refetchData1,
    loading: loading1,
    responseTime: responseTime1
  } = useQuery({
    query: GET_RESOURCES("Building"),
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchBuildings.items',
    onSuccess: (res => {
      setTableData(Object.values(res.reduce((accum, curr) => {
        if (!accum[curr.accountId]) {
          accum[curr.accountId] = {id: curr.accountId, name: curr.accountName, salesOfficeIds: [curr.salesOfficeId], regionIds: curr.regionId ? [curr.regionId] : [], globalRegionIds: curr.globalRegionId ? [curr.globalRegionId] : []}
        } else {
            if (!accum[curr.accountId].salesOfficeIds.includes(curr.salesOfficeId)) {
              accum[curr.accountId].salesOfficeIds.push(curr.salesOfficeId)
            }
            if (curr.regionId && !accum[curr.accountId].regionIds.includes(curr.regionId)) {
              accum[curr.accountId].regionIds.push(curr.regionId)
            }
            if (curr.globalRegionId && !accum[curr.accountId].globalRegionIds.includes(curr.globalRegionId)) {
              accum[curr.accountId].globalRegionIds.push(curr.globalRegionId)
            }
        }
        return accum
      }, {})).slice(0, 30))
    })
  })

  useEffect(() => {
    refetchData1({limit: 1000, filter: {and: [{isActive: {eq: 1}}, {accountId: {exists: true}}, {accountName: {exists: true}}]}, sort: {field: "accountName", direction: "asc"}})
  }, [])

  useEffect(() => {
    if (searchText) {
        refetchData1({limit: 1000, filter: {and: [
        {isActive: {eq: 1}}, 
        {accountId: {exists: true}}, 
        {accountName: {exists: true}}, 
        createWildCardFilterMultiple({
          attrNames: ["accountName"],
          value: searchText.trim()
        }
      )
      ]}, sort: {field: "accountName", direction: "asc"}})
    }
    

  }, [searchText])
  
  return (
    <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
      width={width}
    >
      <ClientSideTable
          key={`UserAccountsClientSide`}
          header={tableHeaders || []}
          rows={tableData}
          search={true}
          searchPlaceholderText={"Type to search..."}
          searchAction={(a, b, c) => {
            setSearchText(b)            
          }}
          searchFields={['name']}
          rowCheckbox={d => {
              return accessControlFunc({id: [`tc.pages.user-admin.Application`, `tc.pages.user-admin.${d.id}`, ...d.salesOfficeIds.map(x => `tc.pages.user-admin.${x}`), ...d.regionIds.map(x => `tc.pages.user-admin.${x}`), ...d.globalRegionIds.map(x => `tc.pages.user-admin.${x}`)], userAccess})
          }}
          checkboxChecked={(e) =>  Boolean(resourceControl[0][resource]?.[e.id])}
          checkboxClick={(e) => {
            const newResources = {...resourceControl[0][resource]}
            Boolean(newResources[e.id]) ? delete newResources[e.id] : newResources[e.id] = {name: e.name, applications: {}, accountId: e?.accountId, globalRegionIds: e?.globalRegionIds, regionIds: e?.regionIds, salesOfficeIds: e?.salesOfficeIds, salesOfficeId: e?.salesOfficeId, contractorCompanyId: e?.contractorCompanyId, groupUser: e?.groupUser, groupAdmin: e?.groupAdmin }
            resourceControl[1]({...resourceControl[0], [resource]: newResources})
          }}
        />
    </Content>
  )
}

AccountsByBuildingsTable.propTypes = {}

export default AccountsByBuildingsTable
