import translate, { TranslateComponent } from 'src/common/translations'
import { Flex, Input, Label, SelectField } from '@aws-amplify/ui-react'
import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import styles from 'src/denali-components/Form/form.module.scss'
import { SelectVisibility } from 'src/denali-components/Form/SelectVisibility'
import { DenaliFormElementError } from './DenaliFormElementErrors'

interface CostSavingsSimpleProps {
  inputValue: any
  selectValue: any
  title?: string
  primaryFieldLabel?: string
  /*
   * The property of values that we want the input to update. Can use dot notation.
   * e.g. "settings.kpi.name" or "title"
   */
  inputProperty: string
  /*
   * The property of values that we want the select to update. Can use dot notation.
   * e.g. "settings.kpi.type" or "tag"
   */
  selectProperty: string
  selectLabel?: string
  icon?: any
  iconColor?: string
  visibility?: boolean
  visibilityProperty: string
  /*
   * The options displayed in the select list.
   */
  options?: { label: string; value: string }[]
  previewBuilder?: (inputValue: any, selectValue: any) => React.ReactElement
}

export const CostSavingsSimple = ({
  inputValue,
  selectValue,
  title,
  primaryFieldLabel,
  inputProperty,
  selectProperty,
  selectLabel,
  icon,
  iconColor,
  visibility,
  visibilityProperty,
  options,
  previewBuilder
}: CostSavingsSimpleProps) => {
  return (
    <div className={styles.cardFinancial}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        width="100%"
      >
        <Flex alignItems="center" direction="row">
          <div className={styles.icon} data-color={iconColor}>
            {icon}
          </div>
          <span className={styles.title}>
            <TranslateComponent>{title}</TranslateComponent>
          </span>
        </Flex>
        <SelectVisibility
          visibility={visibility}
          name={visibilityProperty}
          className={styles.smlSelect}
        />
      </Flex>
      <hr />
      <>
        <Flex alignItems="flex-end" direction="row" width="100%" gap="8px">
          <Flex direction="column" gap="0" width="65%">
            <Label className={styles.smlLabel} htmlFor={primaryFieldLabel}>
              <TranslateComponent>{primaryFieldLabel}</TranslateComponent>:
            </Label>
            <DenaliFormElement id={primaryFieldLabel} name={inputProperty}>
              <Input
                type="text"
                name="primary"
                data-testid={primaryFieldLabel.replace(' ', '-')}
                placeholder={translate('Start typing here...')}
                value={inputValue}
              />
            </DenaliFormElement>
            <DenaliFormElementError name={inputProperty} />
          </Flex>
          <span className={styles.emptyLabelSpacer}></span>
          <DenaliFormElement
            id={`cost-savings-input--${selectProperty}`}
            name={selectProperty}
          >
            <SelectField
              label={selectLabel}
              labelHidden
              name={selectProperty}
              data-testid={selectProperty}
              placeholder={translate('Select')}
              value={selectValue}
            >
              {options.map(({ label, value }) => (
                <option key={label} value={value}>
                  <TranslateComponent>{label}</TranslateComponent>
                </option>
              ))}
            </SelectField>
          </DenaliFormElement>
        </Flex>
        <DenaliFormElementError name={selectProperty} />
        {previewBuilder && previewBuilder(inputValue, selectValue)}
      </>
    </div>
  )
}
