import moment from "moment"
import styled from "styled-components"
import { TranslateComponent } from 'src/common/translations'

const TOUTable = styled.table`
    border-spacing: 0px;
    border-top: 2px solid rgb(118, 118, 118);
`

const SeasonRow = styled.tr`
    border-top: 2px solid rgb(118, 118, 118);
    & th:first-child {
        background-color: green;
        color: white;
        padding: 5px 0px 5px 10px;
        min-width: 90px;
        min-width: 90px;
        width: 5%;
        padding: 5px;
        text-align: left;
    }
`

const HeaderElement = styled.th`
    background-color: ${props => props.isOdd ? "rgb(166, 200, 140);" : "rgb(171, 205, 145);"}
`

const TOUTableDataCell = styled.td`
    ${props => props.selected ? props.type === "Off-Peak" ? "background-color: rgb(196, 223, 250);" : props.type === "Intermediate" ? "background-color: rgb(159, 187, 218);" : props.type === "On-Peak" ? "background-color: rgb(127, 147, 169);" : "border: 1px solid rgb(237, 237, 237);" : "border: 1px solid rgb(237, 237, 237);"}
`

const SelectableValueParagraph = styled.p`
  font-size: 105%;
  margin: 20% 15%;
`

const PeriodComponent = styled.span`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & p:first-child {
    font-weight: bold;
    margin: 0px;
  }
  & p:last-child {
    font-weight: normal;
    font-size: 80%;
  }
`

const TableSpacer = styled.td`
  height: 8px;
  background-color: rgb(166, 200, 140);
`

export const dayTypes = ["Weekdays", "Weekends"]

export const periodTypes = ["On-Peak", "Intermediate", "Off-Peak"]


export const TimeOfUsePeriodTable = ({values, setValue, disabled}) => {
  const activeSeasons = Object.keys(values.seasons).filter(e => values.seasons[e].active)
  return <>
  {activeSeasons.map(s => <TOUTable key={`seasonTableTOU${s}`}>
    <SeasonRow>
      <th>{s === "noSeasonalVariation" ? "" : s.toUpperCase()}</th>
      {Array.from(Array(24)).map((e, i) => {
        return <HeaderElement isOdd={Boolean(i%2)} key={i} />
      })}
    </SeasonRow>
    {dayTypes.map(t => {
    return <>
    
    {periodTypes.map((e, ei) => {
      return <><tr key={`${s}${e}`}>
      <TOUTableDataCell>
        <PeriodComponent>
        <p><TranslateComponent>{e}</TranslateComponent></p>
        <p><TranslateComponent>{t}</TranslateComponent></p>
        </PeriodComponent>
      </TOUTableDataCell>
    {Array.from(Array(24)).map((x, i) => {
      const time = moment().hour(i)
      const nextTime = time.clone().add(1, "hour").format("ha")
      return <TOUTableDataCell selected={values?.timeOfUseValues?.[s]?.[t]?.[nextTime] === e} onClick={disabled ? undefined : () => {
        const newTimeOfUseValues = JSON.parse(JSON.stringify(values.timeOfUseValues))
        newTimeOfUseValues[s][t][nextTime] = e
        setValue("timeOfUseValues", newTimeOfUseValues)
      }} type={e} key={`${s}${e}${t}${i}`}>
        <SelectableValueParagraph>{time.format("ha")}-{nextTime}</SelectableValueParagraph>
      </TOUTableDataCell>
    })}
  </tr>
  </>
    })}
    <tr>
    {Array.from(Array(25)).map((x, i) => {
      return <TableSpacer key={`${t}spacer${i}`} />
    })}
  </tr>
    </>
    })}
  </TOUTable>)
  }
  </>
  }