import { Button, Fieldset, Flex, Input, Label } from '@aws-amplify/ui-react'
import { forwardRef, ReactNode, useCallback, useState } from 'react'

// Project imports
import translate, { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faRadar } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Dialog } from 'src/denali-components/Dialog/Dialog'
import dialogStyles from 'src/denali-components/Dialog/dialog.module.scss'

// Legacy imports
import ImageBlockList from 'src/components/legacy/components/images/image-block-list/image-block-list'

// Local imports
import styles from './form.module.scss'
import { DenaliSupportingImagesProps } from './types.d'

export const DenaliSupportingImages = forwardRef(
  function DenaliSupportingImagesComponent(
    {
      value,
      findings,
      organizationId,
      locationId,
      onChange,
      showAddFindingButton,
      showAddImageButton = true,
      uiMode
    }: DenaliSupportingImagesProps,
    _
  ) {
    const onChangeHandler = useCallback(
      (files: File[]) => {
        onChange(files)
      },
      [onChange]
    )

    const [notification, setNotification] = useState(null)
    const [error, setError] = useState(null)

    const hideNotificationDialog = () => {
      setNotification(null)
    }

    return (
      <>
        <ImageBlockList
          images={value}
          findings={findings}
          maxSize={6}
          onUpdate={onChangeHandler}
          showAddFindingButton={showAddFindingButton}
          showAddImageButton={showAddImageButton}
          onFindingsUpdate={(values, removingFinding) => {
            // Note that removingFinding is always true, even when adding
            onChangeHandler(values)
          }}
          annotationsEnabled={true}
          organizationId={organizationId}
          locationId={locationId}
          uiMode={uiMode}
        />
        {notification && (
          <Dialog
            title={notification.title}
            onClose={hideNotificationDialog}
            width="500px"
            className={dialogStyles.dialogWrapper}
            data-testid="AddFindingDialog"
          >
            <span>
              <TranslateComponent>{notification.text}</TranslateComponent>
            </span>
          </Dialog>
        )}
      </>
    )
  }
)

export const Root = ({ children }: { children: ReactNode }) => {
  return <div className={styles.supportingImageContainer}>{children}</div>
}

export const AddImageButton = ({
  onClick,
  buttonText,
  buttonInstruction
}: {
  onClick?: () => void
  buttonText: string
  buttonInstruction: string
}) => {
  return (
    <Button className={styles.addImageButton} onClick={onClick}>
      <FontAwesomeIcon icon={faImage} />
      <Flex direction="column" gap="0">
        <Label>
          <TranslateComponent>{buttonText}</TranslateComponent>
        </Label>
        <TranslateComponent>{buttonInstruction}</TranslateComponent>
      </Flex>
    </Button>
  )
}

export const AddFindingButton = ({
  onClick,
  buttonText,
  buttonInstruction
}: {
  onClick?: () => void
  buttonText: string
  buttonInstruction: string
}) => {
  return (
    <Button className={styles.addFindingButton} onClick={onClick}>
      <FontAwesomeIcon icon={faRadar} />
      <Flex direction="column" gap="0">
        <Label>
          <TranslateComponent>{buttonText}</TranslateComponent>
        </Label>
        <TranslateComponent>{buttonInstruction}</TranslateComponent>
      </Flex>
    </Button>
  )
}

export const DragAndDropZone = ({ children }: { children: ReactNode }) => {
  return <div className={styles.dragAndDropWrapper}>{children}</div>
}

export const ImageContainer = ({ children }) => {
  return <div className={styles.imageContainer}>{children}</div>
}

export const ImageUploadField = ({ label, inputName, onChange, value }) => {
  return (
    <Fieldset
      legendHidden
      legend={`Image Upload ${label}`}
      className={styles.imageUploadField}
      gap={'0'}
      padding={'0'}
      border={'none'}
    >
      <Label className={styles.imageUploadFieldLabel}>
        <TranslateComponent>{label}</TranslateComponent>
      </Label>
      <Input
        placeholder={translate('Start typing here...')}
        type="text"
        name={inputName}
        onChange={onChange}
        value={value}
      />
    </Fieldset>
  )
}
