import * as Plot from '@observablehq/plot'
import { useEffect, useRef, memo, useCallback } from 'react'

// Custom hooks
import { useDimensions } from 'src/hooks/useDimensions'

// Local imports
import styles from './plot-chart.module.scss'

export type PlotChartOptions = {
  x?: string
  y?: string
  fill?: string
}

export type PlotChartLineOptions = {
  x?: string
  y?: string
  stroke?: string
}

type PlotChartProps = {
  marks: Plot.Markish[]
  facet?: Plot.PlotFacetOptions
  width?: number
  autoSize?: boolean
  fx?: Plot.ScaleOptions
  fy?: Plot.ScaleOptions
  x?: Plot.ScaleOptions
  y?: Plot.ScaleOptions
  clip?: boolean
  marginRight?: number
  marginLeft?: number
  listenForInputEvents?: boolean
  inputEventCallback?: (e: InputEvent, plot: Plot.Plot) => void
  // Allow customizing the plot before it's inserted into the DOM and attached
  beforeAppend?: (plot: Plot.Plot) => void
  // Allow calling code to clean up before plot is removed from the DOM;
  // cleanup event listeners, etc.
  beforeRemove?: (plot: Plot.Plot) => void
}

const PlotChart = ({
  marks,
  facet,
  width,
  autoSize,
  fx,
  fy,
  x,
  y,
  clip = true,
  marginLeft,
  marginRight,
  listenForInputEvents = false,
  inputEventCallback,
  beforeAppend,
  beforeRemove
}: PlotChartProps) => {
  const chartRef = useRef<HTMLDivElement>()
  const { width: autoSizeWidth } = useDimensions(chartRef, [
    marks,
    autoSize,
    facet,
    width,
    fx,
    fy,
    x,
    y,
    marginLeft,
    marginRight
  ])

  // Event callback called by plot whenever a mark recieves an input event. Scrolling, hovering, clicking, etc etc.
  const handlePlotValueChange = useCallback(
    (_: InputEvent, plot: Plot.Plot) => {
      inputEventCallback?.(_, plot)
    },
    []
  )

  // Now render the chart and then attach it to this div using the ref
  useEffect(() => {
    if (marks === undefined) return

    const plot = Plot.plot({
      fx: fx,
      fy: fy,
      y: y,
      x: x,
      clip: clip,
      marginLeft: marginLeft ?? 70,
      marginRight: marginRight ?? 50,
      width: autoSize ? autoSizeWidth : width,
      facet: facet,
      marginBottom: 60,
      marks: [...marks]
    })
    // Call the beforeAppend callback if it exists.
    beforeAppend?.(plot)
    chartRef.current.append(plot)
    if (listenForInputEvents) {
      plot.addEventListener('input', (e: InputEvent) =>
        handlePlotValueChange(e, plot)
      )
    }
    return () => {
      if (listenForInputEvents) {
        plot.removeEventListener('input', (e: InputEvent) =>
          handlePlotValueChange(e, plot)
        )
      }
      // Call the beforeRemove callback if it exists.
      beforeRemove?.(plot)
      plot.remove()
    }
  }, [marks, x, y, fx, fy, facet, autoSizeWidth])

  return (
    <>
      <div
        ref={chartRef}
        className={`${styles.chartBody} ${!clip ? styles.noClip : ''}`}
      />
    </>
  )
}

export default memo(
  PlotChart,
  (prevProps, nextProps) => prevProps === nextProps
)
