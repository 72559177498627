import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from '../../../components/pageHeader/header'
import './user-management.scss'
import Button from 'src/denali-ui/components/Button'
import BuildingsByNameTable from '../components/buildingsByNameTable'
import GroupsByNameTable from '../components/groupsByNameTable'
import Select from 'src/denali-ui/components/Select'
import Option from 'src/denali-ui/components/Option'
import ProgressIndicator from './create-user-progress-indicator'
import { Block } from '../../../components/layouts/index'
import { Container, Content } from '../../../components/layouts'
import { TranslateComponent } from '../../../common/translations'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../../redux/slicers/appData'
import { getSession } from 'src/redux/slicers/appData'
import { AccountsByBuildingsTable } from '../components/accountsByBuildingsTable'


const ButtonContainer = styled.div`
  text-align: end;
`

const FieldDiv = styled.span`
  margin-right: 16px;
  display: inline-flex;
`

export const AddUserStage = ({
  width,
  prev,
  next,
  resourceControl,
  userInfoObject
}) => {
  const signedInUser = useSelector(selectUserInfo)
  const [signedInUserInfo, setSignedInUserInfo] = useState()

  // TODO: We could eventually make this a flag within the user. The reason for this is because we need to restrict external Users from seeing SalesOffice on assigning User Admin role.
  const isBothBuildingOwner = signedInUser.type.id === "c7a04a53-e2dc-46bf-b8d9-84288a431964" && userInfoObject?.userType?.value === "c7a04a53-e2dc-46bf-b8d9-84288a431964"


  let resourceTypes = Object.keys(userInfoObject.applications).reduce((accum, curr) => {
    Object.keys(userInfoObject.applications[curr]?.roles)?.forEach(e => {
      userInfoObject.applications[curr].roles[e]?.resourceTypes?.forEach(x => {
        if (accum.indexOf(x) === -1) {
          accum.push(x)
        }
      })
    })
    return accum
  }, []).sort()

  if (isBothBuildingOwner) {
    resourceTypes = resourceTypes.filter(e => e !== "SalesOffice" && e !== "ContractorCompany" && e !== "BuildingGroup")
  }

  const [selectedFilter, setSelectedFilter] = useState(
    resourceTypes[0]
  )

  useEffect(() => {
    const sessionSetter = async () => {
      await getSession(false).then(res => {
        setSignedInUserInfo(res?.idToken?.payload)
      })
    }
    sessionSetter()
  }, [])

  // This is the Trane Employee Id. Not a fan, but using ID in case text changes
  const showAccountsBasedOnBuildings = signedInUser.type.id === "6be6ec61-cc5c-4967-a0d5-50208f16723d" && !signedInUserInfo?.["cognito:groups"].includes("SuperUser") && !signedInUserInfo?.["cognito:groups"].includes("SuperAdmin")

  return (
    <>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        width={width}
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <Header hideToolBar={false} pageTitle={<TranslateComponent>Buildings</TranslateComponent>} />
        </Content>
          <Content
            xlColumn={6}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Block margin="0 0 46px 0">
              <ProgressIndicator stage={2} />
            </Block>
          </Content>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <FieldDiv>
                <Select
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  placeholder="Default state"
                  popoverConfig={{
                    strategy: 'fixed'
                  }}
                  value={<TranslateComponent>{selectedFilter}</TranslateComponent>}
                >
                  {resourceTypes.map((value) => (
                    <Option key={value} value={value}>
                      <TranslateComponent>{value}</TranslateComponent>
                    </Option>
                  ))}
                </Select>
              </FieldDiv>
            </Content>
            <br />
            {signedInUser 
            ? selectedFilter !== "BuildingGroup" && selectedFilter !== "ApplicationGroup" && selectedFilter !== "Account"
            ? <BuildingsByNameTable
              userInfoObject={userInfoObject}
              width={width}
              resource={selectedFilter}
              resourceControl={resourceControl}
            />
            : selectedFilter === "Account" ? showAccountsBasedOnBuildings ? 
            <AccountsByBuildingsTable
              width={width}
              resource={selectedFilter}
              resourceControl={resourceControl}
            />
            : selectedFilter === "Region" ? 
              <BuildingsByNameTable
              width={width}
              resource={selectedFilter}
              resourceControl={resourceControl}
            />
            : <BuildingsByNameTable
              userInfoObject={userInfoObject}
              width={width}
              resource={selectedFilter}
              resourceControl={resourceControl}
            />
            : <GroupsByNameTable
              key={selectedFilter}
              width={width}
              resource={selectedFilter}
              resourceControl={resourceControl}
            />
            : ""
                  }
          </Content>
        
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <ButtonContainer>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => prev()}
              style={{ marginRight: '14px' }}
            >
              <TranslateComponent>Back</TranslateComponent>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                  next()
              }}
            >
              <TranslateComponent>Next</TranslateComponent>
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </>
  )
}

AddUserStage.propTypes = {}

export default AddUserStage
