import translate, { TranslateComponent } from 'src/common/translations'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Button } from '@aws-amplify/ui-react'
import { DialogTabbed } from 'src/denali-components/Dialog/DialogTabbed'
import tabStyles from 'src/denali-components/Tabs/tabs.module.scss'

export const DenaliInlineHelp = ({ help, modalOpen }) => {
  const modalConfig = getModalConfig(help)

  return (
    <Tooltip key="help">
      <TooltipTrigger>
        <Button
          key="helpButton"
          className="button-help"
          data-testid="helpButton"
          ariaLabel="Help Button"
          onClick={() => {
            help.toggleModal()
          }}
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
        {modalOpen && (
          <DialogTabbed
            title={'Help'}
            onClose={help.toggleModal}
            width={'860px'}
            data-testid="helpModal"
            contents={modalConfig.tabs}
          />
        )}
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>Help</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )
}

const getDenaliHelpContents = (help) => (
  <>
    <div className={tabStyles.tabHeader}>
      {help.props.isTerms ? (
        <TranslateComponent>Terms you need to know</TranslateComponent>
      ) : (
        <TranslateComponent>Using help feature</TranslateComponent>
      )}
    </div>
    {help.props.inlineHelpText.map((e, i) => {
      const text = typeof e.body === 'function' ? e.body() : e.body
      return (
        <div key={i}>
          <div className={tabStyles.tabContentHeading}>
            <TranslateComponent>{e.heading}</TranslateComponent>
          </div>
          <div>{text}</div>
        </div>
      )
    })}
    <div>
      <div className={tabStyles.tabContentHeading}>
        {<TranslateComponent>Need Further Assistance?</TranslateComponent>}
      </div>
      <div>
        <TranslateComponent>
          Contact our Support Staff using the
        </TranslateComponent>{' '}
        <strong>
          <TranslateComponent>Help & Support</TranslateComponent>
        </strong>
        <TranslateComponent>
          {' '}
          menu located in the bottom left corner of the page.
        </TranslateComponent>
      </div>
    </div>
  </>
)

const getModalConfig = (help) => ({
  heading: 'Help',
  className: 'main-general-modal wide-modal',
  toggleModal: help.toggleModal,
  showObjectPicker: false,
  showPanelOne: false,
  tabs: [
    {
      header: <TranslateComponent>Overview</TranslateComponent>,
      content: getDenaliHelpContents(help)
    }
  ]
})
