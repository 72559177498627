import { createContext } from 'react'
import { FlyoutMenuContextState } from './types'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {}

export const FlyoutMenuContext = createContext<FlyoutMenuContextState>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: emptyFunc,
  setHasFocusInside: emptyFunc,
  isOpen: false
})
