export const GET_AVAILABLE_UTILITY_ACCOUNTS = `
  query getAvailableUtilityAccounts($tisAccountId: ID!, $nextToken: String) {
    listUtilityAccounts(
      filter: {or: [{accountId: {attributeExists: false}}, {accountId: {eq: $tisAccountId}}]},
      limit: 1000,
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        providerName
        accountEmail
        accountId
        accountNumber
      }
    }
  }
`

export const UPDATE_UTILITY_ACCOUNT = `
  mutation updateUtilityAccount($utilityAccountId: ID!, $tisAccountId: ID) {
    updateUtilityAccount(input: {id: $utilityAccountId, accountId: $tisAccountId}) {
      id
      accountId
    }
  }
`

export const GET_ACCOUNT_BY_ID = `
  query getAccountById($id: ID!) {
    getAccount(id: $id) {
      id
      name
    }
  }
`

export const GET_PRE_SIGNED_URL = `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`

export const LIST_UTILITY_STATEMENTS = `
  query listUtilityStatements($nextToken: String) {
    listUtilityStatements(nextToken: $nextToken, limit: 1000) {
      nextToken
      items {
        utilityAccountIds
        statementDate
        id
        year
        month
      }
    }
  }
`
