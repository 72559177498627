import moment from 'moment'
import { useMemo } from 'react'

// Project imports
import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { TranslateComponent } from 'src/common/translations'

// Denali imports.
import {
  faTachographDigital,
  faCalendarLines
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { Chip, ChipColor, ChipWeight } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import tableStyles from 'src/denali-components/Table/table.module.scss'

// Local imports.
import styles from './baseline-library.module.scss'

export const BaselineLibraryList = ({
  rows,
  optionsTranslated,
  rowControl,
  showSpinner,
  handleUpdatedTableRows,
  responseTime
}) => {
  const transformedRows = useMemo(
    () =>
      rows.map((row) => ({
        ...row,
        exclusionsSortValue: row.exclusions?.length.toString() || null,
        savingsSortValue: row.savings?.length.toString() || null
      })),
    [rows]
  )

  return (
    <>
      <ClientSideTable
        rows={transformedRows}
        tableClassName={tableStyles.baselineLibraryPage}
        header={getHeadersWithRowRenderOverride(optionsTranslated)}
        search={true}
        searchFields={['name', 'description']}
        rowControl={rowControl}
        handleUpdatedTableRows={handleUpdatedTableRows}
        loadTime={responseTime}
        isDenaliTable={true}
        testName="baselineLibraryPage"
        listDataIsLoading={showSpinner}
        noTableDataTitleText="No baseline libraries found"
      />
    </>
  )
}

const getHeadersWithRowRenderOverride = (optionsTranslated) => {
  return [
    {
      title: optionsTranslated.baselineName,
      key: 'name',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.description,
      key: 'description',
      width: '120px',
      customComponent: descriptionCell
    },
    {
      title: optionsTranslated.baselinePeriod,
      key: 'periods',
      type: 'date',
      sortField: 'endPeriod'
    },
    {
      title: optionsTranslated.exclusions,
      key: 'exclusionsSortValue',
      sortField: 'exclusionsSortValue',
      type: 'number'
    },
    {
      title: optionsTranslated.savings,
      key: 'savingsSortValue',
      sortField: 'savingsSortValue',
      type: 'number'
    },
    {
      key: 'chips',
      hideFromSort: true,
      customComponent: chipsCell
    }
  ]
}

const formatDate = (date) => {
  return moment(date).format('ll')
}

const titleCell = (row) => (
  <div className={styles.titleCentered}>
    <div className={styles.titleContainer}>
      <div className={styles.title}>
        <Tooltip>
          <TooltipTrigger>
            <TranslateComponent>{row.name}</TranslateComponent>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>{row.name}</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
    <div className={styles.chipRow}>
      <Tooltip>
        <TooltipTrigger>
          <Chip text={row.baselineEquipment?.name} icon={faTachographDigital} />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>
            Baseline Equipment - {row.baselineEquipment?.name}
          </TranslateComponent>
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Chip
            text={`${formatDate(row.startPeriod)} - ${formatDate(
              row.endPeriod
            )}`}
            icon={faCalendarLines}
          />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>Date Period</TranslateComponent> -
          {`${row.startPeriod} - ${row.endPeriod}`}
        </TooltipContent>
      </Tooltip>
    </div>
  </div>
)

const descriptionCell = (row) =>
  row.description && (
    <Tooltip>
      <TooltipTrigger>
        <div className={styles.descriptionContainer}>
          <p className={styles.descriptionTitle}>
            <TranslateComponent>Description</TranslateComponent>
          </p>
          <span className={styles.description}>
            <TranslateComponent>{row.description}</TranslateComponent>
          </span>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>{row.description}</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )

const chipsCell = (row) => (
  <Chips className={styles.chipContainer}>
    {row.savingsSortValue > 0 && (
      <Chip color={ChipColor.green} size="medium" weight={ChipWeight.w500}>
        {row.savingsSortValue}
      </Chip>
    )}
    {row.exclusionsSortValue > 0 && (
      <Chip color={ChipColor.blue} size="medium" weight={ChipWeight.w500}>
        {row.exclusionsSortValue}
        <TranslateComponent>
          {row.exclusionsSortValue === '1' ? ' exclusion' : ' exclusions'}
        </TranslateComponent>
      </Chip>
    )}
  </Chips>
)
