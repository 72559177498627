import { Flex } from '@aws-amplify/ui-react'
import { forwardRef, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import Select, { type ActionMeta } from 'react-select'

// Project imports.
import { handleEquipmentChangeSelect } from 'src/pages/issuesFindings/quickAdd/helpers'

// Denali imports.
import { Equipment } from 'src/denali-pages/types'
import { TranslateComponent } from 'src/common/translations'

// Local imports.
import { useEquipmentSelectorState } from './hooks'
import styles from './equipment-selector.module.scss'
import { selectStyles } from './styles'
import { animatedComponents } from './select'
import { EquipmentSelectorProps } from './types'

export const EquipmentSelector = forwardRef(function EquipmentSelectorComponent(
  { locationId, value, onChange, error, ...props }: EquipmentSelectorProps,
  _
) {
  const { equipments } = useEquipmentSelectorState({ locationId })
  const {
    getValues,
    formState: { disabled: formDisabled }
  } = useFormContext()

  const onChangeHandler = useCallback(
    (option: Equipment[], _: ActionMeta<Equipment>) => {
      // Let's get the right data out of here.
      const values = getValues()
      const val = option
      const selectedTisObjects = handleEquipmentChangeSelect({
        value: val,
        values,
        tisObjectForSelector: values.tisObjects,
        equipments,
        useClearAll: false
      })
      onChange(selectedTisObjects)
    },
    [onChange, getValues, equipments]
  )

  const selectedEquipments = equipments.filter((equipment) =>
    value.some((tisObject) => tisObject.tisObjectId === equipment.key)
  )

  const noOptionsMessage = () => {
    return (
      <TranslateComponent>
        Please choose a building to see available equipment
      </TranslateComponent>
    )
  }

  const placeholderComponent = useCallback(() => {
    return (
      <Flex
        className={styles.equipmentSelectorPlaceholder}
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        gap="0"
      >
        <h6>
          <TranslateComponent>Select Equipment</TranslateComponent>
        </h6>
        <p>
          <TranslateComponent>Select one or more</TranslateComponent>
        </p>
      </Flex>
    )
  }, [])

  const selectIsDisabled = props.disabled || formDisabled
  const formatOptionLabel = ({ label }) => (
    <TranslateComponent>{label}</TranslateComponent>
  )

  return (
    <Select
      {...props}
      isDisabled={selectIsDisabled}
      value={selectedEquipments}
      className={`${styles.equipmentSelector} ${
        error?.message ? styles.equipmentSelectorError : ''
      } ${
        selectedEquipments.length > 0 ? styles.equipmentSelectorSelected : ''
      }`}
      placeholder={placeholderComponent()}
      options={equipments}
      components={animatedComponents}
      styles={selectStyles}
      onChange={onChangeHandler}
      noOptionsMessage={noOptionsMessage}
      formatOptionLabel={formatOptionLabel}
      isMulti
    />
  )
})
