import { useCallback } from 'react'
import { Dialog } from 'src/denali-components/Dialog/Dialog'
import { Button, Flex } from '@aws-amplify/ui-react'
import dialogStyles from 'src/denali-components/Dialog/dialog.module.scss'
import dropZoneStyles from 'src/denali-components/DropZone/drop-zone.module.scss'
import { TranslateComponent } from 'src/common/translations'
import { DenaliFileUpload } from 'src/denali-components/Form/DenaliFileUpload'

export const DialogFileUpload = ({
  toggleUploadBillShowModal,
  locationName,
  location,
  downloadTemplate,
  errors,
  fileData,
  onChangeUploadStatus,
  onCloseModal,
  uploadFile,
  allowedFileTypes,
  includeDescription,
  showOnlyAcceptableFileDesc,
  showFileBadgeDate,
  showFileBadgePreviewButton,
  showFileBadgeDeleteButton,
  fileBadgeDownloadStyle,
  setFileName,
  isUploadDisabled
}) => {
  const onChange = useCallback(
    (file) => {
      onChangeUploadStatus([{ ...file }])
      setFileName(file?.name)
    },
    [onChangeUploadStatus]
  )

  return (
    <Dialog
      title="Upload Utility Data"
      onClose={toggleUploadBillShowModal}
      width="759px"
      className={dialogStyles.withDivider}
      data-testid="utilityUploadModal"
    >
      <div className={dialogStyles.dialogRow}>
        <Flex direction="column">
          <span className={dialogStyles.rowLabel}>
            <TranslateComponent>Download Template</TranslateComponent>
          </span>
          <p>
            <TranslateComponent>
              Use this template to format your utility bill data prior to
              uploading. <br />
              Import .xlsx file of Utility Bill to{' '}
              {locationName ? locationName : ''}
            </TranslateComponent>
          </p>
        </Flex>
        <Flex direction="column">
          <Button
            className="button-gray"
            onClick={downloadTemplate}
            data-testid="downloadButton"
            id="downloadButton"
          >
            <TranslateComponent>Download</TranslateComponent>
          </Button>
        </Flex>
      </div>
      <div className={dialogStyles.dialogRow}>
        <Flex direction="column" width="100%">
          <div
            className={
              errors.duplicate
                ? dropZoneStyles.filesError
                : dropZoneStyles.attachedFiles
            }
          >
            <DenaliFileUpload
              value={fileData?.settings?.file}
              onChange={onChange}
              allowedFileTypes={allowedFileTypes}
              includeDescription={includeDescription}
              showOnlyAcceptableFileDesc={showOnlyAcceptableFileDesc}
              showFileBadgeDate={showFileBadgeDate}
              showFileBadgePreviewButton={showFileBadgePreviewButton}
              showFileBadgeDeleteButton={showFileBadgeDeleteButton}
              fileBadgeDownloadStyle={fileBadgeDownloadStyle}
              location={location}
              errors={errors}
            />
          </div>
        </Flex>
      </div>
      <div className={dialogStyles.dialogRow}>
        <div className={dialogStyles.actions}>
          <Button onClick={onCloseModal} className="button-gray">
            <TranslateComponent>Cancel</TranslateComponent>
          </Button>
          {errors.duplicate ? (
            <Button
              onClick={() => {
                uploadFile('override')
              }}
              disabled={isUploadDisabled}
            >
              <TranslateComponent>Overwrite</TranslateComponent>
            </Button>
          ) : (
            <Button onClick={uploadFile} disabled={isUploadDisabled}>
              <TranslateComponent>Upload</TranslateComponent>
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  )
}
