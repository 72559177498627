import { Button } from '@aws-amplify/ui-react'
import { CSVLink } from 'react-csv'
import { trackEvent } from 'src/amplitude'
import { USER_EVENTS } from 'src/amplitude-categories'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'

interface CSVButtonProps {
  filteredData: []
  dataList: []
  locationId: string
  getCSVOpportunitiesData: (data: []) => []
  getLocationName: () => string
  getOrganizationName: () => string
}

export const CSVButton = ({
  filteredData,
  dataList,
  locationId,
  getCSVOpportunitiesData,
  getLocationName,
  getOrganizationName
}: CSVButtonProps) => {
  return (
    <CSVLink
      data={getCSVOpportunitiesData(
        filteredData.length ? filteredData : dataList
      )}
      filename={`Opportunities-${
        locationId ? getLocationName : getOrganizationName
      }.csv`}
      className="csv-link"
      data-testid="downloadCsvButton"
      onClick={() => {
        trackEvent(USER_EVENTS.OPPORTUNITIES.events.DOWNLOAD_OPPORTUNITY_LIST, {
          format: 'csv'
        })
      }}
    >
      <Button
        key="downloadCsv"
        aria-label="Download Opportunities CSV"
        className="button-icon button-gray"
      >
        <FontAwesomeIcon icon={faFileCsv} />
      </Button>
    </CSVLink>
  )
}

export default CSVButton
