import _uniqBy from 'lodash/uniqBy'
import { useEffect, useMemo, useState } from 'react'

// Project imports
import { listUsersForAssignedToField } from 'src/components/AssignedTo/graphql'
import { useQuery } from 'src/hooks/APIHooks'

export const useUserListOption = (locationId: string) => {
  const [userListOption, setUserListOption] = useState([])
  const {
    data: userList,
    refetch: refetchUserList,
    error,
    loading
  } = useQuery({
    query: listUsersForAssignedToField,
    variables: { id: locationId },
    errorPolicy: 'all',
    dataPath: 'data.searchUserByBuilding',
    onSuccess: (data) => {
      try {
        let newUserList = []
        if (data) {
          const usersInfo = JSON.parse(JSON.parse(data).body).filter((e) => e)
          if (usersInfo) {
            newUserList = _uniqBy(usersInfo, 'email')?.map(
              ({ email, firstName, lastName }) => ({
                key: email,
                label: `${firstName} ${lastName} <${email}>`,
                firstName: firstName,
                lastName: lastName
              })
            )
          }
        }
        newUserList.sort((a, b) => (a?.label > b?.label ? 1 : -1))
        setUserListOption(newUserList)
      } catch (error) {
        setUserListOption([])
      }
    }
  })

  useEffect(() => {
    if (locationId) {
      refetchUserList({ id: locationId })
    }
  }, [locationId])

  return {
    userListOption,
    loading,
    error
  }
}
