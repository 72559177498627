import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react'
import {
  faEllipsis,
  faPen,
  faCopy,
  faTrash,
  faEye,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './table.module.scss'
import translate, { TranslateComponent } from 'src/common/translations'

export const RowActions = ({ rowControl, row }) => {
  const isFunction = typeof rowControl === 'function'
  const control = isFunction && row ? rowControl(row) : rowControl
  const actionTranslatedText = translate('Actions')
  const translations = {
    actions: actionTranslatedText
  }
  return (
    <td className={styles.rowActions}>
      {control && control?.length > 0 && (
        <Menu
          key="rowActionMenu"
          data-testid="rowActionMenu"
          trigger={
            <MenuButton
              variation="link"
              className={styles.button}
              data-testid="rowActionButton"
              aria-label={translations.actions}
              onClick={(event) => event.stopPropagation()}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </MenuButton>
          }
        >
          {control.map((item) => (
            <MenuItem
              key={item.text}
              data-testid={`rowActionButton-${item.text}`}
              onClick={() => item.action(row)}
              className={
                `amplify-button__actions__` + item?.text?.toLowerCase()
              }
            >
              <FontAwesomeIcon
                icon={item?.icon ? item.icon : getRowActionsSetIcon(item.text)}
              />
              <TranslateComponent>{item.text}</TranslateComponent>
            </MenuItem>
          ))}
        </Menu>
      )}
    </td>
  )
}

export const getRowActionsSetIcon = (item) => {
  switch (item) {
    case 'Edit':
      return faPen
    case 'Copy':
      return faCopy
    case 'Delete':
      return faTrash
    case 'Download':
      return faDownload
    case 'View Chart':
      return faEye
    default:
      return faPen
  }
}
