import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TranslateComponent } from 'src/common/translations'
import ClientSideTable from 'src/components/Table/clientSideTable'
import { DenaliTableStyle } from 'src/denali-components/Table/table.d'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'

import moment from 'moment'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import { TEXT_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

export const BuildingSetupList = ({
  sortDirection,
  onSortChange,
  buildings
}) => {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const navigate = useNavigate()
  const [refetchCount, setRefetchCount] = useState(0)

  useEffect(() => {
    if (refetchCount < 2) {
      setRowsAndColumns(buildings, getBuildingInfos(), setColumns, setRows)
      setRefetchCount(refetchCount + 1)
    } else {
      setRefetchCount(0)
    }
  }, [buildings])

  return (
    <ClientSideTable
      testName="building-setup-list"
      header={columns}
      rows={rows}
      rowCheckbox={false}
      rowAction={rowActionCallback(navigate)}
      search={true}
      isShowPagination={true}
      searchFields={[
        'locationName',
        'salesOffice',
        'state',
        'connectivityTypes',
        'offeringsString',
        'weatherData',
        'createdByUser'
      ]}
      onUpdateControlsFunc={onSortChange}
      columnOrderDown={sortDirection}
      tableRowOverflowHidden={false}
      isDenaliTable={true}
      denaliTableStyle={DenaliTableStyle.classic}
    />
  )
}

const getBuildingInfos = () => {
  const buildingInfos = [
    {
      title: <TranslateComponent>Building Name</TranslateComponent>,
      key: 'locationName',
      columnType: 'text',
      width: '120px',
      canSort: true,
      customComponent: (row) => {
        return <>{getLocationNameColumn(row)}</>
      }
    },
    {
      title: <TranslateComponent>Sales Office</TranslateComponent>,
      key: 'salesOffice',
      columnType: 'text',
      width: '90px',
      canSort: true,
      customComponent: (row) => {
        return <>{getSalesOfficeColumn(row)}</>
      }
    },
    {
      title: <TranslateComponent>State</TranslateComponent>,
      key: 'state',
      columnType: 'text',
      width: '80px',
      canSort: true
    },
    {
      title: <TranslateComponent>Connection</TranslateComponent>,
      key: 'connectivityTypes',
      columnType: 'text',
      width: '100px',
      canSort: true,
      customComponent: (row) => {
        return <>{getConnectivityTypesColumn(row)}</>
      }
    },
    {
      title: <TranslateComponent>Offerings</TranslateComponent>,
      key: 'offeringsString',
      columnType: 'text',
      width: '80px',
      canSort: true,
      customComponent: (row) => {
        return <>{getOfferingsStringColumn(row)}</>
      }
    },
    {
      title: <TranslateComponent>Weather Data</TranslateComponent>,
      key: 'weatherData',
      columnType: 'text',
      width: '80px',
      canSort: true
    },
    {
      title: <TranslateComponent>Created By</TranslateComponent>,
      key: 'createdByUser',
      columnType: 'text',
      width: '80px',
      canSort: true
    },
    {
      title: <TranslateComponent>Created On</TranslateComponent>,
      key: 'date',
      columnType: 'text',
      width: '80px',
      canSort: true,
      sortField: 'createdByTimestamp',
      customComponent: (row) => {
        return <>{getDateColumn(row)}</>
      }
    }
  ]

  return buildingInfos
}

function setRowsAndColumns(buildings, buildingInfos, setColumns, setRows) {
  let rows = [],
    columns = []
  rows = Object.keys(buildings).map((id) => ({
    ...buildings[id]
  }))
  columns = buildingInfos
  setColumns(columns)
  setRows(rows)
}

const getLocationNameColumn = (row) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <TranslateComponent>{row.locationName}</TranslateComponent>
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>{row.locationName}</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )
}

const getSalesOfficeColumn = (row) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <TranslateComponent>{row.salesOffice}</TranslateComponent>
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>{row.salesOffice}</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )
}

const getOfferingsStringColumn = (row) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <TranslateComponent>{row.offeringsString}</TranslateComponent>
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>{row.offeringsString}</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )
}

const getConnectivityTypesColumn = (row) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <TranslateComponent>{row.connectivityTypes}</TranslateComponent>
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>{row.connectivityTypes}</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )
}

const getDateColumn = (row) => {
  return moment(row.createdByTimestamp).format(
    getDateFormatByLocale(TEXT_DATE_FORMAT)
  )
}

const rowActionCallback = (navigate) => {
  return (_, row) => {
    if (!row?.id || !row?.accountId) {
      return
    }

    navigate(`${row.id}?organization=${row?.accountId}`)
    return
  }
}
