import { Button, Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'

// Denali components
import { Collapsible } from 'src/denali-components/Collapsible/Collapsible'
import { faChevronUp } from 'src/denali-components/lib/pro-solid-svg-icons'

import { CollapsibleWithActionsProps } from './types'
import styles from './collapsible-with-actions.module.scss'

// A reusable collapsible component with a title, possibly actions and a collapsible button with
// text and a rotating chevron icon.
//
// The toggling of the collapsible is handled by the Collapsible component.
export const CollapsibleWithActions = ({
  actions,
  actionsBuilder,
  closedToggleTitle,
  header,
  headerBuilder,
  openToggleTitle,
  initialState = true,
  useLocalStorage = false,
  localStorageKey,
  children
}: CollapsibleWithActionsProps) => {
  const buildHeader = useCallback(
    (isCollapsed: boolean, onToggle: () => void) => {
      return (
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={`${styles.collapsibleWithActionsHeader} ${
            isCollapsed ? styles.collapsed : ''
          }`}
        >
          {header ?? headerBuilder?.(isCollapsed, onToggle)}
          <Flex direction="row">
            <div className={styles.collapsibleWithActionsButtons}>
              {actions ?? actionsBuilder?.(isCollapsed, onToggle)}
            </div>
            <Button
              className={styles.collapsibleToggle}
              onClick={onToggle}
              size="small"
            >
              {isCollapsed ? closedToggleTitle : openToggleTitle}
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
          </Flex>
        </Flex>
      )
    },
    [header, actions, openToggleTitle, closedToggleTitle]
  )

  return (
    <Collapsible
      initialState={initialState}
      headerBuilder={buildHeader}
      useLocalStorage={useLocalStorage}
      localStorageKey={localStorageKey}
      className={styles.collapsibleWithActions}
    >
      {children}
    </Collapsible>
  )
}
