import { TranslateComponent } from 'src/common/translations'

export const ImageGalleryMissing = () => {
  return (
    <div className="no-image">
      <span>
        <TranslateComponent>No Image</TranslateComponent>
      </span>
    </div>
  )
}
