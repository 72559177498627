import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { sortHelper } from 'src/common/helperFunctions'
import { GET_EQUIPMENT_LIST } from 'src/common/queries/datasource'
import { usePaginateQuery } from 'src/hooks/usePaginateQuery'
import { Equipment } from 'src/pages/issuesFindings/types'

// Local imports.
import { InlineEquipmentSelectorStateProps } from '../types'
import { getEquipmentSelectOptions } from 'src/components/target-path/helper'
import { InlineEquipmentSelectorState } from '../types'
import {
  getColor as getRandomColor,
  randomizeString
} from 'src/denali-components/helpers'
export const useInlineEquipment = ({
  locationId
}: InlineEquipmentSelectorStateProps): InlineEquipmentSelectorState => {
  const [equipments, setEquipments] = useState<Equipment[]>([])
  const [selectedTisObjects, setSelectedTisObjects] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [activeScroll, setActiveScroll] = useState(true)
  const equipmentGroup = useRef(null)

  const {
    data: equipmentsList,
    refetch: refetchEquipmentsList,
    loading: isEquipmentLoading
  } = usePaginateQuery({
    query: GET_EQUIPMENT_LIST,
    variables: { id: locationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment'
  })
  // If locationId changes, refetch the equipments list.
  useEffect(() => {
    refetchEquipmentsList({ id: locationId })
  }, [locationId])

  // If the equipments list changes, sort it and update the equipments state.
  useEffect(() => {
    if (equipmentsList) {
      const sortedEquipmentsList = sortHelper(equipmentsList)
      setEquipments(sortedEquipmentsList)
    }
  }, [equipmentsList])

  const equipmentMappedOptions = getEquipmentSelectOptions(
    equipments,
    selectedTisObjects
  ).map((equipment) => ({
    ...equipment,
    label: equipment.value
  }))

  const resetSearch = useCallback(
    (e) => {
      e.preventDefault()
      //searchEquipment.current.value = ''
      setSearchTerm('')
    },
    [setSearchTerm]
  )

  return {
    equipmentMappedOptions,
    equipments,
    isEquipmentLoading,
    resetSearch,
    selectedTisObjects,
    setSelectedTisObjects,
    searchTerm,
    setSearchTerm,
    activeScroll,
    setActiveScroll,
    equipmentGroup
  }
}
