import PropTypes from 'prop-types'
import { Image, View } from '@react-pdf/renderer'
import PDFBlockWrapper from '../pdf-block-wrapper'
import { CHART } from '../../chart-image/common'
import PDFImage from '../../../../../components/print-components/pdf-images-block/image'

const PdfSavedBeenDoneBlocks = ({
  chartImage,
  image = null,
  title,
  blockName,
  mode,
  images = null
}) => {
  if (!chartImage && !image && !images) {
    return <></>
  }
  const content =
    mode === CHART ? (
      <>{chartImage && <Image source={chartImage} />}</>
    ) : (
      <>{image ? <PDFImage img={{ imageUrl: image }} withBorder={false} /> : <>
        {images?.map((img) => (<View key={img?.imageId}>
          <PDFImage img={{ imageUrl: img?.original }} withBorder={false} />
        </View>))}
      </>}
      </>
    )

  return (
    <>
      {image ? <>
        <PDFBlockWrapper title={title || blockName} >
          {content}
        </PDFBlockWrapper>
      </> : <>
        <PDFBlockWrapper title={title || blockName} />
        {content}
      </>}
    </>
  )
}

PdfSavedBeenDoneBlocks.propTypes = {
  mode: PropTypes.string,
  chartImage: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  blockName: PropTypes.string.isRequired
}

export default PdfSavedBeenDoneBlocks
