import { Button, Flex, RadioGroupField } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useCallback, useMemo } from 'react'

// Denali imports
import { FlyoutMenu } from 'src/denali-components/FlyoutMenu/FlyoutMenu'
import {
  FlyoutMenuContextState,
  FlyoutMenuListItem
} from 'src/denali-components/FlyoutMenu/types'
import {
  faChevronRight,
  faCircleSmall,
  faSort
} from 'src/denali-components/lib/pro-solid-svg-icons'
import formStyles from 'src/denali-components/Form/form.module.scss'

// Project imports
import translate, { TranslateComponent } from 'src/common/translations'

// Local imports
import styles from '../table.module.scss'
import { SortMenuProps } from '../types'
import { SortMenuSubmenu } from './SortMenuSubmenu'

export const SortMenu = ({
  header,
  handleSort,
  selectedColumn,
  isAscending
}: SortMenuProps) => {
  // current strategy for the custom sort options is combining the column number with the ascending boolean.
  const currentSelection = `${selectedColumn}-${isAscending}`

  const handleRadioSelection = (val: string) => {
    const [col, asc] = val.split('-')
    handleSort(Number(col), asc === 'true')
  }

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleRadioSelection(event.target.value)
    },
    [handleRadioSelection]
  )

  const sortElementOptions = useMemo(() => {
    // Filter out null options.
    return header.map(mapHeaderData).filter((f) => f !== null)
  }, [JSON.stringify(header)])

  const sortColumnKeys = useMemo(() => {
    // We need to reduce the sortElementOptions down into an object with the keys as the titles
    // and the values are the column keys.
    return sortElementOptions.reduce((prev, next) => {
      const [sortOption] = next
      prev[`${sortOption.title}`] = sortOption.columnKey
      return prev
    }, {})
  }, [sortElementOptions])

  const translatedSortLabel = translate('Sort')

  const buildIcon = useCallback((sortActive: boolean) => {
    return (
      <Flex
        direction="row"
        justifyContent="flex-end"
        className={styles.menuIconsRow}
      >
        {sortActive && (
          <FontAwesomeIcon
            icon={faCircleSmall}
            color="var(--amplify-colors-blue-50)"
          />
        )}
        <FontAwesomeIcon icon={faChevronRight} />
      </Flex>
    )
  }, [])

  const childSortButtonBuilder = useCallback(
    (
      label: string,
      _: FlyoutMenuContextState,
      __: FlyoutMenuListItem,
      ___: boolean
    ) => {
      return buildChildSortButton(
        label,
        currentSelection,
        sortColumnKeys,
        buildIcon
      )
    },
    [currentSelection, sortColumnKeys, buildIcon]
  )

  const buildSortButton = useCallback(
    (
      label: string,
      parent: FlyoutMenuContextState,
      item: FlyoutMenuListItem,
      isNested: boolean
    ) => {
      return (
        <Button
          className={styles.menuButton}
          size="small"
          data-testid={`tableSortButton`}
          data-nested={isNested ? '' : undefined}
          tabIndex={
            !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1
          }
          role={isNested ? 'menuitem' : undefined}
        >
          <TranslateComponent>{label}</TranslateComponent>
          <FontAwesomeIcon icon={faSort} />
        </Button>
      )
    },
    []
  )

  return (
    <FlyoutMenu label={translatedSortLabel} triggerBuilder={buildSortButton}>
      <RadioGroupField
        className={formStyles.cardRadioGroup}
        label="sortOptions"
        labelHidden={true}
        name="sortOptions"
        value={currentSelection}
        onChange={onChangeHandler}
      >
        <Flex direction="column" className={styles.sortMenu}>
          {sortElementOptions.map((sortOptions) => {
            // Use the preserved original index from sortOptions[0].columnKey.
            const origIndex = sortOptions[0].columnKey
            const label = sortOptions[0].title
            return (
              <FlyoutMenu
                key={origIndex}
                label={label}
                triggerBuilder={childSortButtonBuilder}
              >
                <SortMenuSubmenu
                  key={label}
                  data={sortOptions}
                  selectedValue={currentSelection}
                />
              </FlyoutMenu>
            )
          })}
        </Flex>
      </RadioGroupField>
    </FlyoutMenu>
  )
}

const mapHeaderData = (header, index) => {
  const origIndex = header.originalIndex ?? index
  const headerData = [
    {
      columnKey: origIndex,
      ascending: true,
      key: header.key,
      title: header.title,
      type: header.type,
      hideFromSort: header.hideFromSort,
      value: `${origIndex}-true`
    },
    {
      columnKey: origIndex,
      ascending: false,
      key: header.key,
      title: header.title,
      type: header.type,
      hideFromSort: header.hideFromSort,
      value: `${origIndex}-false`
    }
  ]

  return headerData
}

const buildChildSortButton = (
  label: string,
  currentSelection: string,
  sortColumnKeys: {
    [key: string]: number
  },
  buildIcon: (sortActive: boolean) => React.ReactNode
) => {
  // If this item is selected as the sort, we want to mark it with a little red dot
  // before the chevron.
  const sortActive = currentSelection.includes(
    sortColumnKeys[label]?.toString() ?? null
  )
  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      className={`${styles.menuItem} ${sortActive ? styles.selected : ''}`}
    >
      <span className={styles.menuItemLabel}>{label}</span>
      {buildIcon(sortActive)}
    </Flex>
  )
}
