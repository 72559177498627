
import { useEffect, useState } from 'react'
import { UploadContainer, DownloadInfoContainer, DownloadLabel, UploadTemplateInfo, DownloadTemplateButton, TemplateFileIcon, UtilityLocationLabel, StyledUploadModel } from "../styles"
import Spinner from 'src/components/legacy/components/spinner/spinner'
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'
import colors from 'src/components/legacy/common/colors.json'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/legacy/components/label/label'
import { GET_PRE_SIGNED_URL, UPLOAD_UTILITY_BILL } from "./graphql"
import { useMutation } from 'src/hooks/APIHooks'
import { getSearchParams, getValidFileName } from 'src/common/helperFunctions.js'
import { convertSizeToUnits } from 'src/components/legacy/components/file-select-sc/file-select.sc.jsx'
import Icon from 'src/denali-ui/components/Icon/index'
import { saveAs } from 'file-saver'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { UiMode } from 'src/redux/types/AppTypes'
import { DialogFileUpload } from 'src/denali-components/Dialog/DialogFileUpload'
import { TranslateComponent } from 'src/common/translations'

const UtilityFileUploadModal = ({
	toggleUploadBillShowModal,
	refetchBuildingBillData,
	locationName,
	showUploadBillModal,
	setSuccessNotification,
	buildingFloorArea,
	uiMode
}) => {

	const [t] = useTranslation()
	const [fileData, setFileData] = useState([])
	const [fileName, setFileName] = useState(null)
	const [loadingFile, setloadingFile] = useState(false)
	const [errors, setErrors] = useState({})
	const [fileDescription, setFileDescription] = useState('')

	const searchParams = getSearchParams()
	const buildingId = searchParams?.buildingId

	const isUploadDisabled = fileData.length === 0 || loadingFile || !fileName

	const { onSubmit: getPreSignedUrlMutation } = useMutation({
		query: GET_PRE_SIGNED_URL
	})

	const resetStates = () => {
		setFileData([])
		setFileName(null)
		setErrors({})
	}

	useEffect(() => {
		resetStates()
	}, [showUploadBillModal])

	const onCloseModal = () => {
		setFileDescription('')
		toggleUploadBillShowModal()
	}

	const { onSubmit: uploadFileForProcess, loading: loadingUploadMutation } = useMutation({
		query: UPLOAD_UTILITY_BILL,
		dataPath: 'data.utilityDataUploader',
		errorPolicy: 'global'
	})

	const onRemoveFile = () => {
		resetStates()
	}

	const onChangeUploadStatus = (files) => {
		const uploadFiles = files?.map(({ file }) => {
			const fileDetails = file?.file ? file.file : file
			return {
				fileName: fileDetails?.name,
				title: fileDetails?.title,
				filePath: `${buildingId}/${fileDetails?.name}`,
				fileSize: fileDetails?.size ? convertSizeToUnits(fileDetails?.size) : '',
			}
		})
		setFileData(uploadFiles)
	}

	const uploadFile = async (overrideFlag) => {
		try {
			setloadingFile(true)
			if (fileName && buildingId) {
				setErrors({})
				const inputJSON = {
					buildingId: buildingId,
					fileName: fileName,
					deleteKeys: [],
					floorArea: buildingFloorArea || 0,
					action: overrideFlag === "override" ? "override" : "overridecheck"
				}
				const uploadUtilityResult = await uploadFileForProcess({ requestBody: JSON.stringify(inputJSON) })
				let parsedResult
				if (uploadUtilityResult) {
					parsedResult = JSON.parse(JSON.parse(uploadUtilityResult))
				}
				// If the data presents already, returns with body true
				if (parsedResult && parsedResult?.body === "True") {
					setErrors({ duplicate: true })
				} else {
					setSuccessNotification({
						type: 'upload',
						message: `Successfully uploaded utility bill data for ${locationName}`
					})
					refetchBuildingBillData()
					onCloseModal()
					trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.UPLOAD_UTILITY_BILL_DATA)
				}
			}
		} catch (error) {
			// error message will be thrown by global modal, so we can close the modal
			onCloseModal()
		} finally {
			setloadingFile(false)
		}
	}

	const addFileFunc = async (e) => {
		try {
			const fileName = getValidFileName(e?.name)
			Object.defineProperty(e, 'name', {
				writable: true,
				value: fileName
			})
			setFileName(e.name)
			setloadingFile(true)
			const res = await getPreSignedUrlMutation({
				input: `{"action": "UPLOAD", "key": "${e?.name}", "buildingId": "${buildingId}" }`
			})
			const requestOptions = {
				method: 'PUT',
				body: e,
				redirect: 'follow',
				headers: { 'Content-Type': '' }
			}
			const response = await fetch(
				JSON.parse(res?.data?.getPreSignedUrl).body,
				requestOptions
			)
			return response
		} catch (error) {
			console.log('error', error)
		} finally {
			setloadingFile(false)
		}
	}

	const onDescriptionChange = (e) => {
		setFileDescription(e?.value)
	}

	const downloadTemplate = async () => {
		try {
			return await getPreSignedUrlMutation({
				input: `{"action": "DOWNLOAD", "key": "utility-bill-template.xlsx", "buildingId": "assets" }`
			}).then(async (res) => {
				const requestOptions = {
					method: 'GET',
					redirect: 'follow',
					headers: { 'Content-Type': '' }
				}
				return await fetch(
					JSON.parse(res.data.getPreSignedUrl).body,
					requestOptions
				)
					.then(async (response) => {
						const responseBlob = await response.blob()
						return await saveAs(responseBlob, "utility-bill-template.xlsx")
					})
					.catch((error) => console.log('error', error))
			})
		} catch (error) {
			console.log("template download error")
		}
	}

	const modalConfig = {
		gray: true,
		heading: "Upload Utility Bill Data",
		className: "utility-upload-modal",
		testName: "utility-uploadDataId",
		buttons: errors.duplicate ? [
			{
				text: "Cancel",
				handleClick: onCloseModal,
				type: "cancel"
			},
			{
				text: "Overwrite",
				handleClick: () => { uploadFile('override') },
				type: "submit",
				disabled: fileData.length === 0 || loadingFile
			},
		] : [
			{
				text: "Cancel",
				handleClick: onCloseModal,
				type: "cancel"
			},
			{
				text: "Upload",
				handleClick: uploadFile,
				type: "submit",
				disabled: fileData.length === 0 || loadingFile
			},
		],
		handleClose: onCloseModal
	}

	if (uiMode === UiMode.denali) {
		if (!showUploadBillModal) {
			return <></>
		}

		return (
			<DialogFileUpload
				toggleUploadBillShowModal={toggleUploadBillShowModal}
				locationName={locationName}
				downloadTemplate={downloadTemplate}
				errors={errors}
				fileData={fileData}
				allowedFileTypes={['xlsx']}
				fileDescription={fileDescription}
				onChangeUploadStatus={onChangeUploadStatus}
				showOnlyAcceptableFileDesc={true}
				includeDescription={false}
				onRemoveFile={onRemoveFile}
				onDescriptionChange={onDescriptionChange}
				onCloseModal={onCloseModal}
				uploadFile={uploadFile}
				location={buildingId}
				setFileName={setFileName}
				isUploadDisabled={isUploadDisabled}
			/>
		)
	}

	return (
		<div>
			{showUploadBillModal ? <div>
				<StyledUploadModel {...modalConfig}>
					{loadingUploadMutation && <Spinner className="section-spinner--overlay" />}
					<UploadContainer>
						<DownloadInfoContainer>
							<DownloadLabel><TranslateComponent>Download Template</TranslateComponent></DownloadLabel>
							<UploadTemplateInfo><TranslateComponent>Use this template to format your utility bill data prior to uploading.</TranslateComponent></UploadTemplateInfo>

							<DownloadTemplateButton onClick={downloadTemplate} data-testid="downloadButton" id="downloadButton">
								<TemplateFileIcon
									type="xls-icon"
									color={colors.GREY_4}
								/>
								Download.XLSX</DownloadTemplateButton>
							<UtilityLocationLabel><TranslateComponent>Import .xlsx file of Utility Bill to</TranslateComponent> {locationName ? locationName : ""}</UtilityLocationLabel>
						</DownloadInfoContainer>
						<div className="attached-files">
							<Label
								text={t('Attach Files')}
							/>
							<AttachedFiles
								attachments={fileData?.map((item) => ({
									...item,
									isEditable: true,
									description: fileDescription,
									fileName: item?.title,
									fileId: item?.id,
									fileSize: item?.fileSize
								}))}
								onChangeUploadStatus={(files) => {
									onChangeUploadStatus(files)
								}}
								onDeleteAttachment={(id, name) => onRemoveFile(id, name)}
								onDescriptionChange={(e) => onDescriptionChange(e)}
								addFile={(e) => addFileFunc(e)}
								proposalFileTypes={["xlsx"]}
								showOnlyAcceptableFileDesc={true}
								fieldsToDisplay={['fileName', 'fileSize', 'description']}
							/>
							{errors?.duplicate && <div className='duplicate-error'>
								<div>
									<Icon
										name="Warning"
										width="30px"
										height="30px"
										margin="4px 12px 0px 0px"
										color={colors.RED}
									/>
								</div>
								<div>
									<p><TranslateComponent>This file contains data for months that have already been imported.</TranslateComponent></p>
									<p><TranslateComponent>If you would like to overwrite the overlapping data, click &quot;Overwrite&quot; </TranslateComponent></p>
								</div>
							</div>}
							{errors?.attachments && (
								<p className="error">{t('reports:AttachmentEmpty')}</p>
							)}
						</div>
					</UploadContainer>
				</StyledUploadModel>
			</div> : null
			}
		</div>
	)
}

export default UtilityFileUploadModal