import { fas } from 'src/denali-components/lib/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const IconSquare = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const DynamicFAIconSquare = ({ icon, color, style }) => {
  return (
    <IconSquare style={{ backgroundColor: `${color}20`, ...style }}>
      <FontAwesomeIcon icon={fas[icon]} color={color} />
    </IconSquare>
  )
}
