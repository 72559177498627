import { Button, Flex } from '@aws-amplify/ui-react'
import { useFormContext } from 'react-hook-form'
import { DenaliLoader } from '../Loaders/DenaliLoader'

// Local imports
import { DenaliFormSubmitProps } from './types'

/**
 * A simple submit button that also shows a loader when the form is being submitted
 * @param children - The children to render inside the button
 * @returns React.ReactNode
 */
export const DenaliFormSubmit = ({
  children,
  testName = 'denaliFormSubmitButton'
}: DenaliFormSubmitProps) => {
  const {
    formState: { isSubmitting }
  } = useFormContext()
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="var(--amplify-space-xs)"
    >
      <Button type="submit" isLoading={isSubmitting} data-testid={testName}>
        {children}
        {isSubmitting && <DenaliLoader size="small" />}
      </Button>
    </Flex>
  )
}
