import { Button, Flex, Input } from '@aws-amplify/ui-react'
import moment from 'moment'
import { ChangeEvent, useCallback } from 'react'

// Legacy imports
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

// Denali Components
import { Dialog } from 'src/denali-components/Dialog/Dialog'

// Local imports
import {
  EnergyIntensityDatePickerDialogProps,
  EnergyIntensityDateRange
} from './types'
import { TranslateComponent } from 'src/common/translations'
import styles from './energy-intensity.module.scss'

// A dialog for selecting a date range using two date inputs.
export const EnergyIntensityDatePickerDialog = ({
  isOpen,
  setIsModalOpen,
  chartDates,
  onDatesChange
}: EnergyIntensityDatePickerDialogProps) => {
  // If the user clicks the reset button, reset the dates to the default range.
  const resetDates = useCallback(() => {
    const updatedDates: EnergyIntensityDateRange = {
      startDate: moment(chartDates.chartStartDate).format(BACKEND_DATE_FORMAT),
      endDate: moment(chartDates.chartEndDate).format(BACKEND_DATE_FORMAT)
    }
    onDatesChange(updatedDates)
  }, [chartDates])

  // Update the start date.
  const onUpdateStartDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = moment(e.currentTarget.value).toDate()
      const updatedDates: EnergyIntensityDateRange = {
        startDate: moment(date).format(BACKEND_DATE_FORMAT),
        endDate: moment(chartDates.chartEndDate).format(BACKEND_DATE_FORMAT)
      }
      onDatesChange(updatedDates)
    },
    [chartDates]
  )

  // Update the end date.
  const onUpdateEndDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = new Date(e.currentTarget.value)
      const updatedDates: EnergyIntensityDateRange = {
        startDate: moment(chartDates.chartStartDate).format(
          BACKEND_DATE_FORMAT
        ),
        endDate: moment(date).format(BACKEND_DATE_FORMAT)
      }
      onDatesChange(updatedDates)
    },
    [chartDates]
  )

  return (
    <Dialog
      title="Select Date Range"
      isOpen={isOpen}
      width="500px"
      onClose={() => setIsModalOpen(false)}
      className={styles.energyIntensityDatePickerDialog}
    >
      <Flex
        direction="column"
        gap="1rem"
        className={styles.energyIntensityDatePickerDialogContent}
      >
        {/* Row of date inputs */}
        <Flex direction="row" gap="1rem">
          <Input
            value={chartDates.chartStartDate}
            type="date"
            onChange={onUpdateStartDate}
          />
          <Input
            value={chartDates.chartEndDate}
            type="date"
            onChange={onUpdateEndDate}
          />
        </Flex>
        <Button variation="link" onClick={resetDates}>
          <TranslateComponent>Reset filter</TranslateComponent>
        </Button>
      </Flex>
    </Dialog>
  )
}
