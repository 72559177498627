import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import translate from 'src/common/translations'
import IssuesFindingsList from 'src/pages/issuesFindings/IssuesFindingsList'
import FindingsListButtons from 'src/pages/issuesFindings/findings-list-buttons'
import { MODAL_TYPE } from 'src/constants'

// Denali components
import { faRadar } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { Dialog } from 'src/denali-components/Dialog/Dialog'

// Local imports
import { IssuesAndFindingsContainerProps } from './types.d'

export const IssuesAndFindingsContainer = ({
  uiMode,
  modalType,
  renderConfirmationText,
  deleteFindingHandler,
  hideDeleteDialog,
  handleGetFindingRecords,
  loading,
  locationName,
  organizationName,
  findings
}: IssuesAndFindingsContainerProps) => {
  return (
    <Page
      title={translate('Issues/Findings')}
      icon={<FontAwesomeIcon icon={faRadar} />}
      iconColor={IconColor.blue}
      testid="issues/findings page"
      actions={
        <FindingsListButtons
          loading={loading}
          locationName={locationName}
          organizationName={organizationName}
          allOrganizations={false}
          findings={findings}
          uiMode={uiMode}
        />
      }
    >
      <IssuesFindingsList
        uiMode={uiMode}
        handleGetFindingRecords={handleGetFindingRecords}
      />
      {modalType === MODAL_TYPE.CONFIRMATION && (
        <DialogConfirm
          title="Delete Issue/Finding"
          onAgree={deleteFindingHandler}
          onClose={hideDeleteDialog}
        >
          {renderConfirmationText(modalType)}
        </DialogConfirm>
      )}
      {modalType === MODAL_TYPE.SUCCESS && (
        <Dialog title="Delete Issue/Finding" onClose={hideDeleteDialog}>
          {renderConfirmationText(modalType)}
        </Dialog>
      )}
    </Page>
  )
}
