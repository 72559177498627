import { Block } from 'src/components/layouts'
import { translate } from 'src/common/translations'
import store from 'src/redux/store'

export const LoadTime = ({ loadTime, styles, testName }) => {

  let selectedLanguage = store?.getState().appData?.selectedLanguage
  selectedLanguage = selectedLanguage ? selectedLanguage : "en"

  // Function to render the translated text with the time highlighted in color
  const renderTranslatedText = (text) => {
    const parts = text.split(/(:\s*\d+\.\d+\s*\w+)/);
    return (
      <>
        {parts.map((part, index) =>
          part.match(/(\d+\.\d+\s*\w+)/) ? (
            <span key={index} style={{ color: 'red' }}> {part}</span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  const labelsTranslated = {
    loadTime: translate(`Load time : ${loadTime || 0} s`)
  }

  return (
    <Block data-tesid={testName} {...styles} padding="0 0 0 8px">
      {
        selectedLanguage === "en" ? <>Load time : <span style={{ color: 'red' }}> {loadTime || 0} s</span></>
          : renderTranslatedText(labelsTranslated.loadTime)                    
      }
    </Block>
  )
}
