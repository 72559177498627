import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, Button } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRadar,
  faPlusCircle,
  faEllipsisVertical,
  faNote
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { Page, IconColor } from 'src/denali-components/Page/Page'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import InlineHelp from 'src/components/legacy/components/inline-help/inline-help'
import { translate, TranslateComponent } from 'src/common/translations'
import { PdfDownloadDetailViewPages } from 'src/components/print-components/helpers'
import PdfDownloadDetailView from 'src/components/print-components/pdf-download-detail-view/pdf-download-detail-view'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'
import { AccessControl } from 'src/components/accessControl'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { getUrlSearchParamValue } from 'src/components/legacy/common/helpers'
import { DetailSubHeader } from 'src/denali-pages/Opportunities/OpportunityDetail/DetailSubHeader'
import { getSearchParams } from 'src/common/helperFunctions.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { DetailImpact } from 'src/denali-pages/Opportunities/OpportunityDetail/DetailImpact'
import { DetailFindings } from 'src/denali-pages/Opportunities/OpportunityDetail/DetailFindings'
import { CardDetails } from 'src/denali-components/Cards/CardDetails/CardDetails'
import { FileBadge } from 'src/denali-components/File/FileBadge'
import { getRowActionsSetIcon } from 'src/denali-components/Table/RowActions'
import styles from 'src/denali-components/Page/detailPage.module.scss'

const IssuesAndFindingsDetailContainer = ({
  finding,
  inlineHelpText,
  uiMode,
  pdfFinding,
  deleteFindingSetup,
  deleteFindingHandler,
  getRemoveMessage,
  onAddToOpportunity
}) => {
  const navigate = useNavigate()
  const { buildingId: locationId, organizationId } = getSearchParams()
  const [modalType, setModalType] = useState('')

  const hasDescription = finding.settings?.description?.blocks?.length > 0
  const hasOpportunities =
    finding.opportunities && finding.opportunities.length > 0
  const hasChartOrDescriptionLink =
    finding.settings?.chartLink || finding.settings?.descriptionLink

  const shouldRenderCardDetails =
    hasDescription || hasOpportunities || hasChartOrDescriptionLink

  const notesHeading = translate('Description')

  return (
    <Page
      title={translate('Issues/Findings')}
      icon={<FontAwesomeIcon icon={faRadar} />}
      titleTools={
        <InlineHelp inlineHelpText={inlineHelpText} uiMode={uiMode} />
      }
      iconColor={IconColor.blue}
      actions={getActions(
        pdfFinding,
        uiMode,
        locationId,
        organizationId,
        onAddToOpportunity,
        finding,
        navigate,
        deleteFindingSetup,
        setModalType
      )}
    >
      <DetailSubHeader
        title={finding.name}
        priority={finding.priority}
        isPublic={finding.visibleToCustomer}
        buildingName={finding.location?.name}
        tisObjects={finding.tisObjects}
        dateCreated={finding.settings.proposalDate}
        status={finding.status}
        nextStep={finding.nextStep}
      />
      {finding.settings.kpi.filter((kpi) => kpi.value).length > 0 && (
        <DetailImpact opportunity={finding} />
      )}
      <div className={styles.detailRow}>
        {finding?.settings?.images?.length > 0 && (
          <DetailFindings images={finding.settings.images} findings={[]} />
        )}
        {shouldRenderCardDetails && (
          <CardDetails
            style="notes"
            title={notesHeading}
            icon={<FontAwesomeIcon icon={faNote} />}
            iconColor="gray"
            description={
              hasDescription &&
              finding.settings.description.blocks.map((block) => block.text)
            }
          >
            {hasChartOrDescriptionLink && (
              <div className={styles.detailChart}>
                <TranslateComponent>Chart link:</TranslateComponent>
                {finding.settings.chartLink ? (
                  <>
                    <a href={finding.settings.chartLink}>
                      {finding.settings.descriptionLink ||
                        finding.settings.chartLink}
                    </a>
                  </>
                ) : (
                  <span>{finding.settings.descriptionLink || ''}</span>
                )}
              </div>
            )}
            {hasOpportunities && (
              <div>
                {finding.opportunities.map(({ name, opportunityId }, i) => (
                  <div
                    className={styles.detailChart}
                    key={`opportunity-${opportunityId}`}
                  >
                    <TranslateComponent>Opportunity link:</TranslateComponent>
                    <Link
                      key={i}
                      state={{ mode: ACTIONS.VIEW, id: opportunityId }}
                      to={`/${PAGE_NAMES.OPPORTUNITIES}/${opportunityId}`}
                    >
                      {name}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </CardDetails>
        )}
      </div>
      {finding.settings.file && finding.settings.file.id && (
        <div className={styles.detailColumn}>
          <span className={styles.detailHeading}>
            <TranslateComponent>Attachments</TranslateComponent>
          </span>
          <div className={styles.detailRow}>
            <FileBadge
              fileId={finding.settings.file.id}
              fileFullName={finding.settings.file.displayName}
              name={finding.settings.file.name}
              buildingId={finding.settings.file.buildingId}
              fileSize={''}
              description={finding.settings.file.description}
              showDate={false}
              showDescription={false}
              showPreviewButton={false}
              downloadButton={'icon'}
              deleteFile={false}
            />
          </div>
        </div>
      )}
      {modalType === MODAL_TYPE.CONFIRMATION && (
        <DialogConfirm
          onAgree={deleteFindingHandler}
          title={'Delete Issue'}
          onClose={() => setModalType('')}
        >
          {getRemoveMessage()}
        </DialogConfirm>
      )}
    </Page>
  )
}

IssuesAndFindingsDetailContainer.propTypes = {
  finding: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  inlineHelpText: PropTypes.array,
  handleCreateFindingClicked: PropTypes.func,
  uiMode: PropTypes.number,
  pdfFinding: PropTypes.object,
  deleteFindingSetup: PropTypes.func,
  deleteFindingHandler: PropTypes.func,
  modalType: PropTypes.string,
  getRemoveMessage: PropTypes.func,
  onAddToOpportunity: PropTypes.func
}

export default IssuesAndFindingsDetailContainer

function getControls(navigate, deleteFindingSetup, setModalType) {
  return [
    {
      text: 'Copy',
      action: (finding) => {
        navigate(`/findings/${finding.findingId}`, {
          state: { mode: ACTIONS.COPY, id: finding.findingId }
        })
      }
    },
    {
      text: 'Edit',
      action: (finding) => {
        navigate(`/findings/${finding.findingId}`, {
          state: { mode: ACTIONS.EDIT, id: finding.findingId }
        })
      }
    },
    {
      text: 'Delete',
      action: () => {
        setModalType(MODAL_TYPE.CONFIRMATION)
        deleteFindingSetup()
      }
    }
  ]
}

function getActions(
  pdfFinding,
  uiMode,
  locationId,
  organizationId,
  onAddToOpportunity,
  finding,
  navigate,
  deleteFindingSetup,
  setModalType
) {
  const controls = getControls(navigate, deleteFindingSetup, setModalType)
  return [
    <Tooltip key="downloadPDF">
      <TooltipTrigger>
        <PdfDownloadDetailView
          downloadData={pdfFinding}
          type={PdfDownloadDetailViewPages.FINDING}
          uiMode={uiMode}
        />
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>Download</TranslateComponent>
      </TooltipContent>
    </Tooltip>,
    <AccessControl key="create-new-item" id="tc.pages.findings.add">
      <Link
        to={{
          pathname: '/findings/add',
          search: getUrlSearchParamValue(locationId, organizationId)
        }}
        state={{ mode: ACTIONS.ADD }}
      >
        <Button data-testid={'create-new-button'}>
          <TranslateComponent>Create New</TranslateComponent>
        </Button>
      </Link>
    </AccessControl>,
    <UserNameBubble
      key="CreatedBy"
      showFullName={false}
      showTooltip
      tooltipContent={`Created by ${finding.createdBy}`}
      firstName={finding.createdBy}
      lastName=""
      iconOnly
    />,
    <UserNameBubble
      key="AssignedTo"
      showFullName={false}
      showTooltip
      tooltipContent={
        finding?.assignedTo?.key
          ? `Assigned to ${finding.assignedTo.key}`
          : 'Unassigned'
      }
      firstName={
        finding?.assignedTo?.key ? finding.assignedTo.key : 'Unassigned'
      }
      lastName=""
      iconOnly
      assignedTo
    />,
    <Menu
      key="FindingActions"
      trigger={
        <MenuButton className="button-gray button-icon">
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </MenuButton>
      }
    >
      <AccessControl id="tc.pages.opportunities.add">
        <MenuItem key="add-to-opportunity" onClick={onAddToOpportunity}>
          <FontAwesomeIcon icon={faPlusCircle} />
          <TranslateComponent>Add to Opportunity</TranslateComponent>
        </MenuItem>
      </AccessControl>
      {controls.map((item) => (
        <AccessControl
          key={item.text}
          id={`tc.pages.findings.${
            item.text === 'Copy' ? 'add' : item.text.toLowerCase()
          }`}
        >
          <MenuItem
            key={item.text}
            onClick={() => item.action(finding)}
            className={`amplify-button__actions__${item.text.toLowerCase()}`}
          >
            <FontAwesomeIcon icon={getRowActionsSetIcon(item.text)} />
            <TranslateComponent>{item.text}</TranslateComponent>
          </MenuItem>
        </AccessControl>
      ))}
    </Menu>
  ]
}
