import Opportunity from 'src/models/opportunity'
import { ACTIONS } from 'src/constants'
import {
  KPI_LIST_KEYS,
  KPI_LEVEL_LIST,
  NEXT_STEPS_LIST,
  STATUS_LIST,
  convertTime
} from 'src/components/legacy/common/opportunity'
import {
  DATE_FORMAT,
  BACKEND_DATE_TIME_FORMAT_TZ,
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'

const DEFAULT_PRIORITY = 'Not Prioritized'
const TOTAL = 'total'
const YEAR = 'year'

export const updateOpportunity = (opportunity, mode, userName, currentDate,translateCopyOf='') => {
  const opportunityObj = new Opportunity()
  opportunityObj.opportunityId =
    opportunity.id && ACTIONS.EDIT ? opportunity.id : null
  opportunityObj.name =
    mode === ACTIONS.COPY ? translateCopyOf+" "+ opportunity.title : opportunity.title
  opportunityObj.status = opportunity.status
    ? opportunity.status
    : STATUS_LIST[0]
  opportunityObj.findings = []
  opportunityObj.isPublic = opportunity.isVisible
  opportunityObj.createdBy = opportunity.createdBy
  opportunityObj.organization.organizationId = opportunity.accountId
  opportunityObj.organization.organizationName = opportunity.account?.name ?? null
  opportunityObj.location.locationId = opportunity.buildingId
  opportunityObj.location.locationName = opportunity.building?.name ?? null
  opportunityObj.tisObjects =
    opportunity.equ?.items?.map((eq) => {
      return {
        equipmentOpportunityId: eq?.id,
        tisObjectId: eq?.equipment?.id,
        tisObjectName: eq?.equipment?.name,
        tisObjectType: eq?.equipment?.type
      }
    }) ?? []

  opportunityObj.assignedTo = { key: opportunity.assignedTo }
  const fileDetail = opportunity?.attachments?.items?.find(
    (a) => a?.category === FileUploadType.ADD_FILES
  )
  const settings = _cloneDeep(opportunityObj.settings)
  settings.file = !fileDetail?.id
    ? {}
    : {
        id: fileDetail?.id,
        name: fileDetail?.name,
        buildingId: fileDetail?.buildingId,
        description: fileDetail?.description,
        displayName: fileDetail?.displayName
      }
  settings.images = []
  settings.description = JSON.parse(opportunity.comment)
  settings.priority = opportunity.priority
    ? opportunity.priority
    : DEFAULT_PRIORITY
  settings.nextStep = opportunity.nextStep
    ? opportunity.nextStep
    : NEXT_STEPS_LIST[0]
  settings.proposalDate = [ACTIONS.EDIT, ACTIONS.VIEW, ACTIONS.COPY].includes(
    mode
  )
    ? !opportunity.creationDate
      ? null
      : moment(opportunity.creationDate).format(DATE_FORMAT)
    : currentDate
  settings.proposedBy = [ACTIONS.EDIT, ACTIONS.VIEW, ACTIONS.COPY].includes(
    mode
  )
    ? opportunity.createdBy
      ? opportunity.createdBy
      : null
    : userName

  settings.costSavings.savings.enabled = opportunity.energySavingsDisplay
    ? opportunity.energySavingsDisplay
    : false
  settings.costSavings.savings.tag = opportunity.energySavingsUnit
    ? opportunity.energySavingsUnit
    : 'total'
  settings.costSavings.savings.value = opportunity.energySavings
    ? opportunity.energySavings
    : 0

  settings.costSavings.costOfWaiting.value = opportunity.showCostOfWaiting
    ? opportunity.showCostOfWaiting
    : false
  settings.costSavings.costOfWaiting.startDate = opportunity.startingFrom
    ? moment(opportunity.startingFrom).format(DATE_FORMAT)
    : null

  settings.costSavings.costToImplement.value = opportunity.price
    ? opportunity.price
    : 0
  settings.costSavings.costToImplement.enabled = opportunity.priceDisplay
    ? opportunity.priceDisplay
    : false
  settings.costSavings.costToImplement.priceType = opportunity.priceUnit
    ? opportunity.priceUnit
    : 'estimated'

  settings.costSavings.paybackCalculation.enabled =
    opportunity.paybackType === 'paybackCalculation'
  settings.costSavings.paybackCalculation.value = paybackCalc(
    opportunity.energySavingsUnit,
    opportunity.price,
    opportunity.energySavings,
    opportunity.paybackUnit,
    opportunity.paybackTerm
  )

  settings.costSavings.timeToUse.tag =
    opportunity.paybackType === 'paybackCalculation'
      ? opportunity.paybackUnit
      : 'months'
  settings.costSavings.timeToUse.value =
    opportunity.paybackType === 'paybackCalculation'
      ? opportunity.paybackTerm?.toString()
      : '0'

  settings.costSavings.lifecycle.tag =
    opportunity.paybackType === 'roiCalculation'
      ? opportunity.paybackUnit
      : 'years'
  settings.costSavings.lifecycle.value =
    opportunity.paybackType === 'roiCalculation'
      ? opportunity.paybackTerm?.toString()
      : '0'

  settings.costSavings.roiCalculation.enabled =
      opportunity.paybackType === 'roiCalculation'
    settings.costSavings.roiCalculation.value = calculateROI(settings?.costSavings?.costToImplement?.value,
       settings?.costSavings?.savings, settings?.costSavings?.lifecycle)
  
  settings.kpi = getKPI(opportunity)
  opportunityObj.settings = _cloneDeep(settings)
  return opportunityObj
}
const getTotalSavings = (savings, timeToUse) => {
  // calculate total savings with 'Time to Use' adjusted to the 'Savings' time period
  if (timeToUse?.value !== undefined) {
    return (
      savings.value *
      timeToUse.value *
      convertTime(`${timeToUse.tag}-${savings.tag}`)
    )
  }
}
export const calculateROI = (costToImplement, savings, timeToUse) => {
  if (costToImplement && savings.value !== undefined) {
    const totalSavings =
      savings.tag == 'total'
        ? savings.value
        : getTotalSavings(savings, timeToUse)
    if (totalSavings !== undefined && totalSavings !== 0) {
      return Math.ceil((totalSavings / costToImplement) * 100)
    }
  }

  return 0
}

const paybackCalc = (tag, cost, energySaving, timeTag, timeValue) => {
  let calcValue = 0
  if (tag === 'total') {
    const paybackValue = cost / energySaving
    if (timeTag === 'years') calcValue = paybackValue * timeValue
    if (timeTag === 'months') calcValue = (paybackValue / 12) * timeValue
    if (timeTag === 'weeks') calcValue = (paybackValue / 52) * timeValue
    if (timeTag === 'days') calcValue = (paybackValue / 365) * timeValue
  }
  if (tag === 'months') {
    calcValue = cost / energySaving / 12
  }
  if (tag === 'years') {
    calcValue = cost / energySaving
  }
  return calcValue
}
const roiCalc = (tag, cost, energySaving) => {
  let calcValue = 0
  if (tag === 'total') {
    calcValue = cost / energySaving
  }
  return calcValue
}

export const getKPI = (opportunity) => {
  const kpiList = [
    {
      name: KPI_LIST_KEYS?.EnergyUsage,
      value: getKPIValue(opportunity.impactEnergyDisplay),
      priority: getKPIPriority(opportunity.impactEnergy)
    },
    {
      name: KPI_LIST_KEYS?.Comfort,
      value: getKPIValue(opportunity.impactComfortDisplay),
      priority: getKPIPriority(opportunity.impactComfort)
    },
    {
      name: KPI_LIST_KEYS?.Reliability,
      value: getKPIValue(opportunity.impactReliabilityDisplay),
      priority: getKPIPriority(opportunity.impactReliability)
    },
    {
      name: KPI_LIST_KEYS?.Performance,
      value: getKPIValue(opportunity.impactPerformanceDisplay),
      priority: getKPIPriority(opportunity.impactPerformance)
    },
    {
      name: KPI_LIST_KEYS?.Compliance,
      value: getKPIValue(opportunity.impactComplianceDisplay),
      priority: getKPIPriority(opportunity.impactCompliance)
    },
    {
      name: opportunity.impactCustom1Name,
      value: getKPIValue(opportunity.impactCustom1Display),
      priority: getKPIPriority(opportunity.impactCustom1),
      custom: 1
    },
    {
      name: opportunity.impactCustom2Name,
      value: getKPIValue(opportunity.impactCustom2Display),
      priority: getKPIPriority(opportunity.impactCustom2),
      custom: 2
    }
  ]

  return kpiList
}

const getKPIValue = (value) => {
  return value ? value : false
}
const getKPIPriority = (value) => {
  return value ? value : KPI_LEVEL_LIST[2]
}

const getUserName = (userInfo) => {
  return `${userInfo?.lastName} ${userInfo?.firstName}`
}

export const getOpportunityFormedInput = (opportunityData, userInfo, mode) => {
  const {
    location,
    organization,
    settings,
    name,
    status,
    assignedTo,
    isPublic
  } = opportunityData
  const {
    costSavings,
    kpi,
    description,
    proposalDate,
    proposedBy,
    nextStep,
    priority
  } = settings
  const {
    costOfWaiting,
    costToImplement,
    lifecycle,
    paybackCalculation,
    timeToUse,
    roiCalculation,
    savings
  } = costSavings
  const impactComfort = kpi?.find((k) => k?.name === KPI_LIST_KEYS.Comfort)
  const impactCompliance = kpi?.find(
    (k) => k?.name === KPI_LIST_KEYS.Compliance
  )
  const impactEnergy = kpi?.find((k) => k?.name === KPI_LIST_KEYS.EnergyUsage)
  const impactPerformance = kpi?.find(
    (k) => k?.name === KPI_LIST_KEYS.Performance
  )
  const impactReliability = kpi?.find(
    (k) => k?.name === KPI_LIST_KEYS.Reliability
  )
  const custom1 = kpi?.find((k) => k?.custom === 1)
  const custom2 = kpi?.find((k) => k?.custom === 2)
  let paybackDisplay = false
  let paybackTerm = 0
  let paybackType = ''
  let paybackUnit = ''
  if (paybackCalculation?.enabled && !roiCalculation?.enabled) {
    paybackType = 'paybackCalculation'
    paybackUnit = timeToUse?.tag
    paybackTerm = timeToUse?.value
    paybackDisplay = true
  }
  if (!paybackCalculation?.enabled && roiCalculation?.enabled) {
    paybackType = 'roiCalculation'
    paybackUnit = lifecycle?.tag
    paybackTerm = lifecycle?.value
    paybackDisplay = true
  }

  const inputs = {
    accountId: organization?.organizationId,
    buildingId: location?.locationId,
    createdBy: proposedBy ? proposedBy : getUserName(userInfo),
    creationDate: proposalDate
      ? moment(proposalDate).locale("en").format(BACKEND_DATE_FORMAT)
      : moment().locale("en").format(BACKEND_DATE_FORMAT),
    startingFrom: costOfWaiting?.startDate
      ? moment(costOfWaiting?.startDate).format(BACKEND_DATE_TIME_FORMAT_TZ)
      : null,
    energySavings: savings?.value ?? 0,
    energySavingsDisplay: savings?.enabled,
    energySavingsUnit: savings?.tag,
    impactComfort: impactComfort?.priority,
    impactComfortDisplay: impactComfort?.value,
    impactCompliance: impactCompliance?.priority,
    impactComplianceDisplay: impactCompliance?.value,
    impactCustom1: custom1?.priority,
    impactCustom1Display: custom1?.value,
    impactCustom1Name: custom1?.name,
    impactCustom2: custom2?.priority,
    impactCustom2Display: custom2?.value,
    impactCustom2Name: custom2?.name,
    impactEnergy: impactEnergy?.priority,
    impactEnergyDisplay: impactEnergy?.value,
    impactPerformance: impactPerformance?.priority,
    impactPerformanceDisplay: impactPerformance?.value,
    impactReliability: impactReliability?.priority,
    impactReliabilityDisplay: impactReliability?.value,
    isVisible: isPublic,
    nextStep: nextStep,
    paybackDisplay: paybackDisplay,
    paybackTerm: paybackTerm,
    paybackType: paybackType,
    paybackUnit: paybackUnit,
    price: costToImplement?.value,
    priceDisplay: costToImplement?.enabled,
    priceUnit: costToImplement?.priceType,
    priority: priority,
    salesOfficeId: 'S1',
    showCostOfWaiting: costOfWaiting?.value,
    status: status,
    title: name,
    userId: userInfo?.id,
    assignedTo: assignedTo?.key ? assignedTo?.key : '',
    comment: JSON.stringify(description)
  }

  if (mode === ACTIONS.EDIT) inputs['id'] = opportunityData?.opportunityId

  return inputs
}

export const getEquipmentsForUpsert = (opportunity, tisObjects, mode) => {
  // Getting existing equipments Ids
  const existingEquipmentIds =
    opportunity?.tisObjects?.map((tisObject) => tisObject?.tisObjectId) ?? []
  // Getting new equipments Ids
  const newEquipmentIds =
    tisObjects?.map((tisObject) => tisObject?.tisObjectId) ?? []
  // Getting new equipments List
  const newEquipmentList =
    mode === ACTIONS.COPY
      ? tisObjects
      : tisObjects?.filter(
          (o) => !existingEquipmentIds?.includes(o?.tisObjectId)
        ) ?? []
  // Getting deleting equipments List
  const deleteEquipmentList =
    mode === ACTIONS.COPY
      ? []
      : opportunity?.tisObjects?.filter(
          (finding) => !newEquipmentIds.includes(finding?.tisObjectId)
        ) ?? []
  return { newEquipmentList, deleteEquipmentList }
}
export const getIssuesForUpsert = (opportunity, findings, mode) => {
  // Getting existing issues Ids
  const existingIssuesIds =
    opportunity?.findings?.map((finding) => finding?.findingId) ?? []
  // Getting new issues Ids
  const newIssuesIds = findings?.map((finding) => finding?.findingId) ?? []
  // Getting new issues List
  const newIssuesList =
    mode === ACTIONS.COPY
      ? findings
      : findings?.filter((o) => !existingIssuesIds?.includes(o?.findingId)) ??
        []
  // Getting deleting issues List
  const deleteIssuesList =
    mode === ACTIONS.COPY
      ? []
      : opportunity?.findings?.filter(
          (finding) => !newIssuesIds.includes(finding?.findingId)
        ) ?? []
  return { newList: newIssuesList, deleteList: deleteIssuesList }
}

export const getInitialOpportunity = (
  accountId,
  buildingId,
  currentDate,
  userName
) => {
  const opportunity = _cloneDeep(
    new Opportunity({
      organizationId: accountId,
      locationId: buildingId,
      proposalDate: currentDate,
      proposedBy: userName,
      file: {}
    })
  )

  return opportunity
}

export const getImagesForUpsert = (existingImages, images, mode, oldBuildingId, newBuildingId) => {
  const isBuildingChangedInEditMode = mode === ACTIONS.EDIT && oldBuildingId !== newBuildingId
  // Getting new images Ids
  const newIds = images?.filter((n) => n?.imageId).map((i) => i?.imageId)
  // Getting existing images Ids
  const existingIds = existingImages?.map((i) => i?.imageId)
  // Getting new images List
  const newImageList =
    mode === ACTIONS.COPY || isBuildingChangedInEditMode
      ? images
      : images?.filter((n) => (!existingIds.includes(n?.imageId) && n?.imageId) || (!n?.name && !n?.imageId && (n?.title || n?.caption)))
  // Getting updating images List
  const updateImageList =
    mode === ACTIONS.COPY || isBuildingChangedInEditMode
      ? []
      : images?.filter((n) => n?.hasOwnProperty('originalImageId') && (n?.originalImageId === n?.imageId || !n?.imageId))
  const updateIds = images
    ?.filter((n) => n?.hasOwnProperty('originalImageId') && !n?.imageId)
    .map((u) => u?.originalImageId)
  let deleteImageList = existingImages?.filter(
    (n) => !newIds.includes(n?.imageId) && !updateIds.includes(n?.imageId)
  )
  // Getting image ( delete image alone )
  const deleteImageAlone = images?.filter(
    (n) => n?.hasOwnProperty('originalImageId') && !n?.imageId
  )
  // Getting deleting images List
  deleteImageList =
    mode === ACTIONS.COPY ? [] : isBuildingChangedInEditMode ? existingImages : [...deleteImageList, ...deleteImageAlone]
  return {
    newList: newImageList,
    deleteList: deleteImageList,
    updateList: updateImageList
  }
}

export const getFileForUpsert = (existingFile, currentFile, mode, oldBuildingId, newBuildingId) => {
  const isBuildingChangedInEditMode = mode === ACTIONS.EDIT && oldBuildingId !== newBuildingId
  // Getting new file
  const newFile =
    mode === ACTIONS.COPY || isBuildingChangedInEditMode
      ? currentFile
      : existingFile?.name !== currentFile?.name
      ? currentFile
      : {}
  // Getting deleting file
  const deleteFile =
    mode === ACTIONS.COPY
      ? {}
      : isBuildingChangedInEditMode ? existingFile 
      : existingFile?.name !== currentFile?.name
      ? existingFile
      : {}
  // Getting updating file
  const updateFile =
    mode === ACTIONS.COPY || isBuildingChangedInEditMode
      ? {}
      : currentFile?.name !== newFile?.name &&
        currentFile?.name !== deleteFile?.name
      ? currentFile
      : {}
  return { newFile, deleteFile, updateFile }
}

export const FileUploadType = {
  ADD_FILES: 'Add Files',
  ADD_IMAGES: 'Add Images'
}

export const getOriginal = async (image, downloadURL) => {
  const img = {
    original: null,
    imageId: image?.id,
    originalImageId: image?.id,
    originalTitle: image?.title,
    description: image?.description,
    name: image?.name,
    buildingId: image?.buildingId,
    title: image?.title,
    caption: image?.description,
    imageUrl: null,
    displayName: image?.displayName,
    attachmentOrder: image?.attachmentOrder
  }
  await downloadURL(image?.name, image?.buildingId).then((res) => {
    img.imageUrl = res
    img.original = res
  })
  return img
}

export const calculatePayback = (costToImplement, savings, timeToUse) => {
  if (savings.value && costToImplement !== undefined) {
    const annualSavings =
      savings.tag == TOTAL
        ? savings.value
        : getAnnualSavings(savings, timeToUse)
    if (annualSavings) {
      return costToImplement / annualSavings
    }
  }

  return 0
}

const getAnnualSavings = (savings, timeToUse) => {
  // convert entered savings amount to annual savings
  if (savings.tag == TOTAL) {
    return timeToUse.value
      ? savings.value /
          (timeToUse.value * convertTime(`${timeToUse.tag}-years`))
      : 0
  } else {
    // need to hardcode "years", because timeSelector was added
    return savings.value * convertTime(`years-${savings.tag}`)
  }
}