import { createContext, useContext } from 'react'
import { IssuesAndFindingsAddToOpportunityDialogContextType } from '../types.d'

export const IssuesAndFindingsAddToOpportunityDialogContext =
  createContext<IssuesAndFindingsAddToOpportunityDialogContextType>(null)

export const useIssuesAndFindingsAddToOpportunityDialogContext = () => {
  const context = useContext(IssuesAndFindingsAddToOpportunityDialogContext)

  if (context == null) {
    throw new Error(
      'IssuesAndFindingsAddToOpportunityDialog components must be wrapped in <IssuesAndFindingsAddToOpportunityDialogProvider />'
    )
  }

  return context
}
