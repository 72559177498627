// Project imports
import { formatDollarToPrecision } from 'src/common/currency'
import translate, { TranslateComponent } from 'src/common/translations'

// Legacy imports
import { formatCurrency } from 'src/components/legacy/common/currency'
import { round } from 'src/components/legacy/common/helpers.js'
import { costOfWaitingValue } from 'src/components/legacy/common/opportunity.js'

// Denali components
import styles from 'src/denali-components/Form/form.module.scss'
import { CostSavingsSimple } from 'src/denali-components/Form/CostSavingsSimple'
import { CostSavingsPaybackROI } from 'src/denali-components/Form/CostSavingsPaybackROI'
import { CostSavingsWaiting } from 'src/denali-components/Form/CostSavingsWaiting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faZap,
  faDollar,
  faBadgeDollar,
  faCircleDollarToSlot,
  faCalculator
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { OpportunityCreateFormFinancialsProps } from './types'

export const OpportunityCreateFormFinancials = ({
  values
}: OpportunityCreateFormFinancialsProps) => {
  const data = values?.settings.costSavings

  const savingsOptionList = [
    { label: translate('Per Month'), value: 'months' },
    { label: translate('Per Year'), value: 'years' },
    { label: translate('Total'), value: 'total' }
  ]
  const costToImplementOptionList = [
    { label: translate('Estimated'), value: 'Estimated' },
    { label: translate('Proposed'), value: 'Proposed' }
  ]
  const paybackTitleOptionList = [
    { label: translate('Simple Payback'), value: 'Simple Payback' },
    { label: translate('Simple ROI'), value: 'Simple ROI' }
  ]
  const paybackOptionList = [
    { label: translate('days'), value: 'days' },
    { label: translate('weeks'), value: 'weeks' },
    { label: translate('months'), value: 'months' },
    { label: translate('years'), value: 'years' }
  ]
  const roiOptionList = [
    { label: translate('hours'), value: 'hours' },
    { label: translate('days'), value: 'days' },
    { label: translate('weeks'), value: 'weeks' },
    { label: translate('months'), value: 'months' },
    { label: translate('years'), value: 'years' }
  ]

  return (
    <div className={styles.financialsWrapper}>
      <CostSavingsSimple
        inputValue={data.savings.value}
        inputProperty="settings.costSavings.savings.value"
        selectValue={data.savings.tag}
        selectProperty="settings.costSavings.savings.tag"
        title="Energy Savings"
        primaryFieldLabel="Estimated Savings"
        icon={<FontAwesomeIcon icon={faZap} />}
        iconColor="orange"
        visibility={data.savings.enabled}
        visibilityProperty="settings.costSavings.savings.enabled"
        options={savingsOptionList}
        previewBuilder={(inputValue, selectValue) => {
          return (
            <>
              <hr />
              <div className={styles.cardPreview}>
                <span className={styles.primary}>
                  <TranslateComponent>
                    {formatCurrency(inputValue)}
                  </TranslateComponent>
                </span>
                <span className={styles.disclaimer}>
                  <TranslateComponent>{selectValue}</TranslateComponent>
                </span>
              </div>
            </>
          )
        }}
      />
      <CostSavingsSimple
        inputValue={values?.settings.costSavings.costToImplement.value}
        inputProperty="settings.costSavings.costToImplement.value"
        selectValue={data.costToImplement.priceType}
        selectProperty="settings.costSavings.costToImplement.priceType"
        title="Price"
        primaryFieldLabel="Price"
        icon={<FontAwesomeIcon icon={faDollar} />}
        iconColor="green"
        visibility={data.costToImplement.enabled}
        visibilityProperty="settings.costSavings.costToImplement.enabled"
        options={costToImplementOptionList}
        previewBuilder={(inputValue, selectValue) => {
          return (
            <>
              <hr />
              <div className={styles.cardPreview}>
                <span className={styles.primary}>
                  <TranslateComponent>
                    {formatCurrency(inputValue)}
                  </TranslateComponent>
                </span>
                <span className={styles.disclaimer}>
                  <TranslateComponent>{selectValue}</TranslateComponent>
                </span>
              </div>
            </>
          )
        }}
      />
      <CostSavingsPaybackROI
        values={values?.settings?.costSavings}
        titleOptions={paybackTitleOptionList}
        initialTitleValue={
          data.roiCalculation?.enabled ? 'Simple ROI' : 'Simple Payback'
        }
        paybackInputValue={values?.settings?.costSavings?.timeToUse?.value}
        paybackInputProperty="settings.costSavings.timeToUse.value"
        paybackFieldLabel="Amount of Time For Payback"
        paybackFormula="Cost to Implement divided by savings per year."
        paybackOptions={paybackOptionList}
        paybackSelectValue={data?.timeToUse?.tag}
        paybackSelectProperty="settings.costSavings.timeToUse.tag"
        roiInputValue={values?.settings?.costSavings?.lifecycle?.value}
        roiInputProperty="settings.costSavings.lifecycle.value"
        roiFieldLabel="Estimated Opportunity Lifecycle Time"
        roiFormula="Actual savings divided by cost to implement."
        roiOptions={roiOptionList}
        roiSelectValue={data?.lifecycle?.tag}
        roiSelectProperty="settings.costSavings.lifecycle.tag"
        paybackVisibility={!!data.paybackCalculation?.enabled}
        paybackVisibilityProperty="settings.costSavings.paybackCalculation.enabled"
        roiVisibility={!!data.roiCalculation?.enabled}
        roiVisibilityProperty="settings.costSavings.roiCalculation.enabled"
        icon={<FontAwesomeIcon icon={faCircleDollarToSlot} />}
        iconColor="purple"
        previewBuilder={(values, formula) => {
          let calculatedValue = ''
          if (
            values.paybackCalculation.enabled &&
            values.paybackCalculation.value
          ) {
            calculatedValue = round(
              values.paybackCalculation.value,
              2
            ).toLocaleString()
          } else if (
            values.roiCalculation.enabled &&
            values.roiCalculation.value
          ) {
            calculatedValue = `${round(
              values.roiCalculation.value,
              2
            ).toLocaleString()} %`
          }
          return (
            <>
              <hr />
              <div className={styles.cardPreview}>
                {values.paybackCalculation.enabled && (
                  <span className={styles.primary}>
                    {calculatedValue}{' '}
                    <TranslateComponent>Years</TranslateComponent>
                  </span>
                )}
                {values.roiCalculation.enabled && (
                  <span className={styles.primary}>{calculatedValue}</span>
                )}
                <span className={styles.disclaimer}>
                  <TranslateComponent>
                    {values.costToImplement.priceType}
                  </TranslateComponent>
                </span>
              </div>
              <div className={styles.formula}>
                <FontAwesomeIcon icon={faCalculator} />
                <span>
                  <TranslateComponent>{formula}</TranslateComponent>
                </span>
              </div>
            </>
          )
        }}
      />
      <CostSavingsWaiting
        values={data}
        inputValue={data.costOfWaiting.startDate}
        inputProperty="settings.costSavings.costOfWaiting.startDate"
        title="Cost of Waiting"
        primaryFieldLabel="Starting From"
        visibility={data.costOfWaiting.value}
        visibilityProperty="settings.costSavings.costOfWaiting.value"
        icon={<FontAwesomeIcon icon={faBadgeDollar} />}
        iconColor="turquoise"
        previewBuilder={(values) => {
          const savings = costOfWaitingValue(values)

          if (savings) {
            return (
              <>
                <hr />
                <div className={styles.cardPreview}>
                  <span className={styles.disclaimer}>
                    <TranslateComponent>
                      Potential Savings By Now
                    </TranslateComponent>
                  </span>
                  <span className={styles.primary}>
                    <TranslateComponent>
                      {formatDollarToPrecision(savings?.value)}
                    </TranslateComponent>
                  </span>
                  <span className={styles.discription}>
                    <TranslateComponent>
                      If implemented by {savings?.date}
                    </TranslateComponent>
                  </span>
                </div>
              </>
            )
          }

          return null
        }}
      />
    </div>
  )
}

// enum CostSavingsSimpleFormatPrimary {
//   USD = 'USD',
//   PERCENT = 'percent',
//   DATE = 'date'
// }

// const formatPrimary = (primary: number, primaryFormat: string) => {
//   if (primaryFormat === CostSavingsSimpleFormatPrimary.USD) {
//     return formatCurrency(Math.round(primary), 'USD')
//   }
//   if (primaryFormat === CostSavingsSimpleFormatPrimary.PERCENT) {
//     return `${primary.toLocaleString('en-US')}%`
//   }
//   if (primaryFormat === CostSavingsSimpleFormatPrimary.DATE) {
//     return primary.toLocaleString('en-US')
//   }
//   return primary
// }
