import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole
} from '@floating-ui/react'

// Local imports
import { FlyoutMenuContext } from './FlyoutMenuContext'
import { UseFlyoutMenuProps, UseFlyoutMenuState } from './types'

export const useFlyoutMenu = ({
  placement
}: UseFlyoutMenuProps): UseFlyoutMenuState => {
  const [isOpen, setIsOpen] = useState(false)
  const [hasFocusInside, setHasFocusInside] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const elementsRef = useRef<Array<HTMLButtonElement | null>>([])
  const labelsRef = useRef<Array<string | null>>([])
  const parent = useContext(FlyoutMenuContext)

  const tree = useFloatingTree()
  const nodeId = useFloatingNodeId()
  const parentId = useFloatingParentNodeId()
  const item = useListItem()

  const isNested = parentId != null

  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement ?? isNested ? 'right-start' : 'bottom-start',
    middleware: [
      offset({ mainAxis: isNested ? 24 : 12, alignmentAxis: -12 }),
      flip(),
      shift()
    ],
    whileElementsMounted: autoUpdate
  })

  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true })
  })

  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested
  })

  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context, { bubbles: true })

  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [hover, click, role, dismiss, listNavigation]
  )

  // Callback for handling clicks inside the tree. Default to closing the menu.
  const handleTreeClick = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const onSubMenuOpen = useCallback(
    (event: { nodeId: string; parentId: string }) => {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false)
      }
    },
    [setIsOpen, tree, nodeId, parentId]
  )

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) return

    tree.events.on('click', handleTreeClick)
    tree.events.on('menuopen', onSubMenuOpen)

    return () => {
      tree.events.off('click', handleTreeClick)
      tree.events.off('menuopen', onSubMenuOpen)
    }
  }, [tree, nodeId, parentId])

  // If certain props change then we call a 'menuopen' event with the parentId and nodeId; this
  // will handle closing the menu if parentId/nodeId changes (due to rebuilds or menu being removed from render tree
  // and then added back)
  useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId })
    }
  }, [tree, isOpen, nodeId, parentId])

  // Return props
  return {
    activeIndex,
    context,
    elementsRef,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    hasFocusInside,
    item,
    isNested,
    isOpen,
    labelsRef,
    nodeId,
    parent,
    refs,
    setActiveIndex,
    setHasFocusInside
  }
}
