import { axisBottom, axisLeft, axisRight, axisTop, scaleBand, select } from 'd3'
import moment from 'moment'
import { has, isNil, __ } from 'ramda'
import { useEffect, useMemo } from 'react'
import {getDateFormatByLocale} from 'src/common/chartHelperFunctions'
import store from 'src/redux/store'
const axisTypes: any = {
  axisBottom,
  axisTop,
  axisLeft,
  axisRight
}

export default function useAxis({
  el,
  axisType,
  scale,
  lastUpdatedAt,
  props
}: UseAxisHook) {
  // momoize props
  const axisProps = useMemo(
    () => ({ ...props }),
    [
      props.dimention,
      props.lastUpdatedAt,
      props.tickArguments,
      props.tickFormat,
      props.tickPadding,
      props.tickCount,
      props.tickSize,
      props.tickSizeInner,
      props.tickSizeOuter,
      props.tickValues,
      props.xPosition,
      props.yPosition,
      props.textProps
    ]
  )

  // draw or redraw axis
  useEffect(() => {
    if (axisType && scale && el.current && lastUpdatedAt) {
      const [axis, availableSpace] = initalizeAxis(axisType, scale, axisProps)
      renderAxis(
        el.current,
        axis,
        availableSpace,
        axisProps.dimention,
        axisProps.textProps
      )
    }
  }, [lastUpdatedAt, axisType, axisProps])
}

/**
 * function to initalize axis with given prop values
 *
 * @param type
 * @param scale
 * @param axisProps
 * @returns
 */
function initalizeAxis(type: string, scale: any, axisProps: any = {}) {
  const axis: any = axisTypes[type](scale)

  const {
    tickArguments,
    tickCount,
    tickFormat,
    tickPadding,
    tickSize,
    tickSizeInner,
    tickSizeOuter,
    tickValues
  } = axisProps

  initProp(axis, 'ticks', tickCount)
  initProp(axis, 'tickArguments', tickArguments)
  initProp(axis, 'tickValues', tickValues)
  initProp(axis, 'tickFormat', tickFormat)
  initProp(axis, 'tickSize', tickSize)
  initProp(axis, 'tickSizeInner', tickSizeInner)
  initProp(axis, 'tickSizeOuter', tickSizeOuter)
  initProp(axis, 'tickPadding', tickPadding)

  let ticks = []

  if (tickValues && tickValues.length) {
    ticks = tickValues
  } else {
    ticks = scale.ticks
      ? scale?.ticks?.apply(scale, tickArguments)
      : scale?.domain()
  }

  const tickSizeScale = scaleBand(ticks, scale.range())
  tickSizeScale.rangeRound(scale.range())

  const availableSpace = tickSizeScale.bandwidth()

  return [axis, availableSpace]
}

/**
 * Render Axis on given element
 *
 * @param el
 * @param axis
 */
function renderAxis(
  el: any,
  axis: any,
  labelVisibleSpace,
  axisDiM: AxisDimention,
  props: TextProps = {}
) {
  const {
    textAnchor,
    textRotate,
    breakLabelTextBy = ' ',
    enableAutoGroupForDateLabel = false
  } = props

  const axisContainer = select(el)
    .call(axis)
    .selectAll('text')
    .call(function (t) {
      let isAnyOfTickLabelOverlapped = false

      // For now it supports for x axis only
      if (axisDiM === 'y') return

      const availableNodes = t.nodes().length

      let displayAt = 1

      // Below applies when date range is greather than two years
      if (enableAutoGroupForDateLabel && availableNodes > 24) {
        displayAt =
          availableNodes < 49
            ? 2
            : availableNodes < 61
            ? 3
            : availableNodes < 97
            ? 4
            : 6
      }

      t.each(function () {
        const textLength = (this as any).getComputedTextLength()

        isAnyOfTickLabelOverlapped =
          isAnyOfTickLabelOverlapped || labelVisibleSpace < textLength
      })

      t.each(function (d: any, index: number) {
        const self = select(this).attr('class', 'axis-text')

        if (textRotate !== undefined) {
          self.style('transform', `rotate(${textRotate})`)
        }

        if (index % displayAt !== 0) {
          self.text(null)
        } else if (isAnyOfTickLabelOverlapped || breakLabelTextBy?.trim()) {
          const text: string = self.text()
          const tspans = labelFomratter(
            this,
            text,
            labelVisibleSpace,
            breakLabelTextBy
          )

          if (tspans.length > 1) {
            self.text(null)
            tspans.forEach((txt, idx) => {
              self
                .append('tspan')
                .attr('x', 0)
                .attr('dy', 1 + idx * 0.2 + 'em')
                .text(tspans[idx])
            })
          }
        }
      })
    })

  if (textAnchor) axisContainer.attr('text-anchor', textAnchor)
}

function initProp(axis: any, prop: string, value: any) {
  if (!has(prop, axis)) return

  const fn: any = axis[prop]

  // apply default value if no values are provide
  isNil(value) ? fn() : fn(value)
}

function labelFomratter(
  scope: any,
  labelText: string,
  maxSize: number,
  splitBy = ' '
) {
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";
  const actualSize = (scope as any).getComputedTextLength()
  let txtToSplit = labelText
  let splitByValue = splitBy
  if(selectedLanguage !== "en"){
    const isDate = moment(labelText).isValid()
    txtToSplit = isDate ? moment(labelText).format(getDateFormatByLocale("llll")) : labelText
    splitByValue = selectedLanguage === "ar" ? " " : splitBy
  }
  const texts = txtToSplit.split(splitByValue)

  const tspan = []

  texts.some((text, i) => {
    const size = scope.getSubStringLength(0, text.length)

    const remainingSize = actualSize - (size + (i + 1))

    const isFit = maxSize > remainingSize

    tspan.push(text)

    if (isFit) {
      const rText = texts.slice(i + 1, text.length).join(splitBy)
      rText && tspan.push(rText)
    }

    return isFit
  })

  return tspan
}
