import * as Plot from '@observablehq/plot'
import * as d3 from 'd3'
import { useCallback } from 'react'

// Project imports
import { useEnergyIntensityBenchmarksAndMarkersContext } from './hooks/use-energy-intensity-benchmarks-and-markers-context'

// Denali component imports
import PlotChart from 'src/denali-components/PlotChart/PlotChart'

// Local imports
import {
  eciLineGradientDef,
  euiLineGradientDef,
  euiLineGradientTranslucentDef
} from './constants'
import {
  createBenchmarkRule,
  createMarkerRule,
  createXDomainConfig,
  formatEuiYAxisValues,
  getLineChartMarksOrder,
  LINE_CHART_MARKS_ORDER
} from './helpers'
import {
  ChartDataItem,
  EnergyIntensityBenchmark,
  EnergyIntensityChartProps,
  EnergyIntensityMarker,
  MarkNames
} from './types.d'
import { useEnergyIntensityMarksOrder } from './hooks/use-energy-intensity-marks-order'

export const EnergyIntensityLineChart = ({
  chartData,
  chartDates,
  euiEnabled,
  eciEnabled,
  diffDays,
  isDefaultYAxis = false,
  customMinMax
}: EnergyIntensityChartProps) => {
  // Get the benchmarks and markers from the context.
  const { benchmarks, markers } =
    useEnergyIntensityBenchmarksAndMarkersContext()

  // Callback function to get the order of the marks based on the value type.
  const getOrder = useCallback(getLineChartMarksOrder, [])

  // Configure the hook to track the order of the marks.
  const { marksOrder } = useEnergyIntensityMarksOrder(
    LINE_CHART_MARKS_ORDER,
    getOrder
  )

  // Need to take the filtered benchmarks and turn them into ruleY marks.
  const { benchmarkMarks, benchmarkTriggerMarks } = getBenchmarkMarks(
    benchmarks?.filter((b) => b.checked)
  )

  // Need to take the filtered markers and turn them into ruleX marks. We need to make sure we filter
  const { markerMarks, markerTriggerMarks } = getMarkerMarks(
    markers?.filter((m) => m.checked)
  )

  // @TODO: Follow up with Magenta to clarify if the line chart indeed displays the benchmarks and markers
  // above the other marks.
  // // Configure event handlers for the elements that trigger the reordering of the marks.
  // const beforeAppendCallback = useCallback((plot: Plot.Plot) => setupPlotEvents(plot, setPlotValueType), [])

  // // Remove the event listeners from the benchmarkRuleTrigger and markerRuleTrigger marks.
  // const beforeRemoveCallback = useCallback(cleanupPlotEvents, [])

  // Filter the data by type.
  const euiItems = chartData.filter((d) => d.type === 'eui' && d?.eui !== null)
  const eciItems = chartData.filter((d) => d.type === 'eci' && d?.eci !== null)

  // Create an array of ECI values, make sure to include 0.
  const eciDataValues = [0, ...eciItems.map((d) => d.eci)]

  // Create an array of EUI values, including benchmarks,
  const euiDataValues = [
    0,
    ...euiItems.map((d) => d.eui),
    ...benchmarks.filter((b) => b.checked).map((b) => b.value)
  ]

  // If we have a custom min mix, we need to create a domain of values for the Y axis.
  const euiYDomain =
    customMinMax && !isDefaultYAxis
      ? [customMinMax.section1min, customMinMax.section1max]
      : [0, d3.max(euiDataValues)]
  const eciYDomain =
    customMinMax && !isDefaultYAxis
      ? [customMinMax.section2min, customMinMax.section2max]
      : d3.extent(eciDataValues, (d: number) => d)

  // Now let's create a scale using ECI values as the domain and the range is using EUI values. This will
  // give us a "scale" that we can use to feed it an ECI value it will convert internally to an EUI value
  // and plot the X/Y accordingly.
  const eciScale = d3.scaleLinear(eciYDomain, euiYDomain)

  // Create the x domain config
  const { xConfig } = createXDomainConfig({ chartDates, diffDays })

  // This function takes in the mark names and returns an array of renderable marks.
  // For example, if the marks are [MarkNames.GRADIENT, MarkNames.EUI, MarkNames.ECI, MarkNames.MARKER, MarkNames.BENCHMARK]
  // then the function will return an array of marks like:
  // [
  //   () => euiLineGradientTranslucentDef,
  //   () => euiLineGradientDef,
  //   () => eciLineGradientDef,
  //   Plot.areaY, // eui area
  //   Plot.lineY, // eui line
  //   Plot.areaY, // eci area
  //   Plot.lineY, // eci line
  //   enabledMarkerMarks,
  //   enabledMarkerTriggerMarks,
  //   enabledBenchmarkMarks,
  //   enabledBenchmarkTriggerMarks,
  //   Plot.ruleY[0]
  // ]
  const getOrderedMarks = useCallback(
    (marks: MarkNames[]): Plot.Markish[] => {
      return marks.reduce((acc, m) => {
        switch (m) {
          case MarkNames.GRADIENT:
            return [
              ...acc,
              () => euiLineGradientTranslucentDef,
              () => euiLineGradientDef,
              () => eciLineGradientDef
            ]
          case MarkNames.MARKER:
            return [...acc, markerMarks, markerTriggerMarks]
          case MarkNames.BENCHMARK:
            return [...acc, benchmarkMarks, benchmarkTriggerMarks]
          case MarkNames.EUI:
            return getRenderEuiMarks(acc, euiItems, euiEnabled)
          case MarkNames.EUI_TIP:
            return getRenderTipMarks(
              acc,
              euiItems,
              'eui',
              (d) => `${d?.eui.toFixed(0)} EUI`,
              euiEnabled
            )
          case MarkNames.ECI:
            return getRenderEciMarks(acc, eciItems, eciEnabled, eciScale)
          case MarkNames.ECI_TIP:
            return getRenderTipMarks(
              acc,
              eciItems,
              'eci',
              (d) => `${d?.eci?.toFixed(2)} ECI`,
              eciEnabled
            )
        }
      }, [])
    },
    [
      marksOrder,
      eciEnabled,
      euiEnabled,
      euiItems,
      eciItems,
      markerMarks,
      benchmarkMarks
    ]
  )

  // Create an array of marks, but only render the ones that are enabled.
  const marks: Plot.Markish[] = [
    // Create the second Y Axis for ECI.
    Plot.axisY(eciScale.ticks(diffDays > 365 ? 8 : 6), {
      color: 'gray',
      anchor: 'right',
      tickSize: 0,
      y: eciScale,
      tickFormat: (d) => `${d.toFixed(2)} ECI`
    }),
    getOrderedMarks(marksOrder),
    Plot.ruleY[0]
  ]

  // Return the PlotChart component.
  return (
    <PlotChart
      clip={false}
      y={{
        axis: 'left',
        grid: true,
        label: null,
        ticks: diffDays > 365 ? 8 : 6,
        tickSize: 0,
        domain: euiYDomain,
        tickFormat: formatEuiYAxisValues,
        nice: true
      }}
      x={xConfig}
      marks={marks}
      autoSize={true}
    />
  )
}

const getRenderTipMarks = (
  acc: Plot.Markish[],
  items: ChartDataItem[],
  value: string,
  title: string | ((d: ChartDataItem) => string),
  enabled: boolean,
  scale?: d3.ScaleLinear<number, number>
) => {
  if (enabled) {
    // If we pass a scale, apply the scaling to the value.
    const valueFunc = scale !== undefined ? (v) => scale(v.eci) : value
    return [
      ...acc,
      Plot.tip(
        items,
        Plot.pointer({
          x: 'date',
          y: valueFunc,
          pointer: 'xy',
          stroke: 'none',
          anchor: 'left',
          title: title
        })
      )
    ]
  }

  return acc
}

const getRenderEuiMarks = (
  acc: Plot.Markish[],
  items: ChartDataItem[],
  enabled: boolean
) => {
  if (enabled) {
    return [
      ...acc,
      Plot.areaY(items, {
        x: 'date',
        y: 'eui',
        fill: 'url(#euiLineTranslucentGradient)',
        curve: 'cardinal',
        clip: true
      }),
      Plot.lineY(items, {
        x: 'date',
        y: 'eui',
        stroke: 'url(#euiLineGradient)',
        strokeWidth: 3,
        curve: 'cardinal',
        clip: true
      })
    ]
  }

  return acc
}

const getRenderEciMarks = (
  acc: Plot.Markish[],
  items: ChartDataItem[],
  enabled: boolean,
  scale: d3.ScaleLinear<number, number>
) => {
  if (enabled) {
    return [
      ...acc,
      Plot.areaY(
        items,
        Plot.mapY((D) => D.map(scale), {
          x: 'date',
          y: 'eci',
          fill: 'url(#eciLineGradient)',
          curve: 'cardinal',
          tension: 0.3,
          clip: true
        })
      ),
      Plot.lineY(
        items,
        Plot.mapY((D) => D.map(scale), {
          x: 'date',
          y: 'eci',
          stroke: '#F1DCA9',
          strokeWidth: 3,
          curve: 'cardinal',
          tension: 0.3,
          clip: true
        })
      )
    ]
  }

  return acc
}

// Take a list of markers and create two sets of marks; one is for "triggering" the hover effect.
const getMarkerMarks = (
  filteredMarkers: EnergyIntensityMarker[]
): { markerMarks: Plot.Markish[]; markerTriggerMarks: Plot.Markish[] } => {
  const markerMarks = filteredMarkers.map((m) =>
    createMarkerRule({ value: m.markerDate, name: m.name })
  )
  const markerTriggerMarks = filteredMarkers.map((m) =>
    createMarkerRule({
      value: m.markerDate,
      name: m.name,
      stroke: 'transparent',
      strokeWidth: 10,
      strokeDasharray: 0,
      showTip: false,
      className: 'markerRuleTrigger'
    })
  )

  return { markerMarks, markerTriggerMarks }
}

// Take a list of benchmarks and create two sets of marks; one is for "triggering" the hover effect.
const getBenchmarkMarks = (
  filteredBenchmarks: EnergyIntensityBenchmark[]
): {
  benchmarkMarks: Plot.Markish[]
  benchmarkTriggerMarks: Plot.Markish[]
} => {
  const benchmarkMarks = filteredBenchmarks.map((b) =>
    createBenchmarkRule({ value: b.value, name: b.name })
  )
  const benchmarkTriggerMarks = filteredBenchmarks.map((b) =>
    createBenchmarkRule({
      value: b.value,
      name: b.name,
      stroke: 'transparent',
      strokeWidth: 10,
      showTip: false,
      className: 'benchmarkRuleTrigger'
    })
  )

  return { benchmarkMarks, benchmarkTriggerMarks }
}
