import styled, { css } from 'styled-components'
import Icon from '../Icon'
import Button from '../Button'
import Box from '../Box'

export const StyledButton = styled(Button)`
  min-width: 80px;
  padding: 8px 15px;

  ${(props) =>
    props.isAlert &&
    css`
      border: 1px solid #991909;
      background-color: white;
      color: #991909;
      :focus {
        background-color: #991909;
        color: white;
      }
      :hover {
        background-color: #991909;
        color: white;
      }
    `}
`

export const ModalContainer = styled.div`
  height: ${props => props.height ? props.height : "400px"};
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`

export const ModalHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
`
export const ModalHeaderContainer = styled.div`
  font-weight: bold;
  font-size: 16px;

  .icon {
    font-size: 22px;
    margin-right: 6px;
  }
`
export const ModalTitle = styled.div`
  padding-left: 40px;
  width: 100%;
  color: rgb(30, 30, 30);
  font-size: 28px;
  font-weight: bold;
  line-height: 35px;
  box-sizing: border-box;
`

export const ModalContentContainer = styled.div`
  margin-left: 40px;
  box-sizing: border-box;
  text-align: justify;
  word-break: break-word;
  word-wrap: break-word;
  color: inherit;
  height: ${props => props.height ? props.height : "208px"};
  width: 446px;
  color: rgb(85, 85, 85);
  font-size: 16px;
  line-height: 24px;
  p {
    margin: 0;
  }
`

export const AlertBar = styled.div`
  width: 100%;
  height: 10px;
  background: #991909;
  border-radius: 4px 4px 0px 0px;
`

export const CloseButtonContainer = styled(Icon)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 20px 0px 0;
  align-self: flex-end;
`
export const ModalPanelContainer = styled.div`
  ${(props) => props.hasError && `display: flex;`}
  padding: 0;
  background: inherit;
  border-radius: 0;
  width: 100%;
  ${(props) =>
    props.isGray &&
    `padding: 16px 10px;
    background: #ffffff;
    border-radius: 6px;
   `}
`

export const ModalFooterButtonRowContainer = styled.div`
  width: 100%;
  min-height: 44px;
  padding: 19px 44px;
  border-top: 1px solid #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  box-sizing: border-box;
  gap: 20px;
`
