import { Button, Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { forwardRef, useCallback, useState } from 'react'

// Denali imports
import { Dialog } from 'src/denali-components/Dialog/Dialog'
import {
  faSpinner,
  faMagnifyingGlass
} from 'src/denali-components/lib/pro-solid-svg-icons/index'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'

// Local imports
import styles from './form.module.scss'
import { useUserListOption } from './hooks/use-user-list-option'
import { DenaliAssignedToProps } from './types'

export const DenaliAssignedTo = forwardRef(function DenaliAssignedToComponent(
  { locationId, value, onChange }: DenaliAssignedToProps,
  _
) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { userListOption, loading, error } = useUserListOption(locationId)

  const onChangeHanlder = useCallback(
    (key: string) => {
      onChange({ key: key })
      setIsOpen(false)
    },
    [onChange]
  )

  const filteredUserList = userListOption.filter((user) =>
    user.label.toLowerCase().includes(searchTerm)
  )

  if (loading || error) {
    return (
      <Button className={styles.buttonWrapper} variation="link">
        <FontAwesomeIcon icon={faSpinner} className={styles.spin} />
      </Button>
    )
  }

  return (
    <>
      <Button
        id={'assignedTo'}
        onClick={() => setIsOpen(true)}
        className={styles.buttonWrapper}
        variation="link"
      >
        <UserNameBubble
          firstName={!value?.key ? 'Not Assigned' : value.key}
          lastName={''}
          showFullName
          assignedTo
        />
      </Button>
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={styles.dialogWrapper}
        title={'Assign to'}
      >
        <hr />
        <div className={styles.searchContainer}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <Input
            className={styles.searchInput}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            placeholder={'Search'}
          />
        </div>

        <div className={styles.scrollContainer}>
          {filteredUserList.map(({ key, firstName, lastName }) => (
            <Button
              key={key}
              className={styles.userOptionWrapper}
              onClick={() => onChangeHanlder(key)}
              variation={'link'}
            >
              <UserNameBubble
                firstName={firstName ?? 'Not Assigned'}
                lastName={lastName}
                showFullName
                assignedTo
              />
            </Button>
          ))}
        </div>
      </Dialog>
    </>
  )
})
