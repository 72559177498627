export const GET_BUILDING_EQUIPMENT_TYPES = /* GraphQL */ `
  query getBuildingEquipmentTypes($requestBody: AWSJSON!) {
    getBuildingEquipmentTypes(body: $requestBody)
  }
`

export const getEquipmentsByBuildingIDAndType = /* GraphQL */ `
  query listEquipmentsByBuildingAndActiveAndType(
    $buildingId: ID!
    $type: String,
    $nextToken: String
  ) {
    listEquipmentsByBuildingAndActiveAndType(
      buildingId: $buildingId
      isActiveIsTISType: { eq: { isActive: 1, isTIS: 1, type: $type } }
      nextToken: $nextToken
    ) {
      items {
        id
        uri
        type
        name
        parentId
        family
        isParent
        isActive
      }
      nextToken
    }
  }
`

export const GET_EQUIPMENT_PROPERTIES_BY_ID = `query GET_DEFAULT_DATA_SOURCE_PROPERTY ($equipmentId: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $equipmentId, filter: {targetDataGroup: {eq: "Analog"}}, limit: 10000, propertyType: {eq: "Property"}) {
    items {
      id
      propertyType
      tisDisplayName
      targetDataGroup
      targetKey
      targetMeasureUnit
      sourceMeasureUnit
    }
  }
}
`

//To fetch data for Equipment Mapping Calculated  // ID FOR Mutation
export const GET_EQUIPMENT_CALCULATED_PROPERTIES_BY_ID = `query GET_DATA_MAPPING_CALCULATED($equipmentId: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $equipmentId,  propertyType: {eq: "Calculated"}, filter: {targetDataGroup: {eq: "Analog"}}, limit: 1000) {
    items {
      id
      propertyType
      tisDisplayName
      targetDataGroup
      targetKey
      targetMeasureUnit
      sourceMeasureUnit
    }
  }
}

`

export const GET_PROPERTIES_SYMBOL = `query ListConfigs ($filter: ModelConfigFilterInput) {
  listConfigs(filter: $filter, limit: 1000) {
    nextToken
    items {
      type
      value
      subType
    }
  }
}
`

export const GET_BUILDINGS_BY_ACCOUNTS = `query GET_BUILDINGS_BY_ACCOUNTS($id: ID!) {
  listBuildingsByAccount(accountId: $id,limit:200) {
    items {
    salesOfficeCity
    salesOfficeCode
    salesOfficeName
    salesOfficeDistrict
    offeringCodes
    address
    country
    crmCustId
    floorArea
    postalCode
    state
    city
    name
    id
    accountId
    accountName
    tz
    salesOfficeId
    }
  }
}
`

export const SEARCH_BUILDINGS_BY_ACCOUNT = `
query SEARCH_BUILDINGS($filter: SearchableBuildingFilterInput, $nextToken: String) {
  searchBuildings(
    filter: $filter,
    nextToken: $nextToken
  ) {
    items {
      salesOfficeCity
      salesOfficeCode
      salesOfficeName
      salesOfficeDistrict
      offeringCodes
      address
      country
      crmCustId
      floorArea
      postalCode
      state
      city
      name
      id
      accountId
      accountName
      tz
      salesOfficeId
      }
      nextToken
  }
}
`
