import { useState, useRef } from 'react'
import translate, { TranslateComponent } from 'src/common/translations'
import dropZoneStyles from 'src/denali-components/DropZone/drop-zone.module.scss'
import { Input, Label, DropZone } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faImage,
  faExclamationCircle
} from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { FileBadge } from 'src/denali-components/File/FileBadge'
import moment from 'moment'
import { MODAL_TYPE } from 'src/constants'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import {
  getMimeTypes,
  convertBytesToString
} from 'src/denali-components/DropZone/helpers'
import {
  DropZoneAccepted,
  DropZoneRejected,
  DropZoneDefault
} from 'src/denali-components/DropZone/DropZoneComponents'

export const FileDropZone = ({
  fileName,
  fileContent,
  status,
  showOnlyAcceptableFileDesc,
  allowedFileTypes,
  allowMultipleFiles,
  uploadType = 'file',
  errors,
  fileTypes,
  fileChangeFunction,
  removeFileFunction,
  includeDescription,
  description,
  onDescriptionChange,
  showFileBadgeDate,
  showFileBadgePreviewButton,
  showFileBadgeDeleteButton,
  fileBadgeDownloadStyle
}) => {
  const fileInput = useRef(null)

  const showConfirm = () => {
    setModalType(MODAL_TYPE.CONFIRMATION)
  }

  const hideModalDialog = () => {
    setModalType('')
  }

  const [modalType, setModalType] = useState('')

  const optionsTranslated = {
    removeFile: translate('Remove file')
  }

  return (
    <>
      {errors?.duplicate && (
        <div className={dropZoneStyles.duplicateError}>
          <div>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div>
            <TranslateComponent>
              This file contains data for months that have already been
              imported. <br />
              If you would like to overwrite the overlapping data, click
              Overwrite{' '}
            </TranslateComponent>
          </div>
        </div>
      )}

      {errors?.attachments && (
        <p className={dropZoneStyles.error}>
          <TranslateComponent>Missing Attachment</TranslateComponent>
        </p>
      )}

      {/* We will always show the dropzone, allow multiple will determine if files get added or replaced */}
      {/* {!fileName || (fileName && allowMultipleFiles) ? ( */}
      <DropZone
        className={dropZoneStyles.dropZone}
        data-testid="files-select"
        onDropComplete={({ acceptedFiles, rejectedFiles }) => {
          fileChangeFunction({ target: { files: acceptedFiles } })
          // setFile(acceptedFiles[0])
        }}
        onClick={() => fileInput.current.click()}
        acceptedFileTypes={
          showOnlyAcceptableFileDesc
            ? getMimeTypes(allowedFileTypes)
            : getMimeTypes(fileTypes)
        }
      >
        <DropZoneDefault>
          <FontAwesomeIcon
            icon={uploadType === 'image' ? faImage : faDownload}
          />
          <span className={dropZoneStyles.dropZoneText}>
            <TranslateComponent>Drop {uploadType} here</TranslateComponent>
            <br />{' '}
            <span>
              <TranslateComponent>
                or click to choose {uploadType} to upload
              </TranslateComponent>
            </span>
          </span>
          <AllowedFileTypeDescription
            showOnlyAcceptableFileDesc={showOnlyAcceptableFileDesc}
            allowedFileTypes={allowedFileTypes}
            fileTypes={fileTypes}
          />
          <Input
            id="file-upload"
            type="file"
            ref={fileInput}
            accept={
              showOnlyAcceptableFileDesc
                ? `.${allowedFileTypes.join(', .')}`
                : `.${fileTypes.join(', .')}`
            }
            onChange={(e) => {
              fileChangeFunction(e)
            }}
            hidden={true}
            disabled={status === 'uploading' || !location}
          />
        </DropZoneDefault>
        {/* TODO: get some guidance on content for Acceptable */}
        <DropZoneAccepted>
          <span className={dropZoneStyles.dropZoneText}>
            <TranslateComponent>Acceptable file format</TranslateComponent>
          </span>
        </DropZoneAccepted>
        <DropZoneRejected>
          <span className={dropZoneStyles.dropZoneText}>
            <TranslateComponent>
              This file will not be uploaded. File type is not allowed.
              Recommended file types include{' '}
              {showOnlyAcceptableFileDesc
                ? `.${allowedFileTypes.join(', .')}`
                : `.${fileTypes.join(', .')}`}
            </TranslateComponent>
          </span>
        </DropZoneRejected>
      </DropZone>
      {/* ) : null} */}

      {fileName && (
        <FileBadge
          fileId={fileContent && fileContent?.name}
          fileFullName={fileContent?.displayName}
          name={fileContent?.displayName}
          buildingId={fileContent?.buildingId}
          fileSize={convertBytesToString(fileContent?.size)}
          description={fileContent?.description}
          date={moment(fileContent?.attachedAt).format('LLL')}
          showDate={showFileBadgeDate}
          showDescription={false}
          showPreviewButton={showFileBadgePreviewButton}
          downloadButton={fileBadgeDownloadStyle}
          deleteFile={showFileBadgeDeleteButton}
          deleteFunc={showConfirm}
        />
      )}

      {/* Description field */}
      {includeDescription && (
        <div
          className={
            fileName
              ? dropZoneStyles.descriptionField
              : dropZoneStyles.descriptionFieldHidden
          }
        >
          <Label htmlFor="description" className={dropZoneStyles.dropZoneLabel}>
            <TranslateComponent>File Description</TranslateComponent>
          </Label>
          <Input
            placeholder={translate('Start typing here...')}
            name="description"
            disabled={!fileName}
            type="text"
            value={description}
            onChange={onDescriptionChange}
            maxLength={255}
          />
        </div>
      )}
      {modalType === MODAL_TYPE.CONFIRMATION && (
        <DialogConfirm
          onAgree={() => {
            removeFileFunction()
            hideModalDialog()
          }}
          title={optionsTranslated.removeFile}
          onClose={hideModalDialog}
        >
          <TranslateComponent>
            Are you sure you want to remove this file?
          </TranslateComponent>
        </DialogConfirm>
      )}
    </>
  )
}

const AllowedFileTypeDescription = ({
  showOnlyAcceptableFileDesc,
  allowedFileTypes,
  fileTypes
}) => {
  if (showOnlyAcceptableFileDesc && allowedFileTypes.length <= 3) {
    return (
      <p className={dropZoneStyles.fileExtensions}>
        <TranslateComponent>Acceptable format: </TranslateComponent>
        {` .${allowedFileTypes.join(', .')}`}
        <TranslateComponent> (Max: 50mb)</TranslateComponent>
      </p>
    )
  }

  return (
    <p className={dropZoneStyles.fileExtensions}>
      <TranslateComponent>Acceptable format: </TranslateComponent>
      .doc, .xls, .pdf
      <Tooltip>
        <TooltipTrigger testName="allowedFileTypesTrigger">
          {' '}
          and more
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>
            {showOnlyAcceptableFileDesc
              ? allowedFileTypes.join(', .')
              : fileTypes.join(', .')}
          </TranslateComponent>
        </TooltipContent>
      </Tooltip>
      <TranslateComponent>
        <br />
        (Max: 50mb)
      </TranslateComponent>
    </p>
  )
}
