import { Button } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate, { TranslateComponent } from 'src/common/translations'

// Denali components
import { faCalendarLines } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'

export const EventMarkersContainer = ({
  createDisabled,
  onCreateMarker,
  children
}) => {
  return (
    <Page
      title={translate('Event Markers')}
      icon={<FontAwesomeIcon icon={faCalendarLines} />}
      iconColor={IconColor.orange}
      testid="event markers page"
      actions={
        <Button
          disabled={createDisabled}
          onClick={onCreateMarker}
          data-testid={'create-new-marker-button'}
        >
          <TranslateComponent>Create Marker</TranslateComponent>
        </Button>
      }
    >
      {children}
    </Page>
  )
}
