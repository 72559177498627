
import _set from "lodash/set"
import moment from 'moment'
import {
    hasCMSA,
    getTranslatedValue
  } from "src/pages/contracts-and-offerings/common/contracts-and-offerings"


const MAX_LENGTH_OF_NUMBER = 50
const MAX_LENGTH_OF_PACT_NUMBER = 50
const MAX_LENGTH_OF_MSA_NUMBER = 50

export const  AcceptDeclineValidate = ({ numberOfDevices,stream,offeringCodes, serviceNumber, startDate, analyticsStartDate,coachingServicesValue, endDate, pactContractNumber, mechanicalServiceAgreementNumber, primaryContact, buildingCount }) => {

    const errors = {}
    if (offeringCodes?.some(sub => sub.includes("AM"))) {
        if (!numberOfDevices) {
            _set(errors, "numberOfDevices", "Required")
        }

        if (numberOfDevices != "" && (numberOfDevices < 1 || numberOfDevices > 999)) {
            _set(errors, "numberOfDevices", "Device Count should be a number from 1 to 99")
        }
    }

    if (!buildingCount) {
        _set(errors, "buildingCount", "Required")
    }

    if (offeringCodes?.some(sub => sub ===("CS"))) {
        if (!coachingServicesValue) {
          _set(errors, "coachingServicesValue", "Required")
        }
      }

    // Different businessStreams selected goes with different fields
    if (/Independent/i.test(stream)) {
        if (!serviceNumber) {
            _set(errors, "purchaseOrderNumber", "Purchase order number is required")
        } else if (serviceNumber.length > MAX_LENGTH_OF_NUMBER) {
            _set(errors, "purchaseOrderNumber", `Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`)
        }
    }
    if ((!/Independent/i.test(stream)) && !((hasCMSA(offeringCodes)) && offeringCodes.length == 1)) {
        if (!serviceNumber) {
            _set(errors, "serviceContractNumber", "Required")
        } else if (serviceNumber.length > MAX_LENGTH_OF_NUMBER) {
            _set(errors, "serviceContractNumber", `Field shouldn’t contain more than ${MAX_LENGTH_OF_NUMBER} symbols`)
        } /* else if (serviceContractNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(serviceContractNumber)) { // eslint-disable-line
            _set(errors, "serviceContractNumber", i18next.t("users:SpCharacterNotAllowed"))
        } */
        // if (!crmSiteId) {
        // 	_set(errors, "crmSiteId", "Required"))
        // } else if (crmSiteId.length > MAX_LENGTH_OF_CRM_SITE_ID_NUMBER) {
        // 	_set(errors, "crmSiteId", <Trans i18nKey="errors:TooLongField" values={{ number: MAX_LENGTH_OF_CRM_SITE_ID_NUMBER }} />)
        // }
    }

    if (offeringCodes?.some(sub => sub.includes("CAPxM&V"))) {
        if (!pactContractNumber) {
            _set(errors, "pactContractNumber", "Required")
        } else if (pactContractNumber.length > MAX_LENGTH_OF_PACT_NUMBER) {
            _set(errors, "pactContractNumber", `Field shouldn’t contain more than ${MAX_LENGTH_OF_PACT_NUMBER} symbols`)
        } /*else if (pactContractNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(pactContractNumber)) { // eslint-disable-line
            _set(errors, "pactContractNumber", i18next.t("users:SpCharacterNotAllowed"))
        } */
    }
    if ((hasCMSA(offeringCodes)) && offeringCodes.length == 1) {
        if (!mechanicalServiceAgreementNumber) {
            _set(errors, "mechanicalServiceAgreementNumber", "Required")
        } else if (mechanicalServiceAgreementNumber.length > MAX_LENGTH_OF_MSA_NUMBER) {
            _set(errors, "mechanicalServiceAgreementNumber", `Field shouldn’t contain more than ${MAX_LENGTH_OF_MSA_NUMBER} symbols`)
        } /*else if (mechanicalServiceAgreementNumber && /[*|\":<>[\]{}`\\()';@&$/]/i.test(mechanicalServiceAgreementNumber)) { // eslint-disable-line
            _set(errors, "mechanicalServiceAgreementNumber", i18next.t("users:SpCharacterNotAllowed"))
        } */
    }
    if (startDate && analyticsStartDate && moment(analyticsStartDate).startOf("day") < moment(startDate).startOf("day")) {
        _set(errors, "startDate", "Start date should be earlier than Analytics date")
    }

    if (startDate && endDate && moment(endDate).startOf("day") < moment(startDate).startOf("day")) {
        _set(errors, "startDate", "Start date should be earlier than Expiration date")
    }

    if (primaryContact?.username) {
        const emailRegEx = new RegExp(/^([A-Za-z0-9.-]+@(tranetechnologies|irco|trane|Boland|Damuth)+\.(com)+)(,[A-Za-z0-9.-]+@(tranetechnologies|irco|trane|Boland|Damuth)+\.(com)+)*$/g) // eslint-disable-line
        const testEmail = primaryContact?.username.split(",").map(email => email.trim()).join(",")
  
        if (!testEmail.match(emailRegEx)) {
          _set(errors, "primaryContact", "Required")
        }
      }
    return errors
}
