import Modal from 'src/components/legacy/components/modal/modal'
import { useWidgetsContext } from '../WidgetsContextProvider'
import WidgetsPreview from 'src/pages/dashboards/components/DashboardCardWidget/WidgetsPreview'
import { WidgetPreviewWrapper } from '../widget-library/widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import './styles.scss'

export const WidgetPreviewModal = ({ widgetDetails }) => {
  const { setShowWidgetPreview, setSelectedWidgetDetails } = useWidgetsContext()
  const handleClose = () => {
    setShowWidgetPreview(false)
    setSelectedWidgetDetails(null)
  }
  return (
    <Modal
      gray={true}
      className="widget-preview-modal-container"
      heading= "Preview"
      handleClose={handleClose}
      removeFooter={true}
    >
      <div className="widget-preview-main">
        <WidgetPreviewWrapper type={widgetDetails?.type} widgetName={widgetDetails?.name} isPreview={true}>
          <WidgetChartContainer>
            <WidgetsPreview widget={widgetDetails} />
          </WidgetChartContainer>
        </WidgetPreviewWrapper>
      </div>
    </Modal>
  )
}