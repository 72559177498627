import styles from './building-selector.module.scss'
import { useSelector } from 'react-redux'
import {
  selectBuildings,
  selectBuildingsCount,
  selectSearchText,
  setBuildings,
  setBuildingsCount,
  setSearchText
} from 'src/redux/slicers/buildingPicker'
import { useEffect, useMemo, useState } from 'react'
import {
  SEARCH_BUILDINGS,
  SearchBuildingsQueryVariables
} from 'src/components/buildingNavigation/graphql'
import { useDispatch } from 'react-redux'
import { useQuery } from 'src/hooks/APIHooks'
import { accountMapping } from 'src/components/buildingNavigation/helper'
import { OrganizationsPopupItem } from './OrganizationsPopupItem'
import { Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons'
import translate, { TranslateComponent } from 'src/common/translations'
import { faGrid2 } from '../lib/pro-solid-svg-icons'
import { useDebounce } from '../helpers'
import { RecentSelections } from './RecentSelections'
import cloneDeep from 'lodash/cloneDeep'
import { Loading } from '../Table/Loading'

export const OrganizationsPopup = ({
  setOrganizationPopupOpen,
  selectedOrganization,
  selectedBuilding,
  orgGetFloatingProps,
  orgRefs,
  orgFloatingStyles
}) => {
  const limit = 1000

  // Store data
  const { data: organizations } = useSelector(selectBuildings)
  const searchText = useSelector(selectSearchText)
  const [localSearch, setLocalSearch] = useState(searchText)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const isSearchResultMaximum = useSelector(selectBuildingsCount)
  const { refetch, loading } = setUpQuery()
  const dispatch = useDispatch()
  const [previousSearch, setPreviousSearch] = useState('')
  const updateSearchResults = useDebounce(() => {
    searchWithOptionalSearchTerm(
      refetch,
      limit,
      localSearch,
      previousSearch,
      setPreviousSearch,
      organizations
    )
    setTimeout(() => {
      dispatch(setSearchText(localSearch))
    }, 0) // Timeout 0 to use the next JS event loop. Prevents hierarchical search results from appearing before loading spinner is triggered.
  })
  const translations = {
    clear: translate('Clear')
  }

  const sortedOrgs = useMemo(() => {
    return cloneDeep(organizations)?.sort((org) => {
      if (org.id === selectedOrganization.id) return -1
      return 0
    })
  }, [organizations, selectedOrganization])

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
      if (organizations?.length !== 0) return
    }
    updateSearchResults(localSearch)
  }, [localSearch])

  return (
    <div
      className={styles.popupWrapper}
      {...orgGetFloatingProps()}
      ref={orgRefs.setFloating}
      data-testid="buildingSelectorOrgPopup"
      style={orgFloatingStyles}
    >
      <div className={`${styles.popup} ${styles.organizations}`}>
        <form
          className={styles.searchForm}
          onSubmit={(event) => {
            event.preventDefault()
          }}
        >
          <Input
            type="text"
            value={localSearch}
            onChange={(event) => {
              setLocalSearch(event.target.value)
            }}
            placeholder={translate('Search...')}
            aria-label={translate('Search...')}
            data-testid="orgDenaliInput"
          />
          <button type="submit" aria-label={translate('Submit')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          {searchText !== '' && (
            <button
              className={styles.searchClear}
              aria-label={translations.clear}
              onClick={() => {
                setLocalSearch('')
                dispatch(setSearchText(''))
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
          )}
        </form>
        <div className={styles.scrollContainer}>
          {searchText?.length < 2 && (
            <RecentSelections
              setPopupOpen={setOrganizationPopupOpen}
              selectedOrganization={selectedOrganization}
            />
          )}
          <h3 className={styles.popupHeadline}>
            <FontAwesomeIcon icon={faGrid2} />
            <TranslateComponent>All Organizations</TranslateComponent>
          </h3>
          {loading && <Loading text="Searching" />}
          {!loading && (
            <ul>
              {sortedOrgs.map((organization) => (
                <OrganizationsPopupItem
                  key={organization.id}
                  organization={organization}
                  selectedBuilding={selectedBuilding}
                  setOrganizationPopupOpen={() => {
                    setLocalSearch('')
                    dispatch(setSearchText(''))
                    setOrganizationPopupOpen(false)
                  }}
                  showBuildingCount={isSearchResultMaximum !== limit}
                  selectedOrganization={selectedOrganization}
                  searchText={searchText}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

function setUpQuery() {
  const dispatch = useDispatch()

  return useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items',
    onSuccess: (data: any) => {
      try {
        const accountsData = accountMapping(data, true)
        const finalData = Object.values(accountsData)
        dispatch(setBuildingsCount({ buildingsCount: data.length }))
        dispatch(
          setBuildings({
            type: 'Success',
            data: finalData
          })
        )
      } catch (e) {
        console.error(e)
      }
    }
  })
}

function searchWithOptionalSearchTerm(
  refetch,
  limit,
  searchText,
  previousSearch,
  setPreviousSearch,
  organizations
) {
  // Based on handleBodyFilterChange in src/components/buildingNavigation/building-navigation.tsx
  if (searchText.length > 0 && searchText.length < 2) {
    searchText = ''
  }
  if (organizations?.length !== 0 && previousSearch === searchText) return
  setPreviousSearch(searchText)

  const variables: SearchBuildingsQueryVariables = { limit }
  if (searchText.trim() !== '') {
    variables.filter = {
      or: [
        { accountName: { matchPhrasePrefix: `*${searchText}*` } },
        { name: { matchPhrasePrefix: `*${searchText}*` } },
        { address: { matchPhrasePrefix: `*${searchText}*` } }
      ]
    }
  }
  refetch(variables)
}
