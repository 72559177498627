import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectBuilding,
  selectUserHistory
} from 'src/redux/slicers/buildingPicker'
import { OrganizationsPopupItem } from './OrganizationsPopupItem'
import { BuildingsPopupItem } from './BuildingsPopupItem'
import styles from './building-selector.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { TranslateComponent } from 'src/common/translations'

export const RecentSelections = ({ setPopupOpen, selectedOrganization }) => {
  const { data: recentSearchItems } = useSelector(selectUserHistory)
  const filteredHistory = useFilteredHistory(recentSearchItems)
  const selectedOrgBuildingSales = useSelector(selectBuilding)
  const selectedBuilding =
    selectedOrgBuildingSales?.type === 'location'
      ? selectedOrgBuildingSales
      : null

  if (!filteredHistory || filteredHistory?.length === 0) return <></>

  return (
    <div data-testid="buildingPickerRecentSelection">
      <h3 className={styles.popupHeadline}>
        <FontAwesomeIcon icon={faHistory} />
        <TranslateComponent>Recent Selections</TranslateComponent>
      </h3>
      <ul className={styles.recentList}>
        {filteredHistory?.map((item) => {
          return item.type === 'organization' ? (
            <OrganizationsPopupItem
              organization={item}
              setOrganizationPopupOpen={setPopupOpen}
              key={item?.id}
            />
          ) : (
            <BuildingsPopupItem
              building={item}
              setBuildingsPopupOpen={setPopupOpen}
              key={item?.id}
            />
          )
        })}
      </ul>
    </div>
  )
}

const useFilteredHistory = (historyList) => {
  return useMemo(() => {
    const idsUsed = []
    return historyList
      .filter((item) => {
        return item.type === 'organization' || item.type === 'location'
      })
      .filter((item) => {
        const isUnique = !idsUsed.includes(item.id)
        idsUsed.push(item.id)
        return isUnique
      })
      .slice(0, 5)
  }, [historyList])
}
