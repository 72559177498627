import { IBuildingOld } from 'src/redux/types/BuildingPickerTypes'
import styles from './building-selector.module.scss'
import { BuildingIcon } from './BuildingIcon'
import { useDispatch } from 'react-redux'
import { setSelectedBuilding } from 'src/redux/slicers/buildingPicker'
import { useHighlightText } from './OrganizationsPopupItem'

type BuildingsPopupItemProps = {
  building: IBuildingOld
  selectedBuilding?: IBuildingOld
  isMenuChild?: boolean
  isLastChild?: boolean
  searchText?: string
  parentOrganization?: IBuildingOld
  setBuildingsPopupOpen?: (boolean) => void
}

export const BuildingsPopupItem = ({
  building,
  setBuildingsPopupOpen = (_) => {},
  selectedBuilding = null,
  isMenuChild = false,
  isLastChild = false,
  searchText = '',
  parentOrganization
}: BuildingsPopupItemProps) => {
  const dispatch = useDispatch()
  const name = useHighlightText(building.primary, searchText)
  const address = useHighlightText(building.secondary, searchText)

  return (
    <li
      className={`
        ${selectedBuilding?.id === building.id ? styles.active : ''}
        ${isMenuChild ? styles.buildingChildItem : ''}
        ${isLastChild ? styles.buildingLastChildItem : ''}
      `}
    >
      <div className={styles.buildingButtonWrapper}>
        <button
          className={styles.buildingButton}
          onClick={() => onClick(setBuildingsPopupOpen, dispatch, building)}
          data-testid={`buildingSelectorBuildingItem-${building.id}`}
        >
          <div className={styles.buildingIcon}>
            <BuildingIcon
              name={building.primary}
              colorOverride={parentOrganization?.primary}
            />
          </div>
          <div className={styles.details}>
            <div className={styles.name}>
              <div className={styles.nameInner}>{name}</div>
            </div>
            <div className={styles.address}>{address}</div>
          </div>
        </button>
      </div>
    </li>
  )
}

const onClick = (setBuildingsPopupOpen, dispatch, building) => {
  if (setBuildingsPopupOpen) setBuildingsPopupOpen(false)
  if (!setSelectedBuilding) return
  dispatch(
    setSelectedBuilding({
      id: building.id,
      primary: building.primary,
      secondary: building.secondary,
      icon: building.icon,
      type: building.type,
      accountId: building.accountId
    })
  )
}
