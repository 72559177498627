import { forwardRef, useCallback, useEffect, useState } from 'react'

// Project imports
import FileUploader from 'src/components/file-upload/file-upload'

// Local imports
import formStyles from './form.module.scss'
import { DenaliFileUploadProps, UploadFileType } from './types.d'
import { FileDropZone } from 'src/denali-components/DropZone/FileDropZone'
import { PROPOSAL_TYPE_FILE as FILE_TYPE } from 'src/components/file-upload/file-upload'
import { isAllowedFileSize, isAllowedFileType } from '../DropZone/helpers'
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'
import { useAttachment } from 'src/hooks/attachment'
import translate from 'src/common/translations'

interface FileState {
  status: 'loaded' | 'uploading' | 'uploaded' | 'error'
  fileName: string
  name: string
  error: { text: string }
  confirm: Record<string, string | (() => void)>
  errorMessage: string
}

export const DenaliFileUpload = forwardRef(function DenaliFileUploadComponent(
  {
    value,
    onChange,
    allowedFileTypes,
    includeDescription,
    showOnlyAcceptableFileDesc,
    allowMultipleFiles,
    showFileBadgeDate,
    showFileBadgePreviewButton,
    showFileBadgeDeleteButton,
    fileBadgeDownloadStyle,
    location,
    errors
  }: DenaliFileUploadProps,
  _
) {
  const [fileState, setFileState] = useState<FileState>({
    status: null,
    fileName: value?.name ? value.name : null,
    name: null,
    error: null,
    confirm: null,
    errorMessage: null,
    ...value
  })
  const [fileContent, setFileContent] = useState(null)
  const initialId = value?.id
  const { addFileFunc } = useAttachment()

  const errorTranslations = {
    fileType: translate(
      'This file will not be uploaded. File type is not allowed. Recommended file types include .pdf, .doc, .xls, .png, .mp4 and .jpg.'
    ),
    bigFile: translate(
      'File size is too large. Upload the file less than 50 MB in size'
    ),
    smallFile: translate(
      'File is too small. Please choose a file with not 0 size.'
    ),
    error: translate('There was an error. Please try again.')
  }

  // this handler will update the value in redux -- onFileChange
  const onChangeHandler = useCallback(
    (file: UploadFileType) => {
      onChange(file)
    },
    [onChange]
  )

  // fileChangeFunction
  const onInputFileChange = (e) => {
    const inputFile = e.target.files[0]
    setFileState({ ...fileState, errorMessage: '' })
    if (inputFile) {
      handleFileBeforeUpload(inputFile)
    }
  }

  const handleFileBeforeUpload = (file: File) => {
    if (!isAllowedFileType(file?.name)) {
      setFileState({
        ...fileState,
        error: { text: errorTranslations.fileType }
      })
      return
    }
    if (!isAllowedFileSize(file)) {
      const isTooSmall = file.size < 1
      setFileState({
        ...fileState,
        error: {
          text: isTooSmall
            ? errorTranslations.smallFile
            : errorTranslations.bigFile
        }
      })
      return
    }
    // IF allowed type and right sized, update state
    setFileState({
      ...fileState,
      status: 'loaded',
      fileName: file?.name,
      name: file?.name
    })
    Object.defineProperty(file, 'displayName', {
      writable: true,
      value: file?.name
    })
    Object.defineProperty(file, 'name', {
      writable: true,
      value: updateFileNameWithEpoch(file?.name)
    })
    setFileContent(file)
  }

  const onDescriptionChange = (e) => {
    if (e.target.value.length <= 140) {
      onChangeHandler({ ...value, description: e.target.value })
    }
  }

  const handleClearData = () => {
    setFileState(null)
    onChangeHandler(null)
  }

  const uploadFile = async () => {
    setFileState({ ...fileState, status: 'uploading' })
    onChangeHandler({ ...value, loading: true })

    try {
      const res = await addFileFunc(fileContent, location)
      onChangeHandler({
        ...value,
        name: fileContent.name,
        displayName: fileContent?.displayName,
        url: res ? res.url : '',
        loading: false,
        sizeInBytes: fileContent?.size,
        type: fileContent?.type,
        description: value?.description
      })
      setFileState({ ...fileState, status: 'uploaded' })
    } catch {
      setFileState({
        ...fileState,
        status: 'error',
        errorMessage: errorTranslations.error
      })
      onChangeHandler({ ...value, loading: false })
    }
  }

  useEffect(() => {
    if (fileState?.status === 'loaded') {
      uploadFile()
    }
  }, [fileState?.status])

  return (
    <FileDropZone
      fileName={fileState?.fileName}
      status={fileState?.status}
      fileContent={fileContent}
      showOnlyAcceptableFileDesc={showOnlyAcceptableFileDesc}
      allowedFileTypes={allowedFileTypes}
      allowMultipleFiles={allowMultipleFiles}
      fileTypes={FILE_TYPE}
      fileChangeFunction={onInputFileChange}
      removeFileFunction={handleClearData}
      includeDescription={includeDescription}
      description={value?.description}
      onDescriptionChange={onDescriptionChange}
      showFileBadgeDate={showFileBadgeDate}
      showFileBadgePreviewButton={showFileBadgePreviewButton}
      showFileBadgeDeleteButton={showFileBadgeDeleteButton}
      fileBadgeDownloadStyle={fileBadgeDownloadStyle}
      errors={fileState?.error || errors}
    />
  )
})
