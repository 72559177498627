import { useState, useEffect } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import ClientSideTable from 'src/components/Table/clientSideTable'
import Checkbox from 'src/denali-ui/components/Checkbox'
import { Block } from 'src/components/layouts'
import {
  getDefautContractPeriod,
  filterOfferings
} from 'src/pages/contracts-and-offerings/common/contracts-and-offerings'
import { getUniqueItems } from 'src/common/helperFunctions'
import { ModalFieldTitle } from './styles'
import { translate } from 'src/common/translations'

export const AddOfferingsModal: React.FC<any> = ({
  onConfirm,
  values,
  offeringDDLData,
  contractsConfig,
  formik,
  handleCancel,
  loading
}) => {
  const [offeringsState, setOfferingsState] = useState([])
  const [buildingsState, setBuildingsState] = useState([])
  const [selectAllBuildings, setSelectAllBuildings] = useState(false)
  const [noBuildingsSelected, setNoBuildingsSelected] = useState(false)

  const optionsTranslated = {
    buildingName: translate("Building Name"),
    address: translate("Address"),
    CRMSiteID: translate("CRM Site ID"),
    addOfferings: translate("Add Offerings"),
    cancel: translate('cancel'),
    selectOfferings: translate("Select Offering(s)"),
    selectBuildingsDesc: translate("Select the buildings you would like to apply the new offering to"),
    buildings: translate("Buildings")
  }

  useEffect(() => {
    setBuildingsState([
      ...buildingsState,
      ...offeringDDLData.filter(
        ({ key }) =>
          values.buildingIds.includes(key) &&
          !buildingsState.some((building) => key === building.key)
      )
    ])
  }, [offeringDDLData])

  useEffect(() => {
    setSelectAllBuildings(
      buildingsState.every((building) => building.checkboxCheckedEnabled)
    )
    setNoBuildingsSelected(
      !buildingsState.some((building) => building.checkboxCheckedEnabled)
    )
  }, [buildingsState])

  const nameHeadings = [
    {
      name: 'label',
      title: optionsTranslated.buildingName,
      checked: false,
      customHeaderComponent: () => (
        <Checkbox
          label={'BuildingName'}
          checked={selectAllBuildings}
          onClick={() => {
            const _selectAllBuildings = !selectAllBuildings
            setSelectAllBuildings((state) => !state)
            setBuildingsState((state) =>
              state.map((building) => ({
                ...building,
                checkboxCheckedEnabled: _selectAllBuildings
              }))
            )
          }}
        />
      ),
      key: 'label',
      maxWidth: '75px'
    },
    {
      name: 'address',
      title: optionsTranslated.address,
      key: 'address',
      maxWidth: '75px'
    },
    {
      name: 'crmCustId',
      title: optionsTranslated.CRMSiteID,
      key: 'crmCustId',
      maxWidth: '100px'
    }
  ]
  return (
    <Modal
      className={`Addoffering-dialog`}
      gray={true}
      heading={optionsTranslated.addOfferings}
      buttons={[
        { type: 'cancel', text: 'cancel', handleClick: handleCancel },
        {
          type: 'confirm',
          text: optionsTranslated.addOfferings,
          disabled: noBuildingsSelected,
          handleClick: () => {
            let mappings = []
            buildingsState.forEach((building) => {
              const newMappings = offeringsState
                .filter(
                  (offering) =>
                    !values.newOfferingsToBuildinsMap.some(
                      (newOff) => newOff.name === offering
                    )
                )
                .map((offering) => ({
                  checkboxCheckedEnabled: !!building.checkboxCheckedEnabled,
                  buildingId: building.key,
                  name: offering
                }))
              mappings = [...mappings, ...newMappings]
            })

            const modifiedExistingOfferingsToBuildinsMap =
              values.newOfferingsToBuildinsMap.map((offering) => {
                if (offeringsState.includes(offering.name)) {
                  return { ...offering, checkboxCheckedEnabled: true }
                }
                return offering
              })

            formik.setValues({
              ...values,
              buildingIds: getUniqueItems([
                ...values.buildingIds,
                ...mappings.map((mapping) => mapping.buildingId),
                ...modifiedExistingOfferingsToBuildinsMap
                  .filter((map) => map.checkboxCheckedEnabled)
                  ?.map((_map) => _map.buildingId)
              ]),
              offerings: getUniqueItems([
                ...values.offerings,
                ...mappings.map((mapping) => mapping.name),
                ...modifiedExistingOfferingsToBuildinsMap
                  .filter((map) => map.checkboxCheckedEnabled)
                  ?.map((_map) => _map.name)
              ]),
              newOfferingsToBuildinsMap: [
                ...modifiedExistingOfferingsToBuildinsMap,
                ...mappings
              ]
            })

            for (const offering of [...values.offerings, ...offeringsState]) {
              const defaultContractPeriodKey = getDefautContractPeriod(
                contractsConfig,
                offering.key
              )
              defaultContractPeriodKey &&
                formik.setFieldValue('length', defaultContractPeriodKey)
            }
            onConfirm?.()
          }
        }
      ]}
      handleClose={handleCancel}
      loading={loading}
    >
      <>
        <ModalFieldTitle>{optionsTranslated.selectOfferings}*</ModalFieldTitle>
        <SelectrixWrapper
          className={
            'location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select'
          }
          placeHolderInside={true}
          defaultValue={offeringsState}
          multiple={true}
          onChange={(e) => {
            const newOfferingNames = e.map((e) => e.key)
            setOfferingsState(newOfferingNames)
          }}
          options={filterOfferings(
            contractsConfig,
            getUniqueItems([...values.offerings, ...offeringsState]),
            true
          )}
          searchable={false}
          searchBoxInside={false}
          searchIndex={false}
          stayOpen={true}
        />
        <Block margin={'16px 0 0 0 '}>
          <p>
            {optionsTranslated.selectBuildingsDesc}.
          </p>
          <ModalFieldTitle>{optionsTranslated.buildings}</ModalFieldTitle>
          <ClientSideTable
            fixedLayout={false}
            header={nameHeadings}
            search={true}
            searchFields={['label']}
            rows={buildingsState}
            rowCheckbox={true}
            checkboxClick={(row) => {
              setBuildingsState((state) =>
                state.map((building) => ({
                  ...building,
                  checkboxCheckedEnabled:
                    building.key === row.key
                      ? !building.checkboxCheckedEnabled
                      : building.checkboxCheckedEnabled
                }))
              )
            }}
          />
        </Block>
      </>
    </Modal>
  )
}
