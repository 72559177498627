// Legacy imports
import { round } from 'src/components/legacy/common/helpers.js'

export const formatPayback = (value: number) => {
  return isFinite(value) ? round(value, 2).toLocaleString() : '0'
}

export const formatROI = (value: number) => {
  return isFinite(value) ? `${round(value, 2).toLocaleString()} %` : '0'
}
