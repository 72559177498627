import { IssuesAndFindingsAddToOpportunityDialogContext } from 'src/denali-pages/IssuesAndFindings/hooks/use-issues-findings-add-to-opportunity-dialog-context'
import { IssuesAndFindingsAddToOpportunityDialogContextProviderProps } from '../types'

export const IssuesAndFindingsAddToOpportunityDialogContextProvider = ({
  children,
  accountId,
  buildingId,
  selectedItems,
  onChange,
  handleLoading,
  closeDialog,
  modalType,
  setModalType,
  optionType,
  setOptionType,
  opportunityName,
  setOpportunityName,
  opportunityNameError,
  setOpportunityNameError,
  createOpportunity,
  updateOpportunity
}: IssuesAndFindingsAddToOpportunityDialogContextProviderProps) => {
  const contextData = {
    selectedItems,
    accountId,
    buildingId,
    onChange,
    handleLoading,
    closeDialog,
    modalType,
    setModalType,
    optionType,
    setOptionType,
    opportunityName,
    setOpportunityName,
    opportunityNameError,
    setOpportunityNameError,
    createOpportunity,
    updateOpportunity
  }

  return (
    <IssuesAndFindingsAddToOpportunityDialogContext.Provider
      value={contextData}
    >
      {children}
    </IssuesAndFindingsAddToOpportunityDialogContext.Provider>
  )
}
