// Library imports
import * as Plot from '@observablehq/plot'
import * as d3 from 'd3'
import moment from 'moment'

import { PlotChartCustomMinMax } from 'src/denali-components/PlotChart/types'

// Project imports
import {
  ChartDataItem,
  ChartDatesData,
  EnergyIntensityCalculateChartMinMaxProps,
  EnergyIntensityCreateBenchmarkRuleProps,
  EnergyIntensityCreateMarkerRuleProps,
  MarkNames,
  PlotChartValueType
} from './types.d'
import { Dispatch, SetStateAction } from 'react'

export const EUI_MARKS_ORDER = [
  MarkNames.GRADIENT,
  MarkNames.BENCHMARK,
  MarkNames.MARKER,
  MarkNames.EUI,
  MarkNames.EUI_TIP
]

export const ECI_MARKS_ORDER = [
  MarkNames.MARKER,
  MarkNames.ECI,
  MarkNames.ECI_TIP
]

// @TODO: Follow up with Magenta to clarify if the line chart indeed displays the benchmarks and markers
// above the other marks.
export const LINE_CHART_MARKS_ORDER = [
  MarkNames.GRADIENT,
  MarkNames.ECI,
  MarkNames.EUI,
  MarkNames.BENCHMARK,
  MarkNames.MARKER,
  MarkNames.ECI_TIP,
  MarkNames.EUI_TIP
]

type EnergyIntensityDataTransformationProps = {
  chartData: ChartDataItem[]
  startDate: Date
  endDate: Date
}

type CustomYAxisProps = {
  // Yes this feels gross but Plot is very open-ended when it comes to creating "channels" for data by
  // simply accessing properties on the object.
  y: string
  ticks?: number
  tickFormat?: string | ((d: any, i: number) => any) | null
  // To support any additional Plot.axis[X/Y] options
  [key: string]: any
}

export type CustomYAxisData = {
  axis: Plot.CompoundMark
  scale: d3.ScaleLinear<number, number>
}

type CustomYAxisWithMinMaxProps = {
  ticks?: number
  tickFormat?: string | ((d: any, i: number) => any) | null
  // To support any additional Plot.axis[X/Y] options
  [key: string]: any
}

// Depending on the number of X ticks on the bottom, we format the date differently.
// More months == shorter date format.
export const getFormatShortDate = (d: Date) => {
  return d.toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric'
  })
}

export const getFormatLongDate = (d: Date) => {
  const [a, b] = d
    .toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric'
    })
    .split(' ')
  return `${a} ${b}`
}

// Plot value listener helper to get the type of value.
export const getTypeOfPlotValue = (value: any): PlotChartValueType => {
  if (value === null || value === undefined) {
    return PlotChartValueType.NONE
  }

  if (typeof value === 'number') {
    return PlotChartValueType.BENCHMARK
  }

  if (typeof value === 'object' && 'buildingIds' in value) {
    return PlotChartValueType.MARKER
  }

  return PlotChartValueType.NONE
}

// This function will calculate the min and max values for the chart based on the benchmarks and markers.
// Returns an object with the min and max values for each section.
export const calculateChartMinMax = ({
  chartData,
  benchmarks
}: EnergyIntensityCalculateChartMinMaxProps): PlotChartCustomMinMax => {
  // First we need to find the min and max values for the chart data.
  const [eciMin, eciMax] = d3.extent(chartData, (d) => d.eci)
  const [euiMin, euiMax] = d3.extent(chartData, (d) => d.eui)

  // Now find the min and max values for the benchmarks.
  const [benchmarksMin, benchmarksMax] = d3.extent(benchmarks, (b) => b.value)

  // Now find the total min and max values for EUI and ECI.
  const totalEuiMin = Math.min(euiMin, benchmarksMin)
  const totalEuiMax = Math.max(euiMax, benchmarksMax)

  return {
    section1min: totalEuiMin,
    section1max: totalEuiMax,
    section2min: eciMin,
    section2max: eciMax
  }
}

export const energyIntensityDataTransformation = ({
  chartData,
  startDate,
  endDate
}: EnergyIntensityDataTransformationProps) => {
  // Filter items to only in between the startDate and endDate and then add a "date" property using
  // the year and month.
  const filteredData = chartData
    .map((item) => ({
      ...item,
      date: moment([item.year, item.month - 1, 1]).toDate()
    }))
    .filter((item) => {
      //const itemDate = moment(item.date)
      return item.date >= startDate && item.date <= endDate
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime())

  // Now we want to create a new data set, where for each object we create two separate objects one with
  // eci data only and one with eui data only. Make sure to remove the opposite value from the objects (so
  // objects only have eci or eui and not both).
  const eciData = filteredData.map((item) => {
    // Create a copy of the item and remove the eui value.
    const eciItem = {
      ...item,
      eui: null,
      type: 'eci',
      data: item.eci
    }
    return eciItem
  })

  const euiData = filteredData.map((item) => {
    // Create a copy of the item and remove the eci value.
    const euiItem = {
      ...item,
      eci: null,
      type: 'eui',
      data: item.eui
    }
    return euiItem
  })

  return [...eciData, ...euiData]
}

// This function accepts a data object, and then a y "channel" to create the axis for. Then how many "ticks"
// should display, the format of each tick, and then any additional options to pass to the axis.
export const customYAxis = (
  data: ChartDataItem[],
  { y, ticks = 12, tickFormat, ...options }: CustomYAxisProps
): CustomYAxisData => {
  // First let's create an extent for the y values. (Gives us the min and max y values).
  const [y1, y2] = d3.extent(Plot.valueof(data, y))

  // Next create the scale for the axis.
  const scale = d3.scaleLinear().domain([y1, y2])

  // Now create and return the plot axis using the scale and the ticks to print the values on the axis.
  return {
    axis: Plot.axisY(scale.ticks(), {
      ...options,
      y: scale,
      tickSize: 0,
      className: 'yAxisTick',
      // If tickFormat is a string use the scale.tickFormat otherwise use the tickFormat passed in.
      tickFormat:
        typeof tickFormat === 'string'
          ? scale.tickFormat(ticks, tickFormat)
          : tickFormat
    }),
    scale
  }
}

// Create a custom Plot.axisY with a minY and maxY domain.
export const customYAxisWithMinMax = (
  minY: number,
  maxY: number,
  { ticks = 12, tickFormat, ...options }: CustomYAxisWithMinMaxProps
) => {
  // Create the scale for the axis.
  const scale = d3.scaleLinear().domain([minY, maxY])

  // Now create and return the plot axis using the scale and the ticks to print the values on the axis.
  return Plot.axisY(d3.ticks(minY, maxY, ticks), {
    ...options,
    y: scale,
    tickSize: 0,
    className: 'yAxisTick',
    // If tickFormat is a string use the scale.tickFormat otherwise use the tickFormat passed in.
    tickFormat:
      typeof tickFormat === 'string'
        ? scale.tickFormat(ticks, tickFormat)
        : tickFormat
  })
}

// Reusable function to create a ruleY mark for a benchmark.
export const createBenchmarkRule = ({
  value,
  name,
  stroke = '#CADDFF',
  strokeWidth = 2,
  className = 'benchmarkRule',
  showTip = true,
  anchor = 'right',
  frameAnchor = 'right'
}: EnergyIntensityCreateBenchmarkRuleProps) => {
  const options: Plot.RuleYOptions = {
    stroke: stroke,
    strokeWidth: strokeWidth,
    clip: true,
    className: className
  }

  // If we want to show the tip, we need to add it to the options.
  if (showTip) {
    options.tip = {
      anchor: anchor,
      frameAnchor: frameAnchor,
      pointer: 'y',
      stroke: 'none'
    }
    options.title = (_) => name
  }

  return Plot.ruleY([value], options)
}

export const createMarkerRule = ({
  value,
  name,
  stroke = '#FFD0EF',
  strokeWidth = 2,
  strokeDasharray = 10,
  className = 'markerRule',
  showTip = true,
  anchor = 'bottom-left',
  frameAnchor = 'top'
}: EnergyIntensityCreateMarkerRuleProps) => {
  const options: Plot.RuleXOptions = {
    stroke: stroke,
    strokeDasharray: strokeDasharray,
    strokeWidth: strokeWidth,
    clip: true,
    className: className
  }

  // If we want to show the tip, we need to add it to the options.
  if (showTip) {
    options.tip = {
      anchor: anchor,
      frameAnchor: frameAnchor,
      pointer: 'x',
      stroke: 'none'
    }
    options.title = (_) => name
  }

  return Plot.ruleX(
    [typeof value === 'function' ? value : moment(value).toDate()],
    options
  )
}

// Configure event handlers for the elements that trigger the reordering of the marks.
export const setupPlotEvents = (
  plot: Plot.Plot,
  setPlotValueType: Dispatch<SetStateAction<PlotChartValueType>>
) => {
  // Let's use d3 to select the benchmarkRuleTrigger and markerRuleTrigger marks and add an event listener to them.
  // When they are "hovered", we'll update the plotValueType state.
  const selectedPlot = d3.select(plot)
  const benchmarkRules = selectedPlot.selectAll('.benchmarkRuleTrigger')
  const markerRules = selectedPlot.selectAll('.markerRuleTrigger')

  // For each add a pointerenter event and a pointerleave event. On enter for benchmarks, we set plot value to benchmark, etc etc
  benchmarkRules.on('mouseenter', () => {
    setPlotValueType(PlotChartValueType.BENCHMARK)
  })

  // On pointerleave set the plot value to none.
  benchmarkRules.on('mouseleave', () => {
    setPlotValueType(PlotChartValueType.NONE)
  })

  // Same for markers.
  markerRules.each(function () {
    const parent = d3.select(this.parentElement)
    parent.on('mouseenter', () => {
      setPlotValueType(PlotChartValueType.MARKER)
    })
    parent.on('mouseleave', () => {
      setPlotValueType(PlotChartValueType.NONE)
    })
  })
}

// Remove the event listeners from the benchmarkRuleTrigger and markerRuleTrigger marks.
export const cleanupPlotEvents = (plot: Plot.Plot): void => {
  const selectedPlot = d3.select(plot)
  const benchmarkRules = selectedPlot.selectAll('.benchmarkRuleTrigger')
  const markerRules = selectedPlot.selectAll('.markerRuleTrigger')

  // Remove the event listeners from the benchmarkRuleTrigger and markerRuleTrigger marks.
  // Using null removes all event listeners for that type. There are usually no other event listeners
  // for those marks, but if there are issues with events not happening on the chart, check here.
  benchmarkRules.on('mouseenter', null)
  benchmarkRules.on('mouseleave', null)
  markerRules.each(function () {
    d3.select(this.parentElement).on('mouseenter', null)
    d3.select(this.parentElement).on('mouseleave', null)
  })
}

// Mark order for the Line Chart.
export const getLineChartMarksOrder = (
  valueType: PlotChartValueType
): MarkNames[] => {
  switch (valueType) {
    case PlotChartValueType.MARKER:
      return [
        MarkNames.GRADIENT,
        MarkNames.EUI,
        MarkNames.ECI,
        MarkNames.MARKER,
        MarkNames.EUI_TIP,
        MarkNames.ECI_TIP
      ]
    default:
      return LINE_CHART_MARKS_ORDER
  }
}

// Create an xDomain and xConfig for a set of dates.
export const createXDomainConfig = ({
  chartDates,
  diffDays
}: {
  chartDates: ChartDatesData
  diffDays: number
}): {
  xDomain: d3.ScaleUtc
  xConfig: Plot.ScaleOptions
} => {
  // To better control the range we'll use d3 to create a UTC scale for the dates.
  const xDomain = d3
    .scaleUtc()
    .domain([
      moment(chartDates.chartStartDate).toDate(),
      moment(chartDates.chartEndDate).toDate()
    ])

  const xConfig: Plot.ScaleOptions = {
    type: 'band',
    grid: false,
    label: null,
    tickSize: 0,
    interval: 'month',
    domain: xDomain.ticks(d3.utcMonth.every(1)),
    tickRotate: diffDays > 365 ? -90 : 0,
    tickFormat: (d) => {
      return diffDays > 365 ? getFormatShortDate(d) : getFormatLongDate(d)
    }
  }

  return { xDomain, xConfig }
}

// Format the y axis values for the EUI.
export const formatEuiYAxisValues = (d: number) => {
  // If the number is greater than 999, let's format it with like k/M for thousands/millions.
  if (d > 999) {
    return d > 999999
      ? `${(d / 1000000).toFixed(1)}M`
      : `${(d / 1000).toFixed(1)}k`
  }
  return `${d}`
}
