import * as d3 from "d3"
import moment from "moment"
import { DATE_FORMAT } from "./constants"

export const isInvalidEl = (ref: any): boolean => ref && !ref.current

export const convertRefToEl = (ref: any = {}): any | null =>
  ref ? d3.select(ref) : null

export const formatDateByMoment = (
  date: any,
  format: string = DATE_FORMAT
): string => moment(date).format(format)

export const formatDateByMomentInEng = (
  date: any,
  format: string = DATE_FORMAT
): string => moment(date).locale('en').format(format)

export const isValidDate = (date: any): boolean => moment(date).isValid()

export const dateTimeInMS = (date: string, noOfDays = 0): number => {
  return isValidDate(date || null)
    ? moment(date).valueOf()
    : moment(new Date()).add(noOfDays, "day").valueOf()
}

export const isDaysSameOrAfter = (
  dayOne: string | number,
  dayTwo: string | number
): boolean => moment(dayOne).isSameOrAfter(dayTwo) // true
