import { useCallback } from 'react'
import { useCollapsibleState } from './hooks'
import { CollapsibleComponentProps, CollapsibleStyle } from './types.d'

import styles from './collapsible.module.scss'

// A reusable collapsible component. When collapsed the header is the only thing displayed and the builder
// is always called with the current collapsed state.
//
// The onOpen and onClose callbacks are optional and can be used to handle custom logic when the collapsible.
// These can be overridden with the onToggle callback that will be called for both with the next state. This
// will be called both when the collapsible is opened and closed.
export const Collapsible = ({
  header,
  headerBuilder,
  children,
  onOpen,
  onClose,
  onToggle,
  style = CollapsibleStyle.default,
  initialState = true,
  useLocalStorage = false,
  localStorageKey,
  className
}: CollapsibleComponentProps) => {
  const { isCollapsed, setIsCollapsed } = useCollapsibleState({
    initialState,
    useLocalStorage,
    localStorageKey
  })

  const onToggleCallback = useCallback(() => {
    // Let's called the setter and then call the callbacks. Since these are queued, when we pass the "next" state
    // we derive it here based on the current state we are in with this is called.
    setIsCollapsed((prevState: boolean) => {
      // If we have an onToggle called, then we will use it for both scenarios and simply pass it the next state.
      const nextState = !prevState
      if (onToggle) {
        onToggle(nextState)
      }

      // Otherwise we will call the specific callback based on the next state.
      if (nextState) {
        onOpen?.()
      } else {
        onClose?.()
      }

      return nextState
    })
  }, [onOpen, onClose, onToggle])

  const styleClass = style.toString().toLowerCase()

  return (
    <div
      className={`${styles.collapsible} ${
        isCollapsed ? styles.collapsed : ''
      } ${styleClass} ${className ?? ''}`}
    >
      <div className={styles.collapsibleHeader}>
        {buildHeader(isCollapsed, headerBuilder, header, onToggleCallback)}
      </div>
      <div className={styles.collapsibleChildren}>{children}</div>
    </div>
  )
}

// Function to build the header based on either the header child or the headerBuilder.
const buildHeader = (
  isCollapsed: boolean,
  headerBuilder?: (
    isCollapsed: boolean,
    onToggle: () => void
  ) => React.ReactNode,
  header?: React.ReactNode,
  onToggle?: () => void
): React.ReactNode => {
  if (headerBuilder) {
    return headerBuilder(isCollapsed, onToggle)
  }

  // Let's wrap the header element with a div containing the onToggle callback tied to an onClick handler.
  return (
    <div
      className="collapsibleToggle"
      onClick={onToggle}
      onKeyDown={onToggle}
      role="button"
      tabIndex={0}
    >
      {header}
    </div>
  )
}
