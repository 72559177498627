import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { DenaliVisibilitySelect } from 'src/denali-components/Form/DenaliVisibilitySelect'

export const SelectVisibility = ({
  visibility,
  name,
  disabled,
  className
}: {
  visibility: boolean
  name: string
  disabled?: boolean
  className?: string
}) => {
  return (
    <div className={[formStyles.visibilityWrapper, className].join(' ')}>
      <FontAwesomeIcon
        icon={visibility ? faEye : faEyeSlash}
        className={[
          formStyles.visibilityIcon,
          visibility ? formStyles.visible : formStyles.notVisible
        ].join(' ')}
      />
      <DenaliFormElement
        id="visibilitySelect"
        data-testid="visibilitySelect"
        name={name}
        customOnChange={true}
      >
        <DenaliVisibilitySelect
          className={formStyles.visibilitySelect}
          value={visibility?.toString()}
          disabled={disabled}
        />
      </DenaliFormElement>
    </div>
  )
}
