/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://www55elws5gylbzf4lstiudvwm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:1dbc3322-7727-410f-b09d-3cc0292cfe67",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tMTZkZEaf",
    "aws_user_pools_web_client_id": "5kqngggcarpa4tkrnudspa4n8n",
    "oauth": {
        domain: 'denali-beta.auth.us-east-1.amazoncognito.com',
        scope: ['openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.origin,
        redirectSignOut: `https://corpsso.b2clogin.com/corpsso.onmicrosoft.com/B2C_1A_OIDC_USERINFO_DENALI/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`,
        responseType: 'code',
        client_id: '505ef98d-3c71-4d31-9a47-808eb98d30ad'
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "denali-analytics-validation-storage184326-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "convert": {
            "translateText": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "en",
                    "targetLanguage": "fr"
                }
            }
        }
    },
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "search_indices": {
                "items": [
                    "denalilocator-prod"
                ],
                "default": "denalilocator-prod"
            },
            "maps": {
                "items": {
                    "denalimap-prod": {
                        "style": "VectorEsriNavigation"
                    }
                },
                "default": "denalimap-prod"
            }
        }
    },
    "appEnvironment": {
        "BUILD_ENV": "prod",
        DEFAULT_SUBCRIBER_FOR_BUILDING: "vsi@service.traneconnect.com",
        "externalLinks": {
          "traneConnectBaseURL": "https://connect.tis.trane.com",
          "traneConnectGen4BaseURL": "https://gen4.connect.tis.trane.com/",
          "commandCenterBaseURL": "https://tcc.tis.trane.com"
        }
    }
};


export default awsmobile;
export const signOutUrl = `https://${awsmobile.oauth.domain}/logout?client_id=${awsmobile.aws_user_pools_web_client_id}&logout_uri=${awsmobile.oauth.redirectSignOut}&redirect_uri=${window.location.origin}&response_type=code`
export const APP_ID = "9b308188-33ad-4a84-8974-a412dc03e3f6"
export const amplitudeKey = "4fa65a8fddd9d2d8b07d1d2724b68b4d"
export const zendeskWidgetKey = "f42546cb-115b-4d5d-bf44-b4ddab8f10cd"
export const remoteAccessURL = 'https://beta.api.tis.trane.com/remoteaccess/connect'
export const openVPNURL = 'https://api.tis.trane.com/open-vpn-api/v2/remote'
export const staticFilesPath = "https://denali-staticfiles-xelrzpuu3vfojcvv5ou5ornhbe-prod-us-east-1.s3.amazonaws.com"
