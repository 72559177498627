import React, { memo } from 'react'

import { useController, useFormContext } from 'react-hook-form'

// Local imports
import { DenaliFormElementProps } from './types'

/**
 * A reusable component that handles automatically tying the children of this element to the form state.
 * Any children passed to this component will automatically have the React Hook Form props passed to them.
 * The passed name can be a string or a dot separated string to denote nested fields.
 * E.g. `name` or `address.street`
 *
 * This allows for something like this:
 * const defaultValues = { name: 'John', title: 'Mr.' age: 30, location: { name: 'Building 3A', id: '123456' } }
 * <DenaliForm defaultValues={defaultValues} onSubmit={onSubmit}>
 *   <DenaliFormElement name="name">
 *     <input type="text" />
 *   </DenaliFormElement>
 *   <DenaliFormElement name="title">
 *     <input type="text" />
 *   </DenaliFormElement>
 *   <DenaliFormElement name="age">
 *     <input type="number" />
 *   </DenaliFormElement>
 *   <DenaliFormElement name="location.name">
 *     <input type="text" />
 *   </DenaliFormElement>
 *   <DenaliFormElement name="location.id">
 *     <input type="text" />
 *   </DenaliFormElement>
 * </DenaliForm>
 *
 * Where the `onChange` handler from the React Hook Form useController hook is automatically passed to the children.
 * The advantage here is that for handling forms you can write nearly 90% HTML and not worry about managing form state as long
 * as the children components are equipped to handle common prop handlers for form elements: `onChange` and `onBlur`.
 * See the ControllerRenderProps type from react-hook-form for more details.
 *
 * Caveats here are the onChange handlers should expected to be passed the exact data type that the mentioned field is expecting.
 */
const DenaliFormElementWrapper = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DenaliFormElementProps
>(function DenaliFormElementComponent({ children, name, ...props }, propRef) {
  const {
    field,
    fieldState: { error }
  } = useController({ name, shouldUnregister: true })
  const {
    formState: { isSubmitting }
  } = useFormContext()

  if (React.isValidElement(children)) {
    // If the customOnChange is set, then we know the component will handle updating values and let's remove
    // the default passed onChange handler.
    if (props.customOnChange) {
      delete props.onChange
    }

    // If we are submitting the form, automatically pass "disabled" prop as true.
    const finalProps = {
      ref: propRef,
      // Allow custom props and child props to override the field props.
      ...field,
      ...children.props,
      error,
      hasError: !!error,
      disabled: props.disabled ?? isSubmitting,
      'aria-invalid': !!error
    }

    return React.cloneElement(children, finalProps)
  }

  // @TODO: Better error handling here if the children are not valid elements?
  return (
    <>
      <div>Something went wrong</div>
    </>
  )
})

export const DenaliFormElement = memo(DenaliFormElementWrapper)
