import { useState, useMemo, useEffect } from 'react'
import { useBreakPoint } from 'src/hooks/breakPoint'
import translate from 'src/common/translations'
import Modal from 'src/components/legacy/components/modal/modal'
import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import Select from 'src/components/legacy/components/select/select'
import styled from 'styled-components'
import { GET_DASHBOARD_BUILDING_DATA_CHARTS, UPDATE_DASHBOARD } from './graphql'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import { getTimePeriodOptionsList } from 'src/pages/widgets/helpers'
import { ACTIONS } from 'src/constants/actions'
import { Link, useFetcher } from 'react-router-dom'
import { DashboardModalContainer } from './styles'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { getUserSettingByUserId } from 'src/common/userSettingAPI'
import { TranslateComponent } from 'src/common/translations'

const AddDashboardContainer = styled.div`
  padding: 10px;

  p {
    font-size: 15px;
    font-weight: 700;
    margin: 15px 0px !important;
  }
`

const SelectWrapper = styled.div`
  margin: 10px 0px;
`

export const AddChartToDashboardModal = ({
  widgetDetails,
  chartDetails,
  userDashboards = [],
  dashBoardId,
  toggleAddDashboardModal,
  mode = ACTIONS.ADD,
  refreshBuildingDataWidget,
  setSuccessNotification,
  accountId,
}) => {
  const { width } = useBreakPoint()
  const [t] = useTranslation()
  const userInfo = useSelector(selectUserInfo)

  const [dashboardFormValues, setDashboardFormValues] = useState({})

  const optionsTranslated = {
    addChart: translate('Add Chart to Dashboard'),
    editChart: translate('Edit chart added to Dashboard'),
    cancel: translate('Cancel')
  }

  const { onSubmit: updateDashboard, loading: loadingUpdateDashboard } =
    useMutation({
      query: UPDATE_DASHBOARD,
      dataPath: 'data.updateDashboard.id',
      errorPolicy: 'all'
    })

  const { onSubmit: getDashboard, loading: loadinGetDashboard } = useMutation({
    query: GET_DASHBOARD_BUILDING_DATA_CHARTS,
    dataPath: 'data.getDashboard.buildingDataCharts',
    errorPolicy: 'all'
  })

  const setWidgetDetails = async () => {
    if (mode === ACTIONS.EDIT) {
      setDashboardFormValues({
        widgetName: widgetDetails?.widget?.name || '',
        widgetDescription: widgetDetails?.widget?.description || '',
        timePeriod:
          widgetDetails?.widget?.timePeriod ||
          getTimePeriodOptionsList(t)[0].key
      })
    } else {
      const userSettings = await getUserSettingByUserId(
        userInfo?.id,
        'Dashboard'
      )
      const defaultDashboardId = userSettings?.settings?.id
      const defaultDashboardIndex = userDashboards.findIndex(
        (e) => e.id === defaultDashboardId
      )
      const defaultDashboard =
        defaultDashboardIndex === -1
          ? userDashboards?.[0]?.id || ''
          : userDashboards[defaultDashboardIndex].id
      setDashboardFormValues({
        selectedDashboard: defaultDashboard,
        widgetName: chartDetails?.name,
        widgetDescription: '',
        timePeriod: getTimePeriodOptionsList(t)[0].key || ''
      })
    }
  }

  const getDashboardOptions = () => {
    return userDashboards
      .map((x) => {
        return {
          key: x.id,
          value: x.name
        }
      })
      .sort((a, b) => a?.value?.localeCompare(b?.value))
  }

  useEffect(() => {
    setWidgetDetails()
  }, [])

  const handleInputChange = (name, value) => {
    setDashboardFormValues({ ...dashboardFormValues, [name]: value })
  }

  const handleEditChartToDashboard = async () => {
    try {
      if (!dashBoardId || !widgetDetails?.id) {
        return
      }
      const buildingDataCharts = await getDashboard({ id: dashBoardId })
      const parsedBuildingDataCharts = buildingDataCharts
        ? JSON.parse(buildingDataCharts)
        : null
      const buidlingDataChartToEditIndex = parsedBuildingDataCharts?.findIndex(
        (widget) => widget.id === widgetDetails.id
      )
      const updatedBuildingDataChart = parsedBuildingDataCharts.map(
        (widget, index) => {
          if (index !== buidlingDataChartToEditIndex) {
            return widget
          }

          return {
            ...widget,
            name: dashboardFormValues?.widgetName,
            description: dashboardFormValues?.widgetDescription,
            timePeriod: dashboardFormValues?.timePeriod
          }
        }
      )
      const response = await updateDashboard({
        input: {
          id: dashBoardId,
          buildingDataCharts: JSON.stringify([...updatedBuildingDataChart])
        }
      })

      const updatedWidgetDetails = {
        ...widgetDetails?.widget,
        name: dashboardFormValues?.widgetName,
        description: dashboardFormValues?.widgetDescription,
        timePeriod: dashboardFormValues?.timePeriod
      }
      // To update widget in the dashboard with latest changes
      response &&
        refreshBuildingDataWidget?.({
          id: widgetDetails.id,
          widget: updatedWidgetDetails
        })
    } catch (error) {
    } finally {
      // toggleAddDashboardModal()
    }
  }

  const handleAddChartToDashboard = async () => {
    try {
      const buildingDataCharts = await getDashboard({
        id: dashboardFormValues?.selectedDashboard
      })

      const parsedBuildingDataCharts = buildingDataCharts
        ? JSON.parse(buildingDataCharts)
        : []

      const input = {
        name: dashboardFormValues?.widgetName,
        description: dashboardFormValues?.description,
        timePeriod: dashboardFormValues?.timePeriod,
        accountId: accountId ? accountId : undefined,
        chartId: chartDetails?.id,
        id: uuidv4()
      }
      // return

      const response = await updateDashboard({
        input: {
          id: dashboardFormValues?.selectedDashboard,
          buildingDataCharts: JSON.stringify([
            ...parsedBuildingDataCharts,
            input
          ])
        }
      })
      if (response) {
        const dashboardName =
          userDashboards.find(
            (x) => x?.id === dashboardFormValues?.selectedDashboard
          )?.name || ''
        setSuccessNotification({
          type: 'Dashboard',
          message: `${chartDetails?.name} has been added to ${dashboardName}`,
          title: 'Widget Added'
        })
      }
      // }
    } catch (error) {
      console.log(error)
    } finally {
      toggleAddDashboardModal()
    }
  }

  const onSelectionChange = (field, selectedItem) => {
    setDashboardFormValues({ ...dashboardFormValues, [field]: selectedItem })
  }

  const addToDashboardModalConfig = {
    gray: true,
    isHideWhiteBackground: true,
    heading:
      mode === ACTIONS.ADD
        ? optionsTranslated.addChart
        : optionsTranslated.editChart,
    handleClose: toggleAddDashboardModal,
    buttons: [
      {
        text: mode === ACTIONS.ADD ? 'ADD' : 'SAVE',
        handleClick:
          mode === ACTIONS.EDIT
            ? handleEditChartToDashboard
            : handleAddChartToDashboard,
        type: 'save',
        disabled:
          dashboardFormValues?.widgetName === '' ||
          dashboardFormValues?.widgetName?.length <= 0 ||
          dashboardFormValues?.selectedDashboard?.length <= 0 ||
          loadingUpdateDashboard
      },
      {
        text: optionsTranslated.cancel,
        handleClick: toggleAddDashboardModal,
        type: 'cancel'
      }
    ]
  }
  return (
    <Modal {...addToDashboardModalConfig}>
      {
        <ContainerWrapper
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
          padding={'0px'}
          background="#eee"
        >
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
            padding={'0px'}
            background="#fff"
          >
            {loadingUpdateDashboard ? (
              <Spinner />
            ) : (
              <AddDashboardContainer>
                {mode === ACTIONS.ADD && (
                  <SelectWrapper>
                    <label><TranslateComponent>{`Select dashboard`}</TranslateComponent>*</label>
                    <Select
                      options={getDashboardOptions?.() || []}
                      className="dropdown-select"
                      selectedItem={
                        dashboardFormValues.selectedDashboard
                          ? `${
                              getDashboardOptions()?.find(
                                (x) =>
                                  x.key ===
                                  dashboardFormValues.selectedDashboard
                              )?.value
                            }`
                          : 'Select a Dashboard'
                      }
                      onChange={(event) =>
                        onSelectionChange('selectedDashboard', event)
                      }
                    />
                  </SelectWrapper>
                )}

                {mode === ACTIONS.ADD && (
                  <p className="important-note">
                    <TranslateComponent>This chart will be added as a widget to the dashboard.
                    Please specify the widget name, description, and time period
                    below.</TranslateComponent>
                  </p>
                )}
                <DashboardModalContainer>
                  <TextInput
                    labelText="Widget Name"
                    name="widgetName"
                    hasError={
                      dashboardFormValues?.widgetName === '' ||
                      dashboardFormValues?.widgetName === null
                    }
                    errorMessage={'The name is required.'}
                    value={dashboardFormValues?.widgetName || ''}
                    onChange={({ target: { value, name } }) => {
                      handleInputChange(name, value)
                    }}
                    maxLength={250}
                  />
                </DashboardModalContainer>
                <TextAreaInput
                  labelText="Widget Description"
                  defaultValue={dashboardFormValues?.widgetDescription}
                  name="widgetDescription"
                  onChange={({ target: { value } }) => {
                    handleInputChange('widgetDescription', value)
                  }}
                  isRequired={false}
                  hideErrorIcon={false}
                  maxLength={3000}
                />
                <SelectWrapper>
                  <label><TranslateComponent>{`Time Period`}</TranslateComponent>*:</label>
                  <Select
                    options={getTimePeriodOptionsList(t)}
                    className="dropdown-select"
                    selectedItem={t(`time>${dashboardFormValues?.timePeriod}`, {
                      nsSeparator: '>'
                    })}
                    onChange={(event) => onSelectionChange('timePeriod', event)}
                  />
                </SelectWrapper>
                {mode === ACTIONS.EDIT && (
                  <p className="important-note">
                    <TranslateComponent>To edit the properties shown on this widget, just make
                    changes to the saved building data chart</TranslateComponent>:{' '}
                    <Link to="/building-data">
                      {widgetDetails?.widget?.name}
                    </Link>
                  </p>
                )}
              </AddDashboardContainer>
            )}
          </Content>
        </ContainerWrapper>
      }
    </Modal>
  )
}
