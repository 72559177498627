import styles from 'src/denali-components/Page/detailPage.module.scss'
import { CardImpact } from 'src/denali-components/Cards/CardImpact/CardImpact'

export const DetailImpact = ({ opportunity }) => {
  return (
    <div className={styles.detailColumn}>
      <div className={styles.detailRow}>
        {opportunity.settings?.kpi
          ?.filter((kpi) => kpi.value)
          .map((kpi, index) => {
            return (
              <CardImpact
                key={index}
                kpi={kpi}
                count={
                  opportunity.settings?.kpi?.filter((kpi) => kpi.value).length
                }
              />
            )
          })}
      </div>
    </div>
  )
}
