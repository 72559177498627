import React, { useState, useEffect } from 'react'
import _get from 'lodash/get'
import Label from 'src/components/legacy/components/label/label'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import Select from 'src/components/legacy/components/select/select'
import {
  SEARCH_ACCOUNTS,
  SEARCH_BUILDINGS,
  SEARCH_SALES_OFFICES,
  generatePreLoadSelectedBuildingsQuery
} from './graphql'
import './index.scss'
import { useMutation, useQuery } from '../../../hooks/APIHooks'
import ObjectPicker from './objectPicker'
import { Button } from 'src/components/inputs/button'
import styled from 'styled-components'
import { selectUserInfo } from '../../../redux/slicers/appData'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import {
  DELETE_SUBSCRIBER,
  SAVE_SUBSCRIBER,
  UPDATE_SUBSCRIBER
} from './mutations'
import { useInView } from 'react-intersection-observer'
import { StyledSection } from './site-data-collection-notification-settings-item-closed'
import TextInput from 'src/components/legacy/components/modal-text-input'
import translate, { TranslateComponent } from 'src/common/translations'

const StyledFlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    width: 50%;
  }
`

const StyledFlexButtonDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 20px;
  > * {
    &:first-child {
      margin-right: 8px;
    }
  }
`



export const getQueryResponseMap = (response, type) => {
  return response.map((e) => ({
    name: e.name,
    children: e.name,
    id: e.id,
    value: e.id,
    accountName: e?.accountName,
    accountId: e?.accountId,
    salesOfficeId: e?.salesOfficeId,
    salesOfficeName: e?.salesOfficeName,
    disabled: false,
    type: type,
    state: e?.state,
    code: e?.code,
    isActive:e?.isActive,
    buildings: e?.buildings?.items
  }))
}

export const notifyOptions = [
  { key: '1 day', value: '1 day', conversion: 1440 },
  { key: '3 days', value: '3 days', conversion: 4320, default: true },
  { key: '7 days', value: '7 days', conversion: 10080 }
]

export const remindMeOptions = [
  { key: 'None', value: 'None' },
  { key: '1 day', value: '1 day', conversion: 1440 },
  { key: '3 days', value: '3 days', conversion: 4320 },
  { key: '7 days', value: '7 days', conversion: 10080, default: true }
]

export const SiteDataCollectionNotificationsSettingsDialogItem = ({
  notification,
  onSave,
  onCancel,
  first,
  last,
  dataCollectionNotificationsNames = []
}) => {
  const [pickerInputData, setInputData] = useState([])
  const [selectedBuildings, setSelectedBuildings] = useState([])
  const [selectedSalesOffices, setSelectedSalesOffices] = useState([])
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('Building')
  const userInfo = useSelector(selectUserInfo)
  const [nxtToken, setNxtToken] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [error, setError] = useState(false)
  const { ref: intersectionRef, inView } = useInView();
  const optionsTranslated = {
    in: translate('In')
  }
  

  const { data: buildingData, refetch: buildingDataRefetch } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings'
  })

  const { data: accountData, refetch: accountDataRefetch } = useQuery({
    query: SEARCH_ACCOUNTS,
    disableInitialLoad: true,
    dataPath: 'data.searchAccounts'
  })

  const { data: salesOfficeData, refetch: salesOfficeDataRefetch } = useQuery({
    query: SEARCH_SALES_OFFICES,
    disableInitialLoad: true,
    dataPath: 'data.searchSalesOffices'
  })

  const { onSubmit: saveNotificationSetting } = useMutation({
    query: SAVE_SUBSCRIBER,
    dataPath: 'data'
  })

  const { onSubmit: updateNotificationSetting } = useMutation({
    query: UPDATE_SUBSCRIBER,
    dataPath: 'data'
  })

  const { onSubmit: deleteNotificationSetting } = useMutation({
    query: DELETE_SUBSCRIBER,
    dataPath: 'data'
  })

  useEffect(() => {
    setSearchText('')
    setNxtToken(null)
    setInputData([])
    const sort = [{ field: 'name', direction: 'asc' }]
    if (selectedFilter) {
      if (selectedFilter === 'Building') {
        buildingDataRefetch({ limit: 100 , sort ,filter:{
          and: [{ isActive: { eq: 1 } }]
        }})
      } else if (selectedFilter === 'Organization') {
        accountDataRefetch({ limit: 100 , sort, filter:{
          and: [{ isActive: { eq: 1 } }]
        }})
      } else {
        salesOfficeDataRefetch({
          limit: 100,
          sort: { field: 'code', direction: 'asc' }
        })
      }
    }
  }, [selectedFilter])

  useEffect(() => {
    const onViewChange = () => {
      if (inView) {
        const sort = [{ field: 'name', direction: 'asc' }]
        if (selectedFilter && searchText === '') {
          if (selectedFilter === 'Building') {
            if (nxtToken !== null) {
              buildingDataRefetch({
                nextToken: nxtToken,
                limit: 100,
                sort,
                filter:{
                  and: [{ isActive: { eq: 1 } }]
                }
              })
            }
          } else if (selectedFilter === 'Organization') {
            if (nxtToken !== null) {
              accountDataRefetch({
                nextToken: nxtToken,
                limit: 100,
                sort,
                filter:{
                  and: [{ isActive: { eq: 1 } }]
                }
              })
            }
          } else {
            if (nxtToken !== null) {
              salesOfficeDataRefetch({
                nextToken: nxtToken,
                limit: 100,
                sort: { field: 'code', direction: 'asc' }
              })
            }
          }
        } else if (selectedFilter && searchText !== '') {
          selectedFilter === 'Building'
            ? nxtToken !== null &&
              buildingDataRefetch({
                limit: 100,
                nextToken: nxtToken,
                sort,
                filter: {
                  and: [
                    { isActive: { eq: 1 } },
                    searchText && searchText !== '' ? { name: { wildcard: `*${searchText}*` } } : {}
                  ]
                }
              })
            : selectedFilter === 'Organization'
            ? nxtToken !== null &&
              accountDataRefetch({
                nextToken: nxtToken,
                sort,
                limit: 100,
                filter: {
                  and: [
                    { isActive: { eq: 1 } },
                    searchText && searchText !== '' ? { name: { wildcard: `*${searchText}*` } } : {}
                  ]
                }
              })
            : nxtToken !== null &&
              salesOfficeDataRefetch({
                nextToken: nxtToken,
                sort,
                limit: 100,
                sort: { field: 'code', direction: 'asc' },
                filter:
                  searchText && searchText !== ''
                    ? { name: { wildcard: `*${searchText}*` } }
                    : undefined
              })
            }    
      }
    }
    onViewChange()
  }, [inView])

  useEffect(() => {
    setInputData([])
    setNxtToken(null)
  }, [searchText, selectedFilter])

  const getData = () => {
    let totalItems = []
    const data = getQueryResponseMap(buildingData?.items, 'building')
    totalItems = [...pickerInputData, ...data]
    totalItems = totalItems.filter(
      (e, pos) => totalItems.findIndex((x) => x.id === e.id) == pos
    )
    return totalItems
  }
  useEffect(() => {
    if (buildingData) {
      const testingArray = getData()
      setInputData(testingArray)
      setNxtToken(buildingData?.nextToken || null)
    }
  }, [buildingData])

  useEffect(() => {
    if (accountData) {
      let finalAccountsList = []
      setNxtToken(accountData?.nextToken || null)
      const test = getQueryResponseMap(accountData?.items, 'organization')
      const accountassociatedwithbuidlings = test?.filter((e) => e?.buildings?.length !== 0)
      if (searchText === '') {
        finalAccountsList = [...pickerInputData, ...accountassociatedwithbuidlings]
        setInputData(finalAccountsList)
      } else if (searchText !== '' && !inView){
        finalAccountsList = [...accountassociatedwithbuidlings]
        setInputData(finalAccountsList)
      } else if (searchText !== '' && inView) {
        finalAccountsList = [...pickerInputData, ...accountassociatedwithbuidlings]
        setInputData(finalAccountsList)      }
    }
  }, [accountData])
    useEffect(() => {
      if (salesOfficeData) {
        let testingArray = []
        setNxtToken(salesOfficeData?.nextToken || null)
        const test = getQueryResponseMap(salesOfficeData?.items, 'salesOffice')    
        testingArray = [...pickerInputData, ...test].map((salesOffice) => {
          const { code, name, state } = salesOffice
          const formattedName = `${code}, ${name}${state ? `, ${state}` : ''}`
          return { ...salesOffice, name: formattedName }
        })

        setInputData(testingArray)
      }
    }, [salesOfficeData])

  const [cloudRestored, setCloudRestored] = useState(false)
  const [name, setName] = useState('')
  const [notFlowTime, setNotFlowTime] = useState(null)
  const [notifyAgainAfter, setNotifyAgainAfter] = useState(null)
  const [deliveryMethod, setDeliveryMethod] = useState({
    emailEnabled: true,
    uiNotificationEnabled: true,
    smsEnabled: false
  })
  const enabledNotificationTypes = [
    deliveryMethod.emailEnabled ? 'email' : null,
    deliveryMethod.uiNotificationEnabled ? 'tc' : null,
    deliveryMethod.smsEnabled ? 'sms' : null
  ].filter((e) => e)


  useEffect(() => {
    if (notification?.ids?.length) {
      setCloudRestored(notification?.settings?.[0]?.nRestore)
      setNotFlowTime(
        notifyOptions.find(
          (e) => e?.conversion === notification?.settings[0]?.nMin
        )?.key
      )
      setNotifyAgainAfter(
        remindMeOptions.find(
          (e) => e?.conversion === notification?.settings[0]?.remMin
        )?.key || 'None'
      )
      setName(notification.name)
      setDeliveryMethod({
        emailEnabled: Boolean(
          notification?.settings.findIndex((e) => e.nType === 'email') !== -1
        ),
        uiNotificationEnabled: Boolean(
          notification?.settings.findIndex((e) => e.nType === 'tc') !== -1
        ),
        smsEnabled: Boolean(
          notification?.settings.findIndex((e) => e.nType === 'sms') !== -1
        )
      })

      const callLoadBuildingsFunc = async () => {
        const queries = generatePreLoadSelectedBuildingsQuery(
          notification.buildingIds,
          notification.salesOfficeIds,
          notification.accountIds
        )
        await Promise.all(
          queries.map(async (e) => {
            if (e) {
              return await API.graphql({
                query: e
              })
            } else {
              return null
            }
          })
        ).then((res) => {
          if (res[0]) {
            setSelectedBuildings(
              res[0].data.searchBuildings.items.map((e) => ({
                disabled: false,
                name: e.name,
                children: e.name,
                value: e.id,
                id: e.id,
                type: 'building'
              }))
            )
          }
          if (res[1]) {
            setSelectedSalesOffices(
              res[1].data.searchSalesOffices.items.map((e) => ({
                disabled: false,
                name: e.name,
                children: e.name,
                value: e.id,
                id: e.id,
                type: 'salesOffice'
              }))
            )
          }
          if (res[2]) {
            setSelectedOrganizations(
              res[2].data.searchAccounts.items.map((e) => ({
                disabled: false,
                name: e.name,
                children: e.name,
                value: e.id,
                id: e.id,
                type: 'organization'
              }))
            )
          }
        })
      }

      if (notification) {
        callLoadBuildingsFunc()
      }
    } else {
      setNotFlowTime(notifyOptions.find((e) => e?.default)?.key)
      setNotifyAgainAfter(remindMeOptions.find((e) => e?.default)?.key)
    }
  }, [notification])

  return (
    <>
      <StyledSection first={first} last={last}>
        <div className="rx-col-12 rx-col-sm-5">
          <Label text={`Notify me via:`} />
          <Checkbox
            id="ui-notification-checkbox"
            customClassName="delivery-method-checkbox"
            label={optionsTranslated.in + ' Trane Connect'}
            translateLabel={false}
            isChecked={deliveryMethod.uiNotificationEnabled}
            toggleCheck={({ target: { checked } }) =>
              setDeliveryMethod({
                ...deliveryMethod,
                uiNotificationEnabled: checked
              })
            }
          />
          <Checkbox
            id="email-checkbox"
            customClassName="delivery-method-checkbox"
            label={'Email'}
            isChecked={deliveryMethod.emailEnabled}
            toggleCheck={({ target: { checked } }) =>
              setDeliveryMethod({ ...deliveryMethod, emailEnabled: checked })
            }
          />
          {/* <Checkbox
            id="sms-checkbox"
            customClassName="delivery-method-checkbox"
            label={'SMS/Text'}
            isChecked={deliveryMethod.smsEnabled}
            toggleCheck={({ target: { checked } }) =>
              setDeliveryMethod({ ...deliveryMethod, smsEnabled: checked })
            }
          /> */}
        </div>
        <StyledFlexDiv>
          <div>
            <Label text={`Notify me when data is not flowing from *`} />
            <Select
              options={[
                { key: 'Building', value: 'Building' },
                { key: 'Organization', value: 'Organization' },
                { key: 'Sales Office', value: 'Sales Office' }
              ]}
              onChange={(e) => {
                setSelectedFilter(e)
              }}
              selectedItem={selectedFilter}
              dropDownType="top"
            />
            <ObjectPicker
              label=""
              values={pickerInputData}
              headerText={`${selectedFilter}s`}
              setSearchText={setSearchText}
              refetch={
                selectedFilter === 'Building'
                ? (e) => e &&
                    buildingDataRefetch({
                      limit: 1000,
                      sort: [{ field: 'name', direction: 'asc' }],
                      filter: {
                        and: [
                          { isActive: { eq: 1 } },
                          e && e !== '' ? { name: { matchPhrasePrefix: `*${e}*` } } : {}
                        ]
                      }
                    })
                : selectedFilter === 'Organization'
                ? (e) => e &&
                    accountDataRefetch({
                      limit: 1000,
                      sort: [{ field: 'name', direction: 'asc' }],
                      filter: {
                        and: [
                          { isActive: { eq: 1 } },
                          e && e !== '' ? { name: { matchPhrasePrefix: `*${e}*` } } : undefined
                        ]
                      }
                    })
                  : (e) => e &&
                      salesOfficeDataRefetch({
                        limit: 1000,
                        sort: { field: 'code', direction: 'asc' },
                        filter:
                          e && e !== ''
                            ? { name: { matchPhrasePrefix: `*${e}*` } }
                            : undefined
                      })
              }
              onCheckboxUnclick={(e) => {
                if (e.type === 'salesOffice') {
                  setSelectedSalesOffices(
                    selectedSalesOffices.filter((x) => x.id !== e.id)
                  )
                } else if (e.type === 'organization') {
                  setSelectedOrganizations(
                    selectedOrganizations.filter((x) => x.id !== e.id)
                  )
                } else {
                  setSelectedBuildings(
                    selectedBuildings.filter((x) => e.id !== x.id)
                  )
                }
              }}
              onCheckboxClick={(e) => {
                if (e.type === 'salesOffice') {
                  setSelectedSalesOffices([...selectedSalesOffices, e])
                } else if (e.type === 'organization') {
                  setSelectedOrganizations([...selectedOrganizations, e])
                } else {
                  setSelectedBuildings([...selectedBuildings, e])
                }
              }}
              selectedBuildings={selectedBuildings}
              selectedOrganizations={selectedOrganizations}
              selectedSalesOffices={selectedSalesOffices}
              error={false}
              intersectionRef={intersectionRef}
            />
          </div>
          <div>
            <div className="rx-col-12 rx-col-sm-5">
              <TextInput
                value={name}
                customLabelClass="disablePadding"
                labelText="Data Loss Notification Name"
                onChange={(e) => setName(e.target.value)}
                hasError={
                  error &&
                  (!name ||
                    (dataCollectionNotificationsNames.includes(name) &&
                      notification?.name !== name))
                }
                errorMessage={
                  dataCollectionNotificationsNames.includes(name) &&
                  notification?.name !== name
                    ? 'A data loss notification with this name already exists.'
                    : 'Data loss notification name is required.'
                }
              />
            </div>
            <div className="rx-col-12 rx-col-sm-5">
              <Label text={`Notify me when data has not been flowing for:`} />
              <br />
              <Select
                options={notifyOptions}
                onChange={(e) => {
                  setNotFlowTime(e)
                }}
                selectedItem={notFlowTime}
              />
            </div>
            <div className="rx-col-12 rx-col-sm-5">
              <Label text={`Remind me again after:`} />
              <br />
              <Select
                options={remindMeOptions}
                onChange={(e) => {
                  setNotifyAgainAfter(e)
                }}
                selectedItem={notifyAgainAfter}
              />
            </div>
            <div className="rx-col-12 rx-col-sm-5">
              <Checkbox
                id="ui-notification-checkbox-cloud"
                customClassName="delivery-method-checkbox"
                label={'Notify me when connectivity is restored'}
                isChecked={cloudRestored}
                toggleCheck={({ target: { checked } }) =>
                  setCloudRestored(checked)
                }
              />
            </div>
          </div>
        </StyledFlexDiv>
        <StyledFlexButtonDiv>
          <Button
            type="primary"
            disabled={
              !selectedBuildings.length &&
              !selectedOrganizations.length &&
              !selectedSalesOffices.length
            }
            onClick={async () => {
              if (
                !name ||
                name === '' ||
                (dataCollectionNotificationsNames.includes(name) &&
                  notification?.name !== name)
              ) {
                setError(true)
              } else {
                if (notification?.ids?.length) {
                  const idsToDelete = []
                  for (let i = 0; i < notification.buildingIds.length; i++) {
                    if (
                      selectedBuildings.findIndex(
                        (e) => e.id === notification.buildingIds[i]
                      ) === -1
                    ) {
                      idsToDelete.push(
                        notification.notifications.find(
                          (e) => e.buildingId === notification.buildingIds[i]
                        ).id
                      )
                    }
                  }
                  for (let i = 0; i < notification.salesOfficeIds.length; i++) {
                    if (
                      selectedSalesOffices.findIndex(
                        (e) => e.id === notification.salesOfficeIds[i]
                      ) === -1
                    ) {
                      idsToDelete.push(
                        notification.notifications.find(
                          (e) =>
                            e.salesOfficeId === notification.salesOfficeIds[i]
                        ).id
                      )
                    }
                  }
                  for (let i = 0; i < notification.accountIds.length; i++) {
                    if (
                      selectedOrganizations.findIndex(
                        (e) => e.id === notification.accountIds[i]
                      ) === -1
                    ) {
                      idsToDelete.push(
                        notification.notifications.find(
                          (e) => e.accountId === notification.accountIds[i]
                        ).id
                      )
                    }
                  }

                  const buildingIdsToAdd = []
                  const accountIdsToAdd = []
                  const salesOfficeIdsToAdd = []

                  for (let i = 0; i < selectedBuildings.length; i++) {
                    if (
                      notification.buildingIds.findIndex(
                        (e) => e === selectedBuildings[i].id
                      ) === -1
                    ) {
                      buildingIdsToAdd.push(selectedBuildings[i].id)
                    }
                  }

                  for (let i = 0; i < selectedOrganizations.length; i++) {
                    if (
                      notification.accountIds.findIndex(
                        (e) => e === selectedOrganizations[i].id
                      ) === -1
                    ) {
                      accountIdsToAdd.push(selectedOrganizations[i].id)
                    }
                  }

                  for (let i = 0; i < selectedSalesOffices.length; i++) {
                    if (
                      notification.salesOfficeIds.findIndex(
                        (e) => e === selectedSalesOffices[i].id
                      ) === -1
                    ) {
                      salesOfficeIdsToAdd.push(selectedSalesOffices[i].id)
                    }
                  }

                  const unModifiedIds = notification.ids.filter(
                    (e) => idsToDelete.findIndex((x) => x === e) === -1
                  )
                  const dataToSave = []

                  accountIdsToAdd.forEach((e) => {
                    dataToSave.push({
                      name: name,
                      contactId: userInfo.id,
                      userId: userInfo.id,
                      equipmentId: undefined,
                      accountId: e,
                      buildingId: undefined,
                      salesOfficeId: undefined,
                      deviceId: undefined,
                      type: 'dataloss',
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  })
                  salesOfficeIdsToAdd.forEach((e) => {
                    dataToSave.push({
                      name: name,
                      contactId: userInfo.id,
                      userId: userInfo.id,
                      equipmentId: undefined,
                      accountId: undefined,
                      buildingId: undefined,
                      salesOfficeId: e,
                      deviceId: undefined,
                      type: 'dataloss',
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  })
                  buildingIdsToAdd.forEach((e) => {
                    dataToSave.push({
                      name: name,
                      contactId: userInfo.id,
                      userId: userInfo.id,
                      equipmentId: undefined,
                      accountId: undefined,
                      buildingId: e,
                      salesOfficeId: undefined,
                      deviceId: undefined,
                      type: 'dataloss',
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  })

                  const dataToUpdate = []
                  for (let i = 0; i < unModifiedIds.length; i++) {
                    dataToUpdate.push({
                      id: unModifiedIds[i],
                      name: name,
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  }
                  await Promise.all([
                    ...idsToDelete.map(
                      async (e) =>
                        await deleteNotificationSetting({ input: { id: e } })
                    ),
                    ...dataToSave.map(
                      async (e) => await saveNotificationSetting({ input: e })
                    ),
                    ...dataToUpdate.map(
                      async (e) => await updateNotificationSetting({ input: e })
                    )
                  ]).then(() => {
                    onSave()
                  })
                } else {
                  const dataToSave = []
                  selectedOrganizations.forEach((e) => {
                    dataToSave.push({
                      name: name,
                      contactId: userInfo.id,
                      userId: userInfo.id,
                      equipmentId: undefined,
                      accountId: e.id,
                      buildingId: undefined,
                      salesOfficeId: undefined,
                      deviceId: undefined,
                      type: 'dataloss',
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  })
                  selectedSalesOffices.forEach((e) => {
                    dataToSave.push({
                      name: name,
                      contactId: userInfo.id,
                      userId: userInfo.id,
                      equipmentId: undefined,
                      accountId: undefined,
                      buildingId: undefined,
                      salesOfficeId: e.id,
                      deviceId: undefined,
                      type: 'dataloss',
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  })
                  selectedBuildings.forEach((e) => {
                    dataToSave.push({
                      name: name,
                      contactId: userInfo.id,
                      userId: userInfo.id,
                      equipmentId: undefined,
                      accountId: undefined,
                      buildingId: e.id,
                      salesOfficeId: undefined,
                      deviceId: undefined,
                      type: 'dataloss',
                      settings: enabledNotificationTypes.map((e) => ({
                        nType: e,
                        remMin: remindMeOptions?.find(
                          (e) => e.key === notifyAgainAfter
                        )?.conversion,
                        nMin: notifyOptions?.find((e) => e.key === notFlowTime)
                          ?.conversion,
                        nRestore: cloudRestored,
                        nStatus: true
                      }))
                    })
                  })
                  await Promise.all(
                    dataToSave.map(
                      async (e) => await saveNotificationSetting({ input: e })
                    )
                  ).then(() => {
                    onSave()
                  })
                }
              }
            }}
          >
            <TranslateComponent>Save</TranslateComponent>
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              onCancel()
            }}
          >
            <TranslateComponent>Cancel</TranslateComponent>
          </Button>
        </StyledFlexButtonDiv>
      </StyledSection>
    </>
  )
}

SiteDataCollectionNotificationsSettingsDialogItem.propTypes = {}
