// Project imports
import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { TranslateComponent } from 'src/common/translations'
import { Button, ButtonGroup } from '@aws-amplify/ui-react'
import { AccessControl } from 'src/components/accessControl'

// Denali imports.
import { Chip, ChipWeight } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import translate from 'src/common/translations'
import { useAlarmContext } from 'src/pages/alarmNotifications/AlarmContextProvider'
import { DELETE_SUBSCRIBER_CONFIRMATION } from 'src/pages/alarmNotifications/AlarmContainer'
import { IAlarm } from 'src/pages/alarmNotifications/types'
import config from 'src/aws-exports.js'
import { ACTIONS } from 'src/constants'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'
import tableStyles from 'src/denali-components/Table/table.module.scss'

// Local imports.
import styles from './alarm-notifications.module.scss'

export const AlarmNotificationList = () => {
  const optionsTranslated = {
    firstName: translate('First Name'),
    lastName: translate('Last Name'),
    email: translate('Email Address'),
    subscribed: translate('Subscribed to'),
    editTranslate: translate('Edit'),
    deleteTranslate: translate('Delete')
  }
  const {
    setSubscriber,
    addSubscriber,
    addNotification,
    setMode,
    setConfirmationModalType,
    subscribers,
    loadingSubscribers,
    responseTime,
    selectedSubscribers,
    selectSubscriberHandler,
    isLoading
  } = useAlarmContext()

  return (
    <>
      <ClientSideTable
        testName="alarmNotificationsPage"
        rows={subscribers}
        rowCheckbox
        tableClassName={tableStyles.alarmNotificationsPage}
        search
        header={getHeadersWithRowRenderOverride(optionsTranslated)}
        checkboxClick={selectSubscriberHandler}
        isShowPagination={false}
        searchFields={['firstName', 'lastName', 'emailId']}
        isDenaliTable
        listDataIsLoading={loadingSubscribers || isLoading}
        noTableDataTitleText="No alarm notifications found"
        loadTime={responseTime}
        defaultSortColumn={1}
        rowControl={(data) =>
          data?.emailId !==
            config?.appEnvironment?.DEFAULT_SUBCRIBER_FOR_BUILDING && [
            {
              text: optionsTranslated.editTranslate,
              action: (data: IAlarm) => {
                setSubscriber(data)
                setMode(ACTIONS.EDIT)
              }
            },
            {
              text: optionsTranslated.deleteTranslate,
              action: (data: IAlarm) => {
                setSubscriber(data)
                setConfirmationModalType(DELETE_SUBSCRIBER_CONFIRMATION)
              }
            }
          ]
        }
        denaliBulkActions={
          <ButtonGroup>
            <AccessControl id="tc.pages.utility-bill-data.create">
              <Button onClick={addSubscriber} data-testid="addSubscriberButton">
                <TranslateComponent>Add Subscriber</TranslateComponent>
              </Button>
            </AccessControl>
            <AccessControl id="tc.pages.utility-bill-data.create">
              <Button
                onClick={addNotification}
                data-testid="setAlarmNotificationButton"
                disabled={selectedSubscribers?.length === 0}
              >
                <TranslateComponent>Set Alarm Notification</TranslateComponent>
              </Button>
            </AccessControl>
          </ButtonGroup>
        }
      />
    </>
  )
}

const getHeadersWithRowRenderOverride = (optionsTranslated) => {
  return [
    {
      key: 'checkbox',
      hideFromSort: true
    },
    {
      title: optionsTranslated.firstName,
      key: 'firstName',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.lastName,
      key: 'lastName'
    },
    {
      title: optionsTranslated.email,
      key: 'emailId'
    },
    {
      key: 'subscribedTo',
      hideFromSort: true,
      width: '100%',
      customComponent: subscribedCell
    }
  ]
}

const titleCell = (row) => (
  <UserNameBubble
    key="SubscribedTo"
    showFullName={true}
    showTooltip
    tooltipContent={`Created by ${row.emailId}`}
    firstName={row.firstName}
    lastName={row.lastName}
    subLine={row.emailId}
  />
)

const subscribedCell = (row) =>
  row.subscribedToObj &&
  row.subscribedToObj.length > 0 && (
    <div className={styles.subscribedContainer}>
      <p className={styles.subscribedTitle}>
        <TranslateComponent>Subscribed to</TranslateComponent>
      </p>
      <Chips>
        {row.subscribedToObj.map((sub, index) =>
          sub?.deviceData ? (
            <Chip
              weight={ChipWeight.w600}
              key={index}
              text={sub.deviceData.name}
            />
          ) : null
        )}
      </Chips>
    </div>
  )
