import { useState, useEffect } from 'react'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment/moment'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { RadioGroup } from 'src/components'
import Selectrix from 'src/denali-ui/components/Selectrix'
import NewStyles from 'src/NewStyles'
import Modal from 'src/components/legacy/components/modal/modal'
import { accessControlFunc } from 'src/components/accessControl'
import {
  DatePickerWrapper,
  DeviceListContainer,
  ReprocessContainer
} from './styles'
import {
  GET_DEVICES_BY_ID,
  REPROCESS_ANALYTICS,
  REPROCESS_DATA_COLLECTION,
  SEARCH_BUILDINGS
} from './graphql'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import RadioButton from 'src/denali-ui/components/Radio'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { v4 as uuid4 } from 'uuid'

export const DataReprocess = () => {
  const { buildingId } = getSearchParams()
  const [reprocessDates, setReprocessDates] = useState(null)
  const [reprocessType, setReprocessType] = useState('AS')
  const [ingestionType, setIngestionType] = useState('inventory')
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [devicesList, setDevicesList] = useState([])
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const allowAllDates = accessControlFunc({ id: 'tc.pages.data-reprocess.admin' })

  const [selectedDeviceList, setSelectedDeviceList] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([])

  const userInfo = useSelector(selectUserInfo)

  const {
    data: buildingDevices,
    refetch: refetchDevices,
    loading: loadingBuildingDevices
  } = useQuery({
    query: GET_DEVICES_BY_ID,
    dataPath: 'data.getBuilding.devices',
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  const { onSubmit: reprocessAnalytics, loading: loadingReprocessAnalytics } =
    useMutation({
      query: REPROCESS_ANALYTICS,
      dataPath: 'data',
      errorPolicy: 'all'
    })

  const getQueryResponseMap = (values) => (response) =>
    response.map((res) => ({
      value: res.id,
      children: res.name,
      selected: values.some((e) => e.id === res.id),
      ...res
    }))

  const {
    onSubmit: reprocessDataCollection,
    loading: loadingReprocessDataCollection
  } = useMutation({
    query: REPROCESS_DATA_COLLECTION,
    dataPath: 'data',
    errorPolicy: 'all'
  })

  useEffect(() => {
    if (buildingId) {
      refetchDevices({ id: buildingId })
    }
  }, [buildingId])

  const onChangeReprocessType = (event) => {
    setReprocessType(event.target.name)
  }

  useEffect(() => {
    if (buildingDevices && buildingDevices?.items?.length > 0) {
      const list = buildingDevices?.items?.map((x) => ({
        key: x?.serial,
        label: x?.name || x?.uiDisplayName
      }))
      setDevicesList(list)
    }
  }, [buildingDevices])

  const settingDefaultDates = () => {
    const defaultStartDate = moment()
      .subtract(1, 'months')
      .format(BACKEND_DATE_FORMAT)

    const defaultEndDate = moment()
      .subtract(1, 'days')
      .format(BACKEND_DATE_FORMAT)

    setReprocessDates({
      startDate: defaultStartDate,
      endDate: defaultEndDate
    })
  }

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false)
  }

  useEffect(() => {
    settingDefaultDates()
  }, [])

  const successModalConfig = {
    heading: 'Success',
    buttons: [
      {
        text: 'Close',
        handleClick: closeSuccessModal,
        type: 'cancel'
      }
    ],
    handleClose: closeSuccessModal
  }

  const resetAll = () => {
    //settingDefaultDates()
    setSelectedDeviceList([])
    setSelectedOptions([])
  }

  const onDateChange = (selectedDate, datePickerType) => {
    const isOutOfRange = allowAllDates ? false : datePickerType === 'start' 
    ? Boolean(moment().subtract(3, "months").isAfter(moment(selectedDate))) || Boolean(moment().subtract(3, "months").isAfter(moment(reprocessDates?.endDate)))
    : Boolean(moment().subtract(3, "months").isAfter(moment(selectedDate))) || Boolean(moment().subtract(3, "months").isAfter(moment(reprocessDates?.startDate)))
    if (isOutOfRange) {
        setHasError(true)
        setErrorMessage("Cannot reproccess more than three months back.")
      } else {
        setHasError(false)
      }
    if (datePickerType === 'start') {
      if (moment(selectedDate).isAfter(moment(reprocessDates?.endDate))) {
        setHasError(true)
        setErrorMessage("Start date must come before end date.")
      } else if (!isOutOfRange) {
        setHasError(false)
        setErrorMessage(null)
      }
      setReprocessDates({
        startDate: moment(selectedDate)?.format(BACKEND_DATE_FORMAT),
        endDate: reprocessDates?.endDate
      })
    } else {
      if (moment(selectedDate).isBefore(moment(reprocessDates?.startDate))) {
        setHasError(true)
        setErrorMessage("Start date must come before end date.")
      } else if (!isOutOfRange) {
        setHasError(false)
        setErrorMessage(null)
      }
      setReprocessDates({
        startDate: reprocessDates?.startDate,
        endDate: moment(selectedDate)?.format(BACKEND_DATE_FORMAT)
      })
    }
  }

  const analyticsReprocessHandler = async () => {
    try {
      const uuid = uuid4()
      const inputJSON = {
        id: uuid,
        startDate: reprocessDates?.startDate,
        endDate: reprocessDates?.endDate,
        buildingIds: selectedOptions?.map((x) => x.id)?.join(','),
        rpData: {
          id: uuid,
          cmp: 'analytics',
          ptype: 'reprocessing',
          rdate: reprocessDates?.startDate,
          reqBy: userInfo?.email || userInfo?.id,
          reqDt: moment.utc().format()
        }
      }
      const reprocessResult = await reprocessAnalytics({
        requestBody: JSON.stringify(inputJSON)
      })
      resetAll()
      setIsSuccessModalOpen(true)
    } catch (error) {}
  }

  const dataReprocessHandler = async () => {
    try {
      const uuid = uuid4()
      const inputJSON = {
        id: uuid,
        startDate: reprocessDates?.startDate,
        endDate: reprocessDates?.endDate,
        //deviceSerial: selectedDeviceList?.map((x) => x?.key).join(','),
        buildingIds: selectedOptions?.map((x) => x.id)?.join(', '),
        type: ingestionType,
        rpData: {
          id: uuid,
          cmp: ingestionType,
          ptype: 'reprocessing',
          rdate: reprocessDates?.startDate,
          reqBy: userInfo?.email || userInfo?.id,
          reqDt: moment.utc().format()
        }
      }
      const reprocessResult = await reprocessDataCollection({
        request: JSON.stringify(inputJSON)
      })
      resetAll()
      setIsSuccessModalOpen(true)
    } catch (error) {}
  }

  const ingestionOptionTypes = [
    { label: 'Inventory', value: 'inventory', htmlFor: 'inventory' },
    { label: 'Config', value: 'config', htmlFor: 'config' },
    { label: 'Collected', value: 'collected', htmlFor: 'collected' }
  ]

  const startReprocess = () => {
    if (reprocessType === 'AS') {
      analyticsReprocessHandler()
    } else {
      dataReprocessHandler()
    }
  }

  const handleIngestionTypeChange = (name, value) => {
    setIngestionType(value)
  }

  const onChangeOption = (selectedItems) => {
    setSelectedDeviceList(selectedItems)
  }

  const selectedBuildingOnChange = (e, child) => {
    const selectedOptionsNew = [...selectedOptions]
    const index = selectedOptionsNew.findIndex(
      (e) => e.id === child.props.value
    )
    if (index === -1) {
      selectedOptionsNew.push({
        id: child.props.value,
        name: child.props.children,
        ...child.props
      })
    } else {
      selectedOptionsNew.splice(index, 1)
    }

    setSelectedOptions(selectedOptionsNew)
  }

  return (
    <ReprocessContainer>
      <h1 style={{ fontSize: 17, fontWeight: 700 }}>Data Reprocess</h1>
      <hr></hr>
      {loadingBuildingDevices ? (
        <Spinner />
      ) : (
        <div style={{ marginTop: 10 }}>
          <RadioButton
            label="Analytics"
            labelPosition="right"
            className="reprocess-type-radio"
            name="AS"
            data-testid="reprocess_radio_as"
            onChange={onChangeReprocessType}
            checked={reprocessType === 'AS'}
          />
          <RadioButton
            style={{ marginLeft: 20 }}
            label="Data Ingestion"
            labelPosition="right"
            className="reprocess-radio"
            name="DC"
            data-testid="reprocess_radio_dc"
            onChange={onChangeReprocessType}
            checked={reprocessType === 'DC'}
          />
          <div style={{ width: 600, marginTop: 20 }}>
            <NewStyles>
              <Selectrix
                onChange={selectedBuildingOnChange}
                query={SEARCH_BUILDINGS}
                fullWidth={true}
                multiple={true}
                value={selectedOptions}
                allowCheckAll={false}
                getQueryResponseMap={getQueryResponseMap(selectedOptions)}
                sort={true}
                placeholder={'Find a Building...'}
                searchPlaceHolder="Find Building..."
              />
            </NewStyles>
          </div>
          {selectedOptions?.length > 0 && (
            <div style={{ fontWeight: 700, fontSize: 14, marginTop: 10 }}>
              <p>Selected Buildings:</p>
              <p style={{ fontWeight: 500, fontSize: 12, marginTop: 3 }}>
                {selectedOptions?.map((x) => x.name)?.join(', ')}
              </p>
            </div>
          )}

          {reprocessType === 'DC' && (
            <>
              <div style={{ marginTop: 10 }}>
                <RadioGroup
                  isPaddingRequired={true}
                  label="Ingestion Type"
                  handleSelection={({ target: { value, name } }) => {
                    handleIngestionTypeChange(name, value)
                  }}
                  name="ingestionType"
                  radioKey="ingestionType"
                  options={ingestionOptionTypes}
                  selectedValue={ingestionType}
                />
              </div>
              {/* <DeviceListContainer>
                <p>Select Devices :</p>
                <SelectrixWrapper
                  className="selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select user-list"
                  defaultValue={false}
                  onChange={onChangeOption}
                  options={devicesList || []}
                  placeholder={'— Select One —'}
                  searchable={true}
                  searchBoxInside={true}
                  searchIndex={false}
                  isLoading={loadingBuildingDevices}
                  notFoundPrompt={'No devices found'}
                  inputPlaceholder={'Find a device...'}
                  multiple={true}
                  selectAllButton={true}
                  lifo={true}
                />
              </DeviceListContainer> */}
            </>
          )}

          <DatePickerWrapper>
            <ModalDatePickerInput
              isRequired={false}
              labelText="Start date"
              date={reprocessDates?.startDate}
              onChange={(date) => onDateChange(date, 'start')}
              dateType="startDate"
              hasError={hasError}
              errorMessage={errorMessage}
              disableComponentErrorMessage={true}
            />
            <div className="date-seperator">—</div>
            <ModalDatePickerInput
              labelText="End date"
              isRequired={false}
              endDateSelector={true}
              hasError={hasError}
              date={reprocessDates?.endDate}
              onChange={(date) => onDateChange(date, 'end')}
              dateType="endDate"
              disableComponentErrorMessage={true}
            />
          </DatePickerWrapper>
          <button
            disabled={
              hasError ||
              selectedOptions?.length <= 0 ||
              loadingReprocessDataCollection ||
              loadingReprocessAnalytics
            }
            className="primary"
            type="button"
            onClick={startReprocess}
            data-testid="reprocess_button"
          >
            Reprocess
          </button>
        </div>
      )}
      {isSuccessModalOpen && (
        <Modal {...successModalConfig}>
          Reprocess started for the selected building(s).
        </Modal>
      )}
    </ReprocessContainer>
  )
}
