import { useFormContext } from 'react-hook-form'
import styles from './form.module.scss'

export const DenaliFormDisabledOverlay = ({
  children,
  isDisabled
}: {
  children: React.ReactNode
  isDisabled?: boolean
}) => {
  // Also check if the form is disabled.
  const { formState } = useFormContext()
  const isFormDisabled = formState.disabled || isDisabled
  return (
    <div
      className={`${styles.denaliFormDisabledOverlay} ${
        isFormDisabled ? styles.visible : ''
      }`}
    >
      {children}
    </div>
  )
}
