import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// Project imports
import translate, { TranslateComponent } from 'src/common/translations'
import Table from 'src/components/Table/clientSideTable'
import { useQuery } from 'src/hooks/APIHooks'
import {
  GET_OPPORTUNITIES_BY_BUILDING_ID
} from 'src/pages/opportunities/graphql'

// Denali imports
import { DenaliTableStyle } from 'src/denali-components/Table/table.d'

// Local imports
import { OpportunityTablePicketWrapper } from '../styles'

const OpportunityTablePicker = (props: any) => {
  const { handleLoading, accountId, buildingId, selectedItems, isDenaliTable } = props ?? {}
  const [opportunities, setOpportunities] = useState([])
  const {
    data: listData,
    refetch: fetchOpportunities,
    responseTime: listResponseTime
  } = useQuery({
    query: accountId && buildingId
        ? GET_OPPORTUNITIES_BY_BUILDING_ID
        : '',
    disableInitialLoad: true,
    errorPolicy: 'all',
    variables: accountId && buildingId
        ? { buildingId }
        : '',
    dataPath:
      buildingId && !accountId
        ? 'data'
        : 'data'
  })

  useEffect(() => {
    fetchOpportunities()
  }, [])

  useEffect(() => {
    if (listData && listData?.listOpportunitiesByBuilding && listData?.listOpportunitiesByBuilding?.items?.length > 0) {
      const buildingDetails = listData?.getBuilding || {}
      const listOpportunities = listData?.listOpportunitiesByBuilding?.items || []
      setOpportunities(
        !listOpportunities
          ? []
          : listOpportunities?.map((o) => ({
              ...o,
              equipments: !_isEmpty(o?.equipments?.items)
                ? o?.equipments?.items
                    ?.map((eq) => `${eq?.equipment?.type} > ${eq?.equipment?.name}`)
                    ?.join(',')
                : '',
              opportunityId: o?.id,
              name: o?.title,
              buildingName: buildingDetails?.name ?? '',
              checkboxCheckedEnabled: selectedItems
                ?.map((i) => i?.key)
                ?.includes(o?.id)
            }))
      )
    }
  }, [listData, selectedItems])

  const headings = [
    {
      title: translate('Opportunity Name'),
      key: 'title',
      disabled: true,
      maxWidth: '120px'
    },
    {
      title: translate('Priority'),
      key: 'priority',
      disabled: true,
      maxWidth: '60px',
      customComponent: (row) => { return <TranslateComponent>{row.priority}</TranslateComponent>}
    },
    {
      title: translate('Building'),
      key: 'buildingName',
      disabled: true,
      maxWidth: '100px'
    },
    {
      title: translate('Equipment'),
      key: 'equipments',
      disabled: true,
      maxWidth: '100px'
    }
  ]

  handleLoading(opportunities?.length)

  const handleRowChecked = (data) => {
    data.checkboxCheckedEnabled = !data.checkboxCheckedEnabled
    props.onSelect({ key: data.id, value: data.title })
  }

  return (
    <OpportunityTablePicketWrapper>
      <div className="custom-table opportunities-table">
        <header><TranslateComponent>Select Opportunity:</TranslateComponent></header>
        <div className="opportunities-table">
          <Table
            key={`opportunityTablePickerTable-${accountId} ${buildingId? buildingId: ''}`}
            rows={opportunities}
            header={headings}
            rowCheckbox={true}
            loadTime={listResponseTime}
            isDenaliTable={isDenaliTable}
            denaliTableStyle={isDenaliTable ? DenaliTableStyle.classic : null}
            checkboxClick={handleRowChecked}
            checkboxChecked={() => {}}
            search={true}
            searchFields={['name']}
            isShowPagination={false}
            fixedLayout={false}
          />
        </div>
      </div>
    </OpportunityTablePicketWrapper>
  )
}

OpportunityTablePicker.propTypes = {
  accountId: PropTypes.string,
  buildingId: PropTypes.string,
  isDenaliTable: PropTypes.bool,
  opportunities: PropTypes.shape({
    Opportunities: PropTypes.array,
    loading: PropTypes.bool
  }).isRequired,
  pickerClassName: PropTypes.string,
  onSelect: PropTypes.func,
  selectedItems: PropTypes.array,
  handleLoading: PropTypes.func,
  responseTime: PropTypes.string,
  organizationId: PropTypes.string
}

OpportunityTablePicker.defaultProps = {
  selectedItems: [],
  onSelect: () => {},
  handleLoading: () => {}
}
export default OpportunityTablePicker
