export const isValidValue = (value) => {
  if (
    value === undefined ||
    value === '' ||
    value === null
  )
    return false
  else return true
}


export const SerialNumberConversion = (text, isTrim) => {
  return isTrim ? text?.slice(0, -6).toUpperCase().trim() : text.toUpperCase().trim()
}

export const formatConnectivityTypeOptions = (ConnectionTypes) => {
  if (!ConnectionTypes) return []

  return ConnectionTypes.map(item => {
    const parsedValue = JSON.parse(item.value);
    return {
      key: parsedValue.Code,
      value: parsedValue.uiDisplayName,
      listValue: parsedValue.uiShortDisplayName
    };
  })
}