import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import './imageuploader.scss'
import ErrorDialog from 'src/components/legacy/components/error-dialog/error-dialog.jsx'
import ConfirmDialog from 'src/components/legacy/components/confirm-dialog'
import { convertBase64ToBlob } from 'src/components/legacy/common/helpers'
import ImageEditorWrapper from 'src/components/legacy/components/image-editor-wrapper/index'
import { useAttachment } from '../../hooks/attachment'
import { updateFileNameWithEpoch, getValidFileName } from 'src/common/helperFunctions.js'
import translate, { TranslateComponent } from '../../common/translations'
import i18next from 'i18next'

import { UiMode } from '../../redux/types/AppTypes'
import styles from 'src/denali-components/Form/form.module.scss'
import { Button } from '@aws-amplify/ui-react'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'

const FILE_MAX_SIZE = 30000000

const ImageUploader = (props) => {

  const optionsTranslated = {
    image: translate('Image'),
    imageText: translate('Place cursor here and type Command+V (Mac) or Control+V to paste an image from the clipboard'),
    largeFile: translate('Image file is too large. Please choose a smaller image.'),
    errorUploading: translate('Error uploading file. Please try again.'),
    replaceImage: translate('Replace image'),
    confirmText: translate('Are you sure you want to replace this image?'),
    dropImage: translate('Drop image here'),
    chooseImage: translate('or click to choose an image to upload'),
    problemOccurred: translate('A problem occurred loading this image.'),
    yes: translate('Yes'),
    no: translate('No')
  }
  ImageUploader.propTypes = {
    imageId: PropTypes.string,
    update: PropTypes.func.isRequired,
    subjectName: PropTypes.string,
    annotationsEnabled: PropTypes.bool,
    loading: PropTypes.bool
  }

  ImageUploader.defaultProps = {
    subjectName: optionsTranslated.image,
    annotationsEnabled: false,
    loading: false
  }

  const pasteImageElem = useRef(null)
  const nativeFileInput = useRef(null)
  const textForPasteImage = optionsTranslated.imageText

  const initialImageId = props.imageId

  const [state, setState] = useState({
    progress: null,
    loading: false,
    error: null,
    confirm: null,
    errorOfPastingImage: null,
    errorOfLoadingImage: null,
    imageUrl: null,
    imageName: null,
    isOpenAnnotation: false,
    originalImageUrl: '',
    previousMarkerState: null
  })
  const [editedImage, setEditedImage] = useState(null)

  useEffect(() => {
    if (!props?.imageUrl) return
    setState({
      ...state,
      imageUrl: props?.imageUrl,
      imageName: props?.imageName,
      loading: false,
      progress: null,
      errorOfPastingImage: false
    })
  }, [props?.imageUrl])

  useEffect(() => {
    if (!props?.loading) return
    setState({
      ...state,
      imageUrl: props?.imageUrl,
      imageName: props?.imageName,
      loading: props?.loading,
      progress: null,
      errorOfPastingImage: false
    })
  }, [props?.loading])


  useEffect(() => {
    if (!editedImage) return
    handleFileUpload(editedImage, true)
  }, [editedImage])

  const { addFileFunc, downloadFileURL } = useAttachment()
  const { locationId } = props

  const isImageFile = (file) => {
    const isAllowedType = file?.type.includes('image')
    if (!isAllowedType) {
      const error = {
        text: i18next.t('components:images>ErrorUpload', {
          fileName: file.name
        })
      }
      setState({ ...state, error })
    }
    return isAllowedType
  }

  const hasAllowedSize = (file) => {
    const hasAllowedSize = file.size < FILE_MAX_SIZE

    if (!hasAllowedSize) {
      const error = { text: optionsTranslated.largeFile }
      setState({ ...state, error })
      setTimeout(deleteImageFromPastedContainer, 0)
    }
    return hasAllowedSize
  }

  // this check is used to avoid deleting original imageId in case editing will be canceled
  const isNewFileUploaded = () => {
    const { imageId } = props
    return imageId && imageId != initialImageId
  }

  const handleFileUpload = (file, updateFromImageEditor = false) => {
    if (!state.loading && file && isImageFile(file) && hasAllowedSize(file)) {
      isNewFileUploaded() || firstUploadInEditingMode()
        ? replaceFile(file, updateFromImageEditor)
        : uploadFile(file)
    }
  }

  const firstUploadInEditingMode = () => {
    const { imageId } = props
    return initialImageId && initialImageId === imageId
  }

  const uploadFile = (file) => {
    const fileName = getValidFileName(file?.name)
    const displayName = file?.name
    setState({
      ...state,
      loading: true,
      progress: 0,
      errorOfLoadingImage: false
    })
    props.update({ loading: true })
    Object.defineProperty(file, 'name', {
      writable: true,
      value: updateFileNameWithEpoch(fileName)
    })
    return addFileFunc(file, locationId)
      .then((res) => {
        if (res && res.statusCode && res.statusCode !== 200) {
          throw res.reason || res.statusText
        } else {
          const imageId = file.name + '#' + locationId
          downloadFileURL(file.name, locationId, true).then((url) => {
            setState({
              ...state,
              imageUrl: url,
              imageName: file.name,
              loading: false,
              progress: null,
              errorOfPastingImage: false
            })
            setEditedImage(null)
            props.update({
              name: file.name,
              fileName: file.name,
              url: res?.url,
              original: url,
              imageUrl: url,
              loading: false,
              size: file?.size,
              type: file?.type,
              loading: false,
              imageId,
              displayName,
              file // Need this for the usecase where buildingId is not available while uploading image
            })
          })
        }
      })
      .catch(() => {
        setEditedImage(null)
        setState({
          ...state,
          error: { text: optionsTranslated.errorUploading },
          loading: false,
          progress: null
        })
      })
  }

  const replaceFile = (file, updateFromImageEditor = false) => {
    setState({
      ...state,
      confirm: {
        title: optionsTranslated.replaceImage,
        confirmText: optionsTranslated.confirmText,
        ok: () => {
          setState({ ...state, confirm: null })
          !updateFromImageEditor &&
            setState({
              ...state,
              originalImageUrl: '',
              previousMarkerState: null
            })
          return uploadFile(file)
        }
      }
    })
  }

  const selectFile = () => {
    // when user select file by browsing file system
    const file = nativeFileInput.current.files[0]
    if (file) {
      handleFileUpload(file)
    }
  }

  const clearData = () => {
    const { subjectName } = props
    setState({
      ...state,
      confirm: {
        title: "Remove image",
        confirmText: `Are you sure that you want to remove ${state?.imageName || ""} from this ${subjectName}?`,
        ok: () => {
          setState({ ...state, imageUrl: null, confirm: null, imageName: null })
          props.update({ imageId: null, imageUrl: '', original: null })
        }
      }
    })
    return false
  }

  const dragOverFile = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = state.loading ? 'none' : 'copy'
  }

  const dropFile = (event) => {
    // when user uses drag'n'drop // add
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    handleFileUpload(file)
  }

  const pasteImageFromClipboard = (event) => {
    // check in Safari 11 and IE
    const file = event.clipboardData.files[0]
    file ? handleFileUpload(file) : setTimeout(handlingImageInIEBrowser, 0)
    setTimeout(deleteImageFromPastedContainer, 0)
  }

  const handlingImageInIEBrowser = () => {
    if (pasteImageElem.current.querySelector('img')) {
      const sourceImage = pasteImageElem.current
        .querySelector('img')
        .src.split(',')[1]
      try {
        atob(sourceImage)
        setState({ ...state, pastedImage: convertBase64ToBlob(sourceImage) })
        if (state.pastedImage) {
          handleFileUpload(state.pastedImage)
        }
      } catch (e) {
        const error = { text: optionsTranslated.largeFile }
        setState({ ...state, error })
      }
    } else {
      setState({ ...state, errorOfPastingImage: true })
    }
  }

  const openNativeFileBrowser = () => {
    nativeFileInput && nativeFileInput.current.click()
  }

  const deleteImageFromPastedContainer = () => {
    if (pasteImageElem && pasteImageElem.current) {
      pasteImageElem.current.textContent = ''
    }
  }

  const closeDialog = (field) => () => {
    setEditedImage(null)
    setState({ ...state, [field]: null })
  }

  const openAnnotation = () => {
    setState({ ...state, isOpenAnnotation: true })
  }

  const isAnnotationsPermitted = true
  const {
    progress,
    loading,
    error,
    errorOfPastingImage,
    errorOfLoadingImage,
    confirm,
    imageUrl,
    isOpenAnnotation,
    originalImageUrl,
    previousMarkerState
  } = state
  const { imageId, annotationsEnabled, uiMode } = props
  const isDenali = uiMode === UiMode.denali
  const isImageLoaded = imageUrl && !loading
  const isOpenImageAnnotation =
    annotationsEnabled && isOpenAnnotation && isImageLoaded
  const imageStyle = isImageLoaded
    ? {
      backgroundImage: `url(${imageUrl})`,
      cursor: 'default'
    }
    : null

  let mainContent

  if (progress !== null) {
    /* loading with progress data */
    mainContent = (
      <span className={`spinner-with-progress`}>
        <span className={isDenali ? styles.imageUploadSpinner : 'spinner'} />
      </span>
    )
  } else if (loading) {
    /* loading without progress data */
    mainContent = <span className={isDenali ? styles.imageUploadSpinner : 'spinner'} />
  } else if (!imageUrl || errorOfLoadingImage) {
    /* no image -> display text */
    mainContent = (
      <React.Fragment>
        <h2>{optionsTranslated.dropImage}</h2>
        <span className="instruction">
          {optionsTranslated.chooseImage}
        </span>

        <div className={isDenali ? styles.pasteImageContainer : "paste-container"}>
          <div className="paste-image">
            <span className="icon-paste-from-clipboard" />
            <div
              className={isDenali ? styles.pasteImageDiv : ''}
              ref={pasteImageElem}
              data-placeholder={textForPasteImage}
              placeholder={textForPasteImage}
              id="pasteImage"
              contentEditable={true}
              onPaste={pasteImageFromClipboard}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div
        className={isDenali ? styles.uploadAreaOverride : `upload-bounding-area ${errorOfLoadingImage || errorOfPastingImage ? 'loading-error' : ''
          }`}
        onDragOver={dragOverFile}
        onDrop={dropFile}
      >
        {/* div for loaded image as background display */}

        <div
          className={isDenali && !isImageLoaded ? styles.clickableDiv : 'bg'}
          onClick={openNativeFileBrowser}
          style={!isDenali ? imageStyle : {}}
        />

        {isOpenImageAnnotation && isAnnotationsPermitted && (
          <ImageEditorWrapper
            imageSrc={imageUrl}
            openImageEditor={isOpenAnnotation}
            cancelChanges={() =>
              setState({ ...state, isOpenAnnotation: false })
            }
            uploadFile={(value) => {
              setState({ ...state, isOpenAnnotation: false })
              setTimeout(() => {
                setEditedImage(value)
              }, 200)
            }}
            setOriginalUrl={(value) =>
              setState({ ...state, originalImageUrl: value })
            }
            setPreviousMarkerState={(value) =>
              setState({ ...state, previousMarkerState: value })
            }
            originalImageUrl={originalImageUrl}
            previousMarkerState={previousMarkerState}
          />
        )}

        {mainContent}

        {/* remove image button */}
        {isImageLoaded && (
          <>
            {isDenali ? (
              <div className={styles.imageLoadedWrapper}>
                <Button className={styles.backgroundImageContainer} onClick={openNativeFileBrowser}>
                  <div className={styles.bgImage} style={imageStyle} />
                </Button>
                {annotationsEnabled && isAnnotationsPermitted && (
                  <Tooltip>
                    <TooltipTrigger testName="previewSupportingImageTrigger">
                      <Button
                        className='button-gray button-icon'
                        onClick={openAnnotation}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <TooltipContent>
                        <TranslateComponent>View Image</TranslateComponent>
                      </TooltipContent>
                    </TooltipTrigger>
                  </Tooltip>
                )}
                <Tooltip>
                  <TooltipTrigger testName="removeSupportingImageTrigger">
                    <Button
                      onClick={clearData}
                      className='button-red button-icon'
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    <TooltipContent>
                      <TranslateComponent>Remove Image</TranslateComponent>
                    </TooltipContent>
                  </TooltipTrigger>
                </Tooltip>
              </div>
            ) : (
              <>
                {annotationsEnabled && isAnnotationsPermitted && (
                  <button
                    data-testid="edit-icon"
                    type="button"
                    className="icon-btn edit-icon"
                    onClick={openAnnotation}
                  >
                    <span className="icon-edit" />
                  </button>
                )}
                <button
                  data-testid="remove-icon"
                  type="button"
                  className="icon-btn red remove-icon"
                  onClick={clearData}
                >
                  <span className="icon-trash2" />
                </button>
              </>
            )}
          </>
        )}
        <input
          type="file"
          accept="image/*"
          ref={nativeFileInput}
          onChange={selectFile}
          onClick={(event) => {
            event.target.value = null
          }}
          data-testid="image-input"
        />
      </div>

      {(errorOfLoadingImage || errorOfPastingImage) && (
        <p className="error">{optionsTranslated.problemOccurred}</p>
      )}

      {/* show replace text info */}
      {isImageLoaded && !isDenali && (
        <p className="tip">
          <TranslateComponent>To replace this image: drop a new image above or </TranslateComponent>
          <span className="underline" onClick={openNativeFileBrowser} data-testid="paste-image">
            <TranslateComponent> click here to select a new image  </TranslateComponent>
          </span>
          .  <TranslateComponent>To paste a new image from your clipboard, Delete the image first.</TranslateComponent>
        </p>
      )}

      {/* show error dialog */}
      {error && error.statusCode !== 404 && (
        <ErrorDialog handleClose={closeDialog('error')} text={error.text} />
      )}

      {confirm && !isDenali && (
        <ConfirmDialog
          title={confirm.title}
          handleConfirm={confirm.ok}
          handleCancel={closeDialog('confirm')}
          confirmText={optionsTranslated.yes}
          cancelText={optionsTranslated.no}
        >
          {' '}
          <TranslateComponent>{confirm.confirmText}</TranslateComponent> {' '}
        </ConfirmDialog>
      )}

      {confirm && isDenali && (
        <DialogConfirm
          onAgree={confirm.ok}
          title={confirm.title}
          onClose={closeDialog('confirm')}
        >
          <TranslateComponent>{confirm.confirmText}</TranslateComponent>
        </DialogConfirm>
      )}
    </React.Fragment>
  )
}

export default ImageUploader
