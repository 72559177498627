import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './building-selector.module.scss'
import { getColor } from 'src/denali-components/helpers'

type BuildingIconProps = {
  name: string
  colorOverride?: string
}

export const BuildingIcon = ({
  name = '',
  colorOverride = ''
}: BuildingIconProps) => {
  const color = getColor(colorOverride ? colorOverride : name)
  return (
    <div className={styles.buildingIcon} data-color={color}>
      <FontAwesomeIcon icon={faBuilding} />
    </div>
  )
}
