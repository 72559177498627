import { Flex, Radio } from '@aws-amplify/ui-react'
import { useFloatingTree } from '@floating-ui/react'
import { useCallback } from 'react'

import { TranslateComponent } from 'src/common/translations'

import styles from '../table.module.scss'
import { getSortTextByDataType } from '../helpers'
import { SortMenuSubmenuProps } from '../types'

export const SortMenuSubmenu = ({
  data,
  selectedValue
}: SortMenuSubmenuProps) => {
  const tree = useFloatingTree()

  // Use the "type" property to decide what kind of "field" this is to determine the
  // translated label text to use.
  const getDisplayLabel = useCallback(
    (val: string) => {
      const entry = data.find((item: { value: string }) => item.value === val)

      return (
        <TranslateComponent>
          {getSortTextByDataType(entry.type ?? 'text', entry.ascending)}
        </TranslateComponent>
      )
    },
    [data]
  )

  // Render a list of radios and for each one we'll call handleSort with the right
  // column and ascending flag.
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      className={styles.sortSubMenu}
    >
      {data.map((option) => (
        <Radio
          className={`${styles.sortRadio} ${
            option.value === selectedValue ? styles.sortRadioSelected : ''
          }`}
          key={option.value}
          value={option.value}
          data-testid={`tableSortRadio-${option.value}-${option?.title}`}
          onClick={() => tree.events.emit('click')}
        >
          {getDisplayLabel(option.value)}
        </Radio>
      ))}
    </Flex>
  )
}
