import { memo, useMemo, Fragment } from 'react'
import * as d3 from 'd3'
import clsx from 'clsx'
import './Axis.css'
import {
  BOTTOM_BOTTOM,
  BOTTOM_TOP,
  RIGHT_LEFT,
  RIGHT_RIGHT,
  SPACE_BEFORE_AXIS_NAME,
  horizontalSides,
  verticalSides
} from './constants'
import {
  axisNamePositioning,
  symbolPositioning,
  tickFormatter,
  ticksGenerator,
  ticksPositioning
} from './helper'
import { AxisProps } from '../../Types/axis'
import { defaultDateFormatterTitle, getDateDiffInDays } from 'src/chart-library/Charts/XYChart/helper'
import { calculateDecimals } from 'src/chart-library/Utils/Scales/ScaleLinear/utils'
import { calculateTickCountBasedOnDimension } from 'src/chart-library/BaseSetup/PaddingSetup/helper'
import {
  SCALE_LINEAR,
  SCALE_TIME
} from 'src/chart-library/Utils/Scales/constant'
import { differenceInYears, isValid } from 'date-fns'
import translate, { TranslateComponent } from 'src/common/translations'

const Axis = (props: AxisProps) => {
  const {
    hideLine = false,
    hideTicks = false,
    hideLabel = false,
    axisStroke = 'black',
    tickStroke = 'black',
    textFill = 'black',
    strokeWidth = 1,
    scale,
    scaleType,
    dataTestId,
    tickCount = 0,
    tickSize = 10,
    paddingWidth,
    paddingHeight,
    format,
    height,
    width,
    textSelect = false,
    textStyles = {},
    opacity = 1,
    labelClassName = '',
    axisClassname = '',
    side = '',
    hideFirst = false,
    hideLast = false,
    tickValues,
    visibleAt,
    isSlantingTickLable = false,
    name,
    notD3 = false,
    d3Ticks,
    spaceDetails = {},
    adjustmentScale
  } = props

  const {
    isX,
    axisLineD,
    ticks,
    symbol,
    dateDiff,
    symbolStyles,
    axisNameStyles,
    axisNameText,
    decimalCount,
    isAxisTextTranslate = true,
  } = useMemo(() => {
    const range = scale?.range() || []
    let data: any = [() => 0, () => 0]
    if (verticalSides?.includes(side)) {
      data = [() => 0, (d: any) => Number(d)]
    }
    if (horizontalSides?.includes(side)) {
      data = [(d: any) => Number(d), () => 0]
    }
    const isX = horizontalSides?.includes(side)
    const axisLineD = d3.line(...(data || []))(range)
    const dimension = isX ? paddingWidth : paddingHeight
    const dimensionBasedTickCount: any = calculateTickCountBasedOnDimension(
      dimension,
      scaleType
    )
    const ticks = ticksGenerator(
      scale,
      tickCount,
      scaleType,
      notD3,
      tickValues,
      dimensionBasedTickCount,
      d3Ticks,
      adjustmentScale
    )
    let dateDiff = 0
    if (scaleType === 'scaleTime') {
      dateDiff = getDateDiffInDays(ticks)
    }

    const axisNameText = name?.text
    const isAxisTextTranslate = name?.isTranslate
    const symbol = name?.symbol
    const axisNameStyles = axisNamePositioning({
      side,
      paddingWidth,
      paddingHeight,
      spaceDetails
    })
    const symbolStyles = symbolPositioning({ side, isX, spaceDetails })

    let decimalCount
    if (scaleType === 'scaleLinear') {
      const extent = d3.extent(ticks)
      decimalCount = calculateDecimals(
        extent?.[0],
        extent?.[1],
        dimensionBasedTickCount
      )
      decimalCount = isFinite(decimalCount) ? decimalCount : 0
    }

    return {
      isX,
      axisNameStyles,
      symbolStyles,
      symbol,
      axisNameText,
      axisLineD,
      ticks,
      dateDiff,
      dimensionBasedTickCount,
      decimalCount,
      isAxisTextTranslate
    }
  }, [scale, tickCount, side, d3Ticks])

  const transform = useMemo(() => {
    if (width && (side === RIGHT_LEFT || side === RIGHT_RIGHT)) {
      return `translate(${width},0)`
    }

    if (height && (side === BOTTOM_BOTTOM || side === BOTTOM_TOP)) {
      return `translate(0,${height})`
    }
  }, [height, width, side])

  return (
    <g
      className={clsx('trane-axis-group', axisClassname)}
      opacity={opacity}
      data-testid={dataTestId}
      transform={transform}
    >
      {!hideLine && axisLineD && (
        <path stroke={axisStroke} strokeWidth={strokeWidth} d={axisLineD} />
      )}
      {ticks?.map((val: any, index: number) => {
        // More information that will be shared to formatt function if provided
        const tickInfo = {
          index,
          isFirst: index === 0,
          isLast: ticks?.length === index + 1,
          value: val
        }

        const { tranform, x, y, x2, y2, textAnchor } = ticksPositioning(
          side,
          scale,
          val,
          hideTicks,
          tickSize,
          SPACE_BEFORE_AXIS_NAME,
          scaleType,
          adjustmentScale,
          spaceDetails?.tilted || isSlantingTickLable
        )

        let formattingDetails: any = {}
        if (scaleType === SCALE_TIME) {
          let isGTYear = false
          const fDate = new Date(ticks?.[0])
          const sDate = new Date(ticks?.[1])
          if (isValid(fDate) && isValid(sDate)) {
            const diffYears = differenceInYears(sDate, fDate)
            if (diffYears >= 1) {
              isGTYear = true
            } 
          }
          formattingDetails = {
            dateDiff: dateDiff,
            index: index,
            isGTYear
          }
        } else if (scaleType === SCALE_LINEAR) {
          formattingDetails = {
            decimalCount: decimalCount
          }
        }

        return (
          <Fragment key={index}>
            {((index === 0 && !hideFirst) ||
              (index === ticks?.length - 1 && !hideLast) ||
              (index > 0 && index < ticks?.length)) &&
              (!hideTicks || !hideLabel) && (
                <g transform={tranform}>
                  {!hideTicks && (
                    <line
                      stroke={tickStroke}
                      strokeWidth={strokeWidth}
                      y2={y2}
                      x2={x2}
                    />
                  )}
                  {!hideLabel && (
                    <text
                      transform={
                        isSlantingTickLable || spaceDetails?.tilted
                          ? `rotate(-65) translate(-${y},0)`
                          : ''
                      }
                      className={clsx('trane-axis-label AxislblCapitalize', labelClassName, {
                        'prevent-select': !textSelect
                      })}
                      fill={textFill}
                      {...textStyles}
                      textAnchor={textAnchor}
                      x={x}
                      y={!(isSlantingTickLable || spaceDetails?.tilted) && y}
                      dominantBaseline={
                        side === 'bottomBottom' || side === 'topBottom'
                          ? 'hanging'
                          : side === 'leftLeft' ||
                            side === 'leftRight' ||
                            'rightLeft' ||
                            side === 'rightRight'
                          ? 'middle'
                          : ''
                      }
                    >
                      {tickFormatter(
                        format,
                        val,
                        scaleType,
                        formattingDetails,
                        tickInfo
                      )}
                      {scaleType === SCALE_TIME && <title><TranslateComponent>{defaultDateFormatterTitle(val)}</TranslateComponent></title>}
                    </text>
                  )}
                </g>
              )}
          </Fragment>
        )
      })}
      {axisNameText && <text {...axisNameStyles}>{!isAxisTextTranslate ? axisNameText: axisNameText && <TranslateComponent>{axisNameText}</TranslateComponent>}</text>}
      {symbol && !isX && <text {...symbolStyles}>{symbol && <TranslateComponent>{symbol}</TranslateComponent>}</text>}
    </g>
  )
}

export default memo(Axis)
