import { type StylesConfig } from 'react-select'

// Default styling for some of the react-select components.
export const selectStyles: StylesConfig = {
  container: (styles) => ({
    ...styles,
    minHeight: '75px',
    border: '1px solid var(--amplify-colors-neutral-20)',
    borderRadius: 'var(--amplify-components-form-input-border-radius)'
    //paddingBottom: '0.75rem',
  }),
  control: (styles) => ({
    ...styles,
    border: 'none',
    borderRadius: 'var(--amplify-components-form-input-border-radius)'
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: 'flex',
    border: 'none',
    padding: '0 16px 12px',
    gap: 'var(--amplify-space-xxs)'
  }),
  input: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
    padding: '0',
    margin: '0'
  }),
  placeholder: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0'
  }),
  multiValue: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    backgroundColor: 'unset'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    padding: '4px 0 4px 4px',
    paddingLeft: '4px',
    margin: '0',
    fontSize: '13px',
    backgroundColor: 'unset'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    padding: '0 8px 0',
    margin: '0',
    backgroundColor: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
      cursor: 'pointer'
    }
  })
}
