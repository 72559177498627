import { useSelector } from 'react-redux'
import {
  selectBuilding,
  setSelectedBuilding
} from 'src/redux/slicers/buildingPicker'
import {
  SEARCH_BUILDINGS,
  type OrganizationId,
  type BuildingId
} from 'src/components/buildingNavigation/graphql'
import { API } from 'aws-amplify'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { type AnyAction } from '@reduxjs/toolkit'
import {
  accountMapping,
  type AccountMappingBuilding,
  type AccountMappingOrganization
} from 'src/components/buildingNavigation/helper'
import { type GraphQLOptions } from '@aws-amplify/api-graphql/lib-esm/types/index.d'
import { useDispatch } from 'react-redux'
import { IBuildingOld } from 'src/redux/types/BuildingPickerTypes'
import { useSearchParams } from 'react-router-dom'

export const LAST_ORGANIZATION_KEY = 'lastOrganization'
export const LAST_BUILDING_KEY = 'lastBuilding'

export const useSelectedBuilding = (): Selection => {
  const [organization, setOrganization] = useState<IBuildingOld | null>(null)
  const [building, setBuilding] = useState<IBuildingOld | null>(null)

  const { error: initError, initHasFinished } = useInitReduxFromFallbacks()
  const { error: updateError } = useUpdateStateOnReduxChange(
    initHasFinished,
    setBuilding,
    organization,
    setOrganization
  )

  return {
    organization,
    building,
    error: initError || updateError
  }
}

type Selection = {
  organization: IBuildingOld | null
  building: IBuildingOld | null
  error: boolean
}

const useInitReduxFromFallbacks = () => {
  const { organizationId, buildingId } = initIds()
  const [initHasFinished, setInitHasFinished] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setError(false)

    if (buildingId) {
      getBuildingAndOrgFromId(buildingId).then(({ building, organization }) => {
        setInitHasFinished(true)
        if (!building && !organization) return setError(true)
        if (!building) {
          updateReduxOrganization(organization, dispatch)
          return
        }
        updateReduxBuilding(building, dispatch)
      })
      return
    }

    getOrganizationFromId(organizationId).then(({ organization }) => {
      setInitHasFinished(true)
      if (!organization) {
        setError(true)
        return
      }
      if (organization?.buildings?.length === 1) {
        updateReduxBuilding(organization.buildings[0], dispatch)
        return
      }
      updateReduxOrganization(organization, dispatch)
    })
  }, [])

  return {
    error,
    initHasFinished
  }
}

const useUpdateStateOnReduxChange = (
  initHasFinished: boolean,
  setBuilding: Dispatch<SetStateAction<IBuildingOld | null>>,
  organization: IBuildingOld | null,
  setOrganization: Dispatch<SetStateAction<IBuildingOld | null>>
) => {
  const selectedFromRedux = useSelector(selectBuilding)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!selectedFromRedux || !initHasFinished) return
    setError(false)

    handleReduxBuildingUpdates(selectedFromRedux, setBuilding)
    handleReduxOrganizationUpdates(
      selectedFromRedux,
      organization,
      setOrganization,
      setBuilding,
      setError
    )
  }, [selectedFromRedux, initHasFinished])

  return {
    error
  }
}

const handleReduxOrganizationUpdates = (
  selectedFromRedux: IBuildingOld,
  organization: IBuildingOld | null,
  setOrganization: Dispatch<SetStateAction<IBuildingOld | null>>,
  setBuilding: Dispatch<SetStateAction<IBuildingOld | null>>,
  setError: Dispatch<boolean>
) => {
  const isReduxSelectionAnOrg = selectedFromRedux?.type === 'organization'

  if (isReduxSelectionAnOrg) {
    setOrganization(selectedFromRedux)
    setBuilding(null)
    return
  }

  const organizationId = isReduxSelectionAnOrg
    ? selectedFromRedux.id
    : selectedFromRedux.accountId || null

  if (!organizationId) {
    // Old building selector may set a building or sales office without accountId.
    // This is handled in handleReduxBuildingUpdates, just return and wait for the next update.
    return
  }
  if (organization && organization?.id === organizationId) return

  getOrganizationFromId(organizationId).then(({ organization, isFallback }) => {
    if (!organization) {
      setOrganization(null)
      setBuilding(null)
      setError(true)
      return
    }
    setOrganization(organization)
    if (isFallback) {
      // Org not found from building ref, unset building, select default building.
      setBuilding(null)
    }
  })
}

const handleReduxBuildingUpdates = (
  selectedFromRedux: IBuildingOld,
  setBuilding: Dispatch<SetStateAction<IBuildingOld | null>>
) => {
  const isReduxSelectionABuilding = selectedFromRedux?.type === 'location'
  const isReduxSelectionAnOldBuilding =
    isReduxSelectionABuilding && !selectedFromRedux.accountId

  if (isReduxSelectionAnOldBuilding) {
    // Building selected but uses the old data structure that did not include a reference to the parent org (accountId).
    // Track these down by searching for `setSelectedBuilding` in the old components that do not include accountId
    // Get it from the API so that we can find the Org
    getBuilding(selectedFromRedux.id).then((building) => {
      setBuilding(building)
    })
  }

  if (isReduxSelectionABuilding) {
    setBuilding(selectedFromRedux)
  }
}

type InitSelection = {
  organizationId: OrganizationId | null
  buildingId: BuildingId | null
}

const initIds = (): InitSelection => {
  const selectedFromRedux = useSelector(selectBuilding)
  const [searchParams] = useSearchParams()

  const searchParamBuildingId = searchParams.get('location')
  const searchParamOrgId = searchParams.get('organization')
  const isReduxOrganization = !!(selectedFromRedux?.type === 'organization')
  const isReduxBuilding = !!(
    selectedFromRedux?.type === 'location' && selectedFromRedux?.accountId
  )
  const isReduxOldBuilding = !!(
    selectedFromRedux?.type === 'location' && !selectedFromRedux?.accountId
  )
  const isUrlOrganization = !!(searchParamOrgId && !searchParamBuildingId)
  const isUrlBuilding = !!(searchParamOrgId && searchParamBuildingId)
  const lastOrgLocalStorage = localStorage.getItem(LAST_ORGANIZATION_KEY)
  const lastBuildingLocalStorage = localStorage.getItem(LAST_BUILDING_KEY)
  const isLocalStorageSaved = !!(
    lastOrgLocalStorage || lastBuildingLocalStorage
  )

  let organizationId = null
  let buildingId = null

  if (isReduxOrganization) {
    organizationId = selectedFromRedux.id
  } else if (isReduxBuilding) {
    organizationId = selectedFromRedux.accountId
    buildingId = selectedFromRedux.id
  } else if (isReduxOldBuilding) {
    // Building selected but uses the old data structure that did not include a reference to the parent org.
    // Track these down by searching for `setSelectedBuilding` in the old components that do not include accountId
    // when setting a building (not an org or sales office).
    buildingId = selectedFromRedux.id
  } else if (isUrlOrganization || isUrlBuilding) {
    organizationId = searchParamOrgId
    buildingId = searchParamBuildingId || null
  } else if (isLocalStorageSaved) {
    organizationId = lastOrgLocalStorage || null
    buildingId = lastBuildingLocalStorage || null
  }

  return {
    organizationId,
    buildingId
  }
}

type BuildingSelection = {
  organization?
  building?
  isFallback: boolean
}

export const getBuildingAndOrgFromId = (
  buildingId
): Promise<BuildingSelection> => {
  return getBuilding(buildingId).then((building) => {
    if (!building) {
      return selectionFallback()
    }
    return getOrganizationFromId(building.accountId).then(
      ({ organization }) => {
        return {
          building,
          organization,
          isFallback: false
        }
      }
    )
  })
}

export const getOrganizationFromId = (
  organizationId: OrganizationId
): Promise<BuildingSelection> => {
  return getFirstOrganization(organizationId).then((organization) => {
    if (!organization) {
      return selectionFallback()
    }
    return { organization, isFallback: false }
  })
}

export function updateReduxBuilding(
  building: AccountMappingBuilding,
  dispatch: Dispatch<AnyAction>
) {
  if (!building) {
    setSelectedBuilding(null)
    return
  }

  dispatch(
    setSelectedBuilding({
      id: building.id,
      primary: building.primary,
      secondary: building.secondary,
      icon: building.icon,
      type: building.type,
      accountId: building.accountId
    } as IBuildingOld)
  )
}

export function updateReduxOrganization(
  organization: AccountMappingOrganization,
  dispatch: Dispatch<AnyAction>
) {
  if (!organization) {
    setSelectedBuilding(null)
    return
  }

  dispatch(
    setSelectedBuilding({
      id: organization.id,
      primary: organization.primary,
      secondary: organization.secondary,
      icon: organization.icon,
      type: organization.type,
      buildings: organization.buildings
    })
  )
}

const selectionFallback = () => {
  return getFirstOrganization().then((organization) => {
    return organization
      ? { organization, isFallback: true }
      : { isFallback: true }
  })
}

const getFirstOrganization = async (
  organizationId: OrganizationId | null = null
): Promise<AccountMappingOrganization> => {
  try {
    const options: GraphQLOptions = {
      query: SEARCH_BUILDINGS,
      variables: {
        limit: 1000
      }
    }
    if (organizationId) {
      options.variables = {
        filter: {
          or: [{ accountId: { eq: organizationId } }]
        },
        limit: 1000
      }
    }
    const apiData: any = await API.graphql(options)
    const accountsData = accountMapping(
      apiData?.data?.searchBuildings?.items,
      true
    )
    return Object.values(accountsData)?.[0] as AccountMappingOrganization
  } catch (error) {
    console.error(error)
  }
}

const getBuilding = async (
  buildingId: BuildingId
): Promise<AccountMappingBuilding> => {
  try {
    const apiData: any = await API.graphql({
      query: SEARCH_BUILDINGS,
      variables: {
        filter: {
          or: [{ id: { eq: buildingId } }]
        }
      }
    })
    const accountsData = accountMapping(
      apiData?.data?.searchBuildings?.items,
      true
    )
    const org = Object.values(accountsData)?.[0] as AccountMappingOrganization
    return org?.buildings?.[0]
  } catch (error) {
    console.error(error)
  }
}
