import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  DELETE_USER_HISTORY,
  SAVE_USER_HISTORY,
  UPDATE_USER_HISTORY
} from 'src/components/buildingNavigation/graphql'
import { useUserSearchHistory } from 'src/components/buildingNavigation/use-user-search-history'
import { useMutation } from 'src/hooks/APIHooks'
import { getSession } from 'src/redux/slicers/appData'
import {
  selectBuilding,
  selectUserHistory
} from 'src/redux/slicers/buildingPicker'

export const RouteHistoryWrapper = ({ children }) => {
  const selectedOrgBuildingSales = useSelector(selectBuilding)
  const { refetch: fetchUserHistory } = useUserSearchHistory()

  useEffect(() => {
    getHistory(fetchUserHistory)
  }, [])

  const updateHistoryCallback = useUpdateHistory(fetchUserHistory)
  useEffect(() => {
    updateHistoryCallback()
  }, [selectedOrgBuildingSales])

  return <div data-testid="RouteHistoryWrapper">{children}</div>
}

const getHistory = async (fetchUserHistory) => {
  const session: any = await getSession()
  fetchUserHistory({ owner: session?.idToken?.payload['custom:userId'] })
}

const useUpdateHistory = (fetchUserHistory) => {
  const selectedOrgBuildingSales = useSelector(selectBuilding)
  const [lastSelection, setLastSelection] = useState(selectedOrgBuildingSales)
  const { data: recentSearchItems } = useSelector(selectUserHistory)

  // migrated from src/components/buildingNavigation/building-navigation.tsx
  const { onSubmit: updateBuildingUserHistory } = useMutation({
    query: UPDATE_USER_HISTORY,
    dataPath: 'data.updateUserHistory',
    onError: () => {
      return // Suppressing unnecessary error (happens when multiple tabs up sometimes)
    }
  })

  // migrated from src/components/buildingNavigation/building-navigation.tsx
  const { onSubmit: deleteBuildingUserHistory } = useMutation({
    query: DELETE_USER_HISTORY,
    dataPath: 'data.deleteUserHistory',
    onError: () => {
      return // Suppressing unnecessary error (happens when multiple tabs up sometimes)
    }
  })

  // migrated from src/components/buildingNavigation/building-navigation.tsx
  const { onSubmit: addBuildingUserHistory } = useMutation({
    query: SAVE_USER_HISTORY,
    dataPath: 'data.createUserHistory'
  })

  return () => {
    updateHistoryAsync(
      selectedOrgBuildingSales,
      lastSelection,
      setLastSelection,
      recentSearchItems,
      updateBuildingUserHistory,
      fetchUserHistory,
      deleteBuildingUserHistory,
      addBuildingUserHistory
    )
  }
}

const updateHistoryAsync = async (
  selectedOrgBuildingSales,
  lastSelection,
  setLastSelection,
  recentSearchItems,
  updateBuildingUserHistory,
  fetchUserHistory,
  deleteBuildingUserHistory,
  addBuildingUserHistory
) => {
  if (!selectedOrgBuildingSales) return
  if (selectedOrgBuildingSales?.id === lastSelection?.id) return
  setLastSelection(selectedOrgBuildingSales)
  const matchedHistoryItem = recentSearchItems.find(
    (historyItem) => historyItem?.id === selectedOrgBuildingSales?.id
  )

  const session: any = await getSession()

  if (matchedHistoryItem) {
    await updateBuildingUserHistory({
      input: { id: matchedHistoryItem?.dbIdentifier, eTime: moment().unix() }
    })
    fetchUserHistory({ owner: session?.idToken?.payload['custom:userId'] })
    return
  }

  if (recentSearchItems?.length >= 10) {
    await deleteBuildingUserHistory({
      input: { id: recentSearchItems[9]?.dbIdentifier }
    })
  }

  const newItemData = buildHistoryItem(
    selectedOrgBuildingSales,
    session?.['custom:userId']
  )

  await addBuildingUserHistory({
    input: newItemData
  })

  fetchUserHistory({ owner: session?.idToken?.payload['custom:userId'] })
}

const buildHistoryItem = (selectedOrgBuildingSales, owner) => {
  const newItemData = {
    accountId:
      selectedOrgBuildingSales?.type === 'organization'
        ? selectedOrgBuildingSales?.id
        : selectedOrgBuildingSales?.accountId,
    eTime: moment().unix(),
    owner: owner,
    searchQuery: '',
    type: selectedOrgBuildingSales?.type
  } as any
  if (selectedOrgBuildingSales?.type === 'location') {
    newItemData.buildingId = selectedOrgBuildingSales?.id
  }
  if (selectedOrgBuildingSales?.type === 'salesOffices') {
    newItemData.salesOfficeId = selectedOrgBuildingSales?.id
  }

  return newItemData
}
