import { useState } from 'react'
import {
  ChartSettingsContainer,
  Header,
  Title,
  ArrowContainer,
  Arrow
} from './styles'
import translate, { TranslateComponent } from 'src/common/translations'
import store from 'src/redux/store'

const ChartSettings = (props) => {
  const [open, setOpen] = useState(false)
  const { children } = props

  const toggleOpen = () => {
    setOpen(!open)
  }
  const translateChartSettings = translate("CHARTSETTINGS")
 
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";

  const chartControlsFunc = ({ open, children, testName = '' }) => {
    return (
      <div
        data-testid={`${testName}-chart-settings-controls`}
        className={open ? 'chart-controls' : 'chart-controls hide'}
      >
        <div className="control-elements">
          {children}
          <div className="control-group last">
            <button
              type="button"
              className="primary small"
              onClick={toggleOpen}
            >
              <TranslateComponent>Done</TranslateComponent>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ChartSettingsContainer>
      <div
        data-testid={`${props?.testName || ''}-chart-settings`}
        className="accordion-wrapper"
      >
        <Header onClick={toggleOpen} className={open ? 'open' : 'closed'}>
          <span className="icon icon-gear2" />
          <Title className={selectedLanguage !== 'en' && 'title-ellipsis'}>
            <span title={selectedLanguage !== 'en' && translateChartSettings}>{translateChartSettings}</span>
          </Title>
          <ArrowContainer>
            {open ? (
              <Arrow className="open">
                <span className="icon icon-expand" />
              </Arrow>
            ) : (
              <Arrow>
                <span className="icon icon-expand" />
              </Arrow>
            )}
          </ArrowContainer>
        </Header>
      </div>
      {chartControlsFunc({ open, children, testName: props?.testName })}
    </ChartSettingsContainer>
  )
}

export default ChartSettings
