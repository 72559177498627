import React, { useState } from 'react'
import {
  CollapsibleWithToggleComponentProps,
  CollapsibleStyle
} from './types.d'
import styles from './collapsible-with-toggle.module.scss'
import { SwitchField } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronDown
} from 'src/denali-components/lib/pro-solid-svg-icons'
export const CollapsibleWithToggle = ({
  header,
  children,
  autoExpand = false,
  onToggle,
  isToggled,
  icon,
  style = CollapsibleStyle.default,
  className
}: CollapsibleWithToggleComponentProps) => {
  const styleClass = style.toString().toLowerCase()

  // auto expand will open each card's children but will not expand (even if auto expand is on) if the children prop is not populated
  const [collapsed, setCollapsed] = useState(
    autoExpand ? !!!children : !isToggled
  )
  const [isChecked, setIsChecked] = useState(isToggled)

  const handleToggle = () => {
    setIsChecked((prevState) => !prevState)
    setCollapsed((prevState) => (prevState ? !isToggled : false))
    isToggled = !isChecked
  }

  return (
    <div
      className={`${styles.collapsible} ${
        collapsed ? styles.collapsed : ''
      } ${styleClass} ${className ?? ''}`}
    >
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          {collapsed ? (
            <FontAwesomeIcon
              icon={faChevronRight}
              onClick={() => setCollapsed((prevState) => !prevState)}
              data-testid={'collapsible-chevron-right'}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              onClick={() => setCollapsed((prevState) => !prevState)}
              data-testid={'collapsible-chevron-down'}
            />
          )}
          {icon}
          <div
            className={`${collapsed ? '' : styles.header}`}
            data-testid={'collapsible-header-text'}
          >
            {header}
          </div>
        </div>
        <SwitchField
          onChange={() => {
            handleToggle()
            onToggle()
          }}
          className={styles.toggle}
          isChecked={isToggled}
          size="large"
          isLabelHidden={true}
          label={''}
          data-testid={'collapsible-toggle'}
        ></SwitchField>
      </div>
      <div className={styles.collapsibleChildren}>{children}</div>
    </div>
  )
}
