import Modal from 'src/components/legacy/components/modal/modal'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { ACTIONS } from './helpers'
import { OverviewSchema } from './Validations'
import { BaselineFormTabs } from './BaseLineFormTabs'
import moment from 'moment/moment'
import {
  BACKEND_DATE_FORMAT,
  DATE_FORMAT_DASH
} from 'src/components/legacy/common/time-helpers'
import { CREATE_BASELINE, UPDATE_BASELINE } from '../graphql'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useBaseLinesContext } from '../BaseLinesContextProvider'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { TranslateComponent } from '../../../common/translations'
import { useDispatch } from 'react-redux'
import { newRefreshToken } from '../../../redux/slicers/appData'

const BaseLineModal = () => {
  const {
    setReloadEvents,
    mode,
    setShowBaselineModal,
    showBaselineModal,
    baseLine,
    testName,
    dataAvailablityDates
  } = useBaseLinesContext()

  const [t] = useTranslation()
  const [formvalues, setFormvalues] = useState(null)
  const [formikErrors, setFormikErrors] = useState({})
  const dispatch = useDispatch()
  const [isTriggerValidation, setIsTriggerValidation] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const organizationId = urlParams.get('organization')

  const { onSubmit: createBaseLineMutation, loading: loadingCreateBaseline } =
    useMutation({
      query: CREATE_BASELINE,
      dataPath: 'data.createBaseline',
      errorPolicy: 'global'
    })

  const { onSubmit: updateBaseLineMutation, loading: loadingUpdateBaseline } =
    useMutation({
      query: UPDATE_BASELINE,
      dataPath: 'data.updateBaseline',
      errorPolicy: 'global'
    })

  const getFormattedDateToUTC = (exclusions) => {
    return exclusions.map((x) => {
      return {
        name: x.name,
        startPeriod: moment(x.startPeriod).locale('en').format(BACKEND_DATE_FORMAT),
        endPeriod: moment(x.endPeriod).locale('en').format(BACKEND_DATE_FORMAT)
      }
    })
  }

  const getFormattedUTCToString = (exclusions) => {
    return exclusions.map((x, index) => {
      return {
        name: x?.name,
        startPeriod: x?.startPeriod,
        endPeriod: x?.endPeriod,
        id: index
      }
    })
  }

  const createOrUpdateBaseLine = async () => {
    setIsTriggerValidation(true)
    try {
      const { equipmentDetails, ...formValues } = formik?.values
      const input = {
        ...formValues,
        exclusions: getFormattedDateToUTC(formValues?.exclusions || []),
        startPeriod: moment(formValues?.startPeriod).locale('en').format(
          BACKEND_DATE_FORMAT
        ),
        endPeriod: moment(formValues?.endPeriod).locale('en').format(BACKEND_DATE_FORMAT),
        ...(ACTIONS.ADD === mode && {
          equipmentId: equipmentDetails?.equipmentId,
          buildingId: equipmentDetails?.buildingId,
          target: equipmentDetails?.equipmentName
        })
      }
      if (ACTIONS.ADD === mode) {
        const baselineId = await createBaseLineMutation({ input })
        trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.SAVE_BASELINE, {
          mode: 'CREATE',
          description: formValues.description,
          name: formValues.name,
          'start period': formValues.startPeriod,
          'end period': formValues.endPeriod,
          exclusions: formValues.exclusions
        })
      } else {
        const baselineId = await updateBaseLineMutation({ input })
        trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.SAVE_BASELINE, {
          mode: 'EDIT',
          description: formValues.description,
          name: formValues.name,
          'start period': formValues.startPeriod,
          'end period': formValues.endPeriod,
          exclusions: formValues.exclusions
        })
      }
      setReloadEvents(true)
    } catch (error) {
    } finally {
      toggleBaselineModal()
    }
  }

  const submitForm = () => {
    setFormikErrors(formik?.errors)
    formik?.handleSubmit()
  }

  const formik = useFormik({
    initialValues: { ...formvalues },
    onSubmit: createOrUpdateBaseLine,
    validateOnChange: isTriggerValidation,
    validateOnMount:false,
    validationSchema: OverviewSchema({
      initialStartDate: dataAvailablityDates?.startDate,
      initialEndDate: dataAvailablityDates?.endDate
    }),
    enableReinitialize: true
  })

  const toggleBaselineModal = () => {
    setShowBaselineModal(!showBaselineModal)
  }
 
  useEffect(() => {
    if (mode === ACTIONS.ADD) {
      const formFields = {
        name: '',
        accountId: organizationId,
        buildingId: '',
        description: '',
        startPeriod: moment().subtract(1, 'month').locale('en').format(DATE_FORMAT_DASH),
        endPeriod: moment().subtract(1, 'day').locale('en').format(DATE_FORMAT_DASH),
        equipmentId: '',
        exclusions: [],
        target: ''
      }
      setFormvalues(formFields)
    } else {
      const formFields = {
        name: baseLine?.name,
        description: baseLine?.description,
        endPeriod: moment(baseLine?.endPeriod).locale('en').format(DATE_FORMAT_DASH),
        exclusions: getFormattedUTCToString(baseLine?.exclusions || []),
        startPeriod: moment(baseLine?.startPeriod).locale('en').format(DATE_FORMAT_DASH),
        equipmentDetails: {
          equipmentId: baseLine?.equipmentId
        },
        id: baseLine?.id
      }
      setFormvalues(formFields)
    }
  }, [mode])

  const openBaselineModalConfig = {
    gray: true,
    className: 'wide-modal',
    isHideWhiteBackground: true,
    heading:"Create/Edit Baseline",
    handleClose: toggleBaselineModal,
    buttons: [
      {
        text: mode === ACTIONS.ADD ? "CREATE" : "SAVE",
        handleClick: async () => {
          await dispatch(
            newRefreshToken(
              formik?.values?.buildingId && formik.values.buildingId !== ''
                ? [formik.values.buildingId]
                : formik.values?.equipmentDetails?.buildingId
                ? [formik.values.equipmentDetails.buildingId]
                : null,
              formik?.values?.accountId && formik.values.accountId !== ''
                ? [formik?.values?.accountId]
                : null,
              null
            )
          )
          submitForm()
        },
        type: 'save',
        disabled: Object.keys(formik?.errors)?.length > 0 || !formik.values?.equipmentDetails?.equipmentId
      },
      {
        text: t('common:Cancel'),
        handleClick: toggleBaselineModal,
        type: 'cancel'
      }
    ]
  }

  return (
    <Modal testName={testName} {...openBaselineModalConfig}>
      {
        <form>
          <BaselineFormTabs
            formik={formik}
            loading={loadingCreateBaseline || loadingUpdateBaseline}
            mode={mode}
            setIsTriggerValidation={setIsTriggerValidation}
          />
        </form>
      }
    </Modal>
  )
}

export default BaseLineModal
