import React from 'react'
import { useMutation } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/legacy/components/modal/modal'
import { MARK_EQUIPMENT_AS_DELETED } from '../graphql/mutations'
import { trackEvent } from '../../../amplitude'
import { USER_EVENTS } from '../../../amplitude-categories/findings/user-events'
import translate, { TranslateComponent } from '../../../common/translations'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'


const DeleteEquipmentDialog = ({
  equipmentToDelete: { equipmentName, equipmentId },
  toggleDeleteDialog,
  isDenali,
  refetchEquipments
}) => {
  const [t] = useTranslation()

  const { onSubmit: deleteEquipment, loading: deleteEquipmentInProcess } =
    useMutation({
      query: MARK_EQUIPMENT_AS_DELETED
    })

  const onDeleteEquipment = async () => {
    const input = {
      equipmentId: equipmentId
    }
    const getDeleteEquipmentResp = await deleteEquipment({
      body: JSON.stringify(input)
    })
    const parseData = JSON.parse(
      getDeleteEquipmentResp.data.markEquipmentAsDeleted
    )
    if (parseData?.statusCode) {
      const success = parseData?.statusCode === 200
      toggleDeleteDialog(null, success ? null : true)
      trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.DELETE_EQUIPMENT, {"equipment name": equipmentName, "equipment id":equipmentId})
      await refetchEquipments(success, equipmentId)
    } else {
      toggleDeleteDialog(null, true)
    }
  }

  const modalConfig = {
    heading: "Delete Equipment",
    loading: deleteEquipmentInProcess,
    buttons: [
      {
        text: "Yes",
        handleClick: onDeleteEquipment,
        type: 'valid'
      },
      {
        text: "No",
        handleClick: toggleDeleteDialog,
        type: 'cancel'
      }
    ],
    handleClose: toggleDeleteDialog
  }

  if (isDenali) {
    return (
      <DialogConfirm
        onAgree={onDeleteEquipment}
        title={'Delete Equipment'}
        onClose={toggleDeleteDialog}
      >
        <TranslateComponent>Are you sure want to delete </TranslateComponent> {equipmentName}?
      </DialogConfirm>
    )
  }

  return (
    <Modal {...modalConfig}>
      <div className="confirmationText">
        <TranslateComponent>Are you sure want to delete </TranslateComponent> {equipmentName}?
      </div>
    </Modal>
  )
}

export default DeleteEquipmentDialog
