import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ToggleBox from 'src/components/legacy/components/toggle-box/'
import EditableView, {
  DASH_DASH_PLACEHOLDER
} from 'src/components/legacy/components/editable-view/editable-view'
// import { GET_ASSIGNABLE_ORGANIZATIONS } from "graphql/organizations"
// import { GET_USER_ASSIGNED_SALES_OFFICES } from "graphql/salesOffice"
import NewOrganizationDialog from '../../components/newOrganizationDialog'
import Selectrix from 'src/denali-ui/components/Selectrix'
import Option from 'src/denali-ui/components/Option'
import { NewStyles } from 'src/NewStyles'
import { Block } from '../../../../components/layouts'
import translate, { TranslateComponent } from 'src/common/translations'

export class AccountInformation extends Component {
  static propTypes = {
    values: PropTypes.object,
    readOnly: PropTypes.bool,
    setFieldValue: PropTypes.func,
    editAction: PropTypes.string,
    createOrgPermission: PropTypes.bool,
    parentTrackingId: PropTypes.number,
    createAccount: PropTypes.func,
    newAccount: PropTypes.any,
    setValues: PropTypes.func,
    pageName: PropTypes.string,
    searchPlaceHolder : PropTypes.string,
    placeHolder : PropTypes.string,
    officeSearch: PropTypes.string
  }

  static defaultProps = {
    values: {},
    readOnly: false,
    setFieldValue: () => {}
  }

  state = {
    newOrgName: '',
    newOrgModalOpen: false,
    resetOrganizations: false
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.readOnly !== nextProps.readOnly ||
      this.props.setFieldValue !== nextProps.setFieldValue ||
      this.props.editAction !== nextProps.editAction ||
      this.props.values.organizationId !== nextProps.values.organizationId ||
      this.props.values.salesOffice !== nextProps.values.salesOffice ||
      this.props.values.customerCRMSiteId !==
        nextProps.values.customerCRMSiteId ||
      this.state.newOrgName !== nextState.newOrgName ||
      this.state.newOrgModalOpen !== nextState.newOrgModalOpen ||
      this.props.salesOffices !== nextProps.salesOffices ||
      this.props.newAccount !== nextProps.newAccount ||
      this.props.searchPlaceHolder !== nextProps.searchPlaceHolder ||
      this.props.placeHolder !== nextProps.placeHolder ||
      this.props.officeSearch !== nextProps.officeSearch
    ) {
      return true
    }

    return false
  }
  onChangeSelect(setFieldValue, name) {
    return ({ key }) => setFieldValue(name, key)
  }

  onOrgChangeSelect(setValues, values) {
    return (event, child = { props: {} }) => {
      setValues({
        ...values,
        organizationId: event.target.value,
        accountName: child.props.children
      })
    }
  }

  onChangeOffice(setValues, values) {
    return (event, child = { props: {} }) => {
      const salesOffice = child?.props?.salesOffice ?? {}

      setValues({
        ...values,
        salesOffice: {
          officeFullName: salesOffice.name,
          name: salesOffice.name,
          code: salesOffice.code,
          district: salesOffice.district,
          tisTraneOfficeId: salesOffice.id,
          regionId: salesOffice.regionId,
          globalRegionId: salesOffice.globalRegionId,
          region: salesOffice.region
        }
      })
    }
  }

  appendOrg = (newOrgName) =>
    Promise.resolve(this.setState({ newOrgModalOpen: true, newOrgName }))

  getOrgsQueryResponseMap = (values) => (response) => {
    try {
      const idListIndex = response.findIndex((e) => e.name === 'accountIds')
      const nameTermsIndex = response.findIndex(
        (e) => e.name === 'accountTerms'
      )
      const results =
        response &&
        idListIndex !== -1 &&
        nameTermsIndex !== -1 &&
        response[nameTermsIndex].result.buckets.map((e, i) => ({
          searchableValue: e.key,
          value: response[idListIndex].result.buckets[i].key,
          children: e.key,
          selected:
            response[idListIndex].result.buckets[i].key ===
            values.organizationId
        }))

      return results
        ? results?.sort((a, b) => {
            const nameA = a.searchableValue.toUpperCase()
            const nameB = b.searchableValue.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        : []
    } catch (e) {}
  }

  getSalesOfficeQueryResponseMap = (values) => (response) => {
    try {
      return response
        ? response.filter(r => r).map((e, i) => ({
            searchableValue: e.name,
            value: e.id,
            children: `${e?.code}, ${e?.name}, ${e?.district}${e.region?.name  ? (', ' + e.region.name) : '' }`,
            selected: e.id === values?.salesOffice?.tisTraneOfficeId,
            salesOffice: {
              ...e
            }
          }))
        : []
    } catch (e) {
      console.log(e)
    }
  }

  addOrganization = (searchValue = '', searchOptions) => {
    return (
      searchValue &&
      !searchOptions.some(
        (item) => searchValue.trim() === item.searchableValue.trim()
      ) && (
        <Option selected="true" omit={true}>
          <span onClick={() => this.appendOrg(searchValue)}>
            <TranslateComponent>{`Add "${searchValue}" as a new organization`}</TranslateComponent>
          </span>
        </Option>
      )
    )
  }

  afterResetOrgs = () => this.setState({ resetOrganizations: false })

  organizationSelect =
    () =>
    ({ onBlur, onFocus, formik: { values, setValues } }) => {
      const { t, pageName } = this.props
      return (
        <Selectrix
          onChange={this.onOrgChangeSelect(setValues, values)}
          query={this.props.searchAccounts}
          fullWidth={true}
          multiple={false}
          value={values.accountName}
          dataPath={'data.searchBuildings.aggregateItems'}
          containerWidth={true}
          getQueryResponseMap={this.getOrgsQueryResponseMap(values)}
          selectClassName={'orgSelectInput'}
          placeholder={this.props.placeHolder}
          searchPlaceHolder={this.props.searchPlaceHolder}
          testName={`${pageName}_organizationdetails_addorganization`}
          querySearchField={['accountName']}
          aggregates={[
            { field: 'accountName', type: 'terms', name: 'accountTerms' },
            { field: 'accountId', type: 'terms', name: 'accountIds' }
          ]}
          showCutomOption={true}
          customOption={this.addOrganization}
          reset={this.state.resetOrganizations}
          afterReset={this.afterResetOrgs}
          showNoResultsMessage={false}
        />
      )
    }
  salesOfficeSelect =
    () =>
    ({ onBlur, onFocus, formik: { values, setValues } }) => {
      const { t, pageName } = this.props
      return (
        <Selectrix
          onChange={this.onChangeOffice(setValues, values)}
          query={this.props.searchSalesOffices}
          fullWidth={true}
          multiple={false}
          value={values.salesOffice?.code ? `${values.salesOffice.code}, ${values.salesOffice.name}, ${values.salesOffice.district}${values.salesOffice.region?.name ? (', ' + values.salesOffice.region.name) : ''}` : null}
          getQueryResponseMap={this.getSalesOfficeQueryResponseMap(values)}
          dataPath={'data.searchSalesOffices.items'}
          nextTokenPath={'data.searchSalesOffices.nextToken'}
          selectClassName={'officeSelectInput'}
          placeholder={this.props.placeHolder}
          searchPlaceHolder={this.props.officeSearch}
          querySearchField={['name', 'code', 'district']}
          testName={`${pageName}_officedetails_addofficename`}
        />
      )
    }

  toggleCreateModal = () => {
    this.setState({
      newOrgModalOpen: !this.state.newOrgModalOpen
    })
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.newAccount && this.props.newAccount) ||
      (this.props.newAccount &&
        prevProps.newAccount.id !== this.props.newAccount.id)
    ) {
      this.props.setValues({
        ...this.props.values,
        organizationId: this.props.newAccount.id,
        accountName: this.props.newAccount.name
      })
      this.setState({
        newOrgModalOpen: false,
        resetOrganizations: true
      })
    }
  }

  render() {
    const { readOnly, values, pageName, setValues } = this.props
    const { newOrgName } = this.state
    const dialogConfig = {
      className: 'new-organization-dialog',
      toggleCreateModal: this.toggleCreateModal,
      organizationName: newOrgName,
      createAccount: this.props.createAccount,
      responseFunc: (res) => {
        setValues({
          ...values,
          organizationId: res.id,
          accountName: res.name
        })
      }
    }

    return (
      <>
        <NewStyles>
          <ToggleBox
            label="Account Information"
            className="transparent"
            opened={true}
            showOnlyLabel={true}
          >
            <div className="account-information">
              <Block padding="0 0 16px 0">
                <EditableView
                  labelName="Organization/Customer Name"
                  className="organization-select required"
                  readOnly={readOnly}
                  name="organizationId"
                  component={this.organizationSelect()}
                />
              </Block>
              <Block padding="0 0 16px 0">
                <EditableView
                  labelName="Office Name"
                  className="office-select required"
                  readOnly={readOnly}
                  name="salesOffice.tisTraneOfficeId"
                  component={this.salesOfficeSelect(this.props.salesOffices)}
                />
              </Block>
              <Block>
                <EditableView
                  type={EditableView.COMPONENT_TYPE.INPUT}
                  className="customer-crm-site-id number"
                  labelName="Customer CRM Site ID"
                  readOnly={readOnly}
                  value={
                    readOnly
                      ? values.customerCRMSiteId??DASH_DASH_PLACEHOLDER
                      : values.customerCRMSiteId
                  }
                  name="customerCRMSiteId"
                  testName={`${pageName}_crmsiteid_addid`}
                />
              </Block>
            </div>
          </ToggleBox>
        </NewStyles>
        {this.state.newOrgModalOpen && (
          <NewOrganizationDialog {...dialogConfig} />
        )}
      </>
    )
  }
}

export default withTranslation()(AccountInformation)
