import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from 'src/denali-ui/components/Icon/index'
import colors from 'src/components/legacy/common/colors.json'
import {
  ListBuildingDevices,
  GET_AUTO_EQUIPMENT_LIST,
  GET_TIS_EQUIPMENT_LIST,
  GET_BUILDING_EQUIPMENT_TYPES,
  GET_NIAGARA_EQUIPMENT_LIST_BY_BUILDING_ID
} from '../graphql/queries'
import {
  getEquipmentsTreeData,
  getAutomationSystemListData,
  getEquipmentsList,
  getDataFromNestedArray,
  sanitaizeDropDownData,
  orderWithUniq
} from './helper'
import { TableWrapper, NoLocation, SerialWarningComponent } from './style'
import { TIS_EQUIPMENT_LIST, DATA_SOURCE_NAME, NAME } from '../constants'
import TableNew from 'src/components/Table/clientSideTable'
import DeleteEquipmentDialog from '../DeleteEquipmentDialogue/DeleteEquipmentDialog.jsx'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import _uniq from 'lodash/uniqBy'
import _orderBy from 'lodash/orderBy'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getSearchParams } from '../../../common/helperFunctions.js'
import translate, { TranslateComponent } from 'src/common/translations'
import { accessControlFunc } from 'src/components/accessControl'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import WarningsDialog from 'src/components/legacy/components/error-dialog/error-dialog'
import { TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useAppDispatch } from 'src/redux/store'
import { RootState } from 'src/redux/RootState'
import { GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS } from '../graphql/queries'

import { EquipmentSetupTabbedList } from 'src/denali-pages/EquipmentSetup/EquipmentSetupTabbedList'
import { Skeleton } from 'src/denali-components/Table/Skeleton'
import tableStyles from 'src/denali-components/Table/table.module.scss'

const EquipmentSetupList = (props: any) => {
  const optionsTranslated = {
    equipmentName: translate('Equipment Name'),
    equipmentType: translate('Equipment Type'),
    automation: translate('Automation System'),
    dataName: translate('Data Source Name'),
    dataType: translate('Data Source Type'),
    controller: translate('Controller Type'),
    serialNumber: translate('Serial Number'),
    modelNumber: translate('Model Number'),
    delete: translate('Delete'),
    standAlone: translate('Standalone'),
    Device: translate('Device'),
    objectName: translate('Object Name'),
    automationSystem: translate('Automation System'),
    objectType: translate('Object Type'),
    controllerType: translate('Controller Type'),
    defaultCount: translate('Default Count'),
    allAutomation: translate('All Automation System'),
    allObject: translate('All Object Type'),
  }
  const [t] = useTranslation()
  const {
    equipmentListType,
    setLoadtime,
    isRefreshEquipmentList,
    getEquipments,
    setListType,
    isDenali
  } = props

  const userAccess = useSelector(selectUserAccess)

  const [tisListData, settisListData] = useState([])
  const [allAutoListData, setAllAutoListData] = useState([]) 
  const [deviceList, setDeviceList] = useState([])
  const [activeColumn, setActiveColumn] = useState(0)
  const navigate = useNavigate()
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const [equipmentToDelete, setEquipmentToDelete] = useState(null)
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false)
  const [error, setError] = useState(null)
  const [eqType, setEqType] = useState({ buildingId: null, data: [] })
  const [autoSystem, setAutoSystem] = useState([])
  const [objType, setObjType] = useState([])
  const [niagaraEquipmentData, setNiagaraEquipmentData] = useState([])
  const appDispatch = useAppDispatch()
  const { translatedJSON } = useSelector(
    (state: RootState) => state.translationCombinedSlice
  )
  const [eqListForDataSourceTypes, setEqListForDataSourceTypes] = useState([])
  const [translatedEquipTypes, setTranslatedEquipTypes] = useState([])
  const [equipmentData, setEquipmentData] = useState([])
  const [translatedObjTypes, settranslatedObjTypes] = useState([])
  const [isFilterChange, setFilterChange] = useState(false)

  const [currentBuildingID, setCurrentBuildingID] = useState(null)
  const [equipmentAutoData, setEquipmentAutoData] = useState([])
  const [equipmentTISData, setEquipmentTISData] = useState([])
  const [loadingTISData, setLoadingTISData] = useState(false)
  const [loadingAutoData, setLoadingAutoData] = useState(false)
  const [loadingNiagaraData, setLoadingNiagaraData] = useState(false)

  const [isNAX, setIsNAX] = useState(false)

  const [selectedAutomation, setSelectedAutomation] = useState({
    isWarningDialogOpen: false,
    warningHeader: '',
    warningMessage: ''
  })

  const { data: responseAutoEquipmentData, refetch: refetchAutoEquipmentsData, responseTime : autoResponseTime } =
    useQuery({
      query: GET_AUTO_EQUIPMENT_LIST,
      dataPath: 'data.searchEquipment',
      disableInitialLoad: true,
      variables: { id: buildingId },
      errorPolicy: 'all'
    })
  const { data: responseTISEquipmentData, refetch: refetchTISEquipmentsData,responseTime : tisResponseTime } =
    useQuery({
      query: GET_TIS_EQUIPMENT_LIST,
      dataPath: 'data.searchEquipment',
      disableInitialLoad: true,
      variables: { id: buildingId },
      errorPolicy: 'all'
  })

  const {
    data: responseData,
    refetch: refetchBuildingDevices,
    responseTime
  } = useQuery({
    query: ListBuildingDevices,
    dataPath: 'data.getBuilding',
    disableInitialLoad: true,
    variables: { id: buildingId }
  })

  const {
    data: equipmentTypes,
    refetch: refetchEquipmentTypes,
    loading: loadingEquipmentTypes
  } = useQuery({
    query: GET_BUILDING_EQUIPMENT_TYPES,
    disableInitialLoad: true,
    variables: { requestBody: JSON.stringify([buildingId]) },
    dataPath: 'data.getBuildingEquipmentTypes'
  })

  const {
    data: responseNiagaraEquipment,
    refetch: refetchNiagaraEquipmentList
  } = useQuery({
    query: GET_NIAGARA_EQUIPMENT_LIST_BY_BUILDING_ID,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment',
    disableInitialLoad: true,
    variables: { buildingId: buildingId }
  })

  const { data: buildingData, refetch:getBuildingData } = useQuery({
    query: GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS,
    variables: { buildingId: buildingId },
    errorPolicy: 'all',
    dataPath: 'data.getBuilding.devices.items',
    disableInitialLoad: false
  })

  useEffect(() => {
    if (buildingId && isRefreshEquipmentList) {
      setEquipmentData([])
      setEquipmentTISData([])
      refetchTISEquipmentsData()
      refetchBuildingDevices()
      isNAX && refetchNiagaraEquipmentList()
    }
  }, [isRefreshEquipmentList])

  //New UseEffect Improvement
  useEffect(() => {
    if (currentBuildingID !== buildingId) {
      // resets all data
      setCurrentBuildingID(buildingId)
      setIsNAX(false)
      getBuildingData()
      setEquipmentAutoData([])
      setEquipmentTISData([])
    }
    if(buildingData)
      {
      if(!equipmentTISData.length && !loadingTISData) //Only refetch if no data
      {
        setLoadingTISData(true)
        refetchTISEquipmentsData()
      }
      else {
          setIsNAX(buildingData?.[0]?.type=="NAX")
          if(buildingData?.[0]?.type=="NAX")  //isNax run Auto Nax
          {
            if(!niagaraEquipmentData.length && !loadingNiagaraData)
            {
              setLoadingNiagaraData(true)
              refetchNiagaraEquipmentList()
            }
          }
          else //Non Nax device
          {
            if(!equipmentAutoData.length && !loadingAutoData)
            {
              setLoadingAutoData(true)
              refetchAutoEquipmentsData()
            }
          }
        }
      }
      else //Non Nax device
      {
        if(!equipmentAutoData.length && !loadingAutoData)
        {
          setLoadingAutoData(true)
          refetchAutoEquipmentsData()
        }
      }
    }
    // eslint-disable-next-line
  , [buildingId, buildingData, isNAX])
  
  useEffect(() => {
    try {
      setLoadingAutoData(true)
      if (responseAutoEquipmentData && responseAutoEquipmentData?.items.length) {
        setEquipmentAutoData([...equipmentAutoData, ...responseAutoEquipmentData?.items])
      }
      if (responseAutoEquipmentData && responseAutoEquipmentData.nextToken !== null) {
        refetchAutoEquipmentsData({
          id: buildingId,
          nextToken: responseAutoEquipmentData?.nextToken
        })
        setLoadingAutoData(false)
      } else {
        setLoadingAutoData(false)
        setAllAutoListData(equipmentAutoData)
      }
    } catch (error) {
      console.log(error)
      setLoadingAutoData(false)
    }
  },[responseAutoEquipmentData])

  useEffect(() => {
    try {
      setLoadingTISData(true)
      if (responseTISEquipmentData && responseTISEquipmentData?.items.length) {
        const dataWithSerialNumber = formatEquipmentListWithSerialNumber(
          responseTISEquipmentData?.items || []
        )
        setEquipmentTISData([...equipmentTISData, ...dataWithSerialNumber])
      }
      if (responseTISEquipmentData && responseTISEquipmentData.nextToken !== null) {
        refetchTISEquipmentsData({
          id: buildingId,
          nextToken: responseTISEquipmentData?.nextToken
        })
        setLoadingTISData(false)
      } else {
        setLoadingTISData(false)
        settisListData(equipmentTISData)
      }
    } catch (error) {
      console.log(error)
      setLoadingTISData(false)
    }
  },[responseTISEquipmentData])

  useEffect(() => {
    try {
      setLoadingNiagaraData(true)
      if (responseNiagaraEquipment && responseNiagaraEquipment?.items.length) {
        setNiagaraEquipmentData([
          ...niagaraEquipmentData,
          ...responseNiagaraEquipment?.items
        ])
      }
      if (
        isNAX &&
        responseNiagaraEquipment &&
        responseNiagaraEquipment.nextToken !== null
      ) {
        refetchNiagaraEquipmentList({
          buildingId: buildingId,
          nextToken: responseNiagaraEquipment?.nextToken
        })
        setLoadingNiagaraData(false)
      }
      else{
        setLoadingNiagaraData(false)
      }
    } catch (error) {
      console.log(error)
      setLoadingNiagaraData(false)
    }
  }, [responseNiagaraEquipment])

  useEffect(() => {
    if (buildingId) {
      // resets all data
      setEquipmentData([])
      setActiveColumn(0)
      setEquipmentToDelete(null)
      setEqType({ buildingId: null, data: [] })
      setError(null)
      refetchBuildingDevices()
      refetchEquipmentTypes()
    }
    // eslint-disable-next-line
  }, [buildingId])

  // add unit serial number and model number for GCPM
  const formatEquipmentListWithSerialNumber = (equipmentData) => {
    return equipmentData?.map((x) => {
      const equipmentProps = x?.equipmentProperties?.items || []
      if (equipmentProps?.length > 0) {
        if (equipmentProps[0]?.equipmentId === x?.id) {
          const unitSerialNumber =
            equipmentProps?.find((x) => x?.targetKey === 'UnitSerialNumber')
              ?.value || ''
          const unitModelNumber =
            equipmentProps?.find((x) => x?.targetKey === 'UnitModelNumber')
              ?.value || ''
          return {
            ...x,
            unitSerialNumber,
            unitModelNumber
          }
        } else {
          return { ...x }
        }
      } else {
        return { ...x }
      }
    })
  }

  useEffect(() => {
    setFilterChange(true)
  }, [translatedObjTypes?.length])

  useEffect(() => {
    if (!loadingEquipmentTypes && equipmentTypes) {
      const res = JSON.parse(equipmentTypes)
      if (res && res.statusCode === 200) {
        const equipmentTypes = Object.keys(res.body?.EquipmentType)
        setEqType({ buildingId, data: sanitaizeDropDownData(equipmentTypes) })
        setTranslatedEquipTypes(sanitaizeDropDownData(equipmentTypes))
      } else {
        setEqType({ buildingId, data: [] })
      }
    }
  }, [equipmentTypes, loadingEquipmentTypes])
  
  useEffect(() => {
    if (responseData && responseTISEquipmentData && !loadingTISData){
      const tisData = equipmentTISData

      const deviceData = responseData?.devices?.items
      const automationList = tisData
      const automationRowData = getAutomationSystemListData(automationList)
      setLoadtime(responseTime)
      setDeviceList(deviceData)
      settisListData(tisData)
      setEqListForDataSourceTypes(tisData)
      getEquipments(tisData)
      if (tisData) {
        // set automation system- object type dropdown data
        const objectsTypes = getDataFromNestedArray(automationRowData)
          .filter((it) => it?.dataSourceType)
          ?.map((it) => it.dataSourceType)
        const objTyp = orderWithUniq(objectsTypes)
        setObjType(sanitaizeDropDownData(objTyp))
      }

      if (deviceData) {
        // set automation system dropdown data
        const automationSystems = deviceData
          .filter((item) => tisData?.find((ele) => ele?.deviceId == item?.id))
          ?.map((it) => it?.name)
        const systemAuto = orderWithUniq(automationSystems)
        setAutoSystem(sanitaizeDropDownData(systemAuto))
      }
    }
    // eslint-disable-next-line
  }, [responseData, responseTISEquipmentData])

  const onCloseDialog = () => () => {
    setError({ error: null })
  }
  const toggleDeleteDialog = (equipmentToDelete = null, isError = null) => {
    setIsDeleteDialogShown(!isDeleteDialogShown)
    setEquipmentToDelete(equipmentToDelete)
    isError &&
      setError({
        title: t('errors:Error'),
        text: t('errors:unexpected-error')
      })
  }

  const onClickEquipment = useCallback(
    (equipmentId: string) => {
      navigate(
        `/equipment-setup/details?location=${buildingId}&equipment=${equipmentId}`
      )
    },
    [navigate, buildingId]
  )

  const getHyperLinkNameNew = useCallback(
    (row) => {
      return (
        !row.name?.includes('Standalone') && row?.id && onClickEquipment(row.id)
      )
    },
    [onClickEquipment]
  )

  const handleNameClick = () => {
    setSelectedAutomation({
      isWarningDialogOpen: true,
      warningHeader: 'Warning',
      warningMessage: t(
        'equipment-setup:DataMappingIsNotAvailableForSiteObject'
      )
    })
  }

  const closeWarnigDialog = () => {
    setSelectedAutomation({
      isWarningDialogOpen: false,
      warningHeader: '',
      warningMessage: ''
    })
  }

  const tisListColumnNew = [
    {
      title: optionsTranslated.equipmentName,
      key: 'name',
      name: 'name',
      onDataClick: (row) => getHyperLinkNameNew(row)
    },
    { title: optionsTranslated.equipmentType, key: 'familyUI', name: 'family' },
    {
      title: optionsTranslated.serialNumber,
      key: 'unitSerialNumber',
      name: 'unitSerialNumber',
      customComponent: function (props) {
        return (
          <WarningComponent
            family={props?.family}
            number={props?.unitSerialNumber}
          />
        )
      }
    },
    {
      title: optionsTranslated.modelNumber,
      key: 'unitModelNumber',
      name: 'unitModelNumber',
      customComponent: function (props) {
        return (
          <WarningComponent
            family={props?.family}
            number={props?.unitModelNumber}
          />
        )
      }
    },
    {
      title: optionsTranslated.automation,
      key: 'automationSystem',
      name: 'automationSystem'
    },
    {
      title: optionsTranslated.dataName,
      key: 'dataSourceName',
      name: 'dataSourceName'
    },
    {
      title: optionsTranslated.dataType,
      key: 'dataSourceType',
      name: 'dataSourceType'
    },
    {
      title: optionsTranslated.controller,
      key: 'controllerType',
      name: 'controllerType'
    }
  ]

  const automationColumnNew = [
    {
      title: translate('Object Name'),
      key: 'dataSourceName',
      name: 'dataSourceName',
      onDataClick: () => {
        handleNameClick()
      }
    },
    {
      title: translate('Automation System'),
      key: 'automationSystem',
      name: 'automationSystem'
    },
    {
      title: translate('Object Type'),
      key: 'dataSourceTypeUI',
      name: 'dataSourceType'
    },
    {
      title: translate('Controller Type'),
      key: 'controllerType',
      name: 'controllerType'
    },
    {
      title: translate('Default Count'),
      key: 'defaultCount',
      name: 'defaultCount'
    }
  ]

  const unstructureSubLists = (data) => {
    return data?.reduce((accum, curr) => {
      if (!curr?.subData?.length) {
        return [...accum, curr]
      } else {
        const currWithoutSubData = { ...curr }
        delete currWithoutSubData['subData']
        return [
          ...accum,
          currWithoutSubData,
          ...unstructureSubLists(curr.subData)
        ]
      }
    }, [])
  }
  // Call the function to extract subdata recursively

  const extractSubdata = (data, b, result = [], _index) => {
    for (const item of data) {
      const { subData, ...rest } = item
      result.push(rest)
      if (subData) {
        extractSubdata(subData, b, result, _index)
      }
    }

    const filteredData = result.filter((ele) => {
      let flag = -1
      for (let i = 0; i <= _index; i++) {
        if (Array.isArray(result[i].selectedValue)) {
          if (result[i].selectedValue?.includes(ele[`${result[i].name}`])) {
            flag++
          }
        } else {
          if (ele[`${result[i].name}`] == result[i].selectedValue) {
            flag++
          }
        }
      }
      return flag == _index
    })
    return _uniq(filteredData,'id')
  }

  const getSearchValue = (obj, field) => {
    const arrayOfKeys = field?.split('.')
    return arrayOfKeys?.reduce((accum, curr) => {
      accum = accum?.[curr]
      return accum
    }, obj)
  }

  const searchAction = (searchRows, searchText, searchFields, result = []) => {
    searchRows?.forEach((item) => {
      const { subData, ...rest } = item
      result.push(rest)
      if (subData) {
        searchAction(subData, searchText, searchFields, result)
      }
    })
    return result.reduce((acc, val) => {
      const exists = searchFields?.some((f) =>
        getSearchValue(val, f)
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase())
      )
      if (exists && !acc.some((accVal) => accVal.id === val.id)) {
        acc.push(val)
        return acc
      } else {
        return acc
      }
    }, [])
  }

  const WarningComponent = ({ number, family }) => {
    return number ? (
      <p>
        <strong>{number}</strong>
      </p>
    ) : family === 'Chiller' ? (
      <SerialWarningComponent>
        <Icon
          name="Warning"
          width="15px"
          height="15px"
          margin="4px 4px 0px 0px"
          color={colors.RED}
        />
        <span style={{ marginTop: 5 }}>Undefined</span>
      </SerialWarningComponent>
    ) : null
  }

  const rowData = useMemo(() => {
    const equipmentsList = getEquipmentsList(tisListData, deviceList)
    const automationList = getEquipmentsList(allAutoListData, deviceList)
    if (equipmentListType === TIS_EQUIPMENT_LIST) {
      if (activeColumn === 0) {
        const equipmentsListScreened = equipmentsList?.map((obj) => {
          if (obj?.parentId?.includes(obj?.id)) {
            return {
              ...obj,
              parentId: obj?.parentId
                ?.split('#')
                ?.filter((i) => i !== obj?.id)
                ?.join('#')
            }
          } else {
            return obj
          }
        })
        const equipTreeData = getEquipmentsTreeData(
          equipmentsListScreened,
          optionsTranslated.standAlone,
          translatedJSON
        )
        return equipTreeData
      } else {
        return equipmentsList
      }
    } else if (isNAX) {
      const tableRowData: { dataSourceName: string; subData: JSON[] }[] = [
        { dataSourceName: 'Standalone Device', subData: [] }
      ]
      const subData = []
      const tisEquipmentList = equipmentTISData
      niagaraEquipmentData.forEach((point) => {
        let comps = point.name.split('/')
        const compEnd = comps.indexOf('points')
        if (compEnd > -1) {
          comps = comps.slice(0, compEnd)
        } else {
          comps = comps.slice(0, comps.length - 1)
        }
        const truncatedName = comps.join('/')
        if(truncatedName.length){
        const newChild = {
          dataSourceName: truncatedName,
          id: point.id,
          automationSystem: truncatedName,
          dataSourceType: 'Device',
          dataSourceTypeUI: optionsTranslated.Device, 
          controllerType: point.controllerType ?? 'None',
          defaultCount: tisEquipmentList?.filter(
            (equipment) => equipment.dataSourceName === truncatedName
          )?.length
        }
        subData.push(newChild)}
      })
      tableRowData[0].subData = _uniq(subData, 'dataSourceName')
      return tableRowData
    } else {
      const automationRowData = getAutomationSystemListData(automationList)
      return activeColumn === 0
        ? automationRowData
        : automationRowData?.reduce((accum, curr) => {
            if (!curr?.subData?.length) {
              return [...accum, curr]
            } else {
              const currWithoutSubData = { ...curr }
              if (curr.subData) {
                delete currWithoutSubData['subData']
                return [...accum, ...unstructureSubLists(curr.subData)]
              } else {
                return [...accum, currWithoutSubData]
              }
            }
          }, [])
    }
  }, [
    tisListData,
    allAutoListData,
    equipmentListType,
    activeColumn,
    niagaraEquipmentData,
    isNAX
  ])

  useEffect(() => {
    if(eqListForDataSourceTypes && eqType?.data && objType){
      const allTypesToTranslate = [
        ...eqListForDataSourceTypes,
        ...eqType.data,
        ...objType,
        ...allAutoListData
      ].reduce((acc, item) => {
        if (item.dataSourceType && !acc.hasOwnProperty(item.dataSourceType)) {
          acc[item.dataSourceType] = item.dataSourceType
        }
        if (item.family && !acc.hasOwnProperty(item.family)) {
          acc[item.family] = item.family
        }
        if (item.name && item.value && !acc.hasOwnProperty(item.name)) {
          acc[item.name] = item.value
        }
        return acc
      }, {})

      if (Object.keys(allTypesToTranslate).length !== 0)
        appDispatch(translateJSON({ json: allTypesToTranslate }) as any)
    }
  }, [eqListForDataSourceTypes, eqType?.data, objType])

  useEffect(() => {
    if (translatedJSON) {
      //for this new API call setState
      const translatedequipmentTisList = equipmentTISData?.map((eq) => {
        return {
          ...eq,
          familyUI: translatedJSON[eq?.family]
        }
      })
      setEquipmentTISData(translatedequipmentTisList)

      const translatedTisList = tisListData?.map((eq) => {
        const translatedEquipmentTypes = translatedJSON
        return {
          ...eq,
          familyUI: translatedEquipmentTypes[eq?.family]
        }
      })
      settisListData(translatedTisList)

      const translatedAllTisList = allAutoListData?.map((eq) => {
        const translatedObjTypes = translatedJSON
        return {
          ...eq,
          dataSourceTypeUI: translatedObjTypes[eq?.dataSourceType]
        }
      })
      setAllAutoListData(translatedAllTisList)

      const translatedEqTypes = eqType?.data.reduce((acc, item) => {
        const translatedValue = translatedJSON[item.name]
        if (translatedValue) {
          acc.push({ name: translatedValue, value: item.name })
        }
        return acc
      }, [])
      setTranslatedEquipTypes(translatedEqTypes)

      const translatedObjTypes = objType.reduce((acc, item) => {
        const translatedValue = translatedJSON[item.name]
        if (translatedValue) {
          acc.push({ name: translatedValue, value: item.name })
        }
        return acc
      }, [])
      settranslatedObjTypes(translatedObjTypes)
    }
  }, [translatedJSON])

  const automationSystemDropDown = {
    filterName: 'typeFilter',
    id: 'automationSelectorFilter',
    defaultLabel: optionsTranslated.allAutomation,
    filterLabel: optionsTranslated.allAutomation,
    selectedValue: 'default',
    options: autoSystem,
    name: 'automationSystem',
    action: (a, b, c, d) =>
      extractSubdata(rowData?.length > 0 ? rowData : a, b, c, d)
  }

  const selectedFieldFilter =
    equipmentListType === TIS_EQUIPMENT_LIST
      ? [
          {
            filterName: 'typeFilterOne',
            id: 'typeSelectorFilter',
            defaultLabel: optionsTranslated.allObject,
            filterLabel: optionsTranslated.allObject,
            includeSearch: true,
            selectedValue: 'default',
            options: translatedEquipTypes,
            name: 'family',
            action: (a, b, c, d) =>
              extractSubdata(rowData?.length > 0 ? rowData : a, b, c, d)
          },
          automationSystemDropDown
        ]
      : [
          {
            filterName: 'typeFilter',
            id: 'automationSystemFilter',
            defaultLabel: 'All Object Type',
            filterLabel: 'All Object Type',
            includeSearch: true,
            selectedValue: 'default',
            options: translatedObjTypes,
            name: 'dataSourceType',
            action: (a, b, c, d) =>
              extractSubdata(rowData?.length > 0 ? rowData : a, b, c, d)
          },
          automationSystemDropDown
        ]

  const listDataIsLoading =
    loadingEquipmentTypes ||
    eqType.buildingId === null ||
    loadingAutoData ||
    loadingTISData || 
    loadingNiagaraData

  if (isDenali && listDataIsLoading) {
    return (
      <table width="100%">
        <tbody>
          <Skeleton isClassic />
        </tbody>
      </table>
    )
  }

  if (isDenali && !listDataIsLoading) {
    return (
      <EquipmentSetupTabbedList
        setListType={setListType}
        setActiveColumn={setActiveColumn}
        rowData={rowData}
        rowActionFunc={(row) => {getHyperLinkNameNew(row)}}
        activeColumn={activeColumn}
        selectedFieldFilter={selectedFieldFilter}
        isFilterChange={isFilterChange}
        searchAction={searchAction}
        listDataIsLoading={listDataIsLoading}
        optionsTranslated={optionsTranslated}
        toggleDeleteDialog={toggleDeleteDialog}
        userAccess={userAccess}
        // Delete Equipment Dialog Props
        isDenali={isDenali}
        isDeleteDialogShown={isDeleteDialogShown}
        equipmentToDelete={equipmentToDelete}
        setCurrentBuildingID={setCurrentBuildingID}
        setEquipmentData={setEquipmentData}
        setEquipmentTISData={setEquipmentTISData}
        settisListData={settisListData}
        getEquipments={getEquipments}
        equipmentListType={equipmentListType}
        setEquipmentAutoData={setEquipmentAutoData}
        setAllAutoListData={setAllAutoListData}
      />
    )
  }

  if (!buildingId) {
    return (
      <NoLocation>
        <h3>
          <TranslateComponent>
            Select a building above to view equipment
          </TranslateComponent>
        </h3>
      </NoLocation>
    )
  }

  if (!isDenali && listDataIsLoading) {
    return <Spinner />
  }

  return (
    <>
      <TableWrapper>
        <TableNew
          testName={TEXT_NAME_PREFIX()}
          onUpdateControlsFunc={(controls) =>
            setActiveColumn(controls.activeColumn || 0)
          }
          rows={rowData}
          defaultSortColumn={activeColumn}
          header={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? tisListColumnNew
              : automationColumnNew
          }
          search={true}
          searchFields={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? [NAME]
              : [DATA_SOURCE_NAME]
          }
          filtersList={selectedFieldFilter}
          isFilterChange={isFilterChange}
          searchAction={searchAction}
          showSpinner={loadingTISData}
          loadTime={equipmentListType === TIS_EQUIPMENT_LIST ? tisResponseTime : autoResponseTime}
          rowControl={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? (row) => {
                  const isStandalone = row?.equipmentType === 'StandAlone'
                  const isCreattionTypeUser = row?.creationType === 'User'
                  const isShowRowControl =
                    !isStandalone &&
                    isCreattionTypeUser &&
                    accessControlFunc({
                      id: 'tc.pages.equipment-setup.delete',
                      userAccess
                    })
                  if (isShowRowControl) {
                    return [
                      {
                        text: optionsTranslated.delete,
                        action: (data: any) => {
                          if (data?.id)
                            toggleDeleteDialog(
                              {
                                equipmentName: data.name,
                                equipmentId: data.id
                              },
                              null
                            )
                        }
                      }
                    ]
                  } else {
                    return []
                  }
                }
              : undefined
          }
          key={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? 'equipment-list'
              : 'automation-list'
          }
          fixedLayout={false}
        />
      </TableWrapper>
      {isDeleteDialogShown && (
        <DeleteEquipmentDialog
          equipmentToDelete={equipmentToDelete}
          toggleDeleteDialog={toggleDeleteDialog}
          refetchEquipments={(status, equipmentId) => {
            if (status) {
              setCurrentBuildingID(null)
              setEquipmentData((equipmentData) => 
              equipmentData.filter((data) => data.id !== equipmentId))
              if(equipmentListType === "TISEquipmentList")
              {
                setEquipmentTISData((equipmentTISData) =>
                  equipmentTISData.filter((data) => data.id !== equipmentId)
                )
                settisListData((equipmentTISData) =>
                  equipmentTISData.filter((data) => data.id !== equipmentId)
                )
                getEquipments((equipmentTISData) =>
                  equipmentTISData.filter((data) => data.id !== equipmentId)
                )
              }
              else {
                setEquipmentAutoData((equipmentAutoData) =>
                  equipmentAutoData.filter((data) => data.id !== equipmentId)
                )
                setAllAutoListData((equipmentAutoData) =>
                  equipmentAutoData.filter((data) => data.id !== equipmentId)
                )
              }
            } else {
              setEquipmentData([])
              setCurrentBuildingID(null)
            }
          }}
        />
      )}
      {selectedAutomation?.isWarningDialogOpen && (
        <WarningsDialog
          title={selectedAutomation?.warningHeader}
          handleClose={closeWarnigDialog}
          text={selectedAutomation?.warningMessage}
        />
      )}
      <StandardDialogs error={error} onCloseDialog={onCloseDialog} />
    </>
  )
}

export default EquipmentSetupList
