import { Button } from '@aws-amplify/ui-react'
import { ReactNode } from 'react'
import { TranslateComponent } from 'src/common/translations'
import styles from './table.module.scss'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NoTableData = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.noTableDataRoot} data-testid="noTableDataRoot">
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className={styles.noTableDataIcon}
      />
      {children}
    </div>
  )
}

export const NoTableDataTitle = ({ children }: { children?: ReactNode }) => {
  return (
    <div className={styles.noTableDataTitle}>
      <TranslateComponent>{children}</TranslateComponent>
    </div>
  )
}

export const NoTableDataAction = ({
  children,
  isFilterActive,
  clearFilter
}: {
  children?: ReactNode
  isFilterActive: boolean
  clearFilter: () => void
}) => {
  if (isFilterActive) {
    return (
      <Button data-testid="noTableDataClearFiltersButton" onClick={clearFilter}>
        <TranslateComponent>Clear Filters</TranslateComponent>
      </Button>
    )
  }

  return children || null
}
