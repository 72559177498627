import { PropsWithChildren } from 'react'
import styles from './table.module.scss'

export const Chips = ({
  size = '',
  children,
  className
}: PropsWithChildren<ChipsProps>) => {
  return (
    <div className={`${styles.chips} ${className}`} data-size={size}>
      {children}
    </div>
  )
}

type ChipsProps = {
  size?: string
  className?: string
}
