import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Denali imports
import {
  faArrowUp,
  faZap,
  faDollar,
  faLeaf,
  faAtomSimple
} from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  CardDetails,
  CardDetailsFormatPrimary
} from 'src/denali-components/Cards/CardDetails/CardDetails'
import styles from 'src/denali-components/Page/detailPage.module.scss'

// Local imports
import { formatPayback, formatROI } from '../helpers'

interface DetailCostSavingsProps {
  costSavings: {
    enabled: boolean
    value?: number
    tag?: string
  }
  costToImplement: {
    enabled: boolean
    value?: number
    priceType?: string
  }
  simpleROI?: {
    enabled: boolean
    value?: number
    priceType?: string
  }
  simplePayback?: {
    enabled: boolean
    value?: number
  }
  potentialSavings?: {
    value: number
    date?: string
  }
}

const displayCostSavings = (obj) => Boolean(obj && obj.enabled && obj.value)

export const DetailCostSavings = ({
  costSavings,
  costToImplement,
  simpleROI,
  simplePayback,
  // showPotentialSavings,
  potentialSavings
}: DetailCostSavingsProps) => {
  return (
    <div className={styles.detailRow}>
      {displayCostSavings(costSavings) && (
        <CardDetails
          style="energy-savings"
          title="Energy Savings"
          icon={<FontAwesomeIcon icon={faZap} />}
          primary={costSavings?.value}
          primaryFormat={CardDetailsFormatPrimary.USD}
          // chip='38.70%' // TODO: This is a placeholder
          chipIcon={faArrowUp}
          disclaimer={formatTag(costSavings?.tag)}
        />
      )}
      {displayCostSavings(costToImplement) && (
        <CardDetails
          title="Price"
          icon={<FontAwesomeIcon icon={faDollar} />}
          iconColor="blue"
          primary={costToImplement?.value}
          primaryFormat={CardDetailsFormatPrimary.USD}
          disclaimer={costToImplement?.priceType}
        />
      )}
      {displayCostSavings(simpleROI) && (
        <CardDetails
          title="Simple ROI"
          icon={<FontAwesomeIcon icon={faLeaf} />}
          iconColor="green"
          primary={formatROI(simpleROI?.value)}
          // TODO: Double-check where does this value come from?
          disclaimer={costToImplement?.priceType}
        />
      )}
      {/* No idea if this is right */}
      {displayCostSavings(simplePayback) && (
        <CardDetails
          title="Simple Payback"
          icon={<FontAwesomeIcon icon={faLeaf} />}
          iconColor="green"
          primary={formatPayback(simplePayback?.value)}
          primaryFormat={CardDetailsFormatPrimary.YEAR}
          // TODO: Double-check where does this value come from?
          disclaimer={costToImplement?.priceType}
        />
      )}
      {/* potential savings has no enabled property */}
      {potentialSavings !== null && (
        <CardDetails
          title="Potential Savings"
          icon={<FontAwesomeIcon icon={faAtomSimple} />}
          iconColor="orange"
          primary={potentialSavings?.value}
          primaryFormat={CardDetailsFormatPrimary.USD}
          disclaimer={`If implemented on ${potentialSavings?.date}`}
        />
      )}
    </div>
  )
}

const formatTag = (tag) => {
  switch (tag) {
    case 'weeks':
      return 'Per Week'
    case 'months':
      return 'Per Month'
    case 'years':
      return 'Per Year'
    case 'total':
      return 'Total'
    default:
      return 'Per Month'
  }
}
