import { Button } from '@aws-amplify/ui-react'
import { Dialog } from './Dialog'
import translate from 'src/common/translations'
import { ReactNode } from 'react'

type DialogConfirm = {
  isOpen?: boolean
  onClose?: () => void
  title?: string
  children: ReactNode
  onAgree?: () => void
  onAgreeTitle?: string
  onCloseTitle?: string
}

export const DialogConfirm = ({
  isOpen = true,
  onClose,
  title,
  children,
  onAgree,
  onAgreeTitle = '',
  onCloseTitle = ''
}: DialogConfirm) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      actions={
        <ConfirmActions
          onAgree={onAgree}
          onClose={onClose}
          onAgreeTitle={onAgreeTitle}
          onCloseTitle={onCloseTitle}
        />
      }
    >
      {children}
    </Dialog>
  )
}

const ConfirmActions = ({ onAgree, onClose, onAgreeTitle, onCloseTitle }) => (
  <>
    <Button onClick={onClose} variation="link" data-testid="dialogConfirmNo">
      {onCloseTitle || translate('No')}
    </Button>
    <Button onClick={onAgree} data-testid="dialogConfirmYes">
      {onAgreeTitle || translate('Yes')}
    </Button>
  </>
)
