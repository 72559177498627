import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useBreakPoint } from 'src/hooks/breakPoint'
import {
  DataPickerWrapper,
  TextSection,
  InfoText,
  BaslineWrapper
} from './styles'
import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { MAX_NAME_LENGTH, MAX_DESC_LENGTH } from './Validations'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import moment from 'moment/moment'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { DATE_FORMAT_DASH } from '../../../components/legacy/common/time-helpers'
import { useBaseLinesContext } from '../BaseLinesContextProvider'
import translate, { TranslateComponent } from '../../../common/translations'
import { getDateFormatByLocale } from "src/common/chartHelperFunctions"
import store from 'src/redux/store'

export const BaselineOverview = ({ formik, setIsTriggerValidation }) => {
  const { dataAvailablityDates } = useBaseLinesContext()

  const { values, errors, setFieldValue } = formik
  const [t] = useTranslation()

  let selectedLanguage = store?.getState().appData?.selectedLanguage
	selectedLanguage = selectedLanguage ? selectedLanguage : "en"
  const baselineDetails = {}

  const handleInputChange = (key, value) => {
    setIsTriggerValidation(true)
    setFieldValue(key, value)
  }

  const onChangeDate = (path) => (date) => {
    setIsTriggerValidation(true)
    date = date.target
      ? date.target.value
      : moment(date).locale('en').format(DATE_FORMAT_DASH)
    setFieldValue(path, date)
  }

  return (
    <BaslineWrapper>
      <TextInput
        labelText={'Baseline Name'}
        defaultValue={values?.name}
        name="name"
        onChange={({ target: { value, name } }) => {
          handleInputChange(name, value)
        }}
        hasError={errors?.['name']}
        isRequired={true}
        errorMessage={errors?.['name']}
        maxLength={MAX_NAME_LENGTH}
      />
      <TextAreaInput
        labelText={"Baseline Description"}
        defaultValue={values?.description}
        name="description"
        onChange={({ target: { value } }) => {
          handleInputChange('description', value)
        }}
        isRequired={false}
        hasError={errors?.['description']}
        errorMessage={errors?.['description']}
        hideErrorIcon={false}
        maxLength={MAX_DESC_LENGTH}
      />
      <h3>
        <TranslateComponent>Baseline period*</TranslateComponent>
      </h3>
      <DataPickerWrapper className="mini-error-text">
        <div>
          <ModalDatePickerInput
            date={values?.startPeriod ? values?.startPeriod : ''}
            onChange={onChangeDate('startPeriod')}
            isRequired={true}
            hasError={Boolean(errors?.startPeriod)}
            errorMessage={errors?.startPeriod}
            isNoDateFormatApply={true}
            formatApply={DATE_FORMAT_DASH}
          />
        </div>
        <div className="seperator-box">
          <span className="seperator">-</span>
        </div>
        <div>
          <ModalDatePickerInput
            date={values?.endPeriod ? values?.endPeriod : ''}
            onChange={onChangeDate('endPeriod')}
            isRequired={true}
            hasError={Boolean(errors?.endPeriod)}
            errorMessage={errors?.endPeriod}
            isNoDateFormatApply={true}
            formatApply={DATE_FORMAT_DASH}
          />
        </div>
      </DataPickerWrapper>
      <TextSection>
        <InfoText className="info-text">
          <span>
            <TranslateComponent>Defaults to available data.</TranslateComponent>
          </span>
            {' '}
            {translate("Baselines will automatically normalize for weather.")}
      
        </InfoText>
        <InfoText className="info-text">
          <TranslateComponent>
            Baseline periods for electric/gas and virtual meters must match for
            accurate virtual meter cost predictions.
          </TranslateComponent>
        </InfoText>
      </TextSection>
    </BaslineWrapper>
  )
}
