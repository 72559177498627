import * as d3 from 'd3'
import moment from 'moment'

import {
  axisTopTickHeight,
  axisBottomTickHeight,
  axisTickStrokeWidth,
  HEIGHT,
  axisLabelHeight
} from './constants'
import { convertRefToEl } from './helpers'
import { useSelector } from 'react-redux'

const getLocaleData = (localeValue) => {
  const formatter = new Intl.DateTimeFormat(localeValue, { weekday: "long" })
  const shortFormatter = new Intl.DateTimeFormat(localeValue, { weekday: "short" })
  const monthFormatter = new Intl.DateTimeFormat(localeValue, { month: "long" })
  const shortmonthFormatter = new Intl.DateTimeFormat(localeValue, { month: "short" })
  const decimal = '.'
  const thousands = ','
  const grouping = [3]
  const currency = ['$', '']
  const dateTime = '%x %I:%M%p'
  const date = '%m/%d/%Y'
  const time = '%I:%M:%S%p'
  const timeShort = '%I%p'
  const minutes = '%I:%M'
  const minutesLong = '%I:%M%p'
  const dateTimeShort = '%d %b, %I:%M%p'
  const dateLong = '%d %B, %Y'
  const dateShort = '%d %b'
  const dateShortMonthYear = '%m/%Y'
  const periods = [' AM', ' PM']
  const days = [...Array(7).keys()].map(el => formatter.format(new Date(Date.UTC(2024, 0, el + 1))))
  const shortDays = [...Array(7).keys()].map(el => shortFormatter.format(new Date(Date.UTC(2024, 0, el + 1))))
  const months = [...Array(12).keys()].map(el => monthFormatter.format(new Date(Date.UTC(2024, el, 1))))
  const shortMonths = [...Array(12).keys()].map(el => shortmonthFormatter.format(new Date(Date.UTC(2024, el, 1))))
  return {
    decimal,
    thousands,
    grouping,
    currency,
    dateTime,
    date,
    time,
    timeShort,
    minutes,
    minutesLong,
    dateTimeShort,
    dateLong,
    dateShort,
    dateShortMonthYear,
    periods,
    days,
    shortDays,
    months,
    shortMonths
  }
}

const setD3locale = (locale) => {
  const localeData = getLocaleData(locale)
  d3.timeFormatLocale({
    decimal: '.',
    thousands: ',',
    grouping: [3],
    currency: ['$', ''],
    dateTime: '%x %I:%M%p',
    date: '%m/%d/%Y',
    time: '%I:%M:%S%p',
    timeShort: '%I%p',
    minutes: '%I:%M',
    minutesLong: '%I:%M%p',
    dateTimeShort: '%d %b, %I:%M%p',
    dateLong: '%d %B, %Y',
    dateShort: '%d %b',
    dateShortMonthYear: '%m/%Y',
    periods: [' AM', ' PM'],
    days: localeData.days,
    shortDays: localeData.shortDays,
    months: localeData.months,
    shortMonths: localeData.shortMonths,
  })
}

export function initalizeTrackSettings(
  axisTickTopRef,
  axisTickBottomRef,
  axisLabelRef
) {
  const rangeX = d3.scaleTime()

  const axisTickTopEl = convertRefToEl(axisTickTopRef.current)
  const axisTickBottomEl = convertRefToEl(axisTickBottomRef.current)
  const axisLabelEl = convertRefToEl(axisLabelRef.current)

  function configureTrackSize(from, to, svgWidth) {
    rangeX
      .domain([moment(from).toDate(), moment(to).toDate()])
      .range([0, svgWidth])

    axisTickBottomEl.attr('transform', 'translate(0, ' + (HEIGHT + 1) + ')')
  }

  function configureTrackAxisLabel(selectedDateRange, startTime, endTime, selectedLanguage = "en") {
    const getSelectedLanguage = () => {
      if(selectedLanguage?.length > 2){
        return selectedLanguage
      } else {
        const language = selectedLanguage?.toUpperCase()
        return `${selectedLanguage}-${language}`
      }
    }
    setD3locale(getSelectedLanguage()) // this will set the langauage to dyanamic locale JSON(!Important)
    const dynamicLocale = getLocaleData(getSelectedLanguage())
    const locale = d3.timeFormatLocale(dynamicLocale)
    const isPeriodDay = selectedDateRange === 1
    const axisTickTopCount = isPeriodDay ? 24 : 28
    const axisTickBottomCount = isPeriodDay ? 8 : 7

    axisTickTopEl
      .call(
        d3
          .axisBottom(rangeX)
          .ticks(axisTickTopCount)
          .tickSize(axisTopTickHeight)
          .tickFormat(() => '')
      )
      .selectAll('line')
      .attr('y1', axisTickStrokeWidth)
      .attr('display', function (d) {
        if (d.getTime() === startTime || d.getTime() === endTime) {
          return 'none'
        }
      })

    axisTickBottomEl
      .call(
        d3
          .axisTop(rangeX)
          .ticks(axisTickBottomCount)
          .tickSize(axisBottomTickHeight)
          .tickFormat(() => '')
      )
      .selectAll('line')
      .attr('display', function (d) {
        if (d.getTime() === startTime || d.getTime() === endTime) {
          return 'none'
        }
      })

    const formatMinute = locale.format(dynamicLocale.minutes)
    const formatHour = locale.format(dynamicLocale.timeShort)
    const formatDateShort = locale.format(dynamicLocale.dateShort)

    function multiFormat(date) {
      return (
        d3.timeHour(date) < date
          ? formatMinute
          : d3.timeDay(date) < date
          ? formatHour
          : formatDateShort
      )(date)
    }

    axisLabelEl.selectAll('*').remove()
    axisLabelEl.call(
      d3
        .axisBottom(rangeX)
        .ticks(axisTickBottomCount)
        .tickFormat(multiFormat)
        .tickSize(0)
    )

    updateRangeLabelPos(startTime, endTime)
  }

  function updateRangeLabelPos(startTime, endTime) {
    axisLabelEl
      .selectAll('text')
      .attr('dy', axisLabelHeight)
      .attr('class', 'range-text')
      .attr('display', function (d) {
        if (d.getTime() === startTime || d.getTime() === endTime) {
          return 'none'
        }
      })
  }

  return {
    setTrackSize: (from, to, svgWidth) =>
      configureTrackSize(from, to, svgWidth),
    updateAxisLabel: (selectedDateRange, from, to, selectedLanguage) => {
      configureTrackAxisLabel(selectedDateRange, from, to, selectedLanguage)
    }
  }
}
