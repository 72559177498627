import PropTypes from 'prop-types'
import _get from 'lodash/get'
import Table from 'src/components/Table/clientSideTable'
import { FindingsTablePicketWrapper } from '../../styles'
import translate, { TranslateComponent } from 'src/common/translations'

const FindingsTablePicker = (props) => {
  const headings = [
    {
      title: translate('Issue/Finding'),
      key: 'name',
      disabled: true,
      maxWidth: '120px'
    },
    {
      title: translate('About this Finding'),
      key: 'aboutFinding',
      disabled: true,
      maxWidth: '80px'
    },
    {
      title: '',
      key: 'descriptionIcon',
      type: 'icon',
      disabled: true,
      maxWidth: '30px'
    }
  ]

  const { findings, handleLoading, responseTime, organizationId } = props ?? {}
  const loading = findings ? findings?.loading : false
  handleLoading(loading)

  const handleRowChecked = (data) => {
    data.checkboxCheckedEnabled = !data.checkboxCheckedEnabled
    props.onSelect({ key: data.findingId, value: data.name })
  }
  return !loading ? (
    <FindingsTablePicketWrapper>
      <div className="custom-table findings-table">
        <header><TranslateComponent>Select Finding</TranslateComponent>:</header>
        <div className="finding-table">
          <Table
            key={`findingTablePickerTable-${organizationId}`}
            rows={findings}
            header={headings}
            rowCheckbox={true}
            loadTime={responseTime}
            checkboxClick={handleRowChecked}
            checkboxChecked={(e) => e.checkboxCheckedEnabled}
            search={true}
            searchFields={['name']}
            isShowPagination={false}
            fixedLayout={false}
          />
        </div>
      </div>
    </FindingsTablePicketWrapper>
  ) : null
}

FindingsTablePicker.propTypes = {
  findings: PropTypes.shape({
    Findings: PropTypes.array,
    loading: PropTypes.bool
  }).isRequired,
  pickerClassName: PropTypes.string,
  onSelect: PropTypes.func,
  selectedItems: PropTypes.array,
  handleLoading: PropTypes.func,
  responseTime: PropTypes.string,
  organizationId: PropTypes.string
}

FindingsTablePicker.defaultProps = {
  selectedItems: [],
  onSelect: () => {},
  handleLoading: () => {}
}
export default FindingsTablePicker
