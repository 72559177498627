import { useState } from 'react'
import translate, { TranslateComponent } from 'src/common/translations'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { Button } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriverWrench } from 'src/denali-components/lib/pro-solid-svg-icons'
import { EquipmentSetupList } from 'src/pages/equipmentSetup/EquipmentSetupList'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { PageError } from 'src/denali-components/PageError/PageError'
import { CreateEquipment } from 'src/pages/equipmentSetup/CreateEquipment/create-equipment'
import { CreateNiagaraEquipment } from 'src/pages/equipmentSetup/CreateEquipment/create-niagara-equipment'

export const EquipmentSetupContainer = ({
  buildingId,
  isRefreshEquipmentList,
  setLoadtime,
  equipmentListType,
  getEquipments,
  createFunc,
  setListType,
  isDenali,
  isShowCreateEquipment,
  isShowCreateNiagaraEquipment,
  handleCreateEquipmentClose,
  equipmentsList,
  optionsTranslated
}) => {
  const { organizationId } = getSearchParams()
  const [modalType, setModalType] = useState('')

  return (
    <Page
      title={translate('Equipment Setup')}
      icon={<FontAwesomeIcon icon={faScrewdriverWrench} />}
      iconColor={IconColor.orange}
      testid="equipSetup"
      actions={[
        <Button
          key="create"
          data-testid="CreateTISEquipmentButton"
          ariaLabel="Create TIS Equipment"
          onClick={() => {
            createFunc()
          }}
          disabled={!buildingId}
        >
          <TranslateComponent>Create TIS Equipment</TranslateComponent>
        </Button>
      ]}
    >
      {buildingId === null ? (
        <PageError
          buildingId={buildingId}
          organizationId={organizationId}
          message={''}
        />
      ) : (
        <EquipmentSetupList
          isRefreshEquipmentList={isRefreshEquipmentList}
          setLoadtime={setLoadtime}
          equipmentListType={equipmentListType}
          getEquipments={getEquipments}
          setListType={setListType}
          isDenali={isDenali}
        />
      )}
      {isShowCreateEquipment && (
        <CreateEquipment
          buildingId={buildingId}
          handleCreateEquipmentClose={handleCreateEquipmentClose}
          equipmentsList={equipmentsList}
          optionsTranslated={optionsTranslated}
        />
      )}
      {isShowCreateNiagaraEquipment && (
        <CreateNiagaraEquipment
          buildingId={buildingId}
          handleCreateEquipmentClose={handleCreateEquipmentClose}
          equipmentsList={equipmentsList}
        />
      )}
    </Page>
  )
}
