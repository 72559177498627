import { TranslateComponent } from 'src/common/translations'
import { Chip, ChipColor, ChipSize } from './Chip'

export const ChipStatus = ({ statusText, size = '' }: Props) => {
  const formattedText = formatText(statusText)
  const color = getColor(formattedText)
  return (
    <Chip color={color} size={size}>
      <TranslateComponent>{formattedText}</TranslateComponent>
    </Chip>
  )
}

interface Props {
  statusText: ChipStatuses
  size?: ChipSize
}

export type ChipStatuses =
  | 'In Progress'
  | 'inProgress'
  | 'Ready for Review'
  | 'Collecting Data'
  | 'Complete'
  | 'Recommended'
  | 'Validation'
  | 'Train Deferred'
  | 'Customer Deferred'
  | 'Deferred'
  | 'Dismissed'
  | 'Remote Resolved'
  | 'On-Site Resolved'
  | 'Hold'
  | 'Archive'

function getColor(statusText) {
  switch (statusText?.toLowerCase()) {
    case 'in progress':
      return ChipColor.blue
    case 'ready for review':
      return ChipColor.orange
    case 'collecting data':
      return ChipColor.purple
    case 'complete':
      return ChipColor.green
    case 'recommended':
      return ChipColor.purple
    case 'validation':
      return ChipColor.gold
    case 'customer deferred':
      return ChipColor.pink
    case 'train deferred':
      return ChipColor.orange
    case 'deferred':
      return ChipColor.default
    case 'remote resolved':
      return ChipColor.green
    case 'on-site resolved':
      return ChipColor.green
    case 'dismissed':
      return ChipColor.red
    case 'hold':
      return ChipColor.orange
    case 'validation':
      return ChipColor.gold
    case 'archive':
      return ChipColor.gray
  }
  return ChipColor.default
}

function formatText(statusText) {
  switch (statusText?.toLowerCase()) {
    case 'inprogress':
      return 'In Progress'
    case 'readyforreview':
      return 'Ready for Review'
    case 'collectingdata':
      return 'Collecting Data'
    case 'complete':
      return 'Complete'
    case 'recommended':
      return 'Recommended'
    case 'validation':
      return 'Validation'
    case 'customerdeferred':
      return 'Customer Deferred'
    case 'traindeferred':
      return 'Train Deferred'
    case 'deferred':
      return 'Deferred'
    case 'dismissed':
      return 'Dismissed'
    case 'remoteresolved':
      return 'Remote Resolved'
    case 'onsiteresolved':
      return 'On-Site Resolved'
    case 'hold':
      return 'Hold'
    case 'archive':
      return 'Archive'
  }
  return statusText
}
