import { Flex, Input, Label } from '@aws-amplify/ui-react'

// Project imports
import { type IOpportunitySettingsCostSavings } from 'src/models/opportunity'
import { TranslateComponent } from 'src/common/translations'

// Legacy imports
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

// Denali imports
import styles from 'src/denali-components/Form/form.module.scss'
import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import { SelectVisibility } from 'src/denali-components/Form/SelectVisibility'
import { DenaliFormElementError } from './DenaliFormElementErrors'
import moment from 'moment'

interface CostSavingsWaitingProps {
  values: IOpportunitySettingsCostSavings
  inputValue: string
  inputProperty: string
  title?: string
  primaryFieldLabel?: string
  icon?: any
  iconColor?: string
  disabled?: boolean
  visibility?: boolean
  visibilityProperty: string
  previewBuilder?: (
    values: IOpportunitySettingsCostSavings
  ) => React.ReactElement
}

export const CostSavingsWaiting = ({
  values,
  inputValue = '',
  inputProperty,
  title,
  primaryFieldLabel,
  icon,
  iconColor,
  disabled,
  visibility,
  visibilityProperty,
  previewBuilder
}: CostSavingsWaitingProps) => {
  const formattedDate = moment(inputValue).format(BACKEND_DATE_FORMAT)
  return (
    <div
      className={`${styles.cardFinancial} ${
        disabled ? styles.disabledCard : ''
      }`}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        width="100%"
      >
        <Flex alignItems="center" direction="row">
          <div className={styles.icon} data-color={iconColor}>
            {icon}
          </div>
          <span className={styles.title}>
            <TranslateComponent>{title}</TranslateComponent>
          </span>
        </Flex>
        <SelectVisibility
          visibility={visibility}
          name={visibilityProperty}
          className={styles.smlSelect}
        />
      </Flex>
      <hr />
      <Flex direction="column" gap="0" width="100%">
        <Label className={styles.smlLabel} htmlFor="startDate">
          <TranslateComponent>{primaryFieldLabel}</TranslateComponent>:
        </Label>
        <DenaliFormElement name={inputProperty}>
          <Input
            type="date"
            name="startDate"
            data-testid="startDateField"
            value={formattedDate}
            placeholder="Select date"
          />
        </DenaliFormElement>
        <DenaliFormElementError name={inputProperty} />
        <Flex width="100%" direction="column" marginTop="16px">
          {previewBuilder && previewBuilder(values)}
        </Flex>
      </Flex>
    </div>
  )
}
