import { createSlice } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import { Document } from 'src/models/document'
import { BuildingLocation } from 'src/models/location'
import { calculatePayback, calculateROI } from 'src/pages/opportunities/helpers'

import { FormDataTypes } from '../types/FormDataTypes'
import { TStore } from '../store'
import Opportunity from 'src/models/opportunity'

export const defaultDocumentValues = new Document({
  documentName: '',
  documentType: '',
  documentStatus: '',
  visibility: true,
  location: new BuildingLocation({
    locationId: '',
  }), 
  createdBy: '',
  createdDate: moment().format('YYYY-MM-DD'),
  note: '',
  attachments: [],
  description: '',
  startDate: moment().format('YYYY-MM-DD')
})

// Initial state for issuesAndFindings needs to be null until values are dispatched to redux
const initialState: FormDataTypes = {
  issuesAndFindings: null,
  documentsAndReports: cloneDeep(defaultDocumentValues),
  opportunity: null,
  energyUsageReports: {}
}

export const formData = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    setIssuesAndFindings: (state, action) => {
      state.issuesAndFindings = cloneDeep(action.payload)
    },
    setIssuesAndFindingsTisObjects: (state, action) => {
      state.issuesAndFindings = cloneDeep({ ...state.issuesAndFindings, tisObjects: action.payload })
    },
    setDocumentsAndReports: (state, action) => {
      state.documentsAndReports = cloneDeep(action.payload)
    },
    setEnergyUsageReports: (state, action) => {
      state.energyUsageReports = cloneDeep(action.payload)
    },
    setOpportunity: (state, action) => {
      if (action.payload === null) {
        state.opportunity = null
      } else {
        const data: Opportunity = action.payload
        // Let's do the ROI/Payback calculation here.
        const savings = data.settings.costSavings;
        const roi = calculateROI(
          savings.costToImplement.value,
          savings.savings,
          savings.lifecycle
        )
        const payback = calculatePayback(
          savings.costToImplement.value,
          savings.savings,
          savings.timeToUse
        )
        const modifiedCostSavings = {
          roiCalculation: {
            value: roi,
            enabled: !!savings.roiCalculation.enabled
          },
          paybackCalculation: {
            value: payback,
            enabled: !!savings.paybackCalculation.enabled
          }
        }
        // Now update properties.
        data.settings.costSavings.roiCalculation = modifiedCostSavings.roiCalculation
        data.settings.costSavings.paybackCalculation = modifiedCostSavings.paybackCalculation
        
        // Set state
        state.opportunity = cloneDeep(action.payload)
      }
    },
    setOpportunityTisObjects: (state, action) => {
      state.opportunity = cloneDeep({ ...state.opportunity, tisObjects: action.payload })
    },
  }
})

export const {
  setIssuesAndFindings,
  setIssuesAndFindingsTisObjects,
  setDocumentsAndReports,
  setEnergyUsageReports,
  setOpportunity,
  setOpportunityTisObjects
} = formData.actions

export const selectIssuesAndFindingsData = (state: TStore) => state.formData.issuesAndFindings
export const selectDocumentsAndReportsData = (state: TStore) => state.formData.documentsAndReports
export const selectEnergyUsageReportsData = (state: TStore) => state.formData.energyUsageReports
export const selectOpportunityData = (state: TStore) => state.formData.opportunity
