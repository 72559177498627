import { Flex, CheckboxField } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachographDigital } from '../lib/pro-solid-svg-icons'

// Denali imports
import formStyles from 'src/denali-components/Form/form.module.scss'
import {
  getColor as getRandomColor,
  randomizeString
} from 'src/denali-components/helpers'
import { ChangeEvent, memo } from 'react'

type ChardCheckboxProps<T> = {
  value: T
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  color?: string
  checked: boolean
}
const CardCheckboxComponent = ({
  onChange,
  name,
  value,
  color,
  checked
}: ChardCheckboxProps<any>) => {
  const iconColor = color ?? getRandomColor(randomizeString(name))

  return (
    <div className={formStyles.cardCheckbox}>
      <Flex direction={'column'} gap={'8px'}>
        <div data-color={iconColor} className={formStyles.cardBuildingIcon}>
          <FontAwesomeIcon icon={faTachographDigital} />
        </div>
        <span>{name}</span>
      </Flex>
      <CheckboxField
        className={formStyles.checkbox}
        labelHidden
        label={name}
        name={name}
        value={value}
        onChange={(e) => onChange(e, checked)}
        checked={checked}
      />
    </div>
  )
}

export const CardCheckbox = memo(CardCheckboxComponent)
