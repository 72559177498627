import { Chip, ChipColor, ChipSize } from './Chip'

export const ChipNextStep = ({ nextStepText, size = '' }: Props) => {
  const color = getColor(nextStepText)
  return <Chip size={size} text={nextStepText} color={color} />
}

interface Props {
  nextStepText: ChipNextSteps
  size?: ChipSize
}

export type ChipNextSteps =
  | 'Building Personnel Review'
  | 'Trane Office Review'
  | 'traneOfficeReview'
  | 'Mech Tech On-Site'
  | 'BAS Tech On-Site'
  | 'Remove Service'
  | 'Complete'
  | 'Archive'

function getColor(nextStepText) {
  switch (nextStepText.toLowerCase()) {
    case 'building personnel review':
      return ChipColor.pink
    case 'trane office review':
      return ChipColor.orange
    case 'mech tech on-site':
      return ChipColor.purple
    case 'bas tech on-site':
      return ChipColor.gold
    case 'remote service':
      return ChipColor.blue
    case 'complete':
      return ChipColor.green
    case 'archive':
      return ChipColor.default
  }
  return ChipColor.default
}
