import { Flex, Radio } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

// Denali imports
import formStyles from 'src/denali-components/Form/form.module.scss'
import {
  getColor as getRandomColor,
  randomizeString
} from 'src/denali-components/helpers'
import { CardRadioProps } from './types'

export const CardRadio = ({
  name,
  value,
  color,
  disabled,
  icon = faBuilding
}: CardRadioProps) => {
  const iconColor = color ?? getRandomColor(randomizeString(name))
  return (
    <Radio
      value={value}
      className={`${formStyles.cardRadio} ${
        disabled ? formStyles.cardRadioDisabled : ''
      }`}
      disabled={disabled}
    >
      <div data-color={iconColor}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <Flex>
        <span className={formStyles.cardRadioText}>{name}</span>
      </Flex>
    </Radio>
  )
}
