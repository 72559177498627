import { type ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBolt,
  faZap,
  faFire,
  faSnowflake,
  faBuilding,
  faFile,
  faFileLines,
  faMessage,
  faTachographDigital,
  faUserGroup,
  faComment
} from '@fortawesome/free-solid-svg-icons'
import {
  faChartMixed,
  faRadar
} from 'src/denali-components/lib/pro-solid-svg-icons'
import styles from './table.module.scss'
import {
  consultationType,
  documentType,
  fileType,
  energyUsageReportType
} from 'src/pages/documentsAndReports/helper'
import { commentType, ecmType } from 'src/pages/eventMarkers/helper'
import { getColor as getRandomColor } from 'src/denali-components/helpers'

export const IconCell = ({
  iconType,
  children,
  iconSize = IconSize.normal,
  randomizeColor,
  className
}: IconCellProps) => {
  const color = randomizeColor
    ? getRandomColor(randomizeColor)
    : getIconColor(iconType)
  const icon = getIcon(iconType)

  return (
    <div className={`${styles.iconCell} ${className || ''}`}>
      <div
        className={styles.iconCellIcon}
        data-icon-color={color}
        data-size={iconSize}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
      {children && <div>{children}</div>}
    </div>
  )
}

export type IconCellProps = {
  iconType: string
  children?: ReactNode
  iconSize?: IconSize
  randomizeColor?: string
  className?: string
}

export enum IconSize {
  small = 'small',
  normal = 'normal',
  fortyFour = 'fortyFour',
  fortyFourAlt = 'fortyFourAlt'
}

function getIcon(iconType) {
  switch (iconType) {
    case documentType:
      return faFileLines
    case consultationType:
      return faUserGroup
    case energyUsageReportType:
      return faBolt
    case fileType:
      return faFile
    case commentType:
      return faComment
    case ecmType:
      return faBolt
    case 'issues/findings':
      return faRadar
    case 'GAS':
      return faFire
    case 'ElectricMeter':
      return faZap
    case 'VirtualMeter':
      return faSnowflake
    case 'SC/SC+':
    case 'CM':
    case 'Symbio 700':
    case 'Ensemble':
    case 'Symbio 800':
    case 'SCG':
    case 'deviceUnavailable':
    case 'Equipment':
      return faTachographDigital
    case 'Building':
      return faBuilding
    case 'Performance':
      return faChartMixed
    case 'Service Advisory':
      return faMessage
  }
  return faFileLines
}

function getIconColor(iconType) {
  switch (iconType) {
    case documentType:
    case 'Performance':
    case 'ElectricMeter':
      return 'purple'
    case consultationType:
    case 'Service Advisory':
      return 'pink'
    case energyUsageReportType:
    case 'Building':
    case 'VirtualMeter':
      return 'blue'
    case fileType:
      return 'gray-duo'
    case commentType:
      return 'blue'
    case ecmType:
    case 'Equipment':
    case 'GAS':
      return 'orange'
    case 'SC/SC+':
      return 'blue-duo'
    case 'CM':
      return 'purple-duo'
    case 'Symbio 700':
      return 'pink-duo'
    case 'Ensemble':
      return 'turquoise-duo'
    case 'Symbio 800':
      return 'green-duo'
    case 'SCG':
      return 'gold-duo'
    case 'deviceUnavailable':
      return 'red-duo'
  }
  return ''
}
