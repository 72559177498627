/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import NestedSelect from './blocks/wrapper'
import TreeView from './blocks/TreeView'
import BodyShowDisabledLocations from './blocks/body-show-disabled-locations'
import {
  selectOpen,
  setOpen,
  selectSearchText,
  selectBuildings,
  selectBuilding,
  setSearchText,
  setBuildings,
  setRecentSearchedItems,
  selectUserHistory,
  setSelectedBuilding,
  setSelectedOrganization,
  setRecentUserHistoryFetched,
  selectRecentUserHistoryFetched,
  selectRateStructureSalesOfficeId,
  selectBuildingBaseData,
  setSelectedbuildingOfferings
} from 'src/redux/slicers/buildingPicker'
import { TokenInfoContainer } from './style'
import { useDispatch, useSelector } from 'react-redux'
import BodyFilter from './blocks/bodyFilter'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import {
  getBuildingOfferings,
  getSession,
  refreshTokenFromSelection
} from 'src/redux/slicers/appData'
import {
  SEARCH_BUILDINGS,
  SEARCH_SALES_OFFICE,
  SAVE_USER_HISTORY,
  UPDATE_USER_HISTORY,
  DELETE_USER_HISTORY,
  GET_USER_HISTORY
} from './graphql'
import { LIST_OFFERINGS_BY_BUILDING } from 'src/pages/contracts-and-offerings/queries'
import {
  accountMapping,
  salesOfficeMapping,
  checkIfMobile,
  itemFromHistory,
  checkIfFoundUserHistory,
  inheritProperties,
  getFormatVariables,
  getSalesOffice,
  getBuilding
} from './helper'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { useSearchParams } from 'react-router-dom'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { RecentSearchedItems } from './blocks/recent-searched-items'
import { useEffect, useMemo, useRef, useState } from 'react'
import { LOCATION, ORGANIZATION, SALES_OFFICE } from './constants'
import { useLocation } from 'react-router'
import {
  searchObject,
  OPERATORS_ENUM
} from 'src/components/legacy/common/table/search'
import { Navigate } from 'react-router-dom'
import { API } from 'aws-amplify'
import { getSearchParams } from 'src/common/helperFunctions.js'
import clickOutsideHook from 'src/denali-ui/legacy/common_file/customHooks/clickOutsideHook'
import { IBuildingOld } from 'src/redux/types/BuildingPickerTypes'
import { useUserSearchHistory } from './use-user-search-history'


const BuildingNavigation = (props) => {
  const {
    allowDisabledLocations = false,
    groupByTag,
    showLocations,
    updateTokenOrg,
    updateTokenOrgBuildings,
    bypassUpdatingClaims,
    claims,
    updateTokenSO,
    isShowBuildingPicker
  } = props

  const [searchBuildingsResult, setSetSearchBuildingsResult] = useState([])
  const { data: baseSearchBuildingsResult } = useSelector(
    selectBuildingBaseData
  )
  const [searchSalesofficesResult, setSearchSalesOfficesResult] = useState([])
  const [session, setSession] = useState(null)
  const [fetchingToken, setFetchingToken] = useState(false)
  const [tokenFetchError, setTokenFetchError] = useState('')
  const dispatch = useDispatch()
  const open = useSelector(selectOpen)
  const searchText = useSelector(selectSearchText)
  const { loading, data: buildings } = useSelector(selectBuildings)
  const { point: breakpointSize } = useBreakPoint()
  const selected = useSelector(selectBuilding) || ({} as IBuildingOld)
  const [searchParams, setSearchParams] = useSearchParams({})
  const [showDisabledBuildings, setShowDisabledBuildings] = useState(false)
  const userInfo = useSelector(selectUserInfo)
  const recentSearchItems = useSelector(selectUserHistory)
  const isUserHistoryFetched = useSelector(selectRecentUserHistoryFetched)
  const [redirect, setRedirect] = useState(false)
  const rateStructureSalesofficeId =
    useSelector(selectRateStructureSalesOfficeId) || null

  const location = useLocation()
  const wrapperRef = useRef()
  clickOutsideHook(wrapperRef, () => {
    dispatch(setOpen(false))
  })

  const { refetch: fetchBuildings } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items',
    onSuccess: (data: any) => {
      try {
        let finalData = []
        if (groupByTag.includes(ORGANIZATION)) {
          const accountsData = accountMapping(data, showLocations)
          finalData = Object.values(accountsData)
        }
        setSetSearchBuildingsResult(finalData)
      } catch (e) {
        console.error(e)
      }
    }
  })

  const { refetch: refetchBuildingOfferings } = useQuery({
    query: LIST_OFFERINGS_BY_BUILDING,
    disableInitialLoad: true,
    dataPath: 'data.listOfferingsByBuilding.items'
  })

  const { refetch: fetchSalesOffices } = useQuery({
    query: SEARCH_SALES_OFFICE,
    disableInitialLoad: true,
    dataPath: 'data.searchSalesOffices.items',
    onSuccess: (data: any) => {
      try {
        if (groupByTag.includes(SALES_OFFICE)) {
          const salesOfficeData = salesOfficeMapping(data, showLocations)
          const salesOffices = Object.values(salesOfficeData)
          setSearchSalesOfficesResult(salesOffices)
        }
      } catch (e) {
        console.error(e)
      }
    }
  })

  const { refetch: fetchUserHistory, loading: isHistoryLoading } = useUserSearchHistory({
    onEmpty: async () => {
      await setBaseBuildingsData()
      return
    },
    onResults: (userHistoryItems) => {
      setSelectedItemURLFromStore(groupByTag, userHistoryItems)
      setRecentItemsListToDisplay(groupByTag, userHistoryItems)
    }
  })

  const resetState = () => {
    dispatch(setOpen(false))
    dispatch(setSelectedBuilding(null))
    dispatch(
      setRecentSearchedItems({
        type: 'Success',
        data: []
      })
    )
  }

  // Adds entries to user History - Recent Items
  const { onSubmit: addBuidlingUserHistory } = useMutation({
    query: SAVE_USER_HISTORY,
    dataPath: 'data.createUserHistory'
  })

  // Deletes user History - Recent Items
  const { onSubmit: deleteBuildingUserHistory } = useMutation({
    query: DELETE_USER_HISTORY,
    dataPath: 'data.deleteUserHistory',
    onError: () => {
      // Suppressing unnecessary error (happens when multiple tabs up sometimes)
      return
    }
  })

  // Updates user History - Recent Items
  const { onSubmit: updateBuildingUserHistory } = useMutation({
    query: UPDATE_USER_HISTORY,
    dataPath: 'data.updateUserHistory',
    onError: () => {
      // Suppressing unnecessary error (happens when multiple tabs up sometimes)
      return
    }
  })

  const setNewSelectedItem = async (groupByTag, userRecentHistory) => {
    try {
      const { buildingId, organizationId, salesOfficeId } = getSearchParams()
      const filters = []
      let selectedItem = null
      const variables: any = {
        filter: {
          or: {}
        },
        limit: 1
      }
      // check the org or location id is there is the recent items
      if (buildingId && groupByTag?.includes(LOCATION)) {
        const recentItem = userRecentHistory?.data?.find(
          (x) => x?.id === buildingId
        )
        if (recentItem) {
          selectedItem = recentItem
        } else {
          filters.push({ id: { eq: buildingId } })
          variables.filter.or = filters
          const response = await getBuilding(variables, LOCATION)
          if (response?.id) selectedItem = response
        }
      } else if (
        (salesOfficeId || rateStructureSalesofficeId) &&
        groupByTag?.includes(SALES_OFFICE)
      ) {
        const salesOffice = salesOfficeId || rateStructureSalesofficeId
        const recentItem = userRecentHistory?.data?.find(
          (x) => x?.salesOfficeId === salesOffice
        )
        if (recentItem) {
          selectedItem = recentItem
        } else {
          filters.push({ id: { eq: salesOffice } })
          variables.filter.or = filters
          const response = await getSalesOffice(variables, SALES_OFFICE)
          if (response?.id) selectedItem = response
        }
      } else if (organizationId && groupByTag?.includes(ORGANIZATION)) {
        const recentItem = userRecentHistory?.data?.find(
          (x) => x?.accountId === organizationId && x?.type === ORGANIZATION
        )
        if (recentItem) {
          selectedItem = recentItem
        } else {
          filters.push({ accountId: { eq: organizationId } })
          variables.filter.or = filters
          const response = await getBuilding(variables, ORGANIZATION)
          if (response?.id) selectedItem = response
        }
      }
      if (selectedItem) {
        handleSelectItemClick(selectedItem, userRecentHistory)
      } else {
        dispatch(setSelectedBuilding(null))
      }
    } catch (error) {}
  }

  const setSelectedItemURLFromStore = (groupByTag, userRecentHistory) => {
    const { buildingId, organizationId, salesOfficeId } = getSearchParams()
    // if none of the ids present in URL means user cliked on navigation link
    // We can assign the item from Recent history
    // If not, User specially try to access a specific entity so, it is not necesaary to be presnt in recent items
    // so different flow
    if (
      buildingId ||
      organizationId ||
      salesOfficeId ||
      rateStructureSalesofficeId
    ) {
      setNewSelectedItem(groupByTag, userRecentHistory)
    } else {
      let selectedItem = null
      if (groupByTag?.length === 1 && groupByTag.includes(SALES_OFFICE)) {
        // if it has only sales office to show in grouptag
        // don't select anything by default, keep it empty
        dispatch(setSelectedBuilding(null))
        return
      } else {
        for (let i = 0; i < userRecentHistory?.data?.length; i++) {
          const item = userRecentHistory?.data[i]
          // @ts-ignore
          if (groupByTag.includes(item?.type)) {
            selectedItem = userRecentHistory?.data[i]
            break
          }
        }
        if (selected?.type && groupByTag.includes(selected?.type)) {
          handleSelectItemClick(selected)
        } else if (selectedItem) {
          handleSelectItemClick(selectedItem, userRecentHistory)
        } else {
          dispatch(setSelectedBuilding(null))
        }
      }
    }
  }

  const setRecentItemsListToDisplay = (groupByTag, userRecentHistory) => {
    return userRecentHistory?.data?.filter((x) => {
      // @ts-ignore
      return groupByTag.includes(x?.type)
    })
  }

  const initializeUserHistoryData = async () => {
    const isNotFound = true
    if (session?.['custom:userId'] && isNotFound) {
      if (isUserHistoryFetched) {
        setSelectedItemURLFromStore(groupByTag, recentSearchItems)
        setRecentItemsListToDisplay(groupByTag, recentSearchItems)
      } else {
        fetchUserHistory({ owner: session['custom:userId'] })
      }
    }
  }

  useEffect(() => {
    dispatch(setSelectedbuildingOfferings([]))
    initializeUserHistoryData()

    // else resetState()
  }, [session, groupByTag, location?.pathname])

  useEffect(() => {
    const finalData = [...searchSalesofficesResult, ...searchBuildingsResult]
    // recentSearchItems?.data?.length > 0 &&
    dispatch(
      setBuildings({
        type: 'Success',
        data: finalData
      })
    )
  }, [searchSalesofficesResult, searchBuildingsResult])

  useEffect(() => {
    if (location?.search === '') {
      setSelectedItemURLFromStore(groupByTag, recentSearchItems)
      setRecentItemsListToDisplay(groupByTag, recentSearchItems)
    }
  }, [location?.search])

  const toggleOpen = () => {
    const emptyHistoryFunc = async () => {
      if (!groupByTag?.includes(SALES_OFFICE)) {
        !buildings?.length &&
          baseSearchBuildingsResult?.length &&
          setSetSearchBuildingsResult([...baseSearchBuildingsResult])
      }
    }
    if (open) {
      dispatch(setSearchText(''))
      dispatch(
        setBuildings({
          type: 'Success',
          data: []
        })
      )
    }
    if (!open) {
      emptyHistoryFunc()
    }
    dispatch(setOpen(!open))
  }
  const handleBodyFilterChange = (matchPhrasePrefix) => {
    dispatch(setSearchText(matchPhrasePrefix))
    // When user clears the search text, sets the building also to empty
    if (matchPhrasePrefix === '') {
      dispatch(
        setBuildings({
          type: 'Success',
          data: []
        })
      )
    }
    if (matchPhrasePrefix?.length < 2) return
    dispatch(
      setBuildings({
        type: 'Loading'
      })
    )
    if (groupByTag.includes(SALES_OFFICE)) {
      // sales office search handled seperetly due to access control
      const filters: any = []
      filters.push({ name: { matchPhrasePrefix: `*${matchPhrasePrefix}*` } })
      filters.push({ id: { matchPhrasePrefix: `*${matchPhrasePrefix}*` } })
      filters.push({
        district: { matchPhrasePrefix: `*${matchPhrasePrefix}*` }
      })
      const variables: any = {
        filter: {
          or: filters
        },
        limit: 1000
      }
      fetchSalesOffices(variables)
    }
    const filters: any = []
    if (groupByTag.includes(ORGANIZATION))
      filters.push({
        accountName: { matchPhrasePrefix: `*${matchPhrasePrefix}*` }
      })

    if (showLocations) {
      filters.push({ name: { matchPhrasePrefix: `*${matchPhrasePrefix}*` } })
      filters.push({ address: { matchPhrasePrefix: `*${matchPhrasePrefix}*` } })
    }
    const variables: any = {
      filter: {
        or: filters
      },
      limit: 1000
    }
    if (!showDisabledBuildings) {
      variables.filter.and = { isActive: { eq: 1 } }
    }
    fetchBuildings(variables)
  }

  const handleBodyItemsClick = () => (item) => {
    // remove equipment from search param on building/org change
    setSearchParams((prevParams) => {
      if (prevParams.has('equipment')) {
        prevParams.delete('equipment')
      }
      return prevParams
    })
    if (!item.id) {
      return
    }
    handleSelectItemClick(item)
  }

  const closeDialog = () => {
    document.body.classList.remove('fixed')
    toggleOpen()
  }
  const onChangeShowDisabledLocations = (value) => {
    setShowDisabledBuildings(value)
  }

  // This method sets the search params with out deleting the existings one, other than location, salesOfficeId and organization params
  const searchParamSetting = (type, params) => {
    // Set search params, when when we show building picker
    if (isShowBuildingPicker) {
      const { organizationId, salesOfficeId, locationId } = params

      setSearchParams((prevParams) => {
        if (prevParams.has('organization')) {
          prevParams.delete('organization')
        }

        if (prevParams.has('location')) {
          prevParams.delete('location')
        }

        if (prevParams.has('salesOfficeId')) {
          prevParams.delete('salesOfficeId')
        }

        switch (type) {
          case ORGANIZATION:
            prevParams.set('organization', organizationId)
            break
          case SALES_OFFICE:
            prevParams.set('salesOfficeId', salesOfficeId)
            break
          case LOCATION:
            prevParams.set('organization', organizationId)
            prevParams.set('location', locationId)
          default:
        }
        return prevParams
      })
    }
  }

  const handleSelectItemClick = async (selected, userHistoryItems = []) => {
    try {
      const isMobile = checkIfMobile(breakpointSize)
      isMobile && closeDialog()
      setTokenFetchError('')
      setFetchingToken(true)
      const eTime = moment().unix()
      const inputVariables: any = {
        eTime,
        owner: session['custom:userId'],
        searchQuery: searchText,
        type: selected.type
      }
      const organizationId = selected?.accountId || selected?.id
      dispatch(setSelectedOrganization(organizationId))

      const buildingType: any = {
        icon: selected.icon,
        type: selected.type,
        primary: selected.primary,
        id: selected.id,
        accountId: selected.accountId
      }

      if (!bypassUpdatingClaims) {
            await Promise.all([refreshTokenFromSelection(
            selected,
            dispatch,
            fetchBuildings,
            claims,
            updateTokenOrg,
            updateTokenOrgBuildings,
            updateTokenSO
          ),
          // store the offering in redux, it is used by pages to restrict feature based on offerings
        ...(selected.type === LOCATION ? [ getBuildingOfferings(selected.id, refetchBuildingOfferings, dispatch) ] : [])
        ])
      }

      if (selected.type === ORGANIZATION) {
        inputVariables.accountId = selected.id
        searchParamSetting(ORGANIZATION, { organizationId: selected.id })
      } else if (selected.type === LOCATION) {
        // store the offering in redux, it is used by pages to restrict feature based on offerings
        await getBuildingOfferings(
          selected?.id,
          refetchBuildingOfferings,
          dispatch
        )
        inputVariables.accountId = selected.accountId
        inputVariables.buildingId = selected.id
        inputVariables.salesOfficeId = selected.salesOfficeId
        //setSearchParams({ organization: selected.accountId, location: selected.id })
        searchParamSetting(LOCATION, {
          organizationId: selected.accountId,
          locationId: selected.id
        })
      } else if (selected.type === SALES_OFFICE) {
        inputVariables.salesOfficeId = selected.id
        // setSearchParams({ salesOfficeId: selected.id})
        searchParamSetting(SALES_OFFICE, { salesOfficeId: selected.id })
      }

      // if the picker opens, close this
      // When the user clicks on left menu, we don't open building picker, so check before call toggle
      if (open) {
        toggleOpen && toggleOpen()
      }
      buildingType.secondary = selected.secondary
      dispatch(setSelectedBuilding(buildingType))
      userInfo?.id &&
        saveToBuildingUserHistory(inputVariables, selected, userHistoryItems)
    } catch (error) {
      // setTokenFetchError('Something wrong in fetching token, try again later')
    } finally {
      setFetchingToken(false)
    }
  }

  // Save the recent building items for history, Update or Add
  // Save User History Mutation is dumb, it just saves and retrieves
  // So have to handle all the logics in here
  const saveToBuildingUserHistory = async (
    inputVariables,
    selectedItem,
    historyItems: any = []
  ) => {
    try {
      // If method calls with history items take that, otherwise take from store
      const userHistroyItems =
        historyItems?.data?.length > 0
          ? historyItems?.data
          : recentSearchItems?.data || []
      // set all selected items to false, as we don't know the previous selected item
      const modifiedRecentItems = userHistroyItems.map((x) => {
        return { ...x, selected: false }
      })

      const currentTime = inputVariables.eTime

      const isItemFound = checkIfFoundUserHistory(
        userHistroyItems,
        selectedItem
      )
      if (isItemFound) {
        // If item already present in recent items, modify the timestamp to get it as First item
        await updateBuildingUserHistory({
          input: {
            id: isItemFound?.dbIdentifier,
            eTime: currentTime
          }
        })
        // Should not mutate the state, but will revisit this logic
        const selectedItemIndex = userHistroyItems
          .map((item) => {
            return { ...item, selected: false }
          })
          .findIndex((x) => x.dbIdentifier === isItemFound?.dbIdentifier)
        const modifiedCurrentItem = {
          ...isItemFound,
          eTime: currentTime,
          selected: true
        }
        modifiedRecentItems[selectedItemIndex] = modifiedCurrentItem
      } else {
        // Selected item is not present, add to recent item
        // If the recent items count is more than 10, remove the last one
        if (userHistroyItems?.length >= 10) {
          // deletes the 10th Item
          await deleteBuildingUserHistory({
            input: { id: userHistroyItems[9]?.dbIdentifier }
          })
          // adds the new item
          const newlyAddedRecentItem = await addBuidlingUserHistory({
            input: inputVariables
          })
          //@ts-ignore
          if (newlyAddedRecentItem.id) {
            //@ts-ignore
            const modifiedCurrentItem = {
              ...selectedItem,
              eTime: currentTime,
              selected: true,
              dbIdentifier: newlyAddedRecentItem.id
            }
            modifiedRecentItems[9] = modifiedCurrentItem
          }
        } else {
          const newlyAddedRecentItem = await addBuidlingUserHistory({
            input: inputVariables
          })
          //@ts-ignore
          if (newlyAddedRecentItem.id) {
            //@ts-ignore
            const modifiedCurrentItem = {
              ...selectedItem,
              eTime: currentTime,
              selected: true,
              dbIdentifier: newlyAddedRecentItem.id
            }
            modifiedRecentItems.push(modifiedCurrentItem)
          }
        }
      }

      const sortedArray = modifiedRecentItems.sort((a, b) => {
        return moment(b.eTime).diff(a.eTime)
      })

      dispatch(
        setRecentSearchedItems({
          type: 'Success',
          data: sortedArray
        })
      )
    } catch (error) {}
  }

  const filteredRecentItems = useMemo(() => {
    //@ts-ignore
    return (
      recentSearchItems?.data
        //@ts-ignore
        ?.filter((x) => groupByTag?.includes(x?.type))
        ?.filter((item: any) => {
          if (!searchText) return item
          const searchKeys =
            item.type === LOCATION ? ['primary', 'secondary'] : ['primary']
          const isMatched = searchObject(
            searchKeys,
            searchText,
            OPERATORS_ENUM.AND,
            1
          )(item)
          if (isMatched) return item
        })
    )
  }, [recentSearchItems, searchText, groupByTag])

  const isLoading = isHistoryLoading || loading

  const fetchSession = async () => {
    const session: any = await getSession()
    setSession(session.idToken.payload)
  }

  const setBaseBuildingsData = async () => {
    await fetchBuildings({
      filter: {
        and: [{ isActive: { eq: 1 } }]
      }
    }).then((res) => {
      try {
        let finalData = []
        if (groupByTag.includes(ORGANIZATION)) {
          const accountsData = accountMapping(res, showLocations)
          finalData = Object.values(accountsData)
        }
        const { buildingId, organizationId, salesOfficeId } = getSearchParams()
        if (!buildingId && !organizationId && !salesOfficeId) {
          handleSelectItemClick(
            finalData?.[0]?.['buildings']?.[0],
            recentSearchItems.data || []
          )
        } else {
          setSelectedItemURLFromStore(groupByTag, recentSearchItems.data || [])
        }
      } catch (e) {
        console.error(e)
      }
    })
  }

  useEffect(() => {
    fetchSession()
  }, [])

  useEffect(() => {
    handleBodyFilterChange(searchText)
  }, [showDisabledBuildings])

  const firstUserHistorySet = async () => {
    if (
      isUserHistoryFetched &&
      Object.keys(selected).length === 0 &&
      buildings?.length > 0 &&
      recentSearchItems?.data?.length === 0 &&
      groupByTag
    ) {
      const { buildingId, organizationId, salesOfficeId } = getSearchParams()
      if (!buildingId && !organizationId && !salesOfficeId) {
        handleSelectItemClick(
          buildings?.[0]?.['buildings']?.[0],
          recentSearchItems.data
        )
      } else {
        setSelectedItemURLFromStore(groupByTag, recentSearchItems.data)
      }
    }
  }

  useEffect(() => {
    firstUserHistorySet()
  }, [recentSearchItems, isUserHistoryFetched])

  const getSelectBody = () => {
    return (
      <div className="bn-body">
        {/* {fetchingToken && (
            <TokenInfoContainer>Authorization In-Progress...</TokenInfoContainer>
        )} */}
        {tokenFetchError !== '' && (
          <TokenInfoContainer error={true}>
            {tokenFetchError}
          </TokenInfoContainer>
        )}
        <BodyFilter
          searchText={searchText}
          handleChange={handleBodyFilterChange}
        />
        {allowDisabledLocations && (
          <BodyShowDisabledLocations
            isChecked={showDisabledBuildings}
            onChange={onChangeShowDisabledLocations}
          />
        )}

        {filteredRecentItems?.length > 0 && (
          <RecentSearchedItems
            loading={recentSearchItems?.loading}
            items={filteredRecentItems}
            searchText={searchText}
            onClick={handleBodyItemsClick()}
          />
        )}
        <TreeView
          loading={isLoading}
          items={buildings}
          searchText={searchText}
          onClick={handleBodyItemsClick()}
          // selectedItemIndex={bodyItemsScrollTo.current}
          // scrollTo={activeLetter}
          isMobile={false}
          allowDisabledLocations={allowDisabledLocations}
        />
      </div>
    )
  }

  return (
    <>
      {
        // Since building navigator is Class based component Navigate hooks won't work
        // We have two options to redirect with class in react-router-dom
        // 1. Create a HOC and pass class component
        // 2. This is another option , works better
        redirect ? (
          <Navigate to="/resource-not-found" />
        ) : (
          isShowBuildingPicker && (
            <NestedSelect
              missingRecent={Boolean(!recentSearchItems?.data?.length)}
              missingType={groupByTag}
              loading={isLoading}
              selected={selected}
              wrapperRef={wrapperRef}
              open={open}
              toggleOpen={toggleOpen}
              type={'large'}
            >
              {getSelectBody()}
            </NestedSelect>
          )
        )
      }
    </>
  )
}

export default BuildingNavigation
