import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { PROPERTIES_TO_SEND_BY_EVENT } from "src/amplitude-categories"
import store from "src/redux/store.ts"
import { amplitudeKey } from "./aws-exports";
import { getSearchParams } from 'src/common/helperFunctions.js'

const API_KEY = amplitudeKey // Changed in aws-exports files respectively, not currently setup for test (same as dev)

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin();
amplitude.add(sessionReplayTracking);

const identify = new amplitude.Identify()

amplitude.init(API_KEY, null, {
// optional configuration options
    "defaultTracking":"true",
    "config.autocapture.attribution":"false",
    //config.minIdLength -> Default 5 characters for identifiers such as deviceId and userId, can change here
});
const identifyEvent = (properties) =>
{
    const userDataToSend = {
        "user id": store?.getState()?.appData?.userInfo?.data?.id,
        "user type": store?.getState()?.appData?.userInfo?.data?.type?.name,
        "role names": store?.getState()?.appData?.roles,
        "client id": "Trane Connect",
        "job title": store?.getState()?.appData?.userInfo?.data?.jobTitle,
        "primary sales office" : store?.getState()?.appData?.userInfo?.data?.primarySalesOfficeId,
        "version":process.env?.REACT_APP_AWS_COMMIT_ID ? process.env?.REACT_APP_AWS_COMMIT_ID.substring(0, 7): '',
    }
    Object.entries(properties ? properties : userDataToSend).forEach(([key, value]) => {
        identify.set(key, value);
    })
    amplitude.identify(identify)
    amplitude.setUserId(store?.getState()?.appData?.userInfo?.data?.id)
}

const trackEvent = (event, options = {}) => {
    const { orgName } = options
    delete options?.orgName
    const buildingType = store?.getState()?.buildingPicker?.selectedBuilding?.type
    let accName = ''
    switch(buildingType) {
        case "organization":
            accName = store?.getState()?.buildingPicker?.selectedBuilding?.primary
            break
        case "location":
            accName = orgName || ''
    }
    const {
        buildingId,
        organizationId
      } = getSearchParams()
    const userDataToSend = {
        "building id": buildingId ? buildingId : "",
        //Potential to have issues due to state if links change after changing building
        "building name": store?.getState()?.buildingPicker?.selectedBuilding?.type === "location"  ? store?.getState()?.buildingPicker?.selectedBuilding?.primary : "", 
        "organization name": accName,
        "organization id": organizationId ? organizationId : "",
        "client id": "Trane Connect",
    }  
    const mergedProperties = {...PROPERTIES_TO_SEND_BY_EVENT[event] || {}, ...userDataToSend}
    amplitude.track(event, {
        ...Object
            .keys(mergedProperties || {})
            .reduce((result, key) => typeof mergedProperties[key] === "function"
                ? { ...result, [key]: mergedProperties[key]() }
                : { ...result, [key]: mergedProperties[key] },
                {}),
        ...options
    })
}

export { amplitude, trackEvent, identifyEvent };