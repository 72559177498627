import moment from 'moment'

// Project imports
import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { TranslateComponent } from 'src/common/translations'

// Denali imports.
import { faBuilding } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Chip } from 'src/denali-components/Table/Chip'
import { IconCell } from 'src/denali-components/Table/IconCell'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import translate from 'src/common/translations'

// Local imports.
import styles from './event-markers.module.scss'
import tableStyles from 'src/denali-components/Table/table.module.scss'

export const EventMarkersList = ({
  rows,
  rowControl,
  filtersList,
  showSpinner,
  optionsTranslated,
  listDataIsLoading
}) => (
  <>
    <ClientSideTable
      rows={rows}
      tableClassName={tableStyles.eventMarkersPage}
      header={getHeadersWithRowRenderOverride(optionsTranslated)}
      search={true}
      searchFields={['name', 'buildingNames']}
      rowControl={rowControl}
      filtersList={filtersList}
      isDenaliTable={true}
      testName="eventMarkersPage"
      showSpinner={showSpinner}
      listDataIsLoading={listDataIsLoading}
      noTableDataTitleText={translate('No events markers found')}
    />
  </>
)

const getHeadersWithRowRenderOverride = (optionsTranslated) => {
  return [
    {
      title: optionsTranslated.name,
      key: 'name',
      width: '100%',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.date,
      key: 'markerDate',
      width: '120px',
      customComponent: dateCell
    },
    {
      title: optionsTranslated.type,
      key: 'type',
      width: '120px',
      customComponent: typeCell
    }
  ]
}

const titleCell = (row) => (
  <IconCell iconType={row.typeIcon} className={styles.titleCentered}>
    <div className={styles.titleContainer}>
      <div className={styles.title}>
        <TranslateComponent>{row.name}</TranslateComponent>
      </div>
      {row.descriptionIcon}
    </div>
    <Tooltip>
      <TooltipTrigger>
        <Chip text={row.buildingNames} icon={faBuilding} />
      </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>Building</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  </IconCell>
)

const dateCell = (row) => (
  <div className={styles.dateContainer}>
    <p className={styles.date}>
      <TranslateComponent>Marker Date</TranslateComponent>
    </p>
    {moment(row.markerDate).format('LL')}
  </div>
)

const typeCell = (row) => (
  <div className={styles.typeContainer}>
    <p className={styles.type}>
      <TranslateComponent>Marker Type</TranslateComponent>
    </p>
    <span>
      <TranslateComponent>{row.type}</TranslateComponent>
    </span>
  </div>
)
