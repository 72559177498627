import { useFormContext } from 'react-hook-form'
import { LoaderOverlay } from '../Loaders/LoaderOverlay'

export const DenaliFormLoader = () => {
  const {
    formState: { isSubmitting }
  } = useFormContext()

  return <LoaderOverlay isLoading={isSubmitting} />
}
