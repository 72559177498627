import { Flex, Input, Label } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, memo, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  LazyLoadComponent,
  trackWindowScroll,
  type LazyComponentProps
} from 'react-lazy-load-image-component'

// Project imports
import { TranslateComponent } from 'src/common/translations'
import { handleEquipmentChangeSelect } from 'src/pages/issuesFindings/quickAdd/helpers'

// Denali imports
import {
  faMagnifyingGlass,
  faRotateRight
} from 'src/denali-components/lib/pro-solid-svg-icons'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { TisObject } from 'src/denali-pages/IssuesAndFindings/types'
import {
  getColor as getRandomColor,
  randomizeString
} from 'src/denali-components/helpers'

// Local imports
import { CardCheckbox } from './CardCheckbox'
import { useInlineEquipmentSelectorContext } from './hooks/use-inline-equipment-selector-context'
import { DenaliLoader } from '../Loaders/DenaliLoader'

type InlineEquipmentSelectorProps = LazyComponentProps & {
  value: TisObject[]
  onChange?: (...event: any[]) => void
}

const InlineEquipmentSelectorComponent = ({
  value,
  onChange,
  scrollPosition
}: InlineEquipmentSelectorProps) => {
  const {
    equipments,
    isEquipmentLoading,
    resetSearch,
    searchTerm,
    setSearchTerm,
    activeScroll,
    setActiveScroll,
    equipmentGroup
  } = useInlineEquipmentSelectorContext()

  const { getValues } = useFormContext()

  // Get the selected equipments from the tisObjects.
  const selectedEquipments = useMemo(() => {
    return equipments.filter((equipment) =>
      value.some((tisObject) => tisObject.tisObjectId === equipment.key)
    )
  }, [equipments, value])

  const selectedEquipmentIds = useMemo(() => {
    return new Set(selectedEquipments.map((items) => items.key))
  }, [selectedEquipments])

  const filteredEquipment = useMemo(() => {
    return equipments.filter((equipment) =>
      equipment.value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [equipments, searchTerm])

  const iconColors = useMemo(() => {
    return filteredEquipment?.map((equipment) =>
      getRandomColor(randomizeString(equipment.value))
    )
  }, [filteredEquipment])

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const values = getValues()
      const equipmentId = event.target.value
      const selectedEquipment = !checked
        ? equipments.filter((x) => x.key === equipmentId)
        : []

      // To get our existing itmes, checked is true we are adding an item so just keep
      // value, otherwise we want to keep the items that are not the one we are adding
      const existingItems = !checked
        ? equipments.filter((equipment) =>
            value.some((tisObject) => tisObject.tisObjectId === equipment.key)
          )
        : equipments.filter((equipment) =>
            value.some(
              (tisObject) =>
                tisObject.tisObjectId === equipment.key &&
                equipment.key !== equipmentId
            )
          )

      const newEquipmentList = [...selectedEquipment, ...existingItems]

      const selectedTisObjects = handleEquipmentChangeSelect({
        value: newEquipmentList,
        values,
        tisObjectForSelector: values.tisObjects,
        equipments,
        useClearAll: false
      })

      onChange(selectedTisObjects)
    },
    [onChange, getValues, value, equipments]
  )

  const handleScroll = useCallback(() => {
    handleInnerScrollFade(equipmentGroup, setActiveScroll)
  }, [equipmentGroup, setActiveScroll])

  return (
    <Flex
      direction="column"
      data-testid="inlineEquipmentSelector"
      className={formStyles.inlineEquipmentSelector}
    >
      <Label htmlFor="searchEquipment" position="relative">
        <TranslateComponent>Select an Equipment</TranslateComponent>
        <div className={formStyles.searchInner}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <Input
            id="searchEquipment"
            placeholder="Search..."
            aria-description="Search Equipment"
            type="search"
            className={formStyles.equipmentSearch}
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value.toLowerCase())
            }}
          />
        </div>
      </Label>
      {activeScroll && filteredEquipment.length > 0 && (
        <div className={formStyles.scrollRightOverlay}></div>
      )}
      {isEquipmentLoading && (
        <Flex alignItems="center" justifyContent="center" flex="1">
          <DenaliLoader />
        </Flex>
      )}
      {equipments.length === 0 && !isEquipmentLoading && (
        <Flex>
          <button
            className={formStyles.noBuildingResults}
            onClick={resetSearch}
          >
            <TranslateComponent>No Equipment found</TranslateComponent>
            <div className={formStyles.noBuildingsSubText}>
              <FontAwesomeIcon icon={faRotateRight}></FontAwesomeIcon>
              <TranslateComponent>Reset Filters</TranslateComponent>
            </div>
          </button>
        </Flex>
      )}
      {equipments.length > 0 && !isEquipmentLoading && (
        <Flex
          className={formStyles.horizontalWrapper}
          ref={equipmentGroup}
          onScroll={handleScroll}
        >
          <div className={formStyles.cardCheckboxWrapper}>
            {filteredEquipment.map((equipment, index) => {
              const iconColor = iconColors[index]

              return (
                <LazyLoadComponent
                  key={equipment.key}
                  scrollPosition={scrollPosition}
                >
                  <CardCheckbox
                    onChange={onChangeHandler}
                    name={equipment.value}
                    value={equipment.key}
                    checked={selectedEquipmentIds.has(equipment.key)}
                    color={iconColor}
                  />
                </LazyLoadComponent>
              )
            })}
          </div>
        </Flex>
      )}
    </Flex>
  )
}

export const InlineEquipmentSelector = memo(
  trackWindowScroll(InlineEquipmentSelectorComponent)
)

function handleInnerScrollFade(equipmentGroup, setActiveScroll) {
  const optionsWrapper = equipmentGroup?.current
  const optionsList = optionsWrapper?.firstElementChild
  const optionsRight = optionsList?.clientWidth - optionsWrapper?.scrollLeft

  setActiveScroll(optionsRight - 10 >= optionsWrapper?.clientWidth)
}
