import { Formik } from 'formik'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _uniq from 'lodash/uniq'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

// Denali imports
import { ManageIssuesAndFindingsForm } from 'src/denali-pages/IssuesAndFindings/ManageIssuesAndFindingsForm'

// Project imports
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { 
  ATTACHMENT_CATEGORIES, 
  getSearchParams, 
  handleSplitingElements, 
  updateFileNameWithEpoch
} from 'src/common/helperFunctions.js'
import {
  CREATE_ATTACHMENT,
  CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT,
  UPDATE_ATTACHMENT
} from 'src/common/queries/attachment'
import { ACTIONS } from 'src/constants'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useAttachment } from 'src/hooks/attachment'
import Findings from 'src/models/findings'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { selectUserInfo, newRefreshToken } from 'src/redux/slicers/appData'
import { getUserName } from 'src/utils/commonMethods'

// Legacy imports
import {
  getField,
  setField,
  isDateFormatCorrect
} from 'src/components/legacy/common/helpers'
import {
  MAX_LENGTH_OF_NAME,
  KPI_SPECIFIC_VALUE
} from 'src/components/legacy/common/opportunity.js'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import {
  isValidName,
  valueAssumptionValidationMessages
} from 'src/components/legacy/common/validators'

// Local imports
import FindingEditForm from './FindingEditForm'
import {
  CREATE_FINDING_QUICK_ADD,
  UPDATE_FINDING,
  DELETE_EQUIPMENT_ISSUE,
  CREATE_BULK_EQUIPMENT_ISSUES,
  GET_ANALYTIC_SERVICEADVISORY,
  GET_BUILDING_DETAILS
} from 'src/pages/issuesFindings/graphql'

import {
  decodeURLSearchParams,
  frameMutationInput,
  getEquipmentsForUpsert,
  getFileForUpsert,
  getImagesForUpsert
} from 'src/pages/issuesFindings/helpers'

type FindingEditType = {
  mode?: string
  finding?: object
}
const CategoryType = {
  File: 'File',
  Image: 'Image'
}

const FindingEdit = ({ mode = ACTIONS.ADD, finding }: FindingEditType) => {
  const { state } = useLocation()
  const [t] = useTranslation()
  const {buildingId: locationId, organizationId, equipment, sid, chartLink: chartLinkParam, chartImage, chartTitle} = getSearchParams()
  const equipmentList = _uniq(decodeURLSearchParams(equipment ?? null))
  const serviceAdvisoryId = sid || ""
  const chartLink = chartLinkParam || ""
  const [chartImages,setChartImages] = useState([])
  const [chartImagesToLoad,setChartImagesToLoad] = useState([])
  const chartImageDetails = decodeURLSearchParams(chartImage ?? null)
  const chartTiles = decodeURLSearchParams(chartTitle ?? null)
  const [settingsLoaded, setSettingsLoaded] = useState(serviceAdvisoryId ? false : true)
  const [settingsData, setSettingsData] = useState({
    descriptionLink: "",
  })
  const userInfo = useSelector(selectUserInfo)
  const [error, setError] = useState(null)
  const [buildingDetails, setBuildingDetails] = useState(null)
  const [upsertIssueId, setUpsertIssueId] = useState(null)
  const [, setExistingEquipments] = useState([])
  const navigate = useNavigate()
  const { downloadFileURL, copyFileFunc } = useAttachment()
  const uiMode = useSelector(selectUiMode)
  const dispatch = useDispatch() 

  const {
    data: getBuildingDetails,
    refetch: refetchGetBuildingDetails
  } = useQuery(
    {
      query: GET_BUILDING_DETAILS,
      dataPath: 'data.getBuilding',
      variables: { buildingId: locationId },
      disableInitialLoad: true
    }
  )
  useEffect(() => {
    if(getBuildingDetails){
      setBuildingDetails({ locationName: getBuildingDetails?.name, organizationName: getBuildingDetails?.accountName })
    }
  }, [getBuildingDetails])
  

  // This function takes in an "image" object and downloads the file URL from the server
  // and then mutates the image object to include the original and imageUrl properties
  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId, true)
      .then((res) => {
        item.original = res
        item.imageUrl = res
      })
      .catch((err) => {
        console.log('error', err)
      })
    }
    return item
  }

  // This useEffect fetches the building details and then uses the chartImageDetails and chartTiles to create a list of image objects
  // that will be used to display the images in the UI
  useEffect(() => {
    const imageDetail = []
    refetchGetBuildingDetails()
    chartImageDetails?.forEach((imageUrl,index) => {
        const imageSegments = imageUrl?.replace("''", "").split("/") ?? []
        const imgName = imageSegments[imageSegments?.length - 1]
        const imgTitle = chartTiles[index] ?? ''
        const imgDetail = {
          attachmentOrder:index,
          caption:'',
          displayName:imgName,
          imageId:`${imgName}#${locationId}`,
          imageUrl:'',
          name:imgName,
          original:'',
          title: imgTitle,
          buildingId:locationId
        }
        imageDetail.push(imgDetail)
      });
      setChartImages(imageDetail)
  }, [locationId, organizationId, equipment, sid, chartLinkParam, chartImage, chartTitle])

  // This function takes in a list of images and fetches the file URL for each image
  // and then mutates the image object to include the original and imageUrl properties
  const fetchImageData = async (downloadData) => {
    const DownloadDetail = downloadData
    const imagestoLoad = []
    for (const image of DownloadDetail) {
      let img = image
      if (!image?.original) {
        img = await fetchImage(image)
        imagestoLoad.push(img)
      }
    }
    setChartImagesToLoad(imagestoLoad)
  }

  // If the list of chart images changes, this useEffect fetches the file URL for each image
  // and then mutates the image object to include the original and imageUrl properties
  useEffect(() => {
    if (chartImages?.length) {
      fetchImageData(chartImages)
    }
  }, [chartImages])
  
  const titleChartDesc = chartTiles?.length ? chartTiles[0] : ''
  const equipmentData = equipmentList ? equipmentList?.map(equipment => ({ tisObjectId: equipment })) : []

  const {
    data: getAnalyticServiceAdvisory,
    refetch: refetchServiceAdvisoryDetails
  } = useQuery(
    {
      query: GET_ANALYTIC_SERVICEADVISORY,
      dataPath: 'data.getAnalyticServiceAdvisory',
      variables: { serviceAdvisoryId: serviceAdvisoryId },
      disableInitialLoad: true
    }
  )

  // If the service advisory details change, this useEffect updates the settings data
  useEffect(() => {
    if (getAnalyticServiceAdvisory) {
      setSettingsData(getSettings())
      setSettingsLoaded(true)
    }
  }, [getAnalyticServiceAdvisory])

  const createKPI = (name, priority, value, custom = null) => {
    return {
      custom,
      name,
      priority,
      value,
    }
  }
  const formatSuggestions = () => {

    if (getAnalyticServiceAdvisory) {
      const { exceptionName = "", suggestion = "", impactComfort = "", impactEnergy = "", impactReliability = "" } = getAnalyticServiceAdvisory
      const parsedSuggestion = suggestion && JSON.parse(suggestion.suggestion)
      const mostLikelyCauses = parsedSuggestion ? parsedSuggestion.causes.map(({ name }) => (`• ${name}`)).join("\n") : ""

      return {
        suggestions: (parsedSuggestion ? `${t("opportunities:Suggestions")}:\n${exceptionName}\n${parsedSuggestion.description}\n\n${t("building-evaluation:MostLikelyCauses")}\n${mostLikelyCauses}` : ""),
        comfort: impactComfort,
        energy: impactEnergy,
        reliability: impactReliability,
        description: parsedSuggestion ? parsedSuggestion.description : ""
      }
    }

    return {
      suggestions: "",
      comfort: "",
      energy: "",
      reliability: "",
      description: ""
    }
  }

  const getSettings = () => {
    const { suggestions, comfort, energy, reliability, description } = formatSuggestions()
    return {
      ...(suggestions && { description: suggestions }),
      ...({ descriptionLink: chartTiles?.length ? titleChartDesc : description ? description : '' }),
      ...(chartLink && { chartLink }),
      ...(comfort || reliability || energy)
      && {
        kpi: [
          createKPI("comfort", comfort && comfort != "none" ? comfort : "low", comfort && comfort != "none" || false),
          createKPI("reliability", reliability && reliability != "none" ? reliability : "low", reliability && reliability != "none" || false),
          createKPI("performance", "low", false),
          createKPI("energyUsage", energy && energy != "none" ? energy : "low", energy && energy != "none" || false),
          createKPI("compliance", "low", false),
          createKPI("", "low", false, 1),
          createKPI("", "low", false, 2)
        ]
      },
      ...({images:chartImagesToLoad})
    }
  }

  useEffect(() => {
    if (serviceAdvisoryId) {
      refetchServiceAdvisoryDetails( { serviceAdvisoryId })
    }
  }, [serviceAdvisoryId])

  const { onSubmit: createFindingQuickAddMutation } = useMutation({
    query: mode === ACTIONS.EDIT ? UPDATE_FINDING : CREATE_FINDING_QUICK_ADD,
    onError: (error) => {
      setError(error)
    }
  })

  const { onSubmit: createBulkEquipmentOpportunity } = useMutation({
    query: CREATE_BULK_EQUIPMENT_ISSUES,
    onError: (error) => {
      setError(error)
    }
  })

  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: deleteEquipmentIssue } = useMutation({
    query: DELETE_EQUIPMENT_ISSUE,
    onError: (error) => {
      setError(error)
    }
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })

  const validateForm = (values) => {
    const errors: any = {}
    const organization = getField(values, 'organization.organizationId')
    const location = getField(values, 'location.locationId')
    const kpiErrors = []
    if (
      !values.name ||
      typeof values?.name !== 'string' ||
      values.name?.length === 0 ||
      (values.name?.length > 0 && values.name.trim().length === 0)
    ) {
      errors.name = "The name is required."
    }

    if (values.name.length > MAX_LENGTH_OF_NAME) {
      errors.name = (
        <Trans
          i18nKey={'errors:TooLongName'}
          values={{ number: MAX_LENGTH_OF_NAME }}
        />
      )
    }

    // Check dates formatting
    const isTargetDateCorrect = isDateFormatCorrect(getField(values, 'settings.targetDate'), DATE_FORMAT)
    const isProposalDateCorrect = isDateFormatCorrect(getField(values, 'settings.proposalDate'), DATE_FORMAT)

    if (!isTargetDateCorrect) {
      setField(
        errors,
        'settings.targetDate',
        `Please enter a valid date in the format ${DATE_FORMAT}`
      )
    }

    if(!isProposalDateCorrect){
      setField(
        errors,
        'settings.proposalDate',
        `Please enter a valid date in the format ${DATE_FORMAT}`
      )
    }

    values.settings.kpi
      .filter((kpi) => kpi.value)
      .forEach((kpi) => {
        const errorsToAdd: any = {}
        if (!isValidName(kpi.name)) {
          errorsToAdd.missedName = "The name is required."
        }
        if (kpi.priority === KPI_SPECIFIC_VALUE) {
          const validationError = valueAssumptionValidationMessages(
            kpi.savings,
            { allowEmpty: false }
          )
          if (validationError) {
            errorsToAdd.specificValueError = validationError
          }
        }
        Object.keys(errorsToAdd).length &&
          kpiErrors.push({ ...kpi, ...errorsToAdd })
      })

    // If there are any kpi errors, this useEffect adds them to the errors object
    kpiErrors.length && setField(errors, 'settings.kpi', kpiErrors)

    // If the location is not set, this useEffect adds an error to the errors object
    if (!location) {
      errors.location = "Building is required."
    }

    // If the organization is not set, this useEffect adds an error to the errors object
    if (!organization) {
      errors.organization ="Organization is required."
    }

    return errors
  }

  let findingValues = new Findings({
    name: state?.quickAddMode ? state?.name : serviceAdvisoryId ? settingsData?.descriptionLink : chartTiles?.length ? titleChartDesc : '',
    location: state?.location,
    tisObjects: state?.quickAddMode ? state?.tisObjects : equipmentData,
    comment: state?.comment,
    priority: state?.priority,
    status: state?.status,
    nextStep: state?.nextStep,
    foundDuring: state?.foundDuring,
    organizationId,
    ...(buildingDetails?.organizationName ?{organizationName:buildingDetails?.organizationName}:""),
    locationId,
    ...(buildingDetails?.locationName ?{locationName:buildingDetails?.locationName}:""),
    settings: getSettings(),
    proposedBy: getUserName(userInfo),
    proposalDate:  moment().format(DATE_FORMAT)
  })


  if (
    mode &&
    (mode === ACTIONS.EDIT || mode === ACTIONS.COPY) &&
    !_isEmpty(finding['name'])
  ) {
    findingValues = _cloneDeep(finding)
  }

  const tisObjectValue = finding && finding['tisObjects'] || []

  useEffect(() => {
    if (mode === ACTIONS.EDIT || mode === ACTIONS.COPY) {
      setExistingEquipments(
        finding['tisObjects']?.map((tisObject) => tisObject.tisObjectId)
      )
    }
  }, [tisObjectValue])

  useEffect(() => {
    if (!upsertIssueId) return
    // Navigate To Finding View Page
    navigate(`/${PAGE_NAMES.FINDINGS}/${upsertIssueId}`, {
      state: { mode: ACTIONS.VIEW }
    })
  }, [upsertIssueId])

  const handleCreateEquipment = async (issueID, newEquipmentList, accountId, locationId) => {
    let updatedEquipmentList = newEquipmentList

    // Check if any equipment is present as part CC4 redirected URL
    if (serviceAdvisoryId && equipmentData.length > 0) {
      updatedEquipmentList = newEquipmentList.length > 0 ? [...equipmentData, ...newEquipmentList] : [...equipmentData]
    }

    if (updatedEquipmentList?.length > 0) {
      const equipmentsList = handleSplitingElements(updatedEquipmentList, 100)
      await Promise.all(
        equipmentsList?.map(item =>
          createBulkEquipmentOpportunity({
            input: JSON.stringify({
              equipmentList: item?.map((e) => e?.tisObjectId),
              opportunityList: [],
              modelType: 'Issue',
              modelId: issueID,
              action: 'Create',
              accountId,
              buildingId: locationId
            })
          })
        ))
    }
  }

  const handleDeleteEquipment = async (issueID, deleteEquipmentList, accountId, locationId) => {
    if (deleteEquipmentList?.length > 0) {
      const equipmentsList = handleSplitingElements(deleteEquipmentList, 200)
      await Promise.all(
        equipmentsList?.map(item =>
          createBulkEquipmentOpportunity({
            input: JSON.stringify({
              equipmentList: item?.map((e) => e?.tisObjectId),
              opportunityList: [],
              modelType: 'Issue',
              modelId: issueID,
              action: 'Delete',
              accountId,
              buildingId: locationId
            })
          })
        ))
    }
  }

  const handleCreateAttachment = async (locationId, issueID, newList, isBuildingChangedInEditMode) => {
    for (const image of newList) {
      if (image?.name || (!image?.name && (image?.title || image?.caption))) {
        if(isBuildingChangedInEditMode || (mode === ACTIONS.COPY && image?.buildingId)){      
          await copyFileFunc(image?.name, image?.buildingId, locationId, image?.fileName)
        }
        await createAttachmentMutation({
          input: {
            buildingId: locationId,
            description: image?.caption ? image?.caption : '',
            name: image?.fileName ?? image?.name,
            issueId: issueID,
            sizeInBytes: image?.size,
            type: image?.type,
            category:
              image?.categoryType && image?.categoryType === CategoryType.File
                ? ATTACHMENT_CATEGORIES.ADD_FILES
                : ATTACHMENT_CATEGORIES.ADD_IMAGES,
            title: image?.title ? image?.title : '',
            displayName: image?.displayName,
            attachmentOrder: image?.attachmentOrder
          }
        })
      }
    }
  }
  const handleUpdateAttachment = async (updateList) => {
    for (const image of updateList) {
      if (image?.originalImageId) {
        await updateAttachmentMutation({
          input: {
            id: image?.originalImageId,
            description: image?.caption,
            title: image?.title,
            name: image?.categoryType === CategoryType.File ? image?.name : image?.original && image?.imageUrl ? image?.name : "",
            attachmentOrder: image?.attachmentOrder
          }
        })
      }
    }
  }
  const handleDeleteAttachment = async (deleteList) => {
    for (const image of deleteList) {
      if (image?.imageId) {
        await deleteAttachmentMutation({
          input: {
            id: image?.imageId
          }
        })
      }
    }
  }
  const handleS3CreateAttachment = async (attachmentBody) => {
    await createEntryWithAttachmentMutation({
      input: JSON.stringify(attachmentBody)
    })
  }
  const handleUpsertIssue = async (
    input,
    values,
    equipmentsList,
    fileUpsertDetails,
    imageUpsertDetails
  ) => {
    let issueID = null
    const oldLocationId = mode === ACTIONS.ADD ? locationId : finding?.["location"]?.locationId
    const oldOrgId = mode === ACTIONS.ADD ? organizationId : finding?.["organization"]?.organizationId
    const bIds = [...new Set([oldLocationId, values?.location?.locationId]?.filter(n => n))]
    const oIds = [...new Set([oldOrgId, values?.organization?.organizationId]?.filter(n => n))]
    await dispatch<any>(newRefreshToken(bIds, oIds, null, ["OpportunityAdmin", "AttachmentAdmin", "IssueAdmin"])).then(async () => {
      await createFindingQuickAddMutation({ input }).then(async (res) => {
        if (mode === ACTIONS.EDIT) {
          trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.SAVE_FINDING, {
            mode: "EDIT",
            "finding id": res.data?.updateIssue?.id,
            "organization id": values?.organization?.organizationId,
            "building id": values?.location?.locationId
          })
        } else if (mode === ACTIONS.COPY) {
          trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.SAVE_FINDING, {
            mode: "COPY",
            "finding id": res.data?.createIssue?.id,
            "organization id": values?.organization?.organizationId,
            "building id": values?.location?.locationId
          })
        } else {
          trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.SAVE_FINDING, {
            mode: "CREATE",
            "finding id": res.data?.createIssue?.id,
            "organization id": values?.organization?.organizationId,
            "building id": values?.location?.locationId,
            "building name": values?.location?.locationName,
            "organization name": values?.organization?.organizationName,
          })
        }
        issueID =
          mode === ACTIONS.EDIT
            ? res.data?.updateIssue?.id
            : res.data?.createIssue?.id
        const locationId = values?.location?.locationId
        const accountId = values?.organization?.organizationId
        // Adding Issue Equipments
        await handleCreateEquipment(issueID, equipmentsList?.newEquipmentList, accountId, locationId)

        // Delete Issue Equipments
        await handleDeleteEquipment(issueID, equipmentsList?.deleteEquipmentList, accountId, locationId)

        // Add File
        const newFileList = []
        const isBuildingChangedInEditMode = mode === ACTIONS.EDIT && oldLocationId !== locationId
        if (fileUpsertDetails.newFile && fileUpsertDetails.newFile?.name) {
          newFileList.push({
            caption: fileUpsertDetails.newFile?.description ?? '',
            name: fileUpsertDetails.newFile?.name,
            fileName: mode === ACTIONS.COPY || isBuildingChangedInEditMode ? updateFileNameWithEpoch(fileUpsertDetails?.newFile?.displayName) : fileUpsertDetails.newFile?.name,
            size: fileUpsertDetails.newFile?.sizeInBytes,
            type: fileUpsertDetails.newFile?.type,
            categoryType: CategoryType.File,
            title: fileUpsertDetails.newFile?.name,
            displayName: fileUpsertDetails.newFile?.displayName,
            buildingId: fileUpsertDetails.newFile?.buildingId
          })
        }
        // Delete File
        const deleteFileList = []
        if (fileUpsertDetails?.deleteFile && fileUpsertDetails.deleteFile?.name) {
          if (fileUpsertDetails.deleteFile?.id) {
            deleteFileList.push({
              imageId: fileUpsertDetails.deleteFile?.id,
              originalImageId: fileUpsertDetails.deleteFile?.id,
              name: fileUpsertDetails.deleteFile?.name,
              fileName: fileUpsertDetails.deleteFile?.name,
              buildingId: fileUpsertDetails.deleteFile?.buildingId
            })
          }
        }

        // Update File
        const updateFileList = []
        if (fileUpsertDetails?.updateFile && fileUpsertDetails.updateFile?.name) {
          if (fileUpsertDetails.updateFile?.id) {
            updateFileList.push({
              originalImageId: fileUpsertDetails.updateFile?.id,
              name: fileUpsertDetails.updateFile?.name,
              fileName: fileUpsertDetails.updateFile?.name,
              caption: fileUpsertDetails.updateFile?.description,
              categoryType: CategoryType.File,
              title: fileUpsertDetails.updateFile?.name,
              buildingId: fileUpsertDetails.updateFile?.buildingId
            })
          }
        }
        // Update Image List Along With File List
        imageUpsertDetails.newList = [...imageUpsertDetails?.newList, ...newFileList]
        imageUpsertDetails.updateList = [...imageUpsertDetails?.updateList, ...updateFileList]
        imageUpsertDetails.deleteList = [...imageUpsertDetails?.deleteList, ...deleteFileList]
        // Adding Images/File
        if (imageUpsertDetails?.newList) {
          await handleCreateAttachment(
            locationId,
            issueID,
            imageUpsertDetails?.newList,
            isBuildingChangedInEditMode
          )
        }

        // Update Images/Files
        if (imageUpsertDetails?.updateList) {
          await handleUpdateAttachment(imageUpsertDetails?.updateList)
        }

        // Delete Images/File
        if (imageUpsertDetails?.deleteList) {
          await handleDeleteAttachment(imageUpsertDetails?.deleteList)
        }

        // Upsert on S3 Bucket
        const createImages =
          imageUpsertDetails?.newList?.map((i) => ({
            buildingId: locationId,
            name: i?.fileName ?? i?.name
          })) ?? []
        const deleteImages =
          imageUpsertDetails?.deleteList?.map((i) => ({
            attachmentId: i?.originalImageId,
            buildingId: i?.buildingId ?? locationId,
            name: i?.fileName ?? i?.name
          })) ?? []
        const attachmentBody = {
          modelType: 'Issue',
          attachments: {}
        }

        if (createImages?.length)
          attachmentBody.attachments['create'] = createImages
        if (deleteImages?.length)
          attachmentBody.attachments['delete'] = deleteImages

        // Upsert on S3 bucket
        if (createImages?.length || deleteImages?.length) {
          await handleS3CreateAttachment(attachmentBody)
        }
      })
    })
    return issueID
  }

  const onSubmit = async (values, { resetForm }) => {
    const oldLocationId = mode === ACTIONS.ADD ? locationId : finding?.["location"]?.locationId
    const input = frameMutationInput(values, userInfo, mode)
    const equipmentsList = getEquipmentsForUpsert(
      findingValues,
      values?.tisObjects,
      mode ? mode : state?.mode
    )
    const imageUpsertDetails = getImagesForUpsert(
      chartImageDetails?.length ? [] : findingValues?.settings?.images,
      values?.settings?.images,
      mode,
      oldLocationId,
      values?.location?.locationId
    )

    const fileUpsertDetails = getFileForUpsert(
      findingValues?.settings?.file,
      values?.settings?.file,
      mode,
      oldLocationId,
      values?.location?.locationId
    )

    const issueId = await handleUpsertIssue(
      input,
      values,
      equipmentsList,
      fileUpsertDetails,
      imageUpsertDetails
    )
    resetForm()
    setUpsertIssueId(issueId)
  }
  
  return (
    <>
    {uiMode === UiMode.denali ? (
      <ManageIssuesAndFindingsForm initialValues={findingValues} onSubmit={onSubmit} />
    ) : (
    <Formik
      initialValues={findingValues}
      onSubmit={onSubmit}
      validate={validateForm}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {(props) => (
        <FindingEditForm
          {...props} 
          error={error} 
          setError={setError} 
          settingsLoaded={settingsLoaded}
          mode={mode}
        />
      )}
    </Formik>
    )}
      </>
  )
}

export default FindingEdit
