import { BuildingSelectorInput } from './BuildingSelectorInput'

export const NothingSelected = ({
  sidebarIsExpanded,
  orgRefs,
  orgGetReferenceProps
}) => {
  return (
    <div
      ref={orgRefs.setReference}
      {...orgGetReferenceProps()}
      data-testid="buildingSelectorEmptyInput"
    >
      <BuildingSelectorInput sidebarIsExpanded={sidebarIsExpanded} />
    </div>
  )
}
