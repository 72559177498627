import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './label.scss'
import { TranslateComponent } from 'src/common/translations'

const Label = ({ text, forElementId, disabled, customClass, className, isColonRequired=false, required=false, translateText = true }) => {
  const customProps = {}
  const classes = { 'custom-label--disabled': disabled }

  if (forElementId) {
    customProps.htmlFor = forElementId
    customProps.role = 'label'
  }

  return (
    <span
      className={classNames('custom-label', classes, customClass, className)} {...customProps}>
      {typeof text === 'object' ? <>{text}</> : translateText ? <TranslateComponent>{text}</TranslateComponent> : text}{text === "Filter" || Boolean(isColonRequired) ? ":" : ""}{required && "*"}
    </span>
  )
}

Label.propTypes = {
  forElementId: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  customClass: PropTypes.string
}

Label.defaultProps = {
  disabled: false,
  customClass: ''
}

export default Label
