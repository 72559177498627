import colors from 'src/components/legacy/common/colors.json'
import styled from 'styled-components'

export const PriorityWrapper = styled.div`
  .priority {
    border-radius: 6px;
    background: ${colors.WHITE};
    border: 1px solid ${colors.TBA_LIGHT_GREY};
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 20px 25px;
    position: relative;

    label {
      margin-bottom: 3px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 15px;
      margin-top: 0;
    }

    h3 {
      min-width: 200px;
      width: 100%;
      font-weight: bolder;
      font-size: 24px;
    }
    .color-area {
      height: 5px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0 0 6px 6px;
    }
    .PriorityText{
      line-height: 1.5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .PriorityText:hover{
      overflow: visible;
      cursor:pointer
    }
  }
`
