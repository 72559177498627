import {
  VimeoEmbed,
  VimeoEmbedStyles
} from 'src/denali-components/Media/VimeoEmbed'
import styles from './card-highlight-actions.module.scss'

export const CardHighlightActions = ({
  tags = null,
  title = null,
  subTitle = null,
  actions = null,
  backgroundImage = null,
  vimeoId = null,
  vimeoTitle = null,
  vimeoParams = ''
}) => {
  return (
    <section
      className={styles.cardHighlightActions}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      data-testid="cardHighlightActions"
    >
      {vimeoId && (
        <VimeoEmbed
          id="1007803081"
          title="Background design showcase"
          style={VimeoEmbedStyles.backgroundCoverLoop}
          urlParams={vimeoParams}
        />
      )}

      {(title || actions) && (
        <header>
          <div className={styles.backgroundFade}></div>
          <div className={styles.subTitle}>{subTitle}</div>
          <div className={styles.alignTitleAndActions}>
            <div className={styles.title}>{title}</div>
            <div className={styles.actions}>{actions}</div>
          </div>
        </header>
      )}
      <div className={styles.tags}>{tags}</div>
    </section>
  )
}
