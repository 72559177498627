import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { GET_UTILITY_ACCOUNT, GET_UTILITY_METERS, GET_UTILITY_STATEMENTS, GET_BUILDING, UPDATE_ACCOUNT_DISPLAY_NAME, UPDATE_METER_ASSOCIATION } from "./queries"
import { LIST_OFFERINGS_BY_ACCOUNT } from 'src/pages/contracts-and-offerings/queries'
import { Link, useNavigate, useParams } from "react-router-dom"
import Table from "src/components/Table/clientSideTable"
import RouterPrompt from "src/components/RouterPrompt"
import Modal from 'src/denali-ui/components/Modal'
import { NewStyles } from 'src/NewStyles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import Selectrix from 'src/denali-ui/components/Selectrix'
import { Button } from '@trane/trane-components/dist/simple/button/index.js'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { WarningContainer, BuildingLink } from 'src/common/featureRestrictionHOC'
import { TranslateComponent } from 'src/common/translations'
import Icon from 'src/denali-ui/components/Icon/index'
import colors from 'src/components/legacy/common/colors.json'
import Tooltip from "src/components/legacy/components/tooltip/tooltip"
import { accessControlFunc } from 'src/components/accessControl'
import { pages } from 'src/pages/pages.js'
import { isOfferingActive } from "../helpers"
import { Parent, ToolTipContainer, HeaderItem, TabsWrapper } from "./components.js"
import { DownloadStatementButton } from 'src/pages/organizationManagement/associateUtilityAccounts/components/utilityStatementDownload'
import { DATE_FORMAT, DATE_FORMAT_DASH1 } from 'src/components/legacy/common/time-helpers'
import moment from "moment"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const typeDisplayName = {
  "electric": "Electric",
  "natural_gas": "Natural Gas",
  "water": "Water",
  "lighting": "Lighting",
  "sewer": "Sewer",
}

export const AccountDetails = props => {
  const { id: utilityAccountId, orgId } = useParams()
  const [utilityMeterRowData, setUtilityMeterRowData] = useState([])
  const [utilityStatementRowData, setUtilityStatementRowData] = useState([])
  const [selectedMeterBuildings, setSelectedMeterBuildings] = useState({})
  const [buildingOptions, setBuildingOptions] = useState([])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [savedChanges, setSavedChanges] = useState(false)
  const [displayName, setDisplayName] = useState("")
  const [nextLocation, setNextLocation] = useState("")
  const navigate = useNavigate()
  const isEditUser = accessControlFunc({ id: "tc.pages.utility-account-details.edit" })

  const { data: accountData, error: accountError } = useQuery({
    query: GET_UTILITY_ACCOUNT,
    variables: { utilityAccountId },
    errorPolicy: "global",
    dataPath: "data.getUtilityAccount"
  })

  const { data: utilityMeters, refetch: fetchMeters } = useQuery({
    query: GET_UTILITY_METERS,
    variables: { utilityAccountId },
    disableInitialLoad: false,
    errorPolicy: "global",
    dataPath: 'data.listUtilityMeterByUtilityAccount'
  })

  const { data: utilityStatements, refetch: fetchUtilityStatements, error: statementsError } = useQuery({
    query: GET_UTILITY_STATEMENTS,
    disableInitialLoad: true,
    errorPolicy: "global",
    dataPath: 'data.listUtilityStatementByAccount'
  })

  const { data: organizationOfferingData = [], loading: loadingOfferings, refetch: fetchAccountOfferings, error: offeringsError } = useQuery({
    query: LIST_OFFERINGS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listOfferingsByAccount.items'
  })

  const { data: buildingData = [], refetch: fetchBuilding } = useQuery({
    query: GET_BUILDING,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getBuilding'
  })

  const { onSubmit: updateAccount } = useMutation({
    query: UPDATE_ACCOUNT_DISPLAY_NAME
  })

  const { onSubmit: updateMeter } = useMutation({
    query: UPDATE_METER_ASSOCIATION
  })

  // Intentionally clearing building id and building name because it doesn't apply to these events
  const amplitudeProperties = { "building id": "", "building name": "", "organization id": orgId, "organization name": "", "utility account id": accountData?.id, "utility account number": accountData?.accountNumber }
  const handleSave = () => {
    trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.SAVE_ACCOUNT_CHANGES, amplitudeProperties)
    const requests = []
    if ((displayName || accountData?.accountName) && displayName !== accountData?.accountName) {
      trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.EDIT_ACCOUNT_DISPLAY_NAME, amplitudeProperties)
      requests.push(updateAccount({ utilityAccountId: accountData.id, accountName: displayName }))
    }
    let trackedEdit = false
    utilityMeterRowData.forEach(meter => {
      if ((meter.buildingId || selectedMeterBuildings[meter.id]?.id) && meter.buildingId !== selectedMeterBuildings[meter.id]?.id) {
        if (!trackedEdit) {
          trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.EDIT_UTILITY_METER_ASSOCIATIONS, amplitudeProperties)
          trackedEdit = true
        }
        requests.push(updateMeter({ utilityMeterId: meter.id, buildingId: selectedMeterBuildings[meter.id]?.id ?? null }))
      }
    })
    Promise.all(requests).then(result => {
      // Only show success message if there were no errors
      if (!result?.some(response => response.error)) {
        setShowConfirmation(true)
        setSavedChanges(true)
      }
    })
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
    if (nextLocation) {
      navigate(nextLocation)
    } else {
      navigate(pages["Organization Management/:id"].href.replace(":id", accountData?.accountId))
    }
  }

  // Check that organization has active UBM offering
  useEffect(() => {
    if (accountData?.accountId) {
      fetchAccountOfferings({ id: accountData.accountId })
    }
  }, [accountData])

  const ubmBuildings = useMemo(() => organizationOfferingData?.filter(offering => offering.code === "UBM" && isOfferingActive(offering)), [organizationOfferingData])
  const hasUBMOffering = useMemo(() => ubmBuildings?.length, [ubmBuildings])

  useEffect(() => {
    if (isEditUser && ubmBuildings?.length) {
      Promise.all(ubmBuildings.map(building => fetchBuilding({ id: building.buildingId }))).then(result => {
        setBuildingOptions(result.filter(building => building.isActive))
      })
    }
  }, [ubmBuildings])

  // Fetch Utility Meters and set row data and initial building selection
  useEffect(() => {
    const rowData = []
    const associatedData = {}

    // Add meter to appropriate object for setting state
    // Make request to get building name if needed
    const prepareMeterData = async (meter) => {
      let buildingName = ""
      if (meter.buildingId) {
        const building = await fetchBuilding({ id: meter.buildingId })
        buildingName = building.value
        associatedData[meter.id] = { buildingName: buildingName, id: meter.buildingId }
      }
      rowData.push({ ...meter, serviceType: typeDisplayName[meter.serviceType] || meter.serviceType, building: buildingName })
    }

    // Fetch utility meters and set state for Meter table
    const loadUtilityMeters = async () => {
      if (utilityMeters?.items?.length) {
        const meterRequests = []
        for (let i = 0; i < utilityMeters.items.length; i++) {
          meterRequests.push(prepareMeterData(utilityMeters.items[i]))
        }

        await Promise.all(meterRequests)
        setSelectedMeterBuildings(currentList => ({ ...currentList, ...associatedData }))
        setUtilityMeterRowData(currentList => ([...currentList, ...rowData]))
      }
      if (utilityMeters && utilityMeters?.nextToken !== null) {
        fetchMeters({ utilityAccountId, nextToken: utilityMeters.nextToken })
      }
    }

    loadUtilityMeters()
  }, [utilityMeters])

  const trackStatementDownload = () => trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.DOWNLOAD_STATEMENT, amplitudeProperties)
  const organizationId = useMemo(() => orgId ? orgId : accountData?.accountId, [orgId, accountData])
  useEffect(() => {
    if (organizationId && !utilityStatements?.items && !statementsError?.errors) {
      fetchUtilityStatements({ orgId: organizationId })
    }
    if (utilityStatements?.items?.length) {
      setUtilityStatementRowData(prev => ([
        ...prev,
        ...utilityStatements.items
        ?.filter(statement => statement.utilityAccountIds.includes(utilityAccountId))
        ?.map(statement => ({
          ...statement,
          // Statement month will not be zero-indexed, but JS Date months are, so subtract 1
          month: statement.month ? new Date(2025, statement.month - 1, 1).toLocaleString('default', { month: 'long' }) : "",
          totalDue: statement.totalDue ? statement.totalDue.toLocaleString("en-US", { style: "currency", currency: "USD"}) : "",
          statementDate: statement.statementDate ? moment(statement.statementDate, DATE_FORMAT_DASH1).format(DATE_FORMAT) : "",
          statement: <DownloadStatementButton saveFileName={`${accountData?.accountNumber}_Statement`} utilityStatementId={statement.id} usageTrackingCallback={() => trackStatementDownload()} />
        }))
      ]))
    }
    if (organizationId && utilityStatements?.nextToken) {
      fetchUtilityStatements({ orgId: null, nextToken: utilityStatements.nextToken })
    }
  }, [utilityStatements, organizationId])

  // Update statements with accountData if accountData finishes loading after statement rows start getting set
  useEffect(() => {
    setUtilityStatementRowData(prev => ([
      ...prev
      ?.map(statement => ({
        ...statement,
        statement: <DownloadStatementButton saveFileName={`${accountData?.accountNumber}_Statement`} utilityStatementId={statement.id} usageTrackingCallback={() => trackStatementDownload()} />
      }))
    ]))
  }, [accountData])

  useEffect(() => {
    if (accountData?.accountName) {
      setDisplayName(accountData.accountName)
    }
  }, [accountData])

  // Disable save button if there are no changes to save
  const saveButtonDisabled = useMemo(() => {
    if ((displayName || accountData?.accountName) && displayName !== accountData?.accountName) {
      return false
    }
    if (utilityMeterRowData.some(meter => {
      return (meter.buildingId || selectedMeterBuildings[meter.id]?.id) && meter.buildingId !== selectedMeterBuildings[meter.id]?.id
    })) {
      return false
    }
    return true
  }, [displayName, accountData?.accountName, selectedMeterBuildings, utilityMeterRowData])

  const handleChangeBuilding = id => (event, child) => {
    return setSelectedMeterBuildings(prev => ({ ...prev, [id]: { buildingName: child?.props?.value, id: child?.props?.id } }))
  }

  const meterHeadings = [
    {
      name: "meterNumber",
      title: "Meter Number",
      key: "meterNumber",
    },
    {
      name: 'serviceAddress',
      title: 'Service Address',
      key: 'serviceAddress',
      canSort: false,
      maxWidth: '120px'
    },
    {
      name: 'serviceType',
      title: 'Utility Type',
      key: 'serviceType',
      maxWidth: '120px'
    },
    {
      name: 'lastStatementDate',
      title: 'Last Statement',
      key: 'lastStatementDate',
      canSort: false,
      maxWidth: '120px'
    },
    {
      name: 'building',
      title: 'Associated Building',
      key: 'building',
      maxWidth: '120px',
      canSort: false,
      customComponent: isEditUser ? (row) =>
        <div style={{ position: "relative", width: "100%" }}>
          <Selectrix
            key={row.id}
            onChange={handleChangeBuilding(row.id)}
            options={buildingOptions}
            showNoResultsMessage={false}
            fullWidth={true}
            multiple={false}
            value={selectedMeterBuildings[row.id]?.buildingName ?? ""}
            containerWidth={true}
            handleClearSelection={() => setSelectedMeterBuildings(prev => {
              const clone = { ...prev }
              delete clone[row.id]
              return clone
            })}
            fontSize="14px"
            placeholder={"Select Building"}
            searchPlaceHolder={"Search"}
            inTableCell={true}
          />
        </div>
        : undefined
    },
  ]

  const statementHeadings = [
    {
      name: "month",
      title: "Month",
      key: "month",
      canSort: false,
    },
    {
      name: 'statementDate',
      title: 'Statement Date',
      key: 'statementDate',
      maxWidth: '120px',
      canSort: false,
    },
    {
      name: 'dueDate',
      title: 'Due Date',
      key: 'dueDate',
      maxWidth: '120px',
      // Use YYYY-MM-DD format for sorting, but display in MM/DD/YYYY format
      customComponent: (row) => row.dueDate ? moment(row.dueDate, DATE_FORMAT_DASH1).format(DATE_FORMAT) : "",
    },
    {
      name: 'totalDue',
      title: 'Total Due',
      key: 'totalDue',
      maxWidth: '120px',
      canSort: false,
    },
    {
      name: 'statement',
      title: 'Statement PDF',
      key: 'statement',
      maxWidth: '120px',
      canSort: false,
    },
  ]

  const billingAddress = useMemo(() => {
    try {
      const address = JSON.parse(accountData.billingAddress)
      // Take address in all caps and transform to just capitalizing the first letter of the words for street address and city
      const toTitleCase = (str) => str?.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())
      const lineOne = toTitleCase(address.streetLine1)
      const lineTwo = address.streetLine2 ? ` ${toTitleCase(address.streetLine2)}` : ""
      const city = toTitleCase(address.city)
      const state = address.state
      const zip = address.postalCode
      return `${lineOne}${lineTwo}, ${city}, ${state} ${zip}`
    }
    catch {
      return ""
    }
  }, [accountData])

  const DisplayNameMessage = () => (
    <ToolTipContainer>
      <Tooltip
        showArrow={false}
        content="Account Display Name can be used in place of the Account Number to easily identify the utility account."
      >
        <span className="icon icon-infocircle" />
      </Tooltip>
    </ToolTipContainer>
  )

  // Block navigation if there are unsaved changes
  const shouldBlock = useCallback(() => (!saveButtonDisabled && !savedChanges), [savedChanges, saveButtonDisabled])

  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <NewStyles>
        {hasUBMOffering ?
          <>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              position="relative"
            >
              <RouterPrompt shouldBlockNavigation={shouldBlock} proceedOnSave={false} setNextLocation={setNextLocation} handleSave={handleSave} />
              <PageHeader
                pageTitle={`${accountData?.accountName || accountData?.accountNumber} Account Details`}
              />
              {isEditUser
                && <div style={{ position: "absolute", bottom: "10px", right: "0px" }}>
                  <Button status={saveButtonDisabled ? "disabled" : undefined} onClick={handleSave}>Save</Button>
                </div>}
            </Content>
            {showConfirmation && (
              <Modal
                heading="Account Details Saved!"
                modalHeight="auto"
                handleClose={handleCloseConfirmation}
                stopAutoFocus={true}
                buttons={[
                  {
                    variant: 'primary',
                    text: 'Close',
                    handleClick: handleCloseConfirmation
                  }
                ]}
              >
                <p>Any updates to account name or building associations have been saved.</p>
              </ Modal>
            )}
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                display='flex'
                background='white'
              >
                <div style={{ width: "100%" }}>
                  <Parent>
                    <HeaderItem title={<div style={{ display: "flex" }}>Account Display Name{isEditUser && <DisplayNameMessage />}</div>} content={isEditUser ? displayName : accountData?.accountName} edit={isEditUser} handleChange={(e) => setDisplayName(e.target.value)} />
                    <HeaderItem title="Account Number" content={accountData?.accountNumber} />
                    <HeaderItem title="Account ID" content={accountData?.id} />
                    <HeaderItem title="Provider" content={accountData?.providerName} />
                    <HeaderItem title="Credential" content={accountData?.accountEmail} />
                    <HeaderItem title="Billing Address" content={billingAddress} />
                  </Parent>
                </div>
              </Content>
              <TabsWrapper>
                <Tabs activeTabContentStyle={"border: 1px solid lightgray;"}>
                  <Tab
                    title="Meters"
                    disabled={false}
                  >
                    <Table
                      key="utilityAccountsTable"
                      rows={utilityMeterRowData}
                      header={meterHeadings}
                      search={true}
                      searchFields={["meterNumber"]}
                      showItemCount={true}
                      fixedLayout={false}
                    />
                  </Tab>
                  <Tab
                    title="Statements"
                    disabled={false}
                  >
                    <Table
                      defaultSortColumn={2}
                      key="statementsTable"
                      rows={utilityStatementRowData}
                      header={statementHeadings}
                      fixedLayout={false}
                    />
                  </Tab>
                </Tabs>
              </TabsWrapper>
            </Content>
          </>
          : (utilityAccountId && !accountData && !accountError)
            || (accountData?.accountId && !organizationOfferingData && !offeringsError)
            || loadingOfferings ?
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <Spinner />
            </Content>
            :
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              placeSelf="center"
            >
              <WarningContainer>
                <Icon
                  name="Warning"
                  width="25px"
                  height="25px"
                  margin="0px 10px"
                  color={colors.RED}
                />
                <p style={{ marginBlockStart: "1em", marginBlockEnd: "1em" }}>
                  <TranslateComponent>
                    {`Utility Bill Management is not currently subscribed to for this organization.
            Enabled offerings can be seen in`}
                  </TranslateComponent>{' '}
                  <BuildingLink>
                    <Link to={`/building-setup${accountData?.accountId ? `?organization=${accountData.accountId}` : ""}`}><TranslateComponent>Building Setup.</TranslateComponent></Link>
                  </BuildingLink>
                </p>
              </WarningContainer>
            </Content>
        }
      </NewStyles>
    </Container >
  )
}
