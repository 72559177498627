import * as Sidebar from 'src/denali-components/Sidebar/sidebar.theme.ts';
import * as Table from 'src/denali-components/Table/table.theme.ts';
import * as Tabs from 'src/denali-components/Tabs/tabs.theme.ts';
import * as Button from 'src/denali-components/Amplify/button.theme.ts';
import * as Heading from 'src/denali-components/Amplify/heading.theme.ts';
import * as Menu from 'src/denali-components/Amplify/menu.theme.ts';
import * as FieldControl from 'src/denali-components/Amplify/fieldcontrol.theme.ts';
import * as Checkbox from 'src/denali-components/Amplify/checkbox.theme.ts';
import * as Radio from 'src/denali-components/Amplify/radio.theme.ts';
import * as Dialog from 'src/denali-components/Dialog/dialog.theme.ts';
import * as BuildingSelector from 'src/denali-components/BuildingSelector/building-selector.theme.ts';
import * as Page from 'src/denali-components/Page/page.theme.ts';
import * as CardDetails from 'src/denali-components/Cards/CardDetails/card-details.theme.ts';
import * as CardVertical from 'src/denali-components/Cards/CardVertical/card-vertical.theme.ts';
import * as CardHorizontal from 'src/denali-components/Cards/CardHorizontal/card-horizontal.theme.ts';
import * as CardVideo from 'src/denali-components/Cards/CardVideo/card-video.theme.ts';
import * as CardHighlightActions from 'src/denali-components/Cards/CardHighlightActions/card-highlight-actions.theme.ts';
import * as Collapsible from 'src/denali-components/Collapsible/collapsible.theme.ts';
import * as Form from 'src/denali-components/Form/form.theme.ts';

export const theme = {
  name: 'tc-theme',
  tokens: {
    fonts: {
      default: {
        variable: 'Lato,sans-serif'
      }
    },
    colors: {
      TBA_LIGHTER_GREEN: '#DAE2D0',
      TBA_LIGHT_GREEN: '#C3DFA2',
      TBA_GREEN: '#61AE34',
      TBA_MEDIUM_GREEN: '#4C912D',
      TBA_MEDIUM2_GREEN: '#467e26',
      TBA_DARK_GREEN: '#367326',
      WHITE: '#FFFFFF',
      BLACK: '#000000',
      TABLE_CONTENT_COLOR: '#555555',
      TBA_DARKER_GREY: '#666666',
      TBA_DARK_GREY: '#999999',
      TBA_DARK2_GREY: '#7d7d7d',
      TBA_MEDIUM_DARK_GREY: '#757575',
      TBA_MEDIUM_GREY: '#BBBBBB',
      TBA_LIGHT_GREY: '#CCCCCC',
      TBA_LIGHTER_GREY: '#EEEEEE',
      TBA_LIGHTEST_GREY: '#F4F4F4',
      TBA_ALMOST2_WHITE: '#F5F5F5',
      TBA_ALMOST_WHITE: '#FAFAFA',
      TBA_ALMOST2_WHITE3: '#f9fafc',
      TBA_LIGHT2_GREY: '#d2d2d2',
      TBA_LIGHT3_GREY: '#d8d8d8',
      TBA_LIGHTER_GREY_3: '#dce3e5',
      LIGHT_BLUE_1: '#daf9ff',
      GREEN: '#61AE34',
      YELLOW: '#FFF10B',
      RED: '#D52B1E',
      BLUE: '#00B9E4',
      ORANGE: '#FB9A09',
      PURPLE: '#501C8B',
      BROWN: '#703915',
      GREY: '#959595',

      GREEN_1: '#DAFFA7',
      GREEN_2: '#B7FF5B',
      GREEN_3: '#89C506',
      GREEN_4: '#367326',
      GREEN_5: '#7dba3a',
      YELLOW_1: '#FFF787',
      YELLOW_2: '#E1D47B',
      YELLOW_3: '#AB9A1F',
      YELLOW_4: '#817B14',
      RED_1: '#FEA7AA',
      RED_2: '#FD575D',
      RED_3: '#E10018',
      RED_4: '#8C0000',
      RED_5: '#FACBCB',
      RED_6: '#ff3100',
      BLUE_1: '#CFD5E8',
      BLUE_2: '#6C97BF',
      BLUE_3: '#326089',
      BLUE_4: '#203854',
      BLUE_5: '#239dd4',
      BLUE_6: '#0000ff',
      BLUE_7: '#6ba6e9',
      BLUE_8: '#527daf',
      BLUE_9: '#1db8dd',
      BLUE_10: '#bfebfc',
      BLUE_11: '#66a3e6',
      BLUE_12: '#206fcf',
      ORANGE_1: '#FFEEC1',
      ORANGE_2: '#FEDE87',
      ORANGE_3: '#FECF54',
      ORANGE_4: '#D7770C',
      ORANGE_5: '#FF2B00',
      ORANGE_6: '#FF6244',
      PURPLE_1: '#D5C5E3',
      PURPLE_2: '#AB90CB',
      PURPLE_3: '#865EB0',
      PURPLE_4: '#432762',
      BROWN_1: '#EFD4B8',
      BROWN_2: '#C89146',
      BROWN_3: '#A06D30',
      BROWN_4: '#6D3415',
      GREY_1: '#EFEFEF',
      GREY_2: '#BABABA',
      GREY_3: '#6C6C6C',
      GREY_4: '#3C3C3C',
      GREY_5: '#E0E0E0',
      GREY_6: '#B9B3AB',
      GREY_7: '#504949',

      TRANSPARENT: 'transparent',

      COLORPICKER_PRIMARY_COLORS: [
        '#61AE34',
        '#FFF10B',
        '#D52B1E',
        '#00B9E4',
        '#FB9A09',
        '#501C8B',
        '#703915',
        '#959595'
      ],
      COLORPICKER_SECONDARY_COLORS: [
        '#DAFFA7',
        '#FFF787',
        '#FEA7AA',
        '#CFD5E8',
        '#FFEEC1',
        '#D5C5E3',
        '#EFD4B8',
        '#EFEFEF',
        '#B7FF5B',
        '#E1D47B',
        '#FD575D',
        '#6C97BF',
        '#FEDE87',
        '#AB90CB',
        '#C89146',
        '#BABABA',
        '#89C506',
        '#AB9A1F',
        '#E10018',
        '#326089',
        '#FECF54',
        '#865EB0',
        '#A06D30',
        '#6C6C6C',
        '#367326',
        '#817B14',
        '#8C0000',
        '#203854',
        '#D7770C',
        '#432762',
        '#6D3415',
        '#3C3C3C'
      ],

      URGENT: '#d52b1e',
      HIGH: '#f7820e',
      MEDIUM: '#f9da31',
      LOW: '#00aeef',

      COMFORT: '#2c10eb',
      RELIABILITY: '#3a77b3',
      PERFORMANCE: '#00aeef',
      ENERGY_USAGE: '#61ae34',
      COMPLIANCE: '#7340a4',
      CUSTOM: '#fb8f0d',
      CUSTOM_2: '#bf5de6',
      AVOIDED_TRUCK_ROLLS: '#367326',
      IDENTIFIED: 'rgba(153,153,153, 0.8)',

      CHART_GREY: '#979797',
      EXCEEDING: '#00aeef',
      EXCEEDING_HOVER: '#3a77b3'
    },
    space: {
      SPACING_01: '0.25rem',
      SPACING_02: '0.5rem',
      SPACING_03: '0.75rem',
      SPACING_04: '1rem',
      SPACING_05: '1.5rem',
      SPACING_06: '1.75rem',
      SPACING_07: '2rem',
      //convert below to rem
      SPACING_08: '32px',
      SPACING_09: '40px',
      SPACING_10: '48px',
      SPACING_11: '64px',
      SPACING_12: '80px'
    },
    components: {
      _table: {
        header: {
          background: '{colors.WHITE}',
          border: '{colors.TBA_ALMOST3_WHITE}'
        },
        border: '{colors.TBA_ALMOST3_WHITE}',
        cell: {
          border: '{colors.TBA_ALMOST3_WHITE}',
          color: '{colors.BLACK}'
        },
        head: {
          error: {
            background: '{colors.RED_5}',
            border: '{colors.RED}'
          },
          background: '{colors.TBA_MEDIUM2_GREEN}'
        },
        headCell: {
          color: '{colors.WHITE}',
          spaces: {
            top: '{space.SPACING_01}',
            right: '{space.SPACING_04}',
            bottom: '{space.SPACING_01}',
            left: 0
          }
        },
        row: {
          color: '{colors.GREY_4}',
          border: '{colors.TBA_ALMOST3_WHITE}',
          background: {
            even: '{colors.TBA_LIGHTER_GREY}',
            odd: '{colors.WHITE}'
          },
          hover: {
            background: 'none'
          }
        },
        error: {
          border: '{colors.RED}'
        }
      },
      _newTable: {
        header: {
          background: '{colors.WHITE}',
          border: '{colors.TBA_ALMOST3_WHITE}'
        },
        border: '{colors.TBA_ALMOST3_WHITE}',
        cell: {
          border: '{colors.TBA_ALMOST3_WHITE}',
          color: '{colors.BLACK}'
        },
        head: {
          error: {
            background: '{colors.RED_5}',
            border: '{colors.RED}'
          },
          background: '{colors.WHITE}'
        },
        headCell: {
          color: '{colors.GREY_7}',
          spaces: {
            top: '{space.SPACING_01}',
            right: '{space.SPACING_04}',
            bottom: '{space.SPACING_01}',
            left: 0
          }
        },
        row: {
          color: '{colors.GREY_4}',
          border: '{colors.TBA_ALMOST3_WHITE}',
          background: {
            even: '{colors.TBA_ALMOST_WHITE}',
            odd: '{colors.WHITE}'
          },
          hover: {
            background: '{colors.LIGHT_BLUE_1}'
          }
        },
        error: {
          border: '{colors.RED}'
        }
      },
      _button: {
        backgroundColor: '{colors.TBA_DARKER_GREY}',
        borderColor: '{colors.TBA_DARK_GREY}',
        color: '{colors.WHITE}',
        _hover: {
          backgroundColor: '{colors.TBA_DARK2_GREY}',
          color: '{colors.WHITE}'
        },
        _disabled: {
          backgroundColor: '{colors.TBA_LIGHTER_GREY}',
          color: '{colors.TBA_LIGHT_GREY}',
          borderColor: '{colors.TBA_LIGHTER_GREY}'
        },
        primary: {},
        _active: {
          backgroundColor: '{colors.TBA_MEDIUM_GREY}',
          color: '{colors.WHITE}'
        }
      },
      _Textinput: {
        light: {
          active: {
            background: '{colors.TBA_ALMOST2_WHITE3}',
            color: '{colors.DARK_GREY}',
            border: '{colors.TBA_DARK2_GREY}',
            hoverBackground: '{colors.INPUT_BACKGROUND}',
            hoverColor: '{colors.DARK_GREY}',
            hoverBorder: '{colors.PRIMARY_BLUE}',
            labelColor: '{colors.PRIMARY_BLUE}',
            helpColor: '{colors.TEXT_SECONDARY_2}'
          },
          enabled: {
            background: '{colors.TBA_ALMOST2_WHITE3}',
            color: '{colors.DARK_GREY}',
            border: '{colors.TBA_DARK2_GREY}',
            hoverBackground: '{colors.INPUT_BACKGROUND}',
            hoverColor: '{colors.DARK_GREY}',
            hoverBorder: '{colors.TEXT_HOVER_1}',
            labelColor: '{colors.MED_GREY}',
            helpColor: '{colors.TEXT_SECONDARY_2}'
          },
          disabled: {
            background: '{colors.TBA_LIGHT_GREY}',
            color: '{colors.TBA_DARK_GREY}',
            border: '{colors.TBA_MEDIUM_GREY}',
            hoverBackground: '{colors.TBA_LIGHT_GREY}',
            hoverColor: '{colors.TBA_DARK_GREY}',
            hoverBorder: '{colors.TBA_DARK_GREY}',
            labelColor: '{colors.TBA_DARK_GREY}',
            helpColor: '{colors.TBA_DARK_GREY}'
          },
          error: {
            background: '{colors.RED_5}',
            color: '{colors.DARK_GREY}',
            border: '{colors.RED}',
            hoverBackground: '{colors.RED_5}',
            hoverColor: '{colors.DARK_GREY}',
            hoverBorder: '{colors.RED}',
            labelColor: '{colors.RED}',
            helpColor: '{colors.RED}'
          }
        }
      }
    }
  },
}


export const denaliTheme = {
  name: 'denali-theme',
  tokens: {
    fonts: {
      default: {
        variable: 'Inter, sans-serif'
      }
    },
    colors: {
      font: {
        primary: 'colors.neutral.90',
        secondary: '{colors.neutral.50}',
        link: '{colors.blue.40}',
      },
      neutral: {
        100: '#141414',
        90: '#1C1C1C',
        80: '#222',
        70: '#2C2C2C',
        60: '#424242',
        50: '#6B6B6B',
        40: '#A5A7A7',
        30: '#C4C4C4',
        20: '#EAEAEA',
        10: '#F4F4F4',
        loader: 'rgba(233, 233, 233, 0.85)',
        formoverlay: 'rgba(233, 233, 233, 0.95)'
      },
      red: {
        10: '#F633331A',
        20: '#FFF0F0',
        50: '#F63333',
        lightest: '#FEEBEB',
      },
      blue: {
        15: '#1260FD1A',
        20: '#EBF5FF',
        30: '#CADDFF',
        40: '#0F65FD',
        50: '#3378F6',
        60: '#2D6ADB',
        70: '#314363',
        80: '#2D384C',
        90: '#0F65FD0D',
        lightest: '#EAF0F9',
      },
      purple: {
        10: '#6D30D01A',
        50: '#6D30D0',
        lightest: '#F3E6FD',
      },
      turquoise: {
        10: '#25ACE01A',
        40: '#25ACE0',
        50: '#5DBEE3',
        lightest: '#EFF8FC'
      },
      pink: {
        10: '#FF00F51A',
        20: '#F8EAFF',
        30: 'rgba(255, 67, 192, 0.25)',
        40: '#FFD0EF',
        50: '#FF00F5',
        lightest: '#FFE6FE',
        shadow: '#DB75DB3D'
      },
      orange: {
        10: '#FFF2EA',
        50: '#FC640A',
        lightest: '#FFF2EA',
      },
      green: {
        10: '#45CB601A',
        40: '#45CB60',
        50: '#5DDC5D',
        60: '#0AC00A',
        80: '#334633',
        lightest: '#EAF9EA',
      },
      gold: {
        10: '#E2C20F1A',
        40: '#E2C20F',
        50: '#FFF7E8',
        100: '#D39825'
      },
      light: {
        1: 'rgba(255, 255, 255, .01)',
        4: 'rgba(255, 255, 255, .04)',
        16: 'rgba(255, 255, 255, .16)',
        40: 'rgba(255, 255, 255, .4)',
        90: 'rgba(255, 255, 255, .94)',
      },
      white: '#fff',
      gray: {
        50: '#8D8D8D',
        60: '#6B6B6B',
        80: '#1C1C1C'
      },
      eci: {
        background: '#FFF1E0'
      },
      gradients: {
        turquoise: {
          50: 'linear-gradient(144deg, #8CDDD3 4.42%, #4688AD 66.3%)',
          inverse: '{colors.white}',
        },
        purple: {
          //--amplify-colors-gradients-purple-background
          background: 'radial-gradient(104.77% 104.77% at 54.14% -0.7%, #E633F6 0%, #8000FF 100%)',
          foreground: '{colors.white}',
          shadow: '0px 0px 16px 0px rgba(181, 27, 251, 0.30);',
          text: 'linear-gradient(174.15deg, #FF00A9 -100%, #0057FF 100%)',
          decoration: '#0057FF',
        },
        altpurple: {
          50: 'linear-gradient(144deg, #B08CDD 4.42%, #8646AD 66.3%)',
          inverse: '{colors.white}',
        },
        orange: {
          50: 'linear-gradient(144deg, #D6D28F 4.42%, #EEAC7B 66.3%)',
          background: 'radial-gradient(85.72% 85.72% at 54.14% -0.7%, #FF9D35 0%, #F44F4F 100%)',
          inverse: '{colors.white}',
          shadow: '0px 0px 16px 0px #FF9D354D'
        },
        lightorange: {
          background: 'radial-gradient(85.72% 85.72% at 54.14% -0.7%, #FFCC35 0%, #F4834F 100%)',
          inverse: '{colors.white}',
          shadow: '0px 0px 16px 0px #FFCC354D',
        },
        pink: {
          50: 'linear-gradient(144deg, #DD8CD0 4.42%, #AD4671 66.3%)',
          60: 'linear-gradient(221deg, #9C00FF 3.12%, #F780F7 92.12%)',
          70: 'linear-gradient(221deg, #9C00FF 3.12%, #FFBCFF 92.12%);',
          inverse: '{colors.white}',
        },
        red: {
          50: 'linear-gradient(144deg, #D6A08F 4.42%, #EE7B7B 66.3%)',
          70: 'linear-gradient(221deg, #F00 3.12%, #FFBCBC 92.12%)',
          inverse: '{colors.white}',
        },
        green: {
          20: 'linear-gradient(144.11deg, #38C97B 4.42%, #25B266 61.88%)',
          50: 'linear-gradient(144deg, #8CDD8F 4.42%, #4FAD46 66.3%)',
          shadow: '4.5px 6px 19.8px 0px #25B36633',
          inverse: '{colors.white}',
        },        
        blue: {
          20: 'linear-gradient(144.11deg, #6A9EFF 4.42%, #3378F6 61.88%), radial-gradient(78.04% 78.04% at 78.04% 70.21%, rgba(63, 220, 255, 0.8) 0%, rgba(63, 220, 255, 0) 100%)',
          30: 'linear-gradient(144.11deg, #6A9EFF 4.42%, #3378F6 61.88%)',
          50: 'linear-gradient(144deg, #8CA3DD 4.42%, #4669AD 66.3%)',
          70: 'linear-gradient(221deg, #0085FF 3.12%, #BCC7FF 92.12%);',
          inverse: '{colors.white}',
          background: 'radial-gradient(85.72% 85.72% at 54.14% -0.7%, #3333F6 0%, #0077FF 100%)',
          shadow: '2px 6px 20px 0 #3479F733',
          altShadow: '4.5px 6px 19.8px 0px #39AAFB33',
          cobolt: 'radial-gradient(104.77% 104.77% at 54.14% -0.7%, #6735FF 0%, #4FAAF4 100%)',
          coboltShadow: '0px 0px 16px 0px rgba(89, 120, 249, 0.30);'
        },
        eui: {
          background: 'linear-gradient(to right, #4B41F2, #DE79B8, #4B41F2)',
          transparentbg: 'linear-gradient(to right, #EBE9FE, #F9EFF7, #F0EDFF)'
        },
        multiColor: {
          20: 'linear-gradient(233.66deg, #AA68FF -2.15%, #D836D1 46.52%, #D34A38 95.19%), radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 0.1) 100%)',
          50: 'linear-gradient(174deg, #FF00A9 -100%, #0057FF 100%)',
          70: 'linear-gradient(83deg, #364387 49.94%, #5E337C 99.88%)',
          90: 'linear-gradient(83deg, #1E2A4B 49.94%, #371F41 99.88%)',
          border: 'linear-gradient(96.93deg, #3338FD -2.91%, #8355DB 18.55%, #DE79B8 41.57%, #BA90D4 59.52%, #6090F6 77.16%, #8C58FF 97.5%)',
          overlay: '{colors.light.90}',
          hint: '{colors.neutral.40}',
          inverse: '{colors.white}',
        },
        accent: {
          50: 'linear-gradient(225deg, #EFBEDC 0%, #5DBEF6 100%)',
          inverse: '{colors.white}',
        },
        scroll: {
          fadeUp: 'linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #F7F7F7 100%)',
          fadeUpLight: 'linear-gradient(180deg, rgba(252, 252, 252, 0) 0%, #FFFFFF 100%)',
          fadeLeft: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)'
        },
        dark: {
          fadeUpDark: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
        }

      },
      ...theme.tokens.colors,
      ...theme.tokens.space,
    },
    components: {
      Sidebar: Sidebar.light,
      Dialog: Dialog.light,
      BuildingSelector: BuildingSelector.light,
      Page: Page.light,
      CardDetails: CardDetails.light,
      CardVertical: CardVertical.light,
      CardHighlightActions: CardHighlightActions.light,
      CardHorizontal: CardHorizontal.light,
      CardVideo: CardVideo.light,
      Collapsible: Collapsible.light,
      DenaliTable: Table.light,
      DenaliTabs: Tabs.light,
      Form: Form.light,
      _heading: Heading.light,
      _button: Button.light,
      _menu: Menu.light,
      _fieldcontrol: FieldControl.light,
      _checkbox: Checkbox.light,
      _radio: Radio.light,

      // old components backwards compatibility.
      _table: theme.tokens.components._table,
      _newTable: theme.tokens.components._newTable,
      _Textinput: theme.tokens.components._Textinput,
    }
  },
  overrides: [
    {
      colorMode: 'dark',
      tokens: {
        components: {
          sidebar: Sidebar.dark
        }
      }
    }
  ]
}