import translate from 'src/common/translations'
import { NavigationItemSingle } from './NavigationItemSingle'
import { faSparkles } from '../lib/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from 'src/denali-components/Switch/Switch'
import styles from './sidebar.module.scss'
import { useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole
} from '@floating-ui/react'
import { useSelector } from 'react-redux'
import { selectUiMode, setUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { useDispatch } from 'react-redux'
import { trackEvent, identifyEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'

export const UiSwitch = ({ menuIsOpen }) => {
  const uiMode = useSelector(selectUiMode)
  const dispatch = useDispatch()
  const isChecked = uiMode === UiMode.denali
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)
  const setIsChecked = (newIsChecked) => {
    dispatch(setUiMode(newIsChecked ? UiMode.denali : UiMode.standard))
    identifyEvent({ 'user interface': newIsChecked ? 'denali' : 'legacy' })
    trackEvent(USER_EVENTS.NAVIGATION.events.CLICK_CHANGE_UI, {
      'changed to': newIsChecked ? 'denali' : 'legacy'
    })
  }

  return (
    <div
      className={styles.uiMenu}
      {...getReferenceProps()}
      ref={refs.setReference}
      data-testid="uiToggle"
    >
      <NavigationItemSingle
        item={{
          icon: <FontAwesomeIcon icon={faSparkles} />,
          onClick: (event) => {
            event.preventDefault()
            setIsChecked(!isChecked)
          }
        }}
        forceHoverState={true}
        menuIsOpen={menuIsOpen}
        tooltip={false}
      >
        <span className={styles.title}>
          <Switch
            label={translate('New Design')}
            labelPosition="start"
            size="large"
            isChecked={isChecked}
            onChange={setIsChecked}
            data-testid="uiToggleSwitch"
          />
        </span>
      </NavigationItemSingle>

      {!menuIsOpen && isOpen && (
        <div
          className={styles.popupWrapper}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          data-testid="uiTogglePopup"
        >
          <div className={styles.popup}>
            <Switch
              label={translate('New Design')}
              labelPosition="start"
              size="large"
              isChecked={isChecked}
              onChange={setIsChecked}
              data-testid="uiTogglePopupSwitch"
            />
          </div>
        </div>
      )}
    </div>
  )
}

function setupFloating(isOpen, setIsOpen) {
  return useFloating({
    placement: 'right',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip(), shift()]
  })
}

function setupInteractions(context) {
  const hover = useHover(context, {
    handleClose: safePolygon()
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([hover, focus, dismiss, role])
}
