// Taken from https://floating-ui.com/docs/react-examples and modified
import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react'
import { forwardRef, HTMLProps } from 'react'

import { FlyoutMenuComponentProps, FlyoutMenuProps } from './types'
import { FlyoutMenuComponent } from './FlyoutMenuComponent'

export const FlyoutMenu = forwardRef<
  HTMLButtonElement,
  FlyoutMenuProps & HTMLProps<HTMLButtonElement> & FlyoutMenuComponentProps
>(function DropdownMenuFunction(props, ref) {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree key={props?.key}>
        <FlyoutMenuComponent {...props} ref={ref} />
      </FloatingTree>
    )
  }

  return <FlyoutMenuComponent {...props} ref={ref} />
})
