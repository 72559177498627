import { useFormContext } from 'react-hook-form'
import styles from './form.module.scss'

// A reusable form element that will look up the closest form context and use the name prop
// to get the error messages for that name.
export const DenaliFormElementError = ({ name }: { name: string }) => {
  const {
    formState: { errors }
  } = useFormContext()
  // To allow dot notated names for nested properties we'll need to walk the errors objects
  // by splitting name on '.' and then looking up the error message.
  const parts = name.split('.')
  const error = parts.reduce((acc, part) => acc?.[part], errors)

  // If we have an error let's try and display it.
  if (error?.message) {
    if (typeof error.message === 'string') {
      return <div className={styles.errorMessage}>{error.message}</div>
    } else {
      // Coerce to string.
      const errorMessage = error?.message?.toString()
      return <div className={styles.errorMessage}>{errorMessage}</div>
    }
  }

  return null
}
