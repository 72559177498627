export const light = {
  border: '{colors.neutral.20}',

  tab: {
    border: '{colors.neutral.20}',
    color: '{colors.neutral.90}',

    active: {
      backgroundColor: '{colors.gradients.multi.color.border}',
      backgroundInset: '{colors.gradients.multi.color.overlay}',
      color: '{colors.neutral.90}'
    }
  }
}
