import PropTypes from 'prop-types'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import { CostSavingsWrapper } from '../../styles'
import { TranslateComponent } from 'src/common/translations'

export default function CostSaving({
  label,
  value,
  period,
  isEditMode,
  infoText,
  isPayBackCalculation = false,
  mode = ''
}) {
  return (
    <CostSavingsWrapper>
      <div className={`${isPayBackCalculation ? 'payback-energy-block' : ''}`}>
        <div className={`energy-block ${isEditMode ? 'edit' : ''} ${mode === 'View' ? 'view' : ''}`}>
          {label ? <label className='energy-label'>{label}</label> : null}
          <h4 className={`${isPayBackCalculation ? "period payback-roi-period" : "period"}`}>
            <TranslateComponent>{period}</TranslateComponent>
            {isEditMode && infoText && (
              <span className="icon icon-infocircle tooltip-container">
                <div className="tooltip center-top"><TranslateComponent>{infoText}</TranslateComponent></div>
              </span>
            )}
          </h4>
          <div className={`${isPayBackCalculation ? "value payback-roi-value" : "value"}`}>
            <ConditionalTooltip
              type={ConditionalTooltip.TYPE.ICON}
              conditional={true}
              content={value}
              align={ConditionalTooltip.ALIGN.START}
              multiline={true}
            >
              {`${value}`}
            </ConditionalTooltip>
          </div>
        </div>
      </div>
    </CostSavingsWrapper>
  )
}

CostSaving.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  period: PropTypes.string,
  isEditMode: PropTypes.bool,
  infoText: PropTypes.string
}
