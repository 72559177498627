import { Flex } from '@aws-amplify/ui-react'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

// Local imports
import pickerStyles from './selection-wrapper.module.scss'
import {
  SelectionWrapperItemKey,
  SelectionWrapperItemKeyExtractor,
  SelectionWrapperItemNameExtractor,
  SelectionWrapperProps,
  SelectionWrapperSelectedItemsBuilder,
  SelectionWrapperSelectedItemsItemBuilder
} from './types'
import { SelectionWrapperSelectedItems } from './SelectionWrapperSelectedItems'

/**
 * A reusable picker component that takes in a list of items and an onSelectedItemsChange handler and allows
 * picking items and keeping track of the selection.
 *
 * Various "builders" are available to override each section to allow the component to customizable.
 */
export const SelectionWrapper = <T extends NonNullable<unknown>>({
  items,
  selectedItems,
  initialSelectedItems,
  itemBuilder,
  showSelectedItems = true,
  selectedItemBuilder,
  selectedItemsBuilder,
  onSelectedItemsChange,
  // 0 means no limit by default
  limit = 0,
  limitErrorMessage,
  limitErrorBuilder,
  keyExtractor,
  nameExtractor,
  styles
}: SelectionWrapperProps<T>) => {
  // Setup initial list of items; if we are passed [selectedItems] we don't create any state and just use the passed
  // data to handle updating the list and simply call the onSelectedItemChange handler.
  const [internalSelectedItems, setInternalSelectedItems] = useState<T[]>(
    initialSelectedItems ?? []
  )
  const [limitError, setLimitError] = useState<string>('')

  // Memoize a flag determining if we are managed or controlled (Controlled means the selected items are passed in
  // and "managed" means that state is managed internally)
  const isControlled = useMemo(
    () => selectedItems !== undefined,
    [selectedItems]
  )

  // Now memoize our "selections" by using the above flag to decide which value to use.
  const selections = useMemo(
    () => (isControlled ? selectedItems : internalSelectedItems),
    [isControlled, selectedItems, internalSelectedItems]
  )

  const handleChange = useCallback(
    (item: T) => {
      const updatedItems = updateListOfItems(item, selections, keyExtractor)

      // Check if we are over the limit.
      const isLimitationError = Boolean(
        updatedItems.length > limit && limit > 0
      )

      if (isLimitationError && limit > 0) {
        setLimitError(
          limitErrorMessage ?? `You can only select ${limit} items.`
        )
      } else {
        // Remove error message and updated items and call handler.
        setLimitError('')
        if (!isControlled) {
          setInternalSelectedItems(updatedItems)
        }
        onSelectedItemsChange(updatedItems)
      }
    },
    [selections, setInternalSelectedItems, onSelectedItemsChange, setLimitError]
  )

  // Build the child element using the itemBuilder callback and memoize the result so we only rebuild when the
  // items or selected items change.
  const child = useMemo(
    () => itemBuilder(items, selections, handleChange),
    [items, selections, handleChange]
  )

  // Wrap with some classes if we want default styles or anything.
  const invalidSelected = limitError.length > 0

  // Rendered elements.
  return (
    <Flex
      gap="large"
      direction="column"
      className={`${pickerStyles.selectionWrapper} ${
        invalidSelected ? 'invalid-selection' : ''
      }`}
      style={styles}
    >
      {child}
      {showSelectedItems &&
        buildSelectedItems(
          selections,
          handleChange,
          selectedItemBuilder,
          selectedItemsBuilder,
          keyExtractor,
          nameExtractor
        )}
      {limitErrorBuilder !== undefined && limitErrorBuilder(limitError)}
    </Flex>
  )
}

// Function for rendering individual items.
const buildSelectedItems = <T extends NonNullable<unknown>>(
  selectedItems: T[],
  handleChange: (item: T) => void,
  selectedItemBuilder: SelectionWrapperSelectedItemsItemBuilder<T>,
  selectedItemsBuilder: SelectionWrapperSelectedItemsBuilder<T>,
  keyExtractor: SelectionWrapperItemKeyExtractor<T>,
  nameExtractor: SelectionWrapperItemNameExtractor<T>
): React.ReactNode => {
  // Let's see if we have a builder for the whole element.
  if (selectedItemsBuilder !== undefined) {
    return selectedItemsBuilder(selectedItems, handleChange)
  }

  return (
    <SelectionWrapperSelectedItems
      selectedItems={selectedItems}
      itemBuilder={selectedItemBuilder}
      onRemoveSelectedItem={handleChange}
      keyExtractor={keyExtractor}
      nameExtractor={nameExtractor}
    />
  )
}

const updateListOfItems = <T extends NonNullable<unknown>>(
  item: T,
  selections: T[],
  keyExtractor: SelectionWrapperItemKeyExtractor<T>
): T[] => {
  const itemIndexInList = selections.findIndex(
    (i) => keyExtractor(i) === keyExtractor(item)
  )
  // Get the last index without going out of range. I.e. if we have 5 items, [0, 1, 2, 3, 4] and
  // we want to remove the last one (4), itemIndexInList will return an index of 4, so we pass that
  // to slice so we don't go out of range. But if we want to slice out 2, itemIndexInList will be 2
  // so we want to take the slice from 0 - 1 and 3 - end so we end up with:
  //
  // const updated = [
  //   ...selections.slice(0, itemIndexInList) (0 and 2 with the end being exclusive)
  //   ...selections.slice(itemIndexInList + 1) (3 and then if no end supplied it grabs the remaining items)
  // ]
  const lastIndex =
    itemIndexInList < selections.length
      ? itemIndexInList + 1
      : selections.length

  // If index is -1 then it's not in here so just create a carbon copy of the list and add the items otherwise
  // splice it out of the list.
  const updatedItems =
    itemIndexInList === -1
      ? [...selections, item]
      : [
          ...selections.slice(0, itemIndexInList),
          ...selections.slice(lastIndex)
        ]

  return updatedItems
}
