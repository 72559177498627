import translate from 'src/common/translations'
import * as yup from 'yup'
import { MAX_SIZE_OF_NUMBERS } from 'src/pages/documentsAndReports/helper'

// Create Yup schema for Opportunity form
export const useOpportunitySchema = () => {
  // the translate hook may cause errors if use directly inline.
  // all translations here instead
  const errors = {
    locationId: translate('Building is requried'),
    name: translate('Title is required'),
    isPublic: translate('Visibility is required'),
    proposedBy: translate('Created By is required'),
    proposedDate: translate('Creation Date is required'),
    cowStartDate: translate('Start date is required'),
    maxNumber: translate(`Value must be less than ${MAX_SIZE_OF_NUMBERS}`),
    minNumber: translate('Value must be equal to or greater than zero'),
    fieldRequired: translate('Selection required'),
    genericRequired: translate('Value is required')
  }

  const requiredNumberValue = yup
    .number()
    .typeError(errors.genericRequired)
    .required(errors.genericRequired)
    .min(0, errors.minNumber)
    .max(MAX_SIZE_OF_NUMBERS, errors.maxNumber)

  const schema = yup.object({
    location: yup.object().shape({
      locationId: yup.string().required(errors.locationId)
    }),
    name: yup.string().required(errors.name).trim().max(200).min(1),
    isPublic: yup.boolean().required(errors.isPublic),
    settings: yup.object({
      proposedBy: yup.string().required(errors.proposedBy),
      proposalDate: yup.string().required(errors.proposedDate),
      costSavings: yup.object().shape({
        savings: yup.object().shape({
          value: requiredNumberValue,
          enabled: yup.boolean(),
          tag: yup.string().when('enabled', {
            is: true,
            then: (schema) => schema.required(errors.fieldRequired),
            otherwise: (schema) => schema.notRequired()
          })
        }),
        costToImplement: yup.object().shape({
          enabled: yup.boolean(),
          value: requiredNumberValue,
          priceType: yup.string().when('enabled', {
            is: true,
            then: (schema) => schema.required(errors.fieldRequired),
            otherwise: (schema) => schema.notRequired()
          })
        }),
        lifecycle: yup.object().shape({
          value: yup
            .number()
            .transform((value, originalValue) =>
              originalValue === '' ? 0 : value
            )
            .optional(),
          tag: yup.string()
        }),
        timeToUse: yup.object().shape({
          value: yup
            .number()
            .transform((value, originalValue) =>
              originalValue === '' ? 0 : value
            )
            .optional(),
          tag: yup.string()
        }),
        paybackCalculation: yup.object().shape({
          value: yup.number().default(0).optional(),
          enabled: yup.boolean()
        }),
        roiCalculation: yup.object().shape({
          value: yup.number().default(0).optional(),
          enabled: yup.boolean()
        }),
        costOfWaiting: yup.object().shape({
          value: yup.boolean(),
          startDate: yup.string().when('value', {
            is: true,
            then: (schema) => schema.required(errors.cowStartDate),
            otherwise: (schema) => schema.notRequired()
          })
        })
      })
    })
  })

  return schema
}
