import { useMemo, useEffect, useState } from 'react'
import { UPDATE_DASHBOARD } from '../graphql'
import Container from 'src/components/Container'
import { useNavigate, useParams } from 'react-router-dom'
import Content from 'src/components/Content'
import { useDashboardContext } from './DashboardContextProvider'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'
import { MODAL_TYPE } from 'src/constants'
import {
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_WIDGET,
  GET_DASHBOARD_WIDGET_IDS
} from '../graphql'
import ToolBar from './ToolBar'
import { useSelector, useDispatch } from 'react-redux'
import {
  getDashboards,
  selectDashboards,
  getDashboard,
  selectDashboardValues,
  setDashboards,
  setDashboardPath,
  setDashboardIdForWidget,
  selectDashboardIdForWidget
} from 'src/redux/slicers/dashboard'
import Select from 'src/components/legacy/components/select/select'
import { NoDashboard } from '../styles'
import { mutation } from '../helper'
import { frameInputForKeyMetricUpdate } from './CreateDashboard/KeyMetrics/helpers'
import translate, { TranslateComponent } from 'src/common/translations'
import DashboardCardWidget from './DashboardCardWidget'
import { WidgetsContextProvider } from 'src/pages/widgets/WidgetsContextProvider'
import KeyMetricConfigurator from '../components/CreateDashboard/KeyMetrics/KeyMetricConfigurator'
import {
  updateUserDefaultDashboard,
  deleteUserSettings
} from 'src/common/userSettingAPI'
export const DELETE_DASHBOARD_CONFIRMATION = 'delete-dashboard-confirmation'
export const DASHBOARD_HELP = 'dashboard-help'
const DELETE_DASHBOARD_SUCCESS = 'delete-dashboard-success'
export const DEFAULT_DASHBOARD_SUCCESS = 'default-dashboard-success'

import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import KpiStripBlock from './CreateDashboard/KeyMetrics/KeyStripBlock'
import { useTranslation } from 'react-i18next'
import { stringToBoolean } from 'src/utils/commonMethods'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import ErrorAlert from 'src/components/ErrorAlert'

const MODALTYPES = [
  DELETE_DASHBOARD_CONFIRMATION,
  DELETE_DASHBOARD_SUCCESS,
  DEFAULT_DASHBOARD_SUCCESS,
  DASHBOARD_HELP,
  MODAL_TYPE.ERROR
]

const DashboardContainer = () => {
  const { confirmationModalType, setConfirmationModalType } =
    useDashboardContext()
  const {
    loading: loadingDashboard,
    error: dashboardError,
    data: dashboardValues
  } = useSelector(selectDashboardValues)
  const [nonSelectedDashboards, setNonSelectedDashboards] = useState<any>([])
  const [isDeletingDashboard, setIsDeletingDashboard] = useState(false)
  const [nonSelectedDashboardList, setNonSelectedDashboardList] = useState([])
  const [keyMetricConfig, setKeyMetricConfig] = useState(false)
  const [nonSelectedDashboard, setNonSelectedDashboard] = useState<any>({})
  const [selectedDashboardValues, setSelectedDashboardValues] =
    useState<any>(null)
  const dashboardIdForWidget = useSelector(selectDashboardIdForWidget)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [t] = useTranslation()
  const { id } = useParams()

  const updateDashboard = async (variables) => {
    try {
      await mutation({
        query: UPDATE_DASHBOARD,
        variables
      }).then((res) => {
        dispatch<any>(getDashboards())
      })
    } catch (error) {
      throw error
    }
  }
  const { onSubmit: deleteDashboardWidget } = useMutation({
    query: DELETE_DASHBOARD_WIDGET,
    dataPath: 'data.deleteDashboardWidget'
  })
  const { refetch: getDashboardWidgetIds } = useQuery({
    query: GET_DASHBOARD_WIDGET_IDS,
    disableInitialLoad: true,
    dataPath: 'data.getDashboard.widgets.items',
    errorPolicy: 'all'
  })
  const optionsTranslated = {
    yes: 'YES',
    no: 'NO',
    newDefault: translate('Select a new default dashboard'),
    remainingDash: translate('Your remaining dashboard'),
    defaultDash: translate('will become your default dashboard.'),
    successSet: translate(`You have successfully set `),
    asDefault: translate(` as your default dashboard.`),
    unexpected: translate(
      'Sorry, something went wrong. An unexpected error has occurred.'
    ),
    close: translate('CLOSE'),
    needFurther: translate('Need Further Assistance')
  }
  const { loading: loadingDashboards, data: dashboards } =
    useSelector(selectDashboards)

  const initializeData = (data) => {
    if (data?.length > 0) {
      const dashboardId = dashboardIdForWidget ? dashboardIdForWidget : id
      setSelectedDashboardValues(data?.find((it) => it?.id === dashboardId))
      const filtered = data?.filter((das: any) => das.id !== dashboardId)
      setNonSelectedDashboards(filtered)
      const filteredList = filtered
        .map((das: any) => ({
          key: das?.id,
          description: das?.name
        }))
        ?.sort((a, b) => (a?.description > b?.description ? 1 : -1))

      setNonSelectedDashboardList(filteredList)
      const nonDashboard: any = data?.filter(
        (das: any) => das.id !== dashboardId
      )?.[0]
      if (nonDashboard) {
        const dashboardData = {
          key: nonDashboard?.id,
          description: nonDashboard?.name
        }
        setNonSelectedDashboard(dashboardData)
      }
    } else {
      setNonSelectedDashboards([])
      setNonSelectedDashboardList([])
      setNonSelectedDashboard({})
      setSelectedDashboardValues(null)
    }
  }
  useEffect(() => {
    if (id) {
      dispatch<any>(getDashboard(id))
      dispatch(
        setDashboardIdForWidget({
          dashboardId: id
        })
      )
      dispatch(
        setDashboardPath({
          dashboardPath: `/dashboard/${id}`
        })
      )
    }
  }, [id])

  useEffect(() => {
    initializeData(dashboards)
  }, [dashboards, dashboardIdForWidget, id])

  useEffect(() => {
    if(!selectedDashboardValues && dashboardValues?.isPublic === '1') {
      setSelectedDashboardValues(dashboardValues)
    }
  }, [dashboardValues,selectedDashboardValues])
  
  const deleteDashboard = async (variables) => {
    try {
      setIsDeletingDashboard(true)
      // Delete Dashboard Widgets
      await getDashboardWidgetIds({ id: selectedDashboardValues?.id }).then(
        async (items) => {
          if (items && items?.length > 0) {
            const validWidgets = items?.filter((f) => f?.id)
            await Promise.all(
              validWidgets?.map(async (w) =>
                deleteDashboardWidget({ input: { id: w?.id } })
              )
            )
          }
        }
      )
      await mutation({
        query: DELETE_DASHBOARD,
        variables
      }).then(async () => {
        let defaultDashBoard = null
        if (
          selectedDashboardValues?.isDefault === '1' &&
          nonSelectedDashboards?.length > 0
        ) {
          defaultDashBoard =
            nonSelectedDashboards?.find(
              (item) =>
                item?.isDefault !== '1' &&
                item?.id === nonSelectedDashboard?.key
            ) || {}
          await updateUserDefaultDashboard(
            dashboards,
            defaultDashBoard?.id,
            true
          )
          dispatch<any>(getDashboards())
        } else {
          defaultDashBoard = nonSelectedDashboards?.find(
            (item) => item?.isDefault === '1'
          )
          // which means last dashboard is getting deleted
          if (dashboards?.length === 1) {
            const userSettingsId = dashboards?.find(
              (x) => x?.userSettingsId !== null
            )?.userSettingsId
            // if it has user setting entry for this user
            userSettingsId && (await deleteUserSettings(userSettingsId))
          }
          dispatch<any>(getDashboards())
        }
        const dashboardPath =
          Array.isArray(nonSelectedDashboards) &&
          nonSelectedDashboards?.length > 0
            ? `/dashboard/${defaultDashBoard?.id}`
            : '/dashboards'
        dispatch(
          setDashboardPath({
            dashboardPath:
              nonSelectedDashboards?.length > 0 ? dashboardPath : ''
          })
        )
        dispatch(
          setDashboardIdForWidget({
            dashboardId: defaultDashBoard?.id
          })
        )
        setConfirmationModalType(DELETE_DASHBOARD_SUCCESS)
        trackEvent(USER_EVENTS.DASHBOARDS.events.DELETE_DASHBOARD)
        setIsDeletingDashboard(false)
        navigate(dashboardPath)
      })
    } catch {
      setConfirmationModalType(MODAL_TYPE.ERROR)
    }
  }
  const hideDialog = () => {
    if (confirmationModalType === DELETE_DASHBOARD_SUCCESS) {
      setConfirmationModalType('')
      if (dashboards && dashboards?.length === 0) navigate(0)
    } else {
      setConfirmationModalType('')
    }
  }
  const deleteDashboardHandler = () => {
    if (nonSelectedDashboardList?.length === 1) {
      const data = nonSelectedDashboardList[0]
      if (data) {
        setNonSelectedDashboard({
          key: data.key,
          description: data.description
        })
      } else {
        setNonSelectedDashboard(null)
      }
    }
    deleteDashboard({ input: { id: selectedDashboardValues?.id } })
  }

  const isLoading = loadingDashboards || loadingDashboard || isDeletingDashboard
  const modalConfig = useMemo(
    () => ({
      heading: (() => {
        switch (confirmationModalType) {
          case DELETE_DASHBOARD_CONFIRMATION:
            return 'Delete Dashboard'
          case DEFAULT_DASHBOARD_SUCCESS:
          case DELETE_DASHBOARD_SUCCESS:
            return 'Success'
          case DASHBOARD_HELP:
            return 'Dashboards Help'
          case MODAL_TYPE.ERROR:
            return 'Error'
          default:
            return ''
        }
      })(),
      buttons: (() => {
        if (isLoading) return []
        switch (confirmationModalType) {
          case DELETE_DASHBOARD_CONFIRMATION:
            return [
              {
                text: optionsTranslated.yes,
                disabled: isDeletingDashboard,
                handleClick: deleteDashboardHandler,
                type: 'valid'
              },
              {
                text: optionsTranslated.no,
                disabled: isDeletingDashboard,
                handleClick: hideDialog,
                type: 'cancel'
              }
            ]
          case DELETE_DASHBOARD_SUCCESS:
          case DEFAULT_DASHBOARD_SUCCESS:
          default:
            return [
              {
                text: optionsTranslated.close,
                handleClick: hideDialog,
                type: 'cancel'
              }
            ]
        }
      })(),
      handleClose: hideDialog,
      ...([DEFAULT_DASHBOARD_SUCCESS, DELETE_DASHBOARD_SUCCESS].includes(
        confirmationModalType
      ) && {})
    }),
    [confirmationModalType, isLoading, nonSelectedDashboard?.key]
  )

  const isDefaultDashbord = selectedDashboardValues?.isDefault === '1'
  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case DELETE_DASHBOARD_CONFIRMATION:
        return (
          <>
            <p>
              <TranslateComponent>
                Are you sure you want to delete
              </TranslateComponent>{' '}
              {selectedDashboardValues?.name}?
            </p>
            {nonSelectedDashboardList?.length === 0 ? (
              <p>
                <TranslateComponent>
                  Note: You will need to create a new dashboard to add any
                  widgets
                </TranslateComponent>
              </p>
            ) : null}
            {isDefaultDashbord && nonSelectedDashboardList?.length > 1 && (
              <div>
                <label>{optionsTranslated.newDefault}</label>
                <Select
                  options={nonSelectedDashboardList}
                  onChange={(value) => {
                    setNonSelectedDashboard(
                      nonSelectedDashboardList.find(
                        (das: any) => das.key === value
                      )
                    )
                  }}
                  selectedItem={nonSelectedDashboard?.description}
                />
              </div>
            )}
            {isDefaultDashbord && nonSelectedDashboards?.length === 1 && (
              <p>
                {optionsTranslated.remainingDash}{' '}
                {nonSelectedDashboard?.description},{' '}
                {optionsTranslated.defaultDash}
              </p>
            )}
          </>
        )
      case DELETE_DASHBOARD_SUCCESS:
        return (
          <TranslateComponent>
            You have successfully deleted your dashboard.
          </TranslateComponent>
        )
      case DASHBOARD_HELP:
        return (
          <>
            <h1>
              <TranslateComponent>Terms you need to know</TranslateComponent>
            </h1>
            <div>
              <div>
                <TranslateComponent>Dashboard</TranslateComponent>
              </div>
              <p>
                <TranslateComponent>
                  A display, populated by Widgets, that allows a personalized
                  'easy to read' view of your facility and equipment. Default -
                  a preselected dashboard that will be your user profile's
                  startup dashboard. Public - a dashboard that can be viewed by
                  others and copied into their own space for further use. A copy
                  creates a new version of this dashboard for the user that
                  copies it; it does not modify the original.
                </TranslateComponent>
              </p>
            </div>
            <div>
              <div>
                <TranslateComponent>Widget</TranslateComponent>
              </div>
              <p>
                <TranslateComponent>
                  Precise view into selected data from your facility and
                  equipment. You can select either predefined widgets from the
                  Widget Library or create your own custom widgets (depending on
                  permission).
                </TranslateComponent>
              </p>
            </div>
            <div>
              <h2>
                <TranslateComponent>
                  Introduction to Dashboards
                </TranslateComponent>
              </h2>
            </div>
            <div>
              <b>{optionsTranslated.needFurther}</b>
              <span>
                <TranslateComponent>
                  Contact our Support Staff using the{' '}
                </TranslateComponent>
                <a>
                  <TranslateComponent>Support/Feedback</TranslateComponent>
                </a>
                <TranslateComponent>
                  {' '}
                  menu located in the upper right corner of the page.
                </TranslateComponent>
              </span>
            </div>
          </>
        )
      case DEFAULT_DASHBOARD_SUCCESS:
        return (
          optionsTranslated.successSet +
          selectedDashboardValues?.name +
          optionsTranslated.asDefault
        )
      case MODAL_TYPE.ERROR:
        return optionsTranslated.unexpected
    }
  }

  const onKeyMetricConfig = () => {
    setKeyMetricConfig(true)
  }

  const handleDashboardUpdate = async (values) => {
    const input = frameInputForKeyMetricUpdate(values, id)
    await updateDashboard({ input })
    setKeyMetricConfig(false)
  }

  if (loadingDashboards || loadingDashboard || isDeletingDashboard)
    return <Spinner />
  if (dashboardError)
    return (
      <NoDashboard>
        <h2>Something went wrong</h2>
      </NoDashboard>
    )

  if (!selectedDashboardValues) {
    return (
      <ErrorAlert
        errorMsg={`Denied access. Access is denied due to invalid permissions. Please contact your Trane representative.`}
      />
    )
  }

  return (
    <>
      <ToolBar selectedDashboardValues={selectedDashboardValues} />
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding={'0'}
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <WidgetsContextProvider>
            {stringToBoolean(selectedDashboardValues?.isKeyMetric) && (
              <KpiStripBlock
                dashboard={selectedDashboardValues}
                handleOpenKpiStripBuilder={() => {
                  onKeyMetricConfig()
                }}
                t={t}
              />
            )}
            <DashboardCardWidget />
          </WidgetsContextProvider>
        </Content>
        {MODALTYPES.includes(confirmationModalType) ? (
          <Modal {...modalConfig}>
            {isDeletingDashboard ? (
              <Spinner />
            ) : (
              <ConfirmationText>
                {renderConfirmationText(confirmationModalType)}
              </ConfirmationText>
            )}
          </Modal>
        ) : null}
        {keyMetricConfig ? (
          <div>
            <KeyMetricConfigurator
              dashboardValues={selectedDashboardValues}
              setKeyMetricConfig={setKeyMetricConfig}
              handleDashboardUpdate={handleDashboardUpdate}
            />
          </div>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}
export default DashboardContainer
