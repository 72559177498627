import styles from './building-selector.module.scss'
import { BuildingIcon } from './BuildingIcon'
import { OrganizationIcon } from './OrganizationIcon'
import { SelectedOrganizationDisplay } from './SelectedOrganizationDisplay'
import { SelectedBuildingDisplay } from './SelectedBuildingDisplay'

export const SelectedDisplay = ({
  sidebarIsExpanded,
  orgGetReferenceProps,
  orgRefs,
  buildingGetReferenceProps,
  buildingRefs,
  organization,
  building
}) => {
  if (!organization && !building) return <></>

  return (
    <div
      className={`${styles.selectionDisplay} ${
        sidebarIsExpanded ? '' : styles.collapsed
      }`}
      data-testid="buildingSelectorSelectionDisplay"
    >
      {sidebarIsExpanded && (
        <div className={styles.floatingIconIndicator}>
          {sidebarIsExpanded && building && (
            <BuildingIcon name={building?.primary} />
          )}
          {sidebarIsExpanded && !building && organization && (
            <OrganizationIcon name={organization?.primary} />
          )}
        </div>
      )}

      {organization && (
        <button
          {...orgGetReferenceProps()}
          className={styles.orgDisplay}
          ref={orgRefs.setReference}
          data-testid="buildingSelectorSelectedOrgButton"
        >
          <SelectedOrganizationDisplay
            organization={organization}
            sidebarIsExpanded={sidebarIsExpanded}
          />
        </button>
      )}

      {organization && organization.hasOwnProperty('buildings') && (
        <button
          {...buildingGetReferenceProps()}
          ref={buildingRefs.setReference}
          data-testid="buildingSelectorSelectedBuildingButton"
        >
          <SelectedBuildingDisplay
            organization={organization}
            building={building}
            sidebarIsExpanded={sidebarIsExpanded}
          />
        </button>
      )}
    </div>
  )
}
