import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import Modal from '../../components/legacy/components/modal/modal'
import Label from '../../components/legacy/components/label/label'
import {
  DocumentDetails,
  PanelDiv,
  PanelLabel,
  InformationPanel,
  PanelItem,
  PanelText,
  ErrorText
} from './styles'
import moment from 'moment'
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'
import { useDocumentsAndReportsContext } from './DocumentsAndReportsContextProvider'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { useEffect, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_DOCUMENT_BY_ID } from './graphql'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useLocation, useNavigate} from 'react-router-dom'
import {
  selectUserInfo
} from 'src/redux/slicers/appData'
import { getSearchParams } from '../../common/helperFunctions'
import useAttachmentClaims from './attachmentClaimsHook'

const DocumentsViewModal = ({
  downloadFileFunc
}) => {
  const { organizationId, buildingId} = getSearchParams()
  const [RefreshAttachmentClaims] = useAttachmentClaims()
  const [t] = useTranslation()
  const navigate = useNavigate();
  const {search} = useLocation();
  const [attachments, setAttachments] = useState([])
  const supportingNotesTitle = 'Supporting Notes'
  const { setModalType, docReportRow, setDocumentModalView } = useDocumentsAndReportsContext()
  const documentId = docReportRow?.id
  const userInfo = useSelector(selectUserInfo)
  const currentUser = `${userInfo?.firstName} ${userInfo?.lastName}`
  const { data: fetchedDocument, refetch: refetchDocument, error, loading } = useQuery({
    query: GET_DOCUMENT_BY_ID,
    variables: { documentId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDocument'
  })
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    async function getDocument(id) {
      const buildingIds = docReportRow?.buildingId ? [docReportRow?.buildingId] : null
      const organizationIds = organizationId ? [organizationId] : null                    
      await RefreshAttachmentClaims(buildingIds,organizationIds).then(()=>{
        refetchDocument({ id })
      }).catch(()=>{
        setIsLoading(false)
      })
    }
    if (docReportRow?.id) {
      const id = docReportRow?.id
      !buildingId?  getDocument(id): refetchDocument({ id })
    }
  }, [docReportRow])

  useEffect(() => {   
    if (fetchedDocument && fetchedDocument.attachments?.items?.length) { 
      setIsLoading(false)
      const attachmentData = fetchedDocument?.attachments?.items?.map(
        (item) => ({
          ...item,
          fileName: item?.name,
          filePath: `${item?.buildingId}/${item?.name}`,
          fileSize: item?.sizeInBytes,
          title: item?.title,
          timestamp: item?.attachedAt,
          buildingId: item?.buildingId,
          description: item?.description
        })
      )
      setAttachments(attachmentData)
    }
  }, [fetchedDocument])

  const contact = fetchedDocument?.contact
	const isRequestedByCurrentUser = requestedBy => requestedBy === currentUser
	const checkPermission = contact => isRequestedByCurrentUser(contact)

  return (
    <>
      <Modal
        gray={true}
        className="wide-modal document_details"
        heading={t('reports:DocumentDetails')}
        handleClose={() => setDocumentModalView(false)}
        testName="document-view"
        buttons={[
          {
            text: t('common:Close'),
            handleClick: () => setDocumentModalView(false)
          }
        ]}
      >
        <>
          { isLoading  && <Spinner />}
          { error && <ErrorText>Could not read the document details</ErrorText>}
          {fetchedDocument && (
            <>
              <DocumentDetails className="rx-grid" data-testid="document-info">
                <PanelItem className="document-field" key="Document Name" data-testid="document-name">
                  <PanelLabel>{t('reports:DocName')}</PanelLabel>
                  <PanelText>{fetchedDocument?.title}</PanelText>
                </PanelItem>
                <PanelDiv>
                  <PanelItem
                    className="rx-col-1 document-edit"
                    key="Document Name Edit"
                    id="doc-edit"
                  >
                    {
                      <button
                        data-testid="document-edit-icon"
                        className="tiny icon-edit tooltip-container"
                        disabled={!checkPermission(contact)}
                        onClick={()=>  navigate({
                          pathname: `/reports/edit/${documentId}`,
                          search
                        })}
                      >
                        <div className="tooltip dark left-top">
                          <Trans i18nKey="reports:EditDocument" />
                        </div>
                      </button>
                    }
                  </PanelItem>
                  <PanelItem
                    className="rx-col-1 document-edit"
                    key="Document Name Delete"
                  >
                    <button
                      data-testid="document-delete-icon"
                      id="doc-delete-btn"
                      className="tiny icon-trash2 tooltip-container"
                      disabled={!checkPermission(contact)}
                      onClick={() => setModalType(MODAL_TYPE.CONFIRMATION)}
                    >
                      <div className="tooltip dark left-top">
                        <Trans i18nKey="reports:DeleteDocument" />
                      </div>
                    </button>
                  </PanelItem>
                </PanelDiv>
                <br />
                <InformationPanel>
                  <PanelItem className="document-field" key="Document Type" data-testid="document-type">
                    <PanelLabel className="param__label">
                      {t('reports:DocType')}
                    </PanelLabel>
                    <PanelText>{fetchedDocument?.type}</PanelText>
                  </PanelItem>
                  <PanelItem className="document-field" key="Document Status" data-testid="document-status">
                    <PanelLabel className="param__label">
                      {t('reports:DocStatus')}
                    </PanelLabel>
                    <PanelText>{fetchedDocument?.status}</PanelText>
                  </PanelItem>
                  <PanelItem className="document-field" key="Creation Date" data-testid="document-creation-date">
                    <PanelLabel className="param__label">
                      {t('opportunities:CreatedDate')}
                    </PanelLabel>
                    <PanelText>{fetchedDocument?.startDate
                    ? moment(fetchedDocument?.startDate).format('YYYY-MM-DD')
                    : moment(fetchedDocument?.createdAt).format('YYYY-MM-DD')}</PanelText>
                  </PanelItem>
                  <PanelItem className="document-field" key="Created By" data-testid="document-created-by">
                    <PanelLabel className="param__label">
                      {t('common:CreatedBy')}
                    </PanelLabel>
                    <PanelText>{fetchedDocument?.contact}</PanelText>
                  </PanelItem>
                </InformationPanel>
                <InformationPanel>
                  <PanelItem className="document-field" key="Location" data-testid="document-location">
                    <PanelLabel className="param__label">
                      {t('common:Building')}
                    </PanelLabel>
                    <PanelText className="param__value" id="doc-location">
                      {fetchedDocument?.building?.name}
                    </PanelText>
                  </PanelItem>
                  <PanelItem
                    className="document-field"
                    key="Visible To Customer"
                    data-testid="document-visibility"
                  >
                    <PanelLabel className="param__label">
                      {t('common:Visible')}
                    </PanelLabel>
                    <PanelText>
                      {fetchedDocument?.visibility ? 'On' : 'Off'}
                    </PanelText>
                  </PanelItem>
                </InformationPanel>
                <div className="document-field" key="Notes" data-testid="document-supporting-notes">
                  <PanelLabel className="param__label">
                    {supportingNotesTitle}
                  </PanelLabel>
                  <PanelText>{fetchedDocument?.note}</PanelText>
                </div>
              </DocumentDetails>
              <hr className="section-divider" />
              <div className="attached-files" id="doc-attachments" data-testid="doc-attachments">
                <Label text={t('notes:AttachedFiles')} />
                <AttachedFiles
                  mode={ACTIONS.VIEW}
                  attachments={attachments}
                  downloadFileFunc={(e) => downloadFileFunc(e)}
                />
              </div>
            </>
          )}
        </>
      </Modal>
    </>
  )
}

export default DocumentsViewModal
