import TextEditor from 'src/components/text-editor'
import translate, { TranslateComponent } from 'src/common/translations'
import { DenaliTextEditorToolbarOptions } from './helpers'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { DenaliFormTextEditorProps, DenaliTextEditorValue } from './types'
import { forwardRef, useCallback } from 'react'
import { UiMode } from 'src/redux/types/AppTypes'

export const DenaliFormTextEditor = forwardRef(
  function DenaliFormTextEditorComponent(
    {
      value,
      onChange,
      uiMode
    }: DenaliFormTextEditorProps & { uiMode: UiMode | string },
    _
  ) {
    const onChangeHandler = useCallback(
      (draft: DenaliTextEditorValue) => {
        onChange(draft)
      },
      [onChange]
    )

    return (
      <TextEditor
        className={formStyles.textEditor}
        uiMode={uiMode}
        textEditorToolbarOptions={DenaliTextEditorToolbarOptions}
        placeholder={translate('Start typing here...')}
        content={value}
        changeContent={onChangeHandler}
      />
    )
  }
)
