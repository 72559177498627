import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table/graphQLTable'
import { useNavigate } from 'react-router-dom'
import { GET_USERS_LAMBDA } from '../graphql/queries'
import { pages } from 'src/pages/pages.js'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import moment from 'moment'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { useSelector } from 'react-redux'
import { selectUserAccess, selectUserInfo } from '../../../redux/slicers/appData'
import translate, { TranslateComponent } from '../../../common/translations'
import { getDateFormatByLocale } from '../../../common/chartHelperFunctions'
import { useQuery } from "../../../hooks/APIHooks";
import { LIST_USERTYPES } from "../../userTypeManagement/graphql";
import { useDispatch } from 'react-redux'
import { translateJSON } from '../../../redux/slicers/translationCombinedSlice'

export const UserManagementTable = ({setDeactivateUserDialog, setReactivateUserDialog, refetch}) => {
  const navigate = useNavigate()
  const userAccess = useSelector(selectUserAccess)
  const userInfo = useSelector(selectUserInfo)
  const [userTypeFilters, setUserTypeFilters] = useState([])

  const {
    data: data1,
    refetch: refetchData1,
    responseTime: responseTime1,
    loading: loadingUserTypeFilters
  } = useQuery({
    query: LIST_USERTYPES,
    errorPolicy: 'all',
    dataPath: 'data.listUserTypes.items',
    onSuccess: (data) => {
      setUserTypeFilters(data)
    },
  })

  const headersOldTable = [
    {
      title: <TranslateComponent>Last Name</TranslateComponent>,
      key: 'lastName',
      onDataClick: (rowData) => {
        navigate(
          pages['User Management/user-details/:id'].href.replace(':id', rowData.id)
        )
      },
      maxWidth: '120px'
    },
    {
      title: <TranslateComponent>First Name</TranslateComponent>,
      key: 'firstName',
      onDataClick: (rowData) => {
        navigate(
          pages['User Management/user-details/:id'].href.replace(':id', rowData.id)
        )
      },
      maxWidth: '120px'
    },
    {
      title: <TranslateComponent>Email</TranslateComponent>,
      key: 'email',
      onDataClick: (rowData) => {
        navigate(
          pages['User Management/user-details/:id'].href.replace(':id', rowData.id)
        )
      },
      maxWidth: '150px'
    },
    {
      title: <TranslateComponent>Created On</TranslateComponent>,
      key: 'createdAt',
      formatData: (e) => {
          return moment(e, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(getDateFormatByLocale("YYYY-MM-DD"))
      },
      maxWidth: '80px'
    },
    {
      title: <TranslateComponent>Status</TranslateComponent>,
      key: 'isActive',
      sortField: "isActive",
      disabled: true,
      formatData: (e) => {
        if (e === 1) {
          return "Active"
        } else if (e === 0) {
          return "Disabled"
        }
      },
      maxWidth: '80px',
      customComponent: (row)=>{
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p><TranslateComponent>{row?.isActive}</TranslateComponent></p>
          </div>
        )
      }
    },
    {
      title: <TranslateComponent>User Type</TranslateComponent>,
      key: 'type.name',
      maxWidth: '100px',
      alignRight: true,
      disabled: true,
      sortable: false,
      customComponent: (row)=>{
        return (
          <div style={{  alignItems: 'center' }}>
            <p><TranslateComponent>{row?.type?.name}</TranslateComponent></p>
          </div>
        )
      }
    },
    {
      name: "actions",
      title: "",
      key: "actions",
      sortable: false,
      disabled: true,
      hoverOnlyVisible: true,
    }
  ]

  const [statusTranslates, setStatusTranslates] = useState([])
  const [dateTranslate, setDateTranslate] = useState([])
  const valTranslate = translate("Buildings")
  useEffect(() => {
      const datas = [
        { name: 'All Types', value: '' }, 
        ...userTypeFilters.map(e => ({ name: e.name, value: e.id }))
      ]
    
      sourceTranslationHelper(datas)
        .then(res => {
          const translatedData = datas.map(item => {
            const translatedName = res[item.name] || item.name;
            return {
              ...item,
              name: translatedName,
              value: item.value,
            }
          })
          setStatusTranslates(translatedData)
        })
        .catch(error => {
          setStatusTranslates(datas)
        })
  }, [userTypeFilters, valTranslate])

  const option2 = [
    { name: 'All Statuses', value: '' },
    { name: 'Active', value: '1' },
    { name: 'Disabled', value: '0' }
  ]
  const createSourceDisplayName = (table) => {
    const translateFinalSummary = table.reduce((acc, item) => {
      if (item.name && !acc.hasOwnProperty(item.name)) {
        acc[item.name] = item.name;
      }
      return acc;
    }, {});
    return translateFinalSummary
  }

  const dispatch = useDispatch()
  useEffect(() => {
    if(option2.length > 0)
    {
      sourceTranslationHelper(option2).then(res => 
        setDateTranslate(option2.map((item)=>{
          return{
            ...item,
            name: res[item.name],
            value: item.value
          }
        }))
      ).catch(error => {
        setDateTranslate(option2)
      })
    }  
  }, [dispatch, userTypeFilters])

  const sourceTranslationHelper = async (dataTotranslate) => {
    let finalData;
    const tempList = createSourceDisplayName(dataTotranslate)
    const analaogList = await dispatch(translateJSON({ json: tempList })).then(res => {
      finalData = res.payload
    })
    return finalData
  }



  const [filters, setFilters] = useState([])

useEffect(() => {
  setFilters([
    {
      key: 'userTypeId',
      id: 'userTypeId',
      defaultLabel: "All Types",
      options: statusTranslates
    },
    {
      key: 'isActive',
      id: 'isActive',
      defaultLabel: "All Statuses",
      options: dateTranslate
    }
  ])
}, [statusTranslates, dateTranslate])

  return (
    <Table
      key="nameUserTable"
      header={headersOldTable}
      query={{ query: GET_USERS_LAMBDA, dataPath: 'data.listUsersByAccesses', variables: {filter: {or: userInfo?.type?.authUserTypes.map(e => ({userTypeId: {eq: e}}))}} }}
      search={true}
      showSpinner={true}
      filtersList={filters}
      searchFields={['lastName', 'firstName', 'email', ["firstName", "lastName"]]}
      fixedLayout={false}
      refetch={refetch}
      stringifyFields={{
        sort: true,
        filter: true
      }}
      rowControl={(e) => {
        const buttons = []
        if (accessControlFunc({id: [`tc.pages.user-management.activate`], userAccess})) {
          if (e.isActive === "Active") {
            buttons.push({
              text: <TranslateComponent>Deactivate</TranslateComponent>,
              action: (data) => {
                setDeactivateUserDialog(data)
                trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_DEACTIVATE_USER, {"location":"User List"})
              }
            })
          } else {
            buttons.push({
              text: <TranslateComponent>Activate</TranslateComponent>,
              action: (data) => {
                setReactivateUserDialog(data)
                trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ACTIVATE_USER, {"location":"User List"})
              }
            })
          }
        }
        
        return buttons
      }}
      isFilterChange={true}
    />
  )
}

UserManagementTable.propTypes = {}

UserManagementTable.defaultProps = {}

export default UserManagementTable
