import styled from 'styled-components'

export const Parent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 25%));
  padding: 10px;
`

export const ToolTipContainer = styled.div`
margin-left: 3px;
.tooltip-base.default {
  height: 75px;

  &.center {
    top: 100% !important;
  }
    &.right {
    top: 100% !important;
  }
    &.left {
    top: 100% !important;
  }
}
`

export const HeaderItem = ({ title, content, edit = false, handleChange = any => { } }) =>
  <div style={{ display: "flex", flexFlow: "column", padding: "10px 50px 10px 10px", minWidth: "150px" }}>
    <div style={{ fontSize: "12px", color: "#494949", paddingBottom: "5px" }}>{title}</div>
    {edit
      ? <input style={{ borderRadius: "6px", border: "1px solid rgb(204, 204, 204)" }} value={content} onChange={handleChange} />
      : <div style={{ fontSize: "14px", color: "#494949", lineHeight: "15px" }}>{content}</div>}
  </div>

export const TabsWrapper = styled.div`
  border: 1px gray;
  margin: 20px auto;
  li:not(table li) {
    border: 1px solid lightgray;
    border-bottom: none;
    background-color: #ccc;
    color: #292323;
    font-weight: 600;
    border-radius: 5px 5px 0 0 !important;
    justify-content: flex-start;
    font-size: 13px;
    padding-left: 15px;
    max-width: 20%;
    z-index: 1;
  }
  .active {
    background-color: #fff;
    color: #000;
  }
  li {
    margin: 0 5px;
  }
  li:first-child {
    margin-left: 0;
  }
  ul + * {
    position: relative;
    top: -1px;
    margin: unset;
  }
  table * {
    vertical-align: unset;
  }
`