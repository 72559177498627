import React, { PureComponent } from 'react'
import Modal from '../modal/modal'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import styled from 'styled-components'
import { TranslateComponent } from '../../../../common/translations'

const ErrorDialogContainer = styled.div`
  display: block;
  width: 100%;
`

const ErrorDialogText = styled.div`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
`

const ErrorDialogDescription = styled.div`
  font-style: normal;
  letter-spacing: -0.36px;
  font-size: 15px;
  font-weight: 500;
`

export default class ErrorDialog extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    closeText: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    type: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    translateText: PropTypes.bool
  }

  static defaultProps = {
    text: '',
    type: 'unexpected-error',
    error: true,
    className: '',
    description: null,
    translateText: true
  }

  render = () => (
    <Modal
      heading={this.props.title || "Error"}
      buttons={[
        {
          type: 'confirm',
          text: <TranslateComponent>{this.props.closeText || "Close"}</TranslateComponent>,
          handleClick: this.props.handleClose
        }
      ]}
      handleClose={this.props.handleClose}
      error={this.props.error}
      className={this.props.className}
    >
      <ErrorDialogContainer>
        <ErrorDialogText>
          {this.props.error && (
            <div style={{ flexShrink: 0 }} className="icon-error" />
          )}
          {
            this.props.translateText ?
            (!this.props.errorWithText && (
              <p> <TranslateComponent>{this.props.text || i18next.t(`errors:${this.props.type}`)}</TranslateComponent> </p>
            ))
            :
            (<p>{this.props.text || i18next.t(`errors:${this.props.type}`)}</p>)
          }
        </ErrorDialogText>
        {this.props.description && (
          <ErrorDialogDescription>
            {this.props.description.props ? (
              this.props.description
            ) : (
              <div style={{ marginTop: '30px' }}>{this.props.description}</div>
            )}
          </ErrorDialogDescription>
        )}
      </ErrorDialogContainer>
    </Modal>
  )
}
