export const LIST_RATE_STRUCTURES_BY_SALESOFFICE_ID = `
query LIST_RATE_STRUCTURES_BY_SALESOFFICE_ID(
  $id: ID!,
  $nextToken: String
) {
  listRateStructureBySalesOffice(salesOfficeId: $id, nextToken:$nextToken) {
    nextToken
    items {
      id
      name
      type
      charges {
        items {
          id
          meters {
            items {
              chargeId
              equipmentId
              salesOfficeId
              buildingId
              id
            }
          }
          startDate
          endDate
        }
      }
    }
  }
}
`


export const SEARCH_BUILDINGS_FOR_EQUIPMENT_BY_SALESOFFICE_ID_EQUIPMENTSUB = `
query SEARCH_BUILDINGS_BY_SALESOFFICE_ID(
  $filter: SearchableBuildingFilterInput
  $sort: [SearchableBuildingSortInput]
  $limit: Int
  $nextToken: String
  $electricEquipmentNextToken: String
  $gasEquipmentNextToken: String
) {
  searchBuildings(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        isActive
        name
        gasMeters: equipments(type: {eq: "GAS"}, limit: 1000, nextToken: $gasEquipmentNextToken, filter: {and: [{name: {attributeExists: true}}, {isActive: {eq: 1}}]}) {
          nextToken
          items {
            id
            type
            name
            isActive
          }
        }
        electricMeters: equipments(type: {eq: "ElectricMeter"}, limit: 1000, nextToken: $electricEquipmentNextToken, filter: {and: [{name: {attributeExists: true}}, {isActive: {eq: 1}}]}) {
          nextToken
          items {
            id
            type
            name
            isActive
          }
        }
      }
    }
}
`

export const CREATE_CHARGE_EQUIPMENT = `
mutation CREATE_CHARGE_EQUIPMENT(
  $input: CreateEquipmentChargeInput!
) {
  createEquipmentCharge(input: $input) {
    id
  }
}
`

export const DELETE_CHARGE_EQUIPMENT = `
mutation DELETE_CHARGE_EQUIPMENT(
  $input: DeleteEquipmentChargeInput!
) {
  deleteEquipmentCharge(input: $input) {
    id
  }
}
`