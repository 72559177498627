import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import successImage from 'src/assets/images/Success.svg'
import warningImage from 'src/assets/images/Warning.svg'
import '../Characteristics/Characteristics-legacyStyle.scss'
import { AutomatedTestesContainer, InfoText } from './styles'
import { AutomatedTestsSettingsEditDialog } from './Automated-Tests-Edit-Dialog'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { TranslateComponent } from '../../../../common/translations'
import { useNavigate } from 'react-router'

const AutomatedTests = ({
  automatedTestsData,
  onEdit : onDataMappingEdit,
  equipmentDetails,
  displayNameListProperties,
  defaultDimensionGroup,
  isLoading,
  isAllTestsMapped,
  refetchDataMappingPropertiesATS,
  refetchCharacteristicsATS,
  activeAutomatedTest
}) => {
  const [showTestSettingsEditDialog, setShowTestSettingsEditDialog] =
    useState(false)
  const [selectedTest, setSelectedTest] = useState({})

  let sliceCount =
    automatedTestsData?.length <= 20
      ? 10
      : Math.ceil(automatedTestsData?.length / 2)

  const firstHalfAutomatedTests = automatedTestsData?.slice(0, sliceCount)

  const secondHalfAutomatedTests = automatedTestsData?.slice(
    sliceCount,
    automatedTestsData?.length
  )

  const navigate = useNavigate()
  const toggleDialog = () => {
    setShowTestSettingsEditDialog(!showTestSettingsEditDialog)
  }

  useEffect(() => {
    if (
      activeAutomatedTest &&
      automatedTestsData?.length > 0 &&
      !isLoading
    ) {
      let selectedTest = automatedTestsData?.find(
        (x) =>
          x?.displayName?.replace(/^\s+|\s+$/gm, '').replace(/\s+/g, '') ===
          activeAutomatedTest
      )
      setSelectedTest(selectedTest)
      setShowTestSettingsEditDialog(true)
    }
  }, [activeAutomatedTest, automatedTestsData, isLoading])

  const onSavePropertiesATSChangesHandler = () => {
    toggleDialog()
    refetchDataMappingPropertiesATS(true)
  }

  const onSaveCharacteristicsATSChangesHandler = () => {
    toggleDialog()
    refetchCharacteristicsATS(true)
  }

  const onTestClickHanlder = (testId) => {
    const selectedTest = automatedTestsData?.find((x) => x.id === testId)
    if (selectedTest) {
      setSelectedTest(selectedTest)
      toggleDialog()
    }
  }

  const onToggleClose = () => {
    const url = new URL(window.location)
    if (url.searchParams.has('automatedtest')) {
      url.searchParams.delete('automatedtest')
      navigate({ search: url.search }, { replace: true })
    }
    toggleDialog()
  }
  
  return (
    // TODO - Move some styles to Global
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {!isAllTestsMapped && (
            <InfoText marginBottom>
              <TranslateComponent>
              One or more of the automated tests has undefined properties,
              characteristics, or associations. Without these inputs selected,
              the automated test may not run.
              </TranslateComponent>
            </InfoText>
          )}
          <AutomatedTestesContainer
            data-testid={`${DETAILS_TEXT_NAME_PREFIX('automatedTests')}`}
          >
            <div className="rx-grid rx-grid-small-spacing rx-grid-fit">
              <div className="rx-col-3">
                <div className="rx-col-2">
                  <span>
                    <strong><TranslateComponent>Status</TranslateComponent></strong>
                  </span>
                </div>
                <div className="rx-col-10">
                  <span>
                    <strong><TranslateComponent>Test Name</TranslateComponent></strong>
                  </span>
                </div>
              </div>
              {secondHalfAutomatedTests?.length > 1 ? (
                <div className="rx-col-3">
                  <div className="rx-col-2">
                    <span>
                      <strong><TranslateComponent>Status</TranslateComponent></strong>
                    </span>
                  </div>
                  <div className="rx-col-10">
                    <span>
                      <strong><TranslateComponent>Test Name</TranslateComponent></strong>
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="rx-grid rx-grid-small-spacing rx-grid-fit">
              <div className="rx-col-3">
                {(firstHalfAutomatedTests || []).map((test) => (
                  <>
                    <div className="rx-col-2">
                      <span style={{ marginLeft: '15px' }}>
                        <img
                          alt="success"
                          src={test.mappingStatus ? successImage : warningImage}
                        />
                      </span>
                    </div>
                    <div className="rx-col-10" key={test?.id}>
                      <a href="#" onClick={() => onTestClickHanlder(test.id)}>
                        <TranslateComponent>{test?.displayName}</TranslateComponent>
                      </a>
                    </div>
                  </>
                ))}
              </div>
              {secondHalfAutomatedTests?.length > 1 ? (
                <div className="rx-col-3">
                  {(secondHalfAutomatedTests || []).map((test) => (
                    <>
                      <div className="rx-col-2">
                        <span style={{ marginLeft: '15px' }}>
                          <img
                            alt="warning"
                            src={
                              test.mappingStatus ? successImage : warningImage
                            }
                          />
                        </span>
                      </div>
                      <div className="rx-col-10" key={test.id}>
                        <a href="#" onClick={() => onTestClickHanlder(test.id)}>
                          <TranslateComponent>{test?.displayName}</TranslateComponent>
                        </a>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </AutomatedTestesContainer>
        </>
      )}
      {showTestSettingsEditDialog && (
        <AutomatedTestsSettingsEditDialog
        onDataMappingEdit = {onDataMappingEdit}
          equipmentDetails={equipmentDetails}
          displayNameListProperties={displayNameListProperties}
          toggleDialog={onToggleClose}
          defaultDimensionGroup={defaultDimensionGroup}
          selectedTest={selectedTest}
          atsDataMappingProperties={selectedTest?.propertiesListForTest}
          atsCharactertistics={selectedTest?.charactetisticsListFortest}
          onSavePropertiesATSChangesHandler={onSavePropertiesATSChangesHandler}
          onSaveCharacteristicsATSChangesHandler={
            onSaveCharacteristicsATSChangesHandler
          }
        />
      )}{' '}
    </>
  )
}

export default withTranslation()(AutomatedTests)
