import Table from '../../components/Table/clientSideTable'
import { ACTIONS } from 'src/constants'
import { MODAL_TYPE, ROW_CONTROL_TEXT } from './constants'
import { useOpportunityContext } from './OpportunityContextProvider'
import {
  SEARCH_OPPORTUNITIES_BY_ACCOUNT_ID,
  SEARCH_OPPORTUNITIES_BY_BUILDING_ID,
  SEARCH_OPPORTUNITIES_BY_BUILDING_IDS
} from './graphql'
import { useEffect, useMemo, useState } from 'react'
import {
  formatStatusWithTranslateFunc,
  formatNextStepWithTranslateFunc,
  KPI_KEYS,
  statusOptionList
} from '../../components/legacy/common/opportunity'
import { useQuery } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { getInitialOpportunity } from './helpers'
import translate, { TranslateComponent } from 'src/common/translations'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { accessControlFunc } from 'src/components/accessControl'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import OpportunitiesList from 'src/denali-pages/Opportunities/OpportunitiesList'
import { UiMode } from 'src/redux/types/AppTypes'
import {isDateInBW} from "../documentsAndReports/consultation/helperFunction.js"
import { GET_CONSULTATION_BUILDINGS} from 'src/pages/documentsAndReports/graphql'
import { GET_BUILDINGS_BY_ACCOUNT_ID} from 'src/common/queries/datasource'
import { useAppDispatch } from 'src/redux/store'
import { RootState } from 'src/redux/RootState'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'


const OpportunityList = (
  {
    uiMode,
    handleGetOpportunitiesRecords = () => { },
    handleCheckBoxClick = () => { },
    enableShowPagination = false,
    enableRowCheckbox = false,
    selectedItems = [],
    isFromConsultation = false,
    handleOpportunityListData,
    noTableDataAction
  }: any
) => {
  const {
    setMode,
    setOpportunity,
    reloadEvents,
    setReloadEvents,
    setModalType,
    isDeletedDone,
    setOpportunityId,
    setOpportunityInputValues,
    setIsDeletedDone,
    currentDate,
    userName,
    setDataList,
    setFilteredData
  } = useOpportunityContext()
  const {organizationId: accountId, buildingId,startDate,endDate, consultationId} = getSearchParams()
  const [searchParams] = useSearchParams()
  const [rows, setRows] = useState([])
  const [rowsHaveBeenSet, setRowsHaveBeenSet] = useState(false)
  const [responseTime, setResponseTime] = useState(null)
  const [opportunitySelectedRows, setOpportunitySelectedRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [t] = useTranslation()
  const userAccess = useSelector(selectUserAccess)
  const navigate = useNavigate()
  const optionsTranslated = {
    opportunity: translate('Opportunity'),
    roi: translate("ROI"),
    price: translate('Price'),
    keyMetrics: translate('Key Metrics'),
    building: translate('Building'),
    status: translate('Status'),
    nextStep: translate('Next Step'),
    priority: translate('Priority'),
    copy: translate(ROW_CONTROL_TEXT.Copy),
    edit: translate(ROW_CONTROL_TEXT.Edit),
    delete: translate(ROW_CONTROL_TEXT.Delete),

  }
  const STATUS = [
    {key:'recommended', value:'Recommended'},
    {key:'complete', value: 'Complete'},
    {key:'deferred', value:'Trane Deferred'},
    {key:'customerDeferred', value: 'Customer Deferred'},
    {key:'inProgress', value: 'In Progress'},
    {key:'validation', value: 'Validation'},
    {key:'archive', value: 'Archive'},
  ]
  const runAmplitudeLogic = (curState, oppID) => {
    if(curState === ACTIONS.COPY)
    {
      trackEvent(USER_EVENTS.OPPORTUNITIES.events.CLICK_COPY_OPPORTUNITY, {
        "opportunity id": oppID,
        "building id": buildingId,
        "organization id": accountId
      })
    }
    else if(curState === ACTIONS.EDIT)
    {
      trackEvent(USER_EVENTS.OPPORTUNITIES.events.CLICK_EDIT_OPPORTUNITY, {
        "opportunity id": oppID,
        "building id": buildingId,
        "organization id": accountId
      })
    }
    else if(curState === ACTIONS.DELETE)
    {
      trackEvent(USER_EVENTS.OPPORTUNITIES.events.CLICK_DELETE_OPPORTUNITY, {
        "opportunity id": oppID,
        "building id": buildingId,
        "organization id": accountId
      })
    }
  }

  const { data: buildingsList, refetch: refetchBuildingsData } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    variables: { id: accountId },
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.listBuildingsByAccount.items'
  })
  const { data: consultationData, refetch: refetchConsultationDetails } =
    useQuery({
      query: GET_CONSULTATION_BUILDINGS,
      dataPath: 'data',
      variables: { id: consultationId },
      disableInitialLoad: true,
      errorPolicy: 'ignore'
    })
  useEffect(() => {
    if (consultationId) {
      refetchConsultationDetails({ id: consultationId })
    }
  }, [consultationId])

  useEffect(() => {
    if (accountId) {
      refetchBuildingsData({ id: accountId })
    }
  }, [accountId])

  const multipleBuildingIds = consultationData?.getConsultation?.buildingIds

  const {
    data: listData,
    refetch: refetchOpportunityList,
    responseTime: listResponseTime,
    loading: listDataIsLoading
  } = useQuery(
    !buildingId
      ? {
          query: SEARCH_OPPORTUNITIES_BY_ACCOUNT_ID,
          dataPath: 'data',
          variables: { accountId },
          disableInitialLoad: true,
          errorPolicy: 'ignore',
          onSuccess: () => {
            setIsDeletedDone(false)
          }
        }
      : multipleBuildingIds && consultationId
      ? {
          query: SEARCH_OPPORTUNITIES_BY_BUILDING_IDS,
          dataPath: 'data',
          variables:
            multipleBuildingIds && consultationId
              ? {
                  filter: {
                    or: multipleBuildingIds.map((id) => ({
                      buildingId: { eq: id }
                    }))
                  },
                  limit: 1000,
                  sort: [
                    {
                      direction: 'asc',
                      field: 'title'
                    }
                  ]
                }
              : {},
          disableInitialLoad: true,
          errorPolicy: 'ignore',
          onSuccess: () => {
            setIsDeletedDone(false)
          }
        }
      : {
          query: SEARCH_OPPORTUNITIES_BY_BUILDING_ID,
          dataPath: 'data',
          variables: { buildingId },
          disableInitialLoad: true,
          errorPolicy: 'ignore',
          onSuccess: () => {
            setIsDeletedDone(false)
          }
        }
  )
  
  const listDataItems = listData?.searchOpportunities?.items
  const nameHeadings = [
    {
      title: optionsTranslated.opportunity,
      key: 'title',
      maxWidth: '120px',
      onDataClick: (data) => {
        setOpportunityId(data.id)
        navigate(`/${PAGE_NAMES.OPPORTUNITIES}/${data.id}`, {
          state: { mode: ACTIONS.VIEW, id: data.id }
        })
      }
    },
    {
      title: optionsTranslated.roi,
      key: 'paybackTerm',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.price,
      key: 'price',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.keyMetrics,
      key: 'keyMetrics',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.building,
      key: 'buildingName',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.status,
      key: 'status',
      maxWidth: '120px',
	    customComponent: (row) => { return <TranslateComponent>{row.status}</TranslateComponent> }
    },
    {
      title: optionsTranslated.nextStep,
      key: 'nextStep',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.priority,
      key: 'priority',
      maxWidth: '120px'
    }
  ]

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
    }
  }, [reloadEvents, searchParams])

  useEffect(() => {
    if (multipleBuildingIds && consultationId) {
      const variables = {
        filter: {
          or: multipleBuildingIds.map(id => ({
            buildingId: { eq: id }
          }))
        },
        limit: 1000,
        sort: [
          {
            direction: 'asc',
            field: 'title'
          }
        ]
      };
      setIsLoading(false)
      refetchOpportunityList(variables);
    } else if (buildingId && !consultationId) {
      setIsLoading(false)
      refetchOpportunityList({ buildingId });
    } else if (accountId && !consultationId) {
      setIsLoading(false)
      refetchOpportunityList({ accountId });
    }
  }, [buildingId, accountId, multipleBuildingIds, consultationId]);
  useEffect(() => {
    if (isDeletedDone) {
      setTimeout(() => {
        refetchOpportunityList()
      }, 1000);
    }
  }, [isDeletedDone, searchParams])

  useEffect(() => {
    setMode(null)
    setOpportunity(
      getInitialOpportunity(accountId, buildingId, currentDate, userName)
    )
  }, [])

  const comfort = translate(KPI_KEYS.Comfort)
  const compliance = translate(KPI_KEYS.Compliance)
  const energyUsage = translate(KPI_KEYS.EnergyUsage)
  const performance = translate(KPI_KEYS.Performance)
  const reliability = translate(KPI_KEYS.Reliability)

  const getKeyMetrics = (data) => {
    let metrics = ''
    if (data?.impactComfortDisplay)
      metrics = metrics.concat(' ', comfort)
    if (data?.impactComplianceDisplay)
      metrics = metrics.concat(',', compliance)
    if (data?.impactEnergyDisplay)
      metrics = metrics.concat(',', energyUsage)
    if (data?.impactPerformanceDisplay)
      metrics = metrics.concat(',', performance)
    if (data?.impactReliabilityDisplay)
      metrics = metrics.concat(',', reliability)
    if (data?.impactCustom1Display)
      metrics = metrics.concat(',', data?.impactCustom1Name)
    if (data?.impactCustom2Display)
      metrics = metrics.concat(',', data?.impactCustom2Name)
    return metrics
  }

  useEffect(() => {
    if(selectedItems?.length === 0 && !isFromConsultation) return
    setOpportunitySelectedRows(selectedItems)
  }, [selectedItems])

  const statusTranslate = {
    allStatuses: translate("All Statuses"),
    recommended: translate("Recommended"),
    deferred: translate("Trane Deferred"),
    proposalDevelopment: translate("Recommended"),
    proposalReady: translate("Recommended"),
    inProgress: translate("In Progress"),
    validation: translate("Validation"),
    complete: translate("Complete"),
    customerDeferred: translate("Customer Deferred"),
    collectingData: translate("Collecting Data"),
    readyForReview: translate("Ready for Review"),
    archive: translate("Archive"),
    other: translate("Other")
  }

  const statusData = {
    allStatuses: "All Statuses",
    recommended: "Recommended",
    deferred: "Trane Deferred",
    proposalDevelopment: "Recommended",
    proposalReady: "Recommended",
    inProgress: "In Progress",
    validation: "Validation",
    complete: "Complete",
    customerDeferred: "Customer Deferred",
    collectingData: "Collecting Data",
    readyForReview: "Ready for Review",
    archive: "Archive",
    other: "Other"
  }

  const priorityTranslate = {
    "Not Prioritized": translate("Not Prioritized"),
    "URGENT!": translate("URGENT!"),
    "High": translate("High"),
    "Medium": translate("Medium"),
    "Low": translate("Low")
  }

  const nextStepTranslate = {
    "traneOfficeReview": translate("Trane Office Review"),
    "buildingPersonnelReview": translate("Building Personnel Review"),
    "remoteService": translate("Remote Service"),
    "BASTechOnSite": translate("BAS Tech On-Site"),
    "mechTechOnSide": translate("Mech Tech On-Site"),
    "archive": translate("Archive"),
    "complete": translate("Complete")
  }
  const impact = {
    low: translate("low"),
    medium: translate("medium"),
    high: translate("high"),
  }

  const notEstimated = translate("Not Estimated")
  
  useEffect(() => {
    if (listData === null) {
      return
    }
    setResponseTime(listResponseTime)
    let listBuildingsByAccount = []
    let listOpportunities = []
    if (buildingId) {
      const buildingDetails = listData?.searchBuildings?.items?.[0] || null
      listBuildingsByAccount = buildingDetails ? [buildingDetails] : []
      listOpportunities = listData?.searchOpportunities?.items || []
    } else {
      listBuildingsByAccount = listData?.searchBuildings?.items || []
      listOpportunities = listData?.searchOpportunities?.items || []
    }
    const opportunityList = listOpportunities
    ?.filter((row) =>
      !row?.isVisible
        ? accessControlFunc({
            id: 'tc.pages.opportunities.view-private',
            userAccess
          })
        : row?.isVisible
    )
    ?.map((row) => ({
      ...row,
      priority: priorityTranslate[row?.priority],
      impactComfort: impact[row?.impactComfort],
      impactEnergy: impact[row?.impactEnergy],
      impactPerformance: impact[row?.impactPerformance],
      impactReliability: impact[row?.impactReliability],
      impactCompliance:impact[row?.impactCompliance],
      buildingName:
      buildingsList?.find(it=>it.key === row?.buildingId)?.value ||
        '',
      keyMetrics: getKeyMetrics(row),
      price: row?.price ? `$${row?.price}` : notEstimated,
      paybackTerm: <TranslateComponent>{`${row?.paybackTerm ?? '0'} ${row?.paybackUnit ?? ''}`}</TranslateComponent>,
      status: statusData[row?.status],
      statusTranslate: statusTranslate[row?.status],
      nextStep: nextStepTranslate[row?.nextStep],
      checkboxCheckedEnabled: opportunitySelectedRows
        ?.map((i) => i.key)
        ?.includes(row?.id)
    }))
    setRows(startDate && endDate ?  opportunityList 
      .filter((it) => it?.creationDate)
      .filter((it) => {
        return isDateInBW(startDate, endDate, it?.creationDate)
      }) : opportunityList)
    setRowsHaveBeenSet(true)
    setDataList(opportunityList)
    handleGetOpportunitiesRecords(listData?.searchOpportunities?.items ?? [])
  }, [listData, opportunitySelectedRows, notEstimated])

  const handleUpdatedTableRows = (updatedRows) => {
    const updatedRowIds = updatedRows?.map(m=>m.id) ?? [];
    const filteredItems= rows.filter(i=>{
      return updatedRowIds.includes(i?.id);
    })
    handleGetOpportunitiesRecords(filteredItems)
    setFilteredData(filteredItems)
  }

    useEffect(() => {
      if (listDataItems?.length && handleOpportunityListData) {
        handleOpportunityListData(listDataItems);
      }
    }, [listDataItems?.length]);

    const getListRowControl = () => {
      return !isFromConsultation?[
        accessControlFunc({id: "tc.pages.opportunities.edit", userAccess}) ? {
          text: optionsTranslated.edit,
          action: (data) => {
            setOpportunityId(data.id)
            navigate(`/opportunities/${data.id}`, {
              state: { mode: ACTIONS.EDIT, id: data.id }
            })
            runAmplitudeLogic(ACTIONS.EDIT, data.id)
          }
        } : null,
        accessControlFunc({id: "tc.pages.opportunities.add", userAccess}) ? {
          text: optionsTranslated.copy,
          action: (data) => {
            runAmplitudeLogic(ACTIONS.COPY, data.id)
            setOpportunityId(data.id)
            navigate(`/opportunities/${data.id}`, {
              state: { mode: ACTIONS.COPY, id: data.id }
            })
          }
        } : null,
        accessControlFunc({id: "tc.pages.opportunities.delete", userAccess}) ? {
          text: optionsTranslated.delete,
          action: (data) => {
            setOpportunityInputValues(data)
            setModalType(MODAL_TYPE.CONFIRMATION)
            runAmplitudeLogic(ACTIONS.DELETE, data.id)
          }
        } : null
      ].filter(e => e !== null) : []
    }


    const opportunityTypes = STATUS.map((s) => ({
      ...s,
      name: s?.value
    }))
  
  const appDispatch = useAppDispatch();
  const { translatedJSON } = useSelector((state: RootState) => state?.translationCombinedSlice)
    
    
  useEffect(() => {
    const translateFinalSummary = opportunityTypes.reduce((acc, item) => {
      if (item.value && !acc.hasOwnProperty(item.value)) {
        acc[item.value] = item.value;
      }
      if (item.name && !acc.hasOwnProperty(item.name)) {
        acc[item.name] = item.name;
      }
      return acc;
    }, {});

    appDispatch(translateJSON({ json: translateFinalSummary }) as any);
  }, [])

  const [opportunityTranslateList,setOpportunityTranslateList] = useState(opportunityTypes)

  useEffect(() => {
      setOpportunityTranslateList(opportunityTypes.map((item)=>{
        return{
          ...item,
          searchValue: translatedJSON[item.name]
        }
      }))
  }, [translatedJSON])

  const filtersList = useMemo(() => {
    return [
      {
        label: 'All Status',
        key: 'status',
        id: 'statusSelectorFilter',
        defaultLabel: 'All Status',
        selectedValue: 'default',
        multiple:true,
        options: opportunityTranslateList
      }
    ]
  }, [opportunityTranslateList])

  return (
    <>
        { uiMode === UiMode.denali
            ? <OpportunitiesList
                rows={rows}
                nameHeadings={nameHeadings}
                optionsTranslated={optionsTranslated}
                getListRowControl={getListRowControl}
                handleGetOpportunitiesRecords={handleGetOpportunitiesRecords}
                statusOptions={STATUS}
                listDataIsLoading={listData === null || !rowsHaveBeenSet || listDataIsLoading}
                noTableDataAction={noTableDataAction}
                isFilterChange={true}
              />
            : <Table
                key={`opportunityListTable-${buildingId}`}
                rows={rows ?? []}
                header={nameHeadings}
                loadTime={responseTime}
                search={true}
                searchFields={['title']}
                handleUpdatedTableRows={handleUpdatedTableRows}
                rowControl={getListRowControl}
                filtersList={filtersList}
                isShowPagination={enableShowPagination}
                rowCheckbox={enableRowCheckbox}
                checkboxClick={handleCheckBoxClick}
                showSpinner={listDataIsLoading || isLoading}
                isFilterChange={true}
              />
        }
    </>
  )
}

export default OpportunityList
