import { PROPOSAL_TYPE_FILE } from 'src/components/file-upload/file-upload'

export const getMimeTypes = (fileTypes) => {
  const mimeTypes = [
    { type: 'doc', mime: 'application/msword' },
    { type: 'dot', mime: 'application/msword' },
    {
      type: 'docx',
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    { type: 'docm', mime: 'application/vnd.ms-word.document.macroEnabled.12' },
    {
      type: 'dotx',
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    },
    { type: 'dotm', mime: 'application/vnd.ms-word.template.macroEnabled.12' },
    { type: 'docb', mime: 'application/vnd.ms-word.document.binary' },
    { type: 'xls', mime: 'application/vnd.ms-excel' },
    { type: 'xlt', mime: 'application/vnd.ms-excel' },
    {
      type: 'xlsx',
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    { type: 'xlsm', mime: 'application/vnd.ms-excel.sheet.macroEnabled.12' },
    {
      type: 'xltx',
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    },
    { type: 'xltm', mime: 'application/vnd.ms-excel.template.macroEnabled.12' },
    {
      type: 'xlsb',
      mime: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
    },
    { type: 'ppt', mime: 'application/vnd.ms-powerpoint' },
    { type: 'pot', mime: 'application/vnd.ms-powerpoint' },
    {
      type: 'pptx',
      mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    },
    {
      type: 'pptm',
      mime: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12'
    },
    {
      type: 'potx',
      mime: 'application/vnd.openxmlformats-officedocument.presentationml.template'
    },
    {
      type: 'potm',
      mime: 'application/vnd.ms-powerpoint.template.macroEnabled.12'
    },
    {
      type: 'ppsx',
      mime: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
    },
    {
      type: 'ppsm',
      mime: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'
    },
    {
      type: 'sldx',
      mime: 'application/vnd.openxmlformats-officedocument.presentationml.slide'
    },
    {
      type: 'sldm',
      mime: 'application/vnd.ms-powerpoint.slide.macroEnabled.12'
    },
    { type: 'jpg', mime: 'image/jpeg' },
    { type: 'jpeg', mime: 'image/jpeg' },
    { type: 'png', mime: 'image/png' },
    { type: 'gif', mime: 'image/gif' },
    { type: 'tif', mime: 'image/tiff' },
    { type: 'eps', mime: 'application/postscript' },
    { type: 'bmp', mime: 'image/bmp' },
    { type: 'svg', mime: 'image/svg+xml' },
    { type: 'webp', mime: 'image/webp' },
    { type: 'aif', mime: 'audio/aiff' },
    { type: 'm4a', mime: 'audio/mp4' },
    { type: 'mp3', mime: 'audio/mpeg' },
    { type: 'mpa', mime: 'audio/mpeg' },
    { type: 'wav', mime: 'audio/wav' },
    { type: 'wma', mime: 'audio/x-ms-wma' },
    { type: 'm4v', mime: 'video/mp4' },
    { type: 'mov', mime: 'video/quicktime' },
    { type: 'mp4', mime: 'video/mp4' },
    { type: 'mpg', mime: 'video/mpeg' },
    { type: 'wmv', mime: 'video/x-ms-wmv' },
    { type: 'pdf', mime: 'application/pdf' },
    { type: 'txt', mime: 'text/plain' },
    { type: 'rtf', mime: 'application/rtf' },
    { type: 'csv', mime: 'text/csv' }
  ]

  // Build an array of mime types
  return fileTypes.map(
    (fileType) => mimeTypes.find((mimeType) => mimeType.type === fileType)?.mime
  )
}

export const isAllowedFileType = (fileName: string) => {
  return PROPOSAL_TYPE_FILE.some(
    (extension: string) => extension === fileName.split('.').pop().toLowerCase()
  )
}
export const isAllowedFileSize = (file: File) => {
  return file.size < 30000000 && file.size >= 1
}

export const convertBytesToString = (bytes, iterations = 1) => {
  if (iterations > 4) return `1000+ GB`
  const reduce = bytes / 1024

  const suffixes = ['Bytes', 'KB', 'MB', 'GB']
  return reduce < 1
    ? `${Math.round(bytes)} ${suffixes[iterations - 1]}`
    : convertBytesToString(reduce, iterations + 1)
}
