import { InlineEquipmentSelectorContext } from './hooks/use-inline-equipment-selector-context'
import { InlineEquipmentSelectorContextProviderProps } from './types'

export const InlineEquipmentSelectorContextProvider = ({
  children,
  equipments,
  isEquipmentLoading,
  selectedTisObjects,
  setSelectedTisObjects,
  resetSearch,
  searchTerm,
  setSearchTerm,
  activeScroll,
  setActiveScroll,
  equipmentGroup
}: InlineEquipmentSelectorContextProviderProps) => {
  const contextData = {
    equipments,
    isEquipmentLoading,
    selectedTisObjects,
    setSelectedTisObjects,
    resetSearch,
    searchTerm,
    setSearchTerm,
    activeScroll,
    setActiveScroll,
    equipmentGroup
  }

  return (
    <InlineEquipmentSelectorContext.Provider value={contextData}>
      {children}
    </InlineEquipmentSelectorContext.Provider>
  )
}
