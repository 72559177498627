import { getRandomColor } from 'src/common/chartHelperFunctions'

export const groupByPropertykey = (properties, propertyKey) => {
  const groupByKey = {}
  const propertiesByKey = []
  properties?.forEach((property) => {
    const propertiesArray = groupByKey[property?.[propertyKey]] || []
    propertiesArray.push(property)
    groupByKey[property?.[propertyKey]] = [...propertiesArray]
  })

  for (const key in groupByKey) {
    propertiesByKey.push({
      [propertyKey]: key,
      properties: groupByKey[key]
    })
  }

  return propertiesByKey
}

const getEquipmentIdsFilter = (equipmentGroups) => {
  const filter = { isActive: { eq: 1 }, isTIS: { eq: 1 } }

  // this will add all the equipment ids needs to get the details for
  // filter.or = equipmentGroups?.map((props) => ({
  //   id: { eq: props?.equipmentId }
  // }))

  return filter
}

const getEquipmentDetails = async (buildingId, equipmentIdsFilter, fetchEquipmentsByBuildingId) => {
  try {
    let equipments = []
    let response = await fetchEquipmentsByBuildingId({
      id: buildingId,
      filter: equipmentIdsFilter
    })

    equipments = [...equipments, ...response.equipments.items]

    while(response.equipments.nextToken) {
      response = await fetchEquipmentsByBuildingId({
        id: buildingId,
        filter: equipmentIdsFilter,
        nextToken: response.equipments.nextToken
      })

      equipments = [...equipments, ...response.equipments.items]

    }

    return equipments

  } catch(e) {
    console.log(e)
  }
}

// Migrated saved chart from GEN4, will not have equipment name and building names, equipment type and all
// so we have to fill those details in UI only for that data
export const formatMigratedProperties = async (
  properties,
  fetchEquipmentsByBuildingId
) => {
  try {
    if (properties?.length > 0 && !(properties?.[0]?.equipmentName && properties?.[0]?.buildingName)) {
      const buildingGroups = groupByPropertykey(properties, 'buildingId')

      // get equipment name and type assigned to properties array - only on edit
      for (let i = 0; i < buildingGroups?.length; i++) {
        const propWithProperEquipmentNames = []
        const buildingProperties = buildingGroups?.[i]?.properties || []
        const equipmentGroups = groupByPropertykey(properties, 'equipmentId')
        const equipmentIdsFilter = getEquipmentIdsFilter(equipmentGroups)

        const buildingDetails = await fetchEquipmentsByBuildingId({
          id: buildingGroups[i]?.buildingId,
          filter: equipmentIdsFilter
        })

        const equipments = await getEquipmentDetails(buildingGroups[i]?.buildingId, equipmentIdsFilter, fetchEquipmentsByBuildingId)

        buildingProperties?.map((x) => {
          const equipmentDetails = equipments?.find(
            (equipment) => x?.equipmentId === equipment?.id
          )
          propWithProperEquipmentNames.push({
            ...x,
            buildingName: buildingDetails?.name || '',
            equipmentName: equipmentDetails?.name || '',
            equipmentType: equipmentDetails?.type || '',
            color: x?.color ? x?.color : getRandomColor()
          })
        })
        return propWithProperEquipmentNames
      }
    }
    return properties
  } catch (e) {
    return []
  }
}

export const formatMigratedCalcProperties = async (
  calcProperties,
  fetchEquipmentsByBuildingId
) => {
  try {
    for (let i = 0; i < calcProperties?.length; i++) {
      const properties = await formatMigratedProperties(
        calcProperties[i]?.propertiesList,
        fetchEquipmentsByBuildingId
      )
      calcProperties[i].color = calcProperties[i].color || getRandomColor()
      calcProperties[i].propertiesList = properties
    }
    return calcProperties
  } catch (e) {
    return []
  }
}

// This below 2 methods used to format building data chart old data structure
// Can be removed after some time, once all saved charts followed same formatting
export const formatPropertiesReponse = (properties) => {
  try {
    if (properties && properties?.[0]?.propertyKey) {
      return properties
    } else {
      const formatterProperties = []
      properties?.forEach((building) => {
        const equipmentTypes = building?.equipmentTypes || []
        equipmentTypes?.forEach((equipmentsType) => {
          const equipmentsList = equipmentsType?.equipmentsList || []
          equipmentsList?.forEach((equipments) => {
            const propertiesList = equipments?.propertiesList
            propertiesList?.forEach((property) => {
              formatterProperties.push({
                buildingId: building?.buildingId,
                buildingName: building?.buildingName,
                propertyColor: property?.propertyColor,
                equipmentId: equipments?.equipmentId,
                equipmentName: equipments?.equipmentName,
                equipmentType: equipmentsType?.equipmentFamily,
                id: property?.id,
                uom: property?.targetMeasureUnit,
                propertyKey: property?.key,
                propertyName: property?.name,
                symbol: property?.symbol?.Symbol || ''
              })
            })
          })
        })
      })
      return formatterProperties
    }
  } catch (e) {
    return []
  }
}

export const formatCalculatedProperties = (calcProperties) => {
  try {
    calcProperties?.forEach((calcProperty, index) => {
      const calcPropertiesList = calcProperty?.propertiesList || []
      if (calcPropertiesList && calcPropertiesList?.[0]?.symbol?.UoM) {
        const formattedPropList = []
        calcPropertiesList?.forEach((property) => {
          formattedPropList.push({
            ...property,
            symbol: property?.symbol?.Symbol
          })
        })
        calcProperties[index].propertiesList = formattedPropList
        calcProperties[index].color = calcProperties?.color || getRandomColor()
      } else {
        calcProperties[index].propertiesList = calcPropertiesList
        calcProperties[index].color = calcProperty?.color || getRandomColor()
      }
    })
    return calcProperties
  } catch (e) {
    return []
  }
}
