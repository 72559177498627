import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Layout } from '../Layout'
import { pageRoutes } from './pages.js'
import { PageNotFound } from './pagNotFound'
import ErrorFallback from '../errorBoundary'
import DashboardWrapper from './wrapper'
import { RouteWrapper } from 'src/denali-components/Page/RouteWrapper'
// import { selectUserAccess } from 'src/redux/slicers/appData'
// import { useSelector } from 'react-redux'
// import { accessControlFunc } from 'src/components/accessControl'
// import config from 'src/aws-exports.js'
// import { EMEA_PROJECT_REGION } from 'src/components/Header/constants'

// const IsEMEAInstance = config.aws_project_region === EMEA_PROJECT_REGION


// const filterProtectedRoutes = (userAccess) => {
//   return (page) =>
//     page?.access ? accessControlFunc({ id: page.access, userAccess }) : true
// }


// const filterUtilityAndEnergyForEMEAInstance = (item: any) => {
//   return IsEMEAInstance
//     ? item?.name !== 'Utility & Energy' &&
//         item?.name !== 'Energy' &&
//         item?.name !== 'Utility Management'
//     : item
// }


const router = (signOut: any, termsUrl: any) => {

  // const userAccess = useSelector(selectUserAccess)

  // // This function restrict the routes creation itself, if the user don't have access to
  // const allowedPages = [...pageRoutes,{access : `tc.pages.${window.location.pathname.replaceAll("/",".")}`}].filter(filterProtectedRoutes(userAccess))
  // .filter(filterUtilityAndEnergyForEMEAInstance)

return  createBrowserRouter([
  {
    path: '/',
    element: <Layout signOut={signOut} termsUrl={termsUrl}/>,
    errorElement: <ErrorFallback signOut={signOut} />,
    children: [
      {
        path: '/',
        element: <DashboardWrapper />,
        errorElement: <ErrorFallback signOut={signOut} />
      },
      {
        path: '*',
        element: <PageNotFound />,
        errorElement: <ErrorFallback signOut={signOut} />
      },
      ...pageRoutes.map((e) => ({
        path: e.path,
        element: <RouteWrapper key={e.path}>{e.element && React.cloneElement(e.element)}</RouteWrapper>,
        errorElement: <ErrorFallback signOut={signOut} />,
        handle: e.handle,
      }))
    ]
  }
])
}
 

const PageComponent = ({ signOut, termsUrl }) => {
  return <RouterProvider fallbackElement={<></>} router={router(signOut, termsUrl)} />
}

export const Page = React.memo(PageComponent)
