import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/legacy/components/label/label'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import Selectrix from 'src/denali-ui/components/Selectrix'
import { useQuery } from 'src/hooks/APIHooks'
import {
  GET_EQUIPMENT_LIST,
  SEARCH_BUILDINGS
} from 'src/common/queries/datasource'
import { SEARCH_AGGREGATE } from 'src/pages/buildingSetup/graphql/queries'
import {
  getEquipmentSelectOptions,
  getAllEquipmentTypes,
  EQUIPMENT_LENGTH_WITH_CLEAR_ALL
} from './helper'
import unionBy from 'lodash/unionBy'
import './TargetPathView.scss'
import { sortHelper } from 'src/common/helperFunctions.js'
import { NewStyles } from 'src/NewStyles'
import { newRefreshToken } from 'src/redux/slicers/appData'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import translate, { TranslateComponent } from 'src/common/translations'
import { usePaginateQuery } from 'src/hooks/usePaginateQuery'

const TargetPathEdit = (props) => {
  const {
    tisObjects,
    onChange,
    pageName,
    location: { locationId, locationName },
    organization: { organizationId, organizationName },
    values,
    errors,
    setValues
  } = props
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [equipments, setEquipments] = useState([])
  const [updatedLocationName, setUpdatedLocationName] = useState(locationName)
  const [selectedTisObjects, setSelectedTisObjects] = useState([])
  const [searchParams] = useSearchParams()
  const oldLocationId = searchParams.get('location')

  const { data: accountData, refetch: refetchAccountData } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items'
  })

  const { data: equipmentsList, refetch: refetchEquipmentsList } = usePaginateQuery({
    query: GET_EQUIPMENT_LIST,
    variables: { id: locationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment'
  })

  useEffect(() => {
    setSelectedTisObjects(tisObjects?.map(({ tisObjectId }) => tisObjectId))
  }, [tisObjects])

  useEffect(() => {
    if (locationId) {
      refetchEquipmentsList({ id: locationId })
    }
  }, [locationId])

  useEffect(() => {
    if (equipmentsList) {
      const sortedEquipmentsList = sortHelper(equipmentsList)
      setEquipments(sortedEquipmentsList)
    }
  }, [equipmentsList])

  useEffect(() => {
    const bId = [...new Set([oldLocationId, locationId])]
    dispatch<any>(newRefreshToken(bId, null, null))
  }, [locationId])

  useEffect(() => {
    const getAccountDataFunc = async () => {
      if (!accountData && organizationId) {
        const filters: any = [{ accountId: { eq: organizationId } }]
        if (locationId) {
          filters.push({ id: { eq: locationId } })
        }
        await refetchAccountData({ filter: { and: filters } }).then((res) => {
          if (res) {
            if (!organizationName) {
              onChange('organization', {
                organizationId: organizationId,
                organizationName: res?.[0]?.accountName
              })
              onChange('location', {
                locationId: locationId,
                locationName: res?.[0]?.name
              })
            }
          }
          return res
        })
      }
    }
    getAccountDataFunc()
  }, [organizationId])

  const onOrganizationChange = (e, child) => {
    if (e.target.value !== organizationId) {
      setValues({
        ...values,
        organization: {
          organizationId: e.target.value,
          organizationName: child.props.children
        },
        location: {
          locationId: null,
          locationName: null
        },
        tisObjects: []
      })
      setSelectedTisObjects([])
      setEquipments([])
    }
  }

  useEffect(() => {
    if (!locationId) {
      setUpdatedLocationName('')
    } else {
      setUpdatedLocationName(locationName)
    }
  }, [locationName])

  const getOrgsQueryResponseMap = (response, organizationId) => {
    try {
      const idListIndex = response.findIndex((e) => e.name === 'accountIds')
      const nameTermsIndex = response.findIndex(
        (e) => e.name === 'accountTerms'
      )
      const results =
        response &&
        idListIndex !== -1 &&
        nameTermsIndex !== -1 &&
        response[nameTermsIndex].result.buckets.map((e, i) => ({
          searchableValue: e.key,
          value: response[idListIndex]?.result?.buckets[i]?.key,
          children: e?.key,
          selected:
            response[idListIndex]?.result?.buckets[i]?.key === organizationId
        }))

      return results.sort((a, b) => {
        const nameA = a.searchableValue.toUpperCase()
        const nameB = b.searchableValue.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    } catch (e) {}
  }

  const getBldingsQueryResponseMap = (response, locationId) => {
    return response.map((e) => ({
      searchableValue: e.name,
      value: e.id,
      children: e.name,
      selected: e.id === locationId
    }))
  }

  const onBuildingChangeSelect = (e, child) => {
    if (e.target.value !== locationId) {
      setValues({
        ...values,
        location: {
          locationId: e.target.value,
          locationName: child.props.children
        },
        tisObjects: []
      })
      setSelectedTisObjects([])
      setEquipments([])
    }
  }

  const onEquipmentChangeSelect = (value, tisObjectForSelector) => {
    const equipmentTypes = getAllEquipmentTypes(equipments)
    let selectedTisObjects = []
    if (value.some(({ key }) => key === 'all')) {
      selectedTisObjects = tisObjectForSelector.map(({ key }) => ({
        tisObjectId: key
      }))
    } else if (value.some(({ key }) => equipmentTypes.includes(key))) {
      const { key: equipmentType } = value.find(({ key }) =>
        equipmentTypes.includes(key)
      )
      const equipmentByType = equipments
        .filter(({ type }) => type === equipmentType)
        .sort((a, b) => a.value.localeCompare(b.value))
        .map(({ key }) => ({ tisObjectId: key }))
      selectedTisObjects = unionBy(
        tisObjects?.map(({ tisObjectId }) => ({ tisObjectId: tisObjectId })),
        equipmentByType,
        'tisObjectId'
      )
    } else if (
      value.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL &&
      tisObjects?.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL &&
      !value.some(({ key }) => key === 'clear')
    ) {
      selectedTisObjects = []
    } else {
      value.length &&
        (selectedTisObjects = value
          .filter(({ key }) => key !== 'clear')
          .map(({ key }) => ({ tisObjectId: key })))
    }
    onChange('tisObjects', selectedTisObjects)
  }
   const optionsTranslated = {
      translateFindEquipment: translate("Find equipment..."),
      translateNoResultsFound: translate("No results. Change or clear your text above."),
      translateSearchOrganization: translate("Type to search for an organization..."),
      translateSearchBuilding: translate("Type to search for a building...")
    }

  return (
    <NewStyles>
      <div className="data-source-pickers">
        <div className="organization-picker" data-testid="organization-picker">
          <legend>
            <span>{<TranslateComponent>Organization</TranslateComponent>} :</span>
          </legend>
          <Selectrix
            onChange={(value, child) => onOrganizationChange(value, child)}
            query={SEARCH_AGGREGATE}
            fullWidth={true}
            multiple={false}
            value={organizationName}
            dataPath={'data.searchBuildings.aggregateItems'}
            containerWidth={true}
            getQueryResponseMap={(values) =>
              getOrgsQueryResponseMap(values, organizationId)
            }
            selectClassName={'orgSelectInput'}
            searchPlaceHolder={optionsTranslated.translateSearchOrganization}
            testName={`${pageName}_organizationdetails_addorganization`}
            querySearchField={['accountName']}
            aggregates={[
              { field: 'accountName', type: 'terms', name: 'accountTerms' },
              { field: 'accountId', type: 'terms', name: 'accountIds' }
            ]}
            showCutomOption={true}
            showNoResultsMessage={false}
          />
        </div>
        <div className="building-picker" data-testid="building-picker">
          <legend>{<TranslateComponent>Building</TranslateComponent>}:*</legend>
          <Selectrix
            placeHolder={translate("- Select One -")}
            key={`bldingSelectrix-${organizationId}`}
            onChange={(value, child) => {
              onBuildingChangeSelect(value, child)
            }}
            query={SEARCH_BUILDINGS}
            graphQLInputs={{ filter: { accountId: { eq: organizationId } } }}
            fullWidth={true}
            multiple={false}
            value={updatedLocationName}
            containerWidth={true}
            getQueryResponseMap={(values) =>
              getBldingsQueryResponseMap(values, locationId)
            }
            selectClassName={'bldSelectInput'}
            searchPlaceHolder={optionsTranslated.translateSearchBuilding}
            testName={`${pageName}_organizationdetails_addbuilding`}
            querySearchField={['name']}
            showCutomOption={true}
            showNoResultsMessage={false}
          />
          {errors.location &&
            <p className="error">
              <><TranslateComponent>{errors.location}</TranslateComponent></>
            </p>
          }
        </div>
        <div className="multiple-tis-Object" data-testid="equipment-picker">
          <legend>
            {<TranslateComponent>Equipment</TranslateComponent>}:{' '}
            {tisObjects?.length !== 0 && (
              <span className="item-selected">
                ({tisObjects?.length} <TranslateComponent>selected</TranslateComponent>)
              </span>
            )}
          </legend>
          {!equipments.length ? (
            <Label
              text={t('common:NoItemFound', {
                item: t('opportunities:Equipment').toLowerCase()
              })}
            />
          ) : (
            <>
              {/* TODO: Convert this to Selectrix component */}
              <SelectrixWrapper
                className={`equipment selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select ${
                  tisObjects?.length > 10 ? 'auto-scroll' : ''
                }`}
                customKeys={{ key: 'key', label: 'value' }}
                defaultValue={
                  selectedTisObjects.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL
                    ? [...selectedTisObjects, 'clear']
                    : selectedTisObjects
                }
                inputPlaceholder={optionsTranslated.translateFindEquipment}
                notFoundPrompt={optionsTranslated.translateNoResultsFound}
                onChange={(e) => onEquipmentChangeSelect(e, equipments)}
                multiple={true}
                stayOpen={true}
                options={getEquipmentSelectOptions(
                  equipments,
                  selectedTisObjects
                ).map((item)=>{
                  if(item.value === "Clear All"){
                    item.value = <TranslateComponent>{item.value}</TranslateComponent>
                  }
                  return item
                })
                }
                placeholder={<TranslateComponent>-- Select One or More --</TranslateComponent>}
                searchable={true}
                searchBoxInside={true}
                searchIndex={false}
              />
            </>
          )}
        </div>
      </div>
    </NewStyles>
  )
}

export default TargetPathEdit
