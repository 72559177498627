import React from "react"
import PropTypes from "prop-types"
// import { dateInput, subdomainInput } from "react/screens/contracts-and-offerings/offering-dialogs/common.js"
import { translate } from 'src/common/translations'

export function ActivateTenantServices({ handleSubmitForm, values: { startDate, endDate, subdomain }, touched, errors, setFieldValue, setFieldTouched }) {
	const optionsTranslated = {
		startDate: translate("Start Date"),
		expirationDate: translate("Expiration Date"),
		subDomain: translate("Subdomain"),
	}
	return (
		<form onSubmit={handleSubmitForm}>
			<div className="datepickers">
				{dateInput({ name: "startDate", label: optionsTranslated.startDate, value: startDate, touched: touched.startDate || touched.all, setFieldValue, setFieldTouched, errors })}
				{dateInput({ name: "endDate", label: optionsTranslated.expirationDate, value: endDate, touched: touched.endDate || touched.all, setFieldValue, setFieldTouched, errors })}
			</div>
			{subdomainInput({ name: "subdomain", label: optionsTranslated.subDomain, value: subdomain, touched: touched.subdomain || touched.all, setFieldValue, setFieldTouched, errors })}
		</form>
	)
}

ActivateTenantServices.propTypes = {
	values: PropTypes.shape({
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		subdomain: PropTypes.string,
	}).isRequired,
	handleSubmitForm: PropTypes.func.isRequired,
	touched: PropTypes.boolean,
	setFieldTouched: PropTypes.func,
	setFieldValue: PropTypes.func.isRequired,
	errors: PropTypes.object
}
