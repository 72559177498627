import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeProvider } from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
import Tooltip, {
  TOOLTIP_HORIZONTAL_POSITION_RIGHT
} from 'src/components/legacy/components/tooltip/tooltip.jsx'
import { Trans, withTranslation } from 'react-i18next'
import { TranslateComponent } from '../../../../common/translations'

const DropArea = styled.label`
  background: ${colors.TBA_LIGHTEST_GREY};
  border: 1px dashed ${colors.TBA_DARK2_GREY};
  color: ${colors.BLACK};
  cursor: pointer;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 10px;
  text-align: center;
  opacity: 1;
  user-select: none;

  ${(props) =>
    props.theme.dragEnter &&
    css`
      color: ${colors.TBA_DARK_GREY};
    `}

  ${(props) =>
    props.theme.error &&
    css`
      background: ${colors.RED_5};
      border: 1px solid ${colors.RED};
    `}

	${(props) =>
    props.theme.disabled &&
    css`
      background: ${colors.TBA_LIGHT_GREY};
      border-color: ${colors.TBA_MEDIUM_GREY};
      color: ${colors.TBA_DARK_GREY};
      pointer-events: none;
    `}
`

const Note = styled.div`
  color: ${colors.TBA_DARKER_GREY};
  font-size: 10px;
  font-style: italic;
  line-height: 20px;

  ${(props) =>
    props.theme.disabled &&
    css`
      color: ${colors.TBA_DARK_GREY};
    `}
`

const Error = styled.div`
  color: ${colors.RED};
  font-size: 10px;
  font-style: italic;
  line-height: 20px;
`

const ClickHere = styled.span`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
`

export const ALLOWED_FILE_TYPES = [
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'docb',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'ppt',
  'pot',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppsx',
  'ppsm',
  'sldx',
  'sldm',
  'jpg',
  'png',
  'gif',
  'tif',
  'eps',
  'bmp',
  'svg',
  'webp',
  'aif',
  'm4a',
  'mp3',
  'mpa',
  'wav',
  'wma',
  'm4v',
  'mov',
  'mp4',
  'mpg',
  'wmv',
  'pdf',
  'txt',
  'rtf',
  'csv',
  'jpeg'
]

export class FileSelect extends PureComponent {
  static propTypes = {
    allowMultipleFiles: PropTypes.bool,

    /**
     * List of allowed file extensions
     * @type {string[]}
     * @example
     * ["jpg", "png"]
     */
    allowedFileTypes: PropTypes.arrayOf(PropTypes.string),

    /**
     * Whether component is disabled
     */
    disabled: PropTypes.bool,

    /**
     * Used to show error message
     */
    error: PropTypes.node,

    /**
     * Used to show note
     */
    note: PropTypes.node,

    /**
     * On change handler
     * @param {Object} bag
     * @param {string[]} bag.allowedFileTypes - The list of allowed file types
     * @param {Event} bag.event
     * @param {File[]} bag.files - The list of selected files
     */
    onChange: PropTypes.func,

    /**
     * Drag enter handler
     * @param {Object} bag
     * @param {File[]} bag.files - The list of selected files
     * @param {Event} bag.event
     */
    onDragEnter: PropTypes.func,

    /**
     * Drag leave handler
     * @param {Object} bag
     * @param {Event} bag.event
     */
    onDragLeave: PropTypes.func,

    /**
     * Shows only acceptable file types in description
     */
    showOnlyAcceptableFileDesc: PropTypes.bool
  }

  static defaultProps = {
    allowedFileTypes: ALLOWED_FILE_TYPES,
    disabled: false,
    error: null,
    note: null,
    onChange: () => {},
    onDragEnter: () => {},
    onDragLeave: () => {},
    showOnlyAcceptableFileDesc: false
  }

  state = {
    dragEnter: false,
    dragLeave: false
  }

  constructor(props) {
    super(props)

    this.fileInputRef = React.createRef()
    this.dropAreaRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('dragenter', this.onDragEnter, false)
    document.addEventListener('dragover', this.onDragOver, false)
    document.addEventListener('drop', this.onDrop, false)
  }

  componentWillUnmount() {
    document.removeEventListener('dragenter', this.onDragEnter, false)
    document.removeEventListener('dragover', this.onDragOver, false)
    document.removeEventListener('drop', this.onDrop, false)
  }

  /**
   * Handles drag enter event
   * @param {Event} e
   */
  onDragEnter = (e) => {
    const { onDragEnter, onDragLeave } = this.props
    const { dragEnter } = this.state
    const files = Array.from(e.dataTransfer.files)

    if (
      !dragEnter &&
      (this.dropAreaRef.current.contains(e.target) ||
        this.dropAreaRef.current === e.target)
    ) {
      this.setState({ dragEnter: true }, () => onDragEnter({ event: e, files }))
    }

    if (
      dragEnter &&
      !this.dropAreaRef.current.contains(e.target) &&
      this.dropAreaRef.current !== e.target
    ) {
      this.setState({ dragEnter: false }, () => onDragLeave({ event: e }))
    }

    e.stopPropagation()
    e.preventDefault()
  }

  /**
   * Handles drag over event
   * @param {Event} e
   */
  onDragOver = (e) => {
    // prevent default to allow drop without opening dropped file
    e.preventDefault()
  }

  /**
   * Handles drop event
   * @param {Event} e
   */
  onDrop = (e) => {
    const { dragEnter } = this.state

    if (
      dragEnter &&
      (this.dropAreaRef.current.contains(e.target) ||
        e.target === e.currentTarget)
    ) {
      const files = Array.from(e.dataTransfer.files)

      this.setState({ dragEnter: false }, () => {
        this.onChange(e, files)
      })
    }

    e.stopPropagation()
    e.preventDefault()
  }

  /**
   * Handles change event of file input
   * @param {Event} e
   */
  onOpen = (e) => {
    const files = Array.from(e.target.files)

    this.setState({ dragEnter: false }, () => {
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = null
      }
      this.onChange(e.persist(), files)
    })
  }

  /**
   * Handles drop and change events
   * @param {Event} event
   * @param {File[]} files
   */
  onChange = (event, files) => {
    const { allowedFileTypes, onChange } = this.props

    onChange({ allowedFileTypes, event, files })
  }

  render() {
    const {
      allowedFileTypes,
      disabled,
      error,
      note,
      allowMultipleFiles,
      t,
      customUploadContent,
      showOnlyAcceptableFileDesc
    } = this.props
    const { dragEnter } = this.state
    const acceptableFileTypes = allowedFileTypes
      ? allowedFileTypes
      : ALLOWED_FILE_TYPES
    const allowedFilesTooltipContent = acceptableFileTypes
      .filter((type) => !['doc', 'xls', 'pdf'].includes(type))
      .join(', ')

    return (
      <ThemeProvider theme={{ disabled, dragEnter, error }}>
        <>
          <DropArea htmlFor="fileElem" ref={this.dropAreaRef} data-testid="files-select">
            {customUploadContent ? (
              customUploadContent()
            ) : (
              <>
                <div>
                  <b>
                  <TranslateComponent> Drop files here to upload </TranslateComponent>
                  </b>
                </div>
                
                  <div>
                    <TranslateComponent> or </TranslateComponent><ClickHere><TranslateComponent> click here </TranslateComponent></ClickHere>
                    <TranslateComponent> to browse files from your computer.</TranslateComponent>
                  </div>
                
                {
                  showOnlyAcceptableFileDesc ? <p>
                  <TranslateComponent>Acceptable file types include</TranslateComponent>
                  {` .${allowedFileTypes.join(', .')}`}
                </p> :
                <p>
                  <TranslateComponent>Acceptable file types include</TranslateComponent>
                  {' '}
                  .doc, .xls, .pdf,{' '}
                <Tooltip
                  horizontalPosition={TOOLTIP_HORIZONTAL_POSITION_RIGHT}
                  content={allowedFilesTooltipContent}
                  SpanOrDiv="span"
                >
                  <u><TranslateComponent>and more</TranslateComponent></u>
                </Tooltip>
              </p>
                }
                
              </>
            )}
            <input
              type="file"
              ref={this.fileInputRef}
              accept={`.${allowedFileTypes.join(', .')}`}
              id="fileElem"
              multiple={allowMultipleFiles}
              style={{ display: 'none' }}
              onChange={this.onOpen}
            />
          </DropArea>
          {note && !error ? <Note>{note}</Note> : null}
          {error ? <Error>{error}</Error> : null}
        </>
      </ThemeProvider>
    )
  }
}

export default withTranslation()(FileSelect)

/**
 * Checks the file type
 * @param {string} fileName -The full file name, e.g. "file.txt"
 * @param {string[]} allowedFileTypes - The list of file extensions, e.g. ["jpg", "png"]
 * @returns {boolean}
 */
export const isAllowedFileType = (fileName, allowedFileTypes) =>
  Array.isArray(allowedFileTypes) && allowedFileTypes.length
    ? allowedFileTypes.some(
        (extension) => extension === fileName.split('.').pop().toLowerCase()
      )
    : true

/**
 * Converts file size to human readable string
 * @param {number} size
 * @returns {string}
 */
export const convertSizeToUnits = (size) => {
  let sOutput = `${size} bytes`
  for (
    let aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      nMultiple = 0,
      nApprox = size / 1024;
    nApprox >= 1;
    nApprox /= 1024, nMultiple++
  ) {
    sOutput = `${nApprox.toFixed(1)} ${aMultiples[nMultiple]}`
  }
  return sOutput
}
