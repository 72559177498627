import colors from 'src/components/layouts/colors.json'
import styled from 'styled-components'

export const ChartSettingsContainer = styled.div`
  width: 175px;
  .chart-controls {
    background-color: ${colors.TBA_LIGHTEST_GREY};
    border: 1px solid ${colors.TBA_DARK_GREY};
    border-radius: 8px;
    border-top-right-radius: 0px;
    max-height: 650px;
    width: 300px;
    position: relative;
    display: flex;
    flex-flow: column;
    right: 126px;
    overflow: hidden;
    box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.349019607843137);
    transition: max-height 200ms ease-in-out;
    &.hide {
      transition: max-height 200ms ease-in-out, border 50ms linear 200ms;
      max-height: 0px;
      border: 0px solid transparent;
    }
    .control-group {
      padding: 10px;
      border-bottom: 1px solid ${colors.TBA_DARK_GREY};
      &.last {
        border-bottom: none;
        display: flex;
        justify-content: flex-end;
      }
      .chart-setting,
      .message,
      .action {
        margin-bottom: 10px;
      }
      a {
        cursor: pointer;
        &.edit,
        &.delete {
          text-decoration: none;
        }
      }
      .error {
        border: none;
        padding: 0;
        margin: 0;
        font-size: 1em;
      }
      a {
        cursor: pointer;
        &.edit,
        &.delete {
          text-decoration: none;
        }
      }
      .error {
        border: none;
        padding: 0;
        margin: 0;
        font-size: 1em;
      }
    }
    h3 {
      margin-top: 0.5em;
      font-size: 1.2em;
      font-weight: bold;
    }
    a {
      text-decoration: underline;
    }
    .label {
      font-weight: bold;
    }
    .data {
      margin: 1em 2em 1em 0;
    }
    .inline {
      display: flex;
      align-items: center;
      margin: 1em 0;
      label {
        padding-right: 1em;
      }
      button {
        margin-left: 2em;
      }
      .spinner {
        margin-left: 2em;
      }
    }
    .bottom-space {
      margin-bottom: 10px;
    }
    .variance-label {
      font-weight: 700;
      display: block;
    }
    .equipment-name {
      font-weight: 700;
      margin-top:5px;
      font-size: 12px;
      display: block;
    }
    .baseline-date {
      display: block;
      font-size: 12px;
    }
  }
`

export const Header = styled.header`
  color: black;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid ${colors.TBA_DARK_GREY};
  border-top: 0px solid transparent;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${colors.TBA_LIGHTER_GREY};
  /* box-shadow: 0px 5px 15px rgba(0,0,0,0.349019607843137); */
  padding: 0px 0px 0px 8px;
  cursor: pointer;
  &.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0px solid transparent;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  text-transform: uppercase;
  padding: 0 8px;
    &.title-ellipsis{
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
`

export const ArrowContainer = styled.div`
  padding: 0 10px;
  cursor: pointer;
  height: 100%;
  width: 24px;
  border-left: 1px solid ${colors.TBA_DARK_GREY};
  display: flex;
  align-items: center;
`

export const Arrow = styled.div`
  font-size: 12px;
  padding-top: 1px;
  transition: 200ms transform ease-in-out;
  &.open {
    transform: rotate(180deg);
    transition: 200ms transform ease-in-out;
  }
`
