import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import validationSchema from './formValidator'
import { useQuery } from 'src/hooks/APIHooks'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from 'src/components/Icon'
import SelectrixWrapper from 'src/components/legacy/components/selectrix-wrapper/selectrix-wrapper'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import Label from 'src/components/legacy/components/label/label'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import { TimeOfUsePeriodTable } from './timeOfUsePeriodTable'
import { DemandRatchet, DemandRatchetView } from './demandRatchets'
import {
  ToggleButton,
  ButtonWrapper,
  SeasonSelectContainer,
  CheckboxContainer,
  DateDL,
  DateDT,
  DateDD,
  TimeOfUseDesc,
  CustomLabelWrapper
} from './styles.js'
import { SeasonDatePicker, emptyDayTypes } from './helpers.js'
import {
  CREATE_RATE_STRUCTURE,
  CREATE_UTILITY_COMPANY,
  GET_RATE_STRUCTURE_BY_ID,
  LIST_UTILITY_COMPANIES_BY_SALESOFFICE_ID,
  UPDATE_RATE_STRUCTURE
} from './queries'
import moment from 'moment'
import { AccessControl } from 'src/components/accessControl'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'
import { DELETE_RATE_STRUCTURE } from './queries'
import { setRateStructureSaleOfficeId } from 'src/redux/slicers/buildingPicker'
import { useDispatch } from 'react-redux'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate, { TranslateComponent } from 'src/common/translations'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import store, { useAppDispatch } from 'src/redux/store'

export const CreateRateStructure = () => {
  const {
    type: paramType,
    salesOfficeId: paramSalesOfficeId,
    rateStructureId: id
  } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";

  const optionsTranslated = {
    selectOne : translate("-Select One-"),
    findAcompany: translate("Find a Utility Company"+"...")
    
  }

  const [mode, setMode] = useState(
    location?.state?.mode
      ? location?.state?.mode
      : id
      ? ACTIONS.VIEW
      : ACTIONS.ADD
  )
  const [type, setType] = useState(paramType)
  const [utilityCompanyDDLData, setUtilityCompanyDDLData] = useState([
    { key: 'none', label: 'Please Select or Create a Utility Company' }
  ])
  const [salesOfficeId, setSalesOfficeId] = useState(paramSalesOfficeId)
  const [deleteModal, setDeleteModal] = useState(false)
  const [naviagtionState, setNavigationState] = useState(null)
  const [disableSeasonChange, setDisableSeasonChange] = useState(false)

  const { onSubmit: createRateStructureMutation } = useMutation({
    query: CREATE_RATE_STRUCTURE,
    onSuccess: (data) => {
      setNavigationState({
        createdRateStructureEntity: data.data.createRateStructure.name
      })
      trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.SAVE_RATE_STRUCTURE, {mode:"CREATE", name:data.data.createRateStructure.name, id:data.data.createRateStructure.id})
    }
  })

  const { onSubmit: updateRateStructureMutation } = useMutation({
    query: UPDATE_RATE_STRUCTURE,
    onSuccess: (data) => {
      setNavigationState({
        updatedRateStructureEntity: data.data.updateRateStructure.name
      })
      trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.SAVE_RATE_STRUCTURE, {mode:"EDIT", name:data.data.updateRateStructure.name, id:data.data.updateRateStructure.id})
    }
  })

  const { onSubmit: createUtilityCompany } = useMutation({
    query: CREATE_UTILITY_COMPANY,
    dataPath: 'data.createUtilityCompany'
  })

  const { onSubmit: deleteRateStructureMutation } = useMutation({
    query: DELETE_RATE_STRUCTURE,
    onSuccess: async () => {
      setDeleteModal(false)
      setNavigationState({ state: { deletedRateStructureEntity: values.name } })
    }
  })

  const { data: utilityCompanyData, refetch: refetchUtilityCompanyData } =
    useQuery({
      query: LIST_UTILITY_COMPANIES_BY_SALESOFFICE_ID,
      variables: { id: salesOfficeId },
      disableInitialLoad: true,
      errorPolicy: 'global',
      dataPath: 'data.listUtilityCompanyBySalesOffice.items'
    })

  const { data: rateStructureData, refetch: refetchRateStructure } = useQuery({
    query: GET_RATE_STRUCTURE_BY_ID,
    disableInitialLoad: true,
    dataPath: 'data.getRateStructure'
  })

  useEffect(() => {
    if (naviagtionState) {
      navigate(pages['Rate Structure Library'].href, { state: naviagtionState })
      setNavigationState(null)
    }
  }, [naviagtionState])

  const seasonInput = {
    noSeasonalVariation: {
      active: true,
      startDate: '01',
      endDate: 'Last Day',
      startMonth: 'Jan',
      endMonth: 'Dec'
    },
    winter: {
      active: false,
      startDate: '01',
      endDate: 'Last Day',
      startMonth: 'Dec',
      endMonth: 'Feb'
    },
    spring: {
      active: false,
      startDate: '01',
      endDate: 'Last Day',
      startMonth: 'Mar',
      endMonth: 'May'
    },
    summer: {
      active: false,
      startDate: '01',
      endDate: 'Last Day',
      startMonth: 'Jun',
      endMonth: 'Aug'
    },
    fall: {
      active: false,
      startDate: '01',
      endDate: 'Last Day',
      startMonth: 'Sep',
      endMonth: 'Nov'
    }
  }

  const rateInput = {
    description: '',
    name: '',
    seasons: seasonInput,
    tOfUEnabled: false,
    timeOfUseValues: { noSeasonalVariation: emptyDayTypes },
    utilityId: null,
    createUtility: false,
    demandRatchet: {
      'Off-Peak': { active: false },
      'On-Peak': { active: false },
      Intermediate: { active: false }
    }
  }

  useEffect(() => {
    if (salesOfficeId && !utilityCompanyData) {
      refetchUtilityCompanyData({ id: salesOfficeId }).then((res) => {
        const utilityCompanyData = [
          ...utilityCompanyDDLData,
          ...(res?.map((e) => ({ label: e.name, key: e.id })) || [])
        ]
        if(selectedLanguage === "en"){
          setUtilityCompanyDDLData(utilityCompanyData)
        } else {
          const translateFinalSummary = utilityCompanyData.reduce((acc, item) => {
            if (item.key && !acc.hasOwnProperty(item.key)) {
              acc[item.key] = item.key;
            }
            if (item.label && !acc.hasOwnProperty(item.label)) {
              acc[item.label] = item.label;
            }
  
            return acc;
          }, {});
          const translatedDropdown = dispatch<any>(translateJSON({ json: translateFinalSummary })).then(res=>{
            const data = utilityCompanyData?.map((item)=>{
              return{
                key: item.key,
                label: res.payload[item.label],
              }
            })
            setUtilityCompanyDDLData(data)
          })
        }
      })
      dispatch(
        setRateStructureSaleOfficeId({
          type: 'Success',
          salesOfficeId: salesOfficeId
        })
      )
    }
  }, [salesOfficeId])

  useEffect(() => {
    const callFunc = async () => {
      await refetchRateStructure({ id: id }).then((res) => {
        if (res.charges.items.length) {
          setDisableSeasonChange(true)
        }
        const seasons = res.seasonalDefinitions.reduce(
          (accum, curr) => {
            accum[curr.name] = {
              active: true,
              endDate: curr.endDate,
              endMonth: curr.endMonth,
              startDate: curr.startDate,
              startMonth: curr.startMonth
            }
            return accum
          },
          {
            noSeasonalVariation: {
              active: false,
              startDate: '01',
              endDate: 'Last Day',
              startMonth: 'Jan',
              endMonth: 'Dec'
            }
          }
        )

        const timeOfUseValues = res.seasonalDefinitions.reduce(
          (accum, curr) => {
            if (res.isTimeSchedule) {
              try {
                const weekdaySchedule = JSON.parse(curr.weekdaySchedule)
                const weekendSchedule = JSON.parse(curr.weekendSchedule)
                accum[curr.name] = {
                  Weekdays: weekdaySchedule,
                  Weekends: weekendSchedule
                }
              } catch (e) {
                console.log(e)
              }
            } else {
              accum[curr.name] = emptyDayTypes
            }
            return accum
          },
          { noSeasonalVariation: emptyDayTypes }
        )
        const arrayOfPeriods = Object.keys(timeOfUseValues).reduce(
          (accum, curr) => {
            Object.keys(timeOfUseValues[curr]).forEach((p) => {
              Object.keys(timeOfUseValues[curr][p]).forEach((t) => {
                if (accum.indexOf(timeOfUseValues[curr][p][t]) === -1) {
                  accum.push(timeOfUseValues[curr][p][t])
                }
              })
            })
            return accum
          },
          []
        )
        const ratchets = {
          Intermediate: {
            active: res?.intermediateRachet?.enabled,
            enabled:
              res?.intermediateRachet?.enabled ||
              arrayOfPeriods.indexOf('Intermediate') !== -1,
            value: res?.intermediateRachet?.demandPercent,
            type: res?.intermediateRachet?.timeFrame
          },
          'Off-Peak': {
            active: res?.offPeakRachet?.enabled,
            enabled:
              res?.offPeakRachet?.enabled ||
              arrayOfPeriods.indexOf('Off-Peak') !== -1,
            value: res?.offPeakRachet?.demandPercent,
            type: res?.offPeakRachet?.timeFrame
          },
          'On-Peak': {
            active: res?.onPeakRachet?.enabled,
            enabled:
              res?.onPeakRachet?.enabled ||
              arrayOfPeriods.indexOf('On-Peak') !== -1,
            value: res?.onPeakRachet?.demandPercent,
            type: res?.onPeakRachet?.timeFrame
          }
        }

        setType(res.type)
        setSalesOfficeId(res.salesOfficeId)
        formik.setValues({
          ...rateInput,
          name: res.name,
          description: res.description,
          createUtility: false,
          utilityId: res.utilityCompanyId,
          demandRatchet: ratchets,
          tOfUEnabled: res.isTimeSchedule,
          timeOfUseValues: timeOfUseValues,
          seasons: { ...seasonInput, ...seasons }
        })
      })
    }
    if (id && !rateStructureData) {
      callFunc()
    }
  }, [id])

  const formik = useFormik({
    initialValues: rateInput,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      const seasonalDefinitions = Object.keys(values.seasons)
        .filter((k) => values.seasons[k].active)
        .map((e) => ({ name: e, ...values.seasons[e] }))
        .map((e) => {
          return {
            enabled: e.active,
            endDate: e.endDate,
            endMonth: e.endMonth,
            name: e.name,
            startDate: e.startDate,
            startMonth: e.startMonth,
            weekdaySchedule: values.tOfUEnabled
              ? JSON.stringify(values?.timeOfUseValues?.[e.name]?.Weekdays)
              : undefined,
            weekendSchedule: values.tOfUEnabled
              ? JSON.stringify(values?.timeOfUseValues?.[e.name]?.Weekends)
              : undefined
          }
        })

      const inputObj = {
        id: id ? id : undefined,
        name: values.name,
        type,
        description: values.description,
        seasonalDefinitions: seasonalDefinitions.length
          ? seasonalDefinitions
          : [],
        salesOfficeId,
        isTimeSchedule: values.tOfUEnabled,
        isSeasonalVariation: Boolean(
          seasonalDefinitions.filter((k) => k.name !== 'noSeasonalVariation')
            .length
        ),
        utilityCompanyId: !values.createUtility && values.utilityId,
        onPeakRachet: values.demandRatchet['On-Peak'].active
          ? {
              timeFrame: values.demandRatchet['On-Peak'].type,
              enabled: true,
              demandPercent: values.demandRatchet['On-Peak'].value
            }
          : null,
        offPeakRachet: values.demandRatchet['Off-Peak'].active
          ? {
              timeFrame: values.demandRatchet['Off-Peak'].type,
              enabled: true,
              demandPercent: values.demandRatchet['Off-Peak'].value
            }
          : null,
        intermediateRachet: values.demandRatchet['Intermediate'].active
          ? {
              timeFrame: values.demandRatchet['Intermediate'].type,
              enabled: true,
              demandPercent: values.demandRatchet['Intermediate'].value
            }
          : null
      }
      if (!inputObj.utilityCompanyId) {
        await createUtilityCompany({
          input: {
            name: values.utilityId.replace('tag-', ''),
            salesOfficeId,
            type
          }
        }).then(async (res) => {
          inputObj.utilityCompanyId = res.id
          mode === ACTIONS.ADD
            ? await createRateStructureMutation({ input: inputObj })
            : await updateRateStructureMutation({ input: inputObj })
        })
      } else {
        mode === ACTIONS.ADD
          ? await createRateStructureMutation({ input: inputObj })
          : await updateRateStructureMutation({ input: inputObj })
      }
    }
  })

  const { values, errors } = formik

  const setTimeOfUseBase = (seasons) => {
    const activeSeasons = Object.keys(seasons).filter((s) => seasons[s].active)
    const timeOfUseValues = activeSeasons.reduce((accum, curr) => {
      if (!accum[curr]) {
        accum[curr] = JSON.parse(JSON.stringify(emptyDayTypes))
      }
      return accum
    }, {})
    formik.setFieldValue('timeOfUseValues', timeOfUseValues)
    formik.setFieldValue('demandRatchet', { ...rateInput.demandRatchet })
  }

  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {deleteModal && (
          <Modal
            testName="rate-structure-delete-modal"
            heading="Delete Rate Structure"
            buttons={[
              {
                text: 'Yes',
                handleClick: () =>
                  deleteRateStructureMutation({ input: { id: id } }),
                type: 'valid'
              },
              {
                text: 'No',
                handleClick: () => setDeleteModal(false),
                type: 'cancel'
              }
            ]}
            handleClose={() => setDeleteModal(false)}
          >
            <ConfirmationText>
              {`Are you sure you would like to delete the ${values.name} rate structure?`}
            </ConfirmationText>
          </Modal>
        )}
        <CustomLabelWrapper>
          <PageHeader
            pageTitle={
              [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                <>
                  <TextInput
                    testName="rate-structure-name"
                    style={{ width: '260px' }}
                    labelText={
                      type === 'electric'
                        ? 'Electric Rate Structure Name'
                        : 'Gas Rate Structure Name'
                    }
                    value={values?.name || ''}
                    name="name"
                    onChange={(e) => {
                      formik.setFieldValue('name', e.target.value)
                    }}
                    hasError={Boolean(errors?.['name'])}
                    errorMessage={`The ${type} name is required.`}
                  />
                </>
              ) : (
                values?.name
              )
            }
            titleTools={
              [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                <ButtonWrapper>
                  <Button
                    testName="rate-structure-create"
                    type="primary"
                    disabled={Object.keys(errors).length}
                    onClick={formik.handleSubmit}
                  >
                    <TranslateComponent>Save</TranslateComponent>
                  </Button>
                  <Button
                    testName="rate-structure-cancel"
                    type="secondary"
                    onClick={() => {
                      navigate(pages['Rate Structure Library'].href)
                    }}
                  >
                    <TranslateComponent>Cancel</TranslateComponent>
                  </Button>
                </ButtonWrapper>
              ) : (
                <span data-testid="rate-structure-edit">
                  <AccessControl id="tc.pages.rate-structure-library.edit">
                    <IconSvg
                      name={'edit'}
                      margin="0px 0px 0px 10px"
                      color="#666"
                      hover="#666"
                      width="15px"
                      onClick={() => {
                        setMode(ACTIONS.EDIT)
                        trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_EDIT_RATE_STRUCTURE,{from:"Page View"})
                      }}
                    />
                  </AccessControl>
                  <AccessControl id="tc.pages.rate-structure-library.edit">
                    <IconSvg
                      name={'trash2'}
                      margin="0px 0px 0px 10px"
                      color="#666"
                      hover="#666"
                      width="15px"
                      onClick={() => {
                        setDeleteModal(true)
                        trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_DELETE_RATE_STRUCTURE, {from:"Page View"})
                      }}
                    />
                  </AccessControl>
                </span>
              )
            }
          />
        </CustomLabelWrapper>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
          <>
            <TextAreaInput
              testName="rate-structure-description"
              labelText={'Description'}
              defaultValue={values?.description}
              name="Description"
              onChange={({ target: { value } }) => {
                formik.setFieldValue('description', value)
              }}
              isRequired={false}
            />
            <TranslateComponent>Features of rate structure, time of use, ratchet, etc.</TranslateComponent>
          </>
        ) : (
          values?.description
        )}
      </Content>
      <Content
        xlColumn={6}
        lColumn={6}
        mColumn={12}
        sColumn={12}
        border="none"
      />
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
          <>
            <Label text={<span><TranslateComponent>Utility Company</TranslateComponent>*</span>} />
            <div>
              <SelectrixWrapper
                testName="rate-strcuture-company-select"
                appendTagPrompt="Create Utility Company “{searchtext}”"
                className={`selectrix-wrapper--type-autocomplete ${
                  errors['utilityCompanyName'] ? 'utility-name-error' : ''
                }`}
                placeholder={optionsTranslated.selectOne}
                placeHolderInside={true}
                defaultValue={values.utilityId}
                inputPlaceholder="Find a Utility Company..."
                multiple={false}
                onChange={(e) => {
                  if (e.key.includes('tag-')) {
                    formik.setFieldValue('createUtility', true)
                    formik.setFieldValue('utilityId', e.key)
                  } else {
                    formik.setFieldValue('createUtility', false)
                    formik.setFieldValue('utilityId', e.key)
                  }
                }}
                options={utilityCompanyDDLData}
                searchable={true}
                searchBoxInside={true}
                searchIndex={false}
                stayOpen={false}
                tags={true}
                validationWithRegisterCase={true}
              />
              {
                <p className="error">
                  <TranslateComponent>{errors.utilityId && 'Utility Company is required.'}</TranslateComponent>
                </p>
              }
            </div>
          </>
        ) : (
          <>
            <label data-testid="rate-structure-utility-company-names"><TranslateComponent>{`Utility Company: `}</TranslateComponent></label>
            &nbsp;
            <TranslateComponent>
            {utilityCompanyData?.filter((e) => e.id === values.utilityId)
              .length &&
              utilityCompanyData?.filter((e) => e.id === values.utilityId)[0]
                ?.name}
            </TranslateComponent>
          </>
        )}
      </Content>
      <Content
        xlColumn={6}
        lColumn={6}
        mColumn={12}
        sColumn={12}
        border="none"
      />
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <h2><TranslateComponent>Seasonal Definition</TranslateComponent></h2>
        {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
          <span>
            <span>
              <TranslateComponent>Select any "seasons" and define the start and end dates for any
              seasons that you would like to assign charges to.</TranslateComponent>
            </span>
            <br />
            <br />
            <div data-testid="seasonal-checkbox-container">
              <CheckboxContainer>
                <Checkbox
                  testName="rate-structure-seasonal-validation-radio"
                  label={'No Seasonal Variation'}
                  isChecked={values.seasons.noSeasonalVariation.active}
                  id={'noseasons'}
                  disabled={disableSeasonChange}
                  toggleCheck={() => {
                    const seasons = JSON.parse(JSON.stringify(values.seasons))
                    if (values?.seasons?.noSeasonalVariation?.active) {
                      seasons.noSeasonalVariation.active = false
                    } else {
                      Object.keys(seasons).forEach((e) => {
                        if (e !== 'noSeasonalVariation') {
                          seasons[e].active = false
                        } else {
                          seasons[e].active = true
                        }
                      })
                    }
                    setTimeOfUseBase(seasons)
                    formik.setFieldValue('timeOfUseValues', {
                      noSeasonalVariation: emptyDayTypes
                    })
                    formik.setFieldValue('seasons', seasons)
                  }}
                />
              </CheckboxContainer>
              {!values?.seasons?.noSeasonalVariation?.active ? (
                <>
                  <SeasonSelectContainer>
                    <CheckboxContainer>
                      <Checkbox
                        label={'Winter'}
                        isChecked={values.seasons.winter.active}
                        id={'winter'}
                        disabled={disableSeasonChange}
                        toggleCheck={() => {
                          const seasons = {
                            ...values.seasons,
                            winter: {
                              ...values.seasons.winter,
                              active: !values.seasons.winter.active
                            }
                          }
                          setTimeOfUseBase(seasons)
                          formik.setFieldValue('seasons', seasons)
                        }}
                      />
                    </CheckboxContainer>
                    {values.seasons.winter.active ? (
                      <SeasonDatePicker
                        season="winter"
                        values={values}
                        setSeason={(e) => formik.setFieldValue('seasons', e)}
                      />
                    ) : (
                      ''
                    )}
                  </SeasonSelectContainer>
                  <SeasonSelectContainer>
                    <CheckboxContainer>
                      <Checkbox
                        label={'Spring'}
                        isChecked={values.seasons.spring.active}
                        id={'spring'}
                        disabled={disableSeasonChange}
                        toggleCheck={() => {
                          const seasons = {
                            ...values.seasons,
                            spring: {
                              ...values.seasons.spring,
                              active: !values.seasons.spring.active
                            }
                          }
                          setTimeOfUseBase(seasons)
                          formik.setFieldValue('seasons', seasons)
                        }}
                      />
                    </CheckboxContainer>
                    {values.seasons.spring.active ? (
                      <SeasonDatePicker
                        season="spring"
                        values={values}
                        setSeason={(e) => formik.setFieldValue('seasons', e)}
                      />
                    ) : (
                      ''
                    )}
                  </SeasonSelectContainer>
                  <SeasonSelectContainer>
                    <CheckboxContainer>
                      <Checkbox
                        label={'Summer'}
                        isChecked={values.seasons.summer.active}
                        id={'summer'}
                        disabled={disableSeasonChange}
                        toggleCheck={() => {
                          const seasons = {
                            ...values.seasons,
                            summer: {
                              ...values.seasons.summer,
                              active: !values.seasons.summer.active
                            }
                          }
                          setTimeOfUseBase(seasons)
                          formik.setFieldValue('seasons', seasons)
                        }}
                      />
                    </CheckboxContainer>
                    {values.seasons.summer.active ? (
                      <SeasonDatePicker
                        season="summer"
                        values={values}
                        setSeason={(e) => formik.setFieldValue('seasons', e)}
                      />
                    ) : (
                      ''
                    )}
                  </SeasonSelectContainer>
                  <SeasonSelectContainer>
                    <CheckboxContainer>
                      <Checkbox
                        label={'Fall'}
                        isChecked={values.seasons.fall.active}
                        id={'fall'}
                        disabled={disableSeasonChange}
                        toggleCheck={(e) => {
                          const seasons = {
                            ...values.seasons,
                            fall: {
                              ...values.seasons.fall,
                              active: !values.seasons.fall.active
                            }
                          }
                          setTimeOfUseBase(seasons)
                          formik.setFieldValue('seasons', seasons)
                        }}
                      />
                    </CheckboxContainer>
                    {values.seasons.fall.active ? (
                      <SeasonDatePicker
                        season="fall"
                        values={values}
                        setSeason={(e) => formik.setFieldValue('seasons', e)}
                      />
                    ) : (
                      ''
                    )}
                  </SeasonSelectContainer>
                </>
              ) : (
                ''
              )}
            </div>
          </span>
        ) : (
          <span>
            <span>
              <TranslateComponent>The rates change based on the season the energy is used. The
              following are the dates the rates change.</TranslateComponent>
            </span>
            <br />
            <br />
            {values?.seasons?.noSeasonalVariation?.active ? (
              <DateDL>
                <DateDT><TranslateComponent>No seasonal variaton:</TranslateComponent></DateDT>
                <DateDD>January 1 - December 31</DateDD>
              </DateDL>
            ) : (
              Object.keys(values.seasons)
                .filter((e) => values.seasons[e].active)
                .map((e) => {
                  let startDate
                  let endDate
                  if (values.seasons[e].startDate === 'Last Day') {
                    startDate = moment(
                      values.seasons[e].startMonth,
                      'MMM'
                    ).endOf('month')
                  } else {
                    startDate = moment(
                      `${values.seasons[e].startMonth} ${values.seasons[e].startDate}`,
                      'MMM DD'
                    )
                  }
                  if (values.seasons[e].endDate === 'Last Day') {
                    endDate = moment(values.seasons[e].endMonth, 'MMM').endOf(
                      'month'
                    )
                  } else {
                    endDate = moment(
                      `${values.seasons[e].endMonth} ${values.seasons[e].endDate}`,
                      'MMM DD'
                    )
                  }
                  return (
                    <DateDL key={`season${e}`}>
                      <DateDT>{e}</DateDT>
                      <DateDD>{`${startDate.format(
                        'MMMM D'
                      )} - ${endDate.format('MMMM D')}`}</DateDD>
                    </DateDL>
                  )
                })
            )}
          </span>
        )}
        {
          <p className="error">
            <TranslateComponent>
              {errors.seasons &&
                'Selected seasons must add up to one complete year, or, "No Seasonal Variation" must be selected.'}
            </TranslateComponent>
          </p>
        }
        <br />
        <br />
        {type === 'electric' ? (
          <>
            <span>
              <h2><TranslateComponent>Time of Use Schedule</TranslateComponent></h2>
              {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                <ToggleButton>
                  <label><TranslateComponent>{`Time of use periods: `}</TranslateComponent></label>
                  <ToggleButtonHorizontal
                    testName="rate-time-of-schedule"
                    name="touScheduleEnabled"
                    disabled={disableSeasonChange}
                    checked={values.tOfUEnabled}
                    onChange={() => {
                      formik.setFieldValue('tOfUEnabled', !values.tOfUEnabled)
                      formik.setFieldValue('demandRatchet', {
                        ...rateInput.demandRatchet
                      })
                      const newtimeofusevalues = { ...values.timeOfUseValues }
                      Object.keys(newtimeofusevalues).forEach((e) => {
                        newtimeofusevalues[e] = emptyDayTypes
                      })
                      formik.setFieldValue(
                        'timeOfUseValues',
                        newtimeofusevalues
                      )
                    }}
                  />
                  <label><TranslateComponent>{`NOTE: Charges will be applied to times of use independently, with no overlapping time periods.`}</TranslateComponent></label>
                </ToggleButton>
              ) : (
                <div>
                  <TranslateComponent>The rates change based on the time of day the energy is used.
                  The following are the times the rates change.</TranslateComponent>
                </div>
              )}
              <br />
              {values.tOfUEnabled ? (
                <>
                  <TimeOfUseDesc>
                    <TranslateComponent>
                    Select the appropriate hours for each time period. If no
                    time of use is needed, do not select any hours for that time
                    period, for example, if you do not need to assign a rate to
                    On-Peak on Weekends, do not select any hours for that time
                    period.
                    </TranslateComponent>
                  </TimeOfUseDesc>
                  <br />
                  <TimeOfUsePeriodTable
                    values={values}
                    disabled={Boolean(
                      disableSeasonChange ||
                        ![ACTIONS.ADD, ACTIONS.EDIT].includes(mode)
                    )}
                    setValue={async (x, y) => {
                      const keys = Object.keys(y).reduce((accum, curr) => {
                        const periodVals = [
                          ...Object.values(y[curr].Weekdays),
                          ...Object.values(y[curr].Weekends)
                        ]
                        for (let i = 0; i < periodVals.length; i++) {
                          if (
                            accum.findIndex((e) => e === periodVals[i]) === -1
                          ) {
                            accum.push(periodVals[i])
                          }
                        }
                        return accum
                      }, [])
                      const newDemandRatchetObjs = JSON.parse(
                        JSON.stringify(values.demandRatchet)
                      )
                      Object.keys(values.demandRatchet).forEach((e) => {
                        if (keys.findIndex((x) => x === e) !== -1) {
                          newDemandRatchetObjs[e].enabled = true
                        } else {
                          newDemandRatchetObjs[e].enabled = false
                          newDemandRatchetObjs[e].active = false
                        }
                      })
                      await formik.setValues({
                        ...values,
                        demandRatchet: newDemandRatchetObjs,
                        [x]: y
                      })
                    }}
                  />{' '}
                </>
              ) : (
                ''
              )}
            </span>
            <br />
            <br />
            <h2><TranslateComponent>Demand Ratchets</TranslateComponent></h2>
            {![ACTIONS.ADD, ACTIONS.EDIT].includes(mode) && (
              <>
                <TranslateComponent>For the enabled times of use, ratchets are defined as follows</TranslateComponent>:
                <br />
                <br />
              </>
            )}
            {Object.keys(values.demandRatchet).filter(
              (e) => values.demandRatchet[e].enabled
            )?.length > 1 ? (
              <>
                {Object.keys(values.demandRatchet)
                  .filter((e) => values.demandRatchet[e].enabled)
                  .map((e) => {
                    return [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                      <DemandRatchet
                        key={e}
                        name={e}
                        values={values}
                        error={errors.demandRatchet}
                        setValue={(x, y) => formik.setFieldValue(x, y)}
                      />
                    ) : (
                      <DemandRatchetView
                        key={`view${e}`}
                        name={e}
                        values={values}
                      />
                    )
                  })}
              </>
            ) : [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
              <DemandRatchet
                values={values}
                name="Off-Peak"
                displayName="Demand Ratchet"
                error={errors.demandRatchet}
                setValue={(x, y) => formik.setFieldValue(x, y)}
              />
            ) : Object.keys(values.demandRatchet).filter(
                (e) => values.demandRatchet[e].enabled
              )?.length === 1 ? (
              <DemandRatchetView
                key={`view${'Off-Peak'}`}
                name={'Off-Peak'}
                displayName="Demand Ratchet"
                values={values}
              />
            ) : (
              <TranslateComponent>{'Ratchets have not been set. Click edit to set them.'}</TranslateComponent>
            )}
            {
              <p className="error">
                <>{errors.demandRatchet && errors.demandRatchet}</>
              </p>
            }
          </>
        ) : (
          ''
        )}
      </Content>
    </Container>
  )
}
