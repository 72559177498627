type wildCardSearch = {
  attrName: string
  value: string
  operator?: 'and' | 'or',
  isRequiredQueryInputName?: boolean
}

type wildCardSearchMultiple = Omit<wildCardSearch, 'attrName' | 'operator'> & {
  attrNames: Array<string>
  wideOperator?: 'and' | 'or'
  narrowOperator?: 'and' | 'or',
  isRequiredQueryInputName?: boolean
}

export const createWildCardFilter = ({
  attrName,
  value,
  operator = 'and',
  isRequiredQueryInputName
}: wildCardSearch) => {
  const searchWords = (value || '').split(' ')
  const searchData = searchWords.reduce(
    (acc, word) => {
      const inputField = isRequiredQueryInputName
      ? { [attrName]: { matchPhrasePrefix : `*${word}*` } } 
      : { [attrName]: { wildcard : `*${word}*` } }
      word && acc[operator].push(inputField)
      return acc
    },
    { [operator]: [] }
  )
  return searchData
}

export const createWildCardFilterMultiple = ({
  attrNames,
  value,
  wideOperator = 'or',
  narrowOperator = 'and',
  isRequiredQueryInputName
}: wildCardSearchMultiple) => {
  // This is hopefully temporary. Removing reserved keys from filter.
  const searchWords = (value || '').replace(/[&\/\\#,+()$~%.'":*?<>{}=-@!-]/g , ' ').split(' ')
  return attrNames.reduce(
    (acc1, attrName: string|Array<string>) => {
      if (typeof attrName === "string") {
        const wildCardFilter = searchWords.reduce(
          (acc2, word) => {
            const inputField = isRequiredQueryInputName
              ? { [attrName]: { matchPhrasePrefix : `*${word}*` } } 
              : { [attrName]: { wildcard : `*${word}*` } }            
            word.trim() &&
            acc2[narrowOperator].push(inputField)
            return acc2
          },
          { [narrowOperator]: [] }
        )
        acc1[wideOperator].push(wildCardFilter)
      } else {
        if (attrName.length) {
          const filterToUse = { [narrowOperator]: [] }
          searchWords.forEach(word => {
            filterToUse[narrowOperator].push({or: []})
            attrName.forEach(e => {
              const inputField = isRequiredQueryInputName
              ? {[e]: { matchPhrasePrefix : `*${word}*` }}
              : {[e]: { wildcard: `*${word}*` }}
              filterToUse[narrowOperator][filterToUse[narrowOperator].length - 1].or.push(inputField)
            })
          })
          acc1[wideOperator].push(filterToUse)
        }
      }
      return acc1
    },
    { [wideOperator]: [] }
  )
}
