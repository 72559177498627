import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import { Link } from 'react-router-dom'
import ImagePreview from 'src/components/legacy/components/image-preview/index'
import { FindingHeadlinesView } from '../finding-headlines-view/index'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import { FindingViewWrapper } from '../../../styles'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { ACTIONS } from 'src/constants/actions'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import { UiMode } from 'src/redux/types/AppTypes'
import { FindingBadge } from 'src/denali-components/File/FindingBadge'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { MODAL_TYPE } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'
import { useOpportunityContext } from 'src/pages/opportunities/OpportunityContextProvider'
import { selectUiMode } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'


const FindingView = (props) => {
  const { finding, remove } = props
  const [t] = useTranslation()
  const [confirm, setConfirm] = useState(null)
  const { setModalType, modalType } = useOpportunityContext()

  const translateRemoveText=translate("Are you sure you want to remove")
  const translateOpportunityText =translate("from this Opportunity?")
  const onCloseDialog = (field) => () => {
    switch (field) {
      case 'confirm':
        setConfirm(null)
        break
    }
  }

  const removeFinding = (name) => {
    setConfirm({
      title:"Remove Finding",
      text:`${translateRemoveText} ${name} ${translateOpportunityText} `,
      confirmText: "Yes",
      cancelText:"No",
      handleConfirm: () => {
        setConfirm(null)
        remove()
      },
      handleCancel: ()=>{
        setConfirm(null)
      }
    })
  }

  const getFindingTitle = () => (
    <ConditionalTooltip
      type={ConditionalTooltip.TYPE.ICON}
      conditional={true}
      content={finding.name}
      align={ConditionalTooltip.ALIGN.START}
      multiline={true}
    >
      <Link
        to={{
          pathname: `/${PAGE_NAMES.FINDINGS}/${finding.findingId}`
        }}
        state={{ mode: ACTIONS.VIEW }}
      >
        {finding.name}
      </Link>
    </ConditionalTooltip>
  )

  const findingImage = _get(finding, 'settings.images[0]', null)
  const headlines = _get(finding, 'settings.headlines', [])
  const title = _get(headlines, '[0].title', '')
  const description = _get(headlines, '[0].description', '')
  const visibleToCustomer = finding.visibleToCustomer
  const isEditMode = Boolean(remove)
  const uiMode = useSelector(selectUiMode)

  /* 
  * To be updated later on
  *
  * Show the findings only for the user having Technician & customer technician roles and 
  * then the visible to customer option is enabled
  *
  */

  if (uiMode === UiMode.denali) {
    return (
      <>
        <FindingBadge
          findingId={finding.findingId}
          findingName={finding.name}
          original={finding?.original}
          headlines={headlines}
          title={title}
          description={description}
          deleteFunc={removeFinding}
          isEditMode={isEditMode}
        />
        {confirm && (
          <DialogConfirm
            onAgree={confirm.handleConfirm}
            title={'Remove Finding'}
            onClose={onCloseDialog('confirm')}
          >
            <TranslateComponent>Are you sure you want to remove this finding?</TranslateComponent>
          </DialogConfirm>
        )}
      </>
    )
  }

  return (
    <FindingViewWrapper>
      <div className="finding">
        {isEditMode && (
          <button
            type="button"
            onClick={() => removeFinding(finding.name)}
            className="icon-btn red remove-icon remove-block"
          >
            <span className="icon-trash2" />
          </button>
        )}
        <h2 className="finding-link-small">{getFindingTitle()}</h2>
        {finding?.original && (
          <div className="finding-preview">
            <ImagePreview src={finding?.original}>
              {Boolean(headlines.length) && (
                <FindingHeadlinesView
                  name={finding.name}
                  headlines={headlines}
                />
              )}
            </ImagePreview>
          </div>
        )}
        <div className="finding-info">
          <h2 className="finding-link">{getFindingTitle()}</h2>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <StandardDialogs confirm={confirm} onCloseDialog={onCloseDialog} />
      </div>
    </FindingViewWrapper>
  )
}

FindingView.propTypes = {
  finding: PropTypes.shape({
    findingId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    settings: PropTypes.object,
    visibleToCustomer: PropTypes.bool
  }).isRequired,
  remove: PropTypes.func
}

export default FindingView
