import { useState, useEffect } from 'react'
import { useAttachment } from 'src/hooks/attachment'
import ImageGallery from 'react-image-gallery'
import styles from 'src/denali-components/ImageGallery/image-gallery.module.scss'

import { ImageGalleryItem } from 'src/denali-components/ImageGallery/ImageGalleryItem'
import { DetailFindingDescription } from 'src/denali-pages/Opportunities/OpportunityDetail/DetailFindingDescription'

export const ImageGalleryWrapper = (props) => {
  const [galleryItems, setGalleryItems] = useState([])
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [startIndex, setStartIndex] = useState(0)
  const { downloadFileURL } = useAttachment()

  useEffect(() => {
    setGalleryItems(props?.items)
  }, [props?.items])

  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId)
        .then((res) => {
          item.original = res
        })
        .catch((err) => {
          setIsLoadingImage(false)
        })
    }
    return item
  }

  const { isLazyLoading = false } = props

  return (
    <ImageGallery
      renderItem={(item) => (
        <ImageGalleryItem
          item={item}
          isLoadingImage={isLoadingImage}
          renderDescription={
            props.finding && (
              <DetailFindingDescription finding={props?.finding} />
            )
          }
        />
      )}
      additionalClass={styles.imageGallery}
      {...props}
      items={galleryItems}
      startIndex={startIndex}
      lazyLoad={props.lazyLoad}
      showBullets={props.showBullets}
      showNav={props.showNav}
      showPlayButton={props.showPlayButton}
      showThumbnails={props.showThumbnails}
      autoPlay={props.autoPlay}
      slideInterval={props.slideInterval}
      infinite={props.infinite}
      onSlide={async (i) => {
        if (galleryItems?.length === 0) return
        const slideItem = galleryItems[i]
        if (!slideItem?.original && isLazyLoading) {
          setStartIndex(i)
          setIsLoadingImage(true)
          const imgItem = await fetchImage(slideItem)
          setIsLoadingImage(false)
          setGalleryItems(
            galleryItems?.map((i) =>
              i?.name === imgItem?.name
                ? { ...i, original: imgItem?.original }
                : i
            )
          )
          if (props?.handleDownloadImage) {
            props?.handleDownloadImage(imgItem)
          }
        }
      }}
    />
  )
}
