import { useDispatch } from "react-redux";
import { newRefreshToken } from "src/redux/slicers/appData";

const useAttachmentClaims = () => {
  const dispatch = useDispatch()

  const RefreshAttachmentClaims = (buildingIds, orgIds) => {
    return new Promise(async (resolve, reject) => {
      await dispatch<any>(
        newRefreshToken(
          buildingIds?.length > 0 ? buildingIds : null,
          orgIds?.length > 0 ? orgIds : null,
          null,
          [
            'AttachmentAdmin',
            'BuildingAdmin',
            'TraneServiceB',
            'BuildingUser',
            'EnergyUsageReportAdmin',
            'ConsultationUser',
            'ConsultationAdmin',
            'DocumentAdmin'
          ]
        )
      ).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })

    });
  }

  return [RefreshAttachmentClaims];
};

export default useAttachmentClaims;