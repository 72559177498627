import { useCallback, useState } from 'react'
import {
  EnergyIntensityBenchmark,
  EnergyIntensityMarker,
  EnergyIntensityToggles,
  EnergyIntensityTogglesProps
} from '../types.d'

export const useEnergyIntensityToggles = ({
  markers,
  benchmarks,
  onMarkersUpdate,
  onBenchmarksUpdate
}: EnergyIntensityTogglesProps): EnergyIntensityToggles => {
  // Configure the ranges and active range for the chart.
  const [ranges, setRanges] = useState(['1M', '6M', 'YTD', '1Y', 'ALL'])
  const [activeRange, setActiveRange] = useState('ALL')

  // Active chart view type.
  const [chartView, setChartView] = useState('bar')

  // State values for if EUI and ECI are enabled.
  const [euiEnabled, setEuiEnabled] = useState(true)
  const [eciEnabled, setEciEnabled] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const onToggleMarker = useCallback(
    (marker: EnergyIntensityMarker) => {
      // Add or remove the marker from the list of checked markers.
      const markersUpdated = markers?.map((filtered) => {
        return {
          ...filtered,
          checked:
            filtered.id === marker.id ? !filtered.checked : filtered.checked
        }
      })
      onMarkersUpdate(markersUpdated)
    },
    [markers]
  )

  const onToggleBenchmark = useCallback(
    (benchmark: EnergyIntensityBenchmark) => {
      const benchmarksUpdated = benchmarks?.map((filtered) => {
        return {
          ...filtered,
          checked:
            filtered.id === benchmark.id ? !filtered.checked : filtered.checked
        }
      })
      onBenchmarksUpdate(benchmarksUpdated)
    },
    [benchmarks]
  )

  return {
    ranges,
    setRanges,
    activeRange,
    setActiveRange,
    chartView,
    setChartView,
    euiEnabled,
    setEuiEnabled,
    eciEnabled,
    setEciEnabled,
    isModalOpen,
    setIsModalOpen,
    sidebarOpen,
    setSidebarOpen,
    onToggleMarker,
    onToggleBenchmark
  }
}
