import { useEffect, useMemo, useState } from 'react'
import styles from './building-selector.module.scss'
import { BuildingsPopupItem } from './BuildingsPopupItem'
import { Heading, Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faCircleXmark,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { API } from 'aws-amplify'
import {
  SEARCH_BUILDINGS,
  SearchBuildingsQueryVariables
} from 'src/components/buildingNavigation/graphql'
import { accountMapping } from 'src/components/buildingNavigation/helper'
import translate, { TranslateComponent } from 'src/common/translations'
import { useDebounce } from '../helpers'
import { useSelector } from 'react-redux'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'
import cloneDeep from 'lodash/cloneDeep'

export const BuildingsPopup = ({
  organization,
  setBuildingsPopupOpen,
  buildingGetFloatingProps,
  buildingRefs,
  buildingFloatingStyles
}) => {
  const limit = 1000
  const selectedOrgBuildingSales = useSelector(selectBuilding)
  const selectedBuilding =
    selectedOrgBuildingSales?.type === 'location'
      ? selectedOrgBuildingSales
      : null
  const [buildings, setBuildings] = useState([])
  const [searchText, setSearchText] = useState('')
  const [previousSearch, setPreviousSearch] = useState(searchText)
  const updateSearchResults = useDebounce(() => {
    setPreviousSearch(searchText)
    searchWithOptionalSearchTerm(
      searchText,
      organization.id,
      limit,
      setBuildings,
      previousSearch
    )
  })
  const translations = {
    clear: translate('Clear')
  }

  const sortedBuildings = useMemo(() => {
    return cloneDeep(buildings)?.sort((building) => {
      if (building.id === selectedBuilding?.id) return -1
      return 0
    })
  }, [buildings, selectedBuilding])

  useEffect(() => {
    setSearchText('')
    setBuildings(organization?.buildings || [])
  }, [organization])

  useEffect(() => {
    if (!organization?.id) return
    updateSearchResults()
  }, [searchText])

  return (
    <div
      className={styles.popupWrapper}
      {...buildingGetFloatingProps()}
      ref={buildingRefs.setFloating}
      data-testid="buildingSelectorBuildingPopup"
      style={buildingFloatingStyles}
    >
      <div className={`${styles.popup} ${styles.buildings}`}>
        <Heading level={2} className={styles.popupHeadline}>
          <span className={styles.popupHeadlineIcon}>
            <FontAwesomeIcon icon={faBuilding} />
          </span>
          <TranslateComponent>Buildings</TranslateComponent>
        </Heading>
        <form
          className={styles.searchForm}
          onSubmit={(event) => {
            event.preventDefault()
          }}
        >
          <Input
            type="text"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
            }}
            placeholder={translate('Search...')}
            aria-label={translate('Search...')}
            data-testid="buildingDenaliInput"
          />
          <button type="submit" aria-label={translate('Submit')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          {searchText !== '' && (
            <button
              className={styles.searchClear}
              aria-label={translations.clear}
              onClick={() => {
                setSearchText('')
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
          )}
        </form>
        <div className={styles.scrollContainer}>
          <ul>
            {sortedBuildings?.map((building) => (
              <BuildingsPopupItem
                key={building.id}
                building={building}
                selectedBuilding={selectedBuilding}
                setBuildingsPopupOpen={setBuildingsPopupOpen}
                searchText={searchText}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

async function searchWithOptionalSearchTerm(
  searchText,
  organizationId,
  limit,
  setBuildings,
  previousSearch
) {
  // Based on handleBodyFilterChange in src/components/buildingNavigation/building-navigation.tsx
  if (searchText.length < 2) {
    searchText = ''
  }
  if (previousSearch === searchText) return

  try {
    const query = {
      query: SEARCH_BUILDINGS,
      variables: {
        filter: {
          and: [{ accountId: { eq: organizationId } }]
        },
        limit
      } as SearchBuildingsQueryVariables
    }
    if (searchText.trim() !== '') {
      query.variables.filter.and.push({
        or: [
          { name: { matchPhrasePrefix: `*${searchText}*` } },
          { address: { matchPhrasePrefix: `*${searchText}*` } }
        ]
      })
    }
    const apiData: any = await API.graphql(query)
    const accountsData = accountMapping(
      apiData?.data?.searchBuildings?.items,
      true
    ) as AccountData[]
    const buildings = Object.values(accountsData)?.[0]?.buildings || []
    setBuildings(buildings)
    return buildings
  } catch (error) {
    console.error(error)
  }
}

interface AccountData {
  buildings?
}
