import { TranslateComponent } from 'src/common/translations'
// import { IconCell } from "src/denali-components/Table/IconCell";
import {
  type FullDocument,
  type DocumentAttachment
} from 'src/pages/documentsAndReports/graphql'
import styles from './reports.module.scss'
import moment from 'moment'
// import { Button, Flex } from "@aws-amplify/ui-react";
import { FileBadge } from 'src/denali-components/File/FileBadge'
import { convertBytesToString } from 'src/denali-components/DropZone/helpers'

// downloadFile shared from src/pages/documentsAndReports/DocumentsAndReportsList.tsx
export const DocumentAttachments = ({
  document
}: {
  document: FullDocument
}) => {
  if (!document) return <></>

  return (
    <div className={styles.documentModalAttachments}>
      <h2 className={styles.attachmentsHeadline}>
        <TranslateComponent>Attached Files</TranslateComponent>
      </h2>
      {document?.attachments?.items?.map((attachment) => (
        <div key={attachment.id}>
          <FileBadge
            fileId={attachment && attachment.id}
            fileFullName={attachment.name}
            name={attachment.title}
            buildingId={attachment.buildingId}
            fileSize={convertBytesToString(attachment.sizeInBytes)}
            description={attachment.description}
            date={moment(attachment.attachedAt).format('LLL')}
            showDate={true}
            showDescription={true}
            showPreviewButton={false}
            downloadButton={'text'}
            deleteFile={false}
          />
        </div>
      ))}
    </div>
  )
}
