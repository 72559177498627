import React from 'react'
import { Content } from '../../../components/layouts'
import Table from 'src/components/Table/graphQLTable'
import { GET_BUILDINGS, GET_RESOURCES } from '../graphql/queries'
import { accessControlFunc } from 'src/components/accessControl'

import '../createUser/user-management.scss'
import { useSelector } from 'react-redux'
import { selectUserAccess } from '../../../redux/slicers/appData'
import { TranslateComponent } from '../../../common/translations'

function salesOfficeformatRowData(salesOffice) {
  const id = salesOffice.id ?? ''
  const district = salesOffice.district ?? ''
  const isContractor =  salesOffice.code.includes('ASC') || salesOffice.code.includes('LC3')
  const name =
    id +
    (id ? ', ' : '') +
    (isContractor ? salesOffice.code + ','  : '') +
    (salesOffice.name ?? '') +
    (district ? ', ' : '') +
    district
  return name
}

function contractorCompanyformatRowData(contractorCompany) {
  const id = contractorCompany.id ?? ''
  const name =
  id +
    (id ? ', ' : '') +
    (contractorCompany.name ?? '')
  return name
}

const salesOfficeSearchFields = ['name', 'id', 'code', 'district']
const contractorCompanySearchFields = ['name', 'id']
const otherSearchFileds = ['name']

export const BuildingsByNameTable = ({ width, resource, resourceControl, userInfoObject }) => {
  const tableHeaders = [
    {
      title: <TranslateComponent>{`${resource} Name`}</TranslateComponent>,
      key: 'name',
      // Format [code, name, district] and display names for Sales Office
      ...(resource === 'SalesOffice'
        ? { formatRowData: salesOfficeformatRowData }
        : resource === 'ContractorCompany'
        ?  { formatRowData: contractorCompanyformatRowData }
        :null
      )
    }
    // // Commenting below until resource management is done
    // {
    //   title: 'Address',
    //   key: 'address'
    // },
    // {
    //   title: 'City',
    //   key: 'city'
    // },
    // {
    //   title: 'State',
    //   key: 'state'
    // },
    // {
    //   title: 'Service Team',
    //   key: 'salesOfficeName'
    // },
    // {
    //   title: 'Organization Name',
    //   key: 'accountName'
    // }
  ]
  const userAccess = useSelector(selectUserAccess)

  const queryToUse = GET_RESOURCES(resource)

  return (
    <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
      width={width}
    >
      <Table
        key={`${resource}ByName`}
        header={tableHeaders}
        query={{
          query: queryToUse,
          variables: resource === 'SalesOffice' ? {filter: userInfoObject?.userType?.value === "400ecf30-bf26-4fea-bc05-871436c3e2aa" ? {or:[
            {
              type: {eq: "ASC-Contractor"}
            },
            {
              type: {eq: "LC3-Contractor"}
            } 
          ]}
          : {and: [
            {
              type: {ne: "ASC-Contractor"}
            },
            {
              type: {ne: "LC3-Contractor"}
            } 
          ] 
        }}: undefined,
          dataPath: `data.search${
            resource === 'ContractorCompany' ? 'ContractorCompanie' : resource
          }s`
        }}
        search={true}
        // Apply filter based on selected resource i.e for salesoffice, search filter is code,name,district
        searchFields={
          resource === 'SalesOffice'
            ? salesOfficeSearchFields
            : resource === 'ContractorCompany'
            ? contractorCompanySearchFields
            : otherSearchFileds
        }
        rowCheckbox={(d) => {
          switch (resource) {
            case 'Building':
              const admins = d.groupAdmin ? d.groupAdmin.map(e => `tc.pages.user-admin.${e}`) : []
              const base = d.groupUser ? d.groupUser.map(e => `tc.pages.user-admin.${e}`) : []
              return accessControlFunc({
                id: [
                  ...base,
                  ...admins,
                  `tc.pages.user-admin.Application`,
                  `tc.pages.user-admin.${d.id}`,
                  `tc.pages.user-admin.${d.accountId}`,
                  `tc.pages.user-admin.${d.salesOfficeId}`,
                  `tc.pages.user-admin.${d.regionId}`,
                  `tc.pages.user-admin.${d.globalRegionId}`
                ],
                userAccess
              })
            case 'SalesOffice':
              return accessControlFunc({
                id: [
                  `tc.pages.user-admin.Application`,
                  `tc.pages.user-admin.${d.id}`,
                  `tc.pages.user-admin.${d.regionId}`,
                  `tc.pages.user-admin.${d.globalRegionId}`
                ],
                userAccess
              })
            default:
              return accessControlFunc({
                id: [
                  `tc.pages.user-admin.Application`,
                  `tc.pages.user-admin.${d.id}`
                ],
                userAccess
              })
          }
        }}
        checkboxChecked={(e) => Boolean(resourceControl[0][resource]?.[e.id])}
        checkboxClick={(e) => {
          const newResources = { ...resourceControl[0][resource] }
          Boolean(newResources[e.id])
            ? delete newResources[e.id]
            : (newResources[e.id] = {
                name: e.name,
                applications: {},
                accountId: e?.accountId,
                salesOfficeId: e?.salesOfficeId,
                contractorCompanyId: e?.contractorCompanyId,
                groupUser: e?.groupUser, 
                groupAdmin: e?.groupAdmin,
                regionId: e?.regionId,
                globalRegionId: e?.globalRegionId
              })
          resourceControl[1]({
            ...resourceControl[0],
            [resource]: newResources
          })
        }}
        isRequiredQueryInputName={true}
      />
    </Content>
  )
}

BuildingsByNameTable.propTypes = {}

export default BuildingsByNameTable
