import { TranslateComponent } from 'src/common/translations'
import styles from 'src/denali-components/File/file.module.scss'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faFileLines,
  faTrash,
  faEye
} from 'src/denali-components/lib/pro-solid-svg-icons'
import { Button } from '@aws-amplify/ui-react'
import { useAttachment } from 'src/hooks/attachment'
import { useState } from 'react'

interface FileBadgeProps {
  fileId: string
  fileFullName?: string
  name?: string
  buildingId?: string
  fileSize?: string
  description?: string
  date?: string
  showDate?: boolean
  showDescription?: boolean
  showPreviewButton?: boolean
  downloadButton?: string
  deleteFile?: boolean
  deleteFunc?: any
}

export const FileBadge = ({
  fileId,
  fileFullName,
  name,
  buildingId,
  fileSize,
  description,
  date,
  showDate,
  showDescription,
  showPreviewButton,
  downloadButton,
  deleteFile,
  deleteFunc
}: FileBadgeProps) => {
  const { downloadFileFunc } = useAttachment()
  const [preview] = useState<string>('')

  // TODO this is causing 404s rn
  // useEffect(() => {
  //   previewFileFunc(name, buildingId).then((result = '') => {
  //     setPreview(result || '')
  //   })
  // }, [])

  return (
    <>
      <div className={styles.fileBadge}>
        <div className={styles.fileContent}>
          {/* TODO investigate preview link 404s, removing for now */}
          {/* {preview.startsWith('blob:') ? (
            <img className={styles.filePreview} src={preview} alt={name} />
          ) : ( */}
          <div className={styles.filePreview} data-color={preview}>
            <FontAwesomeIcon icon={getIcon(preview)} />
          </div>
          {/* )} */}
          <div className={styles.fileInfo}>
            {fileFullName && (
              <TranslateComponent>{fileFullName}</TranslateComponent>
            )}
            <div className={styles.fileDateAndSize}>
              {showDate && <TranslateComponent>{date}</TranslateComponent>}
              {showDate && date && fileSize && ' • '}
              {fileSize && (
                <TranslateComponent>
                  <span className={styles.fileSize}>{fileSize}</span>
                </TranslateComponent>
              )}
            </div>
          </div>
          {showDescription && description && (
            <div className={styles.fileDescription}>
              <TranslateComponent>{description}</TranslateComponent>
            </div>
          )}
        </div>
        <div className={styles.fileActions}>
          {downloadButton === 'icon' && (
            <Tooltip>
              <TooltipTrigger testName="downloadUtilityBillDataTrigger">
                <Button
                  onClick={() => downloadFileFunc(name, buildingId)}
                  className="button-gray button-icon"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <TooltipContent>
                  <TranslateComponent>Download</TranslateComponent>
                </TooltipContent>
              </TooltipTrigger>
            </Tooltip>
          )}
          {downloadButton === 'text' && (
            <Button onClick={() => downloadFileFunc(name, buildingId)}>
              Download File
            </Button>
          )}
          {/* TODO: Wire up Preview button */}
          {showPreviewButton && (
            <Button
              className="button-gray button-icon"
              // onClick={openAnnotation}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          )}
          {deleteFile && (
            <Button
              className="button-red button-icon"
              onClick={() => deleteFunc(fileId, fileFullName)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </div>
      </div>

      {/* TODO: How to make the previous ImageEditor work with the limited file data since it's not saved */}
      {/* <ImageEditorWrapper
        imageSrc={imageUrl}
        openImageEditor={isOpenAnnotation}
        cancelChanges={() => setState({ ...state, isOpenAnnotation: false })}
        uploadFile={(value) => {
          setState({ ...state, isOpenAnnotation: false })
          setTimeout(() => {
            setEditedImage(value)
          }, 200)
        }}
        setOriginalUrl={(value) =>
          setState({ ...state, originalImageUrl: value })
        }
        setPreviousMarkerState={(value) =>
          setState({ ...state, previousMarkerState: value })
        }
        originalImageUrl={originalImageUrl}
        previousMarkerState={previousMarkerState}
      /> */}
    </>
  )
}

// Need icons for different file types
const getIcon = (preview) => {
  switch (preview) {
    case 'doc':
      return faFileLines
    default:
      return faFileLines
  }
}
