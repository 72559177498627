import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { useFormik } from 'formik'
import { ChangeEvent } from 'react'
import { useMutation } from 'src/hooks/APIHooks'
// components
import Modal from 'src/components/legacy/components/modal/modal'

import { ACTIONS } from 'src/constants'

import validationSchema from './formValidator'
import { CREATE_BENCH_MARK, UPDATE_BENCH_MARK } from './../graphql'
import { IMarker } from '../types'
import { tranformDateToISO } from 'src/utils'
import styled from 'styled-components'
import { PopUpSup } from './../styles'
import _isEmpty from 'lodash/isEmpty'
import translate, { TranslateComponent } from 'src/common/translations'


const textBoxWidth = {
	width: "100%"
}

const ERROR_MESSAGES = {
	benchmarkName: "Please enter a benchmark name",
	value: "Please enter a benchmark value",
	badBenchmarkValue: "Benchmark must be greater than zero"
}

const ModalDivSpacer = styled.div<{ orgView: boolean }>`
  ${(props) =>
    props.orgView
      ? `display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #eeeeee;
    > * {
      &:last-child {
          background-color: transparent;
            margin-left: 20px;
      }
      }
    `
      : ''}
  .custom-label{
    padding: 0px;
  }
`

const ACTION_HEADERS = {
  [ACTIONS.ADD]: 'Create Benchmark',
  [ACTIONS.EDIT]: 'Edit Benchmark',
  [ACTIONS.VIEW]: 'View Benchmark',
  [ACTIONS.COPY]: 'Copy Benchmark'
}

const BenchMark = {
  CONFIRMATION_TYPES: {
    CREATE: 'create',
    UPDATE: 'update',
    EMPTY: ''
  }
}

const BenchmarkDetail = ({
  mode,
  setMode,
  markerInput,
  setMarkerInput,
  initialValues,
  buildingId,
  accountId,
  setReloadEvents,
  setConfirmationType=(e:any)=>{}
}) => {

  const getModalHeading = () => {
    const heading =
      mode === ACTIONS.ADD || mode === ACTIONS.COPY
        ? ``
        : markerInput?.name && `: ${markerInput?.name}`
    return `${ACTION_HEADERS[mode]}${heading}`
  }

  const { onSubmit: createEventsMutation , loading: createBenchMarkLoading} = useMutation({
    query: CREATE_BENCH_MARK,
    onSuccess: () => {
      closeMarkerDialog()
      setReloadEvents(true)
      setConfirmationType(BenchMark.CONFIRMATION_TYPES.CREATE)
    }
  })

  const { onSubmit: updateEventsMutation } = useMutation({
    query: UPDATE_BENCH_MARK,
    onSuccess: () => {
      closeMarkerDialog()
      setReloadEvents(true)
      setConfirmationType(BenchMark.CONFIRMATION_TYPES.UPDATE)
    }
  })
  const formik = useFormik({
    initialValues: markerInput,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: IMarker) => {
      const {
        name,
        markerDate: rawMarkerDate,
        description,
        type,
        value
      } = values
      const markerDate = tranformDateToISO(rawMarkerDate)
      mode === ACTIONS.EDIT
        ? updateEventsMutation({
            input: {
              id: markerInput.id,
              name,
              description,
              markerDate:rawMarkerDate,
              type: "EUI",
              value,
              buildingId
            }
          })
        : createEventsMutation({
            input: {
              name,
              description,
              markerDate,
              type: "EUI",
              value,
              buildingId
            }
          })
          setMarkerInput(values)
    }
  })

  const { values, errors,touched } = formik

  const getButtons = (submitForm, isFormValid = false) => {
    return mode === ACTIONS.VIEW
      ? [{ text: <TranslateComponent>Close</TranslateComponent>, handleClick: closeMarkerDialog }]
      : [
          {
            testName: "benchMark",
            text: <TranslateComponent>{mode === ACTIONS.ADD ? 'Create' : 'Save'}</TranslateComponent>,
            type: 'submit',
            handleClick: submitForm,
            disabled: !isFormValid || createBenchMarkLoading
          },
          {
            testName: "benchMark",
            text: <TranslateComponent>Cancel</TranslateComponent>,
            type: 'cancel',
            handleClick: closeMarkerDialog
          }
        ]
  }

  const closeMarkerDialog = () => {
    setMode(null)
    setMarkerInput({...values})
  }

  const modalConfig = {
    gray: true,
    heading: getModalHeading(),
    buttons: getButtons(formik.handleSubmit, formik.isValid),
    handleClose: closeMarkerDialog
  }

  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value)
  }

  return (
    <Modal {...modalConfig}>
      <ModalDivSpacer orgView={Boolean(!buildingId && mode !== ACTIONS.EDIT)}>
        <div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextInput
              testName= "EUI_events"
              labelText="EUI Benchmark Name"
              defaultValue={values?.name}
              name="name"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) =>{
                formik.setFieldTouched('name')
                handleInputChange(name, value)
              }
              }
               
              hasError={touched?.name && errors?.['name']}
              errorMessage={ERROR_MESSAGES["benchmarkName"]}
            />
            <TextAreaInput
              testName ={"EUI_Description_events"}
              labelText={translate("EUI Benchmark Description")}
              defaultValue={values?.description}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('description', value)
              }
              name="description"
              isRequired={false}
            />
            <TextInput
              testName="EUI_value_events"
              style={textBoxWidth}
              type='number'
              labelWithPowerValue={<>{translate("EUI Benchmark Value")} (kBTU/ft<PopUpSup>2</PopUpSup>/year)</>}
              defaultValue={+values?.value}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) => {
                formik.setFieldTouched('value')
                const val = (!_isEmpty(value) && !isNaN(+value)) ? +value : null
                handleInputChange('value', val)
              }}
              name="value"
              inputClass="align-left"
              hasError={touched?.value && (errors?.["value"])}
              errorMessage={(() => {
                if (touched?.value && errors?.["value"]) {
                  return errors?.["value"]
                }
              })()}
            />
          </form>
        </div>
      </ModalDivSpacer>
    </Modal>
  )
}
export default BenchmarkDetail
