import React, { useEffect, useState } from 'react'
import {connect, useSelector} from 'react-redux'
import styled from 'styled-components'
import UserManagementTable from '../components/user-management-table'
import NewTable from '../../../denali-pages/UserManagement/UserManagementTable'
import { NewStyles } from 'src/NewStyles'
import { t } from 'i18next'
import Button from 'src/denali-ui/components/Button'
import Header from '../../../components/pageHeader/header'
import Icon from 'src/denali-ui/components/Icon'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import Modal from 'src/denali-ui/components/Modal'
import { AccessControl } from 'src/components/accessControl'
import translate, { TranslateComponent } from '../../../common/translations'
import { useMutation } from 'src/hooks/APIHooks'
import { updateUser } from '../graphql/mutations'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsersGear } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { ACTIONS } from "../../../constants";
import { Button as AmplifyButton } from '@aws-amplify/ui-react'
import { selectUiMode } from "../../../redux/slicers/appData";
import { UiMode } from "../../../redux/types/AppTypes";

const FieldDiv = styled.span`
  margin-right: 16px;
`

const ButtonContainer = styled.div`
  text-align: end;
`

export const UserManagement = () => {
    const [error, setError] = useState(false)
    const [deactivateUserDialog, setDeactivateUserDialog] = useState(false)
    const [reactivateUserDialog, setReactivateUserDialog] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState('Users by Name')
    const [traneUserError, setTraneUserError] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const uiMode = useSelector(selectUiMode)

    const { onSubmit: editUser } = useMutation({
        query: updateUser,
        dataPath: 'data',
        onSuccess: (data) => {
            // Need to have timeout due to delay in data actually being updated.
            setTimeout(() => {
                setRefetch(true)
            }, 1000);
          }
    })

    useEffect(() => {
        if (refetch) {
          setRefetch(false)
        }
    }, [refetch])

    const renderError = (error) => {
        return (
          <Modal
            //heading={t("errors:Error")}
            handleClose={() => {
              setError(false)
            }}
            buttons={[
              {
                variant: 'cancel',
                text: <TranslateComponent>Close</TranslateComponent>,
                handleClick: () => {
                  setError(false)
                }
              }
            ]}
            isAlert={true}
          ></Modal>
        )
    }

    const renderCreateUserButton = (isDenaliMode, amplifyButton) => {
    return (
        <AccessControl id="tc.pages.user-management.add">
          <Link
              to={{
                pathname: '/user-management/add',
                // search: getUrlSearchParamValue(buildingId, accountId)
              }}
              state={{ mode: ACTIONS.ADD, id: null }}
          >
            {amplifyButton ? (
                <AmplifyButton
                    data-testid={'createUserButton'}
                    ariaLabel={translate("Create User")}
                    className="button"
                    // onClick={handleCreateClicked}
                >
                  <TranslateComponent>Create User</TranslateComponent>
                </AmplifyButton>
            ) : (
              <ButtonContainer>
                <Button
                    variant="primary"
                    size="medium"
                    startIcon={ <Icon name="Add" color="#ffffff"/> }
                >
                  <TranslateComponent>Create User</TranslateComponent>
                </Button>
              </ButtonContainer>
            )}
          </Link>
        </AccessControl>
      )
    }

    const renderActivateDeactivateModal = (isActivating) => {
        return (
            <NewStyles>
                <Modal
                    heading={<TranslateComponent>{isActivating ? "Activate User" : "Deactivate User"}</TranslateComponent>}
                    handleClose={() => {
                     isActivating ? setReactivateUserDialog(false) : setDeactivateUserDialog(false)
                    }}
                    buttons={[
                      {
                        variant: "primary",
                        text: <TranslateComponent>Confirm</TranslateComponent>,
                        handleClick: () => {
                          isActivating ? setReactivateUserDialog(false) : setDeactivateUserDialog(false)
                          editUser({input: {
                              id: isActivating ? reactivateUserDialog?.id : deactivateUserDialog?.id,
                              isActive: isActivating ? 1 : 0
                            }})
                          trackEvent(USER_EVENTS.ADMIN_TOOLS.events.SAVE_USER, {mode: isActivating ? "ACTIVATE" : "DEACTIVATE"})
                        }
                      },
                      {
                        variant: "secondary",
                        text: <TranslateComponent>Cancel</TranslateComponent>,
                        handleClick: () => {
                          isActivating ? setReactivateUserDialog(false) : setDeactivateUserDialog(false)
                        }
                      }
                    ]}
                    isAlert={false}
                >
                    <p><TranslateComponent>{`Are you sure you wish to  
                    ${isActivating ? 'activate' : 'deactivate'} 
                    ${isActivating ? reactivateUserDialog.firstName : deactivateUserDialog.firstName } 
                    ${isActivating ? reactivateUserDialog.lastName : deactivateUserDialog.lastName }'s account?`}</TranslateComponent></p>
                </Modal>
            </NewStyles>
        )
    }

    const renderTraneError = () => {
        return (
            <Modal
                heading={t('users:SendResetPasswordEmail')}
                handleClose={() => {
                  setTraneUserError(false)
                }}
                buttons={[
                  {
                    variant: 'cancel',
                    text: <TranslateComponent>Close</TranslateComponent>,
                    handleClick: () => {
                      setTraneUserError(false)
                    }
                  }
                ]}
                isAlert={true}
            >
              <p>{t('errors:reset-user-password-invalid-email')}</p>
            </Modal>
        )
    }

    const renderTable = () => {
        switch (selectedFilter) {
            case 'Users by Name':
                return <UserManagementTable refetch={refetch} setDeactivateUserDialog={(e) => setDeactivateUserDialog(e)} setReactivateUserDialog={(e) => setReactivateUserDialog(e)} />
        }
    }

    return (
        uiMode !== UiMode.denali ?
          <NewStyles>
            {deactivateUserDialog ? renderActivateDeactivateModal(false) : ""}
            {reactivateUserDialog ? renderActivateDeactivateModal(true) : ""}
            {traneUserError ? renderTraneError() : ""}
            {error ? renderError() : ""}

            <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                border="none"
              >
                <Header
                  hideToolBar={true}
                  pageTitle={<TranslateComponent>User Management</TranslateComponent>}
                />
              </Content>
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                border="none"
              >
                {renderCreateUserButton(false, false)}
              </Content>
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                border="none"
              >
                {renderTable()}
              </Content>
            </Container>
          </NewStyles>
          : <Page
              title={translate('User Management')}
              icon={<FontAwesomeIcon icon={faUsersGear} />}
              iconColor={IconColor.blue}
              actions={[renderCreateUserButton(true, true)]}
            >
                {deactivateUserDialog ? renderActivateDeactivateModal(false) : ""}
                {reactivateUserDialog ? renderActivateDeactivateModal(true) : ""}
                {traneUserError ? renderTraneError() : ""}
                {error ? renderError() : ''}
                <NewTable refetch={refetch} setDeactivateUserDialog={setDeactivateUserDialog} setReactivateUserDialog={setReactivateUserDialog} />
            </Page>
)
}

export const mapStateToProps = ({ dimensions: { width } }) => ({
  width
})

export default connect(mapStateToProps, {})(UserManagement)
