// Project imports
import { TranslateComponent } from 'src/common/translations'
import {
  CheckboxField,
  Flex,
  Fieldset,
  Label,
  SelectField,
  Input
} from '@aws-amplify/ui-react'

// Denali Components
import formStyles from 'src/denali-components/Form/form.module.scss'

// Local imports
import { DenaliFormElement } from './DenaliFormElement'
import { DenaliImpactCardProps } from './types'
import { TIME_PERIOD_OPTION_LIST_FOR_KPI_DROP_DOWN } from 'src/components/legacy/common/consultation'
import { DenaliFormElementError } from './DenaliFormElementErrors'

const DenaliImpactCard = ({
  index,
  name,
  value,
  cardLabel,
  priority,
  custom,
  savings,
  timePeriod,
  showSpecificValue,
  children
}: DenaliImpactCardProps) => {
  // RHF uses dot notation only
  const kpiIndex = `settings.kpi.${index}`

  const defaultValueList = [
    {
      key: 'high',
      label: 'high'
    },
    {
      key: 'medium',
      label: 'medium'
    },
    {
      key: 'low',
      label: 'low'
    }
  ]
  const specificValueList = [
    {
      key: 'specificValue',
      label: 'specific value'
    }
  ]

  const kpiImpactOptionList = showSpecificValue
    ? [...defaultValueList, ...specificValueList]
    : [...defaultValueList]

  return (
    <Fieldset
      legend={'settings kpi'}
      legendHidden
      className={formStyles.impactCheckboxCard}
    >
      <Flex
        direction={'column'}
        width={'100%'}
        gap="var(--amplify-space-small)"
      >
        <Flex
          direction={'row'}
          justifyContent={'space-between'}
          wrap={'nowrap'}
        >
          <Flex>
            <DenaliFormElement name={`${kpiIndex}.value`}>
              <CheckboxField
                id={`kpi-checkbox-${index}`}
                name={name}
                value={value?.toString()}
                label={name}
                checked={value}
                labelHidden
              />
            </DenaliFormElement>
            <Label htmlFor={`kpi-checkbox-${index}`}>
              <TranslateComponent>Show</TranslateComponent>{' '}
              <TranslateComponent>{cardLabel}</TranslateComponent>
            </Label>
          </Flex>
          {/* Icon here */}
          {children}
        </Flex>
        <hr />
        {custom && value && (
          <Fieldset
            legend={'custom metric name'}
            legendHidden
            className={formStyles.customMetricInputContainer}
          >
            <Label htmlFor="customLabelInput" className={formStyles.smlLabel}>
              <span>
                <TranslateComponent>Custom Metric Name</TranslateComponent>:
              </span>
            </Label>
            <DenaliFormElement name={`${kpiIndex}.name`}>
              <Input
                className={formStyles.customMetricInputElement}
                id={'customLabelInput'}
              />
            </DenaliFormElement>
          </Fieldset>
        )}
        <Flex className={formStyles.impactLabelContainer}>
          <Label
            htmlFor={`impact-${name}-${index}`}
            className={formStyles.smlLabel}
          >
            <span>
              <TranslateComponent>Impact</TranslateComponent>:
            </span>
          </Label>
          <DenaliFormElement name={`${kpiIndex}.priority`}>
            <SelectField
              id={`impact-${name}-${index}`}
              label={name}
              labelHidden
              value={priority}
              className={formStyles.impactLabel}
            >
              {kpiImpactOptionList.map(({ key, label }) => (
                <option key={key} value={key}>
                  <TranslateComponent>{label}</TranslateComponent>
                </option>
              ))}
            </SelectField>
          </DenaliFormElement>
        </Flex>
        {priority === 'specificValue' && (
          <Flex className={formStyles.kpiSpecificValueContainer}>
            <Flex direction={'column'}>
              <DenaliFormElement
                name={`${kpiIndex}.savings`}
                className={formStyles.kpiSpecificValueElement}
              >
                <Input
                  value={savings}
                  placeholder="Dollar amount"
                  type={'number'}
                  className={formStyles.kpiSpecificValueElement}
                />
              </DenaliFormElement>
              <DenaliFormElementError name={`${kpiIndex}.savings`} />
            </Flex>
            <DenaliFormElement name={`${kpiIndex}.timePeriod`}>
              <SelectField
                labelHidden
                label={'specific value time options'}
                value={timePeriod}
                className={formStyles.kpiSpecificValueElement}
              >
                {TIME_PERIOD_OPTION_LIST_FOR_KPI_DROP_DOWN.map(
                  ({ key, value }) => (
                    <option key={key} value={key}>
                      <TranslateComponent>{value}</TranslateComponent>
                    </option>
                  )
                )}
              </SelectField>
            </DenaliFormElement>
          </Flex>
        )}
      </Flex>
    </Fieldset>
  )
}

export default DenaliImpactCard
