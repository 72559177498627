import translate, { TranslateComponent } from 'src/common/translations'
import { Button } from '@aws-amplify/ui-react'
import { ACTIONS } from 'src/constants'

export const CreateNoteButton = ({
  setMode,
  setNotesInput,
  initialValues,
  userInfo
}) => {
  return (
    <Button
      data-testid={'createNoteButton'}
      className="button"
      onClick={() => {
        setMode(ACTIONS.ADD)
        setNotesInput({
          ...initialValues,
          ...{
            currentUserId: userInfo?.id,
            userId: userInfo?.id,
            createdByName: `${userInfo?.firstName ? userInfo.firstName : ''} ${
              userInfo?.lastName ? userInfo.lastName : ''
            }`
          }
        })
      }}
    >
      <TranslateComponent>Create Note</TranslateComponent>
    </Button>
  )
}
