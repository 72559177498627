import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Table from '../../components/Table/clientSideTable'
import {
  listDocumentsAndReportsOrganization,
  listDocumentsAndReportsByBuilding,
  listConsultations,
  GET_PRE_SIGNED_URL,
  GET_CONSULTATION,
  listEnergyUsageReportsByBuilding,
  listEnergyusageReportByAccount,
  GET_DOCUMENT_BY_ID,
  LIST_ATTACHMENT_BY_CONSULTATION
} from './graphql'
import { MODAL_TYPE } from 'src/constants'
import { documentTypeLists, consultationType, documentType, fetchConsultationWithImages, energyUsageReportType, getStatusOptionList, getDateRange } from './helper'
import moment from 'moment'
import { useDocumentsAndReportsContext } from './DocumentsAndReportsContextProvider'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import {
  REPORT_FILTER_TYPE_LIST,
  STATUS_OPTION_FILETER_LIST
} from 'src/components/legacy/common/report.js'
import _uniqBy from 'lodash/uniqBy'
import DocumentsViewModal from './DocumentDetailsModal'
import { ACTIONS } from 'src/constants'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { saveAs } from 'file-saver'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAttachment } from 'src/hooks/attachment'
import translate, { TranslateComponent } from 'src/common/translations'
import {
  selectUserInfo
} from 'src/redux/slicers/appData'
import { getSearchParams } from '../../common/helperFunctions'
import ConsultationDetailDownload from './consultation-blocks/consultation-detail-download/consultation-detail-download'
import Spinner from 'src/components/Spinner'
import { DisableDiv } from './styles'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { UiMode } from 'src/redux/types/AppTypes'
import { ReportsList } from 'src/denali-pages/Reports/ReportsList'
import { accessControlFunc } from 'src/components/accessControl'
import { selectUserAccess } from 'src/redux/slicers/appData'
import useAttachmentClaims from './attachmentClaimsHook'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useDispatch } from 'react-redux'
import { newRefreshToken } from 'src/redux/slicers/appData'



const DocumentsAndReportsList = ({ uiMode, noTableDataAction }: { uiMode: UiMode, noTableDataAction: ReactNode }) => {
  const { downloadFileFunc, downloadFileURL } = useAttachment()
  const [rows, setRows] = useState([])
  const [contactsOptionFilter, setContactsOptionFilter] = useState([])
  const { 
    reloadEvents, 
    setReloadEvents, 
    setDocReportRow,
    documentModalView,
    setDocumentModalView,
    setModalType,
    setConsultationId,
    setReportId,
    setMode
  } = useDocumentsAndReportsContext()
  const [RefreshAttachmentClaims] = useAttachmentClaims()
  const {buildingId, organizationId} = getSearchParams()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {search} = useLocation();
  const userInfo = useSelector(selectUserInfo)
  const currentUser = `${userInfo?.firstName} ${userInfo?.lastName}`
  const [consultationDetails, setConsultationDetails] = useState(null)
  const [isDownload, setIsDownload] = useState(false)
  const [searchParams] = useSearchParams();
  const userAccess = useSelector(selectUserAccess)
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  const optionsTranslated = {
    name: translate('Name'),
    type: translate('Type'),
    org: translate('Organization'),
    building: translate('Building'),
    report: translate('Report Time Period'),
    creation: translate('Creation Date'),
    visibility: translate('Visibility'),
    contact: translate('Contact'),
    status: translate('Status'),
    edit: translate(ACTIONS.EDIT),
    delete: translate(ACTIONS.DELETE),
    copy: translate(ACTIONS.COPY),
    download: translate(ACTIONS.DOWNLOAD)
  }

  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })

  
      const {refetch: refetchlistAttachmentsByConsultation } =
      useQuery({
        query: LIST_ATTACHMENT_BY_CONSULTATION,
        disableInitialLoad: true,
        errorPolicy: 'ignore',
        dataPath: 'data.listAttachmentsByConsultation'
      })
  

  const { loading, refetch: refetchConsultationDetails } = useQuery({
    query: GET_CONSULTATION,
    disableInitialLoad: true,
    dataPath: 'data.getConsultation',
    onSuccess: (consultation) => {
      try {
        if (consultation) {
          (async () => {
              const buildingsList = ConsultationList?.listBuildingsByAccount?.items ?? []
              const buildingIDs = consultation?.buildingIds ?? []
              let newConsultation = JSON.parse(JSON.stringify(consultation))
              let attachments = [];
                        await dispatch<any>(newRefreshToken(buildingIDs?.length ? [buildingIDs[0]] : [], [ newConsultation?.accountId], 
                          ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"]))
                       attachments = await refetchlistAttachmentsByConsultation({consultationId : consultation.id})
                      newConsultation = {...newConsultation, attachments}
              const locationDetails = buildingsList?.filter(f => buildingIDs?.includes(f?.id))?.map(m => ({ locationName: m?.name })) ?? []
              newConsultation.account = {id: newConsultation?.accountId, name: buildingsList?.[0]?.accountName || ""}
              newConsultation.locations = locationDetails
              await handleConsultationData(newConsultation)
            
          })()
        }
      } catch(error){

      }
    },
    onError: () =>{
      setIsDownload(false)
    }
  })

  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId).then((res) => {
        item.original = res
        item.enabled = true
      }).catch((err)=>{
        console.log('err')
      })
    }
    return item
  }
  
  const handleConsultationData = async (consultation) =>{
    const consultationData = await fetchConsultationWithImages(consultation, fetchImage)
    setConsultationDetails(consultationData)
    setIsDownload(false)
  }  
  const {
    data: DocumentsAndReportsList,
    refetch: refetchReportsList,
    responseTime,
    loading: documentsAndReportsListLoading
  } = useQuery(
    !buildingId
      ? {
          query: listDocumentsAndReportsOrganization,
          dataPath: 'data',
          variables: { accountId: organizationId },
          disableInitialLoad: true
        }
      : {
          query: listDocumentsAndReportsByBuilding,
          dataPath: 'data',
          variables: { buildingId },
          disableInitialLoad: true
        }
  )

  const {
    data: ConsultationList,
    refetch: refetchConsultationList,
    loading: consultationListLoading
  } = useQuery({
    query: listConsultations,
    dataPath: 'data',
    disableInitialLoad: true
  })

  const {
    data: EnergyUsageReportList,
    refetch: refetchEnergyUsageReportList,
    loading: energyUsageReportListLoading
  } = useQuery(
    !buildingId
      ? {
          query: listEnergyusageReportByAccount,
          dataPath: 'data',
          variables: { accountId: organizationId },
          disableInitialLoad: true
        }
      : {
          query: listEnergyUsageReportsByBuilding,
          dataPath: 'data',
          variables: { buildingId },
          disableInitialLoad: true
        }
    )

  const listDataIsLoading = documentsAndReportsListLoading || consultationListLoading || energyUsageReportListLoading || !isInitialLoadComplete

  useEffect(() => {
    setTimeout(() => {
      setIsInitialLoadComplete(true);
      if (buildingId) {
        setRows([])
        setConsultationDetails(null)
        refetchReportsList({ buildingId })
        refetchEnergyUsageReportList({ buildingId })
        refetchConsultationList({
          accountId: organizationId,
          filter: {
            accountId: { eq: organizationId },
            buildingIds: { eq: buildingId }
          },
          limit: 1000,
          id: { eq: buildingId }
        })
        setIsLoading(false);
      } else if (organizationId) {
        setRows([])
        setConsultationDetails(null)
        refetchReportsList({ accountId: organizationId })
        refetchEnergyUsageReportList({ accountId: organizationId })
        refetchConsultationList({
          accountId: organizationId,
          filter: {
            accountId: { eq: organizationId }
          },
          limit: 1000
        })
        setIsLoading(false);
      }
    }, 1000);
  }, [buildingId, organizationId, searchParams])

  useEffect(() => {
    if (reloadEvents) {
      setRows([])
      setConsultationDetails(null)
      setReloadEvents(false)
      setTimeout(() => {
        setIsInitialLoadComplete(true);
        if (buildingId) {
          refetchReportsList({ buildingId })
          refetchEnergyUsageReportList({ buildingId })
          refetchConsultationList({
            accountId: organizationId,
            filter: {
              accountId: { eq: organizationId },
              buildingIds: { eq: buildingId }
            },
            limit: 1000,
            id: { eq: buildingId }
          })
        } else if (organizationId) {
          refetchReportsList({ accountId: organizationId })
          refetchConsultationList({
            accountId: organizationId,
            filter: {
              accountId: { eq: organizationId }
            },
            limit: 1000
          })
        }
      }, 1000);
    }
  }, [reloadEvents])

const getDocumentsList = () => {
    let docsList = []
    let buildingsList = []
    if(buildingId) {
      docsList = DocumentsAndReportsList?.listDocumentsByBuilding?.items || []
      const buildingDetails = DocumentsAndReportsList?.getBuilding || null
      buildingsList = buildingDetails ? [buildingDetails] : []
    } else if(organizationId) {
      docsList = DocumentsAndReportsList?.listDocumentsByAccount?.items || []
      buildingsList = DocumentsAndReportsList?.listBuildingsByAccount?.items || []
    }
    return docsList
    ?.filter((doc) =>
      !doc?.visibility
        ? accessControlFunc({
            id: 'tc.pages.documents-and-reports.view-private',
            userAccess
          })
        : doc?.visibility
    )
    ?.map((doc) => {
      const buildingDetails =
        buildingsList?.find((x) => x.id === doc?.buildingId) || {}
      return {
        ...doc,
        document: doc,
        building: buildingDetails,
        buildingName: buildingDetails?.name || '',
        accountName: buildingDetails?.accountName || '',
        visibility: doc?.visibility ? 'ON' : 'OFF',
        reportTimePeriod: `${doc?.startDate ? doc?.startDate : ''}  ${
          doc?.endDate ? '-' + doc?.endDate : ''
        }`,
        start: doc?.startDate && moment(doc?.startDate).format('LL'),
        end: doc?.endDate && moment(doc?.endDate).format('LL'),
        status:
          getStatusOptionList(t)?.find((option) => option.key === doc?.status)
            ?.value || doc?.status,
        createdAt: doc?.startDate
          ? moment(doc?.startDate).format('YYYY-MM-DD')
          : moment(doc?.createdAt).format('YYYY-MM-DD'),
        filterType: documentTypeLists?.includes(doc?.type) && documentType // Should we display just 'Document'?
      }
    })
  }

  const getEUList = () => {
    let euList = []
    let buildingsList = []
    if(buildingId) {
      euList = EnergyUsageReportList?.listEnergyusageReportByBuilding?.items || []
      const buildingDetails = EnergyUsageReportList?.getBuilding || null
      buildingsList = buildingDetails ? [buildingDetails] : []
    } else if(organizationId) {
      euList = EnergyUsageReportList?.listEnergyusageReportByAccount?.items || []
      buildingsList = EnergyUsageReportList?.listBuildingsByAccount?.items || []
    }
    return euList
    ?.filter((doc) =>
      !doc?.visibility
        ? accessControlFunc({
            id: 'tc.pages.documents-and-reports.view-private',
            userAccess
          })
        : doc?.visibility
    )?.map((doc) => {
      const buildingDetails = buildingsList?.find(x=>x.id === doc?.buildingId) || {}
      return {
        ...doc,
        building: buildingDetails,
        buildingName: buildingDetails?.name || "",
        accountName: buildingDetails?.accountName || "",
        visibility: doc?.visibility ? "ON" : "OFF",
        reportTimePeriod: `${doc?.startDate ? doc?.startDate : ''}  ${doc?.endDate ? '-' + doc?.endDate : ''}`,
        start: doc?.startDate && moment(doc?.startDate).format('LL'),
        end: doc?.endDate && moment(doc?.endDate).format('LL'),
        status:getStatusOptionList(t)?.find((option)=>option.key===doc?.status)?.value || doc?.status,
        createdAt: doc?.startDate ? moment(doc?.startDate).format('YYYY-MM-DD') : moment(doc?.createdAt).format('YYYY-MM-DD'),
        filterType: energyUsageReportType,  // Should we display just 'Document'?
      }
    })
  }

const getConsultationList = () => {
    const buildingDetails = ConsultationList?.listBuildingsByAccount?.items ?? []
    const consultationsList = ConsultationList?.searchConsultations?.items ?? []
    return consultationsList?.filter((c) =>
      !c?.visibility
        ? accessControlFunc({
            id: 'tc.pages.documents-and-reports.view-private',
            userAccess
          })
        : c?.visibility
    )?.map(consultation => {
      const buildingDetail = buildingDetails
        ?.filter(b => consultation?.buildingIds?.includes(b?.id))
        ?.map(m => ({ buildingName: m?.name, accountName: m?.accountName })) ?? []
  
      return {
        ...consultation,
        building: {
          accountName: DocumentsAndReportsList?.listBuildingsByAccount?.items?.[0]?.accountName
        },
        buildingName: buildingDetail?.map(b => b?.buildingName)?.join(", "),
        accountName: buildingDetail?.[0]?.accountName,
        visibility: consultation?.visibility ? "ON" : "OFF",
        filterType: consultationType,
        createdAt: consultation?.createdAt ? moment(consultation?.createdAt).format('YYYY-MM-DD') : '',
        reportTimePeriod: `${consultation?.startDate ? consultation?.startDate : ''} - ${consultation?.endDate ? consultation?.endDate : ''}`,
        start: consultation?.startDate && moment(consultation?.startDate).format('LL'),
        status: getStatusOptionList(t)?.find((option) => option.key === consultation?.status)?.value || consultation?.status,
        end: consultation?.endDate && moment(consultation?.endDate).format('LL'),
      }
    }) ?? []
  };
  
  useEffect(() => {
    const combinedRows = [
      ...getDocumentsList(),
      ...getEUList(),
      ...getConsultationList()
    ];
    setRows(combinedRows);
  }, [DocumentsAndReportsList, EnergyUsageReportList, ConsultationList]);

  useEffect(()=> {
    setContactsOptionFilter(rows?.map((report) => ({ name: report?.contact, value: report?.contact } )))
  }, [rows])

  const nameHeadings = [
    {
      title: optionsTranslated.name,
      key: 'title',
      maxWidth: '200px',
      onDataClick: (data) => {
        switch(data?.filterType){
          case documentType : {
            setMode(ACTIONS.VIEW)
            setDocumentModalView(true)
            setDocReportRow(data)
            trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.VIEW_DOCUMENT,
            {
              "document id":data?.id,
              title:data?.title,
            })
            break;
        }
          case consultationType: {
            setMode(ACTIONS.VIEW)
            setConsultationId(data?.id)
            navigate({
              pathname:`/Consultations/${data?.id}`,
              search
            },{
              state: { mode: ACTIONS.VIEW, id: data?.id }
            })
            trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.VIEW_REPORT,
              {
                "report id":data?.id,
                title:data?.title,
                "report type":data?.filterType,
              })
            break;
          }
          case energyUsageReportType: {
            setMode(ACTIONS.VIEW)
            setReportId(data?.id)
            navigate({
              pathname:`/energy-reports/${data?.id}`,
              search: `reportType=EnergyUsageReport`
            },{
              state: { mode: ACTIONS.VIEW, id: data?.id }
            })
            trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.VIEW_REPORT,
              {
                "report id":data?.id,
                title:data?.title,
                "report type":data?.filterType,
              })
            break;
          }
          default: return
        }
      }
    },
    {
      title: optionsTranslated.type,
      key: 'filterType',
      maxWidth: '100px',
      customComponent: (row) => { return <TranslateComponent>{row.filterType}</TranslateComponent> }
    },
    {
      title: optionsTranslated.org,
      key: 'accountName',
      maxWidth: '80px'
    },
    {
      title: optionsTranslated.building,
      key: 'buildingName',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.report,
      key: 'reportTimePeriod',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.creation,
      key: 'createdAt',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.visibility,
      key: 'visibility',
      maxWidth: '50px',
      customComponent: (row) => { return <TranslateComponent>{row.visibility}</TranslateComponent> }
    },
    {
      title: optionsTranslated.contact,
      key: 'contact',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.status,
      key: 'status', 
      maxWidth: '50px',
	    customComponent: (row) => { return <TranslateComponent>{row.status}</TranslateComponent> }
    }
  ]

  const downloadFile = async (file) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "DOWNLOAD", "key": "${file?.name}", "buildingId": "${file?.buildingId}" }`
      }).then(async (res) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            return await saveAs(responseBlob, file?.name)
          })
          .catch((error) => console.log('error', error))
      })
    } catch(error){
      
    }
  
  }

  const downloadFileFromS3 = async(name, buildingId) =>{
    await downloadFileFunc(name, buildingId).then((res) => {
    }).catch((err)=>{
      console.log('Document Bulk Download Error Response',err)
    })
  }

  const handleBulkDownload = async (files) => {
    for (const file of files) {
      await downloadFileFromS3(file?.name, file?.buildingId)
    }
    setIsDownload(false)
  }

  const { refetch: downloadDocumentAttachment } = useQuery({
    query: GET_DOCUMENT_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDocument',
    onSuccess: async (response) =>{
      const attachments = response?.attachments?.items ?? []
      await handleBulkDownload(attachments)
    },
    onError: ()=>{
      setIsDownload(false)
    }
  })

  const isActionControlAccessible = (id) => {
    return accessControlFunc({
      id,
      userAccess
    })
  }

  const getListRowControl = (control) => {
      switch(control?.filterType){
        case documentType: {
          return [
            ...(control?.filterType === 'Document' && control?.userId === userInfo?.id
              ? [
                  {
                    text: optionsTranslated.edit,
                    action: (data) => {
                      const searchContent = `?organization=${organizationId}${buildingId ? `&location=${buildingId}` : ''}`
                      navigate({
                        pathname: `/reports/edit/${data.id}`,
                        search: searchContent
                      },
                      { state: { buildingId: data?.buildingId } })
                    },
                  },
                  {
                    text: optionsTranslated.delete,
                    action: (data) => {
                      setDocReportRow(data)
                      setModalType(MODAL_TYPE.CONFIRMATION);
                    },
                  },
                ]
              : []),
              {
                text: optionsTranslated.download,
                action: async (data) => {   
                  if(organizationId && !buildingId){              
                    setIsDownload(true)
                    const buildingIds = data?.building?.id ? [data?.building?.id] : null
                    const organizationIds = organizationId ? [organizationId] : null
                    await RefreshAttachmentClaims(buildingIds,organizationIds).then(async ()=>{
                      await downloadDocumentAttachment({ id: data?.id })   
                      trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.DOWNLOAD_DOCUMENT)
                    })
                  }else{
                    const attachments = data?.attachments?.items ?? []
                    if (attachments?.length === 0)
                      return              
                    setIsDownload(true)
                    await handleBulkDownload(attachments)
                  }
                  
                }
              }
          ]
        }
        case consultationType: {
          const isEditAccessible = isActionControlAccessible("tc.pages.documents-and-reports.consultation.edit")
          const isCopyAccessible = isActionControlAccessible("tc.pages.documents-and-reports.consultation.copy")
          const isDeleteAccessible = isActionControlAccessible("tc.pages.documents-and-reports.consultation.delete")
          const consultationActionControls = [
            {
              text: optionsTranslated.edit,
              action: (data) => {
                setMode(ACTIONS.EDIT)
                navigate({
                  pathname: `/reports/Consultations/edit/${data?.id}`,
                  search
                })
              },
              isAccessible: isEditAccessible
            },
            {
              text: optionsTranslated.copy,
              action: (data) => {
                if (data.filterType === "Document") return
                setMode(ACTIONS.COPY)
                navigate({
                  pathname: `/reports/Consultations/copy/${data?.id}`,
                  search
                })
              },
              isAccessible: isCopyAccessible
            },
            {
              text: optionsTranslated.delete,
              action: (data) => {
                setDocReportRow(data)
                setReloadEvents(false)
                setMode(ACTIONS.DELETE)
                setModalType(MODAL_TYPE.CONFIRMATION)
              },
              isAccessible: isDeleteAccessible
            },
            {
              text: optionsTranslated.download,
              action: (data) => {
                setIsDownload(true)
                setConsultationDetails(null)
                refetchConsultationDetails({ id: data?.id })
                trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.DOWNLOAD_REPORT)
              },
              isAccessible: true
            }
          ]
          return consultationActionControls?.filter(f => f?.isAccessible)
        }
        case energyUsageReportType: {
          const isEditAccessible = isActionControlAccessible("tc.pages.documents-and-reports.energy-usage.edit")
          const isDeleteAccessible = isActionControlAccessible("tc.pages.documents-and-reports.energy-usage.delete")
          const energyUsageReportActionControls = [
            {
              text: optionsTranslated.edit,
              action: (data) => {
                setMode(ACTIONS.EDIT)
                navigate({
                  pathname: `/reports/energy-reports/edit/${data?.id}`,
                  search: `reportType=EnergyUsageReport`
                })
              },
              isAccessible: isEditAccessible
            },
            {
              text: optionsTranslated.delete,
              action: (data) => {
                setDocReportRow(data)
                setReloadEvents(false)
                setMode(ACTIONS.DELETE)
                setModalType(MODAL_TYPE.CONFIRMATION)
              },
              isAccessible: isDeleteAccessible
            }
          ]
          return energyUsageReportActionControls?.filter(f => f?.isAccessible)
        }
        default: return []
      }
    }

  const dateFilter = [
    { name: 'Ended in the last week', value: 'Ended in the last week' },
    {
      name: 'Ended in the last month',
      value: 'Ended in the last month'
    },
    {
      name: 'Ended in the last 3 months',
      value: 'Ended in the last 3 months'
    },
    {
      name: 'Ended in the last 6 months',
      value: 'Ended in the last 6 months'
    },
    { name: 'Ended in the last year', value: 'Ended in the last year' }
  ]

  const createSourceDisplayName = (table) => {
    const translateFinalSummary = table.reduce((acc, item) => {
      if (item.value && !acc.hasOwnProperty(item.value)) {
        acc[item.value] = item.value;
      }
      if (item.name && !acc.hasOwnProperty(item.name)) {
        acc[item.name] = item.name;
      }
      return acc;
    }, {});
    return translateFinalSummary
  }

  const [statusTranslate, setStatusTranslate] = useState([])
  const [dateTranslate, setDateTranslate] = useState([])
  const [reportTranslate, setReportTranslate] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (STATUS_OPTION_FILETER_LIST.length > 0){
      sourceTranslationHelper(STATUS_OPTION_FILETER_LIST).then(res => 
        setStatusTranslate(STATUS_OPTION_FILETER_LIST.map((item)=>{
          return{
            ...item,
            name: item.name,
            value: item.value,
            searchValue: res[item.name],
          }
        }))
      ).catch(error=>{
        setStatusTranslate(STATUS_OPTION_FILETER_LIST)
      })
    }
    if(dateFilter.length > 0)
    {
      sourceTranslationHelper(dateFilter).then(res => 
        setDateTranslate(dateFilter.map((item)=>{
          return{
            ...item,
            name: res[item.name],
            value: item.value
          }
        }))
      ).catch(error=>{
        setDateTranslate(dateFilter)
      })
    }  
    if(REPORT_FILTER_TYPE_LIST.length > 0)
    {
      const reportFilter = REPORT_FILTER_TYPE_LIST?.filter(f => !['Building Health Report', 'VRF Report'].includes(f?.name))
      sourceTranslationHelper(reportFilter).then(res => 
        setReportTranslate(reportFilter.map((item)=>{
          return{
            ...item,
            name: res[item.name],
            value: item.value
          }
        }))
      ).catch(error=>{
        setReportTranslate(reportFilter)
      })
    }  
  }, [dispatch, STATUS_OPTION_FILETER_LIST])

  const sourceTranslationHelper = async (dataTotranslate) => {
    let finalData;
    const tempList = createSourceDisplayName(dataTotranslate)
    const analaogList = await dispatch(translateJSON({ json: tempList }) as any).then(res => {
      finalData = res.payload
    })
    return finalData
  }

  const filtersList = useMemo(() => {
    return [
      {
        key: 'filterType',
        filterName: 'typeFilter',
        id: 'typeSelectorFilter',
        filterLabel: 'Type',
        defaultLabel: uiMode === UiMode.denali ? 'All Types' : 'Type',
        selectedValue: 'default',
        options: reportTranslate || []
      },
      {
        type: "dateRangeFilter",
        key: 'endDate',
        filterName: 'endDateFilter',
        id: 'endDateSelectorFilter',
        filterLabel: 'Time Period',
        defaultLabel: UiMode.denali ? 'All Dates' : 'Time Period',
        selectedValue: 'default',
        options: dateTranslate || []
      },
      {
        key: 'status',
        filterName: 'statusFilter',
        id: 'statusSelectorFilter',
        filterLabel: 'Status',
        defaultLabel: uiMode === UiMode.denali ? 'All Statuses' : 'Status',
        selectedValue: 'default',
        multiple:true,
        name: 'status',
        options: statusTranslate || []
      },
      {
        key: 'contact',
        filterName: 'contactFilter',
        id: 'contactSelectorFilter',
        filterLabel: 'Contact',
        defaultLabel: uiMode === UiMode.denali ? 'All Contacts' : 'Contact',
        selectedValue: 'default',
        name: 'contact',
        translateOptions: false,
        includeSearch: true,
        options: _uniqBy(contactsOptionFilter, 'name'),
      }
    ];
  }, [
    reportTranslate, 
    dateTranslate, 
    statusTranslate, 
    contactsOptionFilter, 
    uiMode
  ]);
 

  return (
    <>    
      <DisableDiv isDisable={isDownload}>
        {
          uiMode === UiMode.denali
            ? <ReportsList
                rows={rows}
                optionsTranslated={optionsTranslated}
                getListRowControl={getListRowControl}
                filtersList={filtersList}
                listDataIsLoading={listDataIsLoading}
                downloadFile={downloadFile}
                noTableDataAction={noTableDataAction}
                isFilterChange={true}
              />
            : <Table
                key={`DocumentsAndReportsTable-${organizationId} ${buildingId} ${contactsOptionFilter?.length}`}
                rows={rows}
                header={nameHeadings}
                loadTime={responseTime}
                search={true}
                searchFields={['title', 'building.name']}
                rowControl={getListRowControl}
                filtersList={filtersList}
                getDateRange={getDateRange}
                showSpinner={listDataIsLoading || isLoading}
                isFilterChange={true}
              />
        }
      </DisableDiv>
      {isDownload && <Spinner page={true}/>}
      {documentModalView &&
        <DocumentsViewModal
          downloadFileFunc={downloadFile}
        />
      }
      {consultationDetails && <ConsultationDetailDownload isAutoDownload={true} downloadData={consultationDetails} />}
    </>
  )
}

export default DocumentsAndReportsList
