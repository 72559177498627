import { Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, KeyboardEvent, MouseEvent } from 'react'

// Project imports
import { TranslateComponent } from 'src/common/translations'

// Denali imports
import { faCircleX } from 'src/denali-components/lib/pro-solid-svg-icons'

// Local imports
import { SelectionWrapperSelectedItemProps } from './types'
import styles from './selection-wrapper.module.scss'

/**
 * A companion component to the [SelectionWrapper] that will render the selected items as
 * "chips" that can be removed from the list of selected items.
 *
 * @param item The item to be rendered
 * @param omRemoveSelectedItem A callback function that when called will remove the passed item
 * @param keyExtractor A function used to determine "uniqueness" for an item
 * @param nameExtractor A callback that takes in a single selected item and returns a "name"
 *
 * @returns
 */
export const SelectionWrapperSelectedItem = <T extends NonNullable<unknown>>({
  item,
  onRemoveSelectedItem,
  keyExtractor,
  nameExtractor
}: SelectionWrapperSelectedItemProps<T>) => {
  // Memoized name for rendering.
  const name = useMemo(() => nameExtractor(item), [item])

  // Cached local click handler.
  const clickHandler = useCallback(
    (_: MouseEvent<SVGSVGElement> | KeyboardEvent<SVGSVGElement>) => {
      onRemoveSelectedItem(item)
    },
    [onRemoveSelectedItem, item]
  )

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={`${styles.selectionWrapperSelectedItem} ${keyExtractor(item)}`}
    >
      <p className={styles.selectionWrapperSelectedItemLabel}>
        <TranslateComponent>{name}</TranslateComponent>
      </p>
      <FontAwesomeIcon
        icon={faCircleX}
        size="sm"
        onClick={clickHandler}
        onKeyDown={clickHandler}
      />
    </Flex>
  )
}
