import { Divider, Radio, RadioGroupField } from '@aws-amplify/ui-react'
import { useFloatingTree } from '@floating-ui/react'
import { useState, useRef, useCallback } from 'react'

import { TranslateComponent } from 'src/common/translations'
import { InnerSearch } from '../InnerSearch'

import styles from '../table.module.scss'
import { FilterMenuSubmenuProps } from '../types'

export const FilterMenuSubmenu = ({
  filter,
  onChange
}: FilterMenuSubmenuProps) => {
  // Local state values for handling scroll fade and searching for options.
  const [value, setValue] = useState(filter.selectedValue)
  const [activeScroll, setActiveScroll] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const popup = useRef(null)
  const tree = useFloatingTree()

  // @TODO: Document what filter.searchOptions is and why we check it.
  const filterOptions =
    filter.searchOptions !== undefined ? filter.searchOptions : filter.options

  const buildDefaultRadio = useCallback((includeSearch: boolean) => {
    return (
      <>
        <Radio
          value="default"
          data-testid={`tableFilterRadioDefault-${filter?.filterLabel}`}
          className={filter.selectedValue === 'default' ? styles.selected : ''}
        >
          <TranslateComponent>{filter.defaultLabel}</TranslateComponent>
        </Radio>

        {!includeSearch && (
          <Divider
            borderColor="var(--amplify-colors-neutral-10)"
            style={{ marginTop: '10px' }}
          />
        )}
      </>
    )
  }, [])
  // Render a list of radios and for each one we'll call handleSort with the right
  // column and acending flag.
  return (
    <div
      key={filter.defaultLabel}
      className={`'${styles.filterSubmenu} ${
        filter.includeSearch ? styles.filterSubmenuScrollable : ''
      }`}
    >
      {activeScroll && <div className={styles.scrollOverlay}></div>}
      {filter.includeSearch && (
        <>
          <InnerSearch
            filter={filter}
            popup={popup}
            handleInnerScrollFade={handleInnerScrollFade}
            setActiveScroll={setActiveScroll}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <Divider
            borderColor="var(--amplify-colors-neutral-10)"
            style={{ marginBottom: '16px' }}
          />
        </>
      )}
      <div
        className={`${styles.filterOptionsWrapper} ${
          filter.includeSearch ? styles.filterSubmenuScrollable : ''
        }`}
        ref={popup}
        onScroll={() => {
          handleInnerScrollFade(popup, setActiveScroll)
        }}
      >
        <RadioGroupField
          label={filter.defaultLabel}
          labelHidden={true}
          name={filter.defaultLabel}
          value={value}
          onChange={(event) => {
            setValue(event.target.value)
            setSearchTerm('')
            onChange(event, filter, event.target.value)
            filter.searchOptions = undefined
            tree.events.emit('click')
          }}
          className={`options_${filter.key}`}
        >
          {!searchTerm && buildDefaultRadio(filter.includeSearch)}
          {filterOptions.map(
            (option: { value: string; name: string }) =>
              option.name && (
                <Radio
                  key={option.value}
                  value={option.value}
                  data-testid={`tableFilterRadio-${option.value}-${filter?.filterLabel}`}
                  className={value === option.value ? styles.selected : ''}
                >
                  {filter?.translateOptions === false ? (
                    option.name
                  ) : (
                    <TranslateComponent>{option.name}</TranslateComponent>
                  )}
                </Radio>
              )
          )}
        </RadioGroupField>
      </div>
    </div>
  )
}

function handleInnerScrollFade(popup, setActiveScroll) {
  const optionsWrapper = popup?.current
  const optionsList = optionsWrapper?.firstElementChild
  const optionsBottom = optionsList?.clientHeight - optionsWrapper?.scrollTop

  setActiveScroll(optionsBottom - 10 >= optionsWrapper?.clientHeight)
}

function resetSearch(filter) {
  filter.searchOptions = undefined
}
