import {
  Button,
  Fieldset,
  Flex,
  Input,
  Label,
  SelectField
} from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

// Denali Components
import formStyles from 'src/denali-components/Form/form.module.scss'
import { getImpactData } from 'src/denali-components/Form/helpers'
import { DenaliAssignedTo } from 'src/denali-components/Form/DenaliAssignedTo'
import { DenaliForm } from 'src/denali-components/Form/DenaliForm'
import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import { DenaliFormElementError } from 'src/denali-components/Form/DenaliFormElementErrors'
import DenaliImpactCard from 'src/denali-components/Form/DenaliImpactCard'
import { DenaliFileUpload } from 'src/denali-components/Form/DenaliFileUpload'
import { DenaliFormSubmit } from 'src/denali-components/Form/DenaliFormSubmit'
import { DenaliSupportingImages } from 'src/denali-components/Form/DenaliSupportingImages'
import { DenaliFormTextEditor } from 'src/denali-components/Form/DenaliFormTextEditor'
import { InlineBuildingSelector } from 'src/denali-components/Form/InlineBuildingSelector'
import { InlineBuildingSelectorContextProvider } from 'src/denali-components/Form/InlineBuildingSelectorContext'
import { InlineEquipmentSelector } from 'src/denali-components/Form/InlineEquipmentSelector'
import { InlineEquipmentSelectorContextProvider } from 'src/denali-components/Form/InlineEquipmentSelectorContext'
import { useInlineBuilding } from 'src/denali-components/Form/hooks/use-inline-building'
import { useInlineEquipment } from 'src/denali-components/Form/hooks/use-inline-equipment'
import { faRadar } from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  BackgroundColor,
  IconColor,
  Page
} from 'src/denali-components/Page/Page'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'

// Project imports
import { getSearchParams } from 'src/common/helperFunctions'
import translate, { TranslateComponent } from 'src/common/translations'
import { useAppDispatch, useAppSelector } from 'src/redux/store'
import { selectUiMode, selectUserInfo } from 'src/redux/slicers/appData'
import {
  selectIssuesAndFindingsData,
  setIssuesAndFindings,
  setIssuesAndFindingsTisObjects
} from 'src/redux/slicers/formData'

// Legacy imports
import { FINDING_ISSUE_FOUND_OPTION_LIST } from 'src/components/legacy/common/finding.js'
import { PRIORITY_LIST } from 'src/components/legacy/common/opportunity'
import {
  NEXT_STEPS_OPTION_LIST,
  STATUS_OPTION_LIST
} from 'src/components/legacy/common/finding'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'

// Local imports
import { SelectVisibility } from '../../denali-components/Form/SelectVisibility'
import { ManageIssuesAndFindingsFormProps } from './types'
import { useFormSchema } from './hooks/use-form-schema'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { PROPOSAL_TYPE_FILE } from 'src/components/file-upload/file-upload'

export const ManageIssuesAndFindingsForm = ({
  initialValues,
  onSubmit
}: ManageIssuesAndFindingsFormProps) => {
  const { buildingId: locationId } = getSearchParams()
  const values = useAppSelector(selectIssuesAndFindingsData)
  const {
    equipments,
    isEquipmentLoading,
    selectedTisObjects,
    setSelectedTisObjects,
    resetSearch: equipmentResetSearch,
    searchTerm: equipmentSearchTerm,
    setSearchTerm: setEquipmentSearchTerm,
    activeScroll: equipmentActiveScroll,
    setActiveScroll: setEquipmentActiveScroll,
    equipmentGroup
  } = useInlineEquipment({
    locationId: values?.location?.locationId ?? locationId
  })
  const {
    buildings,
    iconColors,
    searchBuildings,
    setSearchTerm,
    buildingsGroup,
    activeScroll,
    setActiveScroll,
    resetSearch
  } = useInlineBuilding(
    values?.organization?.organizationId,
    values?.location?.locationId
  )
  const uiMode = useSelector(selectUiMode)
  const userInfo = useSelector(selectUserInfo)
  const { schema } = useFormSchema({ values })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // the browswer will localize the display of date values
  // Diverging from the universal YYYY-MM-DD problematic.
  const sanitizedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      settings: {
        ...initialValues.settings,
        proposalDate: moment(initialValues.settings.proposalDate).format(
          BACKEND_DATE_FORMAT
        ),
        targetDate: initialValues.settings.targetDate
          ? moment(initialValues.settings.targetDate).format(
              BACKEND_DATE_FORMAT
            )
          : ''
      }
    }
  }, [initialValues])

  const handleCancel = () => {
    dispatch(setIssuesAndFindings(sanitizedInitialValues))
    navigate(`/${PAGE_NAMES.FINDINGS}`)
  }

  // translate() in the JSX may result in render errors
  const translatedTitle = translate('Create Issues/Findings')
  const translatedPlaceholder = translate(
    'Enter the title for the consultation'
  )
  const translatedStartTyping = translate('Start typing here...')

  const clearTisObjects = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== values.location.locationId) {
        dispatch(setIssuesAndFindingsTisObjects([]))
      }
    },
    [values]
  )

  useEffect(() => {
    dispatch(setIssuesAndFindings(sanitizedInitialValues))
    // objects need to actually be different
  }, [JSON.stringify(sanitizedInitialValues)])

  if (!values) {
    // Must wait until sanitizedInitialValues has been dispatched.
    return <div>loading...</div>
  }
  return (
    <DenaliForm
      id="denali-form-issues-new"
      className={formStyles.denaliForm}
      schema={schema}
      defaultValues={sanitizedInitialValues}
      onSubmit={(values, reset) => onSubmit(values, { resetForm: reset })}
      values={values}
      updateReduxStore={true}
      updateReduxStoreAction={setIssuesAndFindings}
    >
      {/* TEMP */}
      {/* <div style={{ height: '400px', overflow: 'scroll', padding: '16px' }}>
        <pre>{JSON.stringify(values, null, 2)}</pre>
      </div> */}
      {/* TEMP */}

      <InlineEquipmentSelectorContextProvider
        equipments={equipments}
        isEquipmentLoading={isEquipmentLoading}
        selectedTisObjects={selectedTisObjects}
        setSelectedTisObjects={setSelectedTisObjects}
        resetSearch={equipmentResetSearch}
        searchTerm={equipmentSearchTerm}
        setSearchTerm={setEquipmentSearchTerm}
        activeScroll={equipmentActiveScroll}
        setActiveScroll={setEquipmentActiveScroll}
        equipmentGroup={equipmentGroup}
      >
        <InlineBuildingSelectorContextProvider
          buildings={buildings}
          iconColors={iconColors}
          searchBuildings={searchBuildings}
          setSearchTerm={setSearchTerm}
          buildingsGroup={buildingsGroup}
          activeScroll={activeScroll}
          setActiveScroll={setActiveScroll}
          resetSearch={resetSearch}
        >
          <Page
            title={translatedTitle}
            icon={<FontAwesomeIcon icon={faRadar} />}
            iconColor={IconColor.blue}
            testid="issues/findings page"
            backgroundColor={BackgroundColor.invert}
            actions={[
              <Button
                key="cancel"
                onClick={handleCancel}
                className={formStyles.cancelButton}
              >
                <TranslateComponent>Cancel</TranslateComponent>
              </Button>,
              <DenaliFormSubmit key="submit">
                <TranslateComponent>Save</TranslateComponent>
              </DenaliFormSubmit>
            ]}
          >
            <hr />
            <Flex className={formStyles.titleRow}>
              {/* Left side col */}
              <Flex
                direction={'column'}
                overflow={'hidden'}
                flex={'1 1 0%'}
                className={formStyles.titleColumn}
              >
                <Label htmlFor={'name'} className={formStyles.lrgLabel}>
                  <TranslateComponent>Title</TranslateComponent>*
                  <DenaliFormElement name="name">
                    <Input
                      id={'name'}
                      value={values?.name}
                      placeholder={translatedPlaceholder}
                      className={formStyles.titleInput}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="name" />
                </Label>
                {/* Select a building* */}
                {/* Search */}
                <Fieldset legendHidden legend variation="outlined">
                  <Fieldset
                    legend={'Inline Building Selector'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                    className={formStyles.selectorContainer}
                  >
                    <DenaliFormElement name="location.locationId">
                      <InlineBuildingSelector
                        value={values?.location?.locationId}
                        onAfterChange={clearTisObjects}
                      />
                    </DenaliFormElement>
                    <DenaliFormElementError name="location.locationId" />
                  </Fieldset>

                  <hr className={formStyles.inlineSubSectionSpacer} />

                  {/* Select an equipment */}
                  <Fieldset
                    legend={'Inline Equipment Selector'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                    className={formStyles.selectorContainer}
                  >
                    <DenaliFormElement
                      name={'tisObjects'}
                      customOnChange={true}
                    >
                      <InlineEquipmentSelector value={values?.tisObjects} />
                    </DenaliFormElement>
                  </Fieldset>
                </Fieldset>
              </Flex>
              {/* END Left side col */}
              {/* Right Side col */}
              <Flex
                direction={'column'}
                flex={'0 0 340px'}
                className={formStyles.titleColumn}
              >
                <div className={formStyles.emptyLabelSpacer}></div>

                <SelectVisibility
                  visibility={values?.visibleToCustomer}
                  name={'visibleToCustomer'}
                />

                {/* CARD section */}
                <Fieldset
                  legend={'Created'}
                  legendHidden
                  variation={'outlined'}
                  direction={'column'}
                  className={formStyles.createdByContainer}
                >
                  <Label className={formStyles.smlLabel}>
                    <TranslateComponent>Created by</TranslateComponent>
                    <Fieldset
                      legend="User"
                      legendHidden
                      className={formStyles.userBubble}
                      marginTop={'6px'}
                    >
                      <UserNameBubble
                        firstName={userInfo?.firstName}
                        lastName={userInfo?.lastName}
                        subLine={userInfo?.type.name}
                        showFullName
                      />
                    </Fieldset>
                    <DenaliFormElement
                      name="createdBy"
                      className={formStyles.smlLabel}
                    >
                      <Input
                        type="hidden"
                        data-testid="createdByField"
                        placeholder={translatedStartTyping}
                        autoComplete=""
                        value={`${userInfo?.firstName} ${userInfo?.lastName}`}
                      />
                    </DenaliFormElement>
                  </Label>
                  <Label htmlFor="creationDate" className={formStyles.smlLabel}>
                    <TranslateComponent>Created Date</TranslateComponent>
                    <DenaliFormElement name="settings.proposalDate">
                      <Input
                        id={'creationDate'}
                        type="date"
                        data-testid="creationDateField"
                        value={values?.settings?.proposalDate}
                      />
                    </DenaliFormElement>
                    <DenaliFormElementError name="settings.proposalDate" />
                  </Label>
                  <Label
                    htmlFor={'foundDuring'}
                    className={formStyles.smlLabel}
                  >
                    <TranslateComponent>Found During</TranslateComponent>
                    <DenaliFormElement name={'foundDuring'}>
                      <SelectField
                        id={'foundDuring'}
                        label={'found during'}
                        labelHidden
                        value={values?.foundDuring}
                      >
                        {FINDING_ISSUE_FOUND_OPTION_LIST.map(
                          ({ key, value }) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        )}
                      </SelectField>
                    </DenaliFormElement>
                  </Label>
                </Fieldset>
              </Flex>
              {/* END Right side Col */}
            </Flex>

            <hr />
            {/* new row */}
            {/* Cards */}
            <Flex direction={'column'} className={formStyles.sectionRoot}>
              <Flex className={formStyles.impactContainer}>
                {values.settings.kpi?.map(
                  (
                    { name, value, priority, custom, savings, timePeriod },
                    index
                  ) => {
                    const { icon, color, label } = getImpactData(
                      custom ? 'custom' : name
                    )

                    return (
                      <DenaliImpactCard
                        key={`kpi-option-${index}`}
                        index={index}
                        name={name}
                        value={value}
                        priority={priority}
                        showSpecificValue={true}
                        savings={savings}
                        timePeriod={timePeriod}
                        custom={custom}
                        cardLabel={label}
                      >
                        <div
                          data-color={color}
                          className={formStyles.impactIcon}
                        >
                          <FontAwesomeIcon icon={icon} size={'lg'} />
                        </div>
                      </DenaliImpactCard>
                    )
                  }
                )}
              </Flex>
              <Flex className={formStyles.settingsRow}>
                {/* Description */}
                <Fieldset
                  className={formStyles.descriptionContainer}
                  legendHidden
                  legend={'Description section'}
                  direction={'column'}
                  variation={'outlined'}
                >
                  <Fieldset
                    legend={'Description'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                  >
                    <Label
                      htmlFor={'settingsDescription'}
                      className={formStyles.smlLabel}
                    >
                      <TranslateComponent>Description</TranslateComponent>:
                    </Label>
                    <DenaliFormElement
                      id={'settingsDescription'}
                      name={'settings.description'}
                      customOnChange={true}
                    >
                      <DenaliFormTextEditor
                        uiMode={uiMode}
                        value={values?.settings?.description}
                      />
                    </DenaliFormElement>
                  </Fieldset>

                  <Fieldset
                    legend={'Chart Link'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                  >
                    <Label
                      htmlFor={'chartLink'}
                      className={formStyles.smlLabel}
                    >
                      <span>
                        <TranslateComponent>Chart Link</TranslateComponent>:
                      </span>
                      <DenaliFormElement name={'settings.chartLink'}>
                        <Input
                          id={'chartLink'}
                          value={values.settings.chartLink}
                          placeholder={translatedStartTyping}
                        />
                      </DenaliFormElement>
                      <p className={formStyles.subLine}>
                        <TranslateComponent>
                          Copy the site address from the address bar of your
                          browser.
                        </TranslateComponent>
                      </p>
                    </Label>
                  </Fieldset>
                  <Fieldset
                    legend={'Description Link'}
                    legendHidden
                    variation={'plain'}
                    border={'none'}
                    direction={'column'}
                  >
                    <Label
                      htmlFor="descriptionLink"
                      className={formStyles.smlLabel}
                    >
                      <span>
                        <TranslateComponent>
                          Link Description
                        </TranslateComponent>
                        :
                      </span>
                      <DenaliFormElement name={'settings.descriptionLink'}>
                        <Input
                          id={'descriptionLink'}
                          value={values.settings.descriptionLink}
                          placeholder={translatedStartTyping}
                        />
                      </DenaliFormElement>
                    </Label>
                  </Fieldset>
                </Fieldset>
                {/* Images */}
                <Fieldset
                  legend={'Images'}
                  legendHidden
                  variation={'outlined'}
                  direction={'column'}
                  className={formStyles.imageContainer}
                >
                  <Label htmlFor={'supportingImages'}>
                    <TranslateComponent>Supporting Images</TranslateComponent>
                  </Label>
                  <DenaliFormElement
                    id="supportingImages"
                    name={'settings.images'}
                    customOnChange={true}
                  >
                    <DenaliSupportingImages
                      value={values?.settings?.images}
                      organizationId={values?.organization?.organizationId}
                      locationId={values?.location?.locationId}
                      uiMode={uiMode}
                    />
                  </DenaliFormElement>
                </Fieldset>
              </Flex>

              <Fieldset
                legend={'Attachment Section'}
                legendHidden
                className={formStyles.attatchmentSectionRow}
                variation="outlined"
              >
                <Fieldset
                  className={formStyles.attachmentContainer}
                  legend="Attach Files"
                  legendHidden
                  direction="column"
                >
                  <Label htmlFor="attachFile" className={formStyles.smlLabel}>
                    <TranslateComponent>Attach file</TranslateComponent>
                  </Label>
                  <DenaliFormElement
                    id={'attachFile'}
                    name={'settings.file'}
                    customOnChange={true}
                  >
                    <DenaliFileUpload
                      location={values?.location?.locationId}
                      value={values?.settings?.file}
                      allowedFileTypes={PROPOSAL_TYPE_FILE}
                      allowMultipleFiles={false}
                      includeDescription={true}
                      showOnlyAcceptableFileDesc={true}
                      showFileBadgeDate={false}
                      showFileBadgePreviewButton={false}
                      showFileBadgeDeleteButton={true}
                      fileBadgeDownloadStyle={''}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="settings.file" />
                </Fieldset>

                <div className={formStyles.verticalDivider}>
                  <span />
                </div>

                <Flex className={formStyles.miscContainer}>
                  <Flex>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Priority Level'}
                    >
                      <Label
                        htmlFor="priorityLevel"
                        className={formStyles.smlLabel}
                      >
                        <TranslateComponent>Priority Level</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'priority'}>
                        <SelectField
                          id={'priorityLevel'}
                          labelHidden
                          label={'Priority Level'}
                          value={values?.priority}
                        >
                          {PRIORITY_LIST.map((priority: string) => (
                            <option key={priority} value={priority}>
                              <TranslateComponent>
                                {priority}
                              </TranslateComponent>
                            </option>
                          ))}
                        </SelectField>
                      </DenaliFormElement>
                    </Fieldset>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Status'}
                    >
                      <Label htmlFor="status" className={formStyles.smlLabel}>
                        <TranslateComponent>Status</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'status'}>
                        <SelectField
                          id={'status'}
                          labelHidden
                          label={'Status'}
                          value={values?.status}
                        >
                          {STATUS_OPTION_LIST.map(({ key, value }) => (
                            <option key={key} value={key}>
                              <TranslateComponent>{value}</TranslateComponent>
                            </option>
                          ))}
                        </SelectField>
                      </DenaliFormElement>
                    </Fieldset>
                  </Flex>
                  <Flex>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Next Step'}
                    >
                      <Label htmlFor="nextStep" className={formStyles.smlLabel}>
                        <TranslateComponent>Next step</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'nextStep'}>
                        <SelectField
                          id={'nextStep'}
                          labelHidden
                          label={'Next step'}
                          value={values?.nextStep}
                        >
                          {NEXT_STEPS_OPTION_LIST.map(({ key, value }) => (
                            <option key={key} value={key}>
                              <TranslateComponent>{value}</TranslateComponent>
                            </option>
                          ))}
                        </SelectField>
                      </DenaliFormElement>
                    </Fieldset>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Target Date'}
                    >
                      <Label
                        htmlFor="targetDate"
                        className={formStyles.smlLabel}
                      >
                        <TranslateComponent>Target Date</TranslateComponent>
                      </Label>
                      <DenaliFormElement name={'settings.targetDate'}>
                        <Input
                          id={'targetDate'}
                          type={'date'}
                          value={values?.settings?.targetDate}
                        />
                      </DenaliFormElement>
                    </Fieldset>
                  </Flex>
                  <Flex>
                    <Fieldset
                      variation={'plain'}
                      border={'none'}
                      legendHidden
                      legend={'Assigned to'}
                      width={'100%'}
                    >
                      <Label
                        htmlFor={'assignedTo'}
                        className={formStyles.smlLabel}
                      >
                        <TranslateComponent>Assigned to</TranslateComponent>
                      </Label>
                      <DenaliFormElement
                        name={'assignedTo'}
                        customOnChange={true}
                      >
                        <DenaliAssignedTo
                          locationId={values?.location?.locationId}
                          value={values?.assignedTo}
                        />
                      </DenaliFormElement>
                    </Fieldset>
                  </Flex>
                </Flex>
              </Fieldset>
            </Flex>
            <hr />
            <Flex className={formStyles.footerActionsWrapper}>
              <Button
                key="cancel"
                onClick={handleCancel}
                className={formStyles.cancelButton}
              >
                <TranslateComponent>Cancel</TranslateComponent>
              </Button>
              <DenaliFormSubmit key="submit">
                <TranslateComponent>Save</TranslateComponent>
              </DenaliFormSubmit>
            </Flex>
          </Page>
        </InlineBuildingSelectorContextProvider>
      </InlineEquipmentSelectorContextProvider>
    </DenaliForm>
  )
}
