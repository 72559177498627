import { ButtonGroup, Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

// Project imports
import translate from 'src/common/translations'

// Local imports
import styles from './table.module.scss'
import { FilterMenu } from './FilterMenu/FilterMenu'
import { SortMenu } from './SortMenu/SortMenu'
import { DenaliTableStyle } from './table.d'

export const TableHeader = ({
  search,
  filters,
  onSearchChange,
  onFilterChange,
  header,
  selectedColumn,
  isAscending,
  handleSort,
  denaliTableStyle = DenaliTableStyle.cards,
  bulkActions
}) => {
  const searchTranslate = translate('Search')

  const sortHeaders = header
    .map((headerOption, index) => ({ ...headerOption, originalIndex: index }))
    .filter(
      (headerOption) =>
        !headerOption.hideFromSort && headerOption.key !== 'actions'
    )

  return (
    <div className={styles.header}>
      <div className={styles.optionsContainer}>
        {denaliTableStyle === DenaliTableStyle.cards && (
          <div className={styles.sort}>
            <ButtonGroup size="small">
              <SortMenu
                header={sortHeaders}
                handleSort={handleSort}
                selectedColumn={selectedColumn}
                isAscending={isAscending}
              />
            </ButtonGroup>
          </div>
        )}

        {filters && filters.length && (
          <div className={styles.filters}>
            <FilterMenu filters={filters} onFilterChange={onFilterChange} />
          </div>
        )}
      </div>

      {search && (
        <div
          className={`${styles.search} ${
            bulkActions ? styles.searchWithBulkActions : ''
          }`}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <Input
            id="search"
            placeholder={searchTranslate}
            aria-description="Search"
            type="search"
            onChange={onSearchChange}
          />
        </div>
      )}
      {bulkActions && <div className={styles.bulkActions}>{bulkActions}</div>}
    </div>
  )
}
