import { Button, Flex } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'

// Denali imports
import { FlyoutMenu } from 'src/denali-components/FlyoutMenu/FlyoutMenu'
import {
  FlyoutMenuContextState,
  FlyoutMenuListItem
} from 'src/denali-components/FlyoutMenu/types'
import {
  faChevronRight,
  faCircleSmall,
  faFilter
} from 'src/denali-components/lib/pro-solid-svg-icons'

// Project imports
import { TranslateComponent } from 'src/common/translations'

// Local imports
import styles from '../table.module.scss'
import { FilterMenuProps } from '../types'
import { FilterMenuSubmenu } from './FilterMenuSubmenu'
import { NumberBadge } from 'src/denali-components/NumberBadge/NumberBadge'

export const FilterMenu = ({ filters, onFilterChange }: FilterMenuProps) => {
  const buildIcon = useCallback((sortActive: boolean) => {
    return (
      <Flex
        direction="row"
        justifyContent="flex-end"
        className={styles.menuIconsRow}
      >
        {sortActive && (
          <FontAwesomeIcon
            icon={faCircleSmall}
            color="var(--amplify-colors-blue-50)"
          />
        )}
        <FontAwesomeIcon icon={faChevronRight} />
      </Flex>
    )
  }, [])

  // The "button" built for each child menu that will also show the indicator is the matching
  // filter is applied.
  const buildChildButton = useCallback(
    (label: string, ..._: any[]) => {
      // If this item is selected as the sort, we want to mark it with a little red dot
      // before the chevron.
      const filterActive =
        filters.filter(
          (f) => f.selectedValue !== 'default' && f.filterLabel == label
        ).length > 0
      return (
        <Flex
          direction="row"
          justifyContent="space-between"
          className={styles.menuItem}
        >
          <span className={styles.menuItemLabel}>{label}</span>
          {buildIcon(filterActive)}
        </Flex>
      )
    },
    [filters]
  )

  // The primary "toggle" button used to open/close the Flyout menu's main content.
  const buildButton = useCallback(
    (
      label: string,
      parent: FlyoutMenuContextState,
      item: FlyoutMenuListItem,
      isNested: boolean
    ) => {
      // Let's find all filters with values and add them up.
      const totalAppliedFilters = filters.filter(
        (f) => f.selectedValue !== 'default' && f.selectedValue !== null
      ).length
      return (
        <Button
          className={styles.menuButton}
          size="small"
          data-testid={`tableFilterButton`}
          data-nested={isNested ? '' : undefined}
          tabIndex={
            !isNested ? undefined : parent.activeIndex === item.index ? 0 : -1
          }
          role={isNested ? 'menuitem' : undefined}
        >
          <TranslateComponent>{label}</TranslateComponent>
          <NumberBadge number={totalAppliedFilters}>
            <FontAwesomeIcon icon={faFilter} />
          </NumberBadge>
        </Button>
      )
    },
    [filters]
  )

  return (
    <FlyoutMenu label={'Filters'} triggerBuilder={buildButton}>
      <Flex direction="column" className={styles.filterMenu}>
        {filters.map((filter) => {
          const label = filter.filterLabel
          return (
            <FlyoutMenu
              key={label}
              label={label}
              triggerBuilder={buildChildButton}
            >
              <FilterMenuSubmenu
                key={label}
                filter={filter}
                onChange={onFilterChange}
              />
            </FlyoutMenu>
          )
        })}
      </Flex>
    </FlyoutMenu>
  )
}
