import { MenuItem } from './MainNavigation'
import { NavigationItemSingle } from './NavigationItemSingle'
import { NavigationItemWithChildren } from './NavigationItemWithChildren'

export const NavigationItem = ({
  item,
  i,
  menuIsOpen = true,
  isRootLevel,
  close = () => {}
}: NavigationItemProps) => {
  // Top-level link default to using a popup with 1+ link(s) unless explicitly disabled
  // (because of icons and sub-page name clarity)
  const isRootLevelWithPopup = isRootLevel && !item?.neverUsePopup
  // Sub-level links default to no popup unless it has multiple child links
  const isSubLevelWithPopup = item?.links?.length > 1

  return isRootLevelWithPopup || isSubLevelWithPopup ? (
    <NavigationItemWithChildren item={item} key={i} menuIsOpen={menuIsOpen} />
  ) : (
    <NavigationItemSingle
      item={item}
      key={i}
      menuIsOpen={menuIsOpen}
      close={close}
    />
  )
}

type NavigationItemProps = {
  item: MenuItem
  i: number
  menuIsOpen?: boolean
  isRootLevel?: boolean
  close?: () => void
}
