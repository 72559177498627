import {
  Button,
  Fieldset,
  Flex,
  Input,
  Label,
  SelectField,
  TextAreaField
} from '@aws-amplify/ui-react'
import _isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

// Project imports
import translate, { TranslateComponent } from 'src/common/translations'
import {
  documentTypeList,
  documentStatusList
} from 'src/pages/documentsAndReports/helper'
import { useAppSelector } from 'src/redux/store'
import {
  defaultDocumentValues,
  selectDocumentsAndReportsData,
  setDocumentsAndReports
} from 'src/redux/slicers/formData'
import { faPrintMagnifyingGlass } from 'src/denali-components/lib/pro-solid-svg-icons'
import { getSearchParams } from 'src/common/helperFunctions'

// Denali imports.
import { DenaliForm } from 'src/denali-components/Form/DenaliForm'
import { DenaliFormElement } from 'src/denali-components/Form/DenaliFormElement'
import { InlineBuildingSelector } from 'src/denali-components/Form/InlineBuildingSelector'
import formStyles from 'src/denali-components/Form/form.module.scss'
import { DenaliFormElementError } from 'src/denali-components/Form/DenaliFormElementErrors'
import { UserNameBubble } from 'src/denali-components/UserNameBubble/UserNameBubble'

// Local imports
import { AddDocumentAttachments } from './AddDocumentAttachments'
import { AddDocumentFormProps } from './types'
import { SelectVisibility } from '../../denali-components/Form/SelectVisibility'
import { useDispatch } from 'react-redux'
import {
  BackgroundColor,
  IconColor,
  Page
} from 'src/denali-components/Page/Page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BuildingLocation } from 'src/models/location'
import { InlineBuildingSelectorContextProvider } from 'src/denali-components/Form/InlineBuildingSelectorContext'
import { useInlineBuilding } from 'src/denali-components/Form/hooks/use-inline-building'

export const AddDocumentForm = ({
  attachments,
  warnBeforeReplace,
  errors,
  addFileFunc,
  downloadFileFunc,
  showConfirm,
  uiMode,
  userInfo,
  onSubmit,
  isSubmitting,
  goBack,
  mode
}: AddDocumentFormProps) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const values = useAppSelector(selectDocumentsAndReportsData)

  const {
    buildings,
    iconColors,
    searchBuildings,
    setSearchTerm,
    buildingsGroup,
    activeScroll,
    setActiveScroll,
    resetSearch
  } = useInlineBuilding()

  const translations = {
    selectFromList: translate('Select from the list'),
    documentNameError: translate('Document Name is required'),
    documentTypeError: translate('Document Type is required'),
    documentStatusError: translate('Document Status is required'),
    buildingError: translate('Building is required'),
    createdDateError: translate('Created Date is required'),
    attachmentsError: translate('Must provide at least 1 attachment')
  }
  const schema = useMemo(() => getSchema(translations), [])

  const reset = useCallback(() => {
    const { buildingId } = getSearchParams()
    dispatch(
      setDocumentsAndReports({
        ...defaultDocumentValues,
        location: new BuildingLocation({
          locationId: buildingId
        })
      })
    )
  }, [])

  useEffect(() => {
    // on mount refresh default values
    reset()

    // on unmount, for editing existing entries, clean up Redux values
    return () => {
      if (mode === 'Edit') reset()
    }
  }, [])

  return (
    <DenaliForm
      id="denali-form-document-new"
      className={formStyles.denaliForm}
      defaultValues={defaultDocumentValues}
      values={values}
      schema={schema}
      data-testid="documentNew"
      updateReduxStore={true}
      updateReduxStoreAction={setDocumentsAndReports}
      onSubmit={(values, _) => onSubmit(values, reset)}
    >
      <InlineBuildingSelectorContextProvider
        buildings={buildings}
        iconColors={iconColors}
        searchBuildings={searchBuildings}
        setSearchTerm={setSearchTerm}
        buildingsGroup={buildingsGroup}
        activeScroll={activeScroll}
        setActiveScroll={setActiveScroll}
        resetSearch={resetSearch}
      >
        <Page
          title={mode === 'Edit' ? 'Edit Document' : 'Add Document'}
          icon={<FontAwesomeIcon icon={faPrintMagnifyingGlass} />}
          iconColor={IconColor.purple}
          backgroundColor={BackgroundColor.invert}
          testid="reports page"
          actions={[
            <Button
              key="cancel"
              className="button-gray"
              data-testid="cancelButton"
              ariaLabel="Cancel"
              disabled={isSubmitting}
              onClick={() => {
                goBack()
              }}
            >
              <TranslateComponent>Cancel</TranslateComponent>
            </Button>,
            <div className="animated-wrapper" key="save">
              <Button
                className="button"
                data-testid="saveButton"
                disabled={isSubmitting}
                ariaLabel="Save"
                type="submit"
              >
                {isSubmitting ? (
                  <div className="spinner" />
                ) : (
                  <TranslateComponent>Save</TranslateComponent>
                )}
              </Button>
            </div>
          ]}
        >
          <hr />
          <Flex>
            <Flex direction="column" width="66%" gap="0">
              <Label className={formStyles.lrgLabel} htmlFor="documentName">
                <TranslateComponent>Document Name</TranslateComponent>*
              </Label>
              <DenaliFormElement name="documentName">
                <Input
                  type="text"
                  name="documentName"
                  data-testid="documentNameField"
                  placeholder={translate(
                    'Enter the title for the consultation'
                  )}
                  value={values?.documentName}
                  className={formStyles.lrgInput}
                  disabled={isSubmitting}
                />
              </DenaliFormElement>
              <DenaliFormElementError name="documentName" />
              {buildings && (
                <Fieldset
                  legend="Inline Building Selector"
                  legendHidden
                  variation="outlined"
                  direction="column"
                >
                  <DenaliFormElement name="location.locationId">
                    <InlineBuildingSelector
                      value={values?.location?.locationId}
                      disabled={isSubmitting}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="location.locationId" />
                </Fieldset>
              )}
              <Fieldset
                legend="Document Info"
                legendHidden
                variation="outlined"
                direction="row"
                gap="16px"
              >
                <Flex direction="column" alignItems="stretch" flex="1">
                  <DenaliFormElement name="documentType">
                    <SelectField
                      id="documentTypeField"
                      data-testid="documentType"
                      label={
                        <>
                          <TranslateComponent>Document Type</TranslateComponent>
                          *
                        </>
                      }
                      options={Object.keys(documentTypeList(t))}
                      placeholder={translations.selectFromList}
                      value={values?.documentType}
                      className={formStyles.smlLabel}
                      disabled={isSubmitting}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="documentType" />
                </Flex>
                <Flex direction="column" alignItems="stretch" flex="1">
                  <DenaliFormElement name="documentStatus">
                    <SelectField
                      id="documentStatusField"
                      data-testid="documentStatus"
                      label={
                        <>
                          <TranslateComponent>
                            Document Status
                          </TranslateComponent>
                          *
                        </>
                      }
                      placeholder={translations.selectFromList}
                      options={Object.keys(documentStatusList(t))}
                      value={values?.documentStatus}
                      className={formStyles.smlLabel}
                      disabled={isSubmitting}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="documentStatus" />
                </Flex>
              </Fieldset>
            </Flex>
            <Flex direction="column" width="33%" gap="0">
              <span className={formStyles.emptyLabelSpacer}></span>
              <SelectVisibility
                visibility={values?.visibility}
                name={'visibility'}
                disabled={isSubmitting}
              />
              <Fieldset
                legend="Created"
                legendHidden
                variation="outlined"
                direction="column"
                gap="20px"
              >
                <Label htmlFor="createdBy" className={formStyles.smlLabel}>
                  <TranslateComponent>Created By*</TranslateComponent>
                  <Fieldset
                    legend="User"
                    legendHidden
                    className={formStyles.userBubble}
                  >
                    <UserNameBubble
                      firstName={userInfo?.firstName}
                      lastName={userInfo?.lastName}
                      subLine={userInfo?.type.name}
                      showFullName
                    />
                  </Fieldset>
                  <DenaliFormElement name="createdBy">
                    <Input
                      type="hidden"
                      name="createdBy"
                      data-testid="createdByField"
                      placeholder={translate('Start typing here...')}
                      autoComplete=""
                      value={`${userInfo?.firstName} ${userInfo?.lastName}`}
                      disabled={isSubmitting}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="createdBy" />
                </Label>
                <Label htmlFor="startDate" className={formStyles.smlLabel}>
                  {
                    // Created Date is actually start date in the old theme & components.
                    // creationDate, createdDate, and createdAt are referenced but are read-only in the updateDocument API mutation.
                    // Field is labeled Created Date but in the listing, detail, and edit pages, it's showing start date.
                    // In src/pages/documentsAndReports/AddDocuments.tsx at onChangeDate the values are cloned.
                  }
                  <TranslateComponent>Created Date*</TranslateComponent>
                  <DenaliFormElement name="startDate">
                    <Input
                      type="date"
                      name="startDate"
                      data-testid="createdDateField"
                      value={values?.startDate}
                      disabled={isSubmitting}
                    />
                  </DenaliFormElement>
                  <DenaliFormElementError name="startDate" />
                </Label>
              </Fieldset>
            </Flex>
          </Flex>
          <hr />
          <Flex>
            <Fieldset
              legend="Attach Files"
              legendHidden
              variation="outlined"
              direction="column"
              className={
                errors.duplicate
                  ? formStyles.filesError
                  : formStyles.attachedFiles
              }
              width="50%"
            >
              <DenaliFormElement name="attachments">
                <AddDocumentAttachments
                  name="attachments"
                  attachments={attachments}
                  warnBeforeReplace={warnBeforeReplace}
                  showConfirm={showConfirm}
                  addFileFunc={addFileFunc}
                  downloadFileFunc={downloadFileFunc}
                  uiMode={uiMode}
                />
              </DenaliFormElement>
              <DenaliFormElementError name="attachments" />
            </Fieldset>
            <Fieldset
              legend="Supporting Notes"
              legendHidden
              variation="outlined"
              direction="column"
              width="50%"
            >
              <Label htmlFor="supporting-notes" className={formStyles.lrgLabel}>
                <TranslateComponent>Supporting Notes</TranslateComponent>
              </Label>
              <DenaliFormElement name="note">
                <TextAreaField
                  id={'supporting-notes'}
                  label={'Supporting notes'}
                  labelHidden
                  value={values?.note ?? ''}
                  rows={10}
                  placeholder={translate('Start typing here...')}
                  disabled={isSubmitting}
                />
              </DenaliFormElement>
              <DenaliFormElementError name="note" />
            </Fieldset>
          </Flex>
          <hr />

          <Flex justifyContent={'flex-end'}>
            <Button
              key="cancel"
              className="button-gray"
              data-testid="cancelButton"
              ariaLabel="Cancel"
              disabled={isSubmitting}
              onClick={() => {
                goBack()
              }}
            >
              <TranslateComponent>Cancel</TranslateComponent>
            </Button>
            <div className="animated-wrapper" key="save">
              <Button
                className="button"
                data-testid="saveButton"
                disabled={isSubmitting}
                ariaLabel="Save"
                type="submit"
              >
                {isSubmitting ? (
                  <div className="spinner" />
                ) : (
                  <TranslateComponent>Save</TranslateComponent>
                )}
              </Button>
            </div>
          </Flex>
        </Page>
      </InlineBuildingSelectorContextProvider>
    </DenaliForm>
  )
}

const getSchema = (translations) => {
  return yup.object().shape({
    documentName: yup.string().required(translations.documentNameError),
    documentType: yup.string().required(translations.documentTypeError),
    documentStatus: yup.string().required(translations.documentStatusError),
    visibility: yup.boolean(),
    location: yup.object({
      locationId: yup.string().required(translations.buildingError)
    }),
    createdDate: yup.string().required(translations.createdDateError),
    attachments: yup
      .array()
      .min(1, translations.attachmentsError)
      .required(translations.attachmentsError),
    note: yup.string(),
    description: yup.string()
  })
}
