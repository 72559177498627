import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import ImageGallery from 'react-image-gallery'
import Icon from '../icon-svg/icon'
import COLORS from '../../common/colors.json'
import { NoImage, StyledSpinner } from '../image-preview/image-preview.sc'
import { useAttachment } from 'src/hooks/attachment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import './image-gallery-wrapper.scss'
import { MapInteractionCSS } from 'react-map-interaction';
import { Button } from '@trane/trane-components'

export const ItemDescription = ({ item }) =>
  (item.description || item.originalTitle) && (
    <span className="image-gallery-description">
      <strong>{item.originalTitle}</strong>&nbsp;
      {item.description}
    </span>
  )

export const ImageGalleryItem = forwardRef((props, ref) => {
  const {
    item,
    renderDescription,
    isLoadingImage,
    isImageZooming
  } = props
  const { t } = useTranslation()
  const [imageError, setImageError] = useState(false)
  const [value, setValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 }
  })
  useImperativeHandle(ref, () => ({
    handleResetPosition() {
      setValue({
        scale: 1,
        translation: { x: 0, y: 0 }
      })
    }
  }));
  const handleError = () => {
    setImageError(true)
  }
  return (
    <>

      <div className="image-gallery-flex" data-testid="image-gallery">
        <>
          {isLoadingImage ? (
            <div className='loader'>
              <Spinner />
            </div>
          ) : (
            <>
              {!imageError && item.original ? (
                <div className='image-interaction-wrapper'>
                  <div className="image-gallery-image-wrapper" >
                    <MapInteractionCSS value={value}
                      onChange={(value) => setValue(value)}>
                      <img
                        className="image-gallery-image"
                        src={item.original}
                        alt={item.originalAlt}
                        srcSet={item.srcSet}
                        sizes={item.sizes}
                        title={item.originalTitle}
                        onError={handleError}
                      />
                    </MapInteractionCSS>
                  </div>
                </div>
              ) : (
                <NoImage className="no-image">
                  <span>{t('components:imagePreview>NoImage')}</span>
                </NoImage>
              )}
            </>
          )}
        </>
      </div>
    </>
  )
})

const renderNav = (direction) => (onClick, disabled) =>
(
  <button
    className={`image-gallery-icon image-gallery-${direction}-nav`}
    disabled={disabled}
    onClick={onClick}
  >
    <Icon
      className="image-gallery-svg"
      cursor="pointer"
      name={`${direction}-caret`}
      color={COLORS.TBA_DARKER_GREY}
      hover={COLORS.TBA_DARKER_GREY}
      width="8px"
      height="16px"
    />
  </button>
)


const ImageGalleryWrapper = (props) => {
  const [galleryItems, setGalleryItems] = useState([])
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [startIndex, setStartIndex] = useState(0)
  const { downloadFileURL } = useAttachment()
  const galleryItemRef = useRef(null)
  const allGalleryItemRefs = useRef()
  useEffect(() => {
    setGalleryItems(props?.items)
  }, [props?.items])

  const fetchImage = async (item) => {
    if (item && item?.name && item?.buildingId) {
      await downloadFileURL(item?.name, item?.buildingId).then((res) => {
        item.original = res
      }).catch((err) => {
        setIsLoadingImage(false)
      })
    }
    return item
  }

  const handleZoomIn = () => {
    // alert('Zoom In')
    galleryItemRef.current.handleZoomIn()
  }
  const handleZoomOut = () => {
    // alert('Zoom Out')
    galleryItemRef.current.handleZoomOut()
  }

  const handleFullScreenClick = () => {
    Object.keys(allGalleryItemRefs)?.map((key) => {
      if (key !== "current")
        allGalleryItemRefs[key]?.handleResetPosition()
    })
  };


  const { className, isLazyLoading = false, isImageZooming = false } = props
  return (
    <>
      <div className={`image-gallery-wrapper ${className || ''}`} data-testid="image-gallery-wrapper">
        <ImageGallery
          renderItem={(item) => (
            <ImageGalleryItem key={item?.name} ref={ref => allGalleryItemRefs[item?.name] = ref} isLoadingImage={isLoadingImage} item={item} isImageZooming={isImageZooming} />
          )}
          renderLeftNav={renderNav('left')}
          renderRightNav={renderNav('right')}
          {...props}
          items={galleryItems}
          startIndex={startIndex}
          onScreenChange={handleFullScreenClick}
          onSlide={async (i) => {
            if (galleryItems?.length === 0)
              return
            const slideItem = galleryItems[i]
            allGalleryItemRefs[slideItem?.name]?.handleResetPosition()
            if (!slideItem?.original && isLazyLoading) {
              setStartIndex(i)
              setIsLoadingImage(true)
              const imgItem = await fetchImage(slideItem)
              setIsLoadingImage(false)
              setGalleryItems(
                galleryItems?.map((i) =>
                  i?.name === imgItem?.name
                    ? { ...i, original: imgItem?.original }
                    : i
                )
              )
              if (props?.handleDownloadImage) {
                props?.handleDownloadImage(imgItem)
              }
            }
          }}
        />
      </div>
    </>
  )
}

export default ImageGalleryWrapper
