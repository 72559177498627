import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { ACTIONS } from 'src/constants'
import { FindingEdit } from '../finding-edit-page'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_BUILDING_DETAILS, GET_FINDING_BY_ID } from '../graphql'
import { FileUploadType, updateFinding } from '../helpers'
import Findings from 'src/models/findings'
import FindingView from '../finding-view/FindingView'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectUserAccess, selectUserInfo, selectUiMode } from 'src/redux/slicers/appData'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { useAttachment } from 'src/hooks/attachment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import _cloneDeep from 'lodash/cloneDeep'
import _sortBy from 'lodash/sortBy'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'
import ErrorAlert from 'src/components/ErrorAlert'
import { accessControlFunc } from 'src/components/accessControl'
import translate from 'src/common/translations'

const FindingViewEdit = () => {
  const { state } = useLocation()
  const [mode, setMode] = useState(ACTIONS.VIEW)
  const { id } = useParams()
  const { buildingId: locationId, organizationId } = getSearchParams()
  const userInfo = useSelector(selectUserInfo)
  const currentDate = moment().format(DATE_FORMAT)
  const getUserName = () => {
    return `${userInfo?.lastName} ${userInfo?.firstName}`
  }
  const translateCopyOf=translate("Copy of")
  const { downloadFileURL } = useAttachment()
  const userName = getUserName()
  const [imageDetail, setImageDetail] = useState(null)
  const [finding, setFinding] = useState(
    new Findings({ organizationId, locationId })
  )
  const userAccess = useSelector(selectUserAccess)
	const uiMode = useSelector(selectUiMode)

  const [error, setError] = useState({ isError: false, errorMsg: '' })

  const {
    data: fetchedFinding,
    refetch: refetchFinding,
    loading
  } = useQuery({
    query: GET_FINDING_BY_ID,
    variables: { id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getIssue',
    onSuccess: (res) => {
      if (mode === ACTIONS.VIEW) {
        refetchBuildingDetails({ buildingId: res?.buildingId })
      }
    }
  })
  const { data: buildingDetails, refetch: refetchBuildingDetails } = useQuery({
    query: GET_BUILDING_DETAILS,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getBuilding'
  })

  useEffect(() => {
    if (state) {
      setMode(state.mode)
    }
  }, [state])

  useEffect(() => {
    if (id) {
      setFinding(new Findings({ organizationId, locationId }))
      refetchFinding({ id })?.then((finding)=>{
        if (!finding) {
          setError({
            errorMsg: 'The Issues/Findings you are looking for does not exist.',
            isError: true
          })
        } else {
          if (
            !finding?.isVisible &&
            !accessControlFunc({
              id: 'tc.pages.findings.view-private',
              userAccess
            })
          ) {
            setError({
              errorMsg: 'The Issues/Findings you are looking for is not accessible.',
              isError: true
            })
          }
          setError({
            errorMsg: '',
            isError: false
          })
        }
      })
    }
  }, [id, mode])

  const fetchingFindingWithImages = async (finding) => {
    const findingImages = []
    for (const cur of finding?.attachments?.items) {
      if (cur?.category === FileUploadType.ADD_IMAGES) {
        if (mode === ACTIONS.VIEW) {
          // Fetching Images In View Mode
          if (cur?.attachmentOrder === 0) {
            setImageDetail(cur)
            const img = await fetchImage(cur)
            findingImages.push(img)
          } else {
            const img = {
              original: null,
              imageId: cur?.id,
              originalImageId: cur?.id,
              originalTitle: cur?.title,
              description: cur?.description,
              name: cur?.name,
              buildingId: cur?.buildingId,
              title: cur?.title,
              caption: cur?.description,
              imageUrl: null,
              displayName: cur?.displayName,
              fileName: cur?.name,
              attachmentOrder: cur?.attachmentOrder
            }
            findingImages.push(img)
          }
        } else {
          // Fetching Images In Edit Mode
          setImageDetail(cur)
          const img = await fetchImage(cur)
          findingImages.push(img)
        }
      }
    }

    const convertToFindingObject = updateFinding(
      fetchedFinding,
      mode,
      userName,
      currentDate,
      translateCopyOf
    )
    const settings = _cloneDeep(convertToFindingObject.settings)
    settings.images = _sortBy(findingImages, 'attachmentOrder') ?? []
    convertToFindingObject.settings = _cloneDeep(settings)
    convertToFindingObject.organization.organizationName =
      buildingDetails?.accountName
    convertToFindingObject.location.locationName = buildingDetails?.name
    setFinding(convertToFindingObject)
  }

  const fetchImage = useCallback(
    async (image) => {
      let newFileName = image?.name
      if (mode !== ACTIONS.EDIT)
        newFileName = updateFileNameWithEpoch(image?.displayName)
      const img = {
        original: null,
        imageId: image?.id,
        originalImageId: image?.id,
        originalTitle: image?.title,
        description: image?.description,
        name: image?.name,
        buildingId: image?.buildingId,
        title: image?.title,
        caption: image?.description,
        imageUrl: null,
        displayName: image?.displayName,
        fileName: newFileName,
        attachmentOrder: image?.attachmentOrder
      }
      if (image?.name && image?.buildingId) {
        await downloadFileURL(image?.name, image?.buildingId).then((res) => {
          img.imageUrl = res
          img.original = res
        })
      }
      return img
    },
    [imageDetail]
  )

  useEffect(() => {
    if (fetchedFinding) {
      (async () => {
        await fetchingFindingWithImages(fetchedFinding)
      })()
    }
  }, [fetchedFinding, buildingDetails,translateCopyOf])

  return (
    <div>
      {mode === ACTIONS.VIEW && finding?.name ? (
        <FindingView finding={finding} uiMode={uiMode} />
      ) : mode === ACTIONS.ADD ? (
        <FindingEdit mode={mode} />
      ) : (
        <>
          {(mode === ACTIONS.EDIT || mode === ACTIONS.COPY) && finding?.name ? (
            <FindingEdit mode={mode} finding={finding} />
          ) : (
            <>
              {error?.isError ? (
                <ErrorAlert errorMsg={error?.errorMsg} />
              ) : (
                <Spinner />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default FindingViewEdit
