import { useState, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Grid, Heading, View } from '@aws-amplify/ui-react'
import translate, { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from 'src/denali-components/lib/pro-solid-svg-icons'
import { BackgroundColor, Page } from 'src/denali-components/Page/Page'
import { selectUiMode, selectUserInfo } from 'src/redux/slicers/appData'
import {
  CardHorizontal,
  CardHorizontalColor
} from 'src/denali-components/Cards/CardHorizontal/CardHorizontal'
import { CardVideo } from 'src/denali-components/Cards/CardVideo/CardVideo'
import { Chip, ChipColor } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import styles from './video-onboarding.module.scss'
import { Navigate, useNavigate } from 'react-router'
import { UiMode } from 'src/redux/types/AppTypes'
import { videoOnboardingData } from './VideoOnboardingData'
import { DialogVideoPlayer } from '../../denali-components/Dialog/DialogVideoPlayer'

export const VideoOnboarding = ({}) => {
  const user = useSelector(selectUserInfo)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const openDialog = searchParams.get('openDialog') === 'true'
  const cardIndex = parseInt(searchParams.get('cardIndex'), 10) || 0
  const navigate = useNavigate()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(null) // New state to store selected video data

  const uiMode = useSelector(selectUiMode)
  if (uiMode !== UiMode.denali) {
    return <Navigate to={`/`} />
  }

  const handleCardClick = (index) => {
    const videoData = videoOnboardingData[index]
    setSelectedVideo(videoData)
    setIsDialogOpen(true)
    navigate(`?openDialog=true&cardIndex=${index}`)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
    navigate(`?openDialog=false`)
  }

  handleOpenDialogEffect(
    openDialog,
    cardIndex,
    setIsDialogOpen,
    setSelectedVideo
  )

  return (
    <Page
      title={null}
      icon={null}
      backgroundColor={BackgroundColor.alt}
      actions={[]}
      showHeading={false}
    >
      <Grid
        templateColumns={{ base: '1fr', medium: '1fr 1fr 1fr' }}
        templateRows={{
          small: ' 18.8125rem auto auto',
          xxl: '18.8125rem 19.4375rem 19.4375rem'
        }}
        columnGap="1.5rem"
        rowGap="1.5rem"
        className={`${styles.video} ${styles.customRowGap}`}
      >
        <View columnSpan={3}>
          <CardHorizontal
            color={CardHorizontalColor.lightBlue}
            icon={<FontAwesomeIcon icon={faVideo} />}
            testId="videoOnboardingHeader"
          >
            <Heading level={1} className="h1">
              <TranslateComponent>
                Welcome to Video Onboarding
              </TranslateComponent>
            </Heading>
            <p>
              <TranslateComponent>
                Welcome to Trane Connect's video onboarding section! Here,
                you'll find quick guides and tips to help you navigate the app
                and maximize your HVAC system’s performance. Get started by
                clicking a video below.
              </TranslateComponent>
            </p>
          </CardHorizontal>
        </View>
        {videoOnboardingData.map((video, index) => (
          <View key={index}>
            <CardVideo
              onClick={() => handleCardClick(index)}
              testId={video.testId}
              backgroundImage={video.backgroundImage}
              backgroundSize={video.backgroundSize}
              icon={<FontAwesomeIcon icon={video.icon} />}
              tags={
                <Chips>
                  {video.tags.map((tag, tagIndex) => (
                    <Chip key={tagIndex} color={ChipColor[tag.color]}>
                      <TranslateComponent>{tag.label}</TranslateComponent>
                    </Chip>
                  ))}
                </Chips>
              }
            >
              <Heading level={2} className={'title-sm'}>
                <TranslateComponent>{video.heading}</TranslateComponent>
              </Heading>
              <p>
                <TranslateComponent>{video.episode}</TranslateComponent>
              </p>
            </CardVideo>
          </View>
        ))}

        {isDialogOpen && selectedVideo && (
          <DialogVideoPlayer
            isOpen={isDialogOpen}
            onClose={handleDialogClose}
            setIsOpen={setIsDialogOpen}
            videoData={selectedVideo}
            currentIndex={cardIndex}
          />
        )}
      </Grid>
    </Page>
  )
}

const handleOpenDialogEffect = (
  openDialog,
  cardIndex,
  setIsDialogOpen,
  setSelectedVideo
) => {
  useEffect(() => {
    if (openDialog && !isNaN(cardIndex)) {
      setIsDialogOpen(true)
      setSelectedVideo(videoOnboardingData[cardIndex])
    }
  }, [openDialog, cardIndex])
}
