import { useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { formatData, aggrDataFormatter,formatMonthlyEnergyData,formatDataWithIndex } from 'src/common/chartHelperFunctions'
import moment from 'moment'
import store from 'src/redux/store';
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

const propertiesList = [
  'ConsumptionCost',
  'DemandCost',
  'AdditionalCost',
  'GasConsumptionCost'
]

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

export const chartDateTimeRequest = {
  minutes: '15m',
  day: '1d',
  month: '1m'
}

// Hooks returns daily or aggr data based on Interval
// Other bar related formattting has to be done in component level
export const useEnergyCostDataHook = () => {
  const { refetch: getEnergyCostDataQuery, loading: loadingChartData } =
    useQuery({
      query: GET_CHART_DATA,
      dataPath: 'data.getChartData',
      disableInitialLoad: true
    })

  const [energyCostData, setEnergyCostData] = useState(null)

  const formatEnergyCostData = (
    chartRequestTimeInterval,
    data,
    equipmentId,
    startDate,
    endDate
  ) => {
    if (chartRequestTimeInterval === chartDateTimeRequest.month) {
      const dataWithProperIndex = formatDataWithIndex(
        data?.consumptionData
      )
      try {
        const energyData = formatMonthlyEnergyData(dataWithProperIndex)
        setEnergyCostData(energyData)
      } catch (error) {
        setEnergyCostData(null)
      }
    } else if (chartRequestTimeInterval === chartDateTimeRequest.minutes) {
      const equipmentData = data?.data
      const energyCost15MinsData = formatData(
        equipmentData || [],
        startDate,
        endDate,
        moment.tz.guess(),
        chartRequestTimeInterval
      )
      format15MinsAndDailyData(energyCost15MinsData)
    } else if (chartRequestTimeInterval === chartDateTimeRequest.day) {
      // day data will be aggr one - sum aggregation
      const equipmentData = data?.agg_data
      const energyCostDailyData = aggrDataFormatter(
        equipmentData || [],
        startDate,
        endDate,
        moment.tz.guess(),
        chartRequestTimeInterval
      )
      format15MinsAndDailyData(energyCostDailyData?.sum)
    }
  }

  const getEnergyCostDataHandler = async ({
    startDate,
    endDate,
    interval,
    buildingId,
    equipmentId,
    equipmentType,
    uomDetails = null
  }) => {
    try {
      if (equipmentId) {
        const needsMonthlyData = interval === chartDateTimeRequest.month
        let selectedLanguage = store?.getState().appData?.selectedLanguage;
        selectedLanguage = selectedLanguage ? selectedLanguage : "en";
        const chartDataRequestFormat = {
          startDate: selectedLanguage === 'en' ? startDate : moment(startDate).locale('en').format(BACKEND_DATE_FORMAT),
          endDate: selectedLanguage === 'en' ? endDate : moment(endDate).locale('en').format(BACKEND_DATE_FORMAT),
          category: 'equipment', // equipment or building level data needed
          chartType: 'line',
          building: {
            id: buildingId,
            pId: [],
            type: ''
          },
          equipment: !needsMonthlyData
            ? [
                {
                  eId: [{ id: equipmentId }],
                  type: equipmentType,
                  pId: getPropertyIdsArray(interval)
                }
              ]
            : [],
          ...(needsMonthlyData
            ? {
                consumption: {
                  eId: equipmentId,
                  pId: propertiesList
                }
              }
            : {}),
          equipmentType: equipmentType,
          weather: {},
          settings: [],
          buildingDateTime: '2024-01-08 23:26:43'
        }
        const uom = uomDetails
        if (uom && !needsMonthlyData)
          chartDataRequestFormat.equipment[0]["uom"] = { "ConsumptionCost": uom, "DemandCost": uom, "AdditionalCost": uom, "GasConsumptionCost": uom }
        if (uom && needsMonthlyData)
          chartDataRequestFormat.consumption["uom"] = { "ConsumptionCost": uom, "DemandCost": uom, "AdditionalCost": uom, "GasConsumptionCost": uom }
        const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
        const chartData = await getEnergyCostDataQuery(requestBody)
        const parsedChartData = JSON.parse(chartData)
        formatEnergyCostData(
          interval,
          parsedChartData?.body,
          equipmentId,
          startDate,
          endDate
        )
      }
    } catch (error) {
      setEnergyCostData(null)
    }
  }

  const format15MinsAndDailyData = (allData) => {
    try {
      // 15-mins and 1-day data, will not be having demand and additional cost
      const consumptionCost = allData?.find(
        (x) => x.propertyKey === 'ConsumptionCost'
      )
      const consumptionCostValues = consumptionCost?.values?.[0] || []

      const isDataAvailable = consumptionCostValues?.some(
        (x) => x?.value !== null
      )

      setEnergyCostData({
        consumptionCost,
        isDataAvailable
      })
    } catch (error) {
      setEnergyCostData(null)
    }
  }

  const getPropertyIdsArray = (interval) => {
    if (interval === chartDateTimeRequest.minutes) {
      return propertiesList
    } else {
      return [
        {
          sum: propertiesList
        }
      ]
    }
  }

  return {
    loadingEnergyCostData: loadingChartData,
    getEnergyCostData: getEnergyCostDataHandler,
    energyCostData
  }
}
