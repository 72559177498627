import styles from './building-selector.module.scss'
import { Chips } from '../Table/Chips'
import { Chip, ChipColor } from '../Table/Chip'
import { TranslateComponent } from 'src/common/translations'
import { OrganizationIcon } from './OrganizationIcon'
import { useDispatch } from 'react-redux'
import { setSelectedBuilding } from 'src/redux/slicers/buildingPicker'
import {
  SEARCH_BUILDINGS,
  type OrganizationId
} from 'src/components/buildingNavigation/graphql'
import { API } from 'aws-amplify'
import { type GraphQLOptions } from '@aws-amplify/api-graphql/lib-esm/types/index.d'
import {
  accountMapping,
  AccountMappingOrganization
} from 'src/components/buildingNavigation/helper'
import { BuildingsPopupItem } from './BuildingsPopupItem'
import { useMemo, useState } from 'react'
import { useDebounce } from '../helpers'

export const OrganizationsPopupItem = ({
  organization,
  selectedOrganization = null,
  showBuildingCount = true,
  setOrganizationPopupOpen = () => {},
  selectedBuilding = null,
  searchText = ''
}) => {
  const dispatch = useDispatch()
  const onClick = getOnClickHandler(
    organization,
    setOrganizationPopupOpen,
    dispatch
  )
  const highlightedOrgName = useHighlightText(organization.primary, searchText)

  return (
    <li
      className={`
        ${selectedOrganization?.id === organization.id ? styles.active : ''}
        ${searchText?.length >= 2 ? styles.orgWithChildren : ''}
      `}
    >
      <button
        className={styles.organizationButton}
        onClick={onClick}
        data-testid={`buildingSelectorOrgItem-${organization.id}`}
      >
        <div className={styles.organizationIcon}>
          <OrganizationIcon name={organization.primary} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>
            <div className={styles.nameInner}>{highlightedOrgName}</div>
          </div>

          {showBuildingCount && organization.buildings && (
            <div className={styles.count}>
              <Chips>
                <OrganizationBuildingChip organization={organization} />
              </Chips>
            </div>
          )}
        </div>
      </button>
      {searchText?.length >= 2 && organization?.buildings?.length > 0 && (
        <ul>
          {organization.buildings.map((building, index) => {
            return (
              <BuildingsPopupItem
                building={building}
                selectedBuilding={selectedBuilding}
                key={building.id}
                isMenuChild={true}
                isLastChild={index === organization.buildings.length - 1}
                searchText={searchText}
                parentOrganization={organization}
                setBuildingsPopupOpen={setOrganizationPopupOpen}
              />
            )
          })}
        </ul>
      )}
    </li>
  )
}

export const useHighlightText = (textBeingSearched, searchText) => {
  const [highlightedText, setHighlightedText] = useState([textBeingSearched])
  const getTextDebounced = useDebounce(() => {
    setHighlightedText(highlightSearchTerm(textBeingSearched, searchText))
  }, 1000)

  useMemo(() => {
    getTextDebounced()
  }, [textBeingSearched, searchText])
  return highlightedText
}

const highlightSearchTerm = (textBeingSearched, searchText) => {
  if (!searchText) return [textBeingSearched]
  if (searchText?.length <= 3) return [textBeingSearched]

  const truncatedSearchText = searchText.slice(0, 100)
  const escapedSearchText = truncatedSearchText.replace(
    /[.*+?^${}()|[\]\\]/g,
    '\\$&'
  )

  const regex = new RegExp(escapedSearchText, 'gi')
  const result = []
  let lastIndex = 0
  let match

  while ((match = regex.exec(textBeingSearched)) !== null) {
    result.push(
      wrapText(
        textBeingSearched?.slice(lastIndex, match.index),
        `${match.index}-between`,
        false
      )
    )
    result.push(
      wrapText(
        textBeingSearched?.slice(match.index, regex.lastIndex),
        `${match.index}-highlight`,
        true
      )
    )
    lastIndex = regex.lastIndex
  }
  result.push(wrapText(textBeingSearched?.slice(lastIndex), `final`, false))

  return result
}

const wrapText = (
  text: string,
  key: number | string,
  isHighlighted: boolean
) => {
  return (
    <span
      key={key}
      className={isHighlighted ? styles.highlight : styles.notHighlighted}
    >
      {text}
    </span>
  )
}

const getOnClickHandler = (
  organization,
  setOrganizationPopupOpen,
  dispatch
) => {
  return () => {
    dispatch(
      setSelectedBuilding({
        ...organization,
        skipTokensWhileLoading: true
      })
    )
    if (setOrganizationPopupOpen) setOrganizationPopupOpen(false)

    getFullOrg(organization.id).then((organization) => {
      // Load the full org because buildings may not be fully counted from the API limit
      // of 1000 without a specific ID to narrow it down in the full popup list.
      if (organization?.buildings?.length === 1) {
        dispatch(
          setSelectedBuilding({ ...organization, skipTokensWhileLoading: true })
        )
        setTimeout(() => {
          dispatch(setSelectedBuilding(organization?.buildings[0]))
        }, 0) // Timeout 0 in order to transfer to the next JS event loop, allowing the org with building list to propagate in Redux

        return
      }
      dispatch(setSelectedBuilding(organization))
    })
  }
}

const OrganizationBuildingChip = ({ organization }) => {
  if (!organization?.buildings?.length) return
  const isSingular = organization.buildings.length === 1
  const text = `${organization.buildings.length} ${
    isSingular ? 'building' : 'buildings'
  }`

  return (
    <Chip color={ChipColor.darkGreen} size="small">
      <TranslateComponent>{text}</TranslateComponent>
    </Chip>
  )
}

const getFullOrg = async (
  organizationId: OrganizationId
): Promise<AccountMappingOrganization> => {
  try {
    const options: GraphQLOptions = {
      query: SEARCH_BUILDINGS,
      variables: {
        filter: {
          or: [{ accountId: { eq: organizationId } }]
        },
        limit: 1000
      }
    }
    const apiData: any = await API.graphql(options)
    const accountsData = accountMapping(
      apiData?.data?.searchBuildings?.items,
      true
    )
    return Object.values(accountsData)?.[0] as AccountMappingOrganization
  } catch (error) {
    console.error(error)
  }
}
