import { faPlay } from '@fortawesome/free-solid-svg-icons'

export const videoOnboardingData = [
  {
    testId: 'videoOnboardingCardOne',
    backgroundImage: require('src/assets/images/video-onboarding/sidebar-video-1.jpg'),
    backgroundSize: 'cover',
    icon: faPlay,
    tags: [{ color: 'transparentGray', label: '1 min' }],
    heading: 'Building Selector',
    title: 'Introducing the new Building Selector',
    episode: 'Episode 1',
    description:
      'This video provides a quick overview of how to navigate through the platform, highlighting the Building Selector feature. With the Building Selector, users can view all their organizations, buildings, and devices in one convenient place.',
    id: '1018459880',
    urlParams:
      'h=e12facf902&amp;badge=0&amp;autopause=0&amp;controls=0&amp;player_id=0&amp;app_id=58479'
  },
  {
    testId: 'videoOnboardingCardTwo',
    backgroundImage: require('src/assets/images/video-onboarding/new-design-video-1.jpg'),
    backgroundSize: 'cover',
    icon: faPlay,
    tags: [{ color: 'transparentGray', label: '1 min' }],
    heading: 'How to Switch to Old Design',
    title: 'Switching to the Legacy Interface',
    episode: 'Episode 2',
    description:
      'In this video, learn how to switch back to the Legacy Trane Connect interface temporarily. Simply toggle the new design switch located at the bottom left corner of your screen to access the familiar interface.',
    id: '1018462598',
    urlParams: 'h=8b3b95c908&badge=0&autopause=0&player_id=0&app_id=58479'
  }
  // {
  //   testId: "videoOnboardingCardThree",
  //   backgroundImage: require('src/assets/images/video-onboarding/reports-video-1.jpg'),
  //   backgroundSize: "cover",
  //   icon: faPlay,
  //   tags: [{ color: "transparentGray", label: "10 min" }],
  //   heading: "Documents & Reports",
  //   title: "Documents & Reports",
  //   episode: "Episode 3",
  //   description: "The new Trane Connect menu provides quick access to remote monitoring, real-time alerts, and energy management tools. Optimize your system for cost savings and comfort with an intuitive interface designed for your needs.",
  //   id: '1007803081',
  //   urlParams: "h=197ff7b13a"
  // },
  // {
  //   testId: "videoOnboardingCardFour",
  //   backgroundImage: require('src/assets/images/video-onboarding/new-design-video-2.jpg'),
  //   backgroundSize: "cover",
  //   icon: faPlay,
  //   tags: [{ color: "transparentGray", label: "6 min" }],
  //   heading: "How to switch to old design",
  //   title: "How to switch to old design",
  //   episode: "Episode 4",
  //   description: "The new Trane Connect menu provides quick access to remote monitoring, real-time alerts, and energy management tools. Optimize your system for cost savings and comfort with an intuitive interface designed for your needs.",
  //   id: '1007803081',
  //   urlParams: "h=197ff7b13a"
  // },
  // {
  //   testId: "videoOnboardingCardFive",
  //   backgroundImage: require('src/assets/images/video-onboarding/reports-video-2.jpg'),
  //   backgroundSize: "cover",
  //   icon: faPlay,
  //   tags: [{ color: "transparentGray", label: "6 min" }],
  //   heading: "Documents & Reports",
  //   title: "Documents & Reports",
  //   episode: "Episode 5",
  //   description: "The new Trane Connect menu provides quick access to remote monitoring, real-time alerts, and energy management tools. Optimize your system for cost savings and comfort with an intuitive interface designed for your needs.",
  //   id: '1007803081',
  //   urlParams: "h=197ff7b13a"
  // },
  // {
  //   testId: "videoOnboardingCardSix",
  //   backgroundImage: require('src/assets/images/video-onboarding/sidebar-video-1.jpg'),
  //   backgroundSize: "cover",
  //   icon: faPlay,
  //   tags: [{ color: "transparentGray", label: "6 min" }],
  //   heading: "New UI for the sidebar",
  //   title: "New UI for the sidebar",
  //   episode: "Episode 6",
  //   description: "The new Trane Connect menu provides quick access to remote monitoring, real-time alerts, and energy management tools. Optimize your system for cost savings and comfort with an intuitive interface designed for your needs.",
  //   id: '1007803081',
  //   urlParams: "h=197ff7b13a"
  // },
]
