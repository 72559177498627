import { Loader, Theme, ThemeProvider } from '@aws-amplify/ui-react'
import { DenaliLoaderProps } from '../Form/types'

const loaderTheme = ({ size = 'large' }: DenaliLoaderProps): Theme => {
  return {
    name: 'denali-loader-theme',
    tokens: {
      components: {
        loader: {
          strokeFilled: { value: '{colors.neutral.80}' },
          large: {
            width: {
              value:
                size === 'large' ? '{fontSizes.xxxxl}' : '{fontSizes.large}'
            },
            height: {
              value:
                size === 'large' ? '{fontSizes.xxxxl}' : '{fontSizes.large}'
            }
          }
        }
      }
    }
  }
}

export const DenaliLoader = ({ size = 'large' }: DenaliLoaderProps) => {
  return (
    <ThemeProvider theme={loaderTheme({ size })}>
      <Loader size="large" />
    </ThemeProvider>
  )
}
