import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// Project imports.
import { getSearchParams } from 'src/common/helperFunctions.js'
import { useQuery } from 'src/hooks/APIHooks'

// Denali imports.
import {
  getColor as getRandomColor,
  randomizeString
} from 'src/denali-components/helpers'

export const useInlineBuilding = (
  existingOrganizationId?: string,
  existingBuildingId?: string
) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [activeScroll, setActiveScroll] = useState(true)
  const buildingsGroup = useRef(null)
  const searchBuildings = useRef(null)

  const { organizationId: paramOrganizationId, buildingId: paramBuildingId } =
    getSearchParams()

  const organizationId = existingOrganizationId ?? paramOrganizationId
  const buildingId = existingBuildingId ?? paramBuildingId

  // Calculate icon colors for each building and memoize it.
  const { data: buildings, refetch } = useQuery({
    query: `query GET_BUILDINGS_BY_ACCOUNT_ID($id: ID!, $filter: ModelBuildingFilterInput) {
        listBuildingsByAccount(accountId: $id, filter: $filter, limit: 1000) {
          items {
            key: id
            value: name
            accountName
          }
        }
      }`,
    variables: { id: organizationId },
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })

  const filteredBuildings =
    searchTerm.length > 0
      ? buildings?.filter((building) =>
          building?.value?.toLowerCase().includes(searchTerm)
        )
      : buildings ?? []

  useMemo(() => {
    filteredBuildings?.sort((building) => {
      if (building.key === buildingId) return -1
      return 0
    })
  }, [filteredBuildings, buildingId])

  const iconColors = useMemo(() => {
    return filteredBuildings?.map((building) =>
      getRandomColor(randomizeString(building.value))
    )
  }, [filteredBuildings])

  const resetSearch = useCallback(
    (e) => {
      e.preventDefault()
      searchBuildings.current.value = ''
      setSearchTerm('')
    },
    [searchBuildings, setSearchTerm]
  )

  useEffect(() => {
    if (organizationId !== null) {
      refetch()
    }
  }, [organizationId])

  return {
    buildings: filteredBuildings,
    iconColors,
    searchBuildings,
    setSearchTerm,
    buildingsGroup,
    activeScroll,
    setActiveScroll,
    resetSearch
  }
}
