import { Flex } from '@aws-amplify/ui-react'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import styles from 'src/denali-components/Table/table.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileCsv,
  faFileDoc,
  faFileXls,
  faFileLines,
  faFileGif,
  faFileJpg,
  faFileSvg,
  faFilePdf,
  faFilePng,
  faFilePpt,
  faFileXml,
  faFileZip,
  faFileMp3,
  faFileMp4,
  faFileMov,
  faFileEps,
  faFileCad
} from 'src/denali-components/lib/pro-solid-svg-icons'

export const InlineAttachments = ({ attachmentList, downloadFileFunc }) => {
  try {
    const attachments = attachmentList?.filter((file) => !file?.fileError)
    return (
      <Flex className={styles.inlineAttachments}>
        {attachments?.slice(0, 3).map((item) => (
          <Tooltip key={item.id}>
            <TooltipTrigger>
              <div className={styles.inlineAttachmentIcon}>
                <FontAwesomeIcon
                  icon={getIcon(item)}
                  onClick={(e) => {
                    e.stopPropagation()
                    downloadFileFunc(item.name)
                  }}
                />
              </div>
            </TooltipTrigger>
            <TooltipContent>{item.name}</TooltipContent>
          </Tooltip>
        ))}
        {attachments?.length > 3 && (
          <Tooltip>
            <TooltipTrigger>
              <div
                className={[
                  styles.inlineAttachmentIcon,
                  styles.inlineAttachmentIconMore
                ].join(' ')}
              >
                {attachments.length - 3}+
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {attachments.length - 3} more file
              {attachments.length - 3 > 1 ? 's' : ' '}
            </TooltipContent>
          </Tooltip>
        )}
      </Flex>
    )
  } catch (error) {}
}

const getIcon = (item) => {
  const type = item.type === 'file' ? item.name.split('.').pop() : item.type

  switch (type) {
    case 'application/pdf':
    case 'pdf':
      return faFilePdf
    case 'text/csv':
    case 'csv':
      return faFileCsv
    case 'image/jpeg':
    case 'jpeg':
    case 'jpg':
      return faFileJpg
    case 'image/png':
    case 'png':
      return faFilePng
    case 'image/gif':
    case 'gif':
      return faFileGif
    case 'application/svg+xml':
    case 'svg':
      return faFileSvg
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'xls':
    case 'xlsx':
      return faFileXls
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'ppt':
    case 'pptx':
      return faFilePpt
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
    case 'doc':
    case 'docx':
      return faFileDoc
    case 'application/xml':
    case 'xml':
      return faFileXml
    case 'application/zip':
    case 'application/x-zip-compressed':
    case 'zip':
    case 'zipx':
      return faFileZip
    case 'audio/mpeg':
    case 'audio/MPA':
    case 'audio/mpa-robust':
    case 'mp3':
      return faFileMp3
    case 'video/mp4':
    case 'video/ogg':
    case 'mp4':
      return faFileMp4
    case 'video/quicktime':
    case 'mov':
      return faFileMov
    case 'application/postscript':
    case 'application/eps':
    case 'application/x-eps':
    case 'application/x-postscript':
    case 'image/eps':
    case 'image/x-eps':
    case 'eps':
    case 'ps':
      return faFileEps
    case 'model/vnd.vnd-dwf':
    case 'dwf':
    case 'dwfx':
      return faFileCad
    default:
      return faFileLines
  }
}
