export const GET_DASHBOARD = /* GraphQL */ `
  query getDashboard($id: ID!) {
    getDashboard(id: $id) {
      isDefault
      isKeyMetric
      isVisible
      isPublic
      userId
      id
      name
      Description
      widgetOrder
      keyMetricaccountId
      keyMetricbuildingId
      keyMetric1Display
      keyMetric1Name
      keyMetric1UoM
      keyMetric2Display
      keyMetric2Name
      keyMetric2UoM
      keyMetric3Display
      keyMetric3Name
      keyMetric3UoM
      keyMetric4Display
      keyMetric4Name
      keyMetric4UoM
      keyMetric5Display
      keyMetric5Name
      keyMetric5UoM
      keyMetricComparedTo
      keyMetricTimePeriod
      buildingDataCharts
    }
  }
`
export const GET_DASHBOARD_WIDGET_IDS = /* GraphQL */ `
query getDashboard($id: ID!) {
  getDashboard(id: $id) {
    id
    buildingDataCharts
    widgets {
      items {
        id
        widgetID
        dashboardID
      }
    }
  }
}
`
export const GET_DASHBOARD_WIDGET = /* GraphQL */ `
  query getDashboard($id: ID!) {
    getDashboard(id: $id) {
      widgetOrder
      buildingDataCharts
      widgets {
              items {
                id
                widget {
                  accountId
                  buildingId
                  building {
                    name
                    city
                    state
                    postalCode
                    accountId
                    accountName
                  }
                  id
                  name
                  description
                  isKeyMetric
                  isNewWindow
                  widgetLink
                  widgetType
                  customUrl
                  widgetTargetType
                  widgetBuildingId
                  widgetEquipment2Id
                  widgeEquipment2Name
                  widgeEquipment2Type
                  widgeEquipment2Family
                  widgeEquipment1Id
                  widgeEquipment1Name
                  widgeEquipment1Type
                  widgeEquipment1Family
                  widgetImage {
                    items {
                      displayName
                      description
                      buildingId
                      id
                      name
                      title
                      widgetId
                      type
                      sizeInBytes
                    }
                  }
                  imageText
                  imageAdditionalText
                  weatherWidget {
                    target {
                      widgetBuildingId
                      widgetTargetType
                    }
                    metricConversion
                  }
                  baselineId
                  baselineComparision{
                    timePeriod
                    displayValue
                    target{
                      baselineId
                      widgeEquipment1Id
                      widgetBuildingId
                      widgetTargetType
                    }
                  }
                  equipmentCustomKpiWidget {
                    kpiCurrentPeriod
                    kpiDisplayDate
                    kpiGoal
                    kpiMeasurement
                    kpiPlotType
                    kpiPreviousPeriod
                    timePeriod
                    kpiTrend {
                      actual
                      date
                      goal
                    }
                  }
                  equipmentDataMonitorGuageWidget {
                    guageMaximumCalculationType
                    guageMaximumType
                    guageMaximumValue
                    guageMinimum
                    needleValue
                    timePeriod
                    colourConfig {
                      baseline
                      threshold1
                      threshold1Value
                      threshold2
                      threshold2Value
                      thresholdOption
                    }
                    property {
                      property
                      uom
                      symbol
                    }
                  }
                  equipmentDataMonitorOrbWidget {
                    Value
                    timePeriod
                    colourConfig {
                      baseline
                      threshold1
                      threshold1Value
                      threshold2
                      threshold2Value
                      thresholdOption
                    }
                    property {
                      property
                      uom
                      symbol
                    }
                    target {
                      widgetBuildingId
                      widgetTargetType
                    }
                  }
                  equipmentTrendLineWidget {
                    value
                    timePeriod
                    comparePoint
                    colourConfig {
                      line
                      overlayLine
                    }
                    property {
                      property
                      uom
                      symbol
                    }
                  }
                  equipmentTrendBarWidget{
                    timeInterval
                    timePeriod
                    value
                    colourConfig {
                      leftBar
                      leftBenchmark
                    }
                    property {
                      property
                      uom
                      symbol
                    }
                    benchMark {
                      benchmarkCalculation
                      benchmarkLabel
                      benchmarkMarker
                      benchmarkValue
                    }
                  }
                  equipmentTimeComparisionBarWidget {
                    barMaximumCalculationType
                    barMaximumType
                    barMaximumValue
                    barMinimum
                    timePeriod
                    value
                    property {
                      property
                      uom
                      symbol
                    }
                    leftbarBenchmark {
                      benchmarkCalculation
                      benchmarkLabel
                      benchmarkMarker
                      benchmarkValue
                    }
                    rightbarBenchmark {
                      benchmarkCalculation
                      benchmarkLabel
                      benchmarkMarker
                      benchmarkValue
                    }
                    colourConfig {
                      leftInnerBar
                      leftOuterBar
                      leftBenchmark
                      rightBar
                      rightBenchmark
                    }
                  }
                  equipmentDataComparisionBarWidget {
                    barMaximumCalculationType
                    barMaximumType
                    barMaximumValue
                    barMinimum
                    timePeriod
                    value
                    colourConfig {
                      leftBar
                      leftBenchmark
                      rightBar
                      rightBenchmark
                    }
                    leftBarProperty {
                      benchmarkCalculation
                      benchmarkLabel
                      benchmarkValue
                      benchmarkMarker
                      property
                      uom
                      symbol
                    }
                    rightBarProperty {
                      benchmarkCalculation
                      benchmarkLabel
                      benchmarkMarker
                      benchmarkValue
                      property
                      uom
                      symbol
                    }
                  }  
                  energyConsumptionWidget {
                    comparedTo
                    showTemperature
                    timePeriod
                    uom
                    benchMark {
                      benchmarkLabel
                      benchmarkMarker
                      benchmarkValue
                      benchmarkCalculation
                    }
                    colourConfig {
                      leftBar
                      rightBar
                      rightBenchmark
                      toDate
                    }
                    target {
                      widgetBuildingId
                      widgetTargetType
                      widgeEquipment1Id
                    }
                  }
                  energyCostWidget {
                    comparedTo
                    showEnergyCostSegmentation
                    timePeriod
                    benchMark {
                      benchmarkLabel
                      benchmarkMarker
                      benchmarkValue
                      benchmarkCalculation
                    }
                    colourConfig {
                      leftBar
                      rightBar
                      rightBenchmark
                      toDate
                    }
                    target {
                      widgetBuildingId
                      widgetTargetType
                      widgeEquipment1Id
                    }
                  }
                  energyDemandWidget {
                    colourConfig {
                      currentDemand
                      overlayDemand
                      peakDemand
                    }
                    target {
                      widgetTargetType
                      widgetBuildingId
                      widgeEquipment1Id
                    }
                    rateIncrease {
                      name
                      rateIncreaseEnd
                      rateIncreaseStart
                    }
                    comparedTo
                    isRateIncrease
                    timePeriod
                  }
                  widgetBuildingTarget {
                    id
                    name
                  }
                }
              }
            }
    }
  }
`

export const DELETE_DASHBOARD = /* GraphQL */ `
  mutation deleteDashboard($input: DeleteDashboardInput!) {
    deleteDashboard(input: $input) {
      id
    }
  }
`

export const UPDATE_DASHBOARD = /* GraphQL */ `
  mutation updateDashboard($input: UpdateDashboardInput!) {
    updateDashboard(input: $input) {
      id
    }
  }
`
export const CREATE_DASHBOARD_WIDGET = `mutation CREATE_DASHBOARD_WIDGET($input:CreateDashboardWidgetInput!) {
  createDashboardWidget(input: $input) {
    id
  }
}`


export const GET_PROPERTIES_SYMBOL   = `query ListConfigs ($filter: ModelConfigFilterInput) {
  listConfigs(filter: $filter, limit: 100000) {
    items {
      type
      value
      subType
    }
  }
}
`
export const DELETE_DASHBOARD_WIDGET = /* GraphQL */ `
  mutation deleteDashboardWidget($input: DeleteDashboardWidgetInput!) {
    deleteDashboardWidget(input: $input) {
      id
    }
  }
`