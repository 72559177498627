import { useState } from "react";
import { GET_USERS_LAMBDA } from "../../pages/userManagement/graphql/queries";
import { DenaliTableStyle } from "src/denali-components/Table/table.d";
import { pages } from 'src/pages/pages.js'
import Table from '../../components/Table/graphQLTable.js'
import { UserNameBubble } from "../../denali-components/UserNameBubble/UserNameBubble";
import moment from "moment/moment";
import { Chips } from "../../denali-components/Table/Chips";
import { Chip, ChipColor } from "../../denali-components/Table/Chip";
import { USER_EVENTS} from "src/amplitude-categories"
import { faUser, faUserSlash } from "../../denali-components/lib/pro-solid-svg-icons";
import { trackEvent } from "../../amplitude";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserAccess, selectUserInfo } from "../../redux/slicers/appData";
import { useQuery } from "../../hooks/APIHooks";
import { LIST_USERTYPES } from "../../pages/userTypeManagement/graphql";
import { getDateFormatByLocale } from '../../common/chartHelperFunctions'
import styled from "styled-components";

const UserCardRow = styled.div`
  display: flex;
  align-items: center;
`
const InfoDiv = styled.div`
  padding-bottom: 5px;
  color: gray; 
  font-size: 14px;
`
const UserManagementTable = ({setDeactivateUserDialog, setReactivateUserDialog, refetch}) => {
    const navigate = useNavigate()
    const userAccess = useSelector(selectUserAccess)
    const userInfo = useSelector(selectUserInfo)
    const [userTypeFilters, setUserTypeFilters] = useState([])

    const {
        data: data1,
        refetch: refetchData1,
        responseTime: responseTime1,
        loading: loadingUserTypeFilters
    } = useQuery({
        query: LIST_USERTYPES,
        errorPolicy: 'all',
        dataPath: 'data.listUserTypes.items',
        onSuccess: (data) => {
            setUserTypeFilters(data)
        },
    })

    const headersCardDenaliTable = [
        {
            key: "lastName",
            title: "Last Name",
            alignContent: 'center',
            width: "100%",
            customComponent: (row) => {
                return (
                    <UserCardRow>
                        <UserNameBubble
                            firstName={row.firstName}
                            lastName={row.lastName}
                            showFullName={false}
                            showTooltip={false}
                            iconOnly={true}
                        />

                        <div style={{paddingLeft: "20px"}}>
                            {row.firstName} {row.lastName}
                            <div style={{color: "gray", fontSize: "", paddingTop: "3px"}}>
                                {row.email}
                            </div>
                        </div>
                    </UserCardRow>
                )
            }
        },
        {
            key: 'createdAt',
            title: "Creation Date",
            alignContent: 'center',
            type: "date",
            width: "100%",
            customComponent: (row) => {
                return (
                    <UserCardRow>
                        <div>
                            <InfoDiv>Created On</InfoDiv>
                            <div style={{fontSize: "16px"}}>
                                { moment(row.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(getDateFormatByLocale("MM-DD-YYYY")) }
                            </div>
                        </div>
                        <div style={{paddingLeft: "20px"}}>
                            <InfoDiv>Type</InfoDiv>
                            <div style={{fontSize: "16px"}}>
                                {row.type.name}
                            </div>
                        </div>
                    </UserCardRow>
                )
            }
        },
        {
            key: 'activeStatus',
            hideFromSort: true,
            alignContent: 'flex-start',
            width: "100%",
            customComponent: (row) => {
                return (
                    <UserCardRow>
                        <div style={{paddingLeft: "20px", alignSelf: "flex-end"}}>
                            <Chips>
                                <Chip text={row.isActive ? "Active" : "Inactive"} color={row.isActive ? ChipColor.green : ChipColor.red} />
                            </Chips>
                        </div>
                    </UserCardRow>
                )
            }
        }
    ]

    const rowActionCallback = (headers) => {
        return (event, row) => {
            navigate(
                pages['User Management/user-details/:id'].href.replace(':id', row.id)
            )
        }
    }

    const rowControls = (row) => {
        return [{
            text: row.isActive ? "Deactivate" : "Activate",
            icon: row.isActive ? faUserSlash : faUser,
            action: (data) => {
                if (row.isActive) {
                    setDeactivateUserDialog(data)
                    trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_DEACTIVATE_USER, {"location":"User List"})
                } else if (!row.isActive) {
                    setReactivateUserDialog(data)
                    trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ACTIVATE_USER, {"location":"User List"})
                }
            }
        }]
    }

    const denaliCardTableFilters = [
        {
            // key gets used in the graphql query
            // in options array the value gets used in the graphql query
            key: 'userTypeId',
            filterName: 'userType',
            filterLabel: 'Type',
            id: 'userType',
            defaultLabel: 'All Types',
            selectedValue: 'default',
            options: [
                {
                    "name": "Trane Employee",
                    "value": "6be6ec61-cc5c-4967-a0d5-50208f16723d"
                },
                {
                    "name": "Building Employee",
                    "value": "c7a04a53-e2dc-46bf-b8d9-84288a431964"
                },
                {
                    "name": "Contractor",
                    "value": "400ecf30-bf26-4fea-bc05-871436c3e2aa"
                }
            ]
        },
        {
            key: 'isActive',
            filterName: 'isActive',
            filterLabel: 'Status',
            id: 'isActive',
            defaultLabel: 'All Statuses',
            selectedValue: 'default',
            options: [
                {
                    key: 1,
                    value: 1,
                    name: 'Active'
                },
                {
                    key: 0,
                    value: 0,
                    name: 'Inactive'
                }
            ]
        }
    ]

    return (
        <Table
            query={{ query: GET_USERS_LAMBDA, dataPath: 'data.listUsersByAccesses', variables: {filter: {or: userInfo?.type?.authUserTypes.map(e => ({userTypeId: {eq: e}}))}} }}
            stringifyFields={{
                sort: true,
                filter: true
            }}
            refetch={refetch}
            rowAction={rowActionCallback(headersCardDenaliTable)}
            header={headersCardDenaliTable}
            search={true}
            cardStyleFilters={denaliCardTableFilters}
            searchFields={['lastName', 'firstName', 'email', ["firstName", "lastName"]]}
            rowControl={(e) => rowControls(e)}
            isShowPagination={true}
            isDenaliTable={true}
            tableRowOverflowHidden={false}
            showItemCount={true}
            showSpinner={true}
            denaliTableStyle={DenaliTableStyle.cards}
        />
    )

}

export default UserManagementTable
