import { useState } from 'react'
import { Link, useMatches } from 'react-router-dom'
import { ReactComponent as LogoSmallRed } from 'src/assets/images/logo-red-small.svg'
import { ReactComponent as LogoLargeRed } from 'src/assets/images/logo-red-large.svg'
import { DenaliRouteHandle, MainNavigation } from './MainNavigation'
import { MenuToggle } from './MenuToggle'
import styles from './sidebar.module.scss'
import { UserMenu } from './UserMenu'
import { useTranslation } from 'react-i18next'
import { BuildingSelector } from '../BuildingSelector/BuildingSelector'
import { UiSwitch } from './UiSwitch'

export const Sidebar = ({ signOut }) => {
  const [t] = useTranslation()
  const matches = useMatches()
  const routeData = matches?.[matches.length - 1]?.handle as DenaliRouteHandle
  const showNewBuildingPicker =
    !!routeData?.page?.navigationProps?.denaliBuildingPicker
  const [isOpen, setIsOpen] = useState(true)

  return (
    <aside
      className={`${styles.sidebar}`}
      data-testid="sidebar"
      data-open={isOpen}
    >
      <MenuToggle
        menuIsOpen={isOpen}
        setMenuIsOpen={(value: boolean) => setIsOpen(value)}
      />
      <div className={`${isOpen ? styles.open : ''}`}>
        <div className={`${styles.navSection} ${styles.logoSection}`}>
          <Link
            to="/home"
            aria-label={t('common:TraneLogo')}
            data-testid="sidebarLogo"
          >
            {isOpen ? <LogoLargeRed /> : <LogoSmallRed />}
          </Link>
        </div>
      </div>
      <div className={`${styles.sidebarInner} ${isOpen ? styles.open : ''}`}>
        <div className={styles.sidebarReset}>
          {showNewBuildingPicker && (
            <BuildingSelector sidebarIsExpanded={isOpen} />
          )}
          <MainNavigation className={styles.navSection} menuIsOpen={isOpen} />
        </div>
      </div>
      <div className={`${styles.footerSection} ${isOpen ? styles.open : ''}`}>
        <UiSwitch menuIsOpen={isOpen} />
        <UserMenu menuIsOpen={isOpen} signOut={signOut} />
      </div>
    </aside>
  )
}
