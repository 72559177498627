import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

// components
import ToggleBox from 'src/components/legacy/components/toggle-box/'
import Table from 'src/components/layouts/Table'
// import { useQuery } from 'src/hooks/APIHooks'
// import {
//   GET_DATA_MAPPING_CALCULATED,
//   GET_DATA_MAPPING_PROPERTIES
// } from '../../graphql/queries'
import { EQTable } from '../style'
import translate, { fetchTranslate } from '../../../../common/translations'
import { useDispatch, useSelector } from 'react-redux'
import { useFilterEquipmentPropertiesQuery } from 'src/hooks/FilterRelavantEquipmentProperities/useFilterEquipmentProperties'

AssociationDetailsProperties.propTypes = {
  equipmentId: PropTypes.any
}

export default function AssociationDetailsProperties({
  equipmentId,
  equipType
}) {
  const { getFilteredProperties } = useFilterEquipmentPropertiesQuery()
  const [dataProperties, setDataProperties] = useState([])

  const getEquipmentProperties = async (equipmentId, equipType) => {
    const properties = await getFilteredProperties({
      id: equipmentId,
      type: equipType
    })
    setDataProperties(properties)
  }


  useEffect(() => {
    if (equipmentId && equipType) {
      getEquipmentProperties(equipmentId, equipType)
    }
  }, [equipmentId, equipType])

  const automationColumn = [
    {
      displayName: translate('Property'),
      sortable: true,
      key: 'tisDisplayName',
      name: 'tisDisplayName',
      sortValue: 'tisDisplayName'
    },
    {
      displayName: translate('Setting'),
      sortable: true,
      key: 'sourceDisplayName',
      name: 'sourceDisplayName',
      sortValue: 'sourceDisplayName'
    }
  ]
  const selectedLanguage =
    useSelector((state) => state?.appData?.selectedLanguage) || 'en'
  const [translatedValues, setTranslatedValues] = useState([])

  const filteredRow = useMemo(() => {
    let filteredData = dataProperties?.filter(
      // filter item.creationType === 'Auto' has been removed as per the logic in gen4
      (item) => item.tisDisplayName !== ''
    )

    const uniqueRows = []
    const duplicateTisDisplayNames = []
    // To Fetch Unique tisDisplayName (if it has duplicate entries need to check for sourceDisplayName value and display it)
    filteredData?.forEach((item) => {
      if (!duplicateTisDisplayNames.includes(item.tisDisplayName)) {
        uniqueRows.push(item)
        duplicateTisDisplayNames.push(item.tisDisplayName)
      } else {
        const existingIndex = uniqueRows.findIndex(
          (row) => row.tisDisplayName === item.tisDisplayName
        )
        if (
          item.sourceDisplayName &&
          !uniqueRows[existingIndex].sourceDisplayName
        ) {
          uniqueRows[existingIndex] = item
        }
      }
    })

    return (
      uniqueRows.map((id) => ({
        tisDisplayName: id.tisDisplayName,
        sourceDisplayName: id.sourceDisplayName
          ? id.sourceDisplayName
          : 'No Property Selected'
      })) || []
    )
  }, [dataProperties])

  useEffect(() => {
    const fetchTranslations = async () => {
      const translations = await Promise.all(
        filteredRow.map(async (v) => {
          if (selectedLanguage !== 'en' && v?.sourceDisplayName) {
            const translatedName = await fetchTranslate(
              v?.sourceDisplayName,
              selectedLanguage
            )
            const translatedValue = await fetchTranslate(
              v?.tisDisplayName,
              selectedLanguage
            )
            return {
              ...v,
              tisDisplayName: translatedValue,
              sourceDisplayName: translatedName
            }
          }
          return v
        })
      )
      setTranslatedValues(translations)
    }

    if (selectedLanguage !== 'en') {
      fetchTranslations()
    } else {
      setTranslatedValues(filteredRow)
    }
  }, [filteredRow, selectedLanguage])
  return (
    <ToggleBox
      label="Properties"
      className="transparent"
      showOnlyLabel={true}
      opened={true}
    >
      <EQTable>
        <Table
          paginationEnabled={false}
          pageCount={0}
          rows={translatedValues}
          columns={automationColumn}
          className="equipment-list"
        />
      </EQTable>
    </ToggleBox>
  )
}
