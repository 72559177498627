import { useMemo, useRef } from 'react'

import './styles.scss'
import { useOptions } from './useOptions'
import Axes from './Axes'
import BaseWrapper from '../../../chart-library/CommonComponents/BaseWrapper'
import Svg from '../../../chart-library/CommonComponents/Svg'
import { clipUUID } from '../../../chart-library/CommonComponents/Clip'
import { Tooltip } from '../../../chart-library/CommonComponents/Tooltip'
import TooltipRenderer from './tooltip'
import Series from './Series'
import { dataTransformer, toDates } from './helpers'
import { uniq } from 'lodash'
import moment from 'moment'
import { findMinMaxValuesObject } from '../helpers.js'
import { TranslateComponent } from 'src/common/translations'

export default function HeatMap({
  chartData = { data: [], timeStamps: [] },
  TOOLTIP_TITLE,
  unit,
  testName = ''
}) {
  const eventName = useMemo(() => clipUUID('heatmap-chart-tooltip'), [])

  const { xAxisData, data, min, max } = useMemo(() => {
    const dataToPlot = chartData?.data
      ? chartData?.data[0]?.values[0] || []
      : []
    const timeData = chartData?.timeStamps ? chartData?.timeStamps : []
    const bandData = uniq(
      timeData.map((time) => moment(time).locale("en").format('YYYY-MM-DD'))
    )
    const [minValueObject, maxValueObject] =
      findMinMaxValuesObject(dataToPlot) || []

    return {
      xAxisData: toDates(bandData) || [],
      data: dataTransformer(dataToPlot, TOOLTIP_TITLE, unit) || [],
      min: minValueObject?.value || 0,
      max: maxValueObject?.value || 0
    }
  }, [chartData])

  const options = useOptions(xAxisData)

  // provide boundary of tooltip to position properly
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper testName={testName} options={options} className="heat-map">
      <div className="ed-y-label-block">
        <div className="ed-y-label">
          <b><TranslateComponent>Time</TranslateComponent> </b>
        </div>
      </div>
      <Svg ref={svgRef}>
        <Axes />
        <Series
          tooltipDispatchEventName={eventName}
          data={data}
          minValue={min}
          maxValue={max}
          enableTooltip
          unit={unit}
        />
      </Svg>
      <Tooltip
        tooltipClassName="heat-map-tooltip-bg"
        listenEventName={eventName}
        boundary={svgRef}
        TooltipRenderer={TooltipRenderer}
      />
    </BaseWrapper>
  )
}
